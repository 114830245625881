/* tslint:disable */
/* eslint-disable */
/**
 * Posweb REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface ActionResult
 */
export interface ActionResult {
    /**
     *
     * @type {ActionResultData}
     * @memberof ActionResult
     */
    'data'?: ActionResultData;
}
/**
 *
 * @export
 * @interface ActionResultData
 */
export interface ActionResultData {
    /**
     *
     * @type {string}
     * @memberof ActionResultData
     */
    'type'?: string;
    /**
     *
     * @type {ActionResultDataAttributes}
     * @memberof ActionResultData
     */
    'attributes'?: ActionResultDataAttributes;
}
/**
 *
 * @export
 * @interface ActionResultDataAttributes
 */
export interface ActionResultDataAttributes {
    /**
     *
     * @type {string}
     * @memberof ActionResultDataAttributes
     */
    'action'?: string;
    /**
     *
     * @type {string}
     * @memberof ActionResultDataAttributes
     */
    'result'?: string;
}
/**
 *
 * @export
 * @interface ApiV1AggiornamentoprezziStampaElencoData
 */
export interface ApiV1AggiornamentoprezziStampaElencoData {
    /**
     * fixed data type \"aggiornamentoprezzi-stampa-elenco\"
     * @type {string}
     * @memberof ApiV1AggiornamentoprezziStampaElencoData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1AggiornamentoprezziStampaElencoDataAttributes}
     * @memberof ApiV1AggiornamentoprezziStampaElencoData
     */
    'attributes'?: ApiV1AggiornamentoprezziStampaElencoDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1AggiornamentoprezziStampaElencoDataAttributes
 */
export interface ApiV1AggiornamentoprezziStampaElencoDataAttributes {
    /**
     *
     * @type {string}
     * @memberof ApiV1AggiornamentoprezziStampaElencoDataAttributes
     */
    'ordine_stampa'?: ApiV1AggiornamentoprezziStampaElencoDataAttributesOrdineStampaEnum;
    /**
     *
     * @type {string}
     * @memberof ApiV1AggiornamentoprezziStampaElencoDataAttributes
     */
    'set_dati'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiV1AggiornamentoprezziStampaElencoDataAttributesOrdineStampaEnum {
    Classe = 'classe',
    Collezione = 'collezione',
    Perc = 'perc',
    Modello = 'modello',
    Prezzo = 'prezzo',
    Nome = 'nome'
}

/**
 *
 * @export
 * @interface ApiV1AggiornamentoprezziStampaEtichetteData
 */
export interface ApiV1AggiornamentoprezziStampaEtichetteData {
    /**
     * fixed data type \"aggiornamentoprezzi-stampa-etichette\"
     * @type {string}
     * @memberof ApiV1AggiornamentoprezziStampaEtichetteData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1AggiornamentoprezziStampaEtichetteDataAttributes}
     * @memberof ApiV1AggiornamentoprezziStampaEtichetteData
     */
    'attributes'?: ApiV1AggiornamentoprezziStampaEtichetteDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1AggiornamentoprezziStampaEtichetteDataAttributes
 */
export interface ApiV1AggiornamentoprezziStampaEtichetteDataAttributes {
    /**
     *
     * @type {string}
     * @memberof ApiV1AggiornamentoprezziStampaEtichetteDataAttributes
     */
    'ordine_stampa'?: ApiV1AggiornamentoprezziStampaEtichetteDataAttributesOrdineStampaEnum;
    /**
     *
     * @type {string}
     * @memberof ApiV1AggiornamentoprezziStampaEtichetteDataAttributes
     */
    'set_dati'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1AggiornamentoprezziStampaEtichetteDataAttributes
     */
    'solo_imponibile'?: ApiV1AggiornamentoprezziStampaEtichetteDataAttributesSoloImponibileEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiV1AggiornamentoprezziStampaEtichetteDataAttributesOrdineStampaEnum {
    Classe = 'classe',
    Collezione = 'collezione',
    Perc = 'perc',
    Modello = 'modello',
    Prezzo = 'prezzo',
    Nome = 'nome'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiV1AggiornamentoprezziStampaEtichetteDataAttributesSoloImponibileEnum {
    _0 = '0',
    _1 = '1'
}

/**
 *
 * @export
 * @interface ApiV1B2eRefundsData
 */
export interface ApiV1B2eRefundsData {
    /**
     * fixed data type \"b2e-refunds\"
     * @type {string}
     * @memberof ApiV1B2eRefundsData
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsData
     */
    'id'?: string;
    /**
     *
     * @type {ApiV1B2eRefundsDataAttributes}
     * @memberof ApiV1B2eRefundsData
     */
    'attributes'?: ApiV1B2eRefundsDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1B2eRefundsDataAttributes
 */
export interface ApiV1B2eRefundsDataAttributes {
    /**
     *
     * @type {Array<ApiV1B2eRefundsDataAttributesRimborsi>}
     * @memberof ApiV1B2eRefundsDataAttributes
     */
    'rimborsi'?: Array<ApiV1B2eRefundsDataAttributesRimborsi>;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributes
     */
    'id_transazione'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributes
     */
    'rimborso_spedizione'?: ApiV1B2eRefundsDataAttributesRimborsoSpedizioneEnum;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributes
     */
    'cassiera'?: string;
    /**
     * Array vuoto
     * @type {Array<object>}
     * @memberof ApiV1B2eRefundsDataAttributes
     */
    'bollettine_l'?: Array<object>;
    /**
     *
     * @type {Array<ApiV1B2eRefundsDataAttributesPagamenti1>}
     * @memberof ApiV1B2eRefundsDataAttributes
     */
    'pagamenti'?: Array<ApiV1B2eRefundsDataAttributesPagamenti1>;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiV1B2eRefundsDataAttributesRimborsoSpedizioneEnum {
    _0 = '0',
    _1 = '1'
}

/**
 *
 * @export
 * @interface ApiV1B2eRefundsDataAttributesDatiOperazione
 */
export interface ApiV1B2eRefundsDataAttributesDatiOperazione {
    /**
     *
     * @type {ApiV1B2eRefundsDataAttributesDatiOperazioneDatiCarta}
     * @memberof ApiV1B2eRefundsDataAttributesDatiOperazione
     */
    'dati_carta'?: ApiV1B2eRefundsDataAttributesDatiOperazioneDatiCarta;
    /**
     *
     * @type {number}
     * @memberof ApiV1B2eRefundsDataAttributesDatiOperazione
     */
    'importo'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesDatiOperazione
     */
    'descrizione'?: string;
}
/**
 *
 * @export
 * @interface ApiV1B2eRefundsDataAttributesDatiOperazioneDatiCarta
 */
export interface ApiV1B2eRefundsDataAttributesDatiOperazioneDatiCarta {
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesDatiOperazioneDatiCarta
     */
    'online_transaction_id'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesDatiOperazioneDatiCarta
     */
    'company_code'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesDatiOperazioneDatiCarta
     */
    'scontrino'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesDatiOperazioneDatiCarta
     */
    'transaction_mode'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesDatiOperazioneDatiCarta
     */
    'pre_authorization'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesDatiOperazioneDatiCarta
     */
    'terminal_pos_id'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesDatiOperazioneDatiCarta
     */
    'flag_multicurrency'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesDatiOperazioneDatiCarta
     */
    'transaction_time'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesDatiOperazioneDatiCarta
     */
    'card_expiration_date'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesDatiOperazioneDatiCarta
     */
    'transaction_type'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesDatiOperazioneDatiCarta
     */
    'fraction_type'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesDatiOperazioneDatiCarta
     */
    'authorization_number'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesDatiOperazioneDatiCarta
     */
    'transaction_date'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesDatiOperazioneDatiCarta
     */
    'stan'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesDatiOperazioneDatiCarta
     */
    'desc_acquirer'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesDatiOperazioneDatiCarta
     */
    'id_acquirer'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesDatiOperazioneDatiCarta
     */
    'terminal_id'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesDatiOperazioneDatiCarta
     */
    'card_utilization_type'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesDatiOperazioneDatiCarta
     */
    'pan'?: string;
}
/**
 *
 * @export
 * @interface ApiV1B2eRefundsDataAttributesPagamenti
 */
export interface ApiV1B2eRefundsDataAttributesPagamenti {
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesPagamenti
     */
    'cod_operazione'?: string;
    /**
     *
     * @type {ApiV1B2eRefundsDataAttributesDatiOperazione}
     * @memberof ApiV1B2eRefundsDataAttributesPagamenti
     */
    'dati_operazione'?: ApiV1B2eRefundsDataAttributesDatiOperazione;
}
/**
 *
 * @export
 * @interface ApiV1B2eRefundsDataAttributesPagamenti1
 */
export interface ApiV1B2eRefundsDataAttributesPagamenti1 {
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesPagamenti1
     */
    'id_transazione'?: string;
    /**
     * barcode
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesPagamenti1
     */
    'barcode'?: string;
    /**
     * cod_negozio
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesPagamenti1
     */
    'cod_negozio'?: string;
    /**
     * cod_operazione
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesPagamenti1
     */
    'cod_operazione'?: string;
    /**
     * codice_movimento
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesPagamenti1
     */
    'codice_movimento'?: string;
    /**
     * codice_stato
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesPagamenti1
     */
    'codice_stato'?: string;
    /**
     * data_creazione
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesPagamenti1
     */
    'data_creazione'?: string;
    /**
     * data_modifica
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesPagamenti1
     */
    'data_modifica'?: string;
    /**
     * dati_operazione
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesPagamenti1
     */
    'dati_operazione'?: string;
    /**
     * divisa
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesPagamenti1
     */
    'divisa'?: string;
    /**
     * id_postazione
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesPagamenti1
     */
    'id_postazione'?: string;
    /**
     * importo_finale
     * @type {number}
     * @memberof ApiV1B2eRefundsDataAttributesPagamenti1
     */
    'importo_finale'?: number;
    /**
     * importo_iniziale
     * @type {number}
     * @memberof ApiV1B2eRefundsDataAttributesPagamenti1
     */
    'importo_iniziale'?: number;
    /**
     * nota
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesPagamenti1
     */
    'nota'?: string;
    /**
     * progressivo
     * @type {number}
     * @memberof ApiV1B2eRefundsDataAttributesPagamenti1
     */
    'progressivo'?: number;
    /**
     * progressivo_capo
     * @type {number}
     * @memberof ApiV1B2eRefundsDataAttributesPagamenti1
     */
    'progressivo_capo'?: number;
    /**
     * tipo_applicazione
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesPagamenti1
     */
    'tipo_applicazione'?: string;
}
/**
 *
 * @export
 * @interface ApiV1B2eRefundsDataAttributesRimborsi
 */
export interface ApiV1B2eRefundsDataAttributesRimborsi {
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesRimborsi
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesRimborsi
     */
    'stato_spedizione'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesRimborsi
     */
    'canale_rimborso'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesRimborsi
     */
    'numero_ordine'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesRimborsi
     */
    'data_ordine'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesRimborsi
     */
    'numero_vendita'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesRimborsi
     */
    'id_transazione'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesRimborsi
     */
    'data_vendita'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesRimborsi
     */
    'numero_spedizione'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesRimborsi
     */
    'consumer'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesRimborsi
     */
    'sku'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1B2eRefundsDataAttributesRimborsi
     */
    'importo_rimborsabile'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1B2eRefundsDataAttributesRimborsi
     */
    'spese_spedizione'?: number;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1B2eRefundsDataAttributesRimborsi
     */
    'spedizione_rimborsabile'?: boolean;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesRimborsi
     */
    'json_rimborso'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesRimborsi
     */
    'pk_consumer'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1B2eRefundsDataAttributesRimborsi
     */
    'anno_transazione'?: string;
    /**
     *
     * @type {Array<ApiV1B2eRefundsDataAttributesPagamenti>}
     * @memberof ApiV1B2eRefundsDataAttributesRimborsi
     */
    'pagamenti'?: Array<ApiV1B2eRefundsDataAttributesPagamenti>;
}
/**
 *
 * @export
 * @interface ApiV1CartData
 */
export interface ApiV1CartData {
    /**
     *
     * @type {string}
     * @memberof ApiV1CartData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1CartDataAttributes}
     * @memberof ApiV1CartData
     */
    'attributes'?: ApiV1CartDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1CartDataAttributes
 */
export interface ApiV1CartDataAttributes {
    /**
     *
     * @type {Array<string>}
     * @memberof ApiV1CartDataAttributes
     */
    'ok'?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof ApiV1CartDataAttributes
     */
    'ko'?: Array<string>;
}
/**
 *
 * @export
 * @interface ApiV1ChiusuracassaChiusuraPosData
 */
export interface ApiV1ChiusuracassaChiusuraPosData {
    /**
     *
     * @type {string}
     * @memberof ApiV1ChiusuracassaChiusuraPosData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1ChiusuracassaChiusuraPosDataAttributes}
     * @memberof ApiV1ChiusuracassaChiusuraPosData
     */
    'attributes'?: ApiV1ChiusuracassaChiusuraPosDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1ChiusuracassaChiusuraPosDataAttributes
 */
export interface ApiV1ChiusuracassaChiusuraPosDataAttributes {
    /**
     * In POSWEB1 the value is calculated like this: \'closure-\'+Y.one(
     * @type {string}
     * @memberof ApiV1ChiusuracassaChiusuraPosDataAttributes
     */
    'pos_transaction_id'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ChiusuracassaChiusuraPosDataAttributes
     */
    'impersonated_cash'?: string;
}
/**
 *
 * @export
 * @interface ApiV1ChiusuracassaConfermaChiusuraData
 */
export interface ApiV1ChiusuracassaConfermaChiusuraData {
    /**
     *
     * @type {string}
     * @memberof ApiV1ChiusuracassaConfermaChiusuraData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1ChiusuracassaStampaResocontoGiftCardDataAttributes}
     * @memberof ApiV1ChiusuracassaConfermaChiusuraData
     */
    'attributes'?: ApiV1ChiusuracassaStampaResocontoGiftCardDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1ChiusuracassaRispostaCartaTotaliData
 */
export interface ApiV1ChiusuracassaRispostaCartaTotaliData {
    /**
     *
     * @type {string}
     * @memberof ApiV1ChiusuracassaRispostaCartaTotaliData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1ChiusuracassaStampaTotaliPosDataAttributes}
     * @memberof ApiV1ChiusuracassaRispostaCartaTotaliData
     */
    'attributes'?: ApiV1ChiusuracassaStampaTotaliPosDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1ChiusuracassaStampaChiusuraRegistratoreData
 */
export interface ApiV1ChiusuracassaStampaChiusuraRegistratoreData {
    /**
     *
     * @type {string}
     * @memberof ApiV1ChiusuracassaStampaChiusuraRegistratoreData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1ChiusuracassaStampaResocontoGiftCardDataAttributes}
     * @memberof ApiV1ChiusuracassaStampaChiusuraRegistratoreData
     */
    'attributes'?: ApiV1ChiusuracassaStampaResocontoGiftCardDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1ChiusuracassaStampaResocontoCartaData
 */
export interface ApiV1ChiusuracassaStampaResocontoCartaData {
    /**
     *
     * @type {string}
     * @memberof ApiV1ChiusuracassaStampaResocontoCartaData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1ChiusuracassaStampaResocontoGiftCardDataAttributes}
     * @memberof ApiV1ChiusuracassaStampaResocontoCartaData
     */
    'attributes'?: ApiV1ChiusuracassaStampaResocontoGiftCardDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1ChiusuracassaStampaResocontoGiftCardData
 */
export interface ApiV1ChiusuracassaStampaResocontoGiftCardData {
    /**
     *
     * @type {string}
     * @memberof ApiV1ChiusuracassaStampaResocontoGiftCardData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1ChiusuracassaStampaResocontoGiftCardDataAttributes}
     * @memberof ApiV1ChiusuracassaStampaResocontoGiftCardData
     */
    'attributes'?: ApiV1ChiusuracassaStampaResocontoGiftCardDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1ChiusuracassaStampaResocontoGiftCardDataAttributes
 */
export interface ApiV1ChiusuracassaStampaResocontoGiftCardDataAttributes {
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ChiusuracassaStampaResocontoGiftCardDataAttributes
     */
    'test_mode'?: boolean;
}
/**
 *
 * @export
 * @interface ApiV1ChiusuracassaStampaRiepilogoGiornalieroData
 */
export interface ApiV1ChiusuracassaStampaRiepilogoGiornalieroData {
    /**
     *
     * @type {string}
     * @memberof ApiV1ChiusuracassaStampaRiepilogoGiornalieroData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1ChiusuracassaStampaResocontoGiftCardDataAttributes}
     * @memberof ApiV1ChiusuracassaStampaRiepilogoGiornalieroData
     */
    'attributes'?: ApiV1ChiusuracassaStampaResocontoGiftCardDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1ChiusuracassaStampaTotaliPosData
 */
export interface ApiV1ChiusuracassaStampaTotaliPosData {
    /**
     *
     * @type {string}
     * @memberof ApiV1ChiusuracassaStampaTotaliPosData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1ChiusuracassaStampaTotaliPosDataAttributes}
     * @memberof ApiV1ChiusuracassaStampaTotaliPosData
     */
    'attributes'?: ApiV1ChiusuracassaStampaTotaliPosDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1ChiusuracassaStampaTotaliPosDataAttributes
 */
export interface ApiV1ChiusuracassaStampaTotaliPosDataAttributes {
    /**
     * In POSWEB1 the value is calculated like this: \'closure-\'+Y.one(
     * @type {string}
     * @memberof ApiV1ChiusuracassaStampaTotaliPosDataAttributes
     */
    'pos_transaction_id'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ChiusuracassaStampaTotaliPosDataAttributes
     */
    'impersonated_cash'?: string;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ChiusuracassaStampaTotaliPosDataAttributes
     */
    'test_mode'?: boolean;
}
/**
 *
 * @export
 * @interface ApiV1ConsumersAddressValidationData
 */
export interface ApiV1ConsumersAddressValidationData {
    /**
     *
     * @type {ApiV1ConsumersAddressValidationDataWsAddress}
     * @memberof ApiV1ConsumersAddressValidationData
     */
    'ws_address'?: ApiV1ConsumersAddressValidationDataWsAddress;
    /**
     *
     * @type {string}
     * @memberof ApiV1ConsumersAddressValidationData
     */
    'codice_proprietario'?: string;
}
/**
 *
 * @export
 * @interface ApiV1ConsumersAddressValidationDataWsAddress
 */
export interface ApiV1ConsumersAddressValidationDataWsAddress {
    /**
     *
     * @type {string}
     * @memberof ApiV1ConsumersAddressValidationDataWsAddress
     */
    'country'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ConsumersAddressValidationDataWsAddress
     */
    'province'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ConsumersAddressValidationDataWsAddress
     */
    'state'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ConsumersAddressValidationDataWsAddress
     */
    'city'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ConsumersAddressValidationDataWsAddress
     */
    'zip'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ConsumersAddressValidationDataWsAddress
     */
    'hno'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ConsumersAddressValidationDataWsAddress
     */
    'str'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ConsumersAddressValidationDataWsAddress
     */
    'notes'?: string;
}
/**
 *
 * @export
 * @interface ApiV1ConsumersData
 */
export interface ApiV1ConsumersData {
    /**
     * Unique consumer id. SHOULD BE NULL in POST
     * @type {string}
     * @memberof ApiV1ConsumersData
     */
    'id'?: string;
    /**
     * fixed data type \"consumers\"
     * @type {string}
     * @memberof ApiV1ConsumersData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1ConsumersDataAttributes}
     * @memberof ApiV1ConsumersData
     */
    'attributes'?: ApiV1ConsumersDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1ConsumersDataAttributes
 */
export interface ApiV1ConsumersDataAttributes {
    /**
     * anno_nascita
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'anno_nascita'?: string;
    /**
     * anno_nascita_presunto
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'anno_nascita_presunto'?: string;
    /**
     * cap
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'cap'?: string;
    /**
     * cellulare1
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'cellulare1'?: string;
    /**
     * cellulare2
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'cellulare2'?: string;
    /**
     * cod_scheda
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'cod_scheda'?: string;
    /**
     * cognome
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'cognome'?: string;
    /**
     * data_firma_scheda
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'data_firma_scheda'?: string;
    /**
     * data_modifica
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'data_modifica'?: string;
    /**
     * data_prima_registrazione
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'data_prima_registrazione'?: string;
    /**
     * data_ultimo_aggiornamento
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'data_ultimo_aggiornamento'?: string;
    /**
     * edificio
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'edificio'?: string;
    /**
     * email
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'email'?: string;
    /**
     * giorno_nascita
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'giorno_nascita'?: string;
    /**
     * grado_anonimato
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'grado_anonimato'?: string;
    /**
     * indirizzo
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'indirizzo'?: string;
    /**
     * interessi
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'interessi'?: string;
    /**
     * lingua
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'lingua'?: string;
    /**
     * locale
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'locale'?: string;
    /**
     * stringified list
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'indirizzo_alfabeto'?: string;
    /**
     * stringified list
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'nome_alfabeto'?: string;
    /**
     * localita
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'localita'?: string;
    /**
     * mese_nascita
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'mese_nascita'?: string;
    /**
     * nazionalita
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'nazionalita'?: string;
    /**
     * nazione_iso
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'nazione_iso'?: string;
    /**
     * negozio_firma_scheda
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'negozio_firma_scheda'?: string;
    /**
     * nome
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'nome'?: string;
    /**
     * non_contattare
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'non_contattare'?: string;
    /**
     * note
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'note'?: string;
    /**
     * note_indirizzo
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'note_indirizzo'?: string;
    /**
     * note_negozio
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'note_negozio'?: string;
    /**
     * numero_civico
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'numero_civico'?: string;
    /**
     * SHOULD BE NULL
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'pk_consumer'?: string;
    /**
     * SHOULD BE NULL
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'pk_crm'?: string;
    /**
     * preferenze_contatto
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'preferenze_contatto'?: string;
    /**
     * professioni
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'professioni'?: string;
    /**
     * provincia
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'provincia'?: string;
    /**
     * salutation
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'salutation'?: string;
    /**
     * sesso
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'sesso'?: string;
    /**
     * staff
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'staff'?: string;
    /**
     * RDV
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'shop_assistant'?: string;
    /**
     * telefono1
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'telefono1'?: string;
    /**
     * telefono2
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'telefono2'?: string;
    /**
     * vip_1
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'vip_1'?: string;
    /**
     * vip_2
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'vip_2'?: string;
    /**
     * wechat
     * @type {string}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'wechat'?: string;
    /**
     * agreement_profiling
     * @type {number}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'agreement_profiling'?: number;
    /**
     * agreement_direct_marketing
     * @type {number}
     * @memberof ApiV1ConsumersDataAttributes
     */
    'agreement_direct_marketing'?: number;
}
/**
 *
 * @export
 * @interface ApiV1ConsumersPkConsumerAddToLocalData
 */
export interface ApiV1ConsumersPkConsumerAddToLocalData {
    /**
     *
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerAddToLocalData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1ConsumersPkConsumerAddToLocalDataAttributes}
     * @memberof ApiV1ConsumersPkConsumerAddToLocalData
     */
    'attributes'?: ApiV1ConsumersPkConsumerAddToLocalDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1ConsumersPkConsumerAddToLocalDataAttributes
 */
export interface ApiV1ConsumersPkConsumerAddToLocalDataAttributes {
    /**
     *
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerAddToLocalDataAttributes
     */
    'pk_consumer'?: string;
}
/**
 *
 * @export
 * @interface ApiV1ConsumersPkConsumerCardsActivateData
 */
export interface ApiV1ConsumersPkConsumerCardsActivateData {
    /**
     *
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerCardsActivateData
     */
    'chiave'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerCardsActivateData
     */
    'valore'?: string;
}
/**
 *
 * @export
 * @interface ApiV1ConsumersPkConsumerCardsArchiveDocumentData
 */
export interface ApiV1ConsumersPkConsumerCardsArchiveDocumentData {
    /**
     * codice della promozione
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerCardsArchiveDocumentData
     */
    'cod_promozione'?: string;
    /**
     * codice a barre
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerCardsArchiveDocumentData
     */
    'cod_card'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerCardsArchiveDocumentData
     */
    'email_dest'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerCardsArchiveDocumentData
     */
    'lingua'?: string;
    /**
     * base64 della firma
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerCardsArchiveDocumentData
     */
    'sign_image'?: string;
}
/**
 *
 * @export
 * @interface ApiV1ConsumersPkConsumerCardsCreateData
 */
export interface ApiV1ConsumersPkConsumerCardsCreateData {
    /**
     * codice della promozione
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerCardsCreateData
     */
    'cod_promozione'?: string;
}
/**
 *
 * @export
 * @interface ApiV1ConsumersPkConsumerCardsForceData
 */
export interface ApiV1ConsumersPkConsumerCardsForceData {
    /**
     *
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerCardsForceData
     */
    'chiave'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerCardsForceData
     */
    'valore'?: string;
    /**
     * punteggio fidelity da assegnare
     * @type {number}
     * @memberof ApiV1ConsumersPkConsumerCardsForceData
     */
    'amount'?: number;
}
/**
 *
 * @export
 * @interface ApiV1ConsumersPkConsumerCardsMoveData
 */
export interface ApiV1ConsumersPkConsumerCardsMoveData {
    /**
     *
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerCardsMoveData
     */
    'chiave'?: string;
    /**
     * barcode di partenza
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerCardsMoveData
     */
    'valore_from'?: string;
    /**
     * barcode di destinazione
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerCardsMoveData
     */
    'valore'?: string;
}
/**
 *
 * @export
 * @interface ApiV1ConsumersPkConsumerCardsPrintData
 */
export interface ApiV1ConsumersPkConsumerCardsPrintData {
    /**
     * codice della promozione
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerCardsPrintData
     */
    'cod_promozione'?: string;
    /**
     * codice a barre card
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerCardsPrintData
     */
    'cod_card'?: string;
}
/**
 *
 * @export
 * @interface ApiV1ConsumersPkConsumerCardsSendEmailDocumentData
 */
export interface ApiV1ConsumersPkConsumerCardsSendEmailDocumentData {
    /**
     * codice della promozione
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerCardsSendEmailDocumentData
     */
    'cod_promozione'?: string;
    /**
     * codice a barre
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerCardsSendEmailDocumentData
     */
    'cod_card'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerCardsSendEmailDocumentData
     */
    'email_dest'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerCardsSendEmailDocumentData
     */
    'lingua'?: string;
}
/**
 *
 * @export
 * @interface ApiV1ConsumersPkConsumerCheckSogliaContantiData
 */
export interface ApiV1ConsumersPkConsumerCheckSogliaContantiData {
    /**
     *
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerCheckSogliaContantiData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1ConsumersPkConsumerCheckSogliaContantiDataAttributes}
     * @memberof ApiV1ConsumersPkConsumerCheckSogliaContantiData
     */
    'attributes'?: ApiV1ConsumersPkConsumerCheckSogliaContantiDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1ConsumersPkConsumerCheckSogliaContantiDataAttributes
 */
export interface ApiV1ConsumersPkConsumerCheckSogliaContantiDataAttributes {
    /**
     *
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerCheckSogliaContantiDataAttributes
     */
    'consumer'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ConsumersPkConsumerCheckSogliaContantiDataAttributes
     */
    'check_layby'?: number;
}
/**
 *
 * @export
 * @interface ApiV1ConsumersPkConsumerData
 */
export interface ApiV1ConsumersPkConsumerData {
    /**
     * Unique consumer id.
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerData
     */
    'id'?: string;
    /**
     * fixed data type \"consumers\"
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1ConsumersPkConsumerDataAttributes}
     * @memberof ApiV1ConsumersPkConsumerData
     */
    'attributes'?: ApiV1ConsumersPkConsumerDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1ConsumersPkConsumerDataAttributes
 */
export interface ApiV1ConsumersPkConsumerDataAttributes {
    /**
     * anno_nascita
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'anno_nascita'?: string;
    /**
     * anno_nascita_presunto
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'anno_nascita_presunto'?: string;
    /**
     * cap
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'cap'?: string;
    /**
     * cellulare1
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'cellulare1'?: string;
    /**
     * cellulare2
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'cellulare2'?: string;
    /**
     * cod_scheda
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'cod_scheda'?: string;
    /**
     * cognome
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'cognome'?: string;
    /**
     * indirizzo_alfabeto
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'indirizzo_alfabeto'?: string;
    /**
     * nome_alfabeto
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'nome_alfabeto'?: string;
    /**
     * data_firma_scheda
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'data_firma_scheda'?: string;
    /**
     * data_modifica
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'data_modifica'?: string;
    /**
     * data_prima_registrazione
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'data_prima_registrazione'?: string;
    /**
     * data_ultimo_aggiornamento
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'data_ultimo_aggiornamento'?: string;
    /**
     * edificio
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'edificio'?: string;
    /**
     * email
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'email'?: string;
    /**
     * giorno_nascita
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'giorno_nascita'?: string;
    /**
     * grado_anonimato
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'grado_anonimato'?: string;
    /**
     * indirizzo
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'indirizzo'?: string;
    /**
     * interessi. stringified Json array
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'interessi'?: string;
    /**
     * lingua
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'lingua'?: string;
    /**
     * locale
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'locale'?: string;
    /**
     * localita
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'localita'?: string;
    /**
     * mese_nascita
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'mese_nascita'?: string;
    /**
     * nazionalita
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'nazionalita'?: string;
    /**
     * nazione_iso
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'nazione_iso'?: string;
    /**
     * negozio_firma_scheda
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'negozio_firma_scheda'?: string;
    /**
     * nome
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'nome'?: string;
    /**
     * non_contattare
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'non_contattare'?: string;
    /**
     * note
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'note'?: string;
    /**
     * note_indirizzo
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'note_indirizzo'?: string;
    /**
     * note_negozio
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'note_negozio'?: string;
    /**
     * numero_civico
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'numero_civico'?: string;
    /**
     * consumer unique id
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'pk_consumer'?: string;
    /**
     * consumer unique id
     * @type {number}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'pk_crm'?: number;
    /**
     * preferenze_contatto
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'preferenze_contatto'?: string;
    /**
     * professioni. stringified Json array
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'professioni'?: string;
    /**
     * provincia
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'provincia'?: string;
    /**
     * salutation
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'salutation'?: string;
    /**
     * sesso
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'sesso'?: string;
    /**
     * staff
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'staff'?: string;
    /**
     * RDV
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'shop_assistant'?: string;
    /**
     * telefono1
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'telefono1'?: string;
    /**
     * telefono2
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'telefono2'?: string;
    /**
     * vip_1
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'vip_1'?: string;
    /**
     * vip_2
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'vip_2'?: string;
    /**
     * wechat
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'wechat'?: string;
    /**
     * agreement_profiling MANDATORY
     * @type {number}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'agreement_profiling'?: number;
    /**
     * agreement_direct_marketing MANDATORY
     * @type {number}
     * @memberof ApiV1ConsumersPkConsumerDataAttributes
     */
    'agreement_direct_marketing'?: number;
}
/**
 *
 * @export
 * @interface ApiV1ConsumersPkConsumerHistorySchedaData
 */
export interface ApiV1ConsumersPkConsumerHistorySchedaData {
    /**
     *
     * @type {number}
     * @memberof ApiV1ConsumersPkConsumerHistorySchedaData
     */
    'history_limit'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerHistorySchedaData
     */
    'sale_origin'?: string;
}
/**
 *
 * @export
 * @interface ApiV1ConsumersPkConsumerPreferenzeContattoData
 */
export interface ApiV1ConsumersPkConsumerPreferenzeContattoData {
    /**
     * Unique consumer id.
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoData
     */
    'id'?: string;
    /**
     *
     * @type {ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributes}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoData
     */
    'attributes'?: ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributes
 */
export interface ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributes {
    /**
     *
     * @type {ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributes
     */
    'dati_consumer'?: ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer;
}
/**
 *
 * @export
 * @interface ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
 */
export interface ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer {
    /**
     * saluto
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'salutation'?: string;
    /**
     * cognome
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'cognome'?: string;
    /**
     * nome
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'nome'?: string;
    /**
     * lingua
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'lingua'?: string;
    /**
     * nazionalita
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'nazionalita'?: string;
    /**
     * nazione
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'nazione'?: string;
    /**
     * giorno_nascita
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'giorno_nascita'?: string;
    /**
     * mese_nascita
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'mese_nascita'?: string;
    /**
     * anno_nascita
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'anno_nascita'?: string;
    /**
     * eta presunta
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'eta_presunta'?: string;
    /**
     * telefono1
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'telefono1'?: string;
    /**
     * telefono2
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'telefono2'?: string;
    /**
     * cellulare1
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'cellulare1'?: string;
    /**
     * cellulare2
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'cellulare2'?: string;
    /**
     * email
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'email_user'?: string;
    /**
     * email dominio
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'email_dominio'?: string;
    /**
     * edificio
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'edificio'?: string;
    /**
     * note_indirizzo
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'note_indirizzo'?: string;
    /**
     * indirizzo
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'indirizzo'?: string;
    /**
     * numero_civico
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'numero_civico'?: string;
    /**
     * localita
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'localita'?: string;
    /**
     * cap
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'cap'?: string;
    /**
     * provincia
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'provincia'?: string;
    /**
     * note
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'note'?: string;
    /**
     * note_negozio
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'note_negozio'?: string;
    /**
     * sesso
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'sesso'?: string;
    /**
     * interessi. stringified Json array
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'interessi'?: string;
    /**
     * professioni. stringified Json array
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'professioni'?: string;
    /**
     * anno nascita presunto
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'anno_nascita_presunto'?: string;
    /**
     * consumer unique id
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'pk_consumer'?: string;
    /**
     * cod_cassiera
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerPreferenzeContattoDataAttributesDatiConsumer
     */
    'cod_cassiera'?: string;
}
/**
 *
 * @export
 * @interface ApiV1ConsumersPkConsumerSchedaLinguaData
 */
export interface ApiV1ConsumersPkConsumerSchedaLinguaData {
    /**
     *
     * @type {number}
     * @memberof ApiV1ConsumersPkConsumerSchedaLinguaData
     */
    'agreement_profiling'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ConsumersPkConsumerSchedaLinguaData
     */
    'agreement_direct_marketing'?: number;
    /**
     * immagine firma consumatrice in base64
     * @type {string}
     * @memberof ApiV1ConsumersPkConsumerSchedaLinguaData
     */
    'sign_image'?: string;
}
/**
 *
 * @export
 * @interface ApiV1CouponsCouponCodeCheckData
 */
export interface ApiV1CouponsCouponCodeCheckData {
    /**
     *
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1CouponsCouponCodeCheckDataAttributes}
     * @memberof ApiV1CouponsCouponCodeCheckData
     */
    'attributes'?: ApiV1CouponsCouponCodeCheckDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1CouponsCouponCodeCheckDataAttributes
 */
export interface ApiV1CouponsCouponCodeCheckDataAttributes {
    /**
     * pk_consumer
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'pk_consumer'?: string;
    /**
     * sid
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'sid'?: string;
    /**
     * tipo_applicazione_apertura
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'tipo_applicazione_apertura'?: string;
    /**
     * tipo_applicazione_chiusura
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'tipo_applicazione_chiusura'?: string;
    /**
     * cod_cassa
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'cod_cassa'?: string;
    /**
     * cod_cassiera
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'cod_cassiera'?: string;
    /**
     * cod_documento
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'cod_documento'?: string;
    /**
     * cod_negozio
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'cod_negozio'?: string;
    /**
     * cod_vettore
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'cod_vettore'?: string;
    /**
     * codice_movimento
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'codice_movimento'?: string;
    /**
     * codice_stato
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'codice_stato'?: string;
    /**
     * data_creazione
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'data_creazione'?: string;
    /**
     * data_documento
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'data_documento'?: string;
    /**
     * data_modifica
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'data_modifica'?: string;
    /**
     * dati_aggiuntivi
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'dati_aggiuntivi'?: string;
    /**
     * stringified object dati_documenti
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'dati_documenti'?: string;
    /**
     * divisa
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'divisa'?: string;
    /**
     * flag_stampa_documento
     * @type {number}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'flag_stampa_documento'?: number;
    /**
     * id_postazione_apertura
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'id_postazione_apertura'?: string;
    /**
     * id_postazione_chiusura
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'id_postazione_chiusura'?: string;
    /**
     * id_utente
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'id_utente'?: string;
    /**
     * importo_finale
     * @type {number}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'importo_finale'?: number;
    /**
     * importo_iniziale
     * @type {number}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'importo_iniziale'?: number;
    /**
     * importo_pagato
     * @type {number}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'importo_pagato'?: number;
    /**
     * nota
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'nota'?: string;
    /**
     * numero_documento
     * @type {number}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'numero_documento'?: number;
    /**
     * numero_stampa_documento
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'numero_stampa_documento'?: string;
    /**
     * ora_documento
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'ora_documento'?: string;
    /**
     *
     * @type {Array<ApiV1CouponsCouponCodeCheckDataAttributesCapi>}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'capi'?: Array<ApiV1CouponsCouponCodeCheckDataAttributesCapi>;
    /**
     *
     * @type {Array<ApiV1SalesData1AttributesPagamenti>}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributes
     */
    'pagamenti'?: Array<ApiV1SalesData1AttributesPagamenti>;
}
/**
 *
 * @export
 * @interface ApiV1CouponsCouponCodeCheckDataAttributesCapi
 */
export interface ApiV1CouponsCouponCodeCheckDataAttributesCapi {
    /**
     * classe
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'classe'?: string;
    /**
     * cod_commessa
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'cod_commessa'?: string;
    /**
     * cod_negozio
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'cod_negozio'?: string;
    /**
     * codice_movimento
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'codice_movimento'?: string;
    /**
     * codice_stato
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'codice_stato'?: string;
    /**
     * composizione
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'composizione'?: string;
    /**
     * correzione_importo
     * @type {number}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'correzione_importo'?: number;
    /**
     * custom_data
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'custom_data'?: string;
    /**
     * data_creazione
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'data_creazione'?: string;
    /**
     * data_modifica
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'data_modifica'?: string;
    /**
     * ean
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'ean'?: string;
    /**
     * flag_divisa
     * @type {number}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'flag_divisa'?: number;
    /**
     * flag_promo
     * @type {number}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'flag_promo'?: number;
    /**
     * importo_custom
     * @type {number}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'importo_custom'?: number;
    /**
     * importo_finale
     * @type {number}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'importo_finale'?: number;
    /**
     * importo_iniziale
     * @type {number}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'importo_iniziale'?: number;
    /**
     * iva
     * @type {number}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'iva'?: number;
    /**
     * nome
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'nome'?: string;
    /**
     * nota
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'nota'?: string;
    /**
     * progressivo
     * @type {number}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'progressivo'?: number;
    /**
     * sconto
     * @type {number}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'sconto'?: number;
    /**
     * sku
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'sku'?: string;
    /**
     * sku_created
     * @type {number}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'sku_created'?: number;
    /**
     * sku_read
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'sku_read'?: string;
    /**
     * sku_splitted
     * @type {number}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'sku_splitted'?: number;
    /**
     * tipo_importo
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'tipo_importo'?: string;
    /**
     * tipologia_merce
     * @type {string}
     * @memberof ApiV1CouponsCouponCodeCheckDataAttributesCapi
     */
    'tipologia_merce'?: string;
}
/**
 *
 * @export
 * @interface ApiV1GiftCardsActivateSaleIdAttributes
 */
export interface ApiV1GiftCardsActivateSaleIdAttributes {
    /**
     *
     * @type {string}
     * @memberof ApiV1GiftCardsActivateSaleIdAttributes
     */
    'barcode'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1GiftCardsActivateSaleIdAttributes
     */
    'row_num'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1GiftCardsActivateSaleIdAttributes
     */
    'importo'?: number;
}
/**
 *
 * @export
 * @interface ApiV1GiftCardsActivateSaleIdData
 */
export interface ApiV1GiftCardsActivateSaleIdData {
    /**
     * fixed data type \"gift_card\"
     * @type {string}
     * @memberof ApiV1GiftCardsActivateSaleIdData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1GiftCardsActivateSaleIdAttributes}
     * @memberof ApiV1GiftCardsActivateSaleIdData
     */
    'attributes'?: ApiV1GiftCardsActivateSaleIdAttributes;
}
/**
 *
 * @export
 * @interface ApiV1PoswebDocumentTypesActiveData
 */
export interface ApiV1PoswebDocumentTypesActiveData {
    /**
     *
     * @type {ApiV1PoswebDocumentTypesActiveDataAttributes}
     * @memberof ApiV1PoswebDocumentTypesActiveData
     */
    'attributes'?: ApiV1PoswebDocumentTypesActiveDataAttributes;
    /**
     * Type of request
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveData
     */
    'type'?: string;
}
/**
 *
 * @export
 * @interface ApiV1PoswebDocumentTypesActiveDataAttributes
 */
export interface ApiV1PoswebDocumentTypesActiveDataAttributes {
    /**
     * Document type code
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'cod_documento'?: string;
    /**
     * Movement code
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'codice_movimento'?: string;
    /**
     * Document date
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'data_documento'?: string;
    /**
     * Opening application type
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'tipo_applicazione_apertura'?: string;
    /**
     * Date modified in format \"YYYY-MM-DD hh:mm:ss\"
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'data_modifica'?: string;
    /**
     * Further data
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'dati_aggiuntivi'?: string;
    /**
     * Final amount
     * @type {number}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'importo_finale'?: number;
    /**
     * Document data
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'dati_documenti'?: string;
    /**
     * Status Code
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'codice_stato'?: string;
    /**
     * User Id
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'id_utente'?: string;
    /**
     * Document time
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'ora_documento'?: string;
    /**
     * Creation Date
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'data_creazione'?: string;
    /**
     * Notes
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'nota'?: string;
    /**
     * Closing application type
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'tipo_applicazione_chiusura'?: string;
    /**
     * Sid, authentication id
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'sid'?: string;
    /**
     * Currency
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'divisa'?: string;
    /**
     * Paid amount
     * @type {number}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'importo_pagato'?: number;
    /**
     * Consumer primary key
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'pk_consumer'?: string;
    /**
     * Opening station Id
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'id_postazione_apertura'?: string;
    /**
     * Vector code
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'cod_vettore'?: string;
    /**
     * Payments data
     * @type {Array<ApiV1PoswebDocumentTypesActiveDataAttributesPagamenti>}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'pagamenti'?: Array<ApiV1PoswebDocumentTypesActiveDataAttributesPagamenti>;
    /**
     * Document number
     * @type {number}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'numero_documento'?: number;
    /**
     * Document print number
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'numero_stampa_documento'?: string;
    /**
     * Items entities as in MovimentoCapi Class
     * @type {Array<any>}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'capi'?: Array<any>;
    /**
     * Print document flag
     * @type {number}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'flag_stampa_documento'?: number;
    /**
     * Initial amount
     * @type {number}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'importo_iniziale'?: number;
    /**
     * Closing station id
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'id_postazione_chiusura'?: string;
    /**
     * Cashier code
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'cod_cassiera'?: string;
    /**
     * Store code
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'cod_negozio'?: string;
    /**
     * Transaction Id
     * @type {number}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'id_transazione'?: number;
    /**
     * Cash register Id
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributes
     */
    'cod_cassa'?: string;
}
/**
 * Payment
 * @export
 * @interface ApiV1PoswebDocumentTypesActiveDataAttributesPagamenti
 */
export interface ApiV1PoswebDocumentTypesActiveDataAttributesPagamenti {
    /**
     * Transaction code
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributesPagamenti
     */
    'codice_movimento'?: string;
    /**
     * Application type
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributesPagamenti
     */
    'tipo_applicazione'?: string;
    /**
     * Progressivo item
     * @type {number}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributesPagamenti
     */
    'progressivo_capo'?: number;
    /**
     * Barcode
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributesPagamenti
     */
    'barcode'?: string;
    /**
     * Date Modified
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributesPagamenti
     */
    'data_modifica'?: string;
    /**
     * Creation Date
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributesPagamenti
     */
    'data_creazione'?: string;
    /**
     * Station code
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributesPagamenti
     */
    'id_postazione'?: string;
    /**
     * Progressivo
     * @type {number}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributesPagamenti
     */
    'progressivo'?: number;
    /**
     * Notes
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributesPagamenti
     */
    'nota'?: string;
    /**
     * Transaction Id
     * @type {number}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributesPagamenti
     */
    'id_transazione'?: number;
    /**
     * Operation Data
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributesPagamenti
     */
    'dati_operazione'?: string;
    /**
     * Store Code
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributesPagamenti
     */
    'cod_negozio'?: string;
    /**
     * Currency
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributesPagamenti
     */
    'divisa'?: string;
    /**
     * Final amount
     * @type {number}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributesPagamenti
     */
    'importo_finale'?: number;
    /**
     * Status code
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributesPagamenti
     */
    'codice_stato'?: string;
    /**
     * Operation code
     * @type {string}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributesPagamenti
     */
    'cod_operazione'?: string;
    /**
     * Initial amount
     * @type {number}
     * @memberof ApiV1PoswebDocumentTypesActiveDataAttributesPagamenti
     */
    'importo_iniziale'?: number;
}
/**
 *
 * @export
 * @interface ApiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdData
 */
export interface ApiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdData {
    /**
     *
     * @type {string}
     * @memberof ApiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdData
     */
    'type'?: string;
    /**
     * id transazione
     * @type {string}
     * @memberof ApiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdData
     */
    'id'?: string;
    /**
     *
     * @type {ApiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdDataAttributes}
     * @memberof ApiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdData
     */
    'attributes'?: ApiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdDataAttributes
 */
export interface ApiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdDataAttributes {
    /**
     * Amount to be credited
     * @type {number}
     * @memberof ApiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdDataAttributes
     */
    'amount'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdDataAttributes
     */
    'tipo_storno'?: ApiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdDataAttributesTipoStornoEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdDataAttributesTipoStornoEnum {
    Accredito = 'ACCREDITO',
    Storno = 'STORNO'
}

/**
 *
 * @export
 * @interface ApiV1ReintegriData
 */
export interface ApiV1ReintegriData {
    /**
     *
     * @type {string}
     * @memberof ApiV1ReintegriData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1ReintegriDataAttributes}
     * @memberof ApiV1ReintegriData
     */
    'attributes'?: ApiV1ReintegriDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1ReintegriDataAttributes
 */
export interface ApiV1ReintegriDataAttributes {
    /**
     * cashier code
     * @type {string}
     * @memberof ApiV1ReintegriDataAttributes
     */
    'cassiera'?: string;
    /**
     * cashier name
     * @type {string}
     * @memberof ApiV1ReintegriDataAttributes
     */
    'nominativo'?: string;
    /**
     * document type
     * @type {string}
     * @memberof ApiV1ReintegriDataAttributes
     */
    'tipo_doc'?: string;
    /**
     * date and time
     * @type {string}
     * @memberof ApiV1ReintegriDataAttributes
     */
    'date_doc'?: string;
    /**
     * number of document
     * @type {string}
     * @memberof ApiV1ReintegriDataAttributes
     */
    'numero_doc'?: string;
    /**
     * description of petty cash
     * @type {string}
     * @memberof ApiV1ReintegriDataAttributes
     */
    'descrizione'?: string;
    /**
     * total amount
     * @type {string}
     * @memberof ApiV1ReintegriDataAttributes
     */
    'importo'?: string;
}
/**
 *
 * @export
 * @interface ApiV1ReturnData
 */
export interface ApiV1ReturnData {
    /**
     *
     * @type {ApiV1ReturnDataAttributes}
     * @memberof ApiV1ReturnData
     */
    'attributes'?: ApiV1ReturnDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1ReturnData1
 */
export interface ApiV1ReturnData1 {
    /**
     *
     * @type {ApiV1ReturnData1Attributes}
     * @memberof ApiV1ReturnData1
     */
    'attributes'?: ApiV1ReturnData1Attributes;
}
/**
 *
 * @export
 * @interface ApiV1ReturnData1Attributes
 */
export interface ApiV1ReturnData1Attributes {
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1Attributes
     */
    'has_returnable_movements'?: boolean;
    /**
     *
     * @type {Array<ApiV1ReturnData1AttributesPagamenti>}
     * @memberof ApiV1ReturnData1Attributes
     */
    'pagamenti'?: Array<ApiV1ReturnData1AttributesPagamenti>;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1Attributes
     */
    'warning_message'?: string;
    /**
     *
     * @type {Array<ApiV1ReturnData1AttributesCapi>}
     * @memberof ApiV1ReturnData1Attributes
     */
    'capi'?: Array<ApiV1ReturnData1AttributesCapi>;
    /**
     *
     * @type {Array<ApiV1ReturnData1AttributesPagamentiReso>}
     * @memberof ApiV1ReturnData1Attributes
     */
    'pagamenti_reso'?: Array<ApiV1ReturnData1AttributesPagamentiReso>;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1Attributes
     */
    'dati_aggiuntivi'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1Attributes
     */
    'punti_rendibili'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1Attributes
     */
    'cod_documento_reso'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1Attributes
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1Attributes
     */
    'numero_documento'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1Attributes
     */
    'cod_documento'?: string;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1Attributes
     */
    'gst_free'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1Attributes
     */
    'vendita_in_valuta'?: boolean;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1Attributes
     */
    'pk_consumer'?: string;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1Attributes
     */
    'has_returnable_items'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1Attributes
     */
    'reversable'?: boolean;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1Attributes
     */
    'importo_sospeso'?: number;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1Attributes
     */
    'to_return'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1Attributes
     */
    'returnable'?: boolean;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1Attributes
     */
    'data_documento'?: string;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1Attributes
     */
    'returnable_om'?: boolean;
    /**
     *
     * @type {ApiV1ReturnData1AttributesConsumer}
     * @memberof ApiV1ReturnData1Attributes
     */
    'consumer'?: ApiV1ReturnData1AttributesConsumer;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1Attributes
     */
    'dati_documenti'?: string;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1Attributes
     */
    'rendibile_per_intero'?: boolean;
    /**
     *
     * @type {ApiV1ReturnData1AttributesSaleReturnData}
     * @memberof ApiV1ReturnData1Attributes
     */
    'sale_return_data'?: ApiV1ReturnData1AttributesSaleReturnData;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1Attributes
     */
    'cod_cassiera'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1Attributes
     */
    'nazione_oss'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1Attributes
     */
    'importo_rendibile'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1Attributes
     */
    'importo_finale'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1Attributes
     */
    'dati_documenti_reso'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1Attributes
     */
    'id_transazione'?: number;
}
/**
 *
 * @export
 * @interface ApiV1ReturnData1AttributesCapi
 */
export interface ApiV1ReturnData1AttributesCapi {
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'nota'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'flag_promo'?: number;
    /**
     *
     * @type {Array<ApiV1ReturnDataAttributesDettaglioMov>}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'dettaglio_mov'?: Array<ApiV1ReturnDataAttributesDettaglioMov>;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'composizione'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'classe'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'sku_gruppo'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'tipologia_merce'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'prezzo_ricalcolato'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'data_creazione'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'flag_tassabile'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'importo_iniziale'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'reso'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'progressivo'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'tipo_importo'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'sconto_listino_vendita'?: number;
    /**
     *
     * @type {ApiV1ReturnDataAttributesPunti}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'punti'?: ApiV1ReturnDataAttributesPunti;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'rfid'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'codice_movimento'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'custom_data'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'sku_created'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'causale_reso'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'cod_commessa'?: string;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'returnable'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'to_return'?: boolean;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'nome'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'sku_read'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'sconto'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'sku_splitted'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'data_modifica'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'importo_custom'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'codice_stato'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'iva'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'flag_divisa'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'correzione_importo'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'ean'?: string;
    /**
     *
     * @type {Array<ApiV1ReturnDataAttributesListCausaliReso>}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'list_causali_reso'?: Array<ApiV1ReturnDataAttributesListCausaliReso>;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'prezzo_listino_vendita'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'importo_finale'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'id_transazione'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesCapi
     */
    'sku'?: string;
}
/**
 *
 * @export
 * @interface ApiV1ReturnData1AttributesConsumer
 */
export interface ApiV1ReturnData1AttributesConsumer {
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'pk_consumer'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'pk_crm'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'cod_scheda'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'locale'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'stessa_insegna'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'cognome'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'nome'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'sesso'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'lingua'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'giorno_nascita'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'mese_nascita'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'anno_nascita'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'anno_nascita_presunto'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'nazione_iso'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'provincia'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'localita'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'cap'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'indirizzo'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'numero_civico'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'edificio'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'note_indirizzo'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'telefono1'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'telefono2'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'cellulare1'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'cellulare2'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'email'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'wechat'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'preferenze_contatto'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'professioni'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'interessi'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'note'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'note_negozio'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'non_contattare'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'grado_anonimato'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'data_prima_registrazione'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'data_ultimo_aggiornamento'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'data_firma_scheda'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'negozio_firma_scheda'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'data_modifica'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'card_associate'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'consensi'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'canale_firma'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'brand_firma'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'nazionalita'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'salutation'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'indirizzo_alfabeto'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'nome_alfabeto'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'vip_1'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'vip_2'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'staff'?: number;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1AttributesConsumer
     */
    'is_anonima'?: boolean;
}
/**
 *
 * @export
 * @interface ApiV1ReturnData1AttributesPagamenti
 */
export interface ApiV1ReturnData1AttributesPagamenti {
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesPagamenti
     */
    'nota'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesPagamenti
     */
    'cod_operazione'?: string;
    /**
     *
     * @type {Array<ApiV1ReturnDataAttributesDettaglioMov>}
     * @memberof ApiV1ReturnData1AttributesPagamenti
     */
    'dettaglio_mov'?: Array<ApiV1ReturnDataAttributesDettaglioMov>;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1AttributesPagamenti
     */
    'standalone'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1AttributesPagamenti
     */
    'is_pagamento'?: boolean;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesPagamenti
     */
    'prezzo_ricalcolato'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesPagamenti
     */
    'data_creazione'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesPagamenti
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesPagamenti
     */
    'importo_iniziale'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesPagamenti
     */
    'reso'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesPagamenti
     */
    'dati_operazione'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesPagamenti
     */
    'progressivo'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesPagamenti
     */
    'tipo_applicazione'?: string;
    /**
     *
     * @type {ApiV1ReturnDataAttributesPunti}
     * @memberof ApiV1ReturnData1AttributesPagamenti
     */
    'punti'?: ApiV1ReturnDataAttributesPunti;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesPagamenti
     */
    'codice_movimento'?: string;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1AttributesPagamenti
     */
    'to_return'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1AttributesPagamenti
     */
    'returnable'?: boolean;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesPagamenti
     */
    'data_modifica'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesPagamenti
     */
    'codice_stato'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesPagamenti
     */
    'barcode'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesPagamenti
     */
    'divisa'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesPagamenti
     */
    'progressivo_capo'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesPagamenti
     */
    'id_postazione'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesPagamenti
     */
    'importo_finale'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesPagamenti
     */
    'id_transazione'?: number;
}
/**
 *
 * @export
 * @interface ApiV1ReturnData1AttributesPagamentiReso
 */
export interface ApiV1ReturnData1AttributesPagamentiReso {
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesPagamentiReso
     */
    'nota'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesPagamentiReso
     */
    'cod_operazione'?: string;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1AttributesPagamentiReso
     */
    'standalone'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1AttributesPagamentiReso
     */
    'is_pagamento'?: boolean;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesPagamentiReso
     */
    'prezzo_ricalcolato'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesPagamentiReso
     */
    'data_creazione'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesPagamentiReso
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesPagamentiReso
     */
    'importo_iniziale'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesPagamentiReso
     */
    'reso'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesPagamentiReso
     */
    'dati_operazione'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesPagamentiReso
     */
    'progressivo'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesPagamentiReso
     */
    'tipo_applicazione'?: string;
    /**
     *
     * @type {ApiV1ReturnDataAttributesPunti}
     * @memberof ApiV1ReturnData1AttributesPagamentiReso
     */
    'punti'?: ApiV1ReturnDataAttributesPunti;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesPagamentiReso
     */
    'codice_movimento'?: string;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1AttributesPagamentiReso
     */
    'to_return'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1AttributesPagamentiReso
     */
    'returnable'?: boolean;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesPagamentiReso
     */
    'data_modifica'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesPagamentiReso
     */
    'codice_stato'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesPagamentiReso
     */
    'barcode'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesPagamentiReso
     */
    'divisa'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesPagamentiReso
     */
    'progressivo_capo'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesPagamentiReso
     */
    'id_postazione'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesPagamentiReso
     */
    'importo_finale'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesPagamentiReso
     */
    'id_transazione'?: number;
}
/**
 *
 * @export
 * @interface ApiV1ReturnData1AttributesSaleReturnData
 */
export interface ApiV1ReturnData1AttributesSaleReturnData {
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'id_transazione'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'anagrafica_cassiera'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'anagrafica_consumatrice'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'note'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'righe_visibili'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'vendita_totale'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'flag_utilizzo_sconto'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'flag_utilizzo_abbuono'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'flag_utilizzo_gst_free'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'promozioni'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'totaleProdottoFinito'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'totaleTaxFreeJP'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'subtotaleTaxFreeJP'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'totaleSartoriaModelli'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'subtotaleSartoriaModelli'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'totaleSartoriaNoModello'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'subtotaleSartoriaNoModello'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'totaleCoupon'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'subtotaleCoupon'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'totaleSconto'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'subtotaleSconto'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'totalePromozioni'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'subtotalePromozioni'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'totaleAbbuono'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'subtotaleAbbuono'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'totaleCostiExtra'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'subtotaleCostiExtra'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'totaleSpedizione'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'subtotaleSpedizione'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'totaleGiftCard'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'subtotaleGiftCard'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'totaleTessuti'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'subtotaleTessuti'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'totaleAliquota'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'subtotaleAliquota'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'totaleImposte'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'subtotaleImposte'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'totaleShoppingBags'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'subtotaleShoppingBags'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'totaleVendita'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'disabilitaFatture'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'resto'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'dati_storno'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'totale_vendita'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'totale_abbuono'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'totale_sconto'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'flag_b2c'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'finalize'?: string;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'solaSartoria'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'solaSartoriaOmaggio'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'venditaFuoriNegozio'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'venditaMista'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'soliCostiExtra'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'soleGiftCard'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'abilitaTaxfree'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'soloOmaggi'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'soleContabilitaExtra'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'forza_spesometro'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'stornoPagamentoGiftCard'?: boolean;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnData1AttributesSaleReturnData
     */
    'dati_extra_chiusura_reso'?: string;
}
/**
 *
 * @export
 * @interface ApiV1ReturnDataAttributes
 */
export interface ApiV1ReturnDataAttributes {
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnDataAttributes
     */
    'has_returnable_movements'?: boolean;
    /**
     *
     * @type {Array<ApiV1ReturnDataAttributesPagamenti>}
     * @memberof ApiV1ReturnDataAttributes
     */
    'pagamenti'?: Array<ApiV1ReturnDataAttributesPagamenti>;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributes
     */
    'warning_message'?: string;
    /**
     *
     * @type {Array<ApiV1ReturnDataAttributesCapi>}
     * @memberof ApiV1ReturnDataAttributes
     */
    'capi'?: Array<ApiV1ReturnDataAttributesCapi>;
    /**
     *
     * @type {Array<object>}
     * @memberof ApiV1ReturnDataAttributes
     */
    'pagamenti_reso'?: Array<object>;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributes
     */
    'dati_aggiuntivi'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributes
     */
    'punti_rendibili'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributes
     */
    'cod_documento_reso'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributes
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributes
     */
    'numero_documento'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributes
     */
    'cod_documento'?: string;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnDataAttributes
     */
    'gst_free'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnDataAttributes
     */
    'vendita_in_valuta'?: boolean;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributes
     */
    'pk_consumer'?: string;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnDataAttributes
     */
    'has_returnable_items'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnDataAttributes
     */
    'reversable'?: boolean;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributes
     */
    'importo_sospeso'?: number;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnDataAttributes
     */
    'to_return'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnDataAttributes
     */
    'returnable'?: boolean;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributes
     */
    'data_documento'?: string;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnDataAttributes
     */
    'returnable_om'?: boolean;
    /**
     *
     * @type {ApiV1ReturnDataAttributesConsumer}
     * @memberof ApiV1ReturnDataAttributes
     */
    'consumer'?: ApiV1ReturnDataAttributesConsumer;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributes
     */
    'dati_documenti'?: string;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnDataAttributes
     */
    'rendibile_per_intero'?: boolean;
    /**
     *
     * @type {object}
     * @memberof ApiV1ReturnDataAttributes
     */
    'sale_return_data'?: object;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributes
     */
    'cod_cassiera'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributes
     */
    'nazione_oss'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributes
     */
    'importo_rendibile'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributes
     */
    'importo_finale'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributes
     */
    'dati_documenti_reso'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributes
     */
    'id_transazione'?: number;
}
/**
 *
 * @export
 * @interface ApiV1ReturnDataAttributesCapi
 */
export interface ApiV1ReturnDataAttributesCapi {
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'nota'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'flag_promo'?: number;
    /**
     *
     * @type {Array<ApiV1ReturnDataAttributesDettaglioMov1>}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'dettaglio_mov'?: Array<ApiV1ReturnDataAttributesDettaglioMov1>;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'composizione'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'classe'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'sku_gruppo'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'tipologia_merce'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'prezzo_ricalcolato'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'data_creazione'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'flag_tassabile'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'importo_iniziale'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'reso'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'progressivo'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'tipo_importo'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'sconto_listino_vendita'?: number;
    /**
     *
     * @type {ApiV1ReturnDataAttributesPunti}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'punti'?: ApiV1ReturnDataAttributesPunti;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'rfid'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'codice_movimento'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'custom_data'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'sku_created'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'causale_reso'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'cod_commessa'?: string;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'returnable'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'to_return'?: boolean;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'nome'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'sku_read'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'sconto'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'sku_splitted'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'data_modifica'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'importo_custom'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'codice_stato'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'iva'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'flag_divisa'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'correzione_importo'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'ean'?: string;
    /**
     *
     * @type {Array<ApiV1ReturnDataAttributesListCausaliReso>}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'list_causali_reso'?: Array<ApiV1ReturnDataAttributesListCausaliReso>;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'prezzo_listino_vendita'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'importo_finale'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'id_transazione'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesCapi
     */
    'sku'?: string;
}
/**
 *
 * @export
 * @interface ApiV1ReturnDataAttributesConsumer
 */
export interface ApiV1ReturnDataAttributesConsumer {
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesConsumer
     */
    'nome'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesConsumer
     */
    'cognome'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesConsumer
     */
    'grado_anonimato'?: number;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnDataAttributesConsumer
     */
    'is_anonima'?: boolean;
}
/**
 *
 * @export
 * @interface ApiV1ReturnDataAttributesDettaglioMov
 */
export interface ApiV1ReturnDataAttributesDettaglioMov {
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesDettaglioMov
     */
    'cod_mov'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesDettaglioMov
     */
    'tot'?: number;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnDataAttributesDettaglioMov
     */
    'su_capo'?: boolean;
}
/**
 *
 * @export
 * @interface ApiV1ReturnDataAttributesDettaglioMov1
 */
export interface ApiV1ReturnDataAttributesDettaglioMov1 {
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesDettaglioMov1
     */
    'cod_mov'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesDettaglioMov1
     */
    'tot'?: number;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnDataAttributesDettaglioMov1
     */
    'su_capo'?: boolean;
}
/**
 *
 * @export
 * @interface ApiV1ReturnDataAttributesListCausaliReso
 */
export interface ApiV1ReturnDataAttributesListCausaliReso {
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesListCausaliReso
     */
    'val'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesListCausaliReso
     */
    'text'?: string;
}
/**
 *
 * @export
 * @interface ApiV1ReturnDataAttributesPagamenti
 */
export interface ApiV1ReturnDataAttributesPagamenti {
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesPagamenti
     */
    'nota'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesPagamenti
     */
    'cod_operazione'?: string;
    /**
     *
     * @type {Array<ApiV1ReturnDataAttributesDettaglioMov>}
     * @memberof ApiV1ReturnDataAttributesPagamenti
     */
    'dettaglio_mov'?: Array<ApiV1ReturnDataAttributesDettaglioMov>;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnDataAttributesPagamenti
     */
    'standalone'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnDataAttributesPagamenti
     */
    'is_pagamento'?: boolean;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesPagamenti
     */
    'prezzo_ricalcolato'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesPagamenti
     */
    'data_creazione'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesPagamenti
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesPagamenti
     */
    'importo_iniziale'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesPagamenti
     */
    'reso'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesPagamenti
     */
    'dati_operazione'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesPagamenti
     */
    'progressivo'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesPagamenti
     */
    'tipo_applicazione'?: string;
    /**
     *
     * @type {ApiV1ReturnDataAttributesPunti}
     * @memberof ApiV1ReturnDataAttributesPagamenti
     */
    'punti'?: ApiV1ReturnDataAttributesPunti;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesPagamenti
     */
    'codice_movimento'?: string;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnDataAttributesPagamenti
     */
    'to_return'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnDataAttributesPagamenti
     */
    'returnable'?: boolean;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesPagamenti
     */
    'data_modifica'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesPagamenti
     */
    'codice_stato'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesPagamenti
     */
    'barcode'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesPagamenti
     */
    'divisa'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesPagamenti
     */
    'progressivo_capo'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesPagamenti
     */
    'id_postazione'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesPagamenti
     */
    'importo_finale'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnDataAttributesPagamenti
     */
    'id_transazione'?: number;
}
/**
 *
 * @export
 * @interface ApiV1ReturnDataAttributesPunti
 */
export interface ApiV1ReturnDataAttributesPunti {
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnDataAttributesPunti
     */
    'todo'?: string;
}
/**
 *
 * @export
 * @interface ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeData
 */
export interface ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeData {
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeData
     */
    'type'?: string;
    /**
     * Unique sales id, if id_transaction is 0 it means new transaction
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeData
     */
    'id'?: string;
    /**
     *
     * @type {ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeData
     */
    'attributes'?: ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
 */
export interface ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes {
    /**
     * Amount to be restored in gift card
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'importo'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'row_num'?: number;
    /**
     * gift card code
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'barcode'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'id_transazione'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'is_store_credit'?: number;
    /**
     *
     * @type {Array<ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi>}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'capi'?: Array<ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi>;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'cod_cassa'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'cod_cassiera'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'cod_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'cod_vettore'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'codice_movimento'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'codice_stato'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'data_creazione'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'data_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'data_modifica'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'dati_aggiuntivi'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'dati_documenti'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'divisa'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'flag_stampa_documento'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'id_postazione'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'id_utente'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'importo_finale'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'importo_iniziale'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'nota'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'numero_documento'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'numero_stampa_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'ora_documento'?: string;
    /**
     *
     * @type {Array<object>}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'pagamenti'?: Array<object>;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'pk_consumer'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'tipo_applicazione'?: string;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'to_return'?: boolean;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'dati_documenti_reso'?: string;
    /**
     *
     * @type {Array<object>}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'pagamenti_reso'?: Array<object>;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'punti_rendibili'?: number;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'rendibile_per_intero'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'gst_free'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'returnable_om'?: boolean;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'importo_sospeso'?: number;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'has_returnable_movements'?: boolean;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'cod_documento_reso'?: string;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'returnable'?: boolean;
    /**
     *
     * @type {ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesConsumer}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'consumer'?: ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesConsumer;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'importo_rendibile'?: number;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'vendita_in_valuta'?: boolean;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'nazione_oss'?: string;
    /**
     *
     * @type {object}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'sale_return_data'?: object;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'has_returnable_items'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'reversable'?: boolean;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'warning_message'?: string;
}
/**
 *
 * @export
 * @interface ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
 */
export interface ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi {
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'id_transazione'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'progressivo'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'codice_stato'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'codice_movimento'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'cod_commessa'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'sku'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'ean'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'rfid'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'sku_gruppo'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'flag_promo'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'flag_divisa'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'sku_created'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'sku_splitted'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'sku_read'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'nome'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'classe'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'iva'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'composizione'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'custom_data'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'nota'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'prezzo_listino_vendita'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'sconto_listino_vendita'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'importo_iniziale'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'importo_custom'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'sconto'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'correzione_importo'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'importo_finale'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'tipo_importo'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'tipologia_merce'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'reso'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'causale_reso'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'data_creazione'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'data_modifica'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'flag_tassabile'?: number;
}
/**
 *
 * @export
 * @interface ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesConsumer
 */
export interface ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesConsumer {
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesConsumer
     */
    'nome'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesConsumer
     */
    'cognome'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesConsumer
     */
    'grado_anonimato'?: number;
    /**
     *
     * @type {boolean}
     * @memberof ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeDataAttributesConsumer
     */
    'is_anonima'?: boolean;
}
/**
 *
 * @export
 * @interface ApiV1ReturnsSaleIdPaymentsPosPosTransactionIdData
 */
export interface ApiV1ReturnsSaleIdPaymentsPosPosTransactionIdData {
    /**
     *
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsPosPosTransactionIdData
     */
    'type'?: string;
    /**
     * Client-generated id of the payment
     * @type {string}
     * @memberof ApiV1ReturnsSaleIdPaymentsPosPosTransactionIdData
     */
    'id'?: string;
    /**
     *
     * @type {ApiV1ReturnsSaleIdPaymentsPosPosTransactionIdDataAttributes}
     * @memberof ApiV1ReturnsSaleIdPaymentsPosPosTransactionIdData
     */
    'attributes'?: ApiV1ReturnsSaleIdPaymentsPosPosTransactionIdDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1ReturnsSaleIdPaymentsPosPosTransactionIdDataAttributes
 */
export interface ApiV1ReturnsSaleIdPaymentsPosPosTransactionIdDataAttributes {
    /**
     * Amount to be credited
     * @type {number}
     * @memberof ApiV1ReturnsSaleIdPaymentsPosPosTransactionIdDataAttributes
     */
    'amount'?: number;
}
/**
 *
 * @export
 * @interface ApiV1SalesCheckDatiAntiriciclaggioData
 */
export interface ApiV1SalesCheckDatiAntiriciclaggioData {
    /**
     * Type of node
     * @type {string}
     * @memberof ApiV1SalesCheckDatiAntiriciclaggioData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1SalesCheckDatiAntiriciclaggioDataAttributes}
     * @memberof ApiV1SalesCheckDatiAntiriciclaggioData
     */
    'attributes'?: ApiV1SalesCheckDatiAntiriciclaggioDataAttributes;
}
/**
 * Details
 * @export
 * @interface ApiV1SalesCheckDatiAntiriciclaggioDataAttributes
 */
export interface ApiV1SalesCheckDatiAntiriciclaggioDataAttributes {
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesCheckDatiAntiriciclaggioDataAttributes
     */
    'surname'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesCheckDatiAntiriciclaggioDataAttributes
     */
    'name'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesCheckDatiAntiriciclaggioDataAttributes
     */
    'birth_city'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesCheckDatiAntiriciclaggioDataAttributes
     */
    'birth_country'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesCheckDatiAntiriciclaggioDataAttributes
     */
    'birth_date'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesCheckDatiAntiriciclaggioDataAttributes
     */
    'residence_city'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesCheckDatiAntiriciclaggioDataAttributes
     */
    'residence_country'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesCheckDatiAntiriciclaggioDataAttributes
     */
    'passport_number'?: string;
    /**
     * boolean 0 or 1
     * @type {number}
     * @memberof ApiV1SalesCheckDatiAntiriciclaggioDataAttributes
     */
    'procedure_confirm'?: number;
}
/**
 *
 * @export
 * @interface ApiV1SalesData
 */
export interface ApiV1SalesData {
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesData
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesData
     */
    'id'?: string;
    /**
     *
     * @type {ApiV1SalesDataAttributes}
     * @memberof ApiV1SalesData
     */
    'attributes'?: ApiV1SalesDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1SalesData1
 */
export interface ApiV1SalesData1 {
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesData1
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1SalesData1Attributes}
     * @memberof ApiV1SalesData1
     */
    'attributes'?: ApiV1SalesData1Attributes;
}
/**
 *
 * @export
 * @interface ApiV1SalesData1Attributes
 */
export interface ApiV1SalesData1Attributes {
    /**
     * pk_consumer
     * @type {string}
     * @memberof ApiV1SalesData1Attributes
     */
    'pk_consumer'?: string;
    /**
     * sid
     * @type {string}
     * @memberof ApiV1SalesData1Attributes
     */
    'sid'?: string;
    /**
     * tipo_applicazione_apertura
     * @type {string}
     * @memberof ApiV1SalesData1Attributes
     */
    'tipo_applicazione_apertura'?: string;
    /**
     * tipo_applicazione_chiusura
     * @type {string}
     * @memberof ApiV1SalesData1Attributes
     */
    'tipo_applicazione_chiusura'?: string;
    /**
     * cod_cassa
     * @type {string}
     * @memberof ApiV1SalesData1Attributes
     */
    'cod_cassa'?: string;
    /**
     * cod_cassiera
     * @type {string}
     * @memberof ApiV1SalesData1Attributes
     */
    'cod_cassiera'?: string;
    /**
     * cod_documento
     * @type {string}
     * @memberof ApiV1SalesData1Attributes
     */
    'cod_documento'?: string;
    /**
     * cod_negozio
     * @type {string}
     * @memberof ApiV1SalesData1Attributes
     */
    'cod_negozio'?: string;
    /**
     * cod_vettore
     * @type {string}
     * @memberof ApiV1SalesData1Attributes
     */
    'cod_vettore'?: string;
    /**
     * codice_movimento
     * @type {string}
     * @memberof ApiV1SalesData1Attributes
     */
    'codice_movimento'?: string;
    /**
     * codice_stato
     * @type {string}
     * @memberof ApiV1SalesData1Attributes
     */
    'codice_stato'?: string;
    /**
     * data_creazione
     * @type {string}
     * @memberof ApiV1SalesData1Attributes
     */
    'data_creazione'?: string;
    /**
     * data_documento
     * @type {string}
     * @memberof ApiV1SalesData1Attributes
     */
    'data_documento'?: string;
    /**
     * data_modifica
     * @type {string}
     * @memberof ApiV1SalesData1Attributes
     */
    'data_modifica'?: string;
    /**
     * dati_aggiuntivi
     * @type {string}
     * @memberof ApiV1SalesData1Attributes
     */
    'dati_aggiuntivi'?: string;
    /**
     * stringified object dati_documenti
     * @type {string}
     * @memberof ApiV1SalesData1Attributes
     */
    'dati_documenti'?: string;
    /**
     * divisa
     * @type {string}
     * @memberof ApiV1SalesData1Attributes
     */
    'divisa'?: string;
    /**
     * flag_stampa_documento
     * @type {number}
     * @memberof ApiV1SalesData1Attributes
     */
    'flag_stampa_documento'?: number;
    /**
     * id_postazione_apertura
     * @type {string}
     * @memberof ApiV1SalesData1Attributes
     */
    'id_postazione_apertura'?: string;
    /**
     * id_postazione_chiusura
     * @type {string}
     * @memberof ApiV1SalesData1Attributes
     */
    'id_postazione_chiusura'?: string;
    /**
     * id_utente
     * @type {string}
     * @memberof ApiV1SalesData1Attributes
     */
    'id_utente'?: string;
    /**
     * importo_finale
     * @type {number}
     * @memberof ApiV1SalesData1Attributes
     */
    'importo_finale'?: number;
    /**
     * importo_iniziale
     * @type {number}
     * @memberof ApiV1SalesData1Attributes
     */
    'importo_iniziale'?: number;
    /**
     * importo_pagato
     * @type {number}
     * @memberof ApiV1SalesData1Attributes
     */
    'importo_pagato'?: number;
    /**
     * nota
     * @type {string}
     * @memberof ApiV1SalesData1Attributes
     */
    'nota'?: string;
    /**
     * numero_documento
     * @type {number}
     * @memberof ApiV1SalesData1Attributes
     */
    'numero_documento'?: number;
    /**
     * numero_stampa_documento
     * @type {string}
     * @memberof ApiV1SalesData1Attributes
     */
    'numero_stampa_documento'?: string;
    /**
     * ora_documento
     * @type {string}
     * @memberof ApiV1SalesData1Attributes
     */
    'ora_documento'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesData1Attributes
     */
    'flag_stampa_scontrino_cortesia'?: number;
    /**
     *
     * @type {Array<ApiV1SalesData1AttributesCapi>}
     * @memberof ApiV1SalesData1Attributes
     */
    'capi'?: Array<ApiV1SalesData1AttributesCapi>;
    /**
     *
     * @type {Array<ApiV1SalesData1AttributesPagamenti>}
     * @memberof ApiV1SalesData1Attributes
     */
    'pagamenti'?: Array<ApiV1SalesData1AttributesPagamenti>;
    /**
     *
     * @type {ApiV1SalesData1AttributesLayby}
     * @memberof ApiV1SalesData1Attributes
     */
    'layby'?: ApiV1SalesData1AttributesLayby;
}
/**
 *
 * @export
 * @interface ApiV1SalesData1AttributesCapi
 */
export interface ApiV1SalesData1AttributesCapi {
    /**
     * classe
     * @type {string}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'classe'?: string;
    /**
     * cod_commessa
     * @type {string}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'cod_commessa'?: string;
    /**
     * cod_negozio
     * @type {string}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'cod_negozio'?: string;
    /**
     * codice_movimento
     * @type {string}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'codice_movimento'?: string;
    /**
     * codice_stato
     * @type {string}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'codice_stato'?: string;
    /**
     *
     * @type {GetSkuDataAttributesComposizione}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'composizione'?: GetSkuDataAttributesComposizione;
    /**
     * correzione_importo
     * @type {number}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'correzione_importo'?: number;
    /**
     * custom_data
     * @type {string}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'custom_data'?: string;
    /**
     * data_creazione
     * @type {string}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'data_creazione'?: string;
    /**
     * data_modifica
     * @type {string}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'data_modifica'?: string;
    /**
     * ean
     * @type {string}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'ean'?: string;
    /**
     * flag_divisa
     * @type {number}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'flag_divisa'?: number;
    /**
     * flag_promo
     * @type {number}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'flag_promo'?: number;
    /**
     * importo_custom
     * @type {number}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'importo_custom'?: number;
    /**
     * importo_finale
     * @type {number}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'importo_finale'?: number;
    /**
     * importo_iniziale
     * @type {number}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'importo_iniziale'?: number;
    /**
     * iva
     * @type {number}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'iva'?: number;
    /**
     * nome
     * @type {string}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'nome'?: string;
    /**
     * nota
     * @type {string}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'nota'?: string;
    /**
     * progressivo
     * @type {number}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'progressivo'?: number;
    /**
     * sconto
     * @type {number}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'sconto'?: number;
    /**
     * sku
     * @type {string}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'sku'?: string;
    /**
     * sku_created
     * @type {number}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'sku_created'?: number;
    /**
     * sku_read
     * @type {string}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'sku_read'?: string;
    /**
     * sku_splitted
     * @type {number}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'sku_splitted'?: number;
    /**
     * rfid
     * @type {string}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'rfid'?: string;
    /**
     * sku_gruppo
     * @type {string}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'sku_gruppo'?: string;
    /**
     * tipo_importo
     * @type {string}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'tipo_importo'?: string;
    /**
     * tipologia_merce
     * @type {string}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'tipologia_merce'?: string;
    /**
     * prezzo_listino_vendita
     * @type {number}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'prezzo_listino_vendita'?: number;
    /**
     * sconto_listino_vendita (solo capi created)
     * @type {number}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'sconto_listino_vendita'?: number;
    /**
     * empty string if not reso
     * @type {string}
     * @memberof ApiV1SalesData1AttributesCapi
     */
    'causale_reso'?: string;
}
/**
 *
 * @export
 * @interface ApiV1SalesData1AttributesLayby
 */
export interface ApiV1SalesData1AttributesLayby {
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesData1AttributesLayby
     */
    'totale'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesData1AttributesLayby
     */
    'acconto_minimo'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesData1AttributesLayby
     */
    'cancellato'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesData1AttributesLayby
     */
    'importo_pagato'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesData1AttributesLayby
     */
    'prossimo_pagamento'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesData1AttributesLayby
     */
    'id_transazione'?: number;
}
/**
 *
 * @export
 * @interface ApiV1SalesData1AttributesPagamenti
 */
export interface ApiV1SalesData1AttributesPagamenti {
    /**
     * barcode
     * @type {string}
     * @memberof ApiV1SalesData1AttributesPagamenti
     */
    'barcode'?: string;
    /**
     * cod_negozio
     * @type {string}
     * @memberof ApiV1SalesData1AttributesPagamenti
     */
    'cod_negozio'?: string;
    /**
     * cod_operazione
     * @type {string}
     * @memberof ApiV1SalesData1AttributesPagamenti
     */
    'cod_operazione'?: string;
    /**
     * codice_movimento
     * @type {string}
     * @memberof ApiV1SalesData1AttributesPagamenti
     */
    'codice_movimento'?: string;
    /**
     * codice_stato
     * @type {string}
     * @memberof ApiV1SalesData1AttributesPagamenti
     */
    'codice_stato'?: string;
    /**
     * data_creazione
     * @type {string}
     * @memberof ApiV1SalesData1AttributesPagamenti
     */
    'data_creazione'?: string;
    /**
     * data_modifica
     * @type {string}
     * @memberof ApiV1SalesData1AttributesPagamenti
     */
    'data_modifica'?: string;
    /**
     * dati_operazione
     * @type {string}
     * @memberof ApiV1SalesData1AttributesPagamenti
     */
    'dati_operazione'?: string;
    /**
     * divisa
     * @type {string}
     * @memberof ApiV1SalesData1AttributesPagamenti
     */
    'divisa'?: string;
    /**
     * id_postazione
     * @type {string}
     * @memberof ApiV1SalesData1AttributesPagamenti
     */
    'id_postazione'?: string;
    /**
     * importo_finale
     * @type {number}
     * @memberof ApiV1SalesData1AttributesPagamenti
     */
    'importo_finale'?: number;
    /**
     * importo_iniziale
     * @type {number}
     * @memberof ApiV1SalesData1AttributesPagamenti
     */
    'importo_iniziale'?: number;
    /**
     * nota
     * @type {string}
     * @memberof ApiV1SalesData1AttributesPagamenti
     */
    'nota'?: string;
    /**
     * progressivo
     * @type {number}
     * @memberof ApiV1SalesData1AttributesPagamenti
     */
    'progressivo'?: number;
    /**
     * progressivo_capo
     * @type {number}
     * @memberof ApiV1SalesData1AttributesPagamenti
     */
    'progressivo_capo'?: number;
    /**
     * tipo_applicazione
     * @type {string}
     * @memberof ApiV1SalesData1AttributesPagamenti
     */
    'tipo_applicazione'?: string;
}
/**
 *
 * @export
 * @interface ApiV1SalesDataAttributes
 */
export interface ApiV1SalesDataAttributes {
    /**
     * sale counter
     * @type {number}
     * @memberof ApiV1SalesDataAttributes
     */
    'id_transazione'?: number;
    /**
     * pk_consumer
     * @type {string}
     * @memberof ApiV1SalesDataAttributes
     */
    'pk_consumer'?: string;
    /**
     * sid
     * @type {string}
     * @memberof ApiV1SalesDataAttributes
     */
    'sid'?: string;
    /**
     * tipo_applicazione_apertura
     * @type {string}
     * @memberof ApiV1SalesDataAttributes
     */
    'tipo_applicazione_apertura'?: string;
    /**
     * tipo_applicazione_chiusura
     * @type {string}
     * @memberof ApiV1SalesDataAttributes
     */
    'tipo_applicazione_chiusura'?: string;
    /**
     * cod_cassa
     * @type {string}
     * @memberof ApiV1SalesDataAttributes
     */
    'cod_cassa'?: string;
    /**
     * cod_cassiera
     * @type {string}
     * @memberof ApiV1SalesDataAttributes
     */
    'cod_cassiera'?: string;
    /**
     * cod_documento
     * @type {string}
     * @memberof ApiV1SalesDataAttributes
     */
    'cod_documento'?: string;
    /**
     * cod_negozio
     * @type {string}
     * @memberof ApiV1SalesDataAttributes
     */
    'cod_negozio'?: string;
    /**
     * cod_vettore
     * @type {string}
     * @memberof ApiV1SalesDataAttributes
     */
    'cod_vettore'?: string;
    /**
     * codice_movimento
     * @type {string}
     * @memberof ApiV1SalesDataAttributes
     */
    'codice_movimento'?: string;
    /**
     * codice_stato
     * @type {string}
     * @memberof ApiV1SalesDataAttributes
     */
    'codice_stato'?: string;
    /**
     * data_creazione
     * @type {string}
     * @memberof ApiV1SalesDataAttributes
     */
    'data_creazione'?: string;
    /**
     * data_documento
     * @type {string}
     * @memberof ApiV1SalesDataAttributes
     */
    'data_documento'?: string;
    /**
     * data_modifica
     * @type {string}
     * @memberof ApiV1SalesDataAttributes
     */
    'data_modifica'?: string;
    /**
     * dati_aggiuntivi
     * @type {string}
     * @memberof ApiV1SalesDataAttributes
     */
    'dati_aggiuntivi'?: string;
    /**
     * stringified object dati_documenti
     * @type {string}
     * @memberof ApiV1SalesDataAttributes
     */
    'dati_documenti'?: string;
    /**
     * divisa
     * @type {string}
     * @memberof ApiV1SalesDataAttributes
     */
    'divisa'?: string;
    /**
     * flag_stampa_documento
     * @type {number}
     * @memberof ApiV1SalesDataAttributes
     */
    'flag_stampa_documento'?: number;
    /**
     * id_postazione_apertura
     * @type {string}
     * @memberof ApiV1SalesDataAttributes
     */
    'id_postazione_apertura'?: string;
    /**
     * id_postazione_chiusura
     * @type {string}
     * @memberof ApiV1SalesDataAttributes
     */
    'id_postazione_chiusura'?: string;
    /**
     * id_utente
     * @type {string}
     * @memberof ApiV1SalesDataAttributes
     */
    'id_utente'?: string;
    /**
     * importo_finale
     * @type {number}
     * @memberof ApiV1SalesDataAttributes
     */
    'importo_finale'?: number;
    /**
     * importo_iniziale
     * @type {number}
     * @memberof ApiV1SalesDataAttributes
     */
    'importo_iniziale'?: number;
    /**
     * importo_pagato
     * @type {number}
     * @memberof ApiV1SalesDataAttributes
     */
    'importo_pagato'?: number;
    /**
     * nota
     * @type {string}
     * @memberof ApiV1SalesDataAttributes
     */
    'nota'?: string;
    /**
     * numero_documento
     * @type {number}
     * @memberof ApiV1SalesDataAttributes
     */
    'numero_documento'?: number;
    /**
     * om_num_order
     * @type {string}
     * @memberof ApiV1SalesDataAttributes
     */
    'om_num_order'?: string;
    /**
     * numero_stampa_documento
     * @type {string}
     * @memberof ApiV1SalesDataAttributes
     */
    'numero_stampa_documento'?: string;
    /**
     * ora_documento
     * @type {string}
     * @memberof ApiV1SalesDataAttributes
     */
    'ora_documento'?: string;
    /**
     *
     * @type {Array<ApiV1SalesDataAttributesCapi>}
     * @memberof ApiV1SalesDataAttributes
     */
    'capi'?: Array<ApiV1SalesDataAttributesCapi>;
    /**
     *
     * @type {Array<ApiV1SalesDataAttributesPagamenti>}
     * @memberof ApiV1SalesDataAttributes
     */
    'pagamenti'?: Array<ApiV1SalesDataAttributesPagamenti>;
}
/**
 *
 * @export
 * @interface ApiV1SalesDataAttributesCapi
 */
export interface ApiV1SalesDataAttributesCapi {
    /**
     * classe
     * @type {string}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'classe'?: string;
    /**
     * sale counter
     * @type {number}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'id_transazione'?: number;
    /**
     * cod_commessa
     * @type {string}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'cod_commessa'?: string;
    /**
     * cod_negozio
     * @type {string}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'cod_negozio'?: string;
    /**
     * codice_movimento
     * @type {string}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'codice_movimento'?: string;
    /**
     * codice_stato
     * @type {string}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'codice_stato'?: string;
    /**
     *
     * @type {GetSkuDataAttributesComposizione}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'composizione'?: GetSkuDataAttributesComposizione;
    /**
     * correzione_importo
     * @type {number}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'correzione_importo'?: number;
    /**
     * custom_data
     * @type {string}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'custom_data'?: string;
    /**
     * data_creazione
     * @type {string}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'data_creazione'?: string;
    /**
     * data_modifica
     * @type {string}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'data_modifica'?: string;
    /**
     * ean
     * @type {string}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'ean'?: string;
    /**
     * flag_divisa
     * @type {number}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'flag_divisa'?: number;
    /**
     * flag_promo
     * @type {number}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'flag_promo'?: number;
    /**
     * importo_custom
     * @type {number}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'importo_custom'?: number;
    /**
     * importo_finale
     * @type {number}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'importo_finale'?: number;
    /**
     * importo_iniziale
     * @type {number}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'importo_iniziale'?: number;
    /**
     * iva
     * @type {number}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'iva'?: number;
    /**
     * nome
     * @type {string}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'nome'?: string;
    /**
     * nota
     * @type {string}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'nota'?: string;
    /**
     * progressivo
     * @type {number}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'progressivo'?: number;
    /**
     * sconto
     * @type {number}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'sconto'?: number;
    /**
     * sku
     * @type {string}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'sku'?: string;
    /**
     * sku_created
     * @type {number}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'sku_created'?: number;
    /**
     * sku_read
     * @type {string}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'sku_read'?: string;
    /**
     * sku_splitted
     * @type {number}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'sku_splitted'?: number;
    /**
     * tipo_importo
     * @type {string}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'tipo_importo'?: string;
    /**
     * tipologia_merce
     * @type {string}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'tipologia_merce'?: string;
    /**
     * causale_reso
     * @type {string}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'causale_reso'?: string;
    /**
     * flag_tassabile
     * @type {number}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'flag_tassabile'?: number;
    /**
     * prezzo_listino_vendita
     * @type {number}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'prezzo_listino_vendita'?: number;
    /**
     * reso
     * @type {number}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'reso'?: number;
    /**
     * rfid
     * @type {string}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'rfid'?: string;
    /**
     * sconto_listino_vendita
     * @type {number}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'sconto_listino_vendita'?: number;
    /**
     * sku_gruppo
     * @type {string}
     * @memberof ApiV1SalesDataAttributesCapi
     */
    'sku_gruppo'?: string;
}
/**
 *
 * @export
 * @interface ApiV1SalesDataAttributesPagamenti
 */
export interface ApiV1SalesDataAttributesPagamenti {
    /**
     * sale counter
     * @type {number}
     * @memberof ApiV1SalesDataAttributesPagamenti
     */
    'id_transazione'?: number;
    /**
     * barcode
     * @type {string}
     * @memberof ApiV1SalesDataAttributesPagamenti
     */
    'barcode'?: string;
    /**
     * cod_negozio
     * @type {string}
     * @memberof ApiV1SalesDataAttributesPagamenti
     */
    'cod_negozio'?: string;
    /**
     * cod_operazione
     * @type {string}
     * @memberof ApiV1SalesDataAttributesPagamenti
     */
    'cod_operazione'?: string;
    /**
     * codice_movimento
     * @type {string}
     * @memberof ApiV1SalesDataAttributesPagamenti
     */
    'codice_movimento'?: string;
    /**
     * codice_stato
     * @type {string}
     * @memberof ApiV1SalesDataAttributesPagamenti
     */
    'codice_stato'?: string;
    /**
     * data_creazione
     * @type {string}
     * @memberof ApiV1SalesDataAttributesPagamenti
     */
    'data_creazione'?: string;
    /**
     * data_modifica
     * @type {string}
     * @memberof ApiV1SalesDataAttributesPagamenti
     */
    'data_modifica'?: string;
    /**
     * dati_operazione
     * @type {string}
     * @memberof ApiV1SalesDataAttributesPagamenti
     */
    'dati_operazione'?: string;
    /**
     * divisa
     * @type {string}
     * @memberof ApiV1SalesDataAttributesPagamenti
     */
    'divisa'?: string;
    /**
     * id_postazione
     * @type {string}
     * @memberof ApiV1SalesDataAttributesPagamenti
     */
    'id_postazione'?: string;
    /**
     * importo_finale
     * @type {number}
     * @memberof ApiV1SalesDataAttributesPagamenti
     */
    'importo_finale'?: number;
    /**
     * importo_iniziale
     * @type {number}
     * @memberof ApiV1SalesDataAttributesPagamenti
     */
    'importo_iniziale'?: number;
    /**
     * nota
     * @type {string}
     * @memberof ApiV1SalesDataAttributesPagamenti
     */
    'nota'?: string;
    /**
     * progressivo
     * @type {number}
     * @memberof ApiV1SalesDataAttributesPagamenti
     */
    'progressivo'?: number;
    /**
     * progressivo_capo
     * @type {number}
     * @memberof ApiV1SalesDataAttributesPagamenti
     */
    'progressivo_capo'?: number;
    /**
     * tipo_applicazione
     * @type {string}
     * @memberof ApiV1SalesDataAttributesPagamenti
     */
    'tipo_applicazione'?: string;
    /**
     * reso
     * @type {number}
     * @memberof ApiV1SalesDataAttributesPagamenti
     */
    'reso'?: number;
}
/**
 *
 * @export
 * @interface ApiV1SalesFidelityPointsData
 */
export interface ApiV1SalesFidelityPointsData {
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesFidelityPointsData
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesFidelityPointsData
     */
    'id'?: string;
    /**
     *
     * @type {ApiV1SalesFidelityPointsDataAttributes}
     * @memberof ApiV1SalesFidelityPointsData
     */
    'attributes'?: ApiV1SalesFidelityPointsDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1SalesFidelityPointsDataAttributes
 */
export interface ApiV1SalesFidelityPointsDataAttributes {
    /**
     * sale counter
     * @type {number}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'id_transazione'?: number;
    /**
     * pk_consumer
     * @type {string}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'pk_consumer'?: string;
    /**
     * sid
     * @type {string}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'sid'?: string;
    /**
     * tipo_applicazione_apertura
     * @type {string}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'tipo_applicazione_apertura'?: string;
    /**
     * tipo_applicazione_chiusura
     * @type {string}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'tipo_applicazione_chiusura'?: string;
    /**
     * cod_cassa
     * @type {string}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'cod_cassa'?: string;
    /**
     * cod_cassiera
     * @type {string}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'cod_cassiera'?: string;
    /**
     * cod_documento
     * @type {string}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'cod_documento'?: string;
    /**
     * cod_negozio
     * @type {string}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'cod_negozio'?: string;
    /**
     * cod_vettore
     * @type {string}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'cod_vettore'?: string;
    /**
     * codice_movimento
     * @type {string}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'codice_movimento'?: string;
    /**
     * codice_stato
     * @type {string}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'codice_stato'?: string;
    /**
     * data_creazione
     * @type {string}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'data_creazione'?: string;
    /**
     * data_documento
     * @type {string}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'data_documento'?: string;
    /**
     * data_modifica
     * @type {string}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'data_modifica'?: string;
    /**
     * dati_aggiuntivi
     * @type {string}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'dati_aggiuntivi'?: string;
    /**
     * stringified object dati_documenti
     * @type {string}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'dati_documenti'?: string;
    /**
     * divisa
     * @type {string}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'divisa'?: string;
    /**
     * flag_stampa_documento
     * @type {number}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'flag_stampa_documento'?: number;
    /**
     * id_postazione_apertura
     * @type {string}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'id_postazione_apertura'?: string;
    /**
     * id_postazione_chiusura
     * @type {string}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'id_postazione_chiusura'?: string;
    /**
     * id_utente
     * @type {string}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'id_utente'?: string;
    /**
     * importo_finale
     * @type {number}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'importo_finale'?: number;
    /**
     * importo_iniziale
     * @type {number}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'importo_iniziale'?: number;
    /**
     * importo_pagato
     * @type {number}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'importo_pagato'?: number;
    /**
     * nota
     * @type {string}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'nota'?: string;
    /**
     * numero_documento
     * @type {number}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'numero_documento'?: number;
    /**
     * numero_stampa_documento
     * @type {string}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'numero_stampa_documento'?: string;
    /**
     * ora_documento
     * @type {string}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'ora_documento'?: string;
    /**
     *
     * @type {Array<ApiV1SalesSaleIdDataAttributesCapi>}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'capi'?: Array<ApiV1SalesSaleIdDataAttributesCapi>;
    /**
     *
     * @type {Array<ApiV1SalesSaleIdDataAttributesPagamenti>}
     * @memberof ApiV1SalesFidelityPointsDataAttributes
     */
    'pagamenti'?: Array<ApiV1SalesSaleIdDataAttributesPagamenti>;
}
/**
 *
 * @export
 * @interface ApiV1SalesPrintRetryData
 */
export interface ApiV1SalesPrintRetryData {
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesPrintRetryData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1SalesPrintRetryDataAttributes}
     * @memberof ApiV1SalesPrintRetryData
     */
    'attributes'?: ApiV1SalesPrintRetryDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1SalesPrintRetryDataAttributes
 */
export interface ApiV1SalesPrintRetryDataAttributes {
    /**
     * id transazione
     * @type {number}
     * @memberof ApiV1SalesPrintRetryDataAttributes
     */
    'id_transazione'?: number;
    /**
     * document reference and type to print
     * @type {string}
     * @memberof ApiV1SalesPrintRetryDataAttributes
     */
    'prints_to_retry'?: string;
}
/**
 *
 * @export
 * @interface ApiV1SalesSaleIdData
 */
export interface ApiV1SalesSaleIdData {
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdData
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdData
     */
    'id'?: string;
    /**
     *
     * @type {ApiV1SalesSaleIdDataAttributes}
     * @memberof ApiV1SalesSaleIdData
     */
    'attributes'?: ApiV1SalesSaleIdDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1SalesSaleIdDataAttributes
 */
export interface ApiV1SalesSaleIdDataAttributes {
    /**
     * sale counter
     * @type {number}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'id_transazione'?: number;
    /**
     * pk_consumer
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'pk_consumer'?: string;
    /**
     * sid
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'sid'?: string;
    /**
     * tipo_applicazione_apertura
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'tipo_applicazione_apertura'?: string;
    /**
     * tipo_applicazione_chiusura
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'tipo_applicazione_chiusura'?: string;
    /**
     * cod_cassa
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'cod_cassa'?: string;
    /**
     * cod_cassiera
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'cod_cassiera'?: string;
    /**
     * cod_documento
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'cod_documento'?: string;
    /**
     * cod_negozio
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'cod_negozio'?: string;
    /**
     * cod_vettore
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'cod_vettore'?: string;
    /**
     * codice_movimento
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'codice_movimento'?: string;
    /**
     * codice_stato
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'codice_stato'?: string;
    /**
     * data_creazione
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'data_creazione'?: string;
    /**
     * data_documento
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'data_documento'?: string;
    /**
     * data_modifica
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'data_modifica'?: string;
    /**
     * dati_aggiuntivi
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'dati_aggiuntivi'?: string;
    /**
     * stringified object dati_documenti
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'dati_documenti'?: string;
    /**
     * divisa
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'divisa'?: string;
    /**
     * flag_stampa_documento
     * @type {number}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'flag_stampa_documento'?: number;
    /**
     * id_postazione_apertura
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'id_postazione_apertura'?: string;
    /**
     * id_postazione_chiusura
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'id_postazione_chiusura'?: string;
    /**
     * id_utente
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'id_utente'?: string;
    /**
     * importo_finale
     * @type {number}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'importo_finale'?: number;
    /**
     * importo_iniziale
     * @type {number}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'importo_iniziale'?: number;
    /**
     * importo_pagato
     * @type {number}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'importo_pagato'?: number;
    /**
     * nota
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'nota'?: string;
    /**
     * numero_documento
     * @type {number}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'numero_documento'?: number;
    /**
     * numero_stampa_documento
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'numero_stampa_documento'?: string;
    /**
     * ora_documento
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'ora_documento'?: string;
    /**
     *
     * @type {Array<ApiV1SalesSaleIdDataAttributesCapi>}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'capi'?: Array<ApiV1SalesSaleIdDataAttributesCapi>;
    /**
     *
     * @type {Array<ApiV1SalesSaleIdDataAttributesPagamenti>}
     * @memberof ApiV1SalesSaleIdDataAttributes
     */
    'pagamenti'?: Array<ApiV1SalesSaleIdDataAttributesPagamenti>;
}
/**
 *
 * @export
 * @interface ApiV1SalesSaleIdDataAttributesCapi
 */
export interface ApiV1SalesSaleIdDataAttributesCapi {
    /**
     * sale counter
     * @type {number}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'id_transazione'?: number;
    /**
     * classe
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'classe'?: string;
    /**
     * cod_commessa
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'cod_commessa'?: string;
    /**
     * cod_negozio
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'cod_negozio'?: string;
    /**
     * codice_movimento
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'codice_movimento'?: string;
    /**
     * codice_stato
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'codice_stato'?: string;
    /**
     *
     * @type {GetSkuDataAttributesComposizione}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'composizione'?: GetSkuDataAttributesComposizione;
    /**
     * correzione_importo
     * @type {number}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'correzione_importo'?: number;
    /**
     * custom_data
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'custom_data'?: string;
    /**
     * data_creazione
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'data_creazione'?: string;
    /**
     * data_modifica
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'data_modifica'?: string;
    /**
     * ean
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'ean'?: string;
    /**
     * flag_divisa
     * @type {number}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'flag_divisa'?: number;
    /**
     * flag_promo
     * @type {number}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'flag_promo'?: number;
    /**
     * importo_custom
     * @type {number}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'importo_custom'?: number;
    /**
     * importo_finale
     * @type {number}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'importo_finale'?: number;
    /**
     * importo_iniziale
     * @type {number}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'importo_iniziale'?: number;
    /**
     * iva
     * @type {number}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'iva'?: number;
    /**
     * nome
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'nome'?: string;
    /**
     * nota
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'nota'?: string;
    /**
     * progressivo
     * @type {number}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'progressivo'?: number;
    /**
     * sconto
     * @type {number}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'sconto'?: number;
    /**
     * sku
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'sku'?: string;
    /**
     * sku_created
     * @type {number}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'sku_created'?: number;
    /**
     * sku_read
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'sku_read'?: string;
    /**
     * sku_splitted
     * @type {number}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'sku_splitted'?: number;
    /**
     * rfid
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'rfid'?: string;
    /**
     * sku_gruppo
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'sku_gruppo'?: string;
    /**
     * tipo_importo
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'tipo_importo'?: string;
    /**
     * tipologia_merce
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'tipologia_merce'?: string;
    /**
     * prezzo_listino_vendita
     * @type {number}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'prezzo_listino_vendita'?: number;
    /**
     * sconto_listino_vendita (solo capi created)
     * @type {number}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'sconto_listino_vendita'?: number;
    /**
     * empty string if not reso
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesCapi
     */
    'causale_reso'?: string;
}
/**
 *
 * @export
 * @interface ApiV1SalesSaleIdDataAttributesPagamenti
 */
export interface ApiV1SalesSaleIdDataAttributesPagamenti {
    /**
     * sale counter
     * @type {number}
     * @memberof ApiV1SalesSaleIdDataAttributesPagamenti
     */
    'id_transazione'?: number;
    /**
     * barcode
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesPagamenti
     */
    'barcode'?: string;
    /**
     * cod_negozio
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesPagamenti
     */
    'cod_negozio'?: string;
    /**
     * cod_operazione
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesPagamenti
     */
    'cod_operazione'?: string;
    /**
     * codice_movimento
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesPagamenti
     */
    'codice_movimento'?: string;
    /**
     * codice_stato
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesPagamenti
     */
    'codice_stato'?: string;
    /**
     * data_creazione
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesPagamenti
     */
    'data_creazione'?: string;
    /**
     * data_modifica
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesPagamenti
     */
    'data_modifica'?: string;
    /**
     * dati_operazione
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesPagamenti
     */
    'dati_operazione'?: string;
    /**
     * divisa
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesPagamenti
     */
    'divisa'?: string;
    /**
     * id_postazione
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesPagamenti
     */
    'id_postazione'?: string;
    /**
     * importo_finale
     * @type {number}
     * @memberof ApiV1SalesSaleIdDataAttributesPagamenti
     */
    'importo_finale'?: number;
    /**
     * importo_iniziale
     * @type {number}
     * @memberof ApiV1SalesSaleIdDataAttributesPagamenti
     */
    'importo_iniziale'?: number;
    /**
     * nota
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesPagamenti
     */
    'nota'?: string;
    /**
     * progressivo
     * @type {number}
     * @memberof ApiV1SalesSaleIdDataAttributesPagamenti
     */
    'progressivo'?: number;
    /**
     * progressivo_capo
     * @type {number}
     * @memberof ApiV1SalesSaleIdDataAttributesPagamenti
     */
    'progressivo_capo'?: number;
    /**
     * tipo_applicazione
     * @type {string}
     * @memberof ApiV1SalesSaleIdDataAttributesPagamenti
     */
    'tipo_applicazione'?: string;
}
/**
 *
 * @export
 * @interface ApiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusData
 */
export interface ApiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusData {
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusData
     */
    'type'?: string;
    /**
     * Client-generated id of the payment
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusData
     */
    'id'?: string;
    /**
     *
     * @type {ApiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusDataAttributes}
     * @memberof ApiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusData
     */
    'attributes'?: ApiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusDataAttributes
 */
export interface ApiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusDataAttributes {
    /**
     * Amount to be credited
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusDataAttributes
     */
    'amount'?: number;
    /**
     * Customer code
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusDataAttributes
     */
    'pk_consumer'?: string;
    /**
     * user
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusDataAttributes
     */
    'casher_code'?: string;
    /**
     * fixed token
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusDataAttributes
     */
    'tipo_storno'?: string;
    /**
     * fixed token
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusDataAttributes
     */
    'fz_provenienza'?: string;
}
/**
 *
 * @export
 * @interface ApiV1SalesSaleIdPaymentsGiftCardGiftCodeData
 */
export interface ApiV1SalesSaleIdPaymentsGiftCardGiftCodeData {
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeData
     */
    'type'?: string;
    /**
     * Unique sales id, if id_transaction is 0 it means new transaction
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeData
     */
    'id'?: string;
    /**
     *
     * @type {ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeData
     */
    'attributes'?: ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1SalesSaleIdPaymentsGiftCardGiftCodeData1
 */
export interface ApiV1SalesSaleIdPaymentsGiftCardGiftCodeData1 {
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeData1
     */
    'type'?: string;
    /**
     * Unique sales id, if id_transaction is 0 it means new transaction
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeData1
     */
    'id'?: string;
    /**
     *
     * @type {ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeData1
     */
    'attributes'?: ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
 */
export interface ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes {
    /**
     * Amount to be payed with gift card
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'importo'?: number;
    /**
     * gift card code
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'barcode'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'id_transazione'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'is_store_credit'?: number;
    /**
     *
     * @type {Array<ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi>}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'capi'?: Array<ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi>;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'cod_cassa'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'cod_cassiera'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'cod_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'cod_vettore'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'codice_movimento'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'codice_stato'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'data_creazione'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'data_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'data_modifica'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'dati_aggiuntivi'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'dati_documenti'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'divisa'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'flag_stampa_documento'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'id_postazione_apertura'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'id_postazione_chiusura'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'id_utente'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'importo_finale'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'importo_iniziale'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'importo_pagato'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'nota'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'numero_documento'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'numero_stampa_documento'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'om_num_order'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'ora_documento'?: string;
    /**
     *
     * @type {Array<object>}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'pagamenti'?: Array<object>;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'pk_consumer'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'tipo_applicazione_apertura'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'tipo_applicazione_chiusura'?: string;
}
/**
 *
 * @export
 * @interface ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
 */
export interface ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi {
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'id_transazione'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'progressivo'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'codice_stato'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'codice_movimento'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'cod_commessa'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'sku'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'ean'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'rfid'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'sku_gruppo'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'flag_promo'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'flag_divisa'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'sku_created'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'sku_splitted'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'sku_read'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'nome'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'classe'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'iva'?: number;
    /**
     *
     * @type {ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesComposizione}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'composizione'?: ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesComposizione;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'custom_data'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'nota'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'prezzo_listino_vendita'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'sconto_listino_vendita'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'importo_iniziale'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'importo_custom'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'sconto'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'correzione_importo'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'importo_finale'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'tipo_importo'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'tipologia_merce'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'reso'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'causale_reso'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'data_creazione'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'data_modifica'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesCapi
     */
    'flag_tassabile'?: number;
}
/**
 *
 * @export
 * @interface ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesComposizione
 */
export interface ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesComposizione {
    /**
     *
     * @type {Array<any>}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesComposizione
     */
    'F'?: Array<any>;
    /**
     *
     * @type {Array<any>}
     * @memberof ApiV1SalesSaleIdPaymentsGiftCardGiftCodeDataAttributesComposizione
     */
    'T'?: Array<any>;
}
/**
 *
 * @export
 * @interface ApiV1SalesSaleIdPaymentsPosPosTransactionIdData
 */
export interface ApiV1SalesSaleIdPaymentsPosPosTransactionIdData {
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsPosPosTransactionIdData
     */
    'type'?: string;
    /**
     * Client-generated id of the payment
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsPosPosTransactionIdData
     */
    'id'?: string;
    /**
     *
     * @type {ApiV1SalesSaleIdPaymentsPosPosTransactionIdDataAttributes}
     * @memberof ApiV1SalesSaleIdPaymentsPosPosTransactionIdData
     */
    'attributes'?: ApiV1SalesSaleIdPaymentsPosPosTransactionIdDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1SalesSaleIdPaymentsPosPosTransactionIdDataAttributes
 */
export interface ApiV1SalesSaleIdPaymentsPosPosTransactionIdDataAttributes {
    /**
     * Amount to be payed
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsPosPosTransactionIdDataAttributes
     */
    'amount'?: number;
}
/**
 *
 * @export
 * @interface ApiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdData
 */
export interface ApiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdData {
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdData
     */
    'type'?: string;
    /**
     * Client-generated id of the payment
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdData
     */
    'id'?: string;
    /**
     *
     * @type {ApiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdDataAttributes}
     * @memberof ApiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdData
     */
    'attributes'?: ApiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdDataAttributes
 */
export interface ApiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdDataAttributes {
    /**
     * Amount to be payed
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdDataAttributes
     */
    'amount'?: number;
    /**
     * user
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdDataAttributes
     */
    'casher_code'?: string;
    /**
     * Customer code
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdDataAttributes
     */
    'pk_consumer'?: string;
}
/**
 *
 * @export
 * @interface ApiV1SalesSaleIdPaymentsReversalPosPosTransactionIdData
 */
export interface ApiV1SalesSaleIdPaymentsReversalPosPosTransactionIdData {
    /**
     *
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsReversalPosPosTransactionIdData
     */
    'type'?: string;
    /**
     * Client-generated id of the payment
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsReversalPosPosTransactionIdData
     */
    'id'?: string;
    /**
     *
     * @type {ApiV1SalesSaleIdPaymentsReversalPosPosTransactionIdDataAttributes}
     * @memberof ApiV1SalesSaleIdPaymentsReversalPosPosTransactionIdData
     */
    'attributes'?: ApiV1SalesSaleIdPaymentsReversalPosPosTransactionIdDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1SalesSaleIdPaymentsReversalPosPosTransactionIdDataAttributes
 */
export interface ApiV1SalesSaleIdPaymentsReversalPosPosTransactionIdDataAttributes {
    /**
     * Amount to be payed
     * @type {number}
     * @memberof ApiV1SalesSaleIdPaymentsReversalPosPosTransactionIdDataAttributes
     */
    'amount'?: number;
    /**
     * fixed token
     * @type {string}
     * @memberof ApiV1SalesSaleIdPaymentsReversalPosPosTransactionIdDataAttributes
     */
    'tipo_storno'?: string;
}
/**
 *
 * @export
 * @interface ApiV1SessionsData
 */
export interface ApiV1SessionsData {
    /**
     *
     * @type {string}
     * @memberof ApiV1SessionsData
     */
    'type'?: string;
    /**
     * Should be empty
     * @type {string}
     * @memberof ApiV1SessionsData
     */
    'id'?: string;
    /**
     *
     * @type {ApiV1SessionsDataAttributes}
     * @memberof ApiV1SessionsData
     */
    'attributes'?: ApiV1SessionsDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1SessionsDataAttributes
 */
export interface ApiV1SessionsDataAttributes {
    /**
     *
     * @type {string}
     * @memberof ApiV1SessionsDataAttributes
     */
    'username'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SessionsDataAttributes
     */
    'password'?: string;
    /**
     * Unique id of the installation in this subnet, provided by /api/v1/posweb/global_config
     * @type {string}
     * @memberof ApiV1SessionsDataAttributes
     */
    'cod_cassa'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SessionsDataAttributes
     */
    'role'?: string;
}
/**
 *
 * @export
 * @interface ApiV1SessionsPortalSidData
 */
export interface ApiV1SessionsPortalSidData {
    /**
     *
     * @type {string}
     * @memberof ApiV1SessionsPortalSidData
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SessionsPortalSidData
     */
    'id'?: string;
    /**
     *
     * @type {ApiV1SessionsPortalSidDataAttributes}
     * @memberof ApiV1SessionsPortalSidData
     */
    'attributes'?: ApiV1SessionsPortalSidDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1SessionsPortalSidData1
 */
export interface ApiV1SessionsPortalSidData1 {
    /**
     *
     * @type {string}
     * @memberof ApiV1SessionsPortalSidData1
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SessionsPortalSidData1
     */
    'id'?: string;
    /**
     *
     * @type {ApiV1SessionsPortalSidData1Attributes}
     * @memberof ApiV1SessionsPortalSidData1
     */
    'attributes'?: ApiV1SessionsPortalSidData1Attributes;
}
/**
 *
 * @export
 * @interface ApiV1SessionsPortalSidData1Attributes
 */
export interface ApiV1SessionsPortalSidData1Attributes {
    /**
     * Unique id of the installation in this subnet, provided by POST /api/v1/sessions_portal/{sid}
     * @type {string}
     * @memberof ApiV1SessionsPortalSidData1Attributes
     */
    'cod_cassa'?: string;
    /**
     * Unique store id of the installation in this subnet, provided by POST /api/v1/sessions_portal/{sid}
     * @type {string}
     * @memberof ApiV1SessionsPortalSidData1Attributes
     */
    'selected_store'?: string;
}
/**
 *
 * @export
 * @interface ApiV1SessionsPortalSidDataAttributes
 */
export interface ApiV1SessionsPortalSidDataAttributes {
    /**
     *
     * @type {string}
     * @memberof ApiV1SessionsPortalSidDataAttributes
     */
    'action'?: ApiV1SessionsPortalSidDataAttributesActionEnum;
    /**
     * Unique id of the installation in this subnet, provided by POST /api/v1/sessions_portal/{sid}
     * @type {string}
     * @memberof ApiV1SessionsPortalSidDataAttributes
     */
    'cod_cassa'?: string;
    /**
     * Unique store id of the installation in this subnet, provided by POST /api/v1/sessions_portal/{sid}
     * @type {string}
     * @memberof ApiV1SessionsPortalSidDataAttributes
     */
    'cod_negozio'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiV1SessionsPortalSidDataAttributesActionEnum {
    Close = 'CLOSE'
}

/**
 *
 * @export
 * @interface ApiV1SessionsSidData
 */
export interface ApiV1SessionsSidData {
    /**
     *
     * @type {string}
     * @memberof ApiV1SessionsSidData
     */
    'type'?: string;
    /**
     * Session unique id
     * @type {string}
     * @memberof ApiV1SessionsSidData
     */
    'id'?: string;
    /**
     *
     * @type {ApiV1SessionsSidDataAttributes}
     * @memberof ApiV1SessionsSidData
     */
    'attributes'?: ApiV1SessionsSidDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1SessionsSidDataAttributes
 */
export interface ApiV1SessionsSidDataAttributes {
    /**
     * User selected language
     * @type {string}
     * @memberof ApiV1SessionsSidDataAttributes
     */
    'language'?: string;
}
/**
 *
 * @export
 * @interface ApiV1SospesiDataDocumentoNumDocumentoCodNegozioData
 */
export interface ApiV1SospesiDataDocumentoNumDocumentoCodNegozioData {
    /**
     *
     * @type {string}
     * @memberof ApiV1SospesiDataDocumentoNumDocumentoCodNegozioData
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SospesiDataDocumentoNumDocumentoCodNegozioData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1SospesiDataDocumentoNumDocumentoCodNegozioDataAttributes}
     * @memberof ApiV1SospesiDataDocumentoNumDocumentoCodNegozioData
     */
    'attributes'?: ApiV1SospesiDataDocumentoNumDocumentoCodNegozioDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1SospesiDataDocumentoNumDocumentoCodNegozioDataAttributes
 */
export interface ApiV1SospesiDataDocumentoNumDocumentoCodNegozioDataAttributes {
    /**
     *
     * @type {number}
     * @memberof ApiV1SospesiDataDocumentoNumDocumentoCodNegozioDataAttributes
     */
    'importo'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1SospesiDataDocumentoNumDocumentoCodNegozioDataAttributes
     */
    'tipo_prodotto'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SospesiDataDocumentoNumDocumentoCodNegozioDataAttributes
     */
    'pagamento_remoto_preferito'?: string;
}
/**
 *
 * @export
 * @interface ApiV1SospesiPagamentoData
 */
export interface ApiV1SospesiPagamentoData {
    /**
     *
     * @type {string}
     * @memberof ApiV1SospesiPagamentoData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1SospesiPagamentoDataAttributes}
     * @memberof ApiV1SospesiPagamentoData
     */
    'attributes'?: ApiV1SospesiPagamentoDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1SospesiPagamentoDataAttributes
 */
export interface ApiV1SospesiPagamentoDataAttributes {
    /**
     *
     * @type {string}
     * @memberof ApiV1SospesiPagamentoDataAttributes
     */
    'id_transazione'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SospesiPagamentoDataAttributes
     */
    'anagrafica_cassiera'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SospesiPagamentoDataAttributes
     */
    'anagrafica_consumatrice'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1SospesiPagamentoDataAttributes
     */
    'pagamento_tatale'?: number;
    /**
     *
     * @type {Array<ApiV1SospesiPagamentoDataAttributesPagamenti>}
     * @memberof ApiV1SospesiPagamentoDataAttributes
     */
    'pagamenti'?: Array<ApiV1SospesiPagamentoDataAttributesPagamenti>;
    /**
     *
     * @type {Array<ApiV1SospesiPagamentoDataAttributesBollettine>}
     * @memberof ApiV1SospesiPagamentoDataAttributes
     */
    'bollettine'?: Array<ApiV1SospesiPagamentoDataAttributesBollettine>;
}
/**
 *
 * @export
 * @interface ApiV1SospesiPagamentoDataAttributesBollettine
 */
export interface ApiV1SospesiPagamentoDataAttributesBollettine {
    /**
     *
     * @type {string}
     * @memberof ApiV1SospesiPagamentoDataAttributesBollettine
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1SospesiPagamentoDataAttributesBollettine
     */
    'numero_documento'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1SospesiPagamentoDataAttributesBollettine
     */
    'data_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SospesiPagamentoDataAttributesBollettine
     */
    'pk_consumer'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SospesiPagamentoDataAttributesBollettine
     */
    'tipo'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SospesiPagamentoDataAttributesBollettine
     */
    'valore_codice'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1SospesiPagamentoDataAttributesBollettine
     */
    'importo'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1SospesiPagamentoDataAttributesBollettine
     */
    'cognome'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SospesiPagamentoDataAttributesBollettine
     */
    'nome'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SospesiPagamentoDataAttributesBollettine
     */
    'tipo_prodotto'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SospesiPagamentoDataAttributesBollettine
     */
    'pagamento_remoto_preferito'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1SospesiPagamentoDataAttributesBollettine
     */
    'importo_pagato'?: number;
}
/**
 *
 * @export
 * @interface ApiV1SospesiPagamentoDataAttributesPagamenti
 */
export interface ApiV1SospesiPagamentoDataAttributesPagamenti {
    /**
     *
     * @type {number}
     * @memberof ApiV1SospesiPagamentoDataAttributesPagamenti
     */
    'progressivo'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1SospesiPagamentoDataAttributesPagamenti
     */
    'tipo'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SospesiPagamentoDataAttributesPagamenti
     */
    'valore'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1SospesiPagamentoDataAttributesPagamenti
     */
    'altriDati'?: string;
}
/**
 *
 * @export
 * @interface ApiV1SospesiRicercaConsumatriciData
 */
export interface ApiV1SospesiRicercaConsumatriciData {
    /**
     *
     * @type {string}
     * @memberof ApiV1SospesiRicercaConsumatriciData
     */
    'query'?: string;
}
/**
 *
 * @export
 * @interface ApiV1StampaVersamentoData
 */
export interface ApiV1StampaVersamentoData {
    /**
     *
     * @type {ApiV1StampaVersamentoDataData}
     * @memberof ApiV1StampaVersamentoData
     */
    'data'?: ApiV1StampaVersamentoDataData;
}
/**
 *
 * @export
 * @interface ApiV1StampaVersamentoDataData
 */
export interface ApiV1StampaVersamentoDataData {
    /**
     * fixed data type \"stampa-versamento\"
     * @type {string}
     * @memberof ApiV1StampaVersamentoDataData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1StampaVersamentoDataDataAttributes}
     * @memberof ApiV1StampaVersamentoDataData
     */
    'attributes'?: ApiV1StampaVersamentoDataDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1StampaVersamentoDataDataAttributes
 */
export interface ApiV1StampaVersamentoDataDataAttributes {
    /**
     *
     * @type {string}
     * @memberof ApiV1StampaVersamentoDataDataAttributes
     */
    'num_versamento'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1StampaVersamentoDataDataAttributes
     */
    'anno'?: string;
}
/**
 *
 * @export
 * @interface ApiV1StrumentiBundleEditData
 */
export interface ApiV1StrumentiBundleEditData {
    /**
     *
     * @type {string}
     * @memberof ApiV1StrumentiBundleEditData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1StrumentiBundleEditDataAttributes}
     * @memberof ApiV1StrumentiBundleEditData
     */
    'attributes'?: ApiV1StrumentiBundleEditDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1StrumentiBundleEditDataAttributes
 */
export interface ApiV1StrumentiBundleEditDataAttributes {
    /**
     *
     * @type {string}
     * @memberof ApiV1StrumentiBundleEditDataAttributes
     */
    'function'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1StrumentiBundleEditDataAttributes
     */
    'valori'?: string;
}
/**
 *
 * @export
 * @interface ApiV1StrumentiChangeDateData
 */
export interface ApiV1StrumentiChangeDateData {
    /**
     *
     * @type {string}
     * @memberof ApiV1StrumentiChangeDateData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1StrumentiChangeDateDataAttributes}
     * @memberof ApiV1StrumentiChangeDateData
     */
    'attributes'?: ApiV1StrumentiChangeDateDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1StrumentiChangeDateDataAttributes
 */
export interface ApiV1StrumentiChangeDateDataAttributes {
    /**
     *
     * @type {string}
     * @memberof ApiV1StrumentiChangeDateDataAttributes
     */
    'mode'?: ApiV1StrumentiChangeDateDataAttributesModeEnum;
    /**
     *
     * @type {string}
     * @memberof ApiV1StrumentiChangeDateDataAttributes
     */
    'custom_date'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiV1StrumentiChangeDateDataAttributesModeEnum {
    Modify = 'MODIFY',
    Reset = 'RESET'
}

/**
 *
 * @export
 * @interface ApiV1StrumentiCheckCodiceAutorizzazioneData
 */
export interface ApiV1StrumentiCheckCodiceAutorizzazioneData {
    /**
     *
     * @type {string}
     * @memberof ApiV1StrumentiCheckCodiceAutorizzazioneData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1StrumentiCheckCodiceAutorizzazioneDataAttributes}
     * @memberof ApiV1StrumentiCheckCodiceAutorizzazioneData
     */
    'attributes'?: ApiV1StrumentiCheckCodiceAutorizzazioneDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1StrumentiCheckCodiceAutorizzazioneDataAttributes
 */
export interface ApiV1StrumentiCheckCodiceAutorizzazioneDataAttributes {
    /**
     *
     * @type {string}
     * @memberof ApiV1StrumentiCheckCodiceAutorizzazioneDataAttributes
     */
    'numero_iniziale'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1StrumentiCheckCodiceAutorizzazioneDataAttributes
     */
    'codice_autorizzazione'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1StrumentiCheckCodiceAutorizzazioneDataAttributes
     */
    'funzione'?: string;
}
/**
 *
 * @export
 * @interface ApiV1StrumentiForceCashClosureData
 */
export interface ApiV1StrumentiForceCashClosureData {
    /**
     *
     * @type {string}
     * @memberof ApiV1StrumentiForceCashClosureData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1StrumentiForceCashClosureDataAttributes}
     * @memberof ApiV1StrumentiForceCashClosureData
     */
    'attributes'?: ApiV1StrumentiForceCashClosureDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1StrumentiForceCashClosureDataAttributes
 */
export interface ApiV1StrumentiForceCashClosureDataAttributes {
    /**
     *
     * @type {string}
     * @memberof ApiV1StrumentiForceCashClosureDataAttributes
     */
    'cod_cassa'?: string;
}
/**
 *
 * @export
 * @interface ApiV1StrumentiGetCodiceAutorizzazioneData
 */
export interface ApiV1StrumentiGetCodiceAutorizzazioneData {
    /**
     *
     * @type {string}
     * @memberof ApiV1StrumentiGetCodiceAutorizzazioneData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1StrumentiGetCodiceAutorizzazioneDataAttributes}
     * @memberof ApiV1StrumentiGetCodiceAutorizzazioneData
     */
    'attributes'?: ApiV1StrumentiGetCodiceAutorizzazioneDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1StrumentiGetCodiceAutorizzazioneDataAttributes
 */
export interface ApiV1StrumentiGetCodiceAutorizzazioneDataAttributes {
    /**
     *
     * @type {string}
     * @memberof ApiV1StrumentiGetCodiceAutorizzazioneDataAttributes
     */
    'funzione'?: string;
}
/**
 *
 * @export
 * @interface ApiV1StrumentiSendCommandToCustomPrinterData
 */
export interface ApiV1StrumentiSendCommandToCustomPrinterData {
    /**
     *
     * @type {string}
     * @memberof ApiV1StrumentiSendCommandToCustomPrinterData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1StrumentiSendCommandToCustomPrinterDataAttributes}
     * @memberof ApiV1StrumentiSendCommandToCustomPrinterData
     */
    'attributes'?: ApiV1StrumentiSendCommandToCustomPrinterDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1StrumentiSendCommandToCustomPrinterDataAttributes
 */
export interface ApiV1StrumentiSendCommandToCustomPrinterDataAttributes {
    /**
     *
     * @type {string}
     * @memberof ApiV1StrumentiSendCommandToCustomPrinterDataAttributes
     */
    'testo'?: string;
}
/**
 *
 * @export
 * @interface ApiV1StrumentiStampaTotaliPosData
 */
export interface ApiV1StrumentiStampaTotaliPosData {
    /**
     *
     * @type {string}
     * @memberof ApiV1StrumentiStampaTotaliPosData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1StrumentiStampaTotaliPosDataAttributes}
     * @memberof ApiV1StrumentiStampaTotaliPosData
     */
    'attributes'?: ApiV1StrumentiStampaTotaliPosDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1StrumentiStampaTotaliPosDataAttributes
 */
export interface ApiV1StrumentiStampaTotaliPosDataAttributes {
    /**
     *
     * @type {string}
     * @memberof ApiV1StrumentiStampaTotaliPosDataAttributes
     */
    'pos_transaction_id'?: string;
}
/**
 *
 * @export
 * @interface ApiV1SuspendedSaleIdPaymentsGiftCardGiftCodeData
 */
export interface ApiV1SuspendedSaleIdPaymentsGiftCardGiftCodeData {
    /**
     *
     * @type {string}
     * @memberof ApiV1SuspendedSaleIdPaymentsGiftCardGiftCodeData
     */
    'type'?: string;
    /**
     * Unique gift card barcode id
     * @type {string}
     * @memberof ApiV1SuspendedSaleIdPaymentsGiftCardGiftCodeData
     */
    'id'?: string;
    /**
     *
     * @type {ApiV1SuspendedSaleIdPaymentsGiftCardGiftCodeDataAttributes}
     * @memberof ApiV1SuspendedSaleIdPaymentsGiftCardGiftCodeData
     */
    'attributes'?: ApiV1SuspendedSaleIdPaymentsGiftCardGiftCodeDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1SuspendedSaleIdPaymentsGiftCardGiftCodeDataAttributes
 */
export interface ApiV1SuspendedSaleIdPaymentsGiftCardGiftCodeDataAttributes {
    /**
     * Amount to be payed with gift card
     * @type {number}
     * @memberof ApiV1SuspendedSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'importo'?: number;
    /**
     * gift card code
     * @type {string}
     * @memberof ApiV1SuspendedSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'barcode'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1SuspendedSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'id_transazione'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1SuspendedSaleIdPaymentsGiftCardGiftCodeDataAttributes
     */
    'is_store_credit'?: number;
}
/**
 *
 * @export
 * @interface ApiV1UtentiData
 */
export interface ApiV1UtentiData {
    /**
     *
     * @type {string}
     * @memberof ApiV1UtentiData
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1UtentiData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1UtentiDataAttributes}
     * @memberof ApiV1UtentiData
     */
    'attributes'?: ApiV1UtentiDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1UtentiData1
 */
export interface ApiV1UtentiData1 {
    /**
     *
     * @type {string}
     * @memberof ApiV1UtentiData1
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1UtentiData1Attributes}
     * @memberof ApiV1UtentiData1
     */
    'attributes'?: ApiV1UtentiData1Attributes;
}
/**
 *
 * @export
 * @interface ApiV1UtentiData1Attributes
 */
export interface ApiV1UtentiData1Attributes {
    /**
     *
     * @type {string}
     * @memberof ApiV1UtentiData1Attributes
     */
    'user'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1UtentiData1Attributes
     */
    'ruolo'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1UtentiData1Attributes
     */
    'cognome'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1UtentiData1Attributes
     */
    'nome'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1UtentiData1Attributes
     */
    'lingua'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1UtentiData1Attributes
     */
    'new_pwd'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1UtentiData1Attributes
     */
    'new_pwd_conferma'?: string;
}
/**
 *
 * @export
 * @interface ApiV1UtentiDataAttributes
 */
export interface ApiV1UtentiDataAttributes {
    /**
     *
     * @type {string}
     * @memberof ApiV1UtentiDataAttributes
     */
    'user_to_modify'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1UtentiDataAttributes
     */
    'ruolo'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1UtentiDataAttributes
     */
    'cognome'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1UtentiDataAttributes
     */
    'nome'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1UtentiDataAttributes
     */
    'lingua'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1UtentiDataAttributes
     */
    'new_pwd'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1UtentiDataAttributes
     */
    'new_pwd_conferma'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1UtentiDataAttributes
     */
    'data_cambio_pwd'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1UtentiDataAttributes
     */
    'old_pwd'?: string;
}
/**
 *
 * @export
 * @interface ApiV1VersamentiAttributes
 */
export interface ApiV1VersamentiAttributes {
    /**
     *
     * @type {string}
     * @memberof ApiV1VersamentiAttributes
     */
    'cassiera'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1VersamentiAttributes
     */
    'banca'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1VersamentiAttributes
     */
    'data_versamento'?: string;
    /**
     *
     * @type {number}
     * @memberof ApiV1VersamentiAttributes
     */
    'saldo_negozio'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1VersamentiAttributes
     */
    'totale_versato'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1VersamentiAttributes
     */
    'residuo'?: number;
    /**
     *
     * @type {number}
     * @memberof ApiV1VersamentiAttributes
     */
    'importo'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1VersamentiAttributes
     */
    'tipo_versamento'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiV1VersamentiAttributes
     */
    'data_valuta'?: string;
}
/**
 *
 * @export
 * @interface ApiV1VersamentiControllaImportoAntiriciclaggioData
 */
export interface ApiV1VersamentiControllaImportoAntiriciclaggioData {
    /**
     * fixed data type \"versamento\"
     * @type {string}
     * @memberof ApiV1VersamentiControllaImportoAntiriciclaggioData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1VersamentiControllaImportoAntiriciclaggioDataAttributes}
     * @memberof ApiV1VersamentiControllaImportoAntiriciclaggioData
     */
    'attributes'?: ApiV1VersamentiControllaImportoAntiriciclaggioDataAttributes;
}
/**
 *
 * @export
 * @interface ApiV1VersamentiControllaImportoAntiriciclaggioDataAttributes
 */
export interface ApiV1VersamentiControllaImportoAntiriciclaggioDataAttributes {
    /**
     *
     * @type {number}
     * @memberof ApiV1VersamentiControllaImportoAntiriciclaggioDataAttributes
     */
    'amount'?: number;
    /**
     *
     * @type {string}
     * @memberof ApiV1VersamentiControllaImportoAntiriciclaggioDataAttributes
     */
    'selectedOption'?: string;
}
/**
 *
 * @export
 * @interface ApiV1VersamentiData
 */
export interface ApiV1VersamentiData {
    /**
     * Unique bank deposit id. SHOULD BE NULL in POST
     * @type {string}
     * @memberof ApiV1VersamentiData
     */
    'id'?: string;
    /**
     * fixed data type \"versamento\"
     * @type {string}
     * @memberof ApiV1VersamentiData
     */
    'type'?: string;
    /**
     *
     * @type {ApiV1VersamentiAttributes}
     * @memberof ApiV1VersamentiData
     */
    'attributes'?: ApiV1VersamentiAttributes;
}
/**
 *
 * @export
 * @interface BurnGiftCard
 */
export interface BurnGiftCard {
    /**
     *
     * @type {ApiV1SalesSaleIdPaymentsGiftCardGiftCodeData}
     * @memberof BurnGiftCard
     */
    'data'?: ApiV1SalesSaleIdPaymentsGiftCardGiftCodeData;
}
/**
 *
 * @export
 * @interface BurnGiftCard1
 */
export interface BurnGiftCard1 {
    /**
     *
     * @type {ApiV1SalesSaleIdPaymentsGiftCardGiftCodeData1}
     * @memberof BurnGiftCard1
     */
    'data'?: ApiV1SalesSaleIdPaymentsGiftCardGiftCodeData1;
}
/**
 *
 * @export
 * @interface BurnGiftCard2
 */
export interface BurnGiftCard2 {
    /**
     *
     * @type {ApiV1SuspendedSaleIdPaymentsGiftCardGiftCodeData}
     * @memberof BurnGiftCard2
     */
    'data'?: ApiV1SuspendedSaleIdPaymentsGiftCardGiftCodeData;
}
/**
 *
 * @export
 * @interface CCPayment
 */
export interface CCPayment {
    /**
     *
     * @type {CCPaymentData}
     * @memberof CCPayment
     */
    'data'?: CCPaymentData;
}
/**
 *
 * @export
 * @interface CCPayment1
 */
export interface CCPayment1 {
    /**
     *
     * @type {CCPayment1Data}
     * @memberof CCPayment1
     */
    'data'?: CCPayment1Data;
}
/**
 *
 * @export
 * @interface CCPayment1Data
 */
export interface CCPayment1Data {
    /**
     *
     * @type {string}
     * @memberof CCPayment1Data
     */
    'type'?: string;
    /**
     * Client-generated id of the payment
     * @type {string}
     * @memberof CCPayment1Data
     */
    'id'?: string;
    /**
     *
     * @type {CCPayment1DataAttributes}
     * @memberof CCPayment1Data
     */
    'attributes'?: CCPayment1DataAttributes;
}
/**
 *
 * @export
 * @interface CCPayment1DataAttributes
 */
export interface CCPayment1DataAttributes {
    /**
     * Amount to be refund
     * @type {number}
     * @memberof CCPayment1DataAttributes
     */
    'amount'?: number;
    /**
     * Pos Payment status
     * @type {string}
     * @memberof CCPayment1DataAttributes
     */
    'status'?: string;
}
/**
 *
 * @export
 * @interface CCPaymentData
 */
export interface CCPaymentData {
    /**
     *
     * @type {string}
     * @memberof CCPaymentData
     */
    'type'?: string;
    /**
     * Client-generated id of the payment
     * @type {string}
     * @memberof CCPaymentData
     */
    'id'?: string;
    /**
     *
     * @type {CCPaymentDataAttributes}
     * @memberof CCPaymentData
     */
    'attributes'?: CCPaymentDataAttributes;
}
/**
 *
 * @export
 * @interface CCPaymentDataAttributes
 */
export interface CCPaymentDataAttributes {
    /**
     * Amount to be payed
     * @type {number}
     * @memberof CCPaymentDataAttributes
     */
    'amount'?: number;
    /**
     * Pos Payment status
     * @type {string}
     * @memberof CCPaymentDataAttributes
     */
    'status'?: string;
}
/**
 *
 * @export
 * @interface CCPaymentResult
 */
export interface CCPaymentResult {
    /**
     *
     * @type {CCPaymentResultData}
     * @memberof CCPaymentResult
     */
    'data'?: CCPaymentResultData;
}
/**
 *
 * @export
 * @interface CCPaymentResultData
 */
export interface CCPaymentResultData {
    /**
     *
     * @type {string}
     * @memberof CCPaymentResultData
     */
    'type'?: string;
    /**
     * Client-generated id of the payment
     * @type {string}
     * @memberof CCPaymentResultData
     */
    'id'?: string;
    /**
     *
     * @type {CCPaymentResultDataAttributes}
     * @memberof CCPaymentResultData
     */
    'attributes'?: CCPaymentResultDataAttributes;
}
/**
 *
 * @export
 * @interface CCPaymentResultDataAttributes
 */
export interface CCPaymentResultDataAttributes {
    /**
     * Amount to be payed
     * @type {number}
     * @memberof CCPaymentResultDataAttributes
     */
    'amount'?: number;
    /**
     * Pos Payment status
     * @type {string}
     * @memberof CCPaymentResultDataAttributes
     */
    'status'?: string;
    /**
     *
     * @type {CCPaymentResultDataAttributesTransactionData}
     * @memberof CCPaymentResultDataAttributes
     */
    'transaction_data'?: CCPaymentResultDataAttributesTransactionData;
}
/**
 *
 * @export
 * @interface CCPaymentResultDataAttributesTransactionData
 */
export interface CCPaymentResultDataAttributesTransactionData {
    /**
     * Payment date
     * @type {string}
     * @memberof CCPaymentResultDataAttributesTransactionData
     */
    'payment_date'?: string;
    /**
     *
     * @type {string}
     * @memberof CCPaymentResultDataAttributesTransactionData
     */
    'esito'?: string;
    /**
     * Transaction Id generated by client
     * @type {string}
     * @memberof CCPaymentResultDataAttributesTransactionData
     */
    'pos_tran_id'?: string;
    /**
     *
     * @type {string}
     * @memberof CCPaymentResultDataAttributesTransactionData
     */
    'tipo'?: string;
    /**
     *
     * @type {string}
     * @memberof CCPaymentResultDataAttributesTransactionData
     */
    'raw_response'?: string;
    /**
     *
     * @type {string}
     * @memberof CCPaymentResultDataAttributesTransactionData
     */
    'tipo_storno'?: string;
    /**
     *
     * @type {CCPaymentResultDataAttributesTransactionDataDatiCarta}
     * @memberof CCPaymentResultDataAttributesTransactionData
     */
    'dati_carta'?: CCPaymentResultDataAttributesTransactionDataDatiCarta;
}
/**
 *
 * @export
 * @interface CCPaymentResultDataAttributesTransactionDataDatiCarta
 */
export interface CCPaymentResultDataAttributesTransactionDataDatiCarta {
    /**
     *
     * @type {number}
     * @memberof CCPaymentResultDataAttributesTransactionDataDatiCarta
     */
    'codice_compagnia'?: number;
    /**
     *
     * @type {string}
     * @memberof CCPaymentResultDataAttributesTransactionDataDatiCarta
     */
    'desc_acquirer'?: string;
    /**
     *
     * @type {string}
     * @memberof CCPaymentResultDataAttributesTransactionDataDatiCarta
     */
    'online_transaction_id'?: string;
    /**
     *
     * @type {string}
     * @memberof CCPaymentResultDataAttributesTransactionDataDatiCarta
     */
    'flag_multicurrency'?: string;
    /**
     *
     * @type {string}
     * @memberof CCPaymentResultDataAttributesTransactionDataDatiCarta
     */
    'tipo_carta_ingenico'?: string;
    /**
     *
     * @type {string}
     * @memberof CCPaymentResultDataAttributesTransactionDataDatiCarta
     */
    'scontrino'?: string;
    /**
     *
     * @type {string}
     * @memberof CCPaymentResultDataAttributesTransactionDataDatiCarta
     */
    'response_code'?: string;
    /**
     *
     * @type {string}
     * @memberof CCPaymentResultDataAttributesTransactionDataDatiCarta
     */
    'terminal_id'?: string;
    /**
     *
     * @type {string}
     * @memberof CCPaymentResultDataAttributesTransactionDataDatiCarta
     */
    'transaction_time'?: string;
    /**
     *
     * @type {string}
     * @memberof CCPaymentResultDataAttributesTransactionDataDatiCarta
     */
    'transaction_type'?: string;
    /**
     *
     * @type {string}
     * @memberof CCPaymentResultDataAttributesTransactionDataDatiCarta
     */
    'authorization_number'?: string;
    /**
     *
     * @type {string}
     * @memberof CCPaymentResultDataAttributesTransactionDataDatiCarta
     */
    'transaction_date'?: string;
    /**
     *
     * @type {string}
     * @memberof CCPaymentResultDataAttributesTransactionDataDatiCarta
     */
    'esito_transazione'?: string;
    /**
     *
     * @type {string}
     * @memberof CCPaymentResultDataAttributesTransactionDataDatiCarta
     */
    'id_acquirer'?: string;
    /**
     *
     * @type {string}
     * @memberof CCPaymentResultDataAttributesTransactionDataDatiCarta
     */
    'stan'?: string;
    /**
     *
     * @type {string}
     * @memberof CCPaymentResultDataAttributesTransactionDataDatiCarta
     */
    'pan'?: string;
    /**
     *
     * @type {string}
     * @memberof CCPaymentResultDataAttributesTransactionDataDatiCarta
     */
    'terminal_pos_id'?: string;
}
/**
 *
 * @export
 * @interface CCStatus
 */
export interface CCStatus {
    /**
     *
     * @type {ApiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusData}
     * @memberof CCStatus
     */
    'data'?: ApiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusData;
}
/**
 *
 * @export
 * @interface CallThePrintTotalsOnCreditCardMachine
 */
export interface CallThePrintTotalsOnCreditCardMachine {
    /**
     *
     * @type {ApiV1ChiusuracassaStampaTotaliPosData}
     * @memberof CallThePrintTotalsOnCreditCardMachine
     */
    'data'?: ApiV1ChiusuracassaStampaTotaliPosData;
}
/**
 *
 * @export
 * @interface CheckSogliaContanti
 */
export interface CheckSogliaContanti {
    /**
     *
     * @type {ApiV1ConsumersPkConsumerCheckSogliaContantiData}
     * @memberof CheckSogliaContanti
     */
    'data'?: ApiV1ConsumersPkConsumerCheckSogliaContantiData;
}
/**
 *
 * @export
 * @interface ChekcDatiAntiriciclaggio
 */
export interface ChekcDatiAntiriciclaggio {
    /**
     *
     * @type {ApiV1SalesCheckDatiAntiriciclaggioData}
     * @memberof ChekcDatiAntiriciclaggio
     */
    'data'?: ApiV1SalesCheckDatiAntiriciclaggioData;
}
/**
 *
 * @export
 * @interface CloseCreditCardMachine
 */
export interface CloseCreditCardMachine {
    /**
     *
     * @type {ApiV1ChiusuracassaChiusuraPosData}
     * @memberof CloseCreditCardMachine
     */
    'data'?: ApiV1ChiusuracassaChiusuraPosData;
}
/**
 *
 * @export
 * @interface CloseCreditCardMachine1
 */
export interface CloseCreditCardMachine1 {
    /**
     *
     * @type {ApiV1ChiusuracassaRispostaCartaTotaliData}
     * @memberof CloseCreditCardMachine1
     */
    'data'?: ApiV1ChiusuracassaRispostaCartaTotaliData;
}
/**
 *
 * @export
 * @interface ConsumerAddToLocal
 */
export interface ConsumerAddToLocal {
    /**
     *
     * @type {ApiV1ConsumersPkConsumerAddToLocalData}
     * @memberof ConsumerAddToLocal
     */
    'data'?: ApiV1ConsumersPkConsumerAddToLocalData;
}
/**
 *
 * @export
 * @interface ConsumerAddToLocalResponse
 */
export interface ConsumerAddToLocalResponse {
    /**
     *
     * @type {ConsumerAddToLocalResponseData}
     * @memberof ConsumerAddToLocalResponse
     */
    'data'?: ConsumerAddToLocalResponseData;
}
/**
 *
 * @export
 * @interface ConsumerAddToLocalResponseData
 */
export interface ConsumerAddToLocalResponseData {
    /**
     * Unique id
     * @type {string}
     * @memberof ConsumerAddToLocalResponseData
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof ConsumerAddToLocalResponseData
     */
    'type'?: string;
    /**
     *
     * @type {ConsumerAddToLocalResponseDataAttributes}
     * @memberof ConsumerAddToLocalResponseData
     */
    'attributes'?: ConsumerAddToLocalResponseDataAttributes;
}
/**
 *
 * @export
 * @interface ConsumerAddToLocalResponseDataAttributes
 */
export interface ConsumerAddToLocalResponseDataAttributes {
    /**
     *
     * @type {string}
     * @memberof ConsumerAddToLocalResponseDataAttributes
     */
    'status'?: string;
    /**
     *
     * @type {string}
     * @memberof ConsumerAddToLocalResponseDataAttributes
     */
    'message'?: string;
    /**
     *
     * @type {object}
     * @memberof ConsumerAddToLocalResponseDataAttributes
     */
    'consumer'?: object;
}
/**
 *
 * @export
 * @interface ConsumerAnonymizationStatus
 */
export interface ConsumerAnonymizationStatus {
    /**
     *
     * @type {ConsumerAnonymizationStatusData}
     * @memberof ConsumerAnonymizationStatus
     */
    'data'?: ConsumerAnonymizationStatusData;
}
/**
 *
 * @export
 * @interface ConsumerAnonymizationStatusData
 */
export interface ConsumerAnonymizationStatusData {
    /**
     *
     * @type {string}
     * @memberof ConsumerAnonymizationStatusData
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof ConsumerAnonymizationStatusData
     */
    'id'?: string;
    /**
     *
     * @type {ConsumerAnonymizationStatusDataAttributes}
     * @memberof ConsumerAnonymizationStatusData
     */
    'attributes'?: ConsumerAnonymizationStatusDataAttributes;
}
/**
 *
 * @export
 * @interface ConsumerAnonymizationStatusDataAttributes
 */
export interface ConsumerAnonymizationStatusDataAttributes {
    /**
     * codice consumatrice
     * @type {string}
     * @memberof ConsumerAnonymizationStatusDataAttributes
     */
    'pk_consumer'?: string;
    /**
     * stato anonimizzazione parziale
     * @type {string}
     * @memberof ConsumerAnonymizationStatusDataAttributes
     */
    'anonimizzazione_parziale'?: string;
    /**
     * stato anonimizzazione totale
     * @type {string}
     * @memberof ConsumerAnonymizationStatusDataAttributes
     */
    'anonimizzazione_totale'?: string;
}
/**
 *
 * @export
 * @interface ConsumerCardActivate
 */
export interface ConsumerCardActivate {
    /**
     *
     * @type {ApiV1ConsumersPkConsumerCardsActivateData}
     * @memberof ConsumerCardActivate
     */
    'data'?: ApiV1ConsumersPkConsumerCardsActivateData;
}
/**
 *
 * @export
 * @interface ConsumerCardData
 */
export interface ConsumerCardData {
    /**
     *
     * @type {string}
     * @memberof ConsumerCardData
     */
    'type'?: string;
    /**
     * <fidelity code>,<barcode card>
     * @type {string}
     * @memberof ConsumerCardData
     */
    'id'?: string;
    /**
     *
     * @type {ConsumerCardDataAttributes}
     * @memberof ConsumerCardData
     */
    'attributes'?: ConsumerCardDataAttributes;
    /**
     *
     * @type {GetConsumerCardsInfoRelationships}
     * @memberof ConsumerCardData
     */
    'relationships'?: GetConsumerCardsInfoRelationships;
}
/**
 *
 * @export
 * @interface ConsumerCardDataAttributes
 */
export interface ConsumerCardDataAttributes {
    /**
     * programma fidelity
     * @type {string}
     * @memberof ConsumerCardDataAttributes
     */
    'chiave'?: string;
    /**
     * punteggio
     * @type {number}
     * @memberof ConsumerCardDataAttributes
     */
    'current_value'?: number;
    /**
     * dati della card
     * @type {string}
     * @memberof ConsumerCardDataAttributes
     */
    'custom_data'?: string;
    /**
     * data di disattivazione
     * @type {string}
     * @memberof ConsumerCardDataAttributes
     */
    'data_fine'?: string;
    /**
     * data di attivazione
     * @type {string}
     * @memberof ConsumerCardDataAttributes
     */
    'data_inizio'?: string;
    /**
     * data di modifica
     * @type {string}
     * @memberof ConsumerCardDataAttributes
     */
    'data_modifica'?: string;
    /**
     * fascia della fidelity
     * @type {string}
     * @memberof ConsumerCardDataAttributes
     */
    'fascia_attuale'?: string;
    /**
     * identificativo della consumatrice
     * @type {string}
     * @memberof ConsumerCardDataAttributes
     */
    'pk_consumer'?: string;
    /**
     * possiblità di sartorie omaggio
     * @type {boolean}
     * @memberof ConsumerCardDataAttributes
     */
    'sartorie_omaggio'?: boolean;
    /**
     * stato della fidelity
     * @type {string}
     * @memberof ConsumerCardDataAttributes
     */
    'status'?: string;
    /**
     * barcode della fidelity
     * @type {string}
     * @memberof ConsumerCardDataAttributes
     */
    'valore'?: string;
    /**
     * unità di misura dei punti
     * @type {string}
     * @memberof ConsumerCardDataAttributes
     */
    'value_type'?: string;
}
/**
 *
 * @export
 * @interface ConsumerCardDisable
 */
export interface ConsumerCardDisable {
    /**
     *
     * @type {ApiV1ConsumersPkConsumerCardsActivateData}
     * @memberof ConsumerCardDisable
     */
    'data'?: ApiV1ConsumersPkConsumerCardsActivateData;
}
/**
 *
 * @export
 * @interface ConsumerCardForceValue
 */
export interface ConsumerCardForceValue {
    /**
     *
     * @type {ApiV1ConsumersPkConsumerCardsForceData}
     * @memberof ConsumerCardForceValue
     */
    'data'?: ApiV1ConsumersPkConsumerCardsForceData;
}
/**
 *
 * @export
 * @interface ConsumerCardMoveValue
 */
export interface ConsumerCardMoveValue {
    /**
     *
     * @type {ApiV1ConsumersPkConsumerCardsMoveData}
     * @memberof ConsumerCardMoveValue
     */
    'data'?: ApiV1ConsumersPkConsumerCardsMoveData;
}
/**
 *
 * @export
 * @interface ConsumerCheckSogliaContanti
 */
export interface ConsumerCheckSogliaContanti {
    /**
     *
     * @type {ConsumerCheckSogliaContantiData}
     * @memberof ConsumerCheckSogliaContanti
     */
    'data'?: ConsumerCheckSogliaContantiData;
}
/**
 *
 * @export
 * @interface ConsumerCheckSogliaContantiData
 */
export interface ConsumerCheckSogliaContantiData {
    /**
     * Unique id
     * @type {string}
     * @memberof ConsumerCheckSogliaContantiData
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof ConsumerCheckSogliaContantiData
     */
    'type'?: string;
    /**
     *
     * @type {ConsumerCheckSogliaContantiDataAttributes}
     * @memberof ConsumerCheckSogliaContantiData
     */
    'attributes'?: ConsumerCheckSogliaContantiDataAttributes;
}
/**
 *
 * @export
 * @interface ConsumerCheckSogliaContantiDataAttributes
 */
export interface ConsumerCheckSogliaContantiDataAttributes {
    /**
     *
     * @type {number}
     * @memberof ConsumerCheckSogliaContantiDataAttributes
     */
    'contante_utilizzato'?: number;
    /**
     *
     * @type {number}
     * @memberof ConsumerCheckSogliaContantiDataAttributes
     */
    'check_layby'?: number;
}
/**
 *
 * @export
 * @interface ConsumerPreferenceResponse
 */
export interface ConsumerPreferenceResponse {
    /**
     *
     * @type {ConsumerPreferenceResponseData}
     * @memberof ConsumerPreferenceResponse
     */
    'data'?: ConsumerPreferenceResponseData;
}
/**
 *
 * @export
 * @interface ConsumerPreferenceResponseData
 */
export interface ConsumerPreferenceResponseData {
    /**
     * Unique consumer id
     * @type {string}
     * @memberof ConsumerPreferenceResponseData
     */
    'id'?: string;
    /**
     *
     * @type {ConsumerPreferenceResponseDataAttributes}
     * @memberof ConsumerPreferenceResponseData
     */
    'attributes'?: ConsumerPreferenceResponseDataAttributes;
}
/**
 *
 * @export
 * @interface ConsumerPreferenceResponseDataAttributes
 */
export interface ConsumerPreferenceResponseDataAttributes {
    /**
     *
     * @type {string}
     * @memberof ConsumerPreferenceResponseDataAttributes
     */
    'status'?: string;
}
/**
 *
 * @export
 * @interface ConsumerPreferences
 */
export interface ConsumerPreferences {
    /**
     *
     * @type {ApiV1ConsumersPkConsumerPreferenzeContattoData}
     * @memberof ConsumerPreferences
     */
    'data'?: ApiV1ConsumersPkConsumerPreferenzeContattoData;
}
/**
 *
 * @export
 * @interface ConsumerWishlist
 */
export interface ConsumerWishlist {
    /**
     *
     * @type {Array<ConsumerWishlistData>}
     * @memberof ConsumerWishlist
     */
    'data'?: Array<ConsumerWishlistData>;
}
/**
 *
 * @export
 * @interface ConsumerWishlistAttributes
 */
export interface ConsumerWishlistAttributes {
    /**
     *
     * @type {Array<ConsumerWishlistAttributesEntries>}
     * @memberof ConsumerWishlistAttributes
     */
    'entries'?: Array<ConsumerWishlistAttributesEntries>;
}
/**
 *
 * @export
 * @interface ConsumerWishlistAttributesDetails
 */
export interface ConsumerWishlistAttributesDetails {
    /**
     * model code
     * @type {string}
     * @memberof ConsumerWishlistAttributesDetails
     */
    'code'?: string;
    /**
     *
     * @type {Array<object>}
     * @memberof ConsumerWishlistAttributesDetails
     */
    'variants'?: Array<object>;
    /**
     *
     * @type {Array<ConsumerWishlistAttributesDetailsPrices>}
     * @memberof ConsumerWishlistAttributesDetails
     */
    'prices'?: Array<ConsumerWishlistAttributesDetailsPrices>;
}
/**
 *
 * @export
 * @interface ConsumerWishlistAttributesDetailsPrices
 */
export interface ConsumerWishlistAttributesDetailsPrices {
    /**
     *
     * @type {string}
     * @memberof ConsumerWishlistAttributesDetailsPrices
     */
    'price_type'?: string;
    /**
     *
     * @type {number}
     * @memberof ConsumerWishlistAttributesDetailsPrices
     */
    'price'?: number;
}
/**
 *
 * @export
 * @interface ConsumerWishlistAttributesEntries
 */
export interface ConsumerWishlistAttributesEntries {
    /**
     *
     * @type {string}
     * @memberof ConsumerWishlistAttributesEntries
     */
    'sku'?: string;
    /**
     *
     * @type {string}
     * @memberof ConsumerWishlistAttributesEntries
     */
    'b2c_brand'?: string;
    /**
     *
     * @type {ConsumerWishlistAttributesDetails}
     * @memberof ConsumerWishlistAttributesEntries
     */
    'details'?: ConsumerWishlistAttributesDetails;
}
/**
 *
 * @export
 * @interface ConsumerWishlistData
 */
export interface ConsumerWishlistData {
    /**
     * Unique wishlist id
     * @type {string}
     * @memberof ConsumerWishlistData
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof ConsumerWishlistData
     */
    'type'?: string;
    /**
     *
     * @type {ConsumerWishlistAttributes}
     * @memberof ConsumerWishlistData
     */
    'attributes'?: ConsumerWishlistAttributes;
}
/**
 *
 * @export
 * @interface ContentAsListOfSales
 */
export interface ContentAsListOfSales {
    /**
     *
     * @type {Array<ContentAsListOfSalesData>}
     * @memberof ContentAsListOfSales
     */
    'data'?: Array<ContentAsListOfSalesData>;
}
/**
 *
 * @export
 * @interface ContentAsListOfSalesAttributes
 */
export interface ContentAsListOfSalesAttributes {
    /**
     * record id
     * @type {number}
     * @memberof ContentAsListOfSalesAttributes
     */
    'id_transazione'?: number;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'id_carrello'?: string;
    /**
     *
     * @type {number}
     * @memberof ContentAsListOfSalesAttributes
     */
    'numero_documento'?: number;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'numero_documento_orig'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'data_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'ora_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'codice_stato'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'codice_movimento'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'cod_mitt_dest'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'id_utente'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'cod_cassiera'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'cod_vettore'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'cod_cassa'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'tipo_applicazione_apertura'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'id_postazione_apertura'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'tipo_applicazione_chiusura'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'id_postazione_chiusura'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'sid'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'nota'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'divisa'?: string;
    /**
     *
     * @type {number}
     * @memberof ContentAsListOfSalesAttributes
     */
    'importo_iniziale'?: number;
    /**
     *
     * @type {number}
     * @memberof ContentAsListOfSalesAttributes
     */
    'importo_finale'?: number;
    /**
     *
     * @type {number}
     * @memberof ContentAsListOfSalesAttributes
     */
    'importo_pagato'?: number;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'esenzione_iva'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'cod_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'dati_documenti'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'numero_stampa_documento'?: string;
    /**
     *
     * @type {number}
     * @memberof ContentAsListOfSalesAttributes
     */
    'flag_stampa_documento'?: number;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'dati_aggiuntivi'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'dettaglio_iva'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'firma_posweb_input'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'firma_posweb_version'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'nazione_oss'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'data_creazione'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'data_modifica'?: string;
    /**
     *
     * @type {number}
     * @memberof ContentAsListOfSalesAttributes
     */
    'numero_capi'?: number;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'cassiera'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'cognome'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'nome'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'pk_consumer'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'id_transazione_reversed'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesAttributes
     */
    'numero_documento_reversed'?: string;
}
/**
 *
 * @export
 * @interface ContentAsListOfSalesData
 */
export interface ContentAsListOfSalesData {
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesData
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof ContentAsListOfSalesData
     */
    'type'?: string;
    /**
     *
     * @type {ContentAsListOfSalesAttributes}
     * @memberof ContentAsListOfSalesData
     */
    'attributes'?: ContentAsListOfSalesAttributes;
}
/**
 *
 * @export
 * @interface ControllaImportoAntiriciclaggio
 */
export interface ControllaImportoAntiriciclaggio {
    /**
     *
     * @type {ControllaImportoAntiriciclaggioData}
     * @memberof ControllaImportoAntiriciclaggio
     */
    'data'?: ControllaImportoAntiriciclaggioData;
}
/**
 *
 * @export
 * @interface ControllaImportoAntiriciclaggioData
 */
export interface ControllaImportoAntiriciclaggioData {
    /**
     *
     * @type {string}
     * @memberof ControllaImportoAntiriciclaggioData
     */
    'type'?: string;
    /**
     *
     * @type {ControllaImportoAntiriciclaggioDataAttributes}
     * @memberof ControllaImportoAntiriciclaggioData
     */
    'attributes'?: ControllaImportoAntiriciclaggioDataAttributes;
}
/**
 *
 * @export
 * @interface ControllaImportoAntiriciclaggioDataAttributes
 */
export interface ControllaImportoAntiriciclaggioDataAttributes {
    /**
     * only OK o error
     * @type {string}
     * @memberof ControllaImportoAntiriciclaggioDataAttributes
     */
    'status'?: string;
    /**
     *
     * @type {number}
     * @memberof ControllaImportoAntiriciclaggioDataAttributes
     */
    'max_money_amount'?: number;
    /**
     *
     * @type {number}
     * @memberof ControllaImportoAntiriciclaggioDataAttributes
     */
    'amount'?: number;
    /**
     *
     * @type {string}
     * @memberof ControllaImportoAntiriciclaggioDataAttributes
     */
    'result'?: string;
    /**
     *
     * @type {string}
     * @memberof ControllaImportoAntiriciclaggioDataAttributes
     */
    'error_message'?: string;
}
/**
 *
 * @export
 * @interface ControlloVersamento
 */
export interface ControlloVersamento {
    /**
     *
     * @type {ApiV1VersamentiControllaImportoAntiriciclaggioData}
     * @memberof ControlloVersamento
     */
    'data'?: ApiV1VersamentiControllaImportoAntiriciclaggioData;
}
/**
 *
 * @export
 * @interface Coupon
 */
export interface Coupon {
    /**
     *
     * @type {CouponData}
     * @memberof Coupon
     */
    'data'?: CouponData;
}
/**
 *
 * @export
 * @interface Coupon1
 */
export interface Coupon1 {
    /**
     *
     * @type {Coupon1Data}
     * @memberof Coupon1
     */
    'data'?: Coupon1Data;
}
/**
 *
 * @export
 * @interface Coupon1Data
 */
export interface Coupon1Data {
    /**
     *
     * @type {string}
     * @memberof Coupon1Data
     */
    'status'?: string;
    /**
     *
     * @type {Array<CouponDataItems>}
     * @memberof Coupon1Data
     */
    'items'?: Array<CouponDataItems>;
    /**
     *
     * @type {string}
     * @memberof Coupon1Data
     */
    'error'?: string;
}
/**
 *
 * @export
 * @interface CouponData
 */
export interface CouponData {
    /**
     *
     * @type {string}
     * @memberof CouponData
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof CouponData
     */
    'coupon_type'?: string;
    /**
     *
     * @type {string}
     * @memberof CouponData
     */
    'id'?: string;
    /**
     *
     * @type {boolean}
     * @memberof CouponData
     */
    'status'?: boolean;
    /**
     *
     * @type {number}
     * @memberof CouponData
     */
    'value'?: number;
    /**
     *
     * @type {Array<CouponDataItems>}
     * @memberof CouponData
     */
    'items'?: Array<CouponDataItems>;
}
/**
 *
 * @export
 * @interface CouponDataItems
 */
export interface CouponDataItems {
    /**
     *
     * @type {string}
     * @memberof CouponDataItems
     */
    'item_code'?: string;
    /**
     *
     * @type {number}
     * @memberof CouponDataItems
     */
    'value'?: number;
}
/**
 *
 * @export
 * @interface DataForPricesUpdates
 */
export interface DataForPricesUpdates {
    /**
     *
     * @type {DataForPricesUpdatesData}
     * @memberof DataForPricesUpdates
     */
    'data'?: DataForPricesUpdatesData;
}
/**
 *
 * @export
 * @interface DataForPricesUpdatesData
 */
export interface DataForPricesUpdatesData {
    /**
     *
     * @type {string}
     * @memberof DataForPricesUpdatesData
     */
    'type'?: string;
    /**
     *
     * @type {DataForPricesUpdatesDataAttributes}
     * @memberof DataForPricesUpdatesData
     */
    'attributes'?: DataForPricesUpdatesDataAttributes;
}
/**
 *
 * @export
 * @interface DataForPricesUpdatesDataAttributes
 */
export interface DataForPricesUpdatesDataAttributes {
    /**
     *
     * @type {DataForPricesUpdatesDataAttributesSommario}
     * @memberof DataForPricesUpdatesDataAttributes
     */
    'sommario'?: DataForPricesUpdatesDataAttributesSommario;
    /**
     *
     * @type {Array<DataForPricesUpdatesDataAttributesSelector>}
     * @memberof DataForPricesUpdatesDataAttributes
     */
    'selector'?: Array<DataForPricesUpdatesDataAttributesSelector>;
}
/**
 *
 * @export
 * @interface DataForPricesUpdatesDataAttributesSelector
 */
export interface DataForPricesUpdatesDataAttributesSelector {
    /**
     * reference date, unformatted posweb date
     * @type {string}
     * @memberof DataForPricesUpdatesDataAttributesSelector
     */
    'id'?: string;
    /**
     * language formatted string of date
     * @type {string}
     * @memberof DataForPricesUpdatesDataAttributesSelector
     */
    'Data'?: string;
    /**
     *
     * @type {number}
     * @memberof DataForPricesUpdatesDataAttributesSelector
     */
    'numero_modelli'?: number;
}
/**
 *
 * @export
 * @interface DataForPricesUpdatesDataAttributesSommario
 */
export interface DataForPricesUpdatesDataAttributesSommario {
    /**
     *
     * @type {number}
     * @memberof DataForPricesUpdatesDataAttributesSommario
     */
    'quantita'?: number;
    /**
     *
     * @type {string}
     * @memberof DataForPricesUpdatesDataAttributesSommario
     */
    'data_aggiornamento'?: string;
}
/**
 *
 * @export
 * @interface DataListOfPricesUpdates
 */
export interface DataListOfPricesUpdates {
    /**
     *
     * @type {Array<DataListOfPricesUpdatesData>}
     * @memberof DataListOfPricesUpdates
     */
    'data'?: Array<DataListOfPricesUpdatesData>;
}
/**
 *
 * @export
 * @interface DataListOfPricesUpdatesAttributes
 */
export interface DataListOfPricesUpdatesAttributes {
    /**
     *
     * @type {string}
     * @memberof DataListOfPricesUpdatesAttributes
     */
    'annostag'?: string;
    /**
     *
     * @type {string}
     * @memberof DataListOfPricesUpdatesAttributes
     */
    'modello'?: string;
    /**
     *
     * @type {string}
     * @memberof DataListOfPricesUpdatesAttributes
     */
    'variante'?: string;
    /**
     *
     * @type {string}
     * @memberof DataListOfPricesUpdatesAttributes
     */
    'pezzo'?: string;
    /**
     *
     * @type {string}
     * @memberof DataListOfPricesUpdatesAttributes
     */
    'data_validita'?: string;
    /**
     *
     * @type {number}
     * @memberof DataListOfPricesUpdatesAttributes
     */
    'prezzo'?: number;
    /**
     *
     * @type {number}
     * @memberof DataListOfPricesUpdatesAttributes
     */
    'perc'?: number;
    /**
     *
     * @type {string}
     * @memberof DataListOfPricesUpdatesAttributes
     */
    'importo_sconto'?: string;
    /**
     *
     * @type {string}
     * @memberof DataListOfPricesUpdatesAttributes
     */
    'currency'?: string;
    /**
     *
     * @type {number}
     * @memberof DataListOfPricesUpdatesAttributes
     */
    'giacenza'?: number;
    /**
     *
     * @type {string}
     * @memberof DataListOfPricesUpdatesAttributes
     */
    'data_modifica'?: string;
    /**
     *
     * @type {string}
     * @memberof DataListOfPricesUpdatesAttributes
     */
    'nome'?: string;
    /**
     *
     * @type {string}
     * @memberof DataListOfPricesUpdatesAttributes
     */
    'societa'?: string;
    /**
     *
     * @type {string}
     * @memberof DataListOfPricesUpdatesAttributes
     */
    'collezione'?: string;
    /**
     *
     * @type {string}
     * @memberof DataListOfPricesUpdatesAttributes
     */
    'desc_collezione'?: string;
    /**
     *
     * @type {string}
     * @memberof DataListOfPricesUpdatesAttributes
     */
    'classe'?: string;
    /**
     *
     * @type {string}
     * @memberof DataListOfPricesUpdatesAttributes
     */
    'desc_classe'?: string;
    /**
     *
     * @type {number}
     * @memberof DataListOfPricesUpdatesAttributes
     */
    'prezzo_listino'?: number;
    /**
     *
     * @type {string}
     * @memberof DataListOfPricesUpdatesAttributes
     */
    'data_inizio_validita_listino'?: string;
    /**
     *
     * @type {number}
     * @memberof DataListOfPricesUpdatesAttributes
     */
    'id_listino'?: number;
    /**
     *
     * @type {string}
     * @memberof DataListOfPricesUpdatesAttributes
     */
    'annostag_f'?: string;
    /**
     *
     * @type {string}
     * @memberof DataListOfPricesUpdatesAttributes
     */
    'perc_f'?: string;
}
/**
 *
 * @export
 * @interface DataListOfPricesUpdatesData
 */
export interface DataListOfPricesUpdatesData {
    /**
     *
     * @type {string}
     * @memberof DataListOfPricesUpdatesData
     */
    'id'?: string;
    /**
     *
     * @type {DataListOfPricesUpdatesAttributes}
     * @memberof DataListOfPricesUpdatesData
     */
    'attributes'?: DataListOfPricesUpdatesAttributes;
}
/**
 *
 * @export
 * @interface DataSale
 */
export interface DataSale {
    /**
     *
     * @type {boolean}
     * @memberof DataSale
     */
    'preserve_decimals'?: boolean;
    /**
     *
     * @type {ApiV1SalesFidelityPointsData}
     * @memberof DataSale
     */
    'data'?: ApiV1SalesFidelityPointsData;
}
/**
 *
 * @export
 * @interface DatiCorrezioneStatoItemRfid
 */
export interface DatiCorrezioneStatoItemRfid {
    /**
     *
     * @type {ApiV1CartData}
     * @memberof DatiCorrezioneStatoItemRfid
     */
    'data'?: ApiV1CartData;
}
/**
 *
 * @export
 * @interface DatiDaUsarePerAggiornare
 */
export interface DatiDaUsarePerAggiornare {
    /**
     *
     * @type {ApiV1SospesiDataDocumentoNumDocumentoCodNegozioData}
     * @memberof DatiDaUsarePerAggiornare
     */
    'data'?: ApiV1SospesiDataDocumentoNumDocumentoCodNegozioData;
}
/**
 *
 * @export
 * @interface DatiPagamentoEBollettine
 */
export interface DatiPagamentoEBollettine {
    /**
     *
     * @type {ApiV1SospesiPagamentoData}
     * @memberof DatiPagamentoEBollettine
     */
    'data'?: ApiV1SospesiPagamentoData;
}
/**
 *
 * @export
 * @interface DettaglioAcconti
 */
export interface DettaglioAcconti {
    /**
     *
     * @type {Array<DettaglioAccontiData>}
     * @memberof DettaglioAcconti
     */
    'data'?: Array<DettaglioAccontiData>;
}
/**
 *
 * @export
 * @interface DettaglioAccontiAttributes
 */
export interface DettaglioAccontiAttributes {
    /**
     *
     * @type {string}
     * @memberof DettaglioAccontiAttributes
     */
    'data_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof DettaglioAccontiAttributes
     */
    'ora_documento'?: string;
    /**
     *
     * @type {number}
     * @memberof DettaglioAccontiAttributes
     */
    'numero_documento'?: number;
    /**
     *
     * @type {string}
     * @memberof DettaglioAccontiAttributes
     */
    'stato'?: string;
    /**
     *
     * @type {string}
     * @memberof DettaglioAccontiAttributes
     */
    'data_scadenza'?: string;
    /**
     *
     * @type {string}
     * @memberof DettaglioAccontiAttributes
     */
    'consumatrice'?: string;
    /**
     *
     * @type {string}
     * @memberof DettaglioAccontiAttributes
     */
    'contatti'?: string;
    /**
     *
     * @type {number}
     * @memberof DettaglioAccontiAttributes
     */
    'importo_pagato'?: number;
    /**
     *
     * @type {string}
     * @memberof DettaglioAccontiAttributes
     */
    'data_prossimo_pagamento'?: string;
    /**
     *
     * @type {number}
     * @memberof DettaglioAccontiAttributes
     */
    'importo_finale'?: number;
    /**
     *
     * @type {number}
     * @memberof DettaglioAccontiAttributes
     */
    'id_transazione'?: number;
    /**
     *
     * @type {string}
     * @memberof DettaglioAccontiAttributes
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {number}
     * @memberof DettaglioAccontiAttributes
     */
    'numero_capi'?: number;
    /**
     *
     * @type {boolean}
     * @memberof DettaglioAccontiAttributes
     */
    'layby_scaduto'?: boolean;
    /**
     *
     * @type {number}
     * @memberof DettaglioAccontiAttributes
     */
    'importo_mancante'?: number;
    /**
     *
     * @type {boolean}
     * @memberof DettaglioAccontiAttributes
     */
    'ristampa_fiscale'?: boolean;
    /**
     *
     * @type {Array<DettaglioAccontiAttributesCapi>}
     * @memberof DettaglioAccontiAttributes
     */
    'capi'?: Array<DettaglioAccontiAttributesCapi>;
    /**
     *
     * @type {Array<DettaglioAccontiAttributesPagamenti>}
     * @memberof DettaglioAccontiAttributes
     */
    'pagamenti'?: Array<DettaglioAccontiAttributesPagamenti>;
}
/**
 *
 * @export
 * @interface DettaglioAccontiAttributesCapi
 */
export interface DettaglioAccontiAttributesCapi {
    /**
     *
     * @type {string}
     * @memberof DettaglioAccontiAttributesCapi
     */
    'sku'?: string;
    /**
     *
     * @type {string}
     * @memberof DettaglioAccontiAttributesCapi
     */
    'nome'?: string;
    /**
     *
     * @type {string}
     * @memberof DettaglioAccontiAttributesCapi
     */
    'classe'?: string;
    /**
     *
     * @type {number}
     * @memberof DettaglioAccontiAttributesCapi
     */
    'importo_finale'?: number;
    /**
     *
     * @type {string}
     * @memberof DettaglioAccontiAttributesCapi
     */
    'cod_commessa'?: string;
}
/**
 *
 * @export
 * @interface DettaglioAccontiAttributesPagamenti
 */
export interface DettaglioAccontiAttributesPagamenti {
    /**
     *
     * @type {number}
     * @memberof DettaglioAccontiAttributesPagamenti
     */
    'importo_finale'?: number;
    /**
     *
     * @type {string}
     * @memberof DettaglioAccontiAttributesPagamenti
     */
    'cod_operazione'?: string;
    /**
     *
     * @type {string}
     * @memberof DettaglioAccontiAttributesPagamenti
     */
    'data_pagamento'?: string;
}
/**
 *
 * @export
 * @interface DettaglioAccontiData
 */
export interface DettaglioAccontiData {
    /**
     *
     * @type {string}
     * @memberof DettaglioAccontiData
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof DettaglioAccontiData
     */
    'type'?: string;
    /**
     *
     * @type {DettaglioAccontiAttributes}
     * @memberof DettaglioAccontiData
     */
    'attributes'?: DettaglioAccontiAttributes;
}
/**
 *
 * @export
 * @interface DocumentTypeList
 */
export interface DocumentTypeList {
    /**
     *
     * @type {Array<DocumentTypeListData>}
     * @memberof DocumentTypeList
     */
    'data'?: Array<DocumentTypeListData>;
}
/**
 *
 * @export
 * @interface DocumentTypeListAttributes
 */
export interface DocumentTypeListAttributes {
    /**
     *
     * @type {string}
     * @memberof DocumentTypeListAttributes
     */
    'text'?: string;
}
/**
 *
 * @export
 * @interface DocumentTypeListData
 */
export interface DocumentTypeListData {
    /**
     *
     * @type {string}
     * @memberof DocumentTypeListData
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof DocumentTypeListData
     */
    'type'?: string;
    /**
     *
     * @type {DocumentTypeListAttributes}
     * @memberof DocumentTypeListData
     */
    'attributes'?: DocumentTypeListAttributes;
}
/**
 *
 * @export
 * @interface ElencoAcconti
 */
export interface ElencoAcconti {
    /**
     *
     * @type {Array<ElencoAccontiData>}
     * @memberof ElencoAcconti
     */
    'data'?: Array<ElencoAccontiData>;
}
/**
 *
 * @export
 * @interface ElencoAccontiAttributes
 */
export interface ElencoAccontiAttributes {
    /**
     *
     * @type {string}
     * @memberof ElencoAccontiAttributes
     */
    'data_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof ElencoAccontiAttributes
     */
    'ora_documento'?: string;
    /**
     *
     * @type {number}
     * @memberof ElencoAccontiAttributes
     */
    'numero_documento'?: number;
    /**
     *
     * @type {string}
     * @memberof ElencoAccontiAttributes
     */
    'stato'?: string;
    /**
     *
     * @type {string}
     * @memberof ElencoAccontiAttributes
     */
    'data_scadenza'?: string;
    /**
     *
     * @type {string}
     * @memberof ElencoAccontiAttributes
     */
    'consumatrice'?: string;
    /**
     *
     * @type {string}
     * @memberof ElencoAccontiAttributes
     */
    'contatti'?: string;
    /**
     *
     * @type {number}
     * @memberof ElencoAccontiAttributes
     */
    'importo_pagato'?: number;
    /**
     *
     * @type {string}
     * @memberof ElencoAccontiAttributes
     */
    'data_prossimo_pagamento'?: string;
    /**
     *
     * @type {number}
     * @memberof ElencoAccontiAttributes
     */
    'importo_finale'?: number;
    /**
     *
     * @type {number}
     * @memberof ElencoAccontiAttributes
     */
    'id_transazione'?: number;
    /**
     *
     * @type {string}
     * @memberof ElencoAccontiAttributes
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {number}
     * @memberof ElencoAccontiAttributes
     */
    'numero_capi'?: number;
    /**
     *
     * @type {boolean}
     * @memberof ElencoAccontiAttributes
     */
    'layby_scaduto'?: boolean;
}
/**
 *
 * @export
 * @interface ElencoAccontiData
 */
export interface ElencoAccontiData {
    /**
     *
     * @type {string}
     * @memberof ElencoAccontiData
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof ElencoAccontiData
     */
    'type'?: string;
    /**
     *
     * @type {ElencoAccontiAttributes}
     * @memberof ElencoAccontiData
     */
    'attributes'?: ElencoAccontiAttributes;
}
/**
 *
 * @export
 * @interface ElencoUtenti
 */
export interface ElencoUtenti {
    /**
     *
     * @type {Array<ElencoUtentiData>}
     * @memberof ElencoUtenti
     */
    'data'?: Array<ElencoUtentiData>;
}
/**
 *
 * @export
 * @interface ElencoUtentiAttributes
 */
export interface ElencoUtentiAttributes {
    /**
     *
     * @type {string}
     * @memberof ElencoUtentiAttributes
     */
    'username'?: string;
    /**
     *
     * @type {string}
     * @memberof ElencoUtentiAttributes
     */
    'password'?: string;
    /**
     *
     * @type {object}
     * @memberof ElencoUtentiAttributes
     */
    'auth'?: object;
    /**
     *
     * @type {string}
     * @memberof ElencoUtentiAttributes
     */
    'data_modifica'?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof ElencoUtentiAttributes
     */
    'roles'?: Array<string>;
    /**
     *
     * @type {object}
     * @memberof ElencoUtentiAttributes
     */
    'SID_PROJECT_DATA'?: object;
    /**
     *
     * @type {Array<string>}
     * @memberof ElencoUtentiAttributes
     */
    'SID_PROJECT_LIST'?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof ElencoUtentiAttributes
     */
    'USER'?: string;
    /**
     *
     * @type {string}
     * @memberof ElencoUtentiAttributes
     */
    'UTENTE_COGNOME'?: string;
    /**
     *
     * @type {string}
     * @memberof ElencoUtentiAttributes
     */
    'UTENTE_DATA_CAMBIO_PASSWORD'?: string;
    /**
     *
     * @type {string}
     * @memberof ElencoUtentiAttributes
     */
    'UTENTE_EMAIL'?: string;
    /**
     *
     * @type {string}
     * @memberof ElencoUtentiAttributes
     */
    'UTENTE_FORMATO_DATA'?: string;
    /**
     *
     * @type {string}
     * @memberof ElencoUtentiAttributes
     */
    'UTENTE_FORMATO_NUMERO'?: string;
    /**
     *
     * @type {string}
     * @memberof ElencoUtentiAttributes
     */
    'UTENTE_ID_RADIUS'?: string;
    /**
     *
     * @type {string}
     * @memberof ElencoUtentiAttributes
     */
    'UTENTE_INDIRIZZO_IP'?: string;
    /**
     *
     * @type {string}
     * @memberof ElencoUtentiAttributes
     */
    'UTENTE_LINGUA'?: string;
    /**
     *
     * @type {string}
     * @memberof ElencoUtentiAttributes
     */
    'UTENTE_MATRICOLA'?: string;
    /**
     *
     * @type {string}
     * @memberof ElencoUtentiAttributes
     */
    'UTENTE_NOME'?: string;
    /**
     *
     * @type {object}
     * @memberof ElencoUtentiAttributes
     */
    'auth_list'?: object;
}
/**
 *
 * @export
 * @interface ElencoUtentiData
 */
export interface ElencoUtentiData {
    /**
     *
     * @type {string}
     * @memberof ElencoUtentiData
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof ElencoUtentiData
     */
    'id'?: string;
    /**
     *
     * @type {ElencoUtentiAttributes}
     * @memberof ElencoUtentiData
     */
    'attributes'?: ElencoUtentiAttributes;
}
/**
 *
 * @export
 * @interface ErrorErrors
 */
export interface ErrorErrors {
    /**
     *
     * @type {string}
     * @memberof ErrorErrors
     */
    'detail'?: string;
}
/**
 *
 * @export
 * @interface EsitoCancellazioneUtente
 */
export interface EsitoCancellazioneUtente {
    /**
     *
     * @type {EsitoCancellazioneUtenteData}
     * @memberof EsitoCancellazioneUtente
     */
    'data'?: EsitoCancellazioneUtenteData;
}
/**
 *
 * @export
 * @interface EsitoCancellazioneUtenteData
 */
export interface EsitoCancellazioneUtenteData {
    /**
     *
     * @type {string}
     * @memberof EsitoCancellazioneUtenteData
     */
    'type'?: string;
    /**
     *
     * @type {EsitoModificaUtenteDataAttributes}
     * @memberof EsitoCancellazioneUtenteData
     */
    'attributes'?: EsitoModificaUtenteDataAttributes;
}
/**
 *
 * @export
 * @interface EsitoCreazioneUtente
 */
export interface EsitoCreazioneUtente {
    /**
     *
     * @type {EsitoCreazioneUtenteData}
     * @memberof EsitoCreazioneUtente
     */
    'data'?: EsitoCreazioneUtenteData;
}
/**
 *
 * @export
 * @interface EsitoCreazioneUtenteData
 */
export interface EsitoCreazioneUtenteData {
    /**
     *
     * @type {string}
     * @memberof EsitoCreazioneUtenteData
     */
    'type'?: string;
    /**
     *
     * @type {EsitoCreazioneUtenteDataAttributes}
     * @memberof EsitoCreazioneUtenteData
     */
    'attributes'?: EsitoCreazioneUtenteDataAttributes;
}
/**
 *
 * @export
 * @interface EsitoCreazioneUtenteDataAttributes
 */
export interface EsitoCreazioneUtenteDataAttributes {
    /**
     *
     * @type {string}
     * @memberof EsitoCreazioneUtenteDataAttributes
     */
    'status'?: string;
    /**
     *
     * @type {string}
     * @memberof EsitoCreazioneUtenteDataAttributes
     */
    'result'?: string;
}
/**
 *
 * @export
 * @interface EsitoDatiAntiriciclaggio
 */
export interface EsitoDatiAntiriciclaggio {
    /**
     *
     * @type {EsitoDatiAntiriciclaggioData}
     * @memberof EsitoDatiAntiriciclaggio
     */
    'data'?: EsitoDatiAntiriciclaggioData;
}
/**
 *
 * @export
 * @interface EsitoDatiAntiriciclaggioData
 */
export interface EsitoDatiAntiriciclaggioData {
    /**
     *
     * @type {string}
     * @memberof EsitoDatiAntiriciclaggioData
     */
    'type'?: string;
    /**
     *
     * @type {EsitoDatiAntiriciclaggioDataAttributes}
     * @memberof EsitoDatiAntiriciclaggioData
     */
    'attributes'?: EsitoDatiAntiriciclaggioDataAttributes;
}
/**
 * Details
 * @export
 * @interface EsitoDatiAntiriciclaggioDataAttributes
 */
export interface EsitoDatiAntiriciclaggioDataAttributes {
    /**
     *
     * @type {string}
     * @memberof EsitoDatiAntiriciclaggioDataAttributes
     */
    'status'?: string;
    /**
     *
     * @type {object}
     * @memberof EsitoDatiAntiriciclaggioDataAttributes
     */
    'form_errors'?: object;
    /**
     *
     * @type {string}
     * @memberof EsitoDatiAntiriciclaggioDataAttributes
     */
    'generic_errors'?: string;
}
/**
 *
 * @export
 * @interface EsitoModificaUtente
 */
export interface EsitoModificaUtente {
    /**
     *
     * @type {EsitoModificaUtenteData}
     * @memberof EsitoModificaUtente
     */
    'data'?: EsitoModificaUtenteData;
}
/**
 *
 * @export
 * @interface EsitoModificaUtenteData
 */
export interface EsitoModificaUtenteData {
    /**
     *
     * @type {string}
     * @memberof EsitoModificaUtenteData
     */
    'type'?: string;
    /**
     *
     * @type {EsitoModificaUtenteDataAttributes}
     * @memberof EsitoModificaUtenteData
     */
    'attributes'?: EsitoModificaUtenteDataAttributes;
}
/**
 *
 * @export
 * @interface EsitoModificaUtenteDataAttributes
 */
export interface EsitoModificaUtenteDataAttributes {
    /**
     *
     * @type {string}
     * @memberof EsitoModificaUtenteDataAttributes
     */
    'status'?: string;
    /**
     *
     * @type {string}
     * @memberof EsitoModificaUtenteDataAttributes
     */
    'result'?: string;
}
/**
 *
 * @export
 * @interface EsitoRistampaDocumento
 */
export interface EsitoRistampaDocumento {
    /**
     *
     * @type {EsitoRistampaDocumentoData}
     * @memberof EsitoRistampaDocumento
     */
    'data'?: EsitoRistampaDocumentoData;
}
/**
 *
 * @export
 * @interface EsitoRistampaDocumentoData
 */
export interface EsitoRistampaDocumentoData {
    /**
     *
     * @type {string}
     * @memberof EsitoRistampaDocumentoData
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof EsitoRistampaDocumentoData
     */
    'type'?: string;
    /**
     *
     * @type {EsitoRistampaDocumentoDataAttributes}
     * @memberof EsitoRistampaDocumentoData
     */
    'attributes'?: EsitoRistampaDocumentoDataAttributes;
}
/**
 *
 * @export
 * @interface EsitoRistampaDocumentoDataAttributes
 */
export interface EsitoRistampaDocumentoDataAttributes {
    /**
     *
     * @type {Array<EsitoRistampaDocumentoDataAttributesData>}
     * @memberof EsitoRistampaDocumentoDataAttributes
     */
    'data'?: Array<EsitoRistampaDocumentoDataAttributesData>;
}
/**
 *
 * @export
 * @interface EsitoRistampaDocumentoDataAttributesData
 */
export interface EsitoRistampaDocumentoDataAttributesData {
    /**
     *
     * @type {string}
     * @memberof EsitoRistampaDocumentoDataAttributesData
     */
    'ticketPdfData'?: string;
    /**
     *
     * @type {string}
     * @memberof EsitoRistampaDocumentoDataAttributesData
     */
    'ticketPdfName'?: string;
}
/**
 *
 * @export
 * @interface EsitoStampaAntiriciclaggio
 */
export interface EsitoStampaAntiriciclaggio {
    /**
     *
     * @type {EsitoStampaAntiriciclaggioData}
     * @memberof EsitoStampaAntiriciclaggio
     */
    'data'?: EsitoStampaAntiriciclaggioData;
}
/**
 *
 * @export
 * @interface EsitoStampaAntiriciclaggioData
 */
export interface EsitoStampaAntiriciclaggioData {
    /**
     *
     * @type {string}
     * @memberof EsitoStampaAntiriciclaggioData
     */
    'type'?: string;
    /**
     *
     * @type {EsitoStampaAntiriciclaggioDataAttributes}
     * @memberof EsitoStampaAntiriciclaggioData
     */
    'attributes'?: EsitoStampaAntiriciclaggioDataAttributes;
}
/**
 * Details
 * @export
 * @interface EsitoStampaAntiriciclaggioDataAttributes
 */
export interface EsitoStampaAntiriciclaggioDataAttributes {
    /**
     *
     * @type {string}
     * @memberof EsitoStampaAntiriciclaggioDataAttributes
     */
    'status'?: string;
    /**
     *
     * @type {string}
     * @memberof EsitoStampaAntiriciclaggioDataAttributes
     */
    'message'?: string;
}
/**
 *
 * @export
 * @interface FullResoData
 */
export interface FullResoData {
    /**
     *
     * @type {ApiV1ReturnData1}
     * @memberof FullResoData
     */
    'data'?: ApiV1ReturnData1;
}
/**
 *
 * @export
 * @interface FullReturnData
 */
export interface FullReturnData {
    /**
     *
     * @type {FullReturnDataData}
     * @memberof FullReturnData
     */
    'data'?: FullReturnDataData;
}
/**
 *
 * @export
 * @interface FullReturnDataData
 */
export interface FullReturnDataData {
    /**
     *
     * @type {FullReturnDataDataAttributes}
     * @memberof FullReturnDataData
     */
    'attributes'?: FullReturnDataDataAttributes;
}
/**
 *
 * @export
 * @interface FullReturnDataDataAttributes
 */
export interface FullReturnDataDataAttributes {
    /**
     *
     * @type {boolean}
     * @memberof FullReturnDataDataAttributes
     */
    'has_returnable_movements'?: boolean;
    /**
     *
     * @type {Array<ApiV1ReturnData1AttributesPagamenti>}
     * @memberof FullReturnDataDataAttributes
     */
    'pagamenti'?: Array<ApiV1ReturnData1AttributesPagamenti>;
    /**
     *
     * @type {string}
     * @memberof FullReturnDataDataAttributes
     */
    'warning_message'?: string;
    /**
     *
     * @type {Array<ApiV1ReturnData1AttributesCapi>}
     * @memberof FullReturnDataDataAttributes
     */
    'capi'?: Array<ApiV1ReturnData1AttributesCapi>;
    /**
     *
     * @type {Array<object>}
     * @memberof FullReturnDataDataAttributes
     */
    'pagamenti_reso'?: Array<object>;
    /**
     *
     * @type {string}
     * @memberof FullReturnDataDataAttributes
     */
    'dati_aggiuntivi'?: string;
    /**
     *
     * @type {number}
     * @memberof FullReturnDataDataAttributes
     */
    'punti_rendibili'?: number;
    /**
     *
     * @type {string}
     * @memberof FullReturnDataDataAttributes
     */
    'cod_documento_reso'?: string;
    /**
     *
     * @type {string}
     * @memberof FullReturnDataDataAttributes
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {number}
     * @memberof FullReturnDataDataAttributes
     */
    'numero_documento'?: number;
    /**
     *
     * @type {string}
     * @memberof FullReturnDataDataAttributes
     */
    'cod_documento'?: string;
    /**
     *
     * @type {boolean}
     * @memberof FullReturnDataDataAttributes
     */
    'gst_free'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof FullReturnDataDataAttributes
     */
    'vendita_in_valuta'?: boolean;
    /**
     *
     * @type {string}
     * @memberof FullReturnDataDataAttributes
     */
    'pk_consumer'?: string;
    /**
     *
     * @type {boolean}
     * @memberof FullReturnDataDataAttributes
     */
    'has_returnable_items'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof FullReturnDataDataAttributes
     */
    'reversable'?: boolean;
    /**
     *
     * @type {number}
     * @memberof FullReturnDataDataAttributes
     */
    'importo_sospeso'?: number;
    /**
     *
     * @type {boolean}
     * @memberof FullReturnDataDataAttributes
     */
    'to_return'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof FullReturnDataDataAttributes
     */
    'returnable'?: boolean;
    /**
     *
     * @type {string}
     * @memberof FullReturnDataDataAttributes
     */
    'data_documento'?: string;
    /**
     *
     * @type {boolean}
     * @memberof FullReturnDataDataAttributes
     */
    'returnable_om'?: boolean;
    /**
     *
     * @type {ApiV1ReturnData1AttributesConsumer}
     * @memberof FullReturnDataDataAttributes
     */
    'consumer'?: ApiV1ReturnData1AttributesConsumer;
    /**
     *
     * @type {string}
     * @memberof FullReturnDataDataAttributes
     */
    'dati_documenti'?: string;
    /**
     *
     * @type {boolean}
     * @memberof FullReturnDataDataAttributes
     */
    'rendibile_per_intero'?: boolean;
    /**
     *
     * @type {ApiV1ReturnData1AttributesSaleReturnData}
     * @memberof FullReturnDataDataAttributes
     */
    'sale_return_data'?: ApiV1ReturnData1AttributesSaleReturnData;
    /**
     *
     * @type {string}
     * @memberof FullReturnDataDataAttributes
     */
    'cod_cassiera'?: string;
    /**
     *
     * @type {string}
     * @memberof FullReturnDataDataAttributes
     */
    'nazione_oss'?: string;
    /**
     *
     * @type {number}
     * @memberof FullReturnDataDataAttributes
     */
    'importo_rendibile'?: number;
    /**
     *
     * @type {number}
     * @memberof FullReturnDataDataAttributes
     */
    'importo_finale'?: number;
    /**
     *
     * @type {string}
     * @memberof FullReturnDataDataAttributes
     */
    'dati_documenti_reso'?: string;
    /**
     *
     * @type {number}
     * @memberof FullReturnDataDataAttributes
     */
    'id_transazione'?: number;
}
/**
 *
 * @export
 * @interface GestVersamenti
 */
export interface GestVersamenti {
    /**
     * List of bank deposits
     * @type {Array<GestVersamentiData>}
     * @memberof GestVersamenti
     */
    'data'?: Array<GestVersamentiData>;
}
/**
 *
 * @export
 * @interface GestVersamentiAttributes
 */
export interface GestVersamentiAttributes {
    /**
     *
     * @type {string}
     * @memberof GestVersamentiAttributes
     */
    'numdoc'?: string;
    /**
     *
     * @type {string}
     * @memberof GestVersamentiAttributes
     */
    'desc_banca'?: string;
    /**
     *
     * @type {string}
     * @memberof GestVersamentiAttributes
     */
    'data_registrazione'?: string;
    /**
     *
     * @type {string}
     * @memberof GestVersamentiAttributes
     */
    'data_versamento'?: string;
    /**
     *
     * @type {string}
     * @memberof GestVersamentiAttributes
     */
    'totale'?: string;
    /**
     *
     * @type {string}
     * @memberof GestVersamentiAttributes
     */
    'anno'?: string;
}
/**
 * Bank deposits details
 * @export
 * @interface GestVersamentiData
 */
export interface GestVersamentiData {
    /**
     * fixed data type \"versamenti\"
     * @type {string}
     * @memberof GestVersamentiData
     */
    'type'?: string;
    /**
     * Unique id of bank deposit
     * @type {string}
     * @memberof GestVersamentiData
     */
    'id'?: string;
    /**
     *
     * @type {GestVersamentiAttributes}
     * @memberof GestVersamentiData
     */
    'attributes'?: GestVersamentiAttributes;
}
/**
 *
 * @export
 * @interface GetAdvancedFilters
 */
export interface GetAdvancedFilters {
    /**
     *
     * @type {Array<GetAdvancedFiltersData>}
     * @memberof GetAdvancedFilters
     */
    'data'?: Array<GetAdvancedFiltersData>;
}
/**
 *
 * @export
 * @interface GetAdvancedFiltersAttributes
 */
export interface GetAdvancedFiltersAttributes {
    /**
     *
     * @type {Array<GetAdvancedFiltersAttributesAdmittedValues>}
     * @memberof GetAdvancedFiltersAttributes
     */
    'admitted_values'?: Array<GetAdvancedFiltersAttributesAdmittedValues>;
    /**
     *
     * @type {string}
     * @memberof GetAdvancedFiltersAttributes
     */
    'brand'?: string;
    /**
     *
     * @type {number}
     * @memberof GetAdvancedFiltersAttributes
     */
    'category'?: number;
    /**
     *
     * @type {string}
     * @memberof GetAdvancedFiltersAttributes
     */
    'code'?: string;
    /**
     *
     * @type {string}
     * @memberof GetAdvancedFiltersAttributes
     */
    'desc'?: string;
}
/**
 *
 * @export
 * @interface GetAdvancedFiltersAttributesAdmittedValues
 */
export interface GetAdvancedFiltersAttributesAdmittedValues {
    /**
     *
     * @type {string}
     * @memberof GetAdvancedFiltersAttributesAdmittedValues
     */
    'code'?: string | null;
    /**
     *
     * @type {string}
     * @memberof GetAdvancedFiltersAttributesAdmittedValues
     */
    'desc'?: string;
}
/**
 *
 * @export
 * @interface GetAdvancedFiltersData
 */
export interface GetAdvancedFiltersData {
    /**
     * type
     * @type {string}
     * @memberof GetAdvancedFiltersData
     */
    'type'?: string;
    /**
     * id composto: brand, category, filter code, filter desc, language, value desc, value code
     * @type {string}
     * @memberof GetAdvancedFiltersData
     */
    'id'?: string;
    /**
     *
     * @type {GetAdvancedFiltersAttributes}
     * @memberof GetAdvancedFiltersData
     */
    'attributes'?: GetAdvancedFiltersAttributes;
}
/**
 *
 * @export
 * @interface GetAllowedPayments
 */
export interface GetAllowedPayments {
    /**
     *
     * @type {Array<GetAllowedPaymentsData>}
     * @memberof GetAllowedPayments
     */
    'data'?: Array<GetAllowedPaymentsData>;
}
/**
 * Details
 * @export
 * @interface GetAllowedPaymentsAttributes
 */
export interface GetAllowedPaymentsAttributes {
    /**
     * Type of payment
     * @type {string}
     * @memberof GetAllowedPaymentsAttributes
     */
    'descrizione'?: string;
    /**
     * Code of payment type, e.g. ASSEGNI, CARTA, CREDITO_SOSPESO
     * @type {string}
     * @memberof GetAllowedPaymentsAttributes
     */
    'codice_pagamento'?: string;
    /**
     * Progressivo
     * @type {number}
     * @memberof GetAllowedPaymentsAttributes
     */
    'progressivo'?: number;
    /**
     * Store Payment Currency
     * @type {string}
     * @memberof GetAllowedPaymentsAttributes
     */
    'divisa'?: string;
    /**
     *
     * @type {Array<GetAllowedPaymentsAttributesAllowedIdTypes>}
     * @memberof GetAllowedPaymentsAttributes
     */
    'allowed_id_types'?: Array<GetAllowedPaymentsAttributesAllowedIdTypes>;
    /**
     *
     * @type {GetAllowedPaymentsAttributesProductTypes}
     * @memberof GetAllowedPaymentsAttributes
     */
    'product_types'?: GetAllowedPaymentsAttributesProductTypes;
    /**
     * Usable in refund, true/false, only for payment code = CARTA
     * @type {boolean}
     * @memberof GetAllowedPaymentsAttributes
     */
    'usable_in_refund'?: boolean;
}
/**
 * Allowed Id types, present in case of payment code = ASSEGNI or CARTA
 * @export
 * @interface GetAllowedPaymentsAttributesAllowedIdTypes
 */
export interface GetAllowedPaymentsAttributesAllowedIdTypes {
    /**
     * Id type
     * @type {string}
     * @memberof GetAllowedPaymentsAttributesAllowedIdTypes
     */
    'type'?: string;
    /**
     * Id
     * @type {string}
     * @memberof GetAllowedPaymentsAttributesAllowedIdTypes
     */
    'id'?: string;
    /**
     *
     * @type {GetAllowedPaymentsAttributesAttributes}
     * @memberof GetAllowedPaymentsAttributesAllowedIdTypes
     */
    'attributes'?: GetAllowedPaymentsAttributesAttributes;
}
/**
 * Attributes
 * @export
 * @interface GetAllowedPaymentsAttributesAttributes
 */
export interface GetAllowedPaymentsAttributesAttributes {
    /**
     * Code, same as id, present if payment code = CARTA
     * @type {string}
     * @memberof GetAllowedPaymentsAttributesAttributes
     */
    'codice'?: string;
    /**
     * Document code, present if payment code = ASSEGNI
     * @type {string}
     * @memberof GetAllowedPaymentsAttributesAttributes
     */
    'codice_documento'?: string;
    /**
     * Document description, present if payment code = ASSEGNI
     * @type {string}
     * @memberof GetAllowedPaymentsAttributesAttributes
     */
    'descrizione'?: string;
}
/**
 * Types of product in case of codice_pagamento = CREDITO_SOSPESO
 * @export
 * @interface GetAllowedPaymentsAttributesProductTypes
 */
export interface GetAllowedPaymentsAttributesProductTypes {
    /**
     * Description of product type, payment_product or remote_payment
     * @type {string}
     * @memberof GetAllowedPaymentsAttributesProductTypes
     */
    'type'?: string;
    /**
     * Product type id code
     * @type {string}
     * @memberof GetAllowedPaymentsAttributesProductTypes
     */
    'id'?: string;
    /**
     *
     * @type {GetAllowedPaymentsAttributesProductTypesAttributes}
     * @memberof GetAllowedPaymentsAttributesProductTypes
     */
    'attributes'?: GetAllowedPaymentsAttributesProductTypesAttributes;
}
/**
 * Further product type details
 * @export
 * @interface GetAllowedPaymentsAttributesProductTypesAttributes
 */
export interface GetAllowedPaymentsAttributesProductTypesAttributes {
    /**
     * Product code, same as id code
     * @type {string}
     * @memberof GetAllowedPaymentsAttributesProductTypesAttributes
     */
    'codice_prodotto'?: string;
}
/**
 * Available payment type details
 * @export
 * @interface GetAllowedPaymentsData
 */
export interface GetAllowedPaymentsData {
    /**
     * Payment type id code
     * @type {string}
     * @memberof GetAllowedPaymentsData
     */
    'id'?: string;
    /**
     * Type of node
     * @type {string}
     * @memberof GetAllowedPaymentsData
     */
    'type'?: string;
    /**
     *
     * @type {GetAllowedPaymentsAttributes}
     * @memberof GetAllowedPaymentsData
     */
    'attributes'?: GetAllowedPaymentsAttributes;
}
/**
 *
 * @export
 * @interface GetAnonymousConsumer
 */
export interface GetAnonymousConsumer {
    /**
     *
     * @type {GetAnonymousConsumerData}
     * @memberof GetAnonymousConsumer
     */
    'data'?: GetAnonymousConsumerData;
}
/**
 *
 * @export
 * @interface GetAnonymousConsumer1
 */
export interface GetAnonymousConsumer1 {
    /**
     *
     * @type {GetAnonymousConsumer1Data}
     * @memberof GetAnonymousConsumer1
     */
    'data'?: GetAnonymousConsumer1Data;
}
/**
 *
 * @export
 * @interface GetAnonymousConsumer1Data
 */
export interface GetAnonymousConsumer1Data {
    /**
     * fixed data type \"consumers-taxfree\"
     * @type {string}
     * @memberof GetAnonymousConsumer1Data
     */
    'type'?: string;
    /**
     * Unique consumer id
     * @type {string}
     * @memberof GetAnonymousConsumer1Data
     */
    'id'?: string;
    /**
     *
     * @type {GetAnonymousConsumer1DataAttributes}
     * @memberof GetAnonymousConsumer1Data
     */
    'attributes'?: GetAnonymousConsumer1DataAttributes;
}
/**
 *
 * @export
 * @interface GetAnonymousConsumer1DataAttributes
 */
export interface GetAnonymousConsumer1DataAttributes {
    /**
     *
     * @type {GetAnonymousConsumer1DataAttributesTfsTraveller}
     * @memberof GetAnonymousConsumer1DataAttributes
     */
    'tfs_traveller'?: GetAnonymousConsumer1DataAttributesTfsTraveller;
    /**
     * tsf traveller id
     * @type {string}
     * @memberof GetAnonymousConsumer1DataAttributes
     */
    'tfs_traveller_id'?: string;
}
/**
 * details of tax-free consumer traveller
 * @export
 * @interface GetAnonymousConsumer1DataAttributesTfsTraveller
 */
export interface GetAnonymousConsumer1DataAttributesTfsTraveller {
    /**
     *
     * @type {string}
     * @memberof GetAnonymousConsumer1DataAttributesTfsTraveller
     */
    'FirstName'?: string;
    /**
     *
     * @type {string}
     * @memberof GetAnonymousConsumer1DataAttributesTfsTraveller
     */
    'LastName'?: string;
    /**
     *
     * @type {string}
     * @memberof GetAnonymousConsumer1DataAttributesTfsTraveller
     */
    'Email'?: string;
    /**
     *
     * @type {string}
     * @memberof GetAnonymousConsumer1DataAttributesTfsTraveller
     */
    'DateOfBirth'?: string;
    /**
     *
     * @type {string}
     * @memberof GetAnonymousConsumer1DataAttributesTfsTraveller
     */
    'ProfileId'?: string;
    /**
     *
     * @type {string}
     * @memberof GetAnonymousConsumer1DataAttributesTfsTraveller
     */
    'MobileNumber'?: string;
    /**
     *
     * @type {GetAnonymousConsumer1DataAttributesTfsTravellerTravelDocument}
     * @memberof GetAnonymousConsumer1DataAttributesTfsTraveller
     */
    'TravelDocument'?: GetAnonymousConsumer1DataAttributesTfsTravellerTravelDocument;
    /**
     *
     * @type {GetAnonymousConsumer1DataAttributesTfsTravellerAddress}
     * @memberof GetAnonymousConsumer1DataAttributesTfsTraveller
     */
    'Address'?: GetAnonymousConsumer1DataAttributesTfsTravellerAddress;
}
/**
 *
 * @export
 * @interface GetAnonymousConsumer1DataAttributesTfsTravellerAddress
 */
export interface GetAnonymousConsumer1DataAttributesTfsTravellerAddress {
    /**
     *
     * @type {string}
     * @memberof GetAnonymousConsumer1DataAttributesTfsTravellerAddress
     */
    'PostalCode'?: string;
    /**
     *
     * @type {string}
     * @memberof GetAnonymousConsumer1DataAttributesTfsTravellerAddress
     */
    'CountryCode'?: string;
    /**
     *
     * @type {string}
     * @memberof GetAnonymousConsumer1DataAttributesTfsTravellerAddress
     */
    'Town'?: string;
    /**
     *
     * @type {string}
     * @memberof GetAnonymousConsumer1DataAttributesTfsTravellerAddress
     */
    'Street'?: string;
}
/**
 *
 * @export
 * @interface GetAnonymousConsumer1DataAttributesTfsTravellerTravelDocument
 */
export interface GetAnonymousConsumer1DataAttributesTfsTravellerTravelDocument {
    /**
     *
     * @type {string}
     * @memberof GetAnonymousConsumer1DataAttributesTfsTravellerTravelDocument
     */
    'DocumentType'?: string;
    /**
     *
     * @type {string}
     * @memberof GetAnonymousConsumer1DataAttributesTfsTravellerTravelDocument
     */
    'DocumentNumber'?: string;
    /**
     *
     * @type {string}
     * @memberof GetAnonymousConsumer1DataAttributesTfsTravellerTravelDocument
     */
    'DocumentCountryCode'?: string;
}
/**
 *
 * @export
 * @interface GetAnonymousConsumerData
 */
export interface GetAnonymousConsumerData {
    /**
     * fixed data type \"anonymous-consumer\"
     * @type {string}
     * @memberof GetAnonymousConsumerData
     */
    'type'?: string;
    /**
     * Unique consumer id
     * @type {string}
     * @memberof GetAnonymousConsumerData
     */
    'id'?: string;
    /**
     *
     * @type {object}
     * @memberof GetAnonymousConsumerData
     */
    'attributes'?: object;
}
/**
 *
 * @export
 * @interface GetB2ERefunds
 */
export interface GetB2ERefunds {
    /**
     *
     * @type {Array<GetB2ERefundsData>}
     * @memberof GetB2ERefunds
     */
    'data'?: Array<GetB2ERefundsData>;
}
/**
 *
 * @export
 * @interface GetB2ERefunds1
 */
export interface GetB2ERefunds1 {
    /**
     *
     * @type {GetB2ERefunds1Data}
     * @memberof GetB2ERefunds1
     */
    'data'?: GetB2ERefunds1Data;
}
/**
 *
 * @export
 * @interface GetB2ERefunds1Data
 */
export interface GetB2ERefunds1Data {
    /**
     *
     * @type {string}
     * @memberof GetB2ERefunds1Data
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof GetB2ERefunds1Data
     */
    'id'?: string;
    /**
     *
     * @type {GetB2ERefunds1DataAttributes}
     * @memberof GetB2ERefunds1Data
     */
    'attributes'?: GetB2ERefunds1DataAttributes;
}
/**
 *
 * @export
 * @interface GetB2ERefunds1DataAttributes
 */
export interface GetB2ERefunds1DataAttributes {
    /**
     *
     * @type {string}
     * @memberof GetB2ERefunds1DataAttributes
     */
    'print_error_result'?: string;
    /**
     *
     * @type {string}
     * @memberof GetB2ERefunds1DataAttributes
     */
    'warning_msg'?: string;
    /**
     *
     * @type {string}
     * @memberof GetB2ERefunds1DataAttributes
     */
    'message'?: string;
    /**
     *
     * @type {Array<any>}
     * @memberof GetB2ERefunds1DataAttributes
     */
    'prints_to_retry'?: Array<any>;
    /**
     *
     * @type {Array<EsitoRistampaDocumentoDataAttributesData>}
     * @memberof GetB2ERefunds1DataAttributes
     */
    'browser_download_pdf'?: Array<EsitoRistampaDocumentoDataAttributesData>;
}
/**
 *
 * @export
 * @interface GetB2ERefundsAttributes
 */
export interface GetB2ERefundsAttributes {
    /**
     *
     * @type {string}
     * @memberof GetB2ERefundsAttributes
     */
    'cod_negozio'?: string;
    /**
     * stato della spedizione che nel caso di RIMBORSATO non permette altre azioni
     * @type {string}
     * @memberof GetB2ERefundsAttributes
     */
    'stato_spedizione'?: string;
    /**
     *
     * @type {string}
     * @memberof GetB2ERefundsAttributes
     */
    'canale_rimborso'?: string;
    /**
     *
     * @type {string}
     * @memberof GetB2ERefundsAttributes
     */
    'numero_ordine'?: string;
    /**
     *
     * @type {string}
     * @memberof GetB2ERefundsAttributes
     */
    'data_ordine'?: string;
    /**
     *
     * @type {string}
     * @memberof GetB2ERefundsAttributes
     */
    'numero_vendita'?: string;
    /**
     *
     * @type {string}
     * @memberof GetB2ERefundsAttributes
     */
    'id_transazione'?: string;
    /**
     *
     * @type {string}
     * @memberof GetB2ERefundsAttributes
     */
    'data_vendita'?: string;
    /**
     *
     * @type {string}
     * @memberof GetB2ERefundsAttributes
     */
    'numero_spedizione'?: string;
    /**
     *
     * @type {string}
     * @memberof GetB2ERefundsAttributes
     */
    'consumer'?: string;
    /**
     *
     * @type {string}
     * @memberof GetB2ERefundsAttributes
     */
    'sku'?: string;
    /**
     *
     * @type {number}
     * @memberof GetB2ERefundsAttributes
     */
    'importo_rimborsabile'?: number;
    /**
     *
     * @type {number}
     * @memberof GetB2ERefundsAttributes
     */
    'spese_spedizione'?: number;
    /**
     * false se la spedizione non è rimborsabile e il checkbox non è disponibile
     * @type {boolean}
     * @memberof GetB2ERefundsAttributes
     */
    'spedizione_rimborsabile'?: boolean;
    /**
     *
     * @type {string}
     * @memberof GetB2ERefundsAttributes
     */
    'json_rimborso'?: string;
    /**
     *
     * @type {string}
     * @memberof GetB2ERefundsAttributes
     */
    'pk_consumer'?: string;
    /**
     *
     * @type {string}
     * @memberof GetB2ERefundsAttributes
     */
    'anno_transazione'?: string;
    /**
     *
     * @type {Array<GetB2ERefundsAttributesPagamenti>}
     * @memberof GetB2ERefundsAttributes
     */
    'pagamenti'?: Array<GetB2ERefundsAttributesPagamenti>;
    /**
     *
     * @type {number}
     * @memberof GetB2ERefundsAttributes
     */
    'punti_rendibili'?: number;
}
/**
 *
 * @export
 * @interface GetB2ERefundsAttributesPagamenti
 */
export interface GetB2ERefundsAttributesPagamenti {
    /**
     *
     * @type {string}
     * @memberof GetB2ERefundsAttributesPagamenti
     */
    'cod_operazione'?: string;
    /**
     *
     * @type {object}
     * @memberof GetB2ERefundsAttributesPagamenti
     */
    'dati_operazione'?: object;
    /**
     *
     * @type {number}
     * @memberof GetB2ERefundsAttributesPagamenti
     */
    'importo'?: number;
    /**
     *
     * @type {string}
     * @memberof GetB2ERefundsAttributesPagamenti
     */
    'descrizione'?: string;
}
/**
 *
 * @export
 * @interface GetB2ERefundsData
 */
export interface GetB2ERefundsData {
    /**
     *
     * @type {string}
     * @memberof GetB2ERefundsData
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof GetB2ERefundsData
     */
    'id'?: string;
    /**
     *
     * @type {GetB2ERefundsAttributes}
     * @memberof GetB2ERefundsData
     */
    'attributes'?: GetB2ERefundsAttributes;
}
/**
 *
 * @export
 * @interface GetBanche
 */
export interface GetBanche {
    /**
     * List of active banks with code and description
     * @type {Array<GetBancheData>}
     * @memberof GetBanche
     */
    'data'?: Array<GetBancheData>;
}
/**
 *
 * @export
 * @interface GetBancheAttributes
 */
export interface GetBancheAttributes {
    /**
     *
     * @type {string}
     * @memberof GetBancheAttributes
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {string}
     * @memberof GetBancheAttributes
     */
    'codice'?: string;
    /**
     *
     * @type {string}
     * @memberof GetBancheAttributes
     */
    'data_validita_a'?: string;
    /**
     *
     * @type {string}
     * @memberof GetBancheAttributes
     */
    'data_validita_da'?: string;
    /**
     *
     * @type {string}
     * @memberof GetBancheAttributes
     */
    'descrizione'?: string;
}
/**
 * Details of bank
 * @export
 * @interface GetBancheData
 */
export interface GetBancheData {
    /**
     * fixed data type \"banche\"
     * @type {string}
     * @memberof GetBancheData
     */
    'type'?: string;
    /**
     * Unique id of bank
     * @type {string}
     * @memberof GetBancheData
     */
    'id'?: string;
    /**
     *
     * @type {GetBancheAttributes}
     * @memberof GetBancheData
     */
    'attributes'?: GetBancheAttributes;
}
/**
 *
 * @export
 * @interface GetBrandCategories
 */
export interface GetBrandCategories {
    /**
     *
     * @type {Array<GetBrandCategoriesData>}
     * @memberof GetBrandCategories
     */
    'data'?: Array<GetBrandCategoriesData>;
}
/**
 *
 * @export
 * @interface GetBrandCategoriesAttributes
 */
export interface GetBrandCategoriesAttributes {
    /**
     *
     * @type {string}
     * @memberof GetBrandCategoriesAttributes
     */
    'category_code'?: string;
    /**
     *
     * @type {Array<GetBrandCategoriesAttributesSubcategories>}
     * @memberof GetBrandCategoriesAttributes
     */
    'subcategories'?: Array<GetBrandCategoriesAttributesSubcategories>;
    /**
     *
     * @type {string}
     * @memberof GetBrandCategoriesAttributes
     */
    'category_description'?: string;
    /**
     *
     * @type {string}
     * @memberof GetBrandCategoriesAttributes
     */
    'brand'?: string;
}
/**
 *
 * @export
 * @interface GetBrandCategoriesAttributesSubcategories
 */
export interface GetBrandCategoriesAttributesSubcategories {
    /**
     *
     * @type {string}
     * @memberof GetBrandCategoriesAttributesSubcategories
     */
    'sub_category_code'?: string;
    /**
     *
     * @type {string}
     * @memberof GetBrandCategoriesAttributesSubcategories
     */
    'sub_category_description'?: string;
}
/**
 *
 * @export
 * @interface GetBrandCategoriesData
 */
export interface GetBrandCategoriesData {
    /**
     * type
     * @type {string}
     * @memberof GetBrandCategoriesData
     */
    'type'?: string;
    /**
     * id
     * @type {string}
     * @memberof GetBrandCategoriesData
     */
    'id'?: string;
    /**
     *
     * @type {GetBrandCategoriesAttributes}
     * @memberof GetBrandCategoriesData
     */
    'attributes'?: GetBrandCategoriesAttributes;
}
/**
 *
 * @export
 * @interface GetCashFlowStatus
 */
export interface GetCashFlowStatus {
    /**
     *
     * @type {Array<GetCashFlowStatusData>}
     * @memberof GetCashFlowStatus
     */
    'data'?: Array<GetCashFlowStatusData>;
}
/**
 *
 * @export
 * @interface GetCashFlowStatusAttributes
 */
export interface GetCashFlowStatusAttributes {
    /**
     * Income from checks
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'assegni'?: number;
    /**
     * Income from cash
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'contanti'?: number;
    /**
     * Layby balance.
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'corrispettivo_layby'?: number;
    /**
     * Sale/Returns balance.
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'corrispettivo_netto'?: number;
    /**
     * Sale on approval balance.
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'corrispettivo_sale_on_approval'?: number;
    /**
     * Sale/Returns tissue balance.
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'corrispettivo_tessuto'?: number;
    /**
     * Total sold gift card value
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'gift_card_vendute'?: number;
    /**
     * Store petty cash total. Only for type store_totals
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'reintegri'?: number;
    /**
     * Initial (for this day) store balance. Only for type store_cash_flow
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'initial_total_amount'?: number;
    /**
     * Number of returns with invoices
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'return_invoices_qta_standard'?: number;
    /**
     * Returns invoices total.
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'return_invoices_standard'?: number;
    /**
     * Returns B2E sales total.
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'return_receipts_b2e'?: number;
    /**
     * Returns OSS sales total (VAT from EU customer)
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'return_receipts_oss'?: number;
    /**
     * Number of B2E returns
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'return_receipts_qta_b2e'?: number;
    /**
     * Number of OSS returns
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'return_receipts_qta_oss'?: number;
    /**
     * Number of returns with receipts
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'return_receipts_qta_standard'?: number;
    /**
     * Returns receipts total.
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'return_receipts_standard'?: number;
    /**
     * Overall store balance. Only for type store_totals
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'saldo_attuale'?: number;
    /**
     * Number of invoices
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'sale_invoices_qta_standard'?: number;
    /**
     * Invoices total.
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'sale_invoices_standard'?: number;
    /**
     * B2E Sales total.
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'sale_receipts_b2e'?: number;
    /**
     * OSS sales total (VAT from EU customer)
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'sale_receipts_oss'?: number;
    /**
     * Number of B2E sales
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'sale_receipts_qta_b2e'?: number;
    /**
     * Number of OSS sales
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'sale_receipts_qta_oss'?: number;
    /**
     * Number of sale receipts
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'sale_receipts_qta_standard'?: number;
    /**
     * Receipts total.
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'sale_receipts_standard'?: number;
    /**
     * Income from every payment type
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'totale_incassato'?: number;
    /**
     * In-out balance
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'totale_movimentato'?: number;
    /**
     * Non-phisical income. Credit cards, gift cards and credits.
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'totale_ricevuto'?: number;
    /**
     * Returns total
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'totale_storni'?: number;
    /**
     * Number of Returns
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'totale_storni_qta'?: number;
    /**
     * Sales total
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'totale_vendite'?: number;
    /**
     * Number of Sales
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'totale_vendite_qta'?: number;
    /**
     * Store bank assestments total. Only for type store_totals
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'versamenti'?: number;
    /**
     * Income from vouchers
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'voucher_change'?: number;
    /**
     * Capi standard sales
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'return_receipts_standard_capi'?: number;
    /**
     * income for capi sales
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'return_invoices_standard_capi'?: number;
    /**
     * standard b2e capi sales
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'return_receipts_b2e_capi'?: number;
    /**
     * income b2e capi sales
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'return_invoices_b2e_capi'?: number;
    /**
     * standard oss capi sales
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'return_receipts_oss_capi'?: number;
    /**
     * standard tessuti sales
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'return_receipts_standard_tessuti'?: number;
    /**
     * income tessuti sales
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'return_invoices_standard_tessuti'?: number;
    /**
     * standard b2e tessuti sales
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'return_receipts_b2e_tessuti'?: number;
    /**
     * income b2e tessuti sales
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'return_invoices_b2e_tessuti'?: number;
    /**
     * standard oss tessuti sales
     * @type {number}
     * @memberof GetCashFlowStatusAttributes
     */
    'return_receipts_oss_tessuti'?: number;
    /**
     *
     * @type {GetCashFlowStatusAttributesTotaleRicevutoPagamento}
     * @memberof GetCashFlowStatusAttributes
     */
    'totale_ricevuto_pagamento'?: GetCashFlowStatusAttributesTotaleRicevutoPagamento;
}
/**
 * Totals by type of payment
 * @export
 * @interface GetCashFlowStatusAttributesTotaleRicevutoPagamento
 */
export interface GetCashFlowStatusAttributesTotaleRicevutoPagamento {
    /**
     *
     * @type {Array<GetCashFlowStatusAttributesTotaleRicevutoPagamentoEUR>}
     * @memberof GetCashFlowStatusAttributesTotaleRicevutoPagamento
     */
    'EUR'?: Array<GetCashFlowStatusAttributesTotaleRicevutoPagamentoEUR>;
}
/**
 *
 * @export
 * @interface GetCashFlowStatusAttributesTotaleRicevutoPagamentoEUR
 */
export interface GetCashFlowStatusAttributesTotaleRicevutoPagamentoEUR {
    /**
     *
     * @type {string}
     * @memberof GetCashFlowStatusAttributesTotaleRicevutoPagamentoEUR
     */
    'tipo_pagamento'?: string;
    /**
     *
     * @type {number}
     * @memberof GetCashFlowStatusAttributesTotaleRicevutoPagamentoEUR
     */
    'valore'?: number;
}
/**
 * Summary of cash in/out for a single cash or store.
 * @export
 * @interface GetCashFlowStatusData
 */
export interface GetCashFlowStatusData {
    /**
     *
     * @type {GetCashFlowStatusAttributes}
     * @memberof GetCashFlowStatusData
     */
    'attributes'?: GetCashFlowStatusAttributes;
    /**
     * Mutable id depending on type
     * @type {string}
     * @memberof GetCashFlowStatusData
     */
    'id'?: string;
    /**
     * Type of node, \'cash_totals\' for cash sale summary, \'store_totals\' for store sale summary. \'store_cash_flow\' for petty cash and deposits summary.
     * @type {string}
     * @memberof GetCashFlowStatusData
     */
    'type'?: string;
}
/**
 *
 * @export
 * @interface GetCashes
 */
export interface GetCashes {
    /**
     * Array of avaiable Cashes
     * @type {Array<GetCashesData>}
     * @memberof GetCashes
     */
    'data'?: Array<GetCashesData>;
}
/**
 *
 * @export
 * @interface GetCashesAttributes
 */
export interface GetCashesAttributes {
    /**
     * cash_role of the cash (MASTER/SLAVE/WHAREHOUSE/BACKUP)
     * @type {string}
     * @memberof GetCashesAttributes
     */
    'cash_role'?: string;
    /**
     * cash_status of the cash (OPEN/CLOSE)
     * @type {string}
     * @memberof GetCashesAttributes
     */
    'cash_status'?: string;
    /**
     * cod_cassa of the cash
     * @type {string}
     * @memberof GetCashesAttributes
     */
    'cod_cassa'?: string;
    /**
     * cod_negozio of the cash
     * @type {string}
     * @memberof GetCashesAttributes
     */
    'cod_negozio'?: string;
    /**
     * feature_set of the cash
     * @type {string}
     * @memberof GetCashesAttributes
     */
    'feature_set'?: string;
    /**
     * ip_address of the cash
     * @type {string}
     * @memberof GetCashesAttributes
     */
    'ip_address'?: string;
    /**
     * ip_port of the cash
     * @type {string}
     * @memberof GetCashesAttributes
     */
    'ip_port'?: string;
    /**
     * ip_protocol of the cash
     * @type {string}
     * @memberof GetCashesAttributes
     */
    'ip_protocol'?: string;
    /**
     * flag for Enabled RFID
     * @type {string}
     * @memberof GetCashesAttributes
     */
    'CHECKOUT_RFID_ENABLED'?: string;
}
/**
 *
 * @export
 * @interface GetCashesData
 */
export interface GetCashesData {
    /**
     * Unique id in the form <store code>,<cash code>
     * @type {string}
     * @memberof GetCashesData
     */
    'id'?: string;
    /**
     * fixed data type \"cashes\"
     * @type {string}
     * @memberof GetCashesData
     */
    'type'?: string;
    /**
     *
     * @type {GetCashesAttributes}
     * @memberof GetCashesData
     */
    'attributes'?: GetCashesAttributes;
}
/**
 *
 * @export
 * @interface GetCashiers
 */
export interface GetCashiers {
    /**
     * Array of avaiable cashiers
     * @type {Array<GetCashiersData>}
     * @memberof GetCashiers
     */
    'data'?: Array<GetCashiersData>;
}
/**
 *
 * @export
 * @interface GetCashiersAttributes
 */
export interface GetCashiersAttributes {
    /**
     * store code
     * @type {string}
     * @memberof GetCashiersAttributes
     */
    'cod_negozio'?: string;
    /**
     * Second name an name of the cashier
     * @type {string}
     * @memberof GetCashiersAttributes
     */
    'descrizione'?: string;
    /**
     * Name in Kana alphabet
     * @type {string}
     * @memberof GetCashiersAttributes
     */
    'descrizione_kana'?: string;
    /**
     * Name in Kanji alphabet
     * @type {string}
     * @memberof GetCashiersAttributes
     */
    'descrizione_kanji'?: string;
    /**
     * Cashier id for accounting purposes (as for Employeee code)
     * @type {string}
     * @memberof GetCashiersAttributes
     */
    'matricola'?: string;
    /**
     * Cashier id in the mmfg systems
     * @type {string}
     * @memberof GetCashiersAttributes
     */
    'venditrice'?: string;
}
/**
 *
 * @export
 * @interface GetCashiersData
 */
export interface GetCashiersData {
    /**
     * Unique id in the form <store code>,<cashier code>
     * @type {string}
     * @memberof GetCashiersData
     */
    'id'?: string;
    /**
     * fixed data type \"cashiers\"
     * @type {string}
     * @memberof GetCashiersData
     */
    'type'?: string;
    /**
     *
     * @type {GetCashiersAttributes}
     * @memberof GetCashiersData
     */
    'attributes'?: GetCashiersAttributes;
}
/**
 *
 * @export
 * @interface GetCodiciComuni
 */
export interface GetCodiciComuni {
    /**
     * List of matching towns and their codes
     * @type {Array<GetCodiciComuniData>}
     * @memberof GetCodiciComuni
     */
    'data'?: Array<GetCodiciComuniData>;
}
/**
 *
 * @export
 * @interface GetCodiciComuniAttributes
 */
export interface GetCodiciComuniAttributes {
    /**
     *
     * @type {string}
     * @memberof GetCodiciComuniAttributes
     */
    'codice'?: string;
    /**
     *
     * @type {string}
     * @memberof GetCodiciComuniAttributes
     */
    'comune'?: string;
    /**
     *
     * @type {string}
     * @memberof GetCodiciComuniAttributes
     */
    'desc_provincia'?: string;
    /**
     *
     * @type {string}
     * @memberof GetCodiciComuniAttributes
     */
    'nazione'?: string;
    /**
     *
     * @type {string}
     * @memberof GetCodiciComuniAttributes
     */
    'provincia'?: string;
}
/**
 * Details of single town
 * @export
 * @interface GetCodiciComuniData
 */
export interface GetCodiciComuniData {
    /**
     * fixed data type \"codicicomuni\"
     * @type {string}
     * @memberof GetCodiciComuniData
     */
    'type'?: string;
    /**
     * Unique id of town code
     * @type {string}
     * @memberof GetCodiciComuniData
     */
    'id'?: string;
    /**
     *
     * @type {GetCodiciComuniAttributes}
     * @memberof GetCodiciComuniData
     */
    'attributes'?: GetCodiciComuniAttributes;
}
/**
 *
 * @export
 * @interface GetConsumer
 */
export interface GetConsumer {
    /**
     *
     * @type {Array<GetConsumerData>}
     * @memberof GetConsumer
     */
    'data'?: Array<GetConsumerData>;
}
/**
 *
 * @export
 * @interface GetConsumerAffiliations
 */
export interface GetConsumerAffiliations {
    /**
     *
     * @type {Array<GetConsumerAffiliationsData>}
     * @memberof GetConsumerAffiliations
     */
    'data'?: Array<GetConsumerAffiliationsData>;
}
/**
 *
 * @export
 * @interface GetConsumerAffiliationsData
 */
export interface GetConsumerAffiliationsData {
    /**
     * stato della fidelity
     * @type {string}
     * @memberof GetConsumerAffiliationsData
     */
    'status'?: string;
    /**
     * descrizione programma fidelity
     * @type {string}
     * @memberof GetConsumerAffiliationsData
     */
    'descrizione'?: string;
    /**
     * barcode card
     * @type {string}
     * @memberof GetConsumerAffiliationsData
     */
    'valore'?: string;
    /**
     * programma fidelity
     * @type {string}
     * @memberof GetConsumerAffiliationsData
     */
    'chiave'?: string;
    /**
     * punteggio attuale
     * @type {number}
     * @memberof GetConsumerAffiliationsData
     */
    'current_value'?: number;
}
/**
 *
 * @export
 * @interface GetConsumerAttributes
 */
export interface GetConsumerAttributes {
    /**
     * anno_nascita
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'anno_nascita'?: string;
    /**
     * anno_nascita_presunto
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'anno_nascita_presunto'?: string;
    /**
     * cap
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'cap'?: string;
    /**
     * cellulare1
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'cellulare1'?: string;
    /**
     * cellulare2
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'cellulare2'?: string;
    /**
     * cod_scheda
     * @type {number}
     * @memberof GetConsumerAttributes
     */
    'cod_scheda'?: number;
    /**
     * cognome
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'cognome'?: string;
    /**
     * data_firma_scheda
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'data_firma_scheda'?: string;
    /**
     * data_modifica
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'data_modifica'?: string;
    /**
     * data_prima_registrazione
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'data_prima_registrazione'?: string;
    /**
     * data_ultimo_aggiornamento
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'data_ultimo_aggiornamento'?: string;
    /**
     * edificio
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'edificio'?: string;
    /**
     * email
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'email'?: string;
    /**
     * giorno_nascita
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'giorno_nascita'?: string;
    /**
     * grado_anonimato
     * @type {number}
     * @memberof GetConsumerAttributes
     */
    'grado_anonimato'?: number;
    /**
     *
     * @type {Array<object>}
     * @memberof GetConsumerAttributes
     */
    'indirizzo_alfabeto'?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof GetConsumerAttributes
     */
    'nome_alfabeto'?: Array<object>;
    /**
     * indirizzo
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'indirizzo'?: string;
    /**
     * interessi
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'interessi'?: string;
    /**
     * lingua
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'lingua'?: string;
    /**
     * locale
     * @type {number}
     * @memberof GetConsumerAttributes
     */
    'locale'?: number;
    /**
     * localita
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'localita'?: string;
    /**
     * mese_nascita
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'mese_nascita'?: string;
    /**
     * nazionalita
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'nazionalita'?: string;
    /**
     * nazione_iso
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'nazione_iso'?: string;
    /**
     * negozio_firma_scheda
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'negozio_firma_scheda'?: string;
    /**
     * nome
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'nome'?: string;
    /**
     * non_contattare
     * @type {number}
     * @memberof GetConsumerAttributes
     */
    'non_contattare'?: number;
    /**
     * note
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'note'?: string;
    /**
     * note_indirizzo
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'note_indirizzo'?: string;
    /**
     * note_negozio
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'note_negozio'?: string;
    /**
     * numero_civico
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'numero_civico'?: string;
    /**
     * pk_consumer
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'pk_consumer'?: string;
    /**
     * pk_crm
     * @type {number}
     * @memberof GetConsumerAttributes
     */
    'pk_crm'?: number;
    /**
     * preferenze_contatto
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'preferenze_contatto'?: string;
    /**
     * professioni
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'professioni'?: string;
    /**
     * provincia
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'provincia'?: string;
    /**
     * salutation
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'salutation'?: string;
    /**
     * sesso
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'sesso'?: string;
    /**
     * staff
     * @type {number}
     * @memberof GetConsumerAttributes
     */
    'staff'?: number;
    /**
     * telefono1
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'telefono1'?: string;
    /**
     * telefono2
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'telefono2'?: string;
    /**
     * vip_1
     * @type {number}
     * @memberof GetConsumerAttributes
     */
    'vip_1'?: number;
    /**
     * vip_2
     * @type {number}
     * @memberof GetConsumerAttributes
     */
    'vip_2'?: number;
    /**
     * wechat
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'wechat'?: string;
    /**
     * agreement_profiling
     * @type {number}
     * @memberof GetConsumerAttributes
     */
    'agreement_profiling'?: number;
    /**
     * agreement_direct_marketing
     * @type {number}
     * @memberof GetConsumerAttributes
     */
    'agreement_direct_marketing'?: number;
    /**
     * card_associate
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'card_associate'?: string;
    /**
     *
     * @type {Array<GetConsumerAttributesUltimeModifiche>}
     * @memberof GetConsumerAttributes
     */
    'ultime_modifiche'?: Array<GetConsumerAttributesUltimeModifiche>;
    /**
     * card_associate
     * @type {string}
     * @memberof GetConsumerAttributes
     */
    'card_associate'?: string;
}
/**
 *
 * @export
 * @interface GetConsumerAttributesUltimeModifiche
 */
export interface GetConsumerAttributesUltimeModifiche {
    /**
     *
     * @type {string}
     * @memberof GetConsumerAttributesUltimeModifiche
     */
    'upd_timestamp'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerAttributesUltimeModifiche
     */
    'attivita'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerAttributesUltimeModifiche
     */
    'user'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerAttributesUltimeModifiche
     */
    'file_name'?: string;
}
/**
 *
 * @export
 * @interface GetConsumerCardsInfo
 */
export interface GetConsumerCardsInfo {
    /**
     *
     * @type {Array<GetConsumerCardsInfoData>}
     * @memberof GetConsumerCardsInfo
     */
    'data'?: Array<GetConsumerCardsInfoData>;
}
/**
 *
 * @export
 * @interface GetConsumerCardsInfo1
 */
export interface GetConsumerCardsInfo1 {
    /**
     *
     * @type {Array<ConsumerCardData>}
     * @memberof GetConsumerCardsInfo1
     */
    'data'?: Array<ConsumerCardData>;
}
/**
 *
 * @export
 * @interface GetConsumerCardsInfoAttributes
 */
export interface GetConsumerCardsInfoAttributes {
    /**
     * programma fidelity
     * @type {string}
     * @memberof GetConsumerCardsInfoAttributes
     */
    'chiave'?: string;
    /**
     * punteggio
     * @type {number}
     * @memberof GetConsumerCardsInfoAttributes
     */
    'current_value'?: number;
    /**
     * dati della card
     * @type {string}
     * @memberof GetConsumerCardsInfoAttributes
     */
    'custom_data'?: string;
    /**
     * data di disattivazione
     * @type {string}
     * @memberof GetConsumerCardsInfoAttributes
     */
    'data_fine'?: string;
    /**
     * data di attivazione
     * @type {string}
     * @memberof GetConsumerCardsInfoAttributes
     */
    'data_inizio'?: string;
    /**
     * data di modifica
     * @type {string}
     * @memberof GetConsumerCardsInfoAttributes
     */
    'data_modifica'?: string;
    /**
     * fascia della fidelity
     * @type {string}
     * @memberof GetConsumerCardsInfoAttributes
     */
    'fascia_attuale'?: string;
    /**
     * identificativo della consumatrice
     * @type {string}
     * @memberof GetConsumerCardsInfoAttributes
     */
    'pk_consumer'?: string;
    /**
     * possiblità di sartorie omaggio
     * @type {boolean}
     * @memberof GetConsumerCardsInfoAttributes
     */
    'sartorie_omaggio'?: boolean;
    /**
     * stato della fidelity
     * @type {string}
     * @memberof GetConsumerCardsInfoAttributes
     */
    'status'?: string;
    /**
     * barcode della fidelity
     * @type {string}
     * @memberof GetConsumerCardsInfoAttributes
     */
    'valore'?: string;
    /**
     * unità di misura dei punti
     * @type {string}
     * @memberof GetConsumerCardsInfoAttributes
     */
    'value_type'?: string;
    /**
     * indica se è possibile modificare i punti
     * @type {boolean}
     * @memberof GetConsumerCardsInfoAttributes
     */
    'forcing_point_enabled'?: boolean;
    /**
     * tipo di generazione della card
     * @type {string}
     * @memberof GetConsumerCardsInfoAttributes
     */
    'generation_mode'?: string;
    /**
     * nome del file sheda loyalty salvato su CRM
     * @type {string}
     * @memberof GetConsumerCardsInfoAttributes
     */
    'loyalty_signed_file_name'?: string;
}
/**
 *
 * @export
 * @interface GetConsumerCardsInfoData
 */
export interface GetConsumerCardsInfoData {
    /**
     *
     * @type {string}
     * @memberof GetConsumerCardsInfoData
     */
    'type'?: string;
    /**
     * <fidelity code>,<barcode card>
     * @type {string}
     * @memberof GetConsumerCardsInfoData
     */
    'id'?: string;
    /**
     *
     * @type {GetConsumerCardsInfoAttributes}
     * @memberof GetConsumerCardsInfoData
     */
    'attributes'?: GetConsumerCardsInfoAttributes;
    /**
     *
     * @type {GetConsumerCardsInfoRelationships}
     * @memberof GetConsumerCardsInfoData
     */
    'relationships'?: GetConsumerCardsInfoRelationships;
}
/**
 *
 * @export
 * @interface GetConsumerCardsInfoRelationships
 */
export interface GetConsumerCardsInfoRelationships {
    /**
     *
     * @type {GetConsumerCardsInfoRelationshipsConsumer}
     * @memberof GetConsumerCardsInfoRelationships
     */
    'consumer'?: GetConsumerCardsInfoRelationshipsConsumer;
}
/**
 *
 * @export
 * @interface GetConsumerCardsInfoRelationshipsConsumer
 */
export interface GetConsumerCardsInfoRelationshipsConsumer {
    /**
     *
     * @type {GetConsumerCardsInfoRelationshipsConsumerData}
     * @memberof GetConsumerCardsInfoRelationshipsConsumer
     */
    'data'?: GetConsumerCardsInfoRelationshipsConsumerData;
}
/**
 *
 * @export
 * @interface GetConsumerCardsInfoRelationshipsConsumerData
 */
export interface GetConsumerCardsInfoRelationshipsConsumerData {
    /**
     *
     * @type {string}
     * @memberof GetConsumerCardsInfoRelationshipsConsumerData
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerCardsInfoRelationshipsConsumerData
     */
    'id'?: string;
}
/**
 *
 * @export
 * @interface GetConsumerCashUsage
 */
export interface GetConsumerCashUsage {
    /**
     *
     * @type {GetConsumerCashUsageData}
     * @memberof GetConsumerCashUsage
     */
    'data'?: GetConsumerCashUsageData;
}
/**
 *
 * @export
 * @interface GetConsumerCashUsageData
 */
export interface GetConsumerCashUsageData {
    /**
     *
     * @type {string}
     * @memberof GetConsumerCashUsageData
     */
    'type'?: string;
    /**
     * Unique id
     * @type {string}
     * @memberof GetConsumerCashUsageData
     */
    'id'?: string;
    /**
     *
     * @type {GetConsumerCashUsageDataAttributes}
     * @memberof GetConsumerCashUsageData
     */
    'attributes'?: GetConsumerCashUsageDataAttributes;
}
/**
 *
 * @export
 * @interface GetConsumerCashUsageDataAttributes
 */
export interface GetConsumerCashUsageDataAttributes {
    /**
     * Cash payed amount today by consumer
     * @type {number}
     * @memberof GetConsumerCashUsageDataAttributes
     */
    'amount'?: number;
    /**
     * Remaining payable mount in cash
     * @type {number}
     * @memberof GetConsumerCashUsageDataAttributes
     */
    'payable_amount'?: number;
}
/**
 *
 * @export
 * @interface GetConsumerData
 */
export interface GetConsumerData {
    /**
     * Unique consumer id
     * @type {string}
     * @memberof GetConsumerData
     */
    'id'?: string;
    /**
     * fixed data type \"consumers\"
     * @type {string}
     * @memberof GetConsumerData
     */
    'type'?: string;
    /**
     *
     * @type {GetConsumerAttributes}
     * @memberof GetConsumerData
     */
    'attributes'?: GetConsumerAttributes;
}
/**
 *
 * @export
 * @interface GetConsumerExtraInfo
 */
export interface GetConsumerExtraInfo {
    /**
     *
     * @type {GetConsumerExtraInfoData}
     * @memberof GetConsumerExtraInfo
     */
    'data'?: GetConsumerExtraInfoData;
}
/**
 *
 * @export
 * @interface GetConsumerExtraInfoData
 */
export interface GetConsumerExtraInfoData {
    /**
     *
     * @type {string}
     * @memberof GetConsumerExtraInfoData
     */
    'type'?: string;
    /**
     * pk_consumer
     * @type {string}
     * @memberof GetConsumerExtraInfoData
     */
    'id'?: string;
    /**
     *
     * @type {GetConsumerExtraInfoDataAttributes}
     * @memberof GetConsumerExtraInfoData
     */
    'attributes'?: GetConsumerExtraInfoDataAttributes;
}
/**
 *
 * @export
 * @interface GetConsumerExtraInfoDataAttributes
 */
export interface GetConsumerExtraInfoDataAttributes {
    /**
     *
     * @type {GetConsumerExtraInfoDataAttributesSospesi}
     * @memberof GetConsumerExtraInfoDataAttributes
     */
    'sospesi'?: GetConsumerExtraInfoDataAttributesSospesi;
    /**
     *
     * @type {GetConsumerExtraInfoDataAttributesBuoni}
     * @memberof GetConsumerExtraInfoDataAttributes
     */
    'buoni'?: GetConsumerExtraInfoDataAttributesBuoni;
    /**
     *
     * @type {Array<GetConsumerExtraInfoDataAttributesLayby>}
     * @memberof GetConsumerExtraInfoDataAttributes
     */
    'layby'?: Array<GetConsumerExtraInfoDataAttributesLayby>;
    /**
     *
     * @type {Array<object>}
     * @memberof GetConsumerExtraInfoDataAttributes
     */
    'salesonapproval'?: Array<object>;
}
/**
 *
 * @export
 * @interface GetConsumerExtraInfoDataAttributesBuoni
 */
export interface GetConsumerExtraInfoDataAttributesBuoni {
    /**
     *
     * @type {Array<GetConsumerExtraInfoDataAttributesBuoniList>}
     * @memberof GetConsumerExtraInfoDataAttributesBuoni
     */
    'list'?: Array<GetConsumerExtraInfoDataAttributesBuoniList>;
    /**
     *
     * @type {number}
     * @memberof GetConsumerExtraInfoDataAttributesBuoni
     */
    'tot_amount'?: number;
}
/**
 *
 * @export
 * @interface GetConsumerExtraInfoDataAttributesBuoniList
 */
export interface GetConsumerExtraInfoDataAttributesBuoniList {
    /**
     *
     * @type {number}
     * @memberof GetConsumerExtraInfoDataAttributesBuoniList
     */
    'numero_documento'?: number;
    /**
     *
     * @type {string}
     * @memberof GetConsumerExtraInfoDataAttributesBuoniList
     */
    'data_documento'?: string;
    /**
     *
     * @type {number}
     * @memberof GetConsumerExtraInfoDataAttributesBuoniList
     */
    'importo_attuale'?: number;
}
/**
 *
 * @export
 * @interface GetConsumerExtraInfoDataAttributesLayby
 */
export interface GetConsumerExtraInfoDataAttributesLayby {
    /**
     *
     * @type {number}
     * @memberof GetConsumerExtraInfoDataAttributesLayby
     */
    'id_transazione'?: number;
    /**
     *
     * @type {string}
     * @memberof GetConsumerExtraInfoDataAttributesLayby
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {number}
     * @memberof GetConsumerExtraInfoDataAttributesLayby
     */
    'numero_documento'?: number;
    /**
     *
     * @type {string}
     * @memberof GetConsumerExtraInfoDataAttributesLayby
     */
    'numero_documento_orig'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerExtraInfoDataAttributesLayby
     */
    'data_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerExtraInfoDataAttributesLayby
     */
    'consumatrice'?: string;
    /**
     *
     * @type {number}
     * @memberof GetConsumerExtraInfoDataAttributesLayby
     */
    'numero_capi'?: number;
    /**
     *
     * @type {number}
     * @memberof GetConsumerExtraInfoDataAttributesLayby
     */
    'importo_totale'?: number;
    /**
     *
     * @type {number}
     * @memberof GetConsumerExtraInfoDataAttributesLayby
     */
    'balance'?: number;
    /**
     *
     * @type {number}
     * @memberof GetConsumerExtraInfoDataAttributesLayby
     */
    'layby_importo_pagato'?: number;
    /**
     *
     * @type {number}
     * @memberof GetConsumerExtraInfoDataAttributesLayby
     */
    'layby_acconto_minimo'?: number;
    /**
     *
     * @type {number}
     * @memberof GetConsumerExtraInfoDataAttributesLayby
     */
    'layby_importo_oltre_acconto_minimo'?: number;
    /**
     *
     * @type {number}
     * @memberof GetConsumerExtraInfoDataAttributesLayby
     */
    'layby_importo_mancante'?: number;
    /**
     *
     * @type {number}
     * @memberof GetConsumerExtraInfoDataAttributesLayby
     */
    'layby_importo_rendibile'?: number;
    /**
     *
     * @type {number}
     * @memberof GetConsumerExtraInfoDataAttributesLayby
     */
    'layby_importo_trattenuto'?: number;
    /**
     *
     * @type {string}
     * @memberof GetConsumerExtraInfoDataAttributesLayby
     */
    'expiring_date'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerExtraInfoDataAttributesLayby
     */
    'money_return_limit_date'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerExtraInfoDataAttributesLayby
     */
    'next_payment_date'?: string;
    /**
     *
     * @type {number}
     * @memberof GetConsumerExtraInfoDataAttributesLayby
     */
    'required_amount'?: number;
    /**
     *
     * @type {number}
     * @memberof GetConsumerExtraInfoDataAttributesLayby
     */
    'total_paid_amount'?: number;
}
/**
 *
 * @export
 * @interface GetConsumerExtraInfoDataAttributesSospesi
 */
export interface GetConsumerExtraInfoDataAttributesSospesi {
    /**
     *
     * @type {Array<GetConsumerExtraInfoDataAttributesSospesiList>}
     * @memberof GetConsumerExtraInfoDataAttributesSospesi
     */
    'list'?: Array<GetConsumerExtraInfoDataAttributesSospesiList>;
    /**
     *
     * @type {number}
     * @memberof GetConsumerExtraInfoDataAttributesSospesi
     */
    'tot_amount'?: number;
}
/**
 *
 * @export
 * @interface GetConsumerExtraInfoDataAttributesSospesiList
 */
export interface GetConsumerExtraInfoDataAttributesSospesiList {
    /**
     *
     * @type {number}
     * @memberof GetConsumerExtraInfoDataAttributesSospesiList
     */
    'numero_documento'?: number;
    /**
     *
     * @type {string}
     * @memberof GetConsumerExtraInfoDataAttributesSospesiList
     */
    'data_documento'?: string;
    /**
     *
     * @type {number}
     * @memberof GetConsumerExtraInfoDataAttributesSospesiList
     */
    'importo'?: number;
}
/**
 *
 * @export
 * @interface GetConsumerInvoicingData
 */
export interface GetConsumerInvoicingData {
    /**
     *
     * @type {GetConsumerInvoicingDataData}
     * @memberof GetConsumerInvoicingData
     */
    'data'?: GetConsumerInvoicingDataData;
}
/**
 *
 * @export
 * @interface GetConsumerInvoicingDataData
 */
export interface GetConsumerInvoicingDataData {
    /**
     * fixed data type \"consumers-invoice\"
     * @type {string}
     * @memberof GetConsumerInvoicingDataData
     */
    'type'?: string;
    /**
     * Unique client id
     * @type {string}
     * @memberof GetConsumerInvoicingDataData
     */
    'id'?: string;
    /**
     *
     * @type {GetConsumerInvoicingDataDataAttributes}
     * @memberof GetConsumerInvoicingDataData
     */
    'attributes'?: GetConsumerInvoicingDataDataAttributes;
}
/**
 *
 * @export
 * @interface GetConsumerInvoicingDataDataAttributes
 */
export interface GetConsumerInvoicingDataDataAttributes {
    /**
     *
     * @type {string}
     * @memberof GetConsumerInvoicingDataDataAttributes
     */
    'p_iva_cee'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerInvoicingDataDataAttributes
     */
    'cap'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerInvoicingDataDataAttributes
     */
    'email_amb'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerInvoicingDataDataAttributes
     */
    'p_iva'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerInvoicingDataDataAttributes
     */
    'ragione_sociale_2'?: string;
    /**
     *
     * @type {number}
     * @memberof GetConsumerInvoicingDataDataAttributes
     */
    'id_soggetto'?: number;
    /**
     *
     * @type {string}
     * @memberof GetConsumerInvoicingDataDataAttributes
     */
    'ragione_sociale_1'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerInvoicingDataDataAttributes
     */
    'telefono_amb'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerInvoicingDataDataAttributes
     */
    'numero_civico'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerInvoicingDataDataAttributes
     */
    'soggetto_fisico'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerInvoicingDataDataAttributes
     */
    'cod_fiscale'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerInvoicingDataDataAttributes
     */
    'comune_nascita_amb'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerInvoicingDataDataAttributes
     */
    'indirizzo'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerInvoicingDataDataAttributes
     */
    'provincia'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerInvoicingDataDataAttributes
     */
    'codice_sdi'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerInvoicingDataDataAttributes
     */
    'nome'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerInvoicingDataDataAttributes
     */
    'cognome'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerInvoicingDataDataAttributes
     */
    'data_nascita_amb'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerInvoicingDataDataAttributes
     */
    'stato'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerInvoicingDataDataAttributes
     */
    'sesso_amb'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerInvoicingDataDataAttributes
     */
    'localita'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerInvoicingDataDataAttributes
     */
    'pec'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerInvoicingDataDataAttributes
     */
    'nominativo'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerInvoicingDataDataAttributes
     */
    'tipo_cliente'?: string;
}
/**
 *
 * @export
 * @interface GetConsumerPdfContactForm
 */
export interface GetConsumerPdfContactForm {
    /**
     *
     * @type {GetConsumerPdfContactFormData}
     * @memberof GetConsumerPdfContactForm
     */
    'data'?: GetConsumerPdfContactFormData;
}
/**
 *
 * @export
 * @interface GetConsumerPdfContactFormData
 */
export interface GetConsumerPdfContactFormData {
    /**
     * Unique id
     * @type {string}
     * @memberof GetConsumerPdfContactFormData
     */
    'id'?: string;
    /**
     *
     * @type {GetConsumerPdfContactFormDataAttributes}
     * @memberof GetConsumerPdfContactFormData
     */
    'attributes'?: GetConsumerPdfContactFormDataAttributes;
}
/**
 *
 * @export
 * @interface GetConsumerPdfContactFormDataAttributes
 */
export interface GetConsumerPdfContactFormDataAttributes {
    /**
     *
     * @type {GetConsumerPdfContactFormDataAttributesBrowserDownload}
     * @memberof GetConsumerPdfContactFormDataAttributes
     */
    'browser_download'?: GetConsumerPdfContactFormDataAttributesBrowserDownload;
}
/**
 *
 * @export
 * @interface GetConsumerPdfContactFormDataAttributesBrowserDownload
 */
export interface GetConsumerPdfContactFormDataAttributesBrowserDownload {
    /**
     * file pdf content base64
     * @type {string}
     * @memberof GetConsumerPdfContactFormDataAttributesBrowserDownload
     */
    'pdf_content'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerPdfContactFormDataAttributesBrowserDownload
     */
    'file_name'?: string;
}
/**
 *
 * @export
 * @interface GetConsumerSchedaLingue
 */
export interface GetConsumerSchedaLingue {
    /**
     *
     * @type {GetConsumerSchedaLingueData}
     * @memberof GetConsumerSchedaLingue
     */
    'data'?: GetConsumerSchedaLingueData;
}
/**
 *
 * @export
 * @interface GetConsumerSchedaLingueData
 */
export interface GetConsumerSchedaLingueData {
    /**
     *
     * @type {string}
     * @memberof GetConsumerSchedaLingueData
     */
    'type'?: string;
    /**
     *
     * @type {Array<GetConsumerSchedaLingueDataAttributes>}
     * @memberof GetConsumerSchedaLingueData
     */
    'attributes'?: Array<GetConsumerSchedaLingueDataAttributes>;
}
/**
 *
 * @export
 * @interface GetConsumerSchedaLingueDataAttributes
 */
export interface GetConsumerSchedaLingueDataAttributes {
    /**
     *
     * @type {string}
     * @memberof GetConsumerSchedaLingueDataAttributes
     */
    'language'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerSchedaLingueDataAttributes
     */
    'locale'?: string;
}
/**
 *
 * @export
 * @interface GetConsumerStatistics
 */
export interface GetConsumerStatistics {
    /**
     *
     * @type {string}
     * @memberof GetConsumerStatistics
     */
    'provenienza'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerStatistics
     */
    'da_online'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerStatistics
     */
    'data_prima_registrazione'?: string;
    /**
     *
     * @type {GetConsumerStatisticsStatistiche}
     * @memberof GetConsumerStatistics
     */
    'statistiche'?: GetConsumerStatisticsStatistiche;
}
/**
 *
 * @export
 * @interface GetConsumerStatisticsStatistiche
 */
export interface GetConsumerStatisticsStatistiche {
    /**
     *
     * @type {GetConsumerStatisticsStatisticheBrand}
     * @memberof GetConsumerStatisticsStatistiche
     */
    'brand'?: GetConsumerStatisticsStatisticheBrand;
    /**
     *
     * @type {GetConsumerStatisticsStatisticheNegozio}
     * @memberof GetConsumerStatisticsStatistiche
     */
    'negozio'?: GetConsumerStatisticsStatisticheNegozio;
    /**
     *
     * @type {GetConsumerStatisticsStatisticheAltri}
     * @memberof GetConsumerStatisticsStatistiche
     */
    'altri'?: GetConsumerStatisticsStatisticheAltri;
}
/**
 *
 * @export
 * @interface GetConsumerStatisticsStatisticheAltri
 */
export interface GetConsumerStatisticsStatisticheAltri {
    /**
     *
     * @type {any}
     * @memberof GetConsumerStatisticsStatisticheAltri
     */
    'top_classi'?: any;
    /**
     *
     * @type {any}
     * @memberof GetConsumerStatisticsStatisticheAltri
     */
    'top_modelli'?: any;
    /**
     *
     * @type {any}
     * @memberof GetConsumerStatisticsStatisticheAltri
     */
    'ultimi_acquisti_aggregato'?: any;
}
/**
 *
 * @export
 * @interface GetConsumerStatisticsStatisticheBrand
 */
export interface GetConsumerStatisticsStatisticheBrand {
    /**
     *
     * @type {any}
     * @memberof GetConsumerStatisticsStatisticheBrand
     */
    'top_classi'?: any;
    /**
     *
     * @type {any}
     * @memberof GetConsumerStatisticsStatisticheBrand
     */
    'top_modelli'?: any;
    /**
     *
     * @type {Array<GetConsumerStatisticsStatisticheBrandUltimiAcquistiAggregato>}
     * @memberof GetConsumerStatisticsStatisticheBrand
     */
    'ultimi_acquisti_aggregato'?: Array<GetConsumerStatisticsStatisticheBrandUltimiAcquistiAggregato>;
}
/**
 *
 * @export
 * @interface GetConsumerStatisticsStatisticheBrandUltimiAcquistiAggregato
 */
export interface GetConsumerStatisticsStatisticheBrandUltimiAcquistiAggregato {
    /**
     *
     * @type {string}
     * @memberof GetConsumerStatisticsStatisticheBrandUltimiAcquistiAggregato
     */
    'data_fine_periodo'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerStatisticsStatisticheBrandUltimiAcquistiAggregato
     */
    'brand'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerStatisticsStatisticheBrandUltimiAcquistiAggregato
     */
    'monetary'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerStatisticsStatisticheBrandUltimiAcquistiAggregato
     */
    'loyalty'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerStatisticsStatisticheBrandUltimiAcquistiAggregato
     */
    'data_inizio_periodo'?: string;
}
/**
 *
 * @export
 * @interface GetConsumerStatisticsStatisticheNegozio
 */
export interface GetConsumerStatisticsStatisticheNegozio {
    /**
     *
     * @type {any}
     * @memberof GetConsumerStatisticsStatisticheNegozio
     */
    'ultimi_acquisti_aggregato'?: any;
    /**
     *
     * @type {GetConsumerStatisticsStatisticheNegozioUltimiAcquistiDettaglio}
     * @memberof GetConsumerStatisticsStatisticheNegozio
     */
    'ultimi_acquisti_dettaglio'?: GetConsumerStatisticsStatisticheNegozioUltimiAcquistiDettaglio;
    /**
     *
     * @type {any}
     * @memberof GetConsumerStatisticsStatisticheNegozio
     */
    'top_modelli'?: any;
    /**
     *
     * @type {string}
     * @memberof GetConsumerStatisticsStatisticheNegozio
     */
    'data_ultimo_acquisto'?: string;
    /**
     *
     * @type {GetConsumerStatisticsStatisticheNegozioTopClassi}
     * @memberof GetConsumerStatisticsStatisticheNegozio
     */
    'top_classi'?: GetConsumerStatisticsStatisticheNegozioTopClassi;
}
/**
 *
 * @export
 * @interface GetConsumerStatisticsStatisticheNegozioTopClassi
 */
export interface GetConsumerStatisticsStatisticheNegozioTopClassi {
    /**
     *
     * @type {string}
     * @memberof GetConsumerStatisticsStatisticheNegozioTopClassi
     */
    'data_fine_periodo'?: string;
    /**
     *
     * @type {Array<GetConsumerStatisticsStatisticheNegozioTopClassiClassi>}
     * @memberof GetConsumerStatisticsStatisticheNegozioTopClassi
     */
    'classi'?: Array<GetConsumerStatisticsStatisticheNegozioTopClassiClassi>;
    /**
     *
     * @type {string}
     * @memberof GetConsumerStatisticsStatisticheNegozioTopClassi
     */
    'data_inizio_periodo'?: string;
}
/**
 *
 * @export
 * @interface GetConsumerStatisticsStatisticheNegozioTopClassiClassi
 */
export interface GetConsumerStatisticsStatisticheNegozioTopClassiClassi {
    /**
     *
     * @type {string}
     * @memberof GetConsumerStatisticsStatisticheNegozioTopClassiClassi
     */
    'classe'?: string;
}
/**
 *
 * @export
 * @interface GetConsumerStatisticsStatisticheNegozioUltimiAcquistiDettaglio
 */
export interface GetConsumerStatisticsStatisticheNegozioUltimiAcquistiDettaglio {
    /**
     *
     * @type {any}
     * @memberof GetConsumerStatisticsStatisticheNegozioUltimiAcquistiDettaglio
     */
    'vendite'?: any;
    /**
     *
     * @type {string}
     * @memberof GetConsumerStatisticsStatisticheNegozioUltimiAcquistiDettaglio
     */
    'data_fine_periodo'?: string;
    /**
     *
     * @type {string}
     * @memberof GetConsumerStatisticsStatisticheNegozioUltimiAcquistiDettaglio
     */
    'data_inizio_periodo'?: string;
}
/**
 *
 * @export
 * @interface GetCountries
 */
export interface GetCountries {
    /**
     *
     * @type {Array<GetCountriesData>}
     * @memberof GetCountries
     */
    'data'?: Array<GetCountriesData>;
}
/**
 *
 * @export
 * @interface GetCountries1
 */
export interface GetCountries1 {
    /**
     *
     * @type {Array<GetCountries1Data>}
     * @memberof GetCountries1
     */
    'data'?: Array<GetCountries1Data>;
}
/**
 *
 * @export
 * @interface GetCountries1Attributes
 */
export interface GetCountries1Attributes {
    /**
     *
     * @type {string}
     * @memberof GetCountries1Attributes
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {string}
     * @memberof GetCountries1Attributes
     */
    'country_code'?: string;
    /**
     *
     * @type {string}
     * @memberof GetCountries1Attributes
     */
    'descrizione'?: string;
    /**
     *
     * @type {string}
     * @memberof GetCountries1Attributes
     */
    'document_country_code'?: string;
    /**
     *
     * @type {string}
     * @memberof GetCountries1Attributes
     */
    'nazione'?: string;
    /**
     *
     * @type {string}
     * @memberof GetCountries1Attributes
     */
    'passport_code'?: string;
    /**
     *
     * @type {number}
     * @memberof GetCountries1Attributes
     */
    'taxfree_enabled'?: number;
}
/**
 *
 * @export
 * @interface GetCountries1Data
 */
export interface GetCountries1Data {
    /**
     * fixed data type \"taxfree-countries\"
     * @type {string}
     * @memberof GetCountries1Data
     */
    'type'?: string;
    /**
     * Unique id, made of store code and country code
     * @type {string}
     * @memberof GetCountries1Data
     */
    'id'?: string;
    /**
     *
     * @type {GetCountries1Attributes}
     * @memberof GetCountries1Data
     */
    'attributes'?: GetCountries1Attributes;
}
/**
 *
 * @export
 * @interface GetCountriesAnonymous
 */
export interface GetCountriesAnonymous {
    /**
     *
     * @type {Array<GetCountriesAnonymousData>}
     * @memberof GetCountriesAnonymous
     */
    'data'?: Array<GetCountriesAnonymousData>;
}
/**
 *
 * @export
 * @interface GetCountriesAnonymousData
 */
export interface GetCountriesAnonymousData {
    /**
     *
     * @type {string}
     * @memberof GetCountriesAnonymousData
     */
    'type'?: string;
    /**
     * Unique id
     * @type {string}
     * @memberof GetCountriesAnonymousData
     */
    'id'?: string;
    /**
     *
     * @type {GetCountriesAttributes}
     * @memberof GetCountriesAnonymousData
     */
    'attributes'?: GetCountriesAttributes;
}
/**
 *
 * @export
 * @interface GetCountriesAttributes
 */
export interface GetCountriesAttributes {
    /**
     *
     * @type {string}
     * @memberof GetCountriesAttributes
     */
    'data_modifica'?: string;
    /**
     *
     * @type {string}
     * @memberof GetCountriesAttributes
     */
    'descrizione'?: string;
    /**
     *
     * @type {number}
     * @memberof GetCountriesAttributes
     */
    'flag_antiriciclaggio'?: number;
    /**
     *
     * @type {number}
     * @memberof GetCountriesAttributes
     */
    'flag_ue'?: number;
    /**
     *
     * @type {string}
     * @memberof GetCountriesAttributes
     */
    'iso_3'?: string;
    /**
     *
     * @type {string}
     * @memberof GetCountriesAttributes
     */
    'iso_num'?: string;
    /**
     *
     * @type {string}
     * @memberof GetCountriesAttributes
     */
    'nazione_iso'?: string;
}
/**
 *
 * @export
 * @interface GetCountriesData
 */
export interface GetCountriesData {
    /**
     *
     * @type {string}
     * @memberof GetCountriesData
     */
    'type'?: string;
    /**
     * Unique id
     * @type {string}
     * @memberof GetCountriesData
     */
    'id'?: string;
    /**
     *
     * @type {GetCountriesAttributes}
     * @memberof GetCountriesData
     */
    'attributes'?: GetCountriesAttributes;
}
/**
 *
 * @export
 * @interface GetCreditCardCircuits
 */
export interface GetCreditCardCircuits {
    /**
     *
     * @type {GetCreditCardCircuitsData}
     * @memberof GetCreditCardCircuits
     */
    'data'?: GetCreditCardCircuitsData;
}
/**
 *
 * @export
 * @interface GetCreditCardCircuits1
 */
export interface GetCreditCardCircuits1 {
    /**
     *
     * @type {GetCreditCardCircuits1Data}
     * @memberof GetCreditCardCircuits1
     */
    'data'?: GetCreditCardCircuits1Data;
}
/**
 *
 * @export
 * @interface GetCreditCardCircuits1Data
 */
export interface GetCreditCardCircuits1Data {
    /**
     * fixed data type \"document-types\"
     * @type {string}
     * @memberof GetCreditCardCircuits1Data
     */
    'type'?: string;
    /**
     * <codice_gruppo>,<codice_documento>
     * @type {string}
     * @memberof GetCreditCardCircuits1Data
     */
    'id'?: string;
    /**
     *
     * @type {GetCreditCardCircuits1DataAttributes}
     * @memberof GetCreditCardCircuits1Data
     */
    'attributes'?: GetCreditCardCircuits1DataAttributes;
}
/**
 *
 * @export
 * @interface GetCreditCardCircuits1DataAttributes
 */
export interface GetCreditCardCircuits1DataAttributes {
    /**
     * id type id
     * @type {string}
     * @memberof GetCreditCardCircuits1DataAttributes
     */
    'codice_documento'?: string;
    /**
     * payment or sale type section
     * @type {string}
     * @memberof GetCreditCardCircuits1DataAttributes
     */
    'codice_gruppo'?: string;
    /**
     * description in user language
     * @type {string}
     * @memberof GetCreditCardCircuits1DataAttributes
     */
    'descrizione'?: string;
    /**
     * internal id to use in sale payload
     * @type {string}
     * @memberof GetCreditCardCircuits1DataAttributes
     */
    'id_tipo_documento'?: string;
    /**
     * order in select
     * @type {number}
     * @memberof GetCreditCardCircuits1DataAttributes
     */
    'progressivo'?: number;
}
/**
 *
 * @export
 * @interface GetCreditCardCircuitsData
 */
export interface GetCreditCardCircuitsData {
    /**
     * fixed data type \"credit-card\"
     * @type {string}
     * @memberof GetCreditCardCircuitsData
     */
    'type'?: string;
    /**
     * <store_code>,<circuit_corporate_code>
     * @type {string}
     * @memberof GetCreditCardCircuitsData
     */
    'id'?: string;
    /**
     *
     * @type {GetCreditCardCircuitsDataAttributes}
     * @memberof GetCreditCardCircuitsData
     */
    'attributes'?: GetCreditCardCircuitsDataAttributes;
}
/**
 *
 * @export
 * @interface GetCreditCardCircuitsDataAttributes
 */
export interface GetCreditCardCircuitsDataAttributes {
    /**
     * Store Code
     * @type {string}
     * @memberof GetCreditCardCircuitsDataAttributes
     */
    'cod_negozio'?: string;
    /**
     * Acquirer credit card circuit code
     * @type {string}
     * @memberof GetCreditCardCircuitsDataAttributes
     */
    'codice_aquirer'?: string;
    /**
     * Corporate credit card circuit code
     * @type {string}
     * @memberof GetCreditCardCircuitsDataAttributes
     */
    'codice_compagnia'?: string;
    /**
     * Credit card circuit name
     * @type {string}
     * @memberof GetCreditCardCircuitsDataAttributes
     */
    'descr_tipo_carta'?: string;
}
/**
 *
 * @export
 * @interface GetCustomerPriceList
 */
export interface GetCustomerPriceList {
    /**
     *
     * @type {GetCustomerPriceListData}
     * @memberof GetCustomerPriceList
     */
    'data'?: GetCustomerPriceListData;
}
/**
 *
 * @export
 * @interface GetCustomerPriceListData
 */
export interface GetCustomerPriceListData {
    /**
     *
     * @type {string}
     * @memberof GetCustomerPriceListData
     */
    'listino_attivo'?: string;
}
/**
 *
 * @export
 * @interface GetDiscounts
 */
export interface GetDiscounts {
    /**
     *
     * @type {GetDiscountsData}
     * @memberof GetDiscounts
     */
    'data'?: GetDiscountsData;
}
/**
 *
 * @export
 * @interface GetDiscountsData
 */
export interface GetDiscountsData {
    /**
     *
     * @type {string}
     * @memberof GetDiscountsData
     */
    'type'?: string;
    /**
     * Unique id
     * @type {string}
     * @memberof GetDiscountsData
     */
    'id'?: string;
    /**
     *
     * @type {GetDiscountsDataAttributes}
     * @memberof GetDiscountsData
     */
    'attributes'?: GetDiscountsDataAttributes;
}
/**
 *
 * @export
 * @interface GetDiscountsDataAttributes
 */
export interface GetDiscountsDataAttributes {
    /**
     * discount code
     * @type {string}
     * @memberof GetDiscountsDataAttributes
     */
    'cod_sconto'?: string;
    /**
     * discount label code
     * @type {string}
     * @memberof GetDiscountsDataAttributes
     */
    'descrizione'?: string;
    /**
     * country for witch is avaiable
     * @type {string}
     * @memberof GetDiscountsDataAttributes
     */
    'nazione'?: string;
    /**
     * percentage of the discount
     * @type {number}
     * @memberof GetDiscountsDataAttributes
     */
    'val_sconto'?: number;
}
/**
 *
 * @export
 * @interface GetDocTypesReintegri
 */
export interface GetDocTypesReintegri {
    /**
     *
     * @type {GetDocTypesReintegriData}
     * @memberof GetDocTypesReintegri
     */
    'data'?: GetDocTypesReintegriData;
}
/**
 *
 * @export
 * @interface GetDocTypesReintegriData
 */
export interface GetDocTypesReintegriData {
    /**
     * fixed data type \"reintegri\"
     * @type {string}
     * @memberof GetDocTypesReintegriData
     */
    'type'?: string;
    /**
     * <codice_negozio><id_documento>
     * @type {string}
     * @memberof GetDocTypesReintegriData
     */
    'id'?: string;
    /**
     *
     * @type {GetDocTypesReintegriDataAttributes}
     * @memberof GetDocTypesReintegriData
     */
    'attributes'?: GetDocTypesReintegriDataAttributes;
}
/**
 *
 * @export
 * @interface GetDocTypesReintegriDataAttributes
 */
export interface GetDocTypesReintegriDataAttributes {
    /**
     * document code
     * @type {string}
     * @memberof GetDocTypesReintegriDataAttributes
     */
    'codice'?: string;
    /**
     * description in user language
     * @type {string}
     * @memberof GetDocTypesReintegriDataAttributes
     */
    'descrizione'?: string;
}
/**
 *
 * @export
 * @interface GetDocumentTypes
 */
export interface GetDocumentTypes {
    /**
     *
     * @type {Array<GetDocumentTypesData>}
     * @memberof GetDocumentTypes
     */
    'data'?: Array<GetDocumentTypesData>;
}
/**
 * Attributes
 * @export
 * @interface GetDocumentTypesAttributes
 */
export interface GetDocumentTypesAttributes {
    /**
     * Document code
     * @type {string}
     * @memberof GetDocumentTypesAttributes
     */
    'codice_documento'?: string;
    /**
     * Document Group code
     * @type {string}
     * @memberof GetDocumentTypesAttributes
     */
    'codice_gruppo'?: string;
    /**
     * Description of document type
     * @type {string}
     * @memberof GetDocumentTypesAttributes
     */
    'descrizione'?: string;
    /**
     * Id of document type
     * @type {string}
     * @memberof GetDocumentTypesAttributes
     */
    'id_tipo_documento'?: string;
    /**
     * Progressivo
     * @type {number}
     * @memberof GetDocumentTypesAttributes
     */
    'progressivo'?: number;
}
/**
 * Enabled document type entities
 * @export
 * @interface GetDocumentTypesData
 */
export interface GetDocumentTypesData {
    /**
     * Type of node
     * @type {string}
     * @memberof GetDocumentTypesData
     */
    'type'?: string;
    /**
     * Document type id code, <codice_gruppo>, <codice_documento>
     * @type {string}
     * @memberof GetDocumentTypesData
     */
    'id'?: string;
    /**
     *
     * @type {GetDocumentTypesAttributes}
     * @memberof GetDocumentTypesData
     */
    'attributes'?: GetDocumentTypesAttributes;
}
/**
 *
 * @export
 * @interface GetInsegnaConsumerStatistics
 */
export interface GetInsegnaConsumerStatistics {
    /**
     *
     * @type {GetInsegnaConsumerStatisticsKpiGruppo}
     * @memberof GetInsegnaConsumerStatistics
     */
    'kpi_gruppo'?: GetInsegnaConsumerStatisticsKpiGruppo;
    /**
     *
     * @type {Array<GetInsegnaConsumerStatisticsStoricoContattiRetail>}
     * @memberof GetInsegnaConsumerStatistics
     */
    'storico_contatti_retail'?: Array<GetInsegnaConsumerStatisticsStoricoContattiRetail>;
    /**
     *
     * @type {any}
     * @memberof GetInsegnaConsumerStatistics
     */
    'ultimi_acquisti'?: any;
    /**
     *
     * @type {string}
     * @memberof GetInsegnaConsumerStatistics
     */
    'data_ultimo_acquisto'?: string;
    /**
     *
     * @type {any}
     * @memberof GetInsegnaConsumerStatistics
     */
    'top_classi'?: any;
    /**
     *
     * @type {Array<GetInsegnaConsumerStatisticsStoricoContattiDigital>}
     * @memberof GetInsegnaConsumerStatistics
     */
    'storico_contatti_digital'?: Array<GetInsegnaConsumerStatisticsStoricoContattiDigital>;
    /**
     *
     * @type {GetInsegnaConsumerStatisticsKpiInsegna}
     * @memberof GetInsegnaConsumerStatistics
     */
    'kpi_insegna'?: GetInsegnaConsumerStatisticsKpiInsegna;
    /**
     *
     * @type {boolean}
     * @memberof GetInsegnaConsumerStatistics
     */
    'has_my_account'?: boolean;
    /**
     *
     * @type {number}
     * @memberof GetInsegnaConsumerStatistics
     */
    'n_case_aperti'?: number;
    /**
     *
     * @type {Array<GetInsegnaConsumerStatisticsEventiPartecipati>}
     * @memberof GetInsegnaConsumerStatistics
     */
    'eventi_partecipati'?: Array<GetInsegnaConsumerStatisticsEventiPartecipati>;
}
/**
 *
 * @export
 * @interface GetInsegnaConsumerStatisticsEventiPartecipati
 */
export interface GetInsegnaConsumerStatisticsEventiPartecipati {
    /**
     *
     * @type {string}
     * @memberof GetInsegnaConsumerStatisticsEventiPartecipati
     */
    'iniziativa'?: string;
    /**
     *
     * @type {string}
     * @memberof GetInsegnaConsumerStatisticsEventiPartecipati
     */
    'nome_store'?: string;
    /**
     *
     * @type {string}
     * @memberof GetInsegnaConsumerStatisticsEventiPartecipati
     */
    'data'?: string;
    /**
     *
     * @type {string}
     * @memberof GetInsegnaConsumerStatisticsEventiPartecipati
     */
    'nazione_negozio'?: string;
}
/**
 *
 * @export
 * @interface GetInsegnaConsumerStatisticsKpiGruppo
 */
export interface GetInsegnaConsumerStatisticsKpiGruppo {
    /**
     * it\'s decimal value
     * @type {number}
     * @memberof GetInsegnaConsumerStatisticsKpiGruppo
     */
    'cltv'?: number;
    /**
     *
     * @type {GetInsegnaConsumerStatisticsKpiGruppoRecency}
     * @memberof GetInsegnaConsumerStatisticsKpiGruppo
     */
    'recency'?: GetInsegnaConsumerStatisticsKpiGruppoRecency;
}
/**
 *
 * @export
 * @interface GetInsegnaConsumerStatisticsKpiGruppoRecency
 */
export interface GetInsegnaConsumerStatisticsKpiGruppoRecency {
    /**
     *
     * @type {string}
     * @memberof GetInsegnaConsumerStatisticsKpiGruppoRecency
     */
    'value'?: string;
}
/**
 *
 * @export
 * @interface GetInsegnaConsumerStatisticsKpiInsegna
 */
export interface GetInsegnaConsumerStatisticsKpiInsegna {
    /**
     *
     * @type {GetInsegnaConsumerStatisticsKpiInsegnaMonetaryRegional}
     * @memberof GetInsegnaConsumerStatisticsKpiInsegna
     */
    'monetary_regional'?: GetInsegnaConsumerStatisticsKpiInsegnaMonetaryRegional;
    /**
     * it\'s decimal value
     * @type {number}
     * @memberof GetInsegnaConsumerStatisticsKpiInsegna
     */
    'cltv'?: number;
    /**
     *
     * @type {GetInsegnaConsumerStatisticsKpiInsegnaMonetaryRegional}
     * @memberof GetInsegnaConsumerStatisticsKpiInsegna
     */
    'monetary_worldwide'?: GetInsegnaConsumerStatisticsKpiInsegnaMonetaryRegional;
    /**
     *
     * @type {string}
     * @memberof GetInsegnaConsumerStatisticsKpiInsegna
     */
    'cluster'?: string;
    /**
     *
     * @type {GetInsegnaConsumerStatisticsKpiInsegnaFrequency}
     * @memberof GetInsegnaConsumerStatisticsKpiInsegna
     */
    'frequency'?: GetInsegnaConsumerStatisticsKpiInsegnaFrequency;
    /**
     *
     * @type {GetInsegnaConsumerStatisticsKpiInsegnaRecency}
     * @memberof GetInsegnaConsumerStatisticsKpiInsegna
     */
    'recency'?: GetInsegnaConsumerStatisticsKpiInsegnaRecency;
    /**
     *
     * @type {string}
     * @memberof GetInsegnaConsumerStatisticsKpiInsegna
     */
    'propensity_churn'?: string;
}
/**
 *
 * @export
 * @interface GetInsegnaConsumerStatisticsKpiInsegnaFrequency
 */
export interface GetInsegnaConsumerStatisticsKpiInsegnaFrequency {
    /**
     *
     * @type {any}
     * @memberof GetInsegnaConsumerStatisticsKpiInsegnaFrequency
     */
    'possible_values'?: any;
    /**
     *
     * @type {string}
     * @memberof GetInsegnaConsumerStatisticsKpiInsegnaFrequency
     */
    'value'?: string;
}
/**
 *
 * @export
 * @interface GetInsegnaConsumerStatisticsKpiInsegnaMonetaryRegional
 */
export interface GetInsegnaConsumerStatisticsKpiInsegnaMonetaryRegional {
    /**
     *
     * @type {any}
     * @memberof GetInsegnaConsumerStatisticsKpiInsegnaMonetaryRegional
     */
    'possible_values'?: any;
    /**
     *
     * @type {string}
     * @memberof GetInsegnaConsumerStatisticsKpiInsegnaMonetaryRegional
     */
    'value'?: string;
}
/**
 *
 * @export
 * @interface GetInsegnaConsumerStatisticsKpiInsegnaRecency
 */
export interface GetInsegnaConsumerStatisticsKpiInsegnaRecency {
    /**
     *
     * @type {any}
     * @memberof GetInsegnaConsumerStatisticsKpiInsegnaRecency
     */
    'possible_values'?: any;
    /**
     *
     * @type {string}
     * @memberof GetInsegnaConsumerStatisticsKpiInsegnaRecency
     */
    'value'?: string;
}
/**
 *
 * @export
 * @interface GetInsegnaConsumerStatisticsStoricoContattiDigital
 */
export interface GetInsegnaConsumerStatisticsStoricoContattiDigital {
    /**
     *
     * @type {string}
     * @memberof GetInsegnaConsumerStatisticsStoricoContattiDigital
     */
    'tipo'?: string;
    /**
     *
     * @type {string}
     * @memberof GetInsegnaConsumerStatisticsStoricoContattiDigital
     */
    'data'?: string;
    /**
     *
     * @type {string}
     * @memberof GetInsegnaConsumerStatisticsStoricoContattiDigital
     */
    'canale'?: string;
    /**
     *
     * @type {string}
     * @memberof GetInsegnaConsumerStatisticsStoricoContattiDigital
     */
    'iniziativa'?: string;
}
/**
 *
 * @export
 * @interface GetInsegnaConsumerStatisticsStoricoContattiRetail
 */
export interface GetInsegnaConsumerStatisticsStoricoContattiRetail {
    /**
     *
     * @type {string}
     * @memberof GetInsegnaConsumerStatisticsStoricoContattiRetail
     */
    'tipo'?: string;
    /**
     *
     * @type {string}
     * @memberof GetInsegnaConsumerStatisticsStoricoContattiRetail
     */
    'data'?: string;
    /**
     *
     * @type {string}
     * @memberof GetInsegnaConsumerStatisticsStoricoContattiRetail
     */
    'canale'?: string;
    /**
     *
     * @type {string}
     * @memberof GetInsegnaConsumerStatisticsStoricoContattiRetail
     */
    'iniziativa'?: string;
}
/**
 *
 * @export
 * @interface GetItemsClasses
 */
export interface GetItemsClasses {
    /**
     *
     * @type {Array<GetItemsClassesData>}
     * @memberof GetItemsClasses
     */
    'data'?: Array<GetItemsClassesData>;
}
/**
 *
 * @export
 * @interface GetItemsClassesAttributes
 */
export interface GetItemsClassesAttributes {
    /**
     * class description translated in current user\'s sid language
     * @type {string}
     * @memberof GetItemsClassesAttributes
     */
    'descrizione'?: string;
    /**
     * Item class
     * @type {string}
     * @memberof GetItemsClassesAttributes
     */
    'classe'?: string;
}
/**
 *
 * @export
 * @interface GetItemsClassesData
 */
export interface GetItemsClassesData {
    /**
     * fixed data type
     * @type {string}
     * @memberof GetItemsClassesData
     */
    'type'?: string;
    /**
     * <class code>,<gui_language>
     * @type {string}
     * @memberof GetItemsClassesData
     */
    'id'?: string;
    /**
     *
     * @type {GetItemsClassesAttributes}
     * @memberof GetItemsClassesData
     */
    'attributes'?: GetItemsClassesAttributes;
}
/**
 *
 * @export
 * @interface GetItemsFabrics
 */
export interface GetItemsFabrics {
    /**
     *
     * @type {Array<GetItemsFabricsData>}
     * @memberof GetItemsFabrics
     */
    'data'?: Array<GetItemsFabricsData>;
}
/**
 *
 * @export
 * @interface GetItemsFabricsAttributes
 */
export interface GetItemsFabricsAttributes {
    /**
     * fabric description translated in current user\'s sid language
     * @type {string}
     * @memberof GetItemsFabricsAttributes
     */
    'descrizione'?: string;
    /**
     * Item fabric
     * @type {string}
     * @memberof GetItemsFabricsAttributes
     */
    'cod_tessuto'?: string;
    /**
     * data modifica
     * @type {string}
     * @memberof GetItemsFabricsAttributes
     */
    'data_modifica'?: string;
    /**
     * Language
     * @type {string}
     * @memberof GetItemsFabricsAttributes
     */
    'lingua'?: string;
}
/**
 *
 * @export
 * @interface GetItemsFabricsData
 */
export interface GetItemsFabricsData {
    /**
     * fixed data type
     * @type {string}
     * @memberof GetItemsFabricsData
     */
    'type'?: string;
    /**
     * <fabric code>,<gui_language>
     * @type {string}
     * @memberof GetItemsFabricsData
     */
    'id'?: string;
    /**
     *
     * @type {GetItemsFabricsAttributes}
     * @memberof GetItemsFabricsData
     */
    'attributes'?: GetItemsFabricsAttributes;
}
/**
 *
 * @export
 * @interface GetItemsPriceAlteration
 */
export interface GetItemsPriceAlteration {
    /**
     *
     * @type {Array<GetItemsPriceAlterationData>}
     * @memberof GetItemsPriceAlteration
     */
    'data'?: Array<GetItemsPriceAlterationData>;
}
/**
 *
 * @export
 * @interface GetItemsPriceAlterationAttributes
 */
export interface GetItemsPriceAlterationAttributes {
    /**
     * Store Code
     * @type {string}
     * @memberof GetItemsPriceAlterationAttributes
     */
    'cod_negozio'?: string;
    /**
     * Item class
     * @type {string}
     * @memberof GetItemsPriceAlterationAttributes
     */
    'classe'?: string;
    /**
     * data modifica
     * @type {string}
     * @memberof GetItemsPriceAlterationAttributes
     */
    'data_modifica'?: string;
    /**
     * descrizione
     * @type {string}
     * @memberof GetItemsPriceAlterationAttributes
     */
    'descrizione'?: string;
    /**
     * importo
     * @type {number}
     * @memberof GetItemsPriceAlterationAttributes
     */
    'importo'?: number;
    /**
     * progressivo
     * @type {string}
     * @memberof GetItemsPriceAlterationAttributes
     */
    'progressivo'?: string;
}
/**
 *
 * @export
 * @interface GetItemsPriceAlterationData
 */
export interface GetItemsPriceAlterationData {
    /**
     * fixed data type \"alterations-prices\"
     * @type {string}
     * @memberof GetItemsPriceAlterationData
     */
    'type'?: string;
    /**
     * <store_code>,<item class>,<alteration id>
     * @type {string}
     * @memberof GetItemsPriceAlterationData
     */
    'id'?: string;
    /**
     *
     * @type {GetItemsPriceAlterationAttributes}
     * @memberof GetItemsPriceAlterationData
     */
    'attributes'?: GetItemsPriceAlterationAttributes;
}
/**
 *
 * @export
 * @interface GetLoyaltyPdfContactCRM
 */
export interface GetLoyaltyPdfContactCRM {
    /**
     * contenuto in base64
     * @type {string}
     * @memberof GetLoyaltyPdfContactCRM
     */
    'document'?: string;
    /**
     * stato
     * @type {string}
     * @memberof GetLoyaltyPdfContactCRM
     */
    'status'?: string;
}
/**
 *
 * @export
 * @interface GetMaxAmountAml
 */
export interface GetMaxAmountAml {
    /**
     *
     * @type {GetMaxAmountAmlData}
     * @memberof GetMaxAmountAml
     */
    'data'?: GetMaxAmountAmlData;
}
/**
 *
 * @export
 * @interface GetMaxAmountAmlData
 */
export interface GetMaxAmountAmlData {
    /**
     *
     * @type {string}
     * @memberof GetMaxAmountAmlData
     */
    'type'?: string;
    /**
     *
     * @type {GetMaxAmountAmlDataAttributes}
     * @memberof GetMaxAmountAmlData
     */
    'attributes'?: GetMaxAmountAmlDataAttributes;
}
/**
 *
 * @export
 * @interface GetMaxAmountAmlDataAttributes
 */
export interface GetMaxAmountAmlDataAttributes {
    /**
     *
     * @type {string}
     * @memberof GetMaxAmountAmlDataAttributes
     */
    'max_money_amount'?: string;
}
/**
 *
 * @export
 * @interface GetMenu
 */
export interface GetMenu {
    /**
     *
     * @type {Array<GetMenuData>}
     * @memberof GetMenu
     */
    'data'?: Array<GetMenuData>;
}
/**
 *
 * @export
 * @interface GetMenuAttributes
 */
export interface GetMenuAttributes {
    /**
     * Frontend route, if configured
     * @type {string}
     * @memberof GetMenuAttributes
     */
    'link'?: string;
    /**
     * Posweb querystring link, if configured
     * @type {string}
     * @memberof GetMenuAttributes
     */
    'qs'?: string;
    /**
     * Array of items contained in this node
     * @type {Array<GetMenuAttributesSubmenu>}
     * @memberof GetMenuAttributes
     */
    'submenu'?: Array<GetMenuAttributesSubmenu>;
}
/**
 *
 * @export
 * @interface GetMenuAttributesAttributes
 */
export interface GetMenuAttributesAttributes {
    /**
     * Frontend route, if configured
     * @type {string}
     * @memberof GetMenuAttributesAttributes
     */
    'link'?: string;
    /**
     * empty if leaf
     * @type {Array<any>}
     * @memberof GetMenuAttributesAttributes
     */
    'submenu'?: Array<any>;
    /**
     * Posweb querystring link, if configured
     * @type {string}
     * @memberof GetMenuAttributesAttributes
     */
    'qs'?: string;
}
/**
 *
 * @export
 * @interface GetMenuAttributesSubmenu
 */
export interface GetMenuAttributesSubmenu {
    /**
     * see top level descriptions
     * @type {string}
     * @memberof GetMenuAttributesSubmenu
     */
    'id'?: string;
    /**
     * see top level descriptions
     * @type {string}
     * @memberof GetMenuAttributesSubmenu
     */
    'type'?: string;
    /**
     *
     * @type {GetMenuAttributesAttributes}
     * @memberof GetMenuAttributesSubmenu
     */
    'attributes'?: GetMenuAttributesAttributes;
}
/**
 *
 * @export
 * @interface GetMenuData
 */
export interface GetMenuData {
    /**
     * Unique id of the node
     * @type {string}
     * @memberof GetMenuData
     */
    'id'?: string;
    /**
     * Type of node, \'menu\' if container of other nodes, \'link\' if clickable node
     * @type {string}
     * @memberof GetMenuData
     */
    'type'?: string;
    /**
     *
     * @type {GetMenuAttributes}
     * @memberof GetMenuData
     */
    'attributes'?: GetMenuAttributes;
}
/**
 *
 * @export
 * @interface GetModelData
 */
export interface GetModelData {
    /**
     *
     * @type {Array<GetModelsData>}
     * @memberof GetModelData
     */
    'data'?: Array<GetModelsData>;
}
/**
 *
 * @export
 * @interface GetModels
 */
export interface GetModels {
    /**
     *
     * @type {Array<GetModelsData>}
     * @memberof GetModels
     */
    'data'?: Array<GetModelsData>;
}
/**
 *
 * @export
 * @interface GetModelsAttributes
 */
export interface GetModelsAttributes {
    /**
     * code
     * @type {string}
     * @memberof GetModelsAttributes
     */
    'code'?: string;
    /**
     * name
     * @type {string}
     * @memberof GetModelsAttributes
     */
    'name'?: string;
    /**
     * cod_categoria
     * @type {string}
     * @memberof GetModelsAttributes
     */
    'cod_categoria'?: string;
    /**
     * desc_categoria
     * @type {string}
     * @memberof GetModelsAttributes
     */
    'desc_categoria'?: string;
    /**
     * cod_sottocategoria
     * @type {string}
     * @memberof GetModelsAttributes
     */
    'cod_sottocategoria'?: string;
    /**
     * desc_sottocategoria
     * @type {string}
     * @memberof GetModelsAttributes
     */
    'desc_sottocategoria'?: string;
    /**
     * brand
     * @type {string}
     * @memberof GetModelsAttributes
     */
    'brand'?: string;
    /**
     * brand_originale
     * @type {string}
     * @memberof GetModelsAttributes
     */
    'brand_originale'?: string;
    /**
     * societa
     * @type {string}
     * @memberof GetModelsAttributes
     */
    'societa'?: string;
    /**
     * classe
     * @type {string}
     * @memberof GetModelsAttributes
     */
    'classe'?: string;
    /**
     * desc_classe
     * @type {string}
     * @memberof GetModelsAttributes
     */
    'desc_classe'?: string;
    /**
     * annostag
     * @type {string}
     * @memberof GetModelsAttributes
     */
    'annostag'?: string;
    /**
     * composition
     * @type {string}
     * @memberof GetModelsAttributes
     */
    'composition'?: string;
    /**
     * washing_description
     * @type {string}
     * @memberof GetModelsAttributes
     */
    'washing_description'?: string;
    /**
     * technical_description
     * @type {string}
     * @memberof GetModelsAttributes
     */
    'technical_description'?: string;
    /**
     * emotional_description
     * @type {string}
     * @memberof GetModelsAttributes
     */
    'emotional_description'?: string;
    /**
     *
     * @type {Array<GetModelsAttributesVariants>}
     * @memberof GetModelsAttributes
     */
    'variants'?: Array<GetModelsAttributesVariants>;
    /**
     * price_type_applied
     * @type {string}
     * @memberof GetModelsAttributes
     */
    'price_type_applied'?: string;
    /**
     * desc_classe_it
     * @type {string}
     * @memberof GetModelsAttributes
     */
    'desc_classe_it'?: string;
    /**
     *
     * @type {Array<GetModelsAttributesPrices>}
     * @memberof GetModelsAttributes
     */
    'prices'?: Array<GetModelsAttributesPrices>;
    /**
     * iva
     * @type {number}
     * @memberof GetModelsAttributes
     */
    'iva'?: number;
}
/**
 *
 * @export
 * @interface GetModelsAttributesPrices
 */
export interface GetModelsAttributesPrices {
    /**
     * price_type
     * @type {string}
     * @memberof GetModelsAttributesPrices
     */
    'price_type'?: string;
    /**
     * price
     * @type {number}
     * @memberof GetModelsAttributesPrices
     */
    'price'?: number;
}
/**
 *
 * @export
 * @interface GetModelsAttributesSizes
 */
export interface GetModelsAttributesSizes {
    /**
     * size code
     * @type {string}
     * @memberof GetModelsAttributesSizes
     */
    'code'?: string;
    /**
     * size description
     * @type {string}
     * @memberof GetModelsAttributesSizes
     */
    'description'?: string;
    /**
     * sku
     * @type {string}
     * @memberof GetModelsAttributesSizes
     */
    'sku'?: string;
    /**
     * ean
     * @type {string}
     * @memberof GetModelsAttributesSizes
     */
    'ean'?: string;
}
/**
 *
 * @export
 * @interface GetModelsAttributesVariants
 */
export interface GetModelsAttributesVariants {
    /**
     * code
     * @type {string}
     * @memberof GetModelsAttributesVariants
     */
    'code'?: string;
    /**
     * description
     * @type {string}
     * @memberof GetModelsAttributesVariants
     */
    'description'?: string;
    /**
     * priority
     * @type {number}
     * @memberof GetModelsAttributesVariants
     */
    'priority'?: number;
    /**
     * listing_img
     * @type {string}
     * @memberof GetModelsAttributesVariants
     */
    'listing_img'?: string;
    /**
     * swatch_img
     * @type {string}
     * @memberof GetModelsAttributesVariants
     */
    'swatch_img'?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof GetModelsAttributesVariants
     */
    'details_img'?: Array<string>;
    /**
     *
     * @type {Array<GetModelsAttributesSizes>}
     * @memberof GetModelsAttributesVariants
     */
    'sizes'?: Array<GetModelsAttributesSizes>;
    /**
     *
     * @type {Array<GetModelsAttributesPrices>}
     * @memberof GetModelsAttributesVariants
     */
    'prices'?: Array<GetModelsAttributesPrices>;
}
/**
 *
 * @export
 * @interface GetModelsData
 */
export interface GetModelsData {
    /**
     *
     * @type {GetModelsAttributes}
     * @memberof GetModelsData
     */
    'attributes'?: GetModelsAttributes;
    /**
     * type
     * @type {string}
     * @memberof GetModelsData
     */
    'type'?: string;
    /**
     * id
     * @type {string}
     * @memberof GetModelsData
     */
    'id'?: string;
}
/**
 *
 * @export
 * @interface GetModelsSizes
 */
export interface GetModelsSizes {
    /**
     *
     * @type {Array<GetModelsSizesData>}
     * @memberof GetModelsSizes
     */
    'data'?: Array<GetModelsSizesData>;
}
/**
 *
 * @export
 * @interface GetModelsSizesAttributes
 */
export interface GetModelsSizesAttributes {
    /**
     * cod_sottocategoria
     * @type {string}
     * @memberof GetModelsSizesAttributes
     */
    'cod_sottocategoria'?: string;
    /**
     * size code
     * @type {string}
     * @memberof GetModelsSizesAttributes
     */
    'codice_taglia'?: string;
    /**
     * size description
     * @type {string}
     * @memberof GetModelsSizesAttributes
     */
    'desc_taglia'?: string;
    /**
     * size index
     * @type {number}
     * @memberof GetModelsSizesAttributes
     */
    'indice_tg'?: number;
    /**
     * brand
     * @type {string}
     * @memberof GetModelsSizesAttributes
     */
    'societa'?: string;
}
/**
 *
 * @export
 * @interface GetModelsSizesData
 */
export interface GetModelsSizesData {
    /**
     * type
     * @type {string}
     * @memberof GetModelsSizesData
     */
    'type'?: string;
    /**
     * id
     * @type {string}
     * @memberof GetModelsSizesData
     */
    'id'?: string;
    /**
     *
     * @type {GetModelsSizesAttributes}
     * @memberof GetModelsSizesData
     */
    'attributes'?: GetModelsSizesAttributes;
}
/**
 *
 * @export
 * @interface GetOMToken
 */
export interface GetOMToken {
    /**
     *
     * @type {GetOMTokenData}
     * @memberof GetOMToken
     */
    'data'?: GetOMTokenData;
}
/**
 *
 * @export
 * @interface GetOMTokenData
 */
export interface GetOMTokenData {
    /**
     *
     * @type {string}
     * @memberof GetOMTokenData
     */
    'type'?: string;
    /**
     * OM Token
     * @type {string}
     * @memberof GetOMTokenData
     */
    'id'?: string;
    /**
     *
     * @type {GetOMTokenDataAttributes}
     * @memberof GetOMTokenData
     */
    'attributes'?: GetOMTokenDataAttributes;
}
/**
 *
 * @export
 * @interface GetOMTokenDataAttributes
 */
export interface GetOMTokenDataAttributes {
    /**
     * om token
     * @type {string}
     * @memberof GetOMTokenDataAttributes
     */
    'access_token'?: string;
    /**
     * time before expiration
     * @type {number}
     * @memberof GetOMTokenDataAttributes
     */
    'expires_in'?: number;
    /**
     * type of token
     * @type {string}
     * @memberof GetOMTokenDataAttributes
     */
    'token_type'?: string;
}
/**
 *
 * @export
 * @interface GetPettyCashDetail
 */
export interface GetPettyCashDetail {
    /**
     *
     * @type {GetPettyCashDetailData}
     * @memberof GetPettyCashDetail
     */
    'data'?: GetPettyCashDetailData;
}
/**
 *
 * @export
 * @interface GetPettyCashDetailData
 */
export interface GetPettyCashDetailData {
    /**
     * number of document
     * @type {number}
     * @memberof GetPettyCashDetailData
     */
    'id'?: number;
    /**
     * fixed data type \"reintegri\"
     * @type {string}
     * @memberof GetPettyCashDetailData
     */
    'type'?: string;
    /**
     *
     * @type {GetPettyCashDetailDataAttributes}
     * @memberof GetPettyCashDetailData
     */
    'attributes'?: GetPettyCashDetailDataAttributes;
}
/**
 *
 * @export
 * @interface GetPettyCashDetailDataAttributes
 */
export interface GetPettyCashDetailDataAttributes {
    /**
     * progressive
     * @type {number}
     * @memberof GetPettyCashDetailDataAttributes
     */
    'id'?: number;
    /**
     *
     * @type {GetPettyCashDetailDataAttributesDoc}
     * @memberof GetPettyCashDetailDataAttributes
     */
    'doc'?: GetPettyCashDetailDataAttributesDoc;
}
/**
 *
 * @export
 * @interface GetPettyCashDetailDataAttributesDoc
 */
export interface GetPettyCashDetailDataAttributesDoc {
    /**
     * cod_cassiera
     * @type {string}
     * @memberof GetPettyCashDetailDataAttributesDoc
     */
    'cod_cassiera'?: string;
    /**
     * cod_cassiera
     * @type {string}
     * @memberof GetPettyCashDetailDataAttributesDoc
     */
    'nominativo'?: string;
    /**
     * type doc esterno
     * @type {string}
     * @memberof GetPettyCashDetailDataAttributesDoc
     */
    'tipo_doc_esterno'?: string;
    /**
     * date doc esterno
     * @type {string}
     * @memberof GetPettyCashDetailDataAttributesDoc
     */
    'data_doc_esterno'?: string;
    /**
     * nr doc esterno
     * @type {string}
     * @memberof GetPettyCashDetailDataAttributesDoc
     */
    'num_doc_esterno'?: string;
    /**
     * date doc petty cash
     * @type {string}
     * @memberof GetPettyCashDetailDataAttributesDoc
     */
    'data_documento'?: string;
    /**
     * time doc petty cash
     * @type {string}
     * @memberof GetPettyCashDetailDataAttributesDoc
     */
    'ora_documento'?: string;
    /**
     * description spesa
     * @type {string}
     * @memberof GetPettyCashDetailDataAttributesDoc
     */
    'descrizione_spesa'?: string;
    /**
     * amount
     * @type {number}
     * @memberof GetPettyCashDetailDataAttributesDoc
     */
    'importo'?: number;
}
/**
 *
 * @export
 * @interface GetPettyCashList
 */
export interface GetPettyCashList {
    /**
     *
     * @type {Array<object>}
     * @memberof GetPettyCashList
     */
    'data'?: Array<object>;
}
/**
 *
 * @export
 * @interface GetPhonePrefixes
 */
export interface GetPhonePrefixes {
    /**
     * List of countries and area codes
     * @type {Array<GetPhonePrefixesData>}
     * @memberof GetPhonePrefixes
     */
    'data'?: Array<GetPhonePrefixesData>;
}
/**
 * Phone prefix and country name
 * @export
 * @interface GetPhonePrefixesAttributes
 */
export interface GetPhonePrefixesAttributes {
    /**
     * Phone prefix without 0s or +sign
     * @type {string}
     * @memberof GetPhonePrefixesAttributes
     */
    'phone_prefix'?: string;
    /**
     * Contry\'s iso2 code
     * @type {string}
     * @memberof GetPhonePrefixesAttributes
     */
    'nazione_iso'?: string;
    /**
     * Country name in current session language
     * @type {string}
     * @memberof GetPhonePrefixesAttributes
     */
    'descrizione'?: string;
}
/**
 * Country name and its phone prefix without leading 0s or + sign
 * @export
 * @interface GetPhonePrefixesData
 */
export interface GetPhonePrefixesData {
    /**
     * fixed data type \"phone_prefix\"
     * @type {string}
     * @memberof GetPhonePrefixesData
     */
    'type'?: string;
    /**
     * Country\'s iso2 code
     * @type {string}
     * @memberof GetPhonePrefixesData
     */
    'id'?: string;
    /**
     *
     * @type {GetPhonePrefixesAttributes}
     * @memberof GetPhonePrefixesData
     */
    'attributes'?: GetPhonePrefixesAttributes;
}
/**
 *
 * @export
 * @interface GetPoswebPromotionTypes
 */
export interface GetPoswebPromotionTypes {
    /**
     *
     * @type {Array<GetPoswebPromotionTypesData>}
     * @memberof GetPoswebPromotionTypes
     */
    'data'?: Array<GetPoswebPromotionTypesData>;
}
/**
 *
 * @export
 * @interface GetPoswebPromotionTypesAttributes
 */
export interface GetPoswebPromotionTypesAttributes {
    /**
     *
     * @type {string}
     * @memberof GetPoswebPromotionTypesAttributes
     */
    'activation_mode'?: string;
    /**
     *
     * @type {string}
     * @memberof GetPoswebPromotionTypesAttributes
     */
    'cod_negozio'?: string;
    /**
     * token che identifica il programma fidelity
     * @type {string}
     * @memberof GetPoswebPromotionTypesAttributes
     */
    'codice_promozione'?: string;
    /**
     * lista delle configurazioni del programma fidelity
     * @type {string}
     * @memberof GetPoswebPromotionTypesAttributes
     */
    'custom_data'?: string;
    /**
     *
     * @type {string}
     * @memberof GetPoswebPromotionTypesAttributes
     */
    'data_fine'?: string;
    /**
     *
     * @type {string}
     * @memberof GetPoswebPromotionTypesAttributes
     */
    'data_inizio'?: string;
    /**
     * nome del programma fidelity
     * @type {string}
     * @memberof GetPoswebPromotionTypesAttributes
     */
    'descrizione'?: string;
    /**
     *
     * @type {number}
     * @memberof GetPoswebPromotionTypesAttributes
     */
    'initial_value'?: number;
    /**
     *
     * @type {string}
     * @memberof GetPoswebPromotionTypesAttributes
     */
    'status'?: string;
    /**
     *
     * @type {string}
     * @memberof GetPoswebPromotionTypesAttributes
     */
    'value_type'?: string;
    /**
     * indica se per questo programma è possibile usare la funzionalità di force del punteggio
     * @type {boolean}
     * @memberof GetPoswebPromotionTypesAttributes
     */
    'forcing_enabled'?: boolean;
    /**
     * indica se per questo programma è disponibile la funzionalità che permette di vedere le fidelity di programmi affiliati
     * @type {boolean}
     * @memberof GetPoswebPromotionTypesAttributes
     */
    'affiliate_programs'?: boolean;
}
/**
 *
 * @export
 * @interface GetPoswebPromotionTypesData
 */
export interface GetPoswebPromotionTypesData {
    /**
     *
     * @type {string}
     * @memberof GetPoswebPromotionTypesData
     */
    'type'?: string;
    /**
     * <codice_negozio>,<codice_fidelity>
     * @type {string}
     * @memberof GetPoswebPromotionTypesData
     */
    'id'?: string;
    /**
     *
     * @type {GetPoswebPromotionTypesAttributes}
     * @memberof GetPoswebPromotionTypesData
     */
    'attributes'?: GetPoswebPromotionTypesAttributes;
}
/**
 *
 * @export
 * @interface GetPromoEnginePromotionList
 */
export interface GetPromoEnginePromotionList {
    /**
     *
     * @type {Array<GetPromoEnginePromotionListData>}
     * @memberof GetPromoEnginePromotionList
     */
    'data'?: Array<GetPromoEnginePromotionListData>;
}
/**
 *
 * @export
 * @interface GetPromoEnginePromotionListData
 */
export interface GetPromoEnginePromotionListData {
    /**
     *
     * @type {number}
     * @memberof GetPromoEnginePromotionListData
     */
    'id'?: number;
    /**
     *
     * @type {number}
     * @memberof GetPromoEnginePromotionListData
     */
    'id_campaign'?: number;
    /**
     *
     * @type {string}
     * @memberof GetPromoEnginePromotionListData
     */
    'name'?: string;
    /**
     *
     * @type {string}
     * @memberof GetPromoEnginePromotionListData
     */
    'start_date'?: string;
    /**
     *
     * @type {string}
     * @memberof GetPromoEnginePromotionListData
     */
    'end_date'?: string;
    /**
     *
     * @type {string}
     * @memberof GetPromoEnginePromotionListData
     */
    'lifecycle'?: string;
    /**
     *
     * @type {number}
     * @memberof GetPromoEnginePromotionListData
     */
    'priority'?: number;
    /**
     *
     * @type {string}
     * @memberof GetPromoEnginePromotionListData
     */
    'combinability'?: string;
    /**
     *
     * @type {string}
     * @memberof GetPromoEnginePromotionListData
     */
    'effect_type'?: string;
    /**
     *
     * @type {string}
     * @memberof GetPromoEnginePromotionListData
     */
    'color_code'?: string;
    /**
     *
     * @type {string}
     * @memberof GetPromoEnginePromotionListData
     */
    'title'?: string;
    /**
     *
     * @type {string}
     * @memberof GetPromoEnginePromotionListData
     */
    'description'?: string;
    /**
     *
     * @type {string}
     * @memberof GetPromoEnginePromotionListData
     */
    'document'?: string;
    /**
     *
     * @type {Array<GetPromoEnginePromotionListEffects>}
     * @memberof GetPromoEnginePromotionListData
     */
    'effects'?: Array<GetPromoEnginePromotionListEffects>;
    /**
     *
     * @type {string}
     * @memberof GetPromoEnginePromotionListData
     */
    'last_update'?: string;
    /**
     *
     * @type {string}
     * @memberof GetPromoEnginePromotionListData
     */
    'campagna'?: string;
    /**
     *
     * @type {number}
     * @memberof GetPromoEnginePromotionListData
     */
    'priority_idx'?: number;
}
/**
 *
 * @export
 * @interface GetPromoEnginePromotionListEffectValue
 */
export interface GetPromoEnginePromotionListEffectValue {
    /**
     *
     * @type {string}
     * @memberof GetPromoEnginePromotionListEffectValue
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof GetPromoEnginePromotionListEffectValue
     */
    'value'?: string;
}
/**
 *
 * @export
 * @interface GetPromoEnginePromotionListEffects
 */
export interface GetPromoEnginePromotionListEffects {
    /**
     *
     * @type {number}
     * @memberof GetPromoEnginePromotionListEffects
     */
    'limit'?: number;
    /**
     *
     * @type {string}
     * @memberof GetPromoEnginePromotionListEffects
     */
    'name'?: string;
    /**
     *
     * @type {string}
     * @memberof GetPromoEnginePromotionListEffects
     */
    'application_type'?: string;
    /**
     *
     * @type {string}
     * @memberof GetPromoEnginePromotionListEffects
     */
    'selection'?: string;
    /**
     *
     * @type {number}
     * @memberof GetPromoEnginePromotionListEffects
     */
    'applications'?: number;
    /**
     *
     * @type {string}
     * @memberof GetPromoEnginePromotionListEffects
     */
    'value_type'?: string;
    /**
     *
     * @type {GetPromoEnginePromotionListEffectValue}
     * @memberof GetPromoEnginePromotionListEffects
     */
    'effect_value'?: GetPromoEnginePromotionListEffectValue;
}
/**
 *
 * @export
 * @interface GetSchedaPrivacyLanguages
 */
export interface GetSchedaPrivacyLanguages {
    /**
     *
     * @type {GetSchedaPrivacyLanguagesData}
     * @memberof GetSchedaPrivacyLanguages
     */
    'data'?: GetSchedaPrivacyLanguagesData;
}
/**
 *
 * @export
 * @interface GetSchedaPrivacyLanguagesData
 */
export interface GetSchedaPrivacyLanguagesData {
    /**
     *
     * @type {string}
     * @memberof GetSchedaPrivacyLanguagesData
     */
    'type'?: string;
    /**
     *
     * @type {Array<GetSchedaPrivacyLanguagesDataAttributes>}
     * @memberof GetSchedaPrivacyLanguagesData
     */
    'attributes'?: Array<GetSchedaPrivacyLanguagesDataAttributes>;
}
/**
 *
 * @export
 * @interface GetSchedaPrivacyLanguagesDataAttributes
 */
export interface GetSchedaPrivacyLanguagesDataAttributes {
    /**
     * riferimento della lingua in Posweb
     * @type {string}
     * @memberof GetSchedaPrivacyLanguagesDataAttributes
     */
    'language'?: string;
    /**
     * sigla della lingua
     * @type {string}
     * @memberof GetSchedaPrivacyLanguagesDataAttributes
     */
    'locale'?: string;
    /**
     * etichetta
     * @type {string}
     * @memberof GetSchedaPrivacyLanguagesDataAttributes
     */
    'desc'?: string;
}
/**
 *
 * @export
 * @interface GetSkuData
 */
export interface GetSkuData {
    /**
     *
     * @type {Array<GetSkuDataData>}
     * @memberof GetSkuData
     */
    'data'?: Array<GetSkuDataData>;
}
/**
 *
 * @export
 * @interface GetSkuDataAttributes
 */
export interface GetSkuDataAttributes {
    /**
     * sku
     * @type {string}
     * @memberof GetSkuDataAttributes
     */
    'sku'?: string;
    /**
     * sku_original
     * @type {string}
     * @memberof GetSkuDataAttributes
     */
    'sku_original'?: string;
    /**
     * ean
     * @type {string}
     * @memberof GetSkuDataAttributes
     */
    'ean'?: string;
    /**
     * societa
     * @type {string}
     * @memberof GetSkuDataAttributes
     */
    'societa'?: string;
    /**
     * annostag
     * @type {string}
     * @memberof GetSkuDataAttributes
     */
    'annostag'?: string;
    /**
     * modello
     * @type {string}
     * @memberof GetSkuDataAttributes
     */
    'modello'?: string;
    /**
     * pezzo
     * @type {string}
     * @memberof GetSkuDataAttributes
     */
    'pezzo'?: string;
    /**
     * nome
     * @type {string}
     * @memberof GetSkuDataAttributes
     */
    'nome'?: string;
    /**
     * variante
     * @type {string}
     * @memberof GetSkuDataAttributes
     */
    'variante'?: string;
    /**
     * desc_colore
     * @type {string}
     * @memberof GetSkuDataAttributes
     */
    'desc_colore'?: string;
    /**
     * desc_taglia
     * @type {string}
     * @memberof GetSkuDataAttributes
     */
    'desc_taglia'?: string;
    /**
     * collezione
     * @type {string}
     * @memberof GetSkuDataAttributes
     */
    'collezione'?: string;
    /**
     * desc_collezione
     * @type {string}
     * @memberof GetSkuDataAttributes
     */
    'desc_collezione'?: string;
    /**
     * classe
     * @type {string}
     * @memberof GetSkuDataAttributes
     */
    'classe'?: string;
    /**
     * desc_classe
     * @type {string}
     * @memberof GetSkuDataAttributes
     */
    'desc_classe'?: string;
    /**
     * iva
     * @type {number}
     * @memberof GetSkuDataAttributes
     */
    'iva'?: number;
    /**
     *
     * @type {GetSkuDataAttributesComposizione}
     * @memberof GetSkuDataAttributes
     */
    'composizione'?: GetSkuDataAttributesComposizione;
    /**
     * prezzo_iniziale
     * @type {number}
     * @memberof GetSkuDataAttributes
     */
    'prezzo_iniziale'?: number;
    /**
     * divisa
     * @type {string}
     * @memberof GetSkuDataAttributes
     */
    'divisa'?: string;
    /**
     * flag_promo
     * @type {number}
     * @memberof GetSkuDataAttributes
     */
    'flag_promo'?: number;
    /**
     * flag_salvato
     * @type {number}
     * @memberof GetSkuDataAttributes
     */
    'flag_salvato'?: number;
    /**
     * num_pezzi
     * @type {number}
     * @memberof GetSkuDataAttributes
     */
    'num_pezzi'?: number;
    /**
     * sku_padre
     * @type {string}
     * @memberof GetSkuDataAttributes
     */
    'sku_padre'?: string;
    /**
     * flag_divisa
     * @type {number}
     * @memberof GetSkuDataAttributes
     */
    'flag_divisa'?: number;
    /**
     * tipo_importo
     * @type {string}
     * @memberof GetSkuDataAttributes
     */
    'tipo_importo'?: string;
    /**
     * tipologia_merce
     * @type {string}
     * @memberof GetSkuDataAttributes
     */
    'tipologia_merce'?: string;
    /**
     * error_code
     * @type {string}
     * @memberof GetSkuDataAttributes
     */
    'error_code'?: string;
    /**
     * sku_gruppo
     * @type {string}
     * @memberof GetSkuDataAttributes
     */
    'sku_gruppo'?: string;
    /**
     * rfid
     * @type {string}
     * @memberof GetSkuDataAttributes
     */
    'rfid'?: string;
    /**
     *
     * @type {GetSkuDataAttributesListini}
     * @memberof GetSkuDataAttributes
     */
    'listini'?: GetSkuDataAttributesListini;
    /**
     * flag_created
     * @type {boolean}
     * @memberof GetSkuDataAttributes
     */
    'flag_created'?: boolean;
    /**
     * flag_sdoppiabile
     * @type {boolean}
     * @memberof GetSkuDataAttributes
     */
    'flag_sdoppiabile'?: boolean;
    /**
     * importo_finale
     * @type {number}
     * @memberof GetSkuDataAttributes
     */
    'importo_finale'?: number;
    /**
     * tipo_mov
     * @type {string}
     * @memberof GetSkuDataAttributes
     */
    'tipo_mov'?: string;
    /**
     *
     * @type {GetSkuDataAttributesElement}
     * @memberof GetSkuDataAttributes
     */
    'element'?: GetSkuDataAttributesElement;
    /**
     *
     * @type {Array<GetSkuDataAttributesGiacenza>}
     * @memberof GetSkuDataAttributes
     */
    'giacenza'?: Array<GetSkuDataAttributesGiacenza>;
    /**
     *
     * @type {GetSkuDataAttributesMedia}
     * @memberof GetSkuDataAttributes
     */
    'media'?: GetSkuDataAttributesMedia;
    /**
     * media link
     * @type {string}
     * @memberof GetSkuDataAttributes
     */
    'model_img'?: string;
    /**
     * media link
     * @type {string}
     * @memberof GetSkuDataAttributes
     */
    'color_img'?: string;
}
/**
 * composizione
 * @export
 * @interface GetSkuDataAttributesComposizione
 */
export interface GetSkuDataAttributesComposizione {
    /**
     *
     * @type {Array<any>}
     * @memberof GetSkuDataAttributesComposizione
     */
    'F'?: Array<any>;
    /**
     *
     * @type {Array<any>}
     * @memberof GetSkuDataAttributesComposizione
     */
    'T'?: Array<any>;
}
/**
 *
 * @export
 * @interface GetSkuDataAttributesElement
 */
export interface GetSkuDataAttributesElement {
    /**
     * sku
     * @type {string}
     * @memberof GetSkuDataAttributesElement
     */
    'sku'?: string;
    /**
     * sku_original
     * @type {string}
     * @memberof GetSkuDataAttributesElement
     */
    'sku_original'?: string;
}
/**
 *
 * @export
 * @interface GetSkuDataAttributesGiacenza
 */
export interface GetSkuDataAttributesGiacenza {
    /**
     * sku
     * @type {string}
     * @memberof GetSkuDataAttributesGiacenza
     */
    'sku'?: string;
    /**
     * giacenza
     * @type {number}
     * @memberof GetSkuDataAttributesGiacenza
     */
    'giacenza'?: number;
    /**
     * inventario
     * @type {number}
     * @memberof GetSkuDataAttributesGiacenza
     */
    'inventario'?: number;
    /**
     * flag giacenza offline restituito solo quando true
     * @type {boolean}
     * @memberof GetSkuDataAttributesGiacenza
     */
    'giac_offline'?: boolean;
}
/**
 *
 * @export
 * @interface GetSkuDataAttributesListini
 */
export interface GetSkuDataAttributesListini {
    /**
     *
     * @type {GetSkuDataAttributesListiniPriceType}
     * @memberof GetSkuDataAttributesListini
     */
    '&lt;price type&gt;'?: GetSkuDataAttributesListiniPriceType;
}
/**
 *
 * @export
 * @interface GetSkuDataAttributesListiniPriceType
 */
export interface GetSkuDataAttributesListiniPriceType {
    /**
     * prezzo_listino
     * @type {number}
     * @memberof GetSkuDataAttributesListiniPriceType
     */
    'prezzo_listino'?: number;
    /**
     * prezzo_attuale
     * @type {number}
     * @memberof GetSkuDataAttributesListiniPriceType
     */
    'prezzo_attuale'?: number;
    /**
     * perc_attuale
     * @type {number}
     * @memberof GetSkuDataAttributesListiniPriceType
     */
    'perc_attuale'?: number;
    /**
     * divisa
     * @type {string}
     * @memberof GetSkuDataAttributesListiniPriceType
     */
    'divisa'?: string;
    /**
     * tipo_prezzo
     * @type {string}
     * @memberof GetSkuDataAttributesListiniPriceType
     */
    'tipo_prezzo'?: string;
    /**
     * data_inizio_validita
     * @type {string}
     * @memberof GetSkuDataAttributesListiniPriceType
     */
    'data_inizio_validita'?: string;
    /**
     * data_fine_validita
     * @type {string}
     * @memberof GetSkuDataAttributesListiniPriceType
     */
    'data_fine_validita'?: string;
    /**
     * modello
     * @type {string}
     * @memberof GetSkuDataAttributesListiniPriceType
     */
    'modello'?: string;
    /**
     * variante
     * @type {string}
     * @memberof GetSkuDataAttributesListiniPriceType
     */
    'variante'?: string;
    /**
     * id_listino
     * @type {number}
     * @memberof GetSkuDataAttributesListiniPriceType
     */
    'id_listino'?: number;
}
/**
 *
 * @export
 * @interface GetSkuDataAttributesMedia
 */
export interface GetSkuDataAttributesMedia {
    /**
     * media link
     * @type {string}
     * @memberof GetSkuDataAttributesMedia
     */
    'listing'?: string;
    /**
     * media link
     * @type {string}
     * @memberof GetSkuDataAttributesMedia
     */
    'swatch'?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof GetSkuDataAttributesMedia
     */
    'details'?: Array<string>;
}
/**
 *
 * @export
 * @interface GetSkuDataData
 */
export interface GetSkuDataData {
    /**
     *
     * @type {GetSkuDataAttributes}
     * @memberof GetSkuDataData
     */
    'attributes'?: GetSkuDataAttributes;
    /**
     * type
     * @type {string}
     * @memberof GetSkuDataData
     */
    'type'?: string;
    /**
     * id
     * @type {string}
     * @memberof GetSkuDataData
     */
    'id'?: string;
}
/**
 *
 * @export
 * @interface GetSkuGiacenza
 */
export interface GetSkuGiacenza {
    /**
     *
     * @type {Array<GetSkuGiacenzaData>}
     * @memberof GetSkuGiacenza
     */
    'data'?: Array<GetSkuGiacenzaData>;
}
/**
 *
 * @export
 * @interface GetSkuGiacenzaAttributes
 */
export interface GetSkuGiacenzaAttributes {
    /**
     * sku
     * @type {string}
     * @memberof GetSkuGiacenzaAttributes
     */
    'sku'?: string;
    /**
     * giacenza sku
     * @type {number}
     * @memberof GetSkuGiacenzaAttributes
     */
    'giacenza'?: number;
    /**
     * inventario
     * @type {number}
     * @memberof GetSkuGiacenzaAttributes
     */
    'inventario'?: number;
    /**
     * flag giacenza offline restituito solo quando true
     * @type {boolean}
     * @memberof GetSkuGiacenzaAttributes
     */
    'giac_offline'?: boolean;
}
/**
 *
 * @export
 * @interface GetSkuGiacenzaData
 */
export interface GetSkuGiacenzaData {
    /**
     *
     * @type {GetSkuGiacenzaAttributes}
     * @memberof GetSkuGiacenzaData
     */
    'attributes'?: GetSkuGiacenzaAttributes;
    /**
     * type
     * @type {string}
     * @memberof GetSkuGiacenzaData
     */
    'type'?: string;
    /**
     * id
     * @type {string}
     * @memberof GetSkuGiacenzaData
     */
    'id'?: string;
}
/**
 *
 * @export
 * @interface GetStockItemData
 */
export interface GetStockItemData {
    /**
     *
     * @type {Array<GetStockItemDataData>}
     * @memberof GetStockItemData
     */
    'data'?: Array<GetStockItemDataData>;
}
/**
 *
 * @export
 * @interface GetStockItemDataData
 */
export interface GetStockItemDataData {
    /**
     * type
     * @type {string}
     * @memberof GetStockItemDataData
     */
    'type'?: string;
    /**
     * sku
     * @type {string}
     * @memberof GetStockItemDataData
     */
    'id'?: string;
    /**
     *
     * @type {object}
     * @memberof GetStockItemDataData
     */
    'attributes'?: object;
}
/**
 *
 * @export
 * @interface GetStoreConfig
 */
export interface GetStoreConfig {
    /**
     *
     * @type {GetStoreConfigData}
     * @memberof GetStoreConfig
     */
    'data'?: GetStoreConfigData;
}
/**
 *
 * @export
 * @interface GetStoreConfigData
 */
export interface GetStoreConfigData {
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigData
     */
    'type'?: string;
    /**
     * Always empty
     * @type {string}
     * @memberof GetStoreConfigData
     */
    'id'?: string;
    /**
     *
     * @type {GetStoreConfigDataAttributes}
     * @memberof GetStoreConfigData
     */
    'attributes'?: GetStoreConfigDataAttributes;
}
/**
 *
 * @export
 * @interface GetStoreConfigDataAttributes
 */
export interface GetStoreConfigDataAttributes {
    /**
     * List of available languages
     * @type {Array<string>}
     * @memberof GetStoreConfigDataAttributes
     */
    'languages'?: Array<string>;
    /**
     * Current store code
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'STORE_CODE'?: string;
    /**
     * Vat rate used in this store
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'VAT_DEFAULT'?: string;
    /**
     *
     * @type {Array<GetStoreConfigDataAttributesTipiVenditaTessuto>}
     * @memberof GetStoreConfigDataAttributes
     */
    'tipi_vendita_tessuto'?: Array<GetStoreConfigDataAttributesTipiVenditaTessuto>;
    /**
     *
     * @type {Array<GetStoreConfigDataAttributesTipiVendita>}
     * @memberof GetStoreConfigDataAttributes
     */
    'tipi_vendita'?: Array<GetStoreConfigDataAttributesTipiVendita>;
    /**
     *
     * @type {{ [key: string]: GetStoreConfigDataAttributesStoreData; }}
     * @memberof GetStoreConfigDataAttributes
     */
    'store_data'?: { [key: string]: GetStoreConfigDataAttributesStoreData; };
    /**
     * 1 if uniserv address validation is enabled
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'ADDRESS_VALIDATION'?: string;
    /**
     * enabled shipment destinations
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'B2E_SHIPMENTS_METHODS'?: string;
    /**
     * ?
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'CARD_ENABLED'?: string;
    /**
     * customer data validity expiration days
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'CONSUMER_OUTDATE_DAYS'?: string;
    /**
     * store country
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'COUNTRY_CODE'?: string;
    /**
     * returns are enabled
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'RETURNS_ENABLED'?: string;
    /**
     * is fatturazione elettronica even worth it?
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'FATTURAZIONE_ELETTRONICA_ENABLED'?: string;
    /**
     * gift cards are enabled
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'GIFT_USAGE'?: string;
    /**
     * store currency
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'CURRENCY_CODE'?: string;
    /**
     * currency simbol
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'CURRENCY_SIGN'?: string;
    /**
     * character used as decimal separator
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'DECIMAL_SEPARATOR'?: string;
    /**
     * character used as thousand separator
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'THOUSAND_SEPARATOR'?: string;
    /**
     * boolean for invio telematico
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'INVIO_TELEMATICO_ENABLED'?: string;
    /**
     * store current date
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'CUSTOM_DATE'?: string;
    /**
     * ?
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'DATA_VISIBILITY_EXTENDED'?: string;
    /**
     * date localization format
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'DATE_FORMAT_CODE'?: string;
    /**
     * number of decimals
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'DECIMAL_PLACES_ROUNDING'?: string;
    /**
     * max applicable discount
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'MAX_DISCOUNT_PERC'?: string;
    /**
     * allow no-customer sale
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'NO_CONSUMER'?: string;
    /**
     * number formatting config
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'NUMBER_FORMAT_CODE'?: string;
    /**
     * promo engine avaiablility
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'PROMO_ENGINE_ENABLED'?: string;
    /**
     * ?
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'SALE_ALLOWANCE_USAGE'?: string;
    /**
     * textile sale anable
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'SALE_TEXTILE'?: string;
    /**
     * customer detail modification enabled
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'SHOW_DETAIL_CONSUMER'?: string;
    /**
     * ?
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'SHOW_TAX_ENABLED'?: string;
    /**
     * store sign
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'STORE_SIGN'?: string;
    /**
     * Store sign description
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'STORE_SIGN_DESC'?: string;
    /**
     * store status
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'STORE_STATUS'?: string;
    /**
     * minumum allowed sale amount for taxfree
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'TAXFREE_MIN'?: string;
    /**
     * time format
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'TIME_FORMAT_CODE'?: string;
    /**
     * number of decimals allowed
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'CURRENCY_DECIMAL'?: string;
    /**
     * GDPR-compliant data entry
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'GDPR'?: string;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'INVENTARIO_CHECK_ENABLED'?: string;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'BLOCK_SALES_NO_PRICE_NO_STOCK'?: string;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'STOCK_CHECK_ENABLED'?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof GetStoreConfigDataAttributes
     */
    'AVAILABLE_BRANDS'?: Array<string>;
    /**
     * Max money payable with cash
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'MAX_MONEY_AMOUNT'?: string;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'MAX_MONEY_AMOUNT_EXTRA_COUNTRY'?: string;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'MAX_MONEY_AMOUNT_EXTRA_EUROPE_AML'?: string;
    /**
     * Default UI language
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'GUI_LANGUAGE'?: string;
    /**
     * Default locale language
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'GUI_LANGUAGE_LOCALE'?: string;
    /**
     * Flag that controls the visibility of the coupon section
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'COUPON_ENABLED'?: string;
    /**
     * Whether shipping costs are enabled
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'SHIPPING_COST_ENABLED'?: string;
    /**
     * Whether taxfree shipping is enabled
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'TAXFREE_SHIPPING_ENABLED'?: string;
    /**
     * Whether oss sales are enabled
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'OSS_ENABLED'?: string;
    /**
     * Whether Gift card are enabled
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'GIFT_CARD_ENABLED'?: string;
    /**
     * Gift card maximum amount
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'GIFT_CARD_MAX_AMOUNT'?: string;
    /**
     * Gift card minimum amount
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'GIFT_CARD_MIN_AMOUNT'?: string;
    /**
     * whether barcode sales are enabled
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'SALES_BARCODE_ENABLED'?: string;
    /**
     * enable more reads for barcodes (1) or not (0)
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'SALES_READ_MULTIPLE_BARCODES'?: string;
    /**
     * enable petty cash
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'PETTY_CASH_MODE'?: string;
    /**
     * whether fidelity points are retuned
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'FIDELITY_POINTS_ON_RETURNS'?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof GetStoreConfigDataAttributes
     */
    'tipi_listino'?: Array<string>;
    /**
     *
     * @type {GetStoreConfigDataAttributesConsumer}
     * @memberof GetStoreConfigDataAttributes
     */
    'consumer'?: GetStoreConfigDataAttributesConsumer;
    /**
     *
     * @type {Array<GetStoreConfigDataAttributesSospesoTipoProdotto>}
     * @memberof GetStoreConfigDataAttributes
     */
    'sospeso_tipo_prodotto'?: Array<GetStoreConfigDataAttributesSospesoTipoProdotto>;
    /**
     *
     * @type {Array<GetStoreConfigDataAttributesSospesoPagamentoRemotoPreferito>}
     * @memberof GetStoreConfigDataAttributes
     */
    'sospeso_pagamento_remoto_preferito'?: Array<GetStoreConfigDataAttributesSospesoPagamentoRemotoPreferito>;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'LAYBY_ENABLED'?: string;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'LAYBY_MINIMUM'?: string;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'LAYBY_MINIMUM_UNIT'?: string;
    /**
     *
     * @type {Array<GetStoreConfigDataAttributesLabelsStatoLayby>}
     * @memberof GetStoreConfigDataAttributes
     */
    'LabelsStatoLayby'?: Array<GetStoreConfigDataAttributesLabelsStatoLayby>;
    /**
     * Flag that controls the ....
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'SKU_GRUPPO_ENABLED'?: string;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'CARDS_ENABLED'?: string;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'SHOW_FIDELITY_CALC_ENABLED'?: string;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'DATE_FORMAT'?: string;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'TIME_FORMAT'?: string;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'ANNOSTAGIONE_FULLSEARCH_ENABLED'?: string;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'ENDLESSAISLE_ENABLED'?: string;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'CRMEVENTS_ENABLED'?: string;
    /**
     * se LEDWALL_MODE è valorizzato, non vuoto, allora sarà presente anche l\'oggetto \"ledwall_service\"
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'LEDWALL_MODE'?: string;
    /**
     * se PICKING_ENABLED=1 allora sarà presente anche l\'oggetto \"picking_service\"
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'PICKING_ENABLED'?: string;
    /**
     *
     * @type {GetStoreConfigDataAttributesLedwallService}
     * @memberof GetStoreConfigDataAttributes
     */
    'ledwall_service'?: GetStoreConfigDataAttributesLedwallService;
    /**
     *
     * @type {GetStoreConfigDataAttributesPickingService}
     * @memberof GetStoreConfigDataAttributes
     */
    'picking_service'?: GetStoreConfigDataAttributesPickingService;
    /**
     *
     * @type {GetStoreConfigDataAttributesPickingService}
     * @memberof GetStoreConfigDataAttributes
     */
    'address_validation_service'?: GetStoreConfigDataAttributesPickingService;
    /**
     * Gestione listino esteso abilitata.
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'LISTINO_ESTESO_ENABLED'?: string;
    /**
     * Se \"1\" la ricerca con sette cifre è abilitata.
     * @type {string}
     * @memberof GetStoreConfigDataAttributes
     */
    'IS_STOCK_SHORTCODE_SEARCH_ENABLED'?: string;
}
/**
 *
 * @export
 * @interface GetStoreConfigDataAttributesConsumer
 */
export interface GetStoreConfigDataAttributesConsumer {
    /**
     *
     * @type {Array<GetStoreConfigDataAttributesConsumerSalutations>}
     * @memberof GetStoreConfigDataAttributesConsumer
     */
    'salutations'?: Array<GetStoreConfigDataAttributesConsumerSalutations>;
    /**
     *
     * @type {Array<string>}
     * @memberof GetStoreConfigDataAttributesConsumer
     */
    'languages'?: Array<string>;
    /**
     *
     * @type {Array<GetStoreConfigDataAttributesConsumerInteressi>}
     * @memberof GetStoreConfigDataAttributesConsumer
     */
    'interessi'?: Array<GetStoreConfigDataAttributesConsumerInteressi>;
    /**
     *
     * @type {Array<GetStoreConfigDataAttributesConsumerProfessioni>}
     * @memberof GetStoreConfigDataAttributesConsumer
     */
    'professioni'?: Array<GetStoreConfigDataAttributesConsumerProfessioni>;
}
/**
 *
 * @export
 * @interface GetStoreConfigDataAttributesConsumerInteressi
 */
export interface GetStoreConfigDataAttributesConsumerInteressi {
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributesConsumerInteressi
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributesConsumerInteressi
     */
    'descrizione'?: string;
}
/**
 *
 * @export
 * @interface GetStoreConfigDataAttributesConsumerProfessioni
 */
export interface GetStoreConfigDataAttributesConsumerProfessioni {
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributesConsumerProfessioni
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributesConsumerProfessioni
     */
    'descrizione'?: string;
}
/**
 *
 * @export
 * @interface GetStoreConfigDataAttributesConsumerSalutations
 */
export interface GetStoreConfigDataAttributesConsumerSalutations {
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributesConsumerSalutations
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributesConsumerSalutations
     */
    'descrizione'?: string;
}
/**
 *
 * @export
 * @interface GetStoreConfigDataAttributesLabelsStatoLayby
 */
export interface GetStoreConfigDataAttributesLabelsStatoLayby {
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributesLabelsStatoLayby
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributesLabelsStatoLayby
     */
    'descrizione'?: string;
}
/**
 *
 * @export
 * @interface GetStoreConfigDataAttributesLedwallService
 */
export interface GetStoreConfigDataAttributesLedwallService {
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributesLedwallService
     */
    'url'?: string;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributesLedwallService
     */
    'username'?: string;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributesLedwallService
     */
    'password'?: string;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributesLedwallService
     */
    'port'?: string;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributesLedwallService
     */
    'ledwall_port'?: string;
    /**
     *
     * @type {number}
     * @memberof GetStoreConfigDataAttributesLedwallService
     */
    'connection_timeout'?: number;
}
/**
 *
 * @export
 * @interface GetStoreConfigDataAttributesPickingService
 */
export interface GetStoreConfigDataAttributesPickingService {
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributesPickingService
     */
    'url'?: string;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributesPickingService
     */
    'username'?: string;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributesPickingService
     */
    'password'?: string;
}
/**
 *
 * @export
 * @interface GetStoreConfigDataAttributesSospesoPagamentoRemotoPreferito
 */
export interface GetStoreConfigDataAttributesSospesoPagamentoRemotoPreferito {
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributesSospesoPagamentoRemotoPreferito
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributesSospesoPagamentoRemotoPreferito
     */
    'descrizione'?: string;
}
/**
 *
 * @export
 * @interface GetStoreConfigDataAttributesSospesoTipoProdotto
 */
export interface GetStoreConfigDataAttributesSospesoTipoProdotto {
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributesSospesoTipoProdotto
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof GetStoreConfigDataAttributesSospesoTipoProdotto
     */
    'descrizione'?: string;
}
/**
 *
 * @export
 * @interface GetStoreConfigDataAttributesStoreData
 */
export interface GetStoreConfigDataAttributesStoreData {
    /**
     * Current store code
     * @type {string}
     * @memberof GetStoreConfigDataAttributesStoreData
     */
    'negozio'?: string;
    /**
     * Store address
     * @type {string}
     * @memberof GetStoreConfigDataAttributesStoreData
     */
    'indirizzo'?: string;
    /**
     * Store postal code
     * @type {string}
     * @memberof GetStoreConfigDataAttributesStoreData
     */
    'cap'?: string;
    /**
     * Store city description
     * @type {string}
     * @memberof GetStoreConfigDataAttributesStoreData
     */
    'citta'?: string;
    /**
     * Store city code
     * @type {string}
     * @memberof GetStoreConfigDataAttributesStoreData
     */
    'provincia'?: string;
    /**
     * Store telephone
     * @type {string}
     * @memberof GetStoreConfigDataAttributesStoreData
     */
    'telefono'?: string;
    /**
     * Store description
     * @type {string}
     * @memberof GetStoreConfigDataAttributesStoreData
     */
    'ragione_sociale'?: string;
    /**
     * Store
     * @type {string}
     * @memberof GetStoreConfigDataAttributesStoreData
     */
    'citta_ditta'?: string;
    /**
     * ?
     * @type {string}
     * @memberof GetStoreConfigDataAttributesStoreData
     */
    'indirizzo_ditta'?: string;
    /**
     * ?
     * @type {string}
     * @memberof GetStoreConfigDataAttributesStoreData
     */
    'cap_ditta'?: string;
    /**
     * ?
     * @type {string}
     * @memberof GetStoreConfigDataAttributesStoreData
     */
    'telefono_ditta'?: string;
    /**
     * Store ISO country
     * @type {string}
     * @memberof GetStoreConfigDataAttributesStoreData
     */
    'sigla_nazione'?: string;
    /**
     * ?
     * @type {string}
     * @memberof GetStoreConfigDataAttributesStoreData
     */
    'sigla_provincia'?: string;
}
/**
 *
 * @export
 * @interface GetStoreConfigDataAttributesTipiVendita
 */
export interface GetStoreConfigDataAttributesTipiVendita {
    /**
     * movement type code
     * @type {string}
     * @memberof GetStoreConfigDataAttributesTipiVendita
     */
    'codice_movimento'?: string;
    /**
     * movement type id
     * @type {string}
     * @memberof GetStoreConfigDataAttributesTipiVendita
     */
    'id_tipo_movimento'?: string;
    /**
     * description in the selected user language
     * @type {string}
     * @memberof GetStoreConfigDataAttributesTipiVendita
     */
    'descrizione'?: string;
}
/**
 *
 * @export
 * @interface GetStoreConfigDataAttributesTipiVenditaTessuto
 */
export interface GetStoreConfigDataAttributesTipiVenditaTessuto {
    /**
     * movement type code
     * @type {string}
     * @memberof GetStoreConfigDataAttributesTipiVenditaTessuto
     */
    'codice_movimento'?: string;
    /**
     * movement type id
     * @type {string}
     * @memberof GetStoreConfigDataAttributesTipiVenditaTessuto
     */
    'id_tipo_movimento'?: string;
    /**
     * description in the selected user language
     * @type {string}
     * @memberof GetStoreConfigDataAttributesTipiVenditaTessuto
     */
    'descrizione'?: string;
}
/**
 *
 * @export
 * @interface GetStoreConnectionStatus
 */
export interface GetStoreConnectionStatus {
    /**
     *
     * @type {GetStoreConnectionStatusData}
     * @memberof GetStoreConnectionStatus
     */
    'data'?: GetStoreConnectionStatusData;
}
/**
 *
 * @export
 * @interface GetStoreConnectionStatusData
 */
export interface GetStoreConnectionStatusData {
    /**
     *
     * @type {string}
     * @memberof GetStoreConnectionStatusData
     */
    'type'?: string;
    /**
     * Unique id
     * @type {string}
     * @memberof GetStoreConnectionStatusData
     */
    'id'?: string;
    /**
     *
     * @type {GetStoreConnectionStatusDataAttributes}
     * @memberof GetStoreConnectionStatusData
     */
    'attributes'?: GetStoreConnectionStatusDataAttributes;
}
/**
 *
 * @export
 * @interface GetStoreConnectionStatusDataAttributes
 */
export interface GetStoreConnectionStatusDataAttributes {
    /**
     * connectivity to corporate status
     * @type {string}
     * @memberof GetStoreConnectionStatusDataAttributes
     */
    'global_status'?: string;
    /**
     * last time global_status was checked
     * @type {string}
     * @memberof GetStoreConnectionStatusDataAttributes
     */
    'global_timestamp'?: string;
    /**
     * sid status
     * @type {string}
     * @memberof GetStoreConnectionStatusDataAttributes
     */
    'user_status'?: string;
    /**
     * last time sid was used
     * @type {string}
     * @memberof GetStoreConnectionStatusDataAttributes
     */
    'user_timestamp'?: string;
}
/**
 *
 * @export
 * @interface GetSystemConfig
 */
export interface GetSystemConfig {
    /**
     *
     * @type {GetSystemConfigData}
     * @memberof GetSystemConfig
     */
    'data'?: GetSystemConfigData;
}
/**
 *
 * @export
 * @interface GetSystemConfigData
 */
export interface GetSystemConfigData {
    /**
     *
     * @type {string}
     * @memberof GetSystemConfigData
     */
    'type'?: string;
    /**
     * Unique id of the current installation
     * @type {string}
     * @memberof GetSystemConfigData
     */
    'id'?: string;
    /**
     *
     * @type {GetSystemConfigDataAttributes}
     * @memberof GetSystemConfigData
     */
    'attributes'?: GetSystemConfigDataAttributes;
}
/**
 *
 * @export
 * @interface GetSystemConfigDataAttributes
 */
export interface GetSystemConfigDataAttributes {
    /**
     * List of avaiable store codes
     * @type {Array<any>}
     * @memberof GetSystemConfigDataAttributes
     */
    'stores'?: Array<any>;
    /**
     * Unique id of the current installation
     * @type {string}
     * @memberof GetSystemConfigDataAttributes
     */
    'cod_installazione'?: string;
    /**
     * Installation type (cash register/ wharehouse / multi-tenant / etc
     * @type {string}
     * @memberof GetSystemConfigDataAttributes
     */
    'feature_set'?: string;
    /**
     * Role inside the subnet (MASTER/SLAVE)
     * @type {string}
     * @memberof GetSystemConfigDataAttributes
     */
    'cash_role'?: string;
    /**
     * Unique id of the installation in the subnet
     * @type {string}
     * @memberof GetSystemConfigDataAttributes
     */
    'cash_id'?: string;
    /**
     * Store default language
     * @type {string}
     * @memberof GetSystemConfigDataAttributes
     */
    'gui_language'?: string;
    /**
     * Enviroment prod/test/dev
     * @type {string}
     * @memberof GetSystemConfigDataAttributes
     */
    'deploy_env'?: string;
    /**
     * Should we use the centralized authentication provider?
     * @type {boolean}
     * @memberof GetSystemConfigDataAttributes
     */
    'portal_login'?: boolean;
    /**
     * URL of the authentication provider
     * @type {string}
     * @memberof GetSystemConfigDataAttributes
     */
    'portal_url'?: string;
}
/**
 *
 * @export
 * @interface GetTipoVersamento
 */
export interface GetTipoVersamento {
    /**
     * List of bank deposit types
     * @type {Array<GetTipoVersamentoData>}
     * @memberof GetTipoVersamento
     */
    'data'?: Array<GetTipoVersamentoData>;
}
/**
 *
 * @export
 * @interface GetTipoVersamentoAttributes
 */
export interface GetTipoVersamentoAttributes {
    /**
     *
     * @type {string}
     * @memberof GetTipoVersamentoAttributes
     */
    'tipo'?: string;
    /**
     *
     * @type {string}
     * @memberof GetTipoVersamentoAttributes
     */
    'label'?: string;
}
/**
 * Bank deposits detail type
 * @export
 * @interface GetTipoVersamentoData
 */
export interface GetTipoVersamentoData {
    /**
     *
     * @type {GetTipoVersamentoAttributes}
     * @memberof GetTipoVersamentoData
     */
    'attributes'?: GetTipoVersamentoAttributes;
}
/**
 *
 * @export
 * @interface GetTranslations
 */
export interface GetTranslations {
    /**
     *
     * @type {Array<GetTranslationsData>}
     * @memberof GetTranslations
     */
    'data'?: Array<GetTranslationsData>;
}
/**
 *
 * @export
 * @interface GetTranslationsAttributes
 */
export interface GetTranslationsAttributes {
    /**
     * Translation group
     * @type {string}
     * @memberof GetTranslationsAttributes
     */
    'cluster'?: string;
    /**
     * Translation id
     * @type {string}
     * @memberof GetTranslationsAttributes
     */
    'id'?: string;
    /**
     * Language ISO code
     * @type {string}
     * @memberof GetTranslationsAttributes
     */
    'language'?: string;
    /**
     * Text in italian
     * @type {string}
     * @memberof GetTranslationsAttributes
     */
    'original_text'?: string;
    /**
     * Project identifier: always POS
     * @type {string}
     * @memberof GetTranslationsAttributes
     */
    'project'?: string;
    /**
     * Translated text
     * @type {string}
     * @memberof GetTranslationsAttributes
     */
    'translated_text'?: string;
}
/**
 *
 * @export
 * @interface GetTranslationsData
 */
export interface GetTranslationsData {
    /**
     *
     * @type {string}
     * @memberof GetTranslationsData
     */
    'type'?: string;
    /**
     * Unique key: cluster + id + language
     * @type {string}
     * @memberof GetTranslationsData
     */
    'id'?: string;
    /**
     *
     * @type {GetTranslationsAttributes}
     * @memberof GetTranslationsData
     */
    'attributes'?: GetTranslationsAttributes;
}
/**
 *
 * @export
 * @interface GetVATRate
 */
export interface GetVATRate {
    /**
     *
     * @type {GetVATRateData}
     * @memberof GetVATRate
     */
    'data'?: GetVATRateData;
}
/**
 *
 * @export
 * @interface GetVATRateData
 */
export interface GetVATRateData {
    /**
     * VAT code Id, made of AliquoteIva values, for (\'nazione\', \'cod_negozio\', \'target_type\', \'target\', \'data_inizio_validita\'), for that store
     * @type {string}
     * @memberof GetVATRateData
     */
    'id'?: string;
    /**
     * Type of node
     * @type {string}
     * @memberof GetVATRateData
     */
    'type'?: string;
    /**
     *
     * @type {GetVATRateDataAttributes}
     * @memberof GetVATRateData
     */
    'attributes'?: GetVATRateDataAttributes;
}
/**
 * Attributes detailing the VAT code and rate
 * @export
 * @interface GetVATRateDataAttributes
 */
export interface GetVATRateDataAttributes {
    /**
     * Country
     * @type {string}
     * @memberof GetVATRateDataAttributes
     */
    'nazione'?: string;
    /**
     * Target type
     * @type {string}
     * @memberof GetVATRateDataAttributes
     */
    'target_type'?: string;
    /**
     * Target
     * @type {number}
     * @memberof GetVATRateDataAttributes
     */
    'target'?: number;
    /**
     * VAT rate in percentage
     * @type {number}
     * @memberof GetVATRateDataAttributes
     */
    'vat_percentage'?: number;
}
/**
 *
 * @export
 * @interface GetYmalData
 */
export interface GetYmalData {
    /**
     *
     * @type {Array<GetYmalDataData>}
     * @memberof GetYmalData
     */
    'data'?: Array<GetYmalDataData>;
}
/**
 *
 * @export
 * @interface GetYmalDataAttributes
 */
export interface GetYmalDataAttributes {
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributes
     */
    'code'?: string;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributes
     */
    'name'?: string;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributes
     */
    'cod_categoria'?: string;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributes
     */
    'desc_categoria'?: string;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributes
     */
    'cod_sottocategoria'?: string;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributes
     */
    'desc_sottocategoria'?: string;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributes
     */
    'brand'?: string;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributes
     */
    'brand_originale'?: string;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributes
     */
    'societa'?: string;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributes
     */
    'classe'?: string;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributes
     */
    'desc_classe'?: string;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributes
     */
    'annostag'?: string;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributes
     */
    'composition'?: string;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributes
     */
    'washing_description'?: string | null;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributes
     */
    'technical_description'?: string | null;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributes
     */
    'emotional_description'?: string | null;
    /**
     *
     * @type {Array<GetYmalDataAttributesVariants>}
     * @memberof GetYmalDataAttributes
     */
    'variants'?: Array<GetYmalDataAttributesVariants>;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributes
     */
    'stagionale'?: string;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributes
     */
    'price_type_applied'?: string;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributes
     */
    'desc_classe_it'?: string;
    /**
     *
     * @type {Array<GetYmalDataAttributesPrices>}
     * @memberof GetYmalDataAttributes
     */
    'prices'?: Array<GetYmalDataAttributesPrices>;
    /**
     *
     * @type {number}
     * @memberof GetYmalDataAttributes
     */
    'iva'?: number;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributes
     */
    'model_img'?: string;
}
/**
 *
 * @export
 * @interface GetYmalDataAttributesPrices
 */
export interface GetYmalDataAttributesPrices {
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributesPrices
     */
    'price_type'?: string;
    /**
     *
     * @type {number}
     * @memberof GetYmalDataAttributesPrices
     */
    'price'?: number;
}
/**
 *
 * @export
 * @interface GetYmalDataAttributesSizes
 */
export interface GetYmalDataAttributesSizes {
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributesSizes
     */
    'code'?: string;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributesSizes
     */
    'description'?: string;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributesSizes
     */
    'sku'?: string;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributesSizes
     */
    'ean'?: string;
}
/**
 *
 * @export
 * @interface GetYmalDataAttributesVariants
 */
export interface GetYmalDataAttributesVariants {
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributesVariants
     */
    'code'?: string;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributesVariants
     */
    'description'?: string;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributesVariants
     */
    'priority'?: string;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributesVariants
     */
    'listing_img'?: string | null;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributesVariants
     */
    'swatch_img'?: string | null;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributesVariants
     */
    'details_img'?: string | null;
    /**
     *
     * @type {Array<GetYmalDataAttributesSizes>}
     * @memberof GetYmalDataAttributesVariants
     */
    'sizes'?: Array<GetYmalDataAttributesSizes>;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataAttributesVariants
     */
    'color_img'?: string;
    /**
     *
     * @type {Array<GetYmalDataAttributesPrices>}
     * @memberof GetYmalDataAttributesVariants
     */
    'prices'?: Array<GetYmalDataAttributesPrices>;
}
/**
 *
 * @export
 * @interface GetYmalDataData
 */
export interface GetYmalDataData {
    /**
     *
     * @type {string}
     * @memberof GetYmalDataData
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof GetYmalDataData
     */
    'id'?: string;
    /**
     *
     * @type {GetYmalDataAttributes}
     * @memberof GetYmalDataData
     */
    'attributes'?: GetYmalDataAttributes;
}
/**
 *
 * @export
 * @interface GiftCardActivateAnswer
 */
export interface GiftCardActivateAnswer {
    /**
     *
     * @type {Array<object>}
     * @memberof GiftCardActivateAnswer
     */
    'data'?: Array<object>;
}
/**
 *
 * @export
 * @interface GiftCardData
 */
export interface GiftCardData {
    /**
     *
     * @type {string}
     * @memberof GiftCardData
     */
    'type'?: string;
    /**
     *
     * @type {GiftCardData1DataAttributesCardData}
     * @memberof GiftCardData
     */
    'attributes'?: GiftCardData1DataAttributesCardData;
}
/**
 *
 * @export
 * @interface GiftCardData1
 */
export interface GiftCardData1 {
    /**
     *
     * @type {GiftCardData1Data}
     * @memberof GiftCardData1
     */
    'data'?: GiftCardData1Data;
}
/**
 *
 * @export
 * @interface GiftCardData1Data
 */
export interface GiftCardData1Data {
    /**
     *
     * @type {string}
     * @memberof GiftCardData1Data
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof GiftCardData1Data
     */
    'id'?: string;
    /**
     *
     * @type {GiftCardData1DataAttributes}
     * @memberof GiftCardData1Data
     */
    'attributes'?: GiftCardData1DataAttributes;
}
/**
 *
 * @export
 * @interface GiftCardData1DataAttributes
 */
export interface GiftCardData1DataAttributes {
    /**
     *
     * @type {number}
     * @memberof GiftCardData1DataAttributes
     */
    'importo'?: number;
    /**
     *
     * @type {number}
     * @memberof GiftCardData1DataAttributes
     */
    'row_num'?: number;
    /**
     *
     * @type {GiftCardData1DataAttributesCardData}
     * @memberof GiftCardData1DataAttributes
     */
    'card_data'?: GiftCardData1DataAttributesCardData;
}
/**
 *
 * @export
 * @interface GiftCardData1DataAttributesCardData
 */
export interface GiftCardData1DataAttributesCardData {
    /**
     *
     * @type {string}
     * @memberof GiftCardData1DataAttributesCardData
     */
    'StatusTranslated'?: string;
    /**
     *
     * @type {boolean}
     * @memberof GiftCardData1DataAttributesCardData
     */
    'gift_is_storecredit'?: boolean;
    /**
     *
     * @type {string}
     * @memberof GiftCardData1DataAttributesCardData
     */
    'ExpireDate'?: string;
    /**
     *
     * @type {string}
     * @memberof GiftCardData1DataAttributesCardData
     */
    'Result'?: string;
    /**
     *
     * @type {string}
     * @memberof GiftCardData1DataAttributesCardData
     */
    'AmountLeft'?: string;
    /**
     *
     * @type {string}
     * @memberof GiftCardData1DataAttributesCardData
     */
    'CardCode'?: string;
    /**
     *
     * @type {string}
     * @memberof GiftCardData1DataAttributesCardData
     */
    'IssueDate'?: string;
    /**
     *
     * @type {string}
     * @memberof GiftCardData1DataAttributesCardData
     */
    'Price'?: string;
    /**
     *
     * @type {string}
     * @memberof GiftCardData1DataAttributesCardData
     */
    'Status'?: string;
    /**
     *
     * @type {string}
     * @memberof GiftCardData1DataAttributesCardData
     */
    'CurrencyChanges'?: string;
    /**
     *
     * @type {string}
     * @memberof GiftCardData1DataAttributesCardData
     */
    'BurnDate'?: string;
    /**
     *
     * @type {ReturnBurnGiftCard1DataAttributesCardDataOperations}
     * @memberof GiftCardData1DataAttributesCardData
     */
    'Operations'?: ReturnBurnGiftCard1DataAttributesCardDataOperations;
}
/**
 *
 * @export
 * @interface GiftCardInformation
 */
export interface GiftCardInformation {
    /**
     *
     * @type {GiftCardInformationData}
     * @memberof GiftCardInformation
     */
    'data'?: GiftCardInformationData;
}
/**
 *
 * @export
 * @interface GiftCardInformationData
 */
export interface GiftCardInformationData {
    /**
     *
     * @type {string}
     * @memberof GiftCardInformationData
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof GiftCardInformationData
     */
    'id'?: string;
    /**
     *
     * @type {GiftCardInformationDataAttributes}
     * @memberof GiftCardInformationData
     */
    'attributes'?: GiftCardInformationDataAttributes;
}
/**
 *
 * @export
 * @interface GiftCardInformationDataAttributes
 */
export interface GiftCardInformationDataAttributes {
    /**
     *
     * @type {string}
     * @memberof GiftCardInformationDataAttributes
     */
    'ExpireDate'?: string;
    /**
     *
     * @type {string}
     * @memberof GiftCardInformationDataAttributes
     */
    'Result'?: string;
    /**
     *
     * @type {string}
     * @memberof GiftCardInformationDataAttributes
     */
    'AmountLeft'?: string;
    /**
     *
     * @type {string}
     * @memberof GiftCardInformationDataAttributes
     */
    'CardCode'?: string;
    /**
     *
     * @type {string}
     * @memberof GiftCardInformationDataAttributes
     */
    'IssueDate'?: string;
    /**
     *
     * @type {string}
     * @memberof GiftCardInformationDataAttributes
     */
    'Price'?: string;
    /**
     *
     * @type {string}
     * @memberof GiftCardInformationDataAttributes
     */
    'Status'?: string;
    /**
     *
     * @type {string}
     * @memberof GiftCardInformationDataAttributes
     */
    'CurrencyChanges'?: string;
    /**
     *
     * @type {object}
     * @memberof GiftCardInformationDataAttributes
     */
    'BurnDate'?: object;
    /**
     *
     * @type {string}
     * @memberof GiftCardInformationDataAttributes
     */
    'TranslatedStatus'?: string;
    /**
     *
     * @type {GiftCardInformationDataAttributesOperations}
     * @memberof GiftCardInformationDataAttributes
     */
    'Operations'?: GiftCardInformationDataAttributesOperations;
}
/**
 *
 * @export
 * @interface GiftCardInformationDataAttributesOperations
 */
export interface GiftCardInformationDataAttributesOperations {
    /**
     *
     * @type {Array<GiftCardInformationDataAttributesOperationsIssuedCardOperation>}
     * @memberof GiftCardInformationDataAttributesOperations
     */
    'IssuedCardOperation'?: Array<GiftCardInformationDataAttributesOperationsIssuedCardOperation>;
}
/**
 *
 * @export
 * @interface GiftCardInformationDataAttributesOperationsIssuedCardOperation
 */
export interface GiftCardInformationDataAttributesOperationsIssuedCardOperation {
    /**
     *
     * @type {string}
     * @memberof GiftCardInformationDataAttributesOperationsIssuedCardOperation
     */
    'Amount'?: string;
    /**
     *
     * @type {string}
     * @memberof GiftCardInformationDataAttributesOperationsIssuedCardOperation
     */
    'CurrencyID'?: string;
    /**
     *
     * @type {string}
     * @memberof GiftCardInformationDataAttributesOperationsIssuedCardOperation
     */
    'Date'?: string;
    /**
     *
     * @type {string}
     * @memberof GiftCardInformationDataAttributesOperationsIssuedCardOperation
     */
    'OperationType'?: string;
    /**
     *
     * @type {string}
     * @memberof GiftCardInformationDataAttributesOperationsIssuedCardOperation
     */
    'OperatorDescription'?: string;
    /**
     *
     * @type {string}
     * @memberof GiftCardInformationDataAttributesOperationsIssuedCardOperation
     */
    'OperatorTermId'?: string;
    /**
     *
     * @type {string}
     * @memberof GiftCardInformationDataAttributesOperationsIssuedCardOperation
     */
    'TranslatedOperationType'?: string;
}
/**
 *
 * @export
 * @interface GiftCards
 */
export interface GiftCards {
    /**
     *
     * @type {Array<ApiV1GiftCardsActivateSaleIdData>}
     * @memberof GiftCards
     */
    'data'?: Array<ApiV1GiftCardsActivateSaleIdData>;
}
/**
 *
 * @export
 * @interface InformazioniConsumatriciTrovateConSospesi
 */
export interface InformazioniConsumatriciTrovateConSospesi {
    /**
     *
     * @type {InformazioniConsumatriciTrovateConSospesiData}
     * @memberof InformazioniConsumatriciTrovateConSospesi
     */
    'data'?: InformazioniConsumatriciTrovateConSospesiData;
}
/**
 *
 * @export
 * @interface InformazioniConsumatriciTrovateConSospesiData
 */
export interface InformazioniConsumatriciTrovateConSospesiData {
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiData
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiData
     */
    'id'?: string;
    /**
     *
     * @type {Array<InformazioniConsumatriciTrovateConSospesiDataAttributes>}
     * @memberof InformazioniConsumatriciTrovateConSospesiData
     */
    'attributes'?: Array<InformazioniConsumatriciTrovateConSospesiDataAttributes>;
}
/**
 *
 * @export
 * @interface InformazioniConsumatriciTrovateConSospesiDataAttributes
 */
export interface InformazioniConsumatriciTrovateConSospesiDataAttributes {
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'pk_consumer'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'pk_crm'?: string;
    /**
     *
     * @type {number}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'locale'?: number;
    /**
     *
     * @type {number}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'stessa_insegna'?: number;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'cognome'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'nome'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'sesso'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'lingua'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'giorno_nascita'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'mese_nascita'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'anno_nascita'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'anno_nascita_presunto'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'nazione_iso'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'provincia'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'localita'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'cap'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'indirizzo'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'numero_civico'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'edificio'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'note_indirizzo'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'telefono1'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'telefono2'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'cellulare1'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'cellulare2'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'email'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'wechat'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'note'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'note_negozio'?: string;
    /**
     *
     * @type {number}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'grado_anonimato'?: number;
    /**
     *
     * @type {string}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'data_ultimo_aggiornamento'?: string;
    /**
     *
     * @type {InformazioniConsumatriciTrovateConSospesiDataConsensi}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'consensi'?: InformazioniConsumatriciTrovateConSospesiDataConsensi;
    /**
     *
     * @type {number}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'staff'?: number;
    /**
     *
     * @type {number}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'vip_1'?: number;
    /**
     *
     * @type {number}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataAttributes
     */
    'vip_2'?: number;
}
/**
 *
 * @export
 * @interface InformazioniConsumatriciTrovateConSospesiDataConsensi
 */
export interface InformazioniConsumatriciTrovateConSospesiDataConsensi {
    /**
     *
     * @type {number}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataConsensi
     */
    'Direct Marketing'?: number;
    /**
     *
     * @type {number}
     * @memberof InformazioniConsumatriciTrovateConSospesiDataConsensi
     */
    'Profiling'?: number;
}
/**
 *
 * @export
 * @interface InformazioniCreditiSospesi
 */
export interface InformazioniCreditiSospesi {
    /**
     *
     * @type {Array<object>}
     * @memberof InformazioniCreditiSospesi
     */
    'data'?: Array<object>;
}
/**
 *
 * @export
 * @interface InformazioniEsitoPagamentoSospesi
 */
export interface InformazioniEsitoPagamentoSospesi {
    /**
     *
     * @type {InformazioniEsitoPagamentoSospesiData}
     * @memberof InformazioniEsitoPagamentoSospesi
     */
    'data'?: InformazioniEsitoPagamentoSospesiData;
}
/**
 *
 * @export
 * @interface InformazioniEsitoPagamentoSospesiData
 */
export interface InformazioniEsitoPagamentoSospesiData {
    /**
     *
     * @type {string}
     * @memberof InformazioniEsitoPagamentoSospesiData
     */
    'type'?: string;
    /**
     *
     * @type {InformazioniEsitoPagamentoSospesiDataAttributes}
     * @memberof InformazioniEsitoPagamentoSospesiData
     */
    'attributes'?: InformazioniEsitoPagamentoSospesiDataAttributes;
}
/**
 *
 * @export
 * @interface InformazioniEsitoPagamentoSospesiDataAttributes
 */
export interface InformazioniEsitoPagamentoSospesiDataAttributes {
    /**
     *
     * @type {string}
     * @memberof InformazioniEsitoPagamentoSospesiDataAttributes
     */
    'status'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniEsitoPagamentoSospesiDataAttributes
     */
    'message'?: string;
    /**
     *
     * @type {number}
     * @memberof InformazioniEsitoPagamentoSospesiDataAttributes
     */
    'num_sospesi'?: number;
    /**
     *
     * @type {string}
     * @memberof InformazioniEsitoPagamentoSospesiDataAttributes
     */
    'print_error_result'?: string;
    /**
     *
     * @type {Array<InformazioniEsitoPagamentoSospesiDataAttributesErrors>}
     * @memberof InformazioniEsitoPagamentoSospesiDataAttributes
     */
    'errors'?: Array<InformazioniEsitoPagamentoSospesiDataAttributesErrors>;
}
/**
 *
 * @export
 * @interface InformazioniEsitoPagamentoSospesiDataAttributesErrors
 */
export interface InformazioniEsitoPagamentoSospesiDataAttributesErrors {
    /**
     *
     * @type {string}
     * @memberof InformazioniEsitoPagamentoSospesiDataAttributesErrors
     */
    'progressivo'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniEsitoPagamentoSospesiDataAttributesErrors
     */
    'field'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniEsitoPagamentoSospesiDataAttributesErrors
     */
    'message'?: string;
}
/**
 *
 * @export
 * @interface InformazioniListaPagamentiDiSospesi
 */
export interface InformazioniListaPagamentiDiSospesi {
    /**
     *
     * @type {InformazioniListaPagamentiDiSospesiData}
     * @memberof InformazioniListaPagamentiDiSospesi
     */
    'data'?: InformazioniListaPagamentiDiSospesiData;
}
/**
 *
 * @export
 * @interface InformazioniListaPagamentiDiSospesiData
 */
export interface InformazioniListaPagamentiDiSospesiData {
    /**
     *
     * @type {string}
     * @memberof InformazioniListaPagamentiDiSospesiData
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniListaPagamentiDiSospesiData
     */
    'id'?: string;
    /**
     *
     * @type {Array<InformazioniListaPagamentiDiSospesiDataAttributes>}
     * @memberof InformazioniListaPagamentiDiSospesiData
     */
    'attributes'?: Array<InformazioniListaPagamentiDiSospesiDataAttributes>;
}
/**
 *
 * @export
 * @interface InformazioniListaPagamentiDiSospesiDataAttributes
 */
export interface InformazioniListaPagamentiDiSospesiDataAttributes {
    /**
     *
     * @type {number}
     * @memberof InformazioniListaPagamentiDiSospesiDataAttributes
     */
    'id_transazione'?: number;
    /**
     *
     * @type {string}
     * @memberof InformazioniListaPagamentiDiSospesiDataAttributes
     */
    'data_pagamento'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniListaPagamentiDiSospesiDataAttributes
     */
    'ora_pagamento'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniListaPagamentiDiSospesiDataAttributes
     */
    'cod_cassa'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniListaPagamentiDiSospesiDataAttributes
     */
    'cod_cassiera'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniListaPagamentiDiSospesiDataAttributes
     */
    'pk_consumer'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniListaPagamentiDiSospesiDataAttributes
     */
    'cognome'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniListaPagamentiDiSospesiDataAttributes
     */
    'nome'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniListaPagamentiDiSospesiDataAttributes
     */
    'sistema'?: string;
    /**
     *
     * @type {number}
     * @memberof InformazioniListaPagamentiDiSospesiDataAttributes
     */
    'importo_totale'?: number;
    /**
     *
     * @type {Array<InformazioniListaPagamentiDiSospesiDataDistinta>}
     * @memberof InformazioniListaPagamentiDiSospesiDataAttributes
     */
    'distinta'?: Array<InformazioniListaPagamentiDiSospesiDataDistinta>;
    /**
     *
     * @type {string}
     * @memberof InformazioniListaPagamentiDiSospesiDataAttributes
     */
    'data_ora_pag'?: string;
    /**
     *
     * @type {boolean}
     * @memberof InformazioniListaPagamentiDiSospesiDataAttributes
     */
    'ristampa_enabled'?: boolean;
    /**
     *
     * @type {string}
     * @memberof InformazioniListaPagamentiDiSospesiDataAttributes
     */
    'ristampa_msg'?: string;
}
/**
 *
 * @export
 * @interface InformazioniListaPagamentiDiSospesiDataDistinta
 */
export interface InformazioniListaPagamentiDiSospesiDataDistinta {
    /**
     *
     * @type {number}
     * @memberof InformazioniListaPagamentiDiSospesiDataDistinta
     */
    'numero_documento'?: number;
    /**
     *
     * @type {string}
     * @memberof InformazioniListaPagamentiDiSospesiDataDistinta
     */
    'data_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniListaPagamentiDiSospesiDataDistinta
     */
    'cod_operazione'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniListaPagamentiDiSospesiDataDistinta
     */
    'importo'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniListaPagamentiDiSospesiDataDistinta
     */
    'divisa'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniListaPagamentiDiSospesiDataDistinta
     */
    'd_doc'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniListaPagamentiDiSospesiDataDistinta
     */
    'imp'?: string;
    /**
     *
     * @type {string}
     * @memberof InformazioniListaPagamentiDiSospesiDataDistinta
     */
    'messaggio'?: string;
}
/**
 *
 * @export
 * @interface InformazioniListaPagamentiEffettuati
 */
export interface InformazioniListaPagamentiEffettuati {
    /**
     *
     * @type {Array<object>}
     * @memberof InformazioniListaPagamentiEffettuati
     */
    'data'?: Array<object>;
}
/**
 *
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     *
     * @type {ApiV1ConsumersPkConsumerSchedaLinguaData}
     * @memberof InlineObject
     */
    'data'?: ApiV1ConsumersPkConsumerSchedaLinguaData;
}
/**
 *
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     *
     * @type {ApiV1ConsumersPkConsumerHistorySchedaData}
     * @memberof InlineObject1
     */
    'data'?: ApiV1ConsumersPkConsumerHistorySchedaData;
}
/**
 *
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     *
     * @type {ApiV1ConsumersAddressValidationData}
     * @memberof InlineObject2
     */
    'data'?: ApiV1ConsumersAddressValidationData;
}
/**
 *
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     *
     * @type {ApiV1SalesData}
     * @memberof InlineResponse200
     */
    'data'?: ApiV1SalesData;
}
/**
 *
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     *
     * @type {InlineResponse2001Data}
     * @memberof InlineResponse2001
     */
    'data'?: InlineResponse2001Data;
}
/**
 *
 * @export
 * @interface InlineResponse2001Data
 */
export interface InlineResponse2001Data {
    /**
     *
     * @type {InlineResponse2001DataAttributes}
     * @memberof InlineResponse2001Data
     */
    'attributes'?: InlineResponse2001DataAttributes;
}
/**
 *
 * @export
 * @interface InlineResponse2001DataAttributes
 */
export interface InlineResponse2001DataAttributes {
    /**
     *
     * @type {string}
     * @memberof InlineResponse2001DataAttributes
     */
    'status'?: string;
}
/**
 *
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     *
     * @type {InlineResponse2002Data}
     * @memberof InlineResponse2002
     */
    'data'?: InlineResponse2002Data;
}
/**
 *
 * @export
 * @interface InlineResponse2002Data
 */
export interface InlineResponse2002Data {
    /**
     *
     * @type {Array<InlineResponse2002DataAttributes>}
     * @memberof InlineResponse2002Data
     */
    'attributes'?: Array<InlineResponse2002DataAttributes>;
}
/**
 *
 * @export
 * @interface InlineResponse2002DataAttributes
 */
export interface InlineResponse2002DataAttributes {
    /**
     *
     * @type {string}
     * @memberof InlineResponse2002DataAttributes
     */
    'country'?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse2002DataAttributes
     */
    'address'?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse2002DataAttributes
     */
    'addressNotes'?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse2002DataAttributes
     */
    'building'?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse2002DataAttributes
     */
    'city'?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse2002DataAttributes
     */
    'numberStreet'?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse2002DataAttributes
     */
    'postalCode'?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse2002DataAttributes
     */
    'province'?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse2002DataAttributes
     */
    'state'?: string;
}
/**
 *
 * @export
 * @interface InlineResponse204
 */
export interface InlineResponse204 {
    /**
     *
     * @type {Array<InlineResponse204Data>}
     * @memberof InlineResponse204
     */
    'data'?: Array<InlineResponse204Data>;
}
/**
 *
 * @export
 * @interface InlineResponse2041
 */
export interface InlineResponse2041 {
    /**
     *
     * @type {Array<InlineResponse2041Data>}
     * @memberof InlineResponse2041
     */
    'data'?: Array<InlineResponse2041Data>;
}
/**
 *
 * @export
 * @interface InlineResponse2041Attributes
 */
export interface InlineResponse2041Attributes {
    /**
     *
     * @type {string}
     * @memberof InlineResponse2041Attributes
     */
    'html'?: string;
}
/**
 * Reports status of printing attempt
 * @export
 * @interface InlineResponse2041Data
 */
export interface InlineResponse2041Data {
    /**
     * Mutable id depending on type
     * @type {string}
     * @memberof InlineResponse2041Data
     */
    'id'?: string;
    /**
     * Type of print depending on cash register type
     * @type {string}
     * @memberof InlineResponse2041Data
     */
    'type'?: string;
    /**
     *
     * @type {InlineResponse2041Attributes}
     * @memberof InlineResponse2041Data
     */
    'attributes'?: InlineResponse2041Attributes;
}
/**
 *
 * @export
 * @interface InlineResponse204Attributes
 */
export interface InlineResponse204Attributes {
    /**
     * Translated result
     * @type {string}
     * @memberof InlineResponse204Attributes
     */
    'result'?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse204Attributes
     */
    'message'?: string;
}
/**
 * Reports status of printing attempt
 * @export
 * @interface InlineResponse204Data
 */
export interface InlineResponse204Data {
    /**
     * Mutable id depending on type
     * @type {string}
     * @memberof InlineResponse204Data
     */
    'id'?: string;
    /**
     * Type of payment in cod_operazione_resoconto
     * @type {string}
     * @memberof InlineResponse204Data
     */
    'type'?: string;
    /**
     *
     * @type {InlineResponse204Attributes}
     * @memberof InlineResponse204Data
     */
    'attributes'?: InlineResponse204Attributes;
}
/**
 *
 * @export
 * @interface InlineResponse400
 */
export interface InlineResponse400 {
    /**
     *
     * @type {Array<object>}
     * @memberof InlineResponse400
     */
    'errors'?: Array<object>;
}
/**
 *
 * @export
 * @interface InserimentoUtente
 */
export interface InserimentoUtente {
    /**
     *
     * @type {ApiV1UtentiData1}
     * @memberof InserimentoUtente
     */
    'data'?: ApiV1UtentiData1;
}
/**
 *
 * @export
 * @interface ListaDeiCapi
 */
export interface ListaDeiCapi {
    /**
     *
     * @type {Array<ListaDeiCapiData>}
     * @memberof ListaDeiCapi
     */
    'data'?: Array<ListaDeiCapiData>;
}
/**
 *
 * @export
 * @interface ListaDeiCapi1
 */
export interface ListaDeiCapi1 {
    /**
     *
     * @type {Array<ListaDeiCapi1Data>}
     * @memberof ListaDeiCapi1
     */
    'data'?: Array<ListaDeiCapi1Data>;
}
/**
 *
 * @export
 * @interface ListaDeiCapi1Attributes
 */
export interface ListaDeiCapi1Attributes {
    /**
     *
     * @type {string}
     * @memberof ListaDeiCapi1Attributes
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {string}
     * @memberof ListaDeiCapi1Attributes
     */
    'cod_cassa'?: string;
    /**
     *
     * @type {string}
     * @memberof ListaDeiCapi1Attributes
     */
    'sku'?: string;
    /**
     *
     * @type {string}
     * @memberof ListaDeiCapi1Attributes
     */
    'rfid'?: string;
    /**
     *
     * @type {string}
     * @memberof ListaDeiCapi1Attributes
     */
    'stato'?: string;
}
/**
 *
 * @export
 * @interface ListaDeiCapi1Data
 */
export interface ListaDeiCapi1Data {
    /**
     *
     * @type {string}
     * @memberof ListaDeiCapi1Data
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof ListaDeiCapi1Data
     */
    'id'?: string;
    /**
     *
     * @type {ListaDeiCapi1Attributes}
     * @memberof ListaDeiCapi1Data
     */
    'attributes'?: ListaDeiCapi1Attributes;
}
/**
 *
 * @export
 * @interface ListaDeiCapiAttributes
 */
export interface ListaDeiCapiAttributes {
    /**
     *
     * @type {string}
     * @memberof ListaDeiCapiAttributes
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {string}
     * @memberof ListaDeiCapiAttributes
     */
    'cod_cassa'?: string;
    /**
     *
     * @type {string}
     * @memberof ListaDeiCapiAttributes
     */
    'sku'?: string;
    /**
     *
     * @type {string}
     * @memberof ListaDeiCapiAttributes
     */
    'rfid'?: string;
    /**
     *
     * @type {string}
     * @memberof ListaDeiCapiAttributes
     */
    'sku_gruppo'?: string;
    /**
     *
     * @type {string}
     * @memberof ListaDeiCapiAttributes
     */
    'stato'?: string;
}
/**
 *
 * @export
 * @interface ListaDeiCapiData
 */
export interface ListaDeiCapiData {
    /**
     *
     * @type {string}
     * @memberof ListaDeiCapiData
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof ListaDeiCapiData
     */
    'id'?: string;
    /**
     *
     * @type {ListaDeiCapiAttributes}
     * @memberof ListaDeiCapiData
     */
    'attributes'?: ListaDeiCapiAttributes;
}
/**
 *
 * @export
 * @interface ListaDeiNuoviTotaliTransazione
 */
export interface ListaDeiNuoviTotaliTransazione {
    /**
     *
     * @type {ListaDeiNuoviTotaliTransazioneData}
     * @memberof ListaDeiNuoviTotaliTransazione
     */
    'data'?: ListaDeiNuoviTotaliTransazioneData;
}
/**
 *
 * @export
 * @interface ListaDeiNuoviTotaliTransazioneData
 */
export interface ListaDeiNuoviTotaliTransazioneData {
    /**
     *
     * @type {string}
     * @memberof ListaDeiNuoviTotaliTransazioneData
     */
    'type'?: string;
    /**
     *
     * @type {ListaDeiNuoviTotaliTransazioneDataAttributes}
     * @memberof ListaDeiNuoviTotaliTransazioneData
     */
    'attributes'?: ListaDeiNuoviTotaliTransazioneDataAttributes;
}
/**
 *
 * @export
 * @interface ListaDeiNuoviTotaliTransazioneDataAttributes
 */
export interface ListaDeiNuoviTotaliTransazioneDataAttributes {
    /**
     *
     * @type {string}
     * @memberof ListaDeiNuoviTotaliTransazioneDataAttributes
     */
    'status'?: string;
    /**
     *
     * @type {ListaDeiNuoviTotaliTransazioneDataAttributesTotals}
     * @memberof ListaDeiNuoviTotaliTransazioneDataAttributes
     */
    'totals'?: ListaDeiNuoviTotaliTransazioneDataAttributesTotals;
}
/**
 *
 * @export
 * @interface ListaDeiNuoviTotaliTransazioneDataAttributesTotals
 */
export interface ListaDeiNuoviTotaliTransazioneDataAttributesTotals {
    /**
     *
     * @type {number}
     * @memberof ListaDeiNuoviTotaliTransazioneDataAttributesTotals
     */
    'num_articoli'?: number;
    /**
     *
     * @type {number}
     * @memberof ListaDeiNuoviTotaliTransazioneDataAttributesTotals
     */
    'num_acquisti'?: number;
    /**
     *
     * @type {number}
     * @memberof ListaDeiNuoviTotaliTransazioneDataAttributesTotals
     */
    'num_storni'?: number;
    /**
     *
     * @type {number}
     * @memberof ListaDeiNuoviTotaliTransazioneDataAttributesTotals
     */
    'num_omaggi'?: number;
    /**
     *
     * @type {number}
     * @memberof ListaDeiNuoviTotaliTransazioneDataAttributesTotals
     */
    'num_articoli_fuori_negozio'?: number;
    /**
     *
     * @type {number}
     * @memberof ListaDeiNuoviTotaliTransazioneDataAttributesTotals
     */
    'totale_prezzo'?: number;
    /**
     *
     * @type {number}
     * @memberof ListaDeiNuoviTotaliTransazioneDataAttributesTotals
     */
    'totale_importo'?: number;
    /**
     *
     * @type {number}
     * @memberof ListaDeiNuoviTotaliTransazioneDataAttributesTotals
     */
    'totale_sartoria_modelli'?: number;
}
/**
 *
 * @export
 * @interface LoyaltyCardCreation
 */
export interface LoyaltyCardCreation {
    /**
     *
     * @type {ApiV1ConsumersPkConsumerCardsCreateData}
     * @memberof LoyaltyCardCreation
     */
    'data'?: ApiV1ConsumersPkConsumerCardsCreateData;
}
/**
 *
 * @export
 * @interface LoyaltyCardPrint
 */
export interface LoyaltyCardPrint {
    /**
     *
     * @type {ApiV1ConsumersPkConsumerCardsPrintData}
     * @memberof LoyaltyCardPrint
     */
    'data'?: ApiV1ConsumersPkConsumerCardsPrintData;
}
/**
 *
 * @export
 * @interface LoyaltyCardPrintResponse
 */
export interface LoyaltyCardPrintResponse {
    /**
     *
     * @type {LoyaltyCardPrintResponseData}
     * @memberof LoyaltyCardPrintResponse
     */
    'data'?: LoyaltyCardPrintResponseData;
}
/**
 *
 * @export
 * @interface LoyaltyCardPrintResponseData
 */
export interface LoyaltyCardPrintResponseData {
    /**
     * codice della card
     * @type {string}
     * @memberof LoyaltyCardPrintResponseData
     */
    'id'?: string;
    /**
     * gli attributi non sono ancora stati definiti
     * @type {object}
     * @memberof LoyaltyCardPrintResponseData
     */
    'attributes'?: object;
}
/**
 *
 * @export
 * @interface LoyaltyCreateEmailSend
 */
export interface LoyaltyCreateEmailSend {
    /**
     *
     * @type {ApiV1ConsumersPkConsumerCardsArchiveDocumentData}
     * @memberof LoyaltyCreateEmailSend
     */
    'data'?: ApiV1ConsumersPkConsumerCardsArchiveDocumentData;
}
/**
 *
 * @export
 * @interface LoyaltyEmailSend
 */
export interface LoyaltyEmailSend {
    /**
     *
     * @type {ApiV1ConsumersPkConsumerCardsSendEmailDocumentData}
     * @memberof LoyaltyEmailSend
     */
    'data'?: ApiV1ConsumersPkConsumerCardsSendEmailDocumentData;
}
/**
 *
 * @export
 * @interface LoyaltyEmailSend1
 */
export interface LoyaltyEmailSend1 {
    /**
     *
     * @type {ApiV1ConsumersPkConsumerCardsSendEmailDocumentData}
     * @memberof LoyaltyEmailSend1
     */
    'data'?: ApiV1ConsumersPkConsumerCardsSendEmailDocumentData;
}
/**
 *
 * @export
 * @interface LoyaltyEmailSendResponse
 */
export interface LoyaltyEmailSendResponse {
    /**
     * stato della mail
     * @type {string}
     * @memberof LoyaltyEmailSendResponse
     */
    'mail_status'?: string;
}
/**
 *
 * @export
 * @interface LoyaltyEmailSendResponse1
 */
export interface LoyaltyEmailSendResponse1 {
    /**
     * stato della mail
     * @type {string}
     * @memberof LoyaltyEmailSendResponse1
     */
    'mail_status'?: string;
    /**
     *
     * @type {boolean}
     * @memberof LoyaltyEmailSendResponse1
     */
    'document_emailed'?: boolean;
}
/**
 *
 * @export
 * @interface LoyaltyEmailSendResponse2
 */
export interface LoyaltyEmailSendResponse2 {
    /**
     *
     * @type {LoyaltyEmailSendResponse2Data}
     * @memberof LoyaltyEmailSendResponse2
     */
    'data'?: LoyaltyEmailSendResponse2Data;
}
/**
 *
 * @export
 * @interface LoyaltyEmailSendResponse2Data
 */
export interface LoyaltyEmailSendResponse2Data {
    /**
     * consumer_id
     * @type {number}
     * @memberof LoyaltyEmailSendResponse2Data
     */
    'id'?: number;
    /**
     *
     * @type {LoyaltyEmailSendResponse2DataAttributes}
     * @memberof LoyaltyEmailSendResponse2Data
     */
    'attributes'?: LoyaltyEmailSendResponse2DataAttributes;
}
/**
 *
 * @export
 * @interface LoyaltyEmailSendResponse2DataAttributes
 */
export interface LoyaltyEmailSendResponse2DataAttributes {
    /**
     *
     * @type {LoyaltyEmailSendResponse2DataAttributesDocumento}
     * @memberof LoyaltyEmailSendResponse2DataAttributes
     */
    'documento'?: LoyaltyEmailSendResponse2DataAttributesDocumento;
    /**
     *
     * @type {LoyaltyEmailSendResponse2DataAttributesBrowserDownload}
     * @memberof LoyaltyEmailSendResponse2DataAttributes
     */
    'browser_download'?: LoyaltyEmailSendResponse2DataAttributesBrowserDownload;
}
/**
 * informazioni relative allo scarico del file
 * @export
 * @interface LoyaltyEmailSendResponse2DataAttributesBrowserDownload
 */
export interface LoyaltyEmailSendResponse2DataAttributesBrowserDownload {
    /**
     * file in base64
     * @type {string}
     * @memberof LoyaltyEmailSendResponse2DataAttributesBrowserDownload
     */
    'pdf_content'?: string;
    /**
     * nome del file
     * @type {string}
     * @memberof LoyaltyEmailSendResponse2DataAttributesBrowserDownload
     */
    'file_name'?: string;
}
/**
 * informazioni relative all\'invio
 * @export
 * @interface LoyaltyEmailSendResponse2DataAttributesDocumento
 */
export interface LoyaltyEmailSendResponse2DataAttributesDocumento {
    /**
     * descrive lo stato della mail. Può essere send o queued
     * @type {string}
     * @memberof LoyaltyEmailSendResponse2DataAttributesDocumento
     */
    'mail_status'?: string;
    /**
     *
     * @type {boolean}
     * @memberof LoyaltyEmailSendResponse2DataAttributesDocumento
     */
    'document_archived'?: boolean;
}
/**
 *
 * @export
 * @interface LoyaltyPdfContactFormSign
 */
export interface LoyaltyPdfContactFormSign {
    /**
     * contenuto della firma in base64
     * @type {string}
     * @memberof LoyaltyPdfContactFormSign
     */
    'data'?: string;
}
/**
 *
 * @export
 * @interface ManageSaleReturnData
 */
export interface ManageSaleReturnData {
    /**
     *
     * @type {string}
     * @memberof ManageSaleReturnData
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof ManageSaleReturnData
     */
    'id'?: string;
    /**
     *
     * @type {ApiV1SalesDataAttributes}
     * @memberof ManageSaleReturnData
     */
    'attributes'?: ApiV1SalesDataAttributes;
}
/**
 *
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     *
     * @type {Array<ErrorErrors>}
     * @memberof ModelError
     */
    'errors'?: Array<ErrorErrors>;
}
/**
 *
 * @export
 * @interface ModificaSospeso
 */
export interface ModificaSospeso {
    /**
     *
     * @type {ModificaSospesoData}
     * @memberof ModificaSospeso
     */
    'data'?: ModificaSospesoData;
}
/**
 *
 * @export
 * @interface ModificaSospesoData
 */
export interface ModificaSospesoData {
    /**
     *
     * @type {string}
     * @memberof ModificaSospesoData
     */
    'type'?: string;
    /**
     *
     * @type {ModificaSospesoDataAttributes}
     * @memberof ModificaSospesoData
     */
    'attributes'?: ModificaSospesoDataAttributes;
}
/**
 *
 * @export
 * @interface ModificaSospesoDataAttributes
 */
export interface ModificaSospesoDataAttributes {
    /**
     *
     * @type {string}
     * @memberof ModificaSospesoDataAttributes
     */
    'message'?: string;
}
/**
 *
 * @export
 * @interface ModificaUtente
 */
export interface ModificaUtente {
    /**
     *
     * @type {ApiV1UtentiData}
     * @memberof ModificaUtente
     */
    'data'?: ApiV1UtentiData;
}
/**
 *
 * @export
 * @interface PatchConsumer
 */
export interface PatchConsumer {
    /**
     *
     * @type {ApiV1ConsumersPkConsumerData}
     * @memberof PatchConsumer
     */
    'data'?: ApiV1ConsumersPkConsumerData;
}
/**
 *
 * @export
 * @interface PatchConsumerResponse
 */
export interface PatchConsumerResponse {
    /**
     *
     * @type {PatchConsumerResponseData}
     * @memberof PatchConsumerResponse
     */
    'data'?: PatchConsumerResponseData;
}
/**
 *
 * @export
 * @interface PatchConsumerResponseData
 */
export interface PatchConsumerResponseData {
    /**
     * Unique consumer id
     * @type {string}
     * @memberof PatchConsumerResponseData
     */
    'id'?: string;
    /**
     * fixed data type \"consumers\"
     * @type {string}
     * @memberof PatchConsumerResponseData
     */
    'type'?: string;
    /**
     *
     * @type {PatchConsumerResponseDataAttributes}
     * @memberof PatchConsumerResponseData
     */
    'attributes'?: PatchConsumerResponseDataAttributes;
}
/**
 *
 * @export
 * @interface PatchConsumerResponseDataAttributes
 */
export interface PatchConsumerResponseDataAttributes {
    /**
     * anno_nascita
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'anno_nascita'?: string;
    /**
     * anno_nascita_presunto
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'anno_nascita_presunto'?: string;
    /**
     * cap
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'cap'?: string;
    /**
     * cellulare1
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'cellulare1'?: string;
    /**
     * cellulare2
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'cellulare2'?: string;
    /**
     * cod_scheda
     * @type {number}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'cod_scheda'?: number;
    /**
     * cognome
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'cognome'?: string;
    /**
     *
     * @type {Array<object>}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'indirizzo_alfabeto'?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'nome_alfabeto'?: Array<object>;
    /**
     * data_firma_scheda
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'data_firma_scheda'?: string;
    /**
     * data_modifica
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'data_modifica'?: string;
    /**
     * data_prima_registrazione
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'data_prima_registrazione'?: string;
    /**
     * data_ultimo_aggiornamento
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'data_ultimo_aggiornamento'?: string;
    /**
     * edificio
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'edificio'?: string;
    /**
     * email
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'email'?: string;
    /**
     * giorno_nascita
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'giorno_nascita'?: string;
    /**
     * grado_anonimato
     * @type {number}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'grado_anonimato'?: number;
    /**
     * indirizzo
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'indirizzo'?: string;
    /**
     * interessi
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'interessi'?: string;
    /**
     * lingua
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'lingua'?: string;
    /**
     * locale
     * @type {number}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'locale'?: number;
    /**
     * localita
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'localita'?: string;
    /**
     * mese_nascita
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'mese_nascita'?: string;
    /**
     * nazionalita
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'nazionalita'?: string;
    /**
     * nazione_iso
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'nazione_iso'?: string;
    /**
     * negozio_firma_scheda
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'negozio_firma_scheda'?: string;
    /**
     * nome
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'nome'?: string;
    /**
     * non_contattare
     * @type {number}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'non_contattare'?: number;
    /**
     * note
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'note'?: string;
    /**
     * note_indirizzo
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'note_indirizzo'?: string;
    /**
     * note_negozio
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'note_negozio'?: string;
    /**
     * numero_civico
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'numero_civico'?: string;
    /**
     * pk_consumer
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'pk_consumer'?: string;
    /**
     * pk_crm
     * @type {number}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'pk_crm'?: number;
    /**
     * preferenze_contatto
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'preferenze_contatto'?: string;
    /**
     * professioni
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'professioni'?: string;
    /**
     * provincia
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'provincia'?: string;
    /**
     * salutation
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'salutation'?: string;
    /**
     * sesso
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'sesso'?: string;
    /**
     * staff
     * @type {number}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'staff'?: number;
    /**
     * telefono1
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'telefono1'?: string;
    /**
     * telefono2
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'telefono2'?: string;
    /**
     * vip_1
     * @type {number}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'vip_1'?: number;
    /**
     * vip_2
     * @type {number}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'vip_2'?: number;
    /**
     * wechat
     * @type {string}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'wechat'?: string;
    /**
     * agreement_profiling
     * @type {number}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'agreement_profiling'?: number;
    /**
     * agreement_direct_marketing
     * @type {number}
     * @memberof PatchConsumerResponseDataAttributes
     */
    'agreement_direct_marketing'?: number;
}
/**
 *
 * @export
 * @interface PatchSale
 */
export interface PatchSale {
    /**
     *
     * @type {boolean}
     * @memberof PatchSale
     */
    'preserve_decimals'?: boolean;
    /**
     *
     * @type {ApiV1SalesSaleIdData}
     * @memberof PatchSale
     */
    'data'?: ApiV1SalesSaleIdData;
}
/**
 *
 * @export
 * @interface PatchSale1
 */
export interface PatchSale1 {
    /**
     *
     * @type {boolean}
     * @memberof PatchSale1
     */
    'preserve_decimals'?: boolean;
    /**
     *
     * @type {ApiV1SalesSaleIdData}
     * @memberof PatchSale1
     */
    'data'?: ApiV1SalesSaleIdData;
}
/**
 *
 * @export
 * @interface PatchSaleFidelity
 */
export interface PatchSaleFidelity {
    /**
     *
     * @type {boolean}
     * @memberof PatchSaleFidelity
     */
    'preserve_decimals'?: boolean;
    /**
     *
     * @type {ApiV1SalesSaleIdData}
     * @memberof PatchSaleFidelity
     */
    'data'?: ApiV1SalesSaleIdData;
}
/**
 *
 * @export
 * @interface PatchSession
 */
export interface PatchSession {
    /**
     *
     * @type {ApiV1SessionsSidData}
     * @memberof PatchSession
     */
    'data'?: ApiV1SessionsSidData;
}
/**
 *
 * @export
 * @interface PdfFile
 */
export interface PdfFile {
    /**
     *
     * @type {PdfFileData}
     * @memberof PdfFile
     */
    'data'?: PdfFileData;
}
/**
 *
 * @export
 * @interface PdfFile1
 */
export interface PdfFile1 {
    /**
     *
     * @type {PdfFile1Data}
     * @memberof PdfFile1
     */
    'data'?: PdfFile1Data;
}
/**
 * print invoice to pdf file
 * @export
 * @interface PdfFile1Data
 */
export interface PdfFile1Data {
    /**
     *
     * @type {string}
     * @memberof PdfFile1Data
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof PdfFile1Data
     */
    'type'?: string;
    /**
     *
     * @type {PdfFileDataAttributes}
     * @memberof PdfFile1Data
     */
    'attributes'?: PdfFileDataAttributes;
}
/**
 *
 * @export
 * @interface PdfFile2
 */
export interface PdfFile2 {
    /**
     *
     * @type {PdfFile2Data}
     * @memberof PdfFile2
     */
    'data'?: PdfFile2Data;
}
/**
 * bill in oss format pdf file
 * @export
 * @interface PdfFile2Data
 */
export interface PdfFile2Data {
    /**
     *
     * @type {string}
     * @memberof PdfFile2Data
     */
    'type'?: string;
    /**
     *
     * @type {PdfFileDataAttributes}
     * @memberof PdfFile2Data
     */
    'attributes'?: PdfFileDataAttributes;
}
/**
 *
 * @export
 * @interface PdfFile3
 */
export interface PdfFile3 {
    /**
     *
     * @type {PdfFile3Data}
     * @memberof PdfFile3
     */
    'data'?: PdfFile3Data;
}
/**
 * bill in oss format pdf file
 * @export
 * @interface PdfFile3Data
 */
export interface PdfFile3Data {
    /**
     *
     * @type {string}
     * @memberof PdfFile3Data
     */
    'type'?: string;
    /**
     *
     * @type {PdfFile3DataAttributes}
     * @memberof PdfFile3Data
     */
    'attributes'?: PdfFile3DataAttributes;
}
/**
 *
 * @export
 * @interface PdfFile3DataAttributes
 */
export interface PdfFile3DataAttributes {
    /**
     * pdf content in base64: base64.b64encode(data).decode(\'ascii\')
     * @type {string}
     * @memberof PdfFile3DataAttributes
     */
    'pdf_content'?: string;
    /**
     *
     * @type {string}
     * @memberof PdfFile3DataAttributes
     */
    'file_name'?: string;
}
/**
 * bill in oss format pdf file
 * @export
 * @interface PdfFileData
 */
export interface PdfFileData {
    /**
     *
     * @type {string}
     * @memberof PdfFileData
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof PdfFileData
     */
    'type'?: string;
    /**
     *
     * @type {PdfFileDataAttributes}
     * @memberof PdfFileData
     */
    'attributes'?: PdfFileDataAttributes;
}
/**
 *
 * @export
 * @interface PdfFileDataAttributes
 */
export interface PdfFileDataAttributes {
    /**
     * pdf content in base64: base64.b64encode(data).decode(\'ascii\')
     * @type {string}
     * @memberof PdfFileDataAttributes
     */
    'pdf_content'?: string;
    /**
     *
     * @type {string}
     * @memberof PdfFileDataAttributes
     */
    'file_name'?: string;
}
/**
 *
 * @export
 * @interface PettyCashDelete
 */
export interface PettyCashDelete {
    /**
     *
     * @type {PettyCashDeleteData}
     * @memberof PettyCashDelete
     */
    'data'?: PettyCashDeleteData;
}
/**
 *
 * @export
 * @interface PettyCashDeleteData
 */
export interface PettyCashDeleteData {
    /**
     *
     * @type {string}
     * @memberof PettyCashDeleteData
     */
    'type'?: string;
    /**
     *
     * @type {PettyCashDeleteDataAttributes}
     * @memberof PettyCashDeleteData
     */
    'attributes'?: PettyCashDeleteDataAttributes;
}
/**
 *
 * @export
 * @interface PettyCashDeleteDataAttributes
 */
export interface PettyCashDeleteDataAttributes {
    /**
     *
     * @type {number}
     * @memberof PettyCashDeleteDataAttributes
     */
    'numero_documento'?: number;
    /**
     *
     * @type {string}
     * @memberof PettyCashDeleteDataAttributes
     */
    'data_documento'?: string;
}
/**
 *
 * @export
 * @interface PettyCashFilter
 */
export interface PettyCashFilter {
    /**
     *
     * @type {PettyCashFilterData}
     * @memberof PettyCashFilter
     */
    'data'?: PettyCashFilterData;
}
/**
 *
 * @export
 * @interface PettyCashFilterData
 */
export interface PettyCashFilterData {
    /**
     *
     * @type {string}
     * @memberof PettyCashFilterData
     */
    'type'?: string;
    /**
     *
     * @type {PettyCashFilterDataAttributes}
     * @memberof PettyCashFilterData
     */
    'attributes'?: PettyCashFilterDataAttributes;
}
/**
 *
 * @export
 * @interface PettyCashFilterDataAttributes
 */
export interface PettyCashFilterDataAttributes {
    /**
     * si tratta del campo \"numero_documento_esterno\"
     * @type {number}
     * @memberof PettyCashFilterDataAttributes
     */
    'numero_documento'?: number;
    /**
     * si tratta del campo \"data_documento_esterna\"
     * @type {string}
     * @memberof PettyCashFilterDataAttributes
     */
    'data_documento_da'?: string;
    /**
     * si tratta del campo \"data_documento_esterna\"
     * @type {string}
     * @memberof PettyCashFilterDataAttributes
     */
    'data_documento_a'?: string;
    /**
     *
     * @type {string}
     * @memberof PettyCashFilterDataAttributes
     */
    'cod_cassiera'?: string;
    /**
     * si tratta del campo \"numero_documento\"
     * @type {number}
     * @memberof PettyCashFilterDataAttributes
     */
    'numero_petty_cash'?: number;
    /**
     * si tratta del campo \"data_documento\"
     * @type {string}
     * @memberof PettyCashFilterDataAttributes
     */
    'data_petty_cash_da'?: string;
    /**
     * si tratta del campo \"data_documento\"
     * @type {string}
     * @memberof PettyCashFilterDataAttributes
     */
    'data_petty_cash_a'?: string;
}
/**
 *
 * @export
 * @interface PettyCashItem
 */
export interface PettyCashItem {
    /**
     *
     * @type {PettyCashItemData}
     * @memberof PettyCashItem
     */
    'data'?: PettyCashItemData;
}
/**
 *
 * @export
 * @interface PettyCashItemData
 */
export interface PettyCashItemData {
    /**
     *
     * @type {string}
     * @memberof PettyCashItemData
     */
    'type'?: string;
    /**
     *
     * @type {PettyCashItemDataAttributes}
     * @memberof PettyCashItemData
     */
    'attributes'?: PettyCashItemDataAttributes;
}
/**
 *
 * @export
 * @interface PettyCashItemDataAttributes
 */
export interface PettyCashItemDataAttributes {
    /**
     *
     * @type {number}
     * @memberof PettyCashItemDataAttributes
     */
    'numero_documento'?: number;
    /**
     *
     * @type {string}
     * @memberof PettyCashItemDataAttributes
     */
    'data_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof PettyCashItemDataAttributes
     */
    'ora_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof PettyCashItemDataAttributes
     */
    'cod_cassiera'?: string;
    /**
     *
     * @type {string}
     * @memberof PettyCashItemDataAttributes
     */
    'nominativo'?: string;
    /**
     *
     * @type {string}
     * @memberof PettyCashItemDataAttributes
     */
    'data_doc_esterno'?: string;
    /**
     *
     * @type {string}
     * @memberof PettyCashItemDataAttributes
     */
    'num_doc_esterno'?: string;
    /**
     *
     * @type {string}
     * @memberof PettyCashItemDataAttributes
     */
    'id_tipo_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof PettyCashItemDataAttributes
     */
    'note'?: string;
    /**
     *
     * @type {number}
     * @memberof PettyCashItemDataAttributes
     */
    'importo'?: number;
    /**
     *
     * @type {number}
     * @memberof PettyCashItemDataAttributes
     */
    'importo_originale'?: number;
}
/**
 *
 * @export
 * @interface PettyCashItemDeletedResponse
 */
export interface PettyCashItemDeletedResponse {
    /**
     *
     * @type {PettyCashItemDeletedResponseData}
     * @memberof PettyCashItemDeletedResponse
     */
    'data'?: PettyCashItemDeletedResponseData;
}
/**
 *
 * @export
 * @interface PettyCashItemDeletedResponseData
 */
export interface PettyCashItemDeletedResponseData {
    /**
     *
     * @type {string}
     * @memberof PettyCashItemDeletedResponseData
     */
    'type'?: string;
    /**
     *
     * @type {boolean}
     * @memberof PettyCashItemDeletedResponseData
     */
    'deleted'?: boolean;
    /**
     *
     * @type {number}
     * @memberof PettyCashItemDeletedResponseData
     */
    'numero_documento'?: number;
}
/**
 *
 * @export
 * @interface PettyCashItemResponse
 */
export interface PettyCashItemResponse {
    /**
     *
     * @type {PettyCashItemResponseData}
     * @memberof PettyCashItemResponse
     */
    'data'?: PettyCashItemResponseData;
}
/**
 *
 * @export
 * @interface PettyCashItemResponseData
 */
export interface PettyCashItemResponseData {
    /**
     *
     * @type {string}
     * @memberof PettyCashItemResponseData
     */
    'type'?: string;
    /**
     *
     * @type {PettyCashItemResponseDataAttributes}
     * @memberof PettyCashItemResponseData
     */
    'attributes'?: PettyCashItemResponseDataAttributes;
}
/**
 *
 * @export
 * @interface PettyCashItemResponseDataAttributes
 */
export interface PettyCashItemResponseDataAttributes {
    /**
     *
     * @type {string}
     * @memberof PettyCashItemResponseDataAttributes
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {number}
     * @memberof PettyCashItemResponseDataAttributes
     */
    'numero_documento'?: number;
    /**
     *
     * @type {string}
     * @memberof PettyCashItemResponseDataAttributes
     */
    'data_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof PettyCashItemResponseDataAttributes
     */
    'ora_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof PettyCashItemResponseDataAttributes
     */
    'cod_cassiera'?: string;
    /**
     *
     * @type {string}
     * @memberof PettyCashItemResponseDataAttributes
     */
    'nominativo'?: string;
    /**
     *
     * @type {string}
     * @memberof PettyCashItemResponseDataAttributes
     */
    'data_doc_esterno'?: string;
    /**
     *
     * @type {string}
     * @memberof PettyCashItemResponseDataAttributes
     */
    'num_doc_esterno'?: string;
    /**
     *
     * @type {string}
     * @memberof PettyCashItemResponseDataAttributes
     */
    'note'?: string;
    /**
     *
     * @type {number}
     * @memberof PettyCashItemResponseDataAttributes
     */
    'importo'?: number;
    /**
     *
     * @type {number}
     * @memberof PettyCashItemResponseDataAttributes
     */
    'importo_originale'?: number;
    /**
     *
     * @type {string}
     * @memberof PettyCashItemResponseDataAttributes
     */
    'id_tipo_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof PettyCashItemResponseDataAttributes
     */
    'stato'?: string;
    /**
     *
     * @type {string}
     * @memberof PettyCashItemResponseDataAttributes
     */
    'data_modifica'?: string;
}
/**
 *
 * @export
 * @interface PettyCashList
 */
export interface PettyCashList {
    /**
     *
     * @type {Array<PettyCashListData>}
     * @memberof PettyCashList
     */
    'data'?: Array<PettyCashListData>;
}
/**
 *
 * @export
 * @interface PettyCashListAttributes
 */
export interface PettyCashListAttributes {
    /**
     *
     * @type {string}
     * @memberof PettyCashListAttributes
     */
    'codice'?: string;
    /**
     *
     * @type {string}
     * @memberof PettyCashListAttributes
     */
    'descrizione'?: string;
}
/**
 *
 * @export
 * @interface PettyCashListData
 */
export interface PettyCashListData {
    /**
     *
     * @type {string}
     * @memberof PettyCashListData
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof PettyCashListData
     */
    'id'?: string;
    /**
     *
     * @type {PettyCashListAttributes}
     * @memberof PettyCashListData
     */
    'attributes'?: PettyCashListAttributes;
}
/**
 *
 * @export
 * @interface PettyCashListResponse
 */
export interface PettyCashListResponse {
    /**
     *
     * @type {PettyCashListResponseData}
     * @memberof PettyCashListResponse
     */
    'data'?: PettyCashListResponseData;
}
/**
 *
 * @export
 * @interface PettyCashListResponseData
 */
export interface PettyCashListResponseData {
    /**
     *
     * @type {PettyCashListResponseDataAttributes}
     * @memberof PettyCashListResponseData
     */
    'attributes'?: PettyCashListResponseDataAttributes;
}
/**
 *
 * @export
 * @interface PettyCashListResponseDataAttributes
 */
export interface PettyCashListResponseDataAttributes {
    /**
     *
     * @type {Array<PettyCashListResponseDataAttributesPettycashData>}
     * @memberof PettyCashListResponseDataAttributes
     */
    'pettycash_data'?: Array<PettyCashListResponseDataAttributesPettycashData>;
    /**
     *
     * @type {PettyCashListResponseDataAttributesPettycashInfo}
     * @memberof PettyCashListResponseDataAttributes
     */
    'pettycash_info'?: PettyCashListResponseDataAttributesPettycashInfo;
}
/**
 *
 * @export
 * @interface PettyCashListResponseDataAttributesAttributes
 */
export interface PettyCashListResponseDataAttributesAttributes {
    /**
     *
     * @type {number}
     * @memberof PettyCashListResponseDataAttributesAttributes
     */
    'numero_documento'?: number;
    /**
     *
     * @type {string}
     * @memberof PettyCashListResponseDataAttributesAttributes
     */
    'cod_cassiera'?: string;
    /**
     *
     * @type {string}
     * @memberof PettyCashListResponseDataAttributesAttributes
     */
    'data_documento_esterno'?: string;
    /**
     *
     * @type {string}
     * @memberof PettyCashListResponseDataAttributesAttributes
     */
    'id_tipo_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof PettyCashListResponseDataAttributesAttributes
     */
    'nome_tipo_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof PettyCashListResponseDataAttributesAttributes
     */
    'nominativo'?: string;
    /**
     *
     * @type {string}
     * @memberof PettyCashListResponseDataAttributesAttributes
     */
    'data_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof PettyCashListResponseDataAttributesAttributes
     */
    'ora_documento'?: string;
    /**
     *
     * @type {number}
     * @memberof PettyCashListResponseDataAttributesAttributes
     */
    'importo'?: number;
    /**
     *
     * @type {string}
     * @memberof PettyCashListResponseDataAttributesAttributes
     */
    'stato'?: string;
    /**
     *
     * @type {string}
     * @memberof PettyCashListResponseDataAttributesAttributes
     */
    'note'?: string;
}
/**
 *
 * @export
 * @interface PettyCashListResponseDataAttributesPettycashData
 */
export interface PettyCashListResponseDataAttributesPettycashData {
    /**
     *
     * @type {string}
     * @memberof PettyCashListResponseDataAttributesPettycashData
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof PettyCashListResponseDataAttributesPettycashData
     */
    'id'?: string;
    /**
     *
     * @type {PettyCashListResponseDataAttributesAttributes}
     * @memberof PettyCashListResponseDataAttributesPettycashData
     */
    'attributes'?: PettyCashListResponseDataAttributesAttributes;
}
/**
 *
 * @export
 * @interface PettyCashListResponseDataAttributesPettycashInfo
 */
export interface PettyCashListResponseDataAttributesPettycashInfo {
    /**
     *
     * @type {string}
     * @memberof PettyCashListResponseDataAttributesPettycashInfo
     */
    'total'?: string;
    /**
     *
     * @type {number}
     * @memberof PettyCashListResponseDataAttributesPettycashInfo
     */
    'ammontare'?: number;
    /**
     *
     * @type {string}
     * @memberof PettyCashListResponseDataAttributesPettycashInfo
     */
    'valuta'?: string;
    /**
     *
     * @type {PettyCashListResponseDataAttributesPettycashInfoSaldoNegozio}
     * @memberof PettyCashListResponseDataAttributesPettycashInfo
     */
    'saldo_negozio'?: PettyCashListResponseDataAttributesPettycashInfoSaldoNegozio;
    /**
     *
     * @type {string}
     * @memberof PettyCashListResponseDataAttributesPettycashInfo
     */
    'warning_postale'?: string;
}
/**
 *
 * @export
 * @interface PettyCashListResponseDataAttributesPettycashInfoSaldoNegozio
 */
export interface PettyCashListResponseDataAttributesPettycashInfoSaldoNegozio {
    /**
     *
     * @type {PettyCashListResponseDataAttributesPettycashInfoSaldoNegozioCURRENCIES}
     * @memberof PettyCashListResponseDataAttributesPettycashInfoSaldoNegozio
     */
    'CURRENCIES'?: PettyCashListResponseDataAttributesPettycashInfoSaldoNegozioCURRENCIES;
    /**
     *
     * @type {number}
     * @memberof PettyCashListResponseDataAttributesPettycashInfoSaldoNegozio
     */
    'saldo_attuale'?: number;
    /**
     *
     * @type {number}
     * @memberof PettyCashListResponseDataAttributesPettycashInfoSaldoNegozio
     */
    'pagamenti_giorno'?: number;
}
/**
 *
 * @export
 * @interface PettyCashListResponseDataAttributesPettycashInfoSaldoNegozioCURRENCIES
 */
export interface PettyCashListResponseDataAttributesPettycashInfoSaldoNegozioCURRENCIES {
    /**
     *
     * @type {PettyCashListResponseDataAttributesPettycashInfoSaldoNegozioCURRENCIESEUR}
     * @memberof PettyCashListResponseDataAttributesPettycashInfoSaldoNegozioCURRENCIES
     */
    'EUR'?: PettyCashListResponseDataAttributesPettycashInfoSaldoNegozioCURRENCIESEUR;
}
/**
 *
 * @export
 * @interface PettyCashListResponseDataAttributesPettycashInfoSaldoNegozioCURRENCIESEUR
 */
export interface PettyCashListResponseDataAttributesPettycashInfoSaldoNegozioCURRENCIESEUR {
    /**
     *
     * @type {number}
     * @memberof PettyCashListResponseDataAttributesPettycashInfoSaldoNegozioCURRENCIESEUR
     */
    'saldo_attuale'?: number;
    /**
     *
     * @type {number}
     * @memberof PettyCashListResponseDataAttributesPettycashInfoSaldoNegozioCURRENCIESEUR
     */
    'pagamenti_giorno'?: number;
}
/**
 *
 * @export
 * @interface PickinRequestID
 */
export interface PickinRequestID {
    /**
     *
     * @type {PickinRequestIDData}
     * @memberof PickinRequestID
     */
    'data'?: PickinRequestIDData;
}
/**
 *
 * @export
 * @interface PickinRequestIDData
 */
export interface PickinRequestIDData {
    /**
     *
     * @type {string}
     * @memberof PickinRequestIDData
     */
    'type'?: string;
    /**
     *
     * @type {PickinRequestIDDataAttributes}
     * @memberof PickinRequestIDData
     */
    'attributes'?: PickinRequestIDDataAttributes;
}
/**
 *
 * @export
 * @interface PickinRequestIDDataAttributes
 */
export interface PickinRequestIDDataAttributes {
    /**
     *
     * @type {number}
     * @memberof PickinRequestIDDataAttributes
     */
    'requestId'?: number;
}
/**
 *
 * @export
 * @interface PortaleDeleteSession
 */
export interface PortaleDeleteSession {
    /**
     *
     * @type {ApiV1SessionsPortalSidData}
     * @memberof PortaleDeleteSession
     */
    'data'?: ApiV1SessionsPortalSidData;
}
/**
 *
 * @export
 * @interface PortalePatchSession
 */
export interface PortalePatchSession {
    /**
     *
     * @type {ApiV1SessionsPortalSidData1}
     * @memberof PortalePatchSession
     */
    'data'?: ApiV1SessionsPortalSidData1;
}
/**
 *
 * @export
 * @interface PortaleSession
 */
export interface PortaleSession {
    /**
     *
     * @type {PortaleSessionData}
     * @memberof PortaleSession
     */
    'data'?: PortaleSessionData;
}
/**
 *
 * @export
 * @interface PortaleSessionData
 */
export interface PortaleSessionData {
    /**
     *
     * @type {string}
     * @memberof PortaleSessionData
     */
    'type'?: string;
    /**
     * Session unique id
     * @type {string}
     * @memberof PortaleSessionData
     */
    'id'?: string;
    /**
     *
     * @type {PortaleSessionDataAttributes}
     * @memberof PortaleSessionData
     */
    'attributes'?: PortaleSessionDataAttributes;
}
/**
 *
 * @export
 * @interface PortaleSessionDataAttributes
 */
export interface PortaleSessionDataAttributes {
    /**
     * User selected language, defaults on the store language
     * @type {string}
     * @memberof PortaleSessionDataAttributes
     */
    'language'?: string;
    /**
     * ISO code of the language field
     * @type {string}
     * @memberof PortaleSessionDataAttributes
     */
    'language_iso'?: string;
    /**
     *
     * @type {string}
     * @memberof PortaleSessionDataAttributes
     */
    'username'?: string;
    /**
     * List of avaiable store codes
     * @type {Array<any>}
     * @memberof PortaleSessionDataAttributes
     */
    'stores'?: Array<any>;
}
/**
 *
 * @export
 * @interface PostB2ERefund
 */
export interface PostB2ERefund {
    /**
     *
     * @type {ApiV1B2eRefundsData}
     * @memberof PostB2ERefund
     */
    'data'?: ApiV1B2eRefundsData;
}
/**
 *
 * @export
 * @interface PostConsumer
 */
export interface PostConsumer {
    /**
     *
     * @type {ApiV1ConsumersData}
     * @memberof PostConsumer
     */
    'data'?: ApiV1ConsumersData;
}
/**
 *
 * @export
 * @interface PostConsumerResponse
 */
export interface PostConsumerResponse {
    /**
     *
     * @type {PostConsumerResponseData}
     * @memberof PostConsumerResponse
     */
    'data'?: PostConsumerResponseData;
}
/**
 *
 * @export
 * @interface PostConsumerResponseData
 */
export interface PostConsumerResponseData {
    /**
     * Unique consumer id
     * @type {string}
     * @memberof PostConsumerResponseData
     */
    'id'?: string;
    /**
     * fixed data type \"consumers\"
     * @type {string}
     * @memberof PostConsumerResponseData
     */
    'type'?: string;
    /**
     *
     * @type {PostConsumerResponseDataAttributes}
     * @memberof PostConsumerResponseData
     */
    'attributes'?: PostConsumerResponseDataAttributes;
}
/**
 *
 * @export
 * @interface PostConsumerResponseDataAttributes
 */
export interface PostConsumerResponseDataAttributes {
    /**
     * anno_nascita
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'anno_nascita'?: string;
    /**
     * anno_nascita_presunto
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'anno_nascita_presunto'?: string;
    /**
     * cap
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'cap'?: string;
    /**
     * cellulare1
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'cellulare1'?: string;
    /**
     * cellulare2
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'cellulare2'?: string;
    /**
     * cod_scheda
     * @type {number}
     * @memberof PostConsumerResponseDataAttributes
     */
    'cod_scheda'?: number;
    /**
     * cognome
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'cognome'?: string;
    /**
     * data_firma_scheda
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'data_firma_scheda'?: string;
    /**
     * data_modifica
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'data_modifica'?: string;
    /**
     * data_prima_registrazione
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'data_prima_registrazione'?: string;
    /**
     * data_ultimo_aggiornamento
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'data_ultimo_aggiornamento'?: string;
    /**
     * edificio
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'edificio'?: string;
    /**
     * email
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'email'?: string;
    /**
     *
     * @type {Array<object>}
     * @memberof PostConsumerResponseDataAttributes
     */
    'indirizzo_alfabeto'?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof PostConsumerResponseDataAttributes
     */
    'nome_alfabeto'?: Array<object>;
    /**
     * giorno_nascita
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'giorno_nascita'?: string;
    /**
     * grado_anonimato
     * @type {number}
     * @memberof PostConsumerResponseDataAttributes
     */
    'grado_anonimato'?: number;
    /**
     * indirizzo
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'indirizzo'?: string;
    /**
     * interessi
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'interessi'?: string;
    /**
     * lingua
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'lingua'?: string;
    /**
     * locale
     * @type {number}
     * @memberof PostConsumerResponseDataAttributes
     */
    'locale'?: number;
    /**
     * localita
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'localita'?: string;
    /**
     * mese_nascita
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'mese_nascita'?: string;
    /**
     * nazionalita
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'nazionalita'?: string;
    /**
     * nazione_iso
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'nazione_iso'?: string;
    /**
     * negozio_firma_scheda
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'negozio_firma_scheda'?: string;
    /**
     * nome
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'nome'?: string;
    /**
     * non_contattare
     * @type {number}
     * @memberof PostConsumerResponseDataAttributes
     */
    'non_contattare'?: number;
    /**
     * note
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'note'?: string;
    /**
     * note_indirizzo
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'note_indirizzo'?: string;
    /**
     * note_negozio
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'note_negozio'?: string;
    /**
     * numero_civico
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'numero_civico'?: string;
    /**
     * pk_consumer
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'pk_consumer'?: string;
    /**
     * pk_crm
     * @type {number}
     * @memberof PostConsumerResponseDataAttributes
     */
    'pk_crm'?: number;
    /**
     * preferenze_contatto
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'preferenze_contatto'?: string;
    /**
     * professioni
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'professioni'?: string;
    /**
     * provincia
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'provincia'?: string;
    /**
     * salutation
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'salutation'?: string;
    /**
     * sesso
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'sesso'?: string;
    /**
     * staff
     * @type {number}
     * @memberof PostConsumerResponseDataAttributes
     */
    'staff'?: number;
    /**
     * telefono1
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'telefono1'?: string;
    /**
     * telefono2
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'telefono2'?: string;
    /**
     * vip_1
     * @type {number}
     * @memberof PostConsumerResponseDataAttributes
     */
    'vip_1'?: number;
    /**
     * vip_2
     * @type {number}
     * @memberof PostConsumerResponseDataAttributes
     */
    'vip_2'?: number;
    /**
     * wechat
     * @type {string}
     * @memberof PostConsumerResponseDataAttributes
     */
    'wechat'?: string;
    /**
     * agreement_profiling
     * @type {number}
     * @memberof PostConsumerResponseDataAttributes
     */
    'agreement_profiling'?: number;
    /**
     * agreement_direct_marketing
     * @type {number}
     * @memberof PostConsumerResponseDataAttributes
     */
    'agreement_direct_marketing'?: number;
}
/**
 *
 * @export
 * @interface PostCoupon
 */
export interface PostCoupon {
    /**
     *
     * @type {ApiV1CouponsCouponCodeCheckData}
     * @memberof PostCoupon
     */
    'data'?: ApiV1CouponsCouponCodeCheckData;
}
/**
 *
 * @export
 * @interface PostCoupon1
 */
export interface PostCoupon1 {
    /**
     *
     * @type {ApiV1CouponsCouponCodeCheckData}
     * @memberof PostCoupon1
     */
    'data'?: ApiV1CouponsCouponCodeCheckData;
}
/**
 *
 * @export
 * @interface PostPettyCash
 */
export interface PostPettyCash {
    /**
     *
     * @type {ApiV1ReintegriData}
     * @memberof PostPettyCash
     */
    'data'?: ApiV1ReintegriData;
}
/**
 *
 * @export
 * @interface PostSale
 */
export interface PostSale {
    /**
     *
     * @type {boolean}
     * @memberof PostSale
     */
    'preserve_decimals'?: boolean;
    /**
     *
     * @type {ApiV1SalesData1}
     * @memberof PostSale
     */
    'data'?: ApiV1SalesData1;
}
/**
 *
 * @export
 * @interface PostSalesEnabledDocumentTypes
 */
export interface PostSalesEnabledDocumentTypes {
    /**
     *
     * @type {ApiV1PoswebDocumentTypesActiveData}
     * @memberof PostSalesEnabledDocumentTypes
     */
    'data'?: ApiV1PoswebDocumentTypesActiveData;
}
/**
 *
 * @export
 * @interface PostSession
 */
export interface PostSession {
    /**
     *
     * @type {ApiV1SessionsData}
     * @memberof PostSession
     */
    'data'?: ApiV1SessionsData;
}
/**
 *
 * @export
 * @interface PostStampaElenco
 */
export interface PostStampaElenco {
    /**
     *
     * @type {ApiV1AggiornamentoprezziStampaElencoData}
     * @memberof PostStampaElenco
     */
    'data'?: ApiV1AggiornamentoprezziStampaElencoData;
}
/**
 *
 * @export
 * @interface PostStampaEtichette
 */
export interface PostStampaEtichette {
    /**
     *
     * @type {ApiV1AggiornamentoprezziStampaEtichetteData}
     * @memberof PostStampaEtichette
     */
    'data'?: ApiV1AggiornamentoprezziStampaEtichetteData;
}
/**
 *
 * @export
 * @interface PostStampaVersamenti
 */
export interface PostStampaVersamenti {
    /**
     *
     * @type {ApiV1StampaVersamentoData}
     * @memberof PostStampaVersamenti
     */
    'data'?: ApiV1StampaVersamentoData;
}
/**
 *
 * @export
 * @interface PostStampaVersamentiResponse
 */
export interface PostStampaVersamentiResponse {
    /**
     *
     * @type {PostStampaVersamentiResponseData}
     * @memberof PostStampaVersamentiResponse
     */
    'data'?: PostStampaVersamentiResponseData;
}
/**
 *
 * @export
 * @interface PostStampaVersamentiResponseData
 */
export interface PostStampaVersamentiResponseData {
    /**
     *
     * @type {PostStampaVersamentiResponseDataData}
     * @memberof PostStampaVersamentiResponseData
     */
    'data'?: PostStampaVersamentiResponseDataData;
}
/**
 *
 * @export
 * @interface PostStampaVersamentiResponseDataData
 */
export interface PostStampaVersamentiResponseDataData {
    /**
     * fixed data type \"versamento\"
     * @type {string}
     * @memberof PostStampaVersamentiResponseDataData
     */
    'type'?: string;
    /**
     *
     * @type {PostStampaVersamentiResponseDataDataAttributes}
     * @memberof PostStampaVersamentiResponseDataData
     */
    'attributes'?: PostStampaVersamentiResponseDataDataAttributes;
}
/**
 *
 * @export
 * @interface PostStampaVersamentiResponseDataDataAttributes
 */
export interface PostStampaVersamentiResponseDataDataAttributes {
    /**
     *
     * @type {string}
     * @memberof PostStampaVersamentiResponseDataDataAttributes
     */
    'status'?: string;
    /**
     *
     * @type {string}
     * @memberof PostStampaVersamentiResponseDataDataAttributes
     */
    'message'?: string;
    /**
     *
     * @type {string}
     * @memberof PostStampaVersamentiResponseDataDataAttributes
     */
    'pdf_link'?: string;
}
/**
 *
 * @export
 * @interface PostVersamenti
 */
export interface PostVersamenti {
    /**
     *
     * @type {Array<ApiV1VersamentiData>}
     * @memberof PostVersamenti
     */
    'data'?: Array<ApiV1VersamentiData>;
}
/**
 *
 * @export
 * @interface PostVersamentiResponse
 */
export interface PostVersamentiResponse {
    /**
     *
     * @type {Array<PostVersamentiResponseData>}
     * @memberof PostVersamentiResponse
     */
    'data'?: Array<PostVersamentiResponseData>;
}
/**
 *
 * @export
 * @interface PostVersamentiResponseAttributes
 */
export interface PostVersamentiResponseAttributes {
    /**
     *
     * @type {string}
     * @memberof PostVersamentiResponseAttributes
     */
    'cassiera'?: string;
    /**
     *
     * @type {string}
     * @memberof PostVersamentiResponseAttributes
     */
    'banca'?: string;
    /**
     *
     * @type {string}
     * @memberof PostVersamentiResponseAttributes
     */
    'data_versamento'?: string;
    /**
     *
     * @type {number}
     * @memberof PostVersamentiResponseAttributes
     */
    'saldo_negozio'?: number;
    /**
     *
     * @type {number}
     * @memberof PostVersamentiResponseAttributes
     */
    'totale_versato'?: number;
    /**
     *
     * @type {number}
     * @memberof PostVersamentiResponseAttributes
     */
    'residuo'?: number;
    /**
     *
     * @type {number}
     * @memberof PostVersamentiResponseAttributes
     */
    'importo'?: number;
    /**
     *
     * @type {string}
     * @memberof PostVersamentiResponseAttributes
     */
    'tipo_versamento'?: string;
    /**
     *
     * @type {string}
     * @memberof PostVersamentiResponseAttributes
     */
    'data_valuta'?: string;
}
/**
 *
 * @export
 * @interface PostVersamentiResponseData
 */
export interface PostVersamentiResponseData {
    /**
     * Unique bank deposit id. SHOULD BE NULL in POST
     * @type {string}
     * @memberof PostVersamentiResponseData
     */
    'id'?: string;
    /**
     * fixed data type \"versamento\"
     * @type {string}
     * @memberof PostVersamentiResponseData
     */
    'type'?: string;
    /**
     *
     * @type {PostVersamentiResponseAttributes}
     * @memberof PostVersamentiResponseData
     */
    'attributes'?: PostVersamentiResponseAttributes;
}
/**
 *
 * @export
 * @interface PrintRetry
 */
export interface PrintRetry {
    /**
     *
     * @type {ApiV1SalesPrintRetryData}
     * @memberof PrintRetry
     */
    'data'?: ApiV1SalesPrintRetryData;
}
/**
 *
 * @export
 * @interface PrintRetry1
 */
export interface PrintRetry1 {
    /**
     *
     * @type {PrintRetry1Data}
     * @memberof PrintRetry1
     */
    'data'?: PrintRetry1Data;
}
/**
 *
 * @export
 * @interface PrintRetry1Data
 */
export interface PrintRetry1Data {
    /**
     *
     * @type {string}
     * @memberof PrintRetry1Data
     */
    'type'?: string;
    /**
     *
     * @type {PrintRetry1DataAttributes}
     * @memberof PrintRetry1Data
     */
    'attributes'?: PrintRetry1DataAttributes;
}
/**
 *
 * @export
 * @interface PrintRetry1DataAttributes
 */
export interface PrintRetry1DataAttributes {
    /**
     * operation status
     * @type {string}
     * @memberof PrintRetry1DataAttributes
     */
    'status'?: string;
    /**
     *
     * @type {string}
     * @memberof PrintRetry1DataAttributes
     */
    'id_transazione'?: string;
    /**
     *
     * @type {string}
     * @memberof PrintRetry1DataAttributes
     */
    'message'?: string;
    /**
     *
     * @type {string}
     * @memberof PrintRetry1DataAttributes
     */
    'print_error_result'?: string;
    /**
     *
     * @type {Array<Array<string>>}
     * @memberof PrintRetry1DataAttributes
     */
    'prints_to_retry'?: Array<Array<string>>;
    /**
     *
     * @type {Array<PrintRetry1DataAttributesBrowserDownload>}
     * @memberof PrintRetry1DataAttributes
     */
    'browser_download'?: Array<PrintRetry1DataAttributesBrowserDownload>;
}
/**
 *
 * @export
 * @interface PrintRetry1DataAttributesBrowserDownload
 */
export interface PrintRetry1DataAttributesBrowserDownload {
    /**
     *
     * @type {string}
     * @memberof PrintRetry1DataAttributesBrowserDownload
     */
    'pdf_content'?: string;
    /**
     *
     * @type {string}
     * @memberof PrintRetry1DataAttributesBrowserDownload
     */
    'file_name'?: string;
}
/**
 *
 * @export
 * @interface PromoEngineApplyRequest
 */
export interface PromoEngineApplyRequest {
    /**
     *
     * @type {PromoEngineApplyRequestData}
     * @memberof PromoEngineApplyRequest
     */
    'data'?: PromoEngineApplyRequestData;
}
/**
 *
 * @export
 * @interface PromoEngineApplyRequestData
 */
export interface PromoEngineApplyRequestData {
    /**
     *
     * @type {PromoEngineApplyRequestDataAttributes}
     * @memberof PromoEngineApplyRequestData
     */
    'attributes'?: PromoEngineApplyRequestDataAttributes;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestData
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestData
     */
    'id'?: string;
}
/**
 *
 * @export
 * @interface PromoEngineApplyRequestDataAttributes
 */
export interface PromoEngineApplyRequestDataAttributes {
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'cod_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'codice_movimento'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'data_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'tipo_applicazione_apertura'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'data_modifica'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'dati_aggiuntivi'?: string;
    /**
     *
     * @type {number}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'importo_finale'?: number;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'dati_documenti'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'codice_stato'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'id_utente'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'ora_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'data_creazione'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'nota'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'tipo_applicazione_chiusura'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'sid'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'divisa'?: string;
    /**
     *
     * @type {number}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'importo_pagato'?: number;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'pk_consumer'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'id_postazione_apertura'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'cod_vettore'?: string;
    /**
     *
     * @type {Array<any>}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'pagamenti'?: Array<any>;
    /**
     *
     * @type {number}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'numero_documento'?: number;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'numero_stampa_documento'?: string;
    /**
     *
     * @type {Array<PromoEngineApplyRequestDataAttributesCapi>}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'capi'?: Array<PromoEngineApplyRequestDataAttributesCapi>;
    /**
     *
     * @type {number}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'flag_stampa_documento'?: number;
    /**
     *
     * @type {number}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'importo_iniziale'?: number;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'id_postazione_chiusura'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'cod_cassiera'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {number}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'id_transazione'?: number;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributes
     */
    'cod_cassa'?: string;
}
/**
 *
 * @export
 * @interface PromoEngineApplyRequestDataAttributesCapi
 */
export interface PromoEngineApplyRequestDataAttributesCapi {
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'codice_movimento'?: string;
    /**
     *
     * @type {number}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'iva'?: number;
    /**
     *
     * @type {number}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'progressivo'?: number;
    /**
     *
     * @type {number}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'flag_divisa'?: number;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'cod_commessa'?: string;
    /**
     *
     * @type {number}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'correzione_importo'?: number;
    /**
     *
     * @type {number}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'sku_created'?: number;
    /**
     *
     * @type {number}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'importo_finale'?: number;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'codice_stato'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'sku'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'nome'?: string;
    /**
     *
     * @type {number}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'flag_promo'?: number;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'data_creazione'?: string;
    /**
     *
     * @type {number}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'sconto'?: number;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'composizione'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'nota'?: string;
    /**
     *
     * @type {number}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'importo_custom'?: number;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'tipologia_merce'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'ean'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'data_modifica'?: string;
    /**
     *
     * @type {number}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'sku_splitted'?: number;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'tipo_importo'?: string;
    /**
     *
     * @type {number}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'importo_iniziale'?: number;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'classe'?: string;
    /**
     *
     * @type {number}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'id_transazione'?: number;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'sku_read'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEngineApplyRequestDataAttributesCapi
     */
    'custom_data'?: string;
}
/**
 *
 * @export
 * @interface PromoEngineApplyResponse
 */
export interface PromoEngineApplyResponse {
    /**
     *
     * @type {PromoEngineApplyRequestData}
     * @memberof PromoEngineApplyResponse
     */
    'data'?: PromoEngineApplyRequestData;
}
/**
 *
 * @export
 * @interface PromoEnginePreviewResponse
 */
export interface PromoEnginePreviewResponse {
    /**
     *
     * @type {PromoEnginePreviewResponseData}
     * @memberof PromoEnginePreviewResponse
     */
    'data'?: PromoEnginePreviewResponseData;
}
/**
 *
 * @export
 * @interface PromoEnginePreviewResponseData
 */
export interface PromoEnginePreviewResponseData {
    /**
     *
     * @type {string}
     * @memberof PromoEnginePreviewResponseData
     */
    'type'?: string;
    /**
     *
     * @type {PromoEnginePreviewResponseDataAttributes}
     * @memberof PromoEnginePreviewResponseData
     */
    'attributes'?: PromoEnginePreviewResponseDataAttributes;
}
/**
 *
 * @export
 * @interface PromoEnginePreviewResponseDataAttributes
 */
export interface PromoEnginePreviewResponseDataAttributes {
    /**
     *
     * @type {Array<PromoEnginePreviewResponseDataAttributesItems>}
     * @memberof PromoEnginePreviewResponseDataAttributes
     */
    'items'?: Array<PromoEnginePreviewResponseDataAttributesItems>;
    /**
     *
     * @type {Array<PromoEnginePreviewResponseDataAttributesCosts>}
     * @memberof PromoEnginePreviewResponseDataAttributes
     */
    'costs'?: Array<PromoEnginePreviewResponseDataAttributesCosts>;
    /**
     *
     * @type {Array<string>}
     * @memberof PromoEnginePreviewResponseDataAttributes
     */
    'vouchers'?: Array<string>;
    /**
     *
     * @type {Array<PromoEnginePreviewResponseDataAttributesPayments>}
     * @memberof PromoEnginePreviewResponseDataAttributes
     */
    'payments'?: Array<PromoEnginePreviewResponseDataAttributesPayments>;
    /**
     *
     * @type {PromoEnginePreviewResponseDataAttributesPromo}
     * @memberof PromoEnginePreviewResponseDataAttributes
     */
    'promo'?: PromoEnginePreviewResponseDataAttributesPromo;
    /**
     *
     * @type {Array<PromoEnginePreviewResponseDataAttributesExtra>}
     * @memberof PromoEnginePreviewResponseDataAttributes
     */
    'extra'?: Array<PromoEnginePreviewResponseDataAttributesExtra>;
}
/**
 *
 * @export
 * @interface PromoEnginePreviewResponseDataAttributesCosts
 */
export interface PromoEnginePreviewResponseDataAttributesCosts {
    /**
     *
     * @type {number}
     * @memberof PromoEnginePreviewResponseDataAttributesCosts
     */
    'id'?: number;
    /**
     *
     * @type {string}
     * @memberof PromoEnginePreviewResponseDataAttributesCosts
     */
    'cost_type'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEnginePreviewResponseDataAttributesCosts
     */
    'cost_type_trad'?: string;
    /**
     *
     * @type {number}
     * @memberof PromoEnginePreviewResponseDataAttributesCosts
     */
    'prev_price'?: number;
    /**
     *
     * @type {number}
     * @memberof PromoEnginePreviewResponseDataAttributesCosts
     */
    'final_price'?: number;
    /**
     *
     * @type {boolean}
     * @memberof PromoEnginePreviewResponseDataAttributesCosts
     */
    'modified'?: boolean;
    /**
     *
     * @type {Array<string>}
     * @memberof PromoEnginePreviewResponseDataAttributesCosts
     */
    'promo_engine'?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof PromoEnginePreviewResponseDataAttributesCosts
     */
    'net_final_price'?: number;
    /**
     *
     * @type {number}
     * @memberof PromoEnginePreviewResponseDataAttributesCosts
     */
    'discount_final_price'?: number;
}
/**
 *
 * @export
 * @interface PromoEnginePreviewResponseDataAttributesExtra
 */
export interface PromoEnginePreviewResponseDataAttributesExtra {
    /**
     *
     * @type {number}
     * @memberof PromoEnginePreviewResponseDataAttributesExtra
     */
    'CONTABILITA_PROMOZIONE'?: number;
}
/**
 *
 * @export
 * @interface PromoEnginePreviewResponseDataAttributesItems
 */
export interface PromoEnginePreviewResponseDataAttributesItems {
    /**
     *
     * @type {number}
     * @memberof PromoEnginePreviewResponseDataAttributesItems
     */
    'id'?: number;
    /**
     *
     * @type {string}
     * @memberof PromoEnginePreviewResponseDataAttributesItems
     */
    'sku'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEnginePreviewResponseDataAttributesItems
     */
    'name'?: string;
    /**
     *
     * @type {number}
     * @memberof PromoEnginePreviewResponseDataAttributesItems
     */
    'discount_on_item'?: number;
    /**
     *
     * @type {number}
     * @memberof PromoEnginePreviewResponseDataAttributesItems
     */
    'prev_price'?: number;
    /**
     *
     * @type {number}
     * @memberof PromoEnginePreviewResponseDataAttributesItems
     */
    'final_price'?: number;
    /**
     *
     * @type {boolean}
     * @memberof PromoEnginePreviewResponseDataAttributesItems
     */
    'modified'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof PromoEnginePreviewResponseDataAttributesItems
     */
    'added'?: boolean;
    /**
     *
     * @type {Array<string>}
     * @memberof PromoEnginePreviewResponseDataAttributesItems
     */
    'promo_engine'?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof PromoEnginePreviewResponseDataAttributesItems
     */
    'net_final_price'?: number;
    /**
     *
     * @type {number}
     * @memberof PromoEnginePreviewResponseDataAttributesItems
     */
    'discount_final_price'?: number;
}
/**
 *
 * @export
 * @interface PromoEnginePreviewResponseDataAttributesPayments
 */
export interface PromoEnginePreviewResponseDataAttributesPayments {
    /**
     *
     * @type {string}
     * @memberof PromoEnginePreviewResponseDataAttributesPayments
     */
    'payment_code'?: string;
    /**
     *
     * @type {number}
     * @memberof PromoEnginePreviewResponseDataAttributesPayments
     */
    'amount'?: number;
}
/**
 *
 * @export
 * @interface PromoEnginePreviewResponseDataAttributesPromo
 */
export interface PromoEnginePreviewResponseDataAttributesPromo {
    /**
     *
     * @type {PromoEnginePreviewResponseDataAttributesPromoPromo161}
     * @memberof PromoEnginePreviewResponseDataAttributesPromo
     */
    'promo_161'?: PromoEnginePreviewResponseDataAttributesPromoPromo161;
}
/**
 *
 * @export
 * @interface PromoEnginePreviewResponseDataAttributesPromoPromo161
 */
export interface PromoEnginePreviewResponseDataAttributesPromoPromo161 {
    /**
     *
     * @type {Array<PromoEnginePreviewResponseDataAttributesPromoPromo161Items>}
     * @memberof PromoEnginePreviewResponseDataAttributesPromoPromo161
     */
    'items'?: Array<PromoEnginePreviewResponseDataAttributesPromoPromo161Items>;
    /**
     *
     * @type {Array<any>}
     * @memberof PromoEnginePreviewResponseDataAttributesPromoPromo161
     */
    'costs'?: Array<any>;
    /**
     *
     * @type {string}
     * @memberof PromoEnginePreviewResponseDataAttributesPromoPromo161
     */
    'title'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEnginePreviewResponseDataAttributesPromoPromo161
     */
    'description'?: string;
    /**
     *
     * @type {string}
     * @memberof PromoEnginePreviewResponseDataAttributesPromoPromo161
     */
    'id_promo'?: string;
}
/**
 *
 * @export
 * @interface PromoEnginePreviewResponseDataAttributesPromoPromo161Items
 */
export interface PromoEnginePreviewResponseDataAttributesPromoPromo161Items {
    /**
     *
     * @type {number}
     * @memberof PromoEnginePreviewResponseDataAttributesPromoPromo161Items
     */
    'id'?: number;
    /**
     *
     * @type {number}
     * @memberof PromoEnginePreviewResponseDataAttributesPromoPromo161Items
     */
    'amount'?: number;
}
/**
 *
 * @export
 * @interface QueryParam
 */
export interface QueryParam {
    /**
     *
     * @type {ApiV1SospesiRicercaConsumatriciData}
     * @memberof QueryParam
     */
    'data'?: ApiV1SospesiRicercaConsumatriciData;
}
/**
 *
 * @export
 * @interface RequestCashRegisterClosing
 */
export interface RequestCashRegisterClosing {
    /**
     *
     * @type {ApiV1ChiusuracassaStampaChiusuraRegistratoreData}
     * @memberof RequestCashRegisterClosing
     */
    'data'?: ApiV1ChiusuracassaStampaChiusuraRegistratoreData;
}
/**
 *
 * @export
 * @interface RequestCashRegisterClosing1
 */
export interface RequestCashRegisterClosing1 {
    /**
     *
     * @type {ApiV1ChiusuracassaConfermaChiusuraData}
     * @memberof RequestCashRegisterClosing1
     */
    'data'?: ApiV1ChiusuracassaConfermaChiusuraData;
}
/**
 *
 * @export
 * @interface RequestDailyTotals
 */
export interface RequestDailyTotals {
    /**
     *
     * @type {ApiV1ChiusuracassaStampaRiepilogoGiornalieroData}
     * @memberof RequestDailyTotals
     */
    'data'?: ApiV1ChiusuracassaStampaRiepilogoGiornalieroData;
}
/**
 *
 * @export
 * @interface RequestGiftCardReport
 */
export interface RequestGiftCardReport {
    /**
     *
     * @type {ApiV1ChiusuracassaStampaResocontoGiftCardData}
     * @memberof RequestGiftCardReport
     */
    'data'?: ApiV1ChiusuracassaStampaResocontoGiftCardData;
}
/**
 *
 * @export
 * @interface RequestReditCardSummary
 */
export interface RequestReditCardSummary {
    /**
     *
     * @type {ApiV1ChiusuracassaStampaResocontoCartaData}
     * @memberof RequestReditCardSummary
     */
    'data'?: ApiV1ChiusuracassaStampaResocontoCartaData;
}
/**
 *
 * @export
 * @interface ResponseDashboardStatoNegozio
 */
export interface ResponseDashboardStatoNegozio {
    /**
     *
     * @type {ResponseDashboardStatoNegozioData}
     * @memberof ResponseDashboardStatoNegozio
     */
    'data'?: ResponseDashboardStatoNegozioData;
}
/**
 *
 * @export
 * @interface ResponseDashboardStatoNegozioData
 */
export interface ResponseDashboardStatoNegozioData {
    /**
     *
     * @type {string}
     * @memberof ResponseDashboardStatoNegozioData
     */
    'type'?: string;
    /**
     *
     * @type {Array<ResponseDashboardStatoNegozioDataAttributes>}
     * @memberof ResponseDashboardStatoNegozioData
     */
    'attributes'?: Array<ResponseDashboardStatoNegozioDataAttributes>;
}
/**
 *
 * @export
 * @interface ResponseDashboardStatoNegozioDataAttributes
 */
export interface ResponseDashboardStatoNegozioDataAttributes {
    /**
     *
     * @type {string}
     * @memberof ResponseDashboardStatoNegozioDataAttributes
     */
    'data_type'?: ResponseDashboardStatoNegozioDataAttributesDataTypeEnum;
    /**
     *
     * @type {string}
     * @memberof ResponseDashboardStatoNegozioDataAttributes
     */
    'key'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponseDashboardStatoNegozioDataAttributes
     */
    'description'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponseDashboardStatoNegozioDataAttributes
     */
    'timestamp'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponseDashboardStatoNegozioDataAttributes
     */
    'status'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponseDashboardStatoNegozioDataAttributes
     */
    'status_desc'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ResponseDashboardStatoNegozioDataAttributesDataTypeEnum {
    SyncAsyncConfig = 'sync | async | config'
}

/**
 *
 * @export
 * @interface ResponsePayload
 */
export interface ResponsePayload {
    /**
     *
     * @type {ResponsePayloadData}
     * @memberof ResponsePayload
     */
    'data'?: ResponsePayloadData;
}
/**
 *
 * @export
 * @interface ResponsePayload1
 */
export interface ResponsePayload1 {
    /**
     *
     * @type {ResponsePayload1Data}
     * @memberof ResponsePayload1
     */
    'data'?: ResponsePayload1Data;
}
/**
 *
 * @export
 * @interface ResponsePayload10
 */
export interface ResponsePayload10 {
    /**
     *
     * @type {ResponsePayload10Data}
     * @memberof ResponsePayload10
     */
    'data'?: ResponsePayload10Data;
}
/**
 *
 * @export
 * @interface ResponsePayload10Data
 */
export interface ResponsePayload10Data {
    /**
     *
     * @type {string}
     * @memberof ResponsePayload10Data
     */
    'type'?: string;
    /**
     *
     * @type {Array<ResponsePayload10DataAttributes>}
     * @memberof ResponsePayload10Data
     */
    'attributes'?: Array<ResponsePayload10DataAttributes>;
}
/**
 *
 * @export
 * @interface ResponsePayload10DataAttributes
 */
export interface ResponsePayload10DataAttributes {
    /**
     *
     * @type {string}
     * @memberof ResponsePayload10DataAttributes
     */
    'url'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponsePayload10DataAttributes
     */
    'descrizione'?: string;
}
/**
 *
 * @export
 * @interface ResponsePayload1Data
 */
export interface ResponsePayload1Data {
    /**
     *
     * @type {string}
     * @memberof ResponsePayload1Data
     */
    'type'?: string;
    /**
     *
     * @type {ResponsePayload1DataAttributes}
     * @memberof ResponsePayload1Data
     */
    'attributes'?: ResponsePayload1DataAttributes;
}
/**
 *
 * @export
 * @interface ResponsePayload1DataAttributes
 */
export interface ResponsePayload1DataAttributes {
    /**
     *
     * @type {string}
     * @memberof ResponsePayload1DataAttributes
     */
    'result'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponsePayload1DataAttributes
     */
    'message'?: string;
}
/**
 *
 * @export
 * @interface ResponsePayload2
 */
export interface ResponsePayload2 {
    /**
     *
     * @type {ResponsePayload2Data}
     * @memberof ResponsePayload2
     */
    'data'?: ResponsePayload2Data;
}
/**
 *
 * @export
 * @interface ResponsePayload2Data
 */
export interface ResponsePayload2Data {
    /**
     *
     * @type {string}
     * @memberof ResponsePayload2Data
     */
    'type'?: string;
    /**
     *
     * @type {ResponsePayload1DataAttributes}
     * @memberof ResponsePayload2Data
     */
    'attributes'?: ResponsePayload1DataAttributes;
}
/**
 *
 * @export
 * @interface ResponsePayload3
 */
export interface ResponsePayload3 {
    /**
     *
     * @type {ResponsePayload3Data}
     * @memberof ResponsePayload3
     */
    'data'?: ResponsePayload3Data;
}
/**
 *
 * @export
 * @interface ResponsePayload3Data
 */
export interface ResponsePayload3Data {
    /**
     *
     * @type {string}
     * @memberof ResponsePayload3Data
     */
    'type'?: string;
    /**
     *
     * @type {ResponsePayload1DataAttributes}
     * @memberof ResponsePayload3Data
     */
    'attributes'?: ResponsePayload1DataAttributes;
}
/**
 *
 * @export
 * @interface ResponsePayload4
 */
export interface ResponsePayload4 {
    /**
     *
     * @type {ResponsePayload4Data}
     * @memberof ResponsePayload4
     */
    'data'?: ResponsePayload4Data;
}
/**
 *
 * @export
 * @interface ResponsePayload4Data
 */
export interface ResponsePayload4Data {
    /**
     *
     * @type {string}
     * @memberof ResponsePayload4Data
     */
    'type'?: string;
    /**
     *
     * @type {ResponsePayload4DataAttributes}
     * @memberof ResponsePayload4Data
     */
    'attributes'?: ResponsePayload4DataAttributes;
}
/**
 *
 * @export
 * @interface ResponsePayload4DataAttributes
 */
export interface ResponsePayload4DataAttributes {
    /**
     *
     * @type {string}
     * @memberof ResponsePayload4DataAttributes
     */
    'result'?: string;
    /**
     *
     * @type {Array<ResponsePayload4DataAttributesBrowserDownload>}
     * @memberof ResponsePayload4DataAttributes
     */
    'browser_download'?: Array<ResponsePayload4DataAttributesBrowserDownload>;
    /**
     *
     * @type {string}
     * @memberof ResponsePayload4DataAttributes
     */
    'message'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponsePayload4DataAttributes
     */
    'message2'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponsePayload4DataAttributes
     */
    'action'?: string;
}
/**
 *
 * @export
 * @interface ResponsePayload4DataAttributesBrowserDownload
 */
export interface ResponsePayload4DataAttributesBrowserDownload {
    /**
     *
     * @type {string}
     * @memberof ResponsePayload4DataAttributesBrowserDownload
     */
    'pdf_content'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponsePayload4DataAttributesBrowserDownload
     */
    'file_name'?: string;
}
/**
 *
 * @export
 * @interface ResponsePayload5
 */
export interface ResponsePayload5 {
    /**
     *
     * @type {ResponsePayload5Data}
     * @memberof ResponsePayload5
     */
    'data'?: ResponsePayload5Data;
}
/**
 *
 * @export
 * @interface ResponsePayload5Data
 */
export interface ResponsePayload5Data {
    /**
     *
     * @type {string}
     * @memberof ResponsePayload5Data
     */
    'type'?: string;
    /**
     *
     * @type {ResponsePayload4DataAttributes}
     * @memberof ResponsePayload5Data
     */
    'attributes'?: ResponsePayload4DataAttributes;
}
/**
 *
 * @export
 * @interface ResponsePayload6
 */
export interface ResponsePayload6 {
    /**
     *
     * @type {ResponsePayload6Data}
     * @memberof ResponsePayload6
     */
    'data'?: ResponsePayload6Data;
}
/**
 *
 * @export
 * @interface ResponsePayload6Data
 */
export interface ResponsePayload6Data {
    /**
     *
     * @type {string}
     * @memberof ResponsePayload6Data
     */
    'type'?: string;
    /**
     *
     * @type {ResponsePayload6DataAttributes}
     * @memberof ResponsePayload6Data
     */
    'attributes'?: ResponsePayload6DataAttributes;
}
/**
 *
 * @export
 * @interface ResponsePayload6DataAttributes
 */
export interface ResponsePayload6DataAttributes {
    /**
     *
     * @type {string}
     * @memberof ResponsePayload6DataAttributes
     */
    'result'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponsePayload6DataAttributes
     */
    'message'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponsePayload6DataAttributes
     */
    'message2'?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof ResponsePayload6DataAttributes
     */
    'sequence'?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof ResponsePayload6DataAttributes
     */
    'action'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponsePayload6DataAttributes
     */
    'error'?: string;
}
/**
 *
 * @export
 * @interface ResponsePayload7
 */
export interface ResponsePayload7 {
    /**
     *
     * @type {ResponsePayload7Data}
     * @memberof ResponsePayload7
     */
    'data'?: ResponsePayload7Data;
}
/**
 *
 * @export
 * @interface ResponsePayload7Data
 */
export interface ResponsePayload7Data {
    /**
     *
     * @type {string}
     * @memberof ResponsePayload7Data
     */
    'type'?: string;
    /**
     *
     * @type {ResponsePayload7DataAttributes}
     * @memberof ResponsePayload7Data
     */
    'attributes'?: ResponsePayload7DataAttributes;
}
/**
 *
 * @export
 * @interface ResponsePayload7DataAttributes
 */
export interface ResponsePayload7DataAttributes {
    /**
     *
     * @type {string}
     * @memberof ResponsePayload7DataAttributes
     */
    'status'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponsePayload7DataAttributes
     */
    'result'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponsePayload7DataAttributes
     */
    'message'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponsePayload7DataAttributes
     */
    'message2'?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof ResponsePayload7DataAttributes
     */
    'sequence'?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof ResponsePayload7DataAttributes
     */
    'action'?: string;
    /**
     *
     * @type {ResponsePayload7DataAttributesData}
     * @memberof ResponsePayload7DataAttributes
     */
    'data'?: ResponsePayload7DataAttributesData;
}
/**
 *
 * @export
 * @interface ResponsePayload7DataAttributesData
 */
export interface ResponsePayload7DataAttributesData {
    /**
     *
     * @type {string}
     * @memberof ResponsePayload7DataAttributesData
     */
    'esito_transazione'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponsePayload7DataAttributesData
     */
    'descrizione_esito_negativo'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponsePayload7DataAttributesData
     */
    'codice_errore'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponsePayload7DataAttributesData
     */
    'importo_cent'?: string;
}
/**
 *
 * @export
 * @interface ResponsePayload8
 */
export interface ResponsePayload8 {
    /**
     *
     * @type {ResponsePayload8Data}
     * @memberof ResponsePayload8
     */
    'data'?: ResponsePayload8Data;
}
/**
 *
 * @export
 * @interface ResponsePayload8Data
 */
export interface ResponsePayload8Data {
    /**
     *
     * @type {string}
     * @memberof ResponsePayload8Data
     */
    'type'?: string;
    /**
     *
     * @type {ResponsePayload8DataAttributes}
     * @memberof ResponsePayload8Data
     */
    'attributes'?: ResponsePayload8DataAttributes;
}
/**
 *
 * @export
 * @interface ResponsePayload8DataAttributes
 */
export interface ResponsePayload8DataAttributes {
    /**
     *
     * @type {string}
     * @memberof ResponsePayload8DataAttributes
     */
    'result'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponsePayload8DataAttributes
     */
    'message'?: string;
    /**
     *
     * @type {Array<ResponsePayload4DataAttributesBrowserDownload>}
     * @memberof ResponsePayload8DataAttributes
     */
    'browser_download'?: Array<ResponsePayload4DataAttributesBrowserDownload>;
}
/**
 *
 * @export
 * @interface ResponsePayload9
 */
export interface ResponsePayload9 {
    /**
     *
     * @type {ResponsePayload9Data}
     * @memberof ResponsePayload9
     */
    'data'?: ResponsePayload9Data;
}
/**
 *
 * @export
 * @interface ResponsePayload9Data
 */
export interface ResponsePayload9Data {
    /**
     *
     * @type {string}
     * @memberof ResponsePayload9Data
     */
    'type'?: string;
    /**
     *
     * @type {ResponsePayload9DataAttributes}
     * @memberof ResponsePayload9Data
     */
    'attributes'?: ResponsePayload9DataAttributes;
}
/**
 *
 * @export
 * @interface ResponsePayload9DataAttributes
 */
export interface ResponsePayload9DataAttributes {
    /**
     *
     * @type {ResponsePayload9DataAttributesDashboardElements}
     * @memberof ResponsePayload9DataAttributes
     */
    'dashboard_elements'?: ResponsePayload9DataAttributesDashboardElements;
    /**
     *
     * @type {Array<ResponsePayload9DataAttributesDashboardPromemoria>}
     * @memberof ResponsePayload9DataAttributes
     */
    'dashboard_promemoria'?: Array<ResponsePayload9DataAttributesDashboardPromemoria>;
}
/**
 *
 * @export
 * @interface ResponsePayload9DataAttributesDashboardElements
 */
export interface ResponsePayload9DataAttributesDashboardElements {
    /**
     *
     * @type {boolean}
     * @memberof ResponsePayload9DataAttributesDashboardElements
     */
    'info_bolle'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ResponsePayload9DataAttributesDashboardElements
     */
    'info_vendite_non_accolte'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ResponsePayload9DataAttributesDashboardElements
     */
    'info_shortage'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ResponsePayload9DataAttributesDashboardElements
     */
    'b2c'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ResponsePayload9DataAttributesDashboardElements
     */
    'b2e'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ResponsePayload9DataAttributesDashboardElements
     */
    'cec'?: boolean;
}
/**
 *
 * @export
 * @interface ResponsePayload9DataAttributesDashboardPromemoria
 */
export interface ResponsePayload9DataAttributesDashboardPromemoria {
    /**
     *
     * @type {string}
     * @memberof ResponsePayload9DataAttributesDashboardPromemoria
     */
    'messaggio'?: string;
    /**
     *
     * @type {boolean}
     * @memberof ResponsePayload9DataAttributesDashboardPromemoria
     */
    'action'?: boolean;
    /**
     *
     * @type {string}
     * @memberof ResponsePayload9DataAttributesDashboardPromemoria
     */
    'action_module'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponsePayload9DataAttributesDashboardPromemoria
     */
    'action_program'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponsePayload9DataAttributesDashboardPromemoria
     */
    'action_message'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponsePayload9DataAttributesDashboardPromemoria
     */
    'action_extra_params_link'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponsePayload9DataAttributesDashboardPromemoria
     */
    'id_menu'?: string;
}
/**
 *
 * @export
 * @interface ResponsePayloadData
 */
export interface ResponsePayloadData {
    /**
     *
     * @type {string}
     * @memberof ResponsePayloadData
     */
    'type'?: string;
    /**
     *
     * @type {ResponsePayloadDataAttributes}
     * @memberof ResponsePayloadData
     */
    'attributes'?: ResponsePayloadDataAttributes;
}
/**
 *
 * @export
 * @interface ResponsePayloadDataAttributes
 */
export interface ResponsePayloadDataAttributes {
    /**
     *
     * @type {ResponsePayloadDataAttributesStati}
     * @memberof ResponsePayloadDataAttributes
     */
    'stati'?: ResponsePayloadDataAttributesStati;
    /**
     *
     * @type {ResponsePayloadDataAttributesListaOperazioni}
     * @memberof ResponsePayloadDataAttributes
     */
    'lista_operazioni'?: ResponsePayloadDataAttributesListaOperazioni;
    /**
     *
     * @type {string}
     * @memberof ResponsePayloadDataAttributes
     */
    'message_error'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponsePayloadDataAttributes
     */
    'message_warning'?: string;
}
/**
 *
 * @export
 * @interface ResponsePayloadDataAttributesListaOperazioni
 */
export interface ResponsePayloadDataAttributesListaOperazioni {
    /**
     *
     * @type {ResponsePayloadDataAttributesListaOperazioni1}
     * @memberof ResponsePayloadDataAttributesListaOperazioni
     */
    '1'?: ResponsePayloadDataAttributesListaOperazioni1;
}
/**
 * Example of end of day task
 * @export
 * @interface ResponsePayloadDataAttributesListaOperazioni1
 */
export interface ResponsePayloadDataAttributesListaOperazioni1 {
    /**
     * Order of the tasks
     * @type {number}
     * @memberof ResponsePayloadDataAttributesListaOperazioni1
     */
    'progressivo'?: number;
    /**
     *
     * @type {string}
     * @memberof ResponsePayloadDataAttributesListaOperazioni1
     */
    'content'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponsePayloadDataAttributesListaOperazioni1
     */
    'descrizione'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponsePayloadDataAttributesListaOperazioni1
     */
    'id_bottone'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponsePayloadDataAttributesListaOperazioni1
     */
    'label_bottone'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponsePayloadDataAttributesListaOperazioni1
     */
    'id_span_risultato'?: string;
    /**
     * End point of the function to be called to launch the closing task, for example /api/v1/chiusuracassa/chiusura/{endpoint}
     * @type {string}
     * @memberof ResponsePayloadDataAttributesListaOperazioni1
     */
    'endpoint'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponsePayloadDataAttributesListaOperazioni1
     */
    'icon'?: string;
}
/**
 *
 * @export
 * @interface ResponsePayloadDataAttributesStati
 */
export interface ResponsePayloadDataAttributesStati {
    /**
     * Shop\'s closing status
     * @type {string}
     * @memberof ResponsePayloadDataAttributesStati
     */
    'negozio'?: string;
    /**
     *
     * @type {ResponsePayloadDataAttributesStatiCasse}
     * @memberof ResponsePayloadDataAttributesStati
     */
    'casse'?: ResponsePayloadDataAttributesStatiCasse;
    /**
     *
     * @type {object}
     * @memberof ResponsePayloadDataAttributesStati
     */
    'cassa_cashprinter'?: object;
}
/**
 *
 * @export
 * @interface ResponsePayloadDataAttributesStatiCasse
 */
export interface ResponsePayloadDataAttributesStatiCasse {
    /**
     *
     * @type {ResponsePayloadDataAttributesStatiCasse01}
     * @memberof ResponsePayloadDataAttributesStatiCasse
     */
    '01'?: ResponsePayloadDataAttributesStatiCasse01;
}
/**
 * Closing status of the till, the till id is the current object key
 * @export
 * @interface ResponsePayloadDataAttributesStatiCasse01
 */
export interface ResponsePayloadDataAttributesStatiCasse01 {
    /**
     *
     * @type {string}
     * @memberof ResponsePayloadDataAttributesStatiCasse01
     */
    'CASH_STATUS'?: string;
    /**
     * 1 means that the till can be closed, 0 means that the till can not be closed
     * @type {string}
     * @memberof ResponsePayloadDataAttributesStatiCasse01
     */
    'CLOSE_PERMISSION'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponsePayloadDataAttributesStatiCasse01
     */
    'FEATURE_SET'?: ResponsePayloadDataAttributesStatiCasse01FEATURESETEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ResponsePayloadDataAttributesStatiCasse01FEATURESETEnum {
    Cash = 'CASH',
    Cashprinter = 'CASHPRINTER',
    Dsmobilepay = 'DSMOBILEPAY',
    B2Emobilepay = 'B2EMOBILEPAY',
    Online = 'ONLINE'
}

/**
 *
 * @export
 * @interface ResponseStatoRegistratoreCassa
 */
export interface ResponseStatoRegistratoreCassa {
    /**
     *
     * @type {ResponseStatoRegistratoreCassaData}
     * @memberof ResponseStatoRegistratoreCassa
     */
    'data'?: ResponseStatoRegistratoreCassaData;
}
/**
 *
 * @export
 * @interface ResponseStatoRegistratoreCassaData
 */
export interface ResponseStatoRegistratoreCassaData {
    /**
     *
     * @type {string}
     * @memberof ResponseStatoRegistratoreCassaData
     */
    'type'?: string;
    /**
     *
     * @type {ResponseStatoRegistratoreCassaDataAttributes}
     * @memberof ResponseStatoRegistratoreCassaData
     */
    'attributes'?: ResponseStatoRegistratoreCassaDataAttributes;
}
/**
 *
 * @export
 * @interface ResponseStatoRegistratoreCassaDataAttributes
 */
export interface ResponseStatoRegistratoreCassaDataAttributes {
    /**
     *
     * @type {string}
     * @memberof ResponseStatoRegistratoreCassaDataAttributes
     */
    'data_type'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponseStatoRegistratoreCassaDataAttributes
     */
    'key'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponseStatoRegistratoreCassaDataAttributes
     */
    'timestamp'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponseStatoRegistratoreCassaDataAttributes
     */
    'status'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponseStatoRegistratoreCassaDataAttributes
     */
    'status_desc'?: string;
}
/**
 *
 * @export
 * @interface Return
 */
export interface Return {
    /**
     *
     * @type {Array<ReturnData>}
     * @memberof Return
     */
    'data'?: Array<ReturnData>;
}
/**
 *
 * @export
 * @interface ReturnAttributes
 */
export interface ReturnAttributes {
    /**
     * Current store code
     * @type {string}
     * @memberof ReturnAttributes
     */
    'cod_negozio'?: string;
    /**
     * Current store anag code
     * @type {string}
     * @memberof ReturnAttributes
     */
    'cod_negozio_anag'?: string;
    /**
     * Store address
     * @type {string}
     * @memberof ReturnAttributes
     */
    'indirizzo'?: string;
    /**
     * Store postal code
     * @type {string}
     * @memberof ReturnAttributes
     */
    'cap'?: string;
    /**
     * Store city description
     * @type {string}
     * @memberof ReturnAttributes
     */
    'citta'?: string;
    /**
     * Store province name
     * @type {string}
     * @memberof ReturnAttributes
     */
    'provincia'?: string;
    /**
     * Store telephone
     * @type {string}
     * @memberof ReturnAttributes
     */
    'telefono'?: string;
    /**
     * Store description
     * @type {string}
     * @memberof ReturnAttributes
     */
    'ragione_sociale'?: string;
    /**
     * Store
     * @type {string}
     * @memberof ReturnAttributes
     */
    'citta_ditta'?: string;
    /**
     * ?
     * @type {string}
     * @memberof ReturnAttributes
     */
    'indirizzo_ditta'?: string;
    /**
     * ?
     * @type {string}
     * @memberof ReturnAttributes
     */
    'cap_ditta'?: string;
    /**
     * ?
     * @type {string}
     * @memberof ReturnAttributes
     */
    'telefono_ditta'?: string;
    /**
     * Store ISO country
     * @type {string}
     * @memberof ReturnAttributes
     */
    'nazione'?: string;
    /**
     * ?
     * @type {string}
     * @memberof ReturnAttributes
     */
    'sigla_nazione'?: string;
    /**
     * Store city code
     * @type {string}
     * @memberof ReturnAttributes
     */
    'sigla_provincia'?: string;
    /**
     *
     * @type {string}
     * @memberof ReturnAttributes
     */
    'data_creazione'?: string;
    /**
     *
     * @type {string}
     * @memberof ReturnAttributes
     */
    'email_negozio'?: string;
    /**
     *
     * @type {string}
     * @memberof ReturnAttributes
     */
    'fax'?: string;
    /**
     *
     * @type {string}
     * @memberof ReturnAttributes
     */
    'lingua'?: string;
    /**
     *
     * @type {string}
     * @memberof ReturnAttributes
     */
    'p_iva'?: string;
    /**
     *
     * @type {string}
     * @memberof ReturnAttributes
     */
    'tipo_negozio'?: string;
}
/**
 *
 * @export
 * @interface ReturnBurnGiftCard
 */
export interface ReturnBurnGiftCard {
    /**
     *
     * @type {Array<ManageSaleReturnData | GiftCardData | object>}
     * @memberof ReturnBurnGiftCard
     */
    'data'?: Array<ManageSaleReturnData | GiftCardData | object>;
}
/**
 *
 * @export
 * @interface ReturnBurnGiftCard1
 */
export interface ReturnBurnGiftCard1 {
    /**
     *
     * @type {ReturnBurnGiftCard1Data}
     * @memberof ReturnBurnGiftCard1
     */
    'data'?: ReturnBurnGiftCard1Data;
}
/**
 *
 * @export
 * @interface ReturnBurnGiftCard1Data
 */
export interface ReturnBurnGiftCard1Data {
    /**
     *
     * @type {string}
     * @memberof ReturnBurnGiftCard1Data
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof ReturnBurnGiftCard1Data
     */
    'id'?: string;
    /**
     *
     * @type {ReturnBurnGiftCard1DataAttributes}
     * @memberof ReturnBurnGiftCard1Data
     */
    'attributes'?: ReturnBurnGiftCard1DataAttributes;
}
/**
 *
 * @export
 * @interface ReturnBurnGiftCard1DataAttributes
 */
export interface ReturnBurnGiftCard1DataAttributes {
    /**
     *
     * @type {number}
     * @memberof ReturnBurnGiftCard1DataAttributes
     */
    'importo'?: number;
    /**
     *
     * @type {number}
     * @memberof ReturnBurnGiftCard1DataAttributes
     */
    'row_num'?: number;
    /**
     *
     * @type {ReturnBurnGiftCard1DataAttributesCardData}
     * @memberof ReturnBurnGiftCard1DataAttributes
     */
    'card_data'?: ReturnBurnGiftCard1DataAttributesCardData;
}
/**
 *
 * @export
 * @interface ReturnBurnGiftCard1DataAttributesCardData
 */
export interface ReturnBurnGiftCard1DataAttributesCardData {
    /**
     *
     * @type {string}
     * @memberof ReturnBurnGiftCard1DataAttributesCardData
     */
    'ExpireDate'?: string;
    /**
     *
     * @type {string}
     * @memberof ReturnBurnGiftCard1DataAttributesCardData
     */
    'Result'?: string;
    /**
     *
     * @type {string}
     * @memberof ReturnBurnGiftCard1DataAttributesCardData
     */
    'AmountLeft'?: string;
    /**
     *
     * @type {string}
     * @memberof ReturnBurnGiftCard1DataAttributesCardData
     */
    'CardCode'?: string;
    /**
     *
     * @type {string}
     * @memberof ReturnBurnGiftCard1DataAttributesCardData
     */
    'IssueDate'?: string;
    /**
     *
     * @type {string}
     * @memberof ReturnBurnGiftCard1DataAttributesCardData
     */
    'Price'?: string;
    /**
     *
     * @type {string}
     * @memberof ReturnBurnGiftCard1DataAttributesCardData
     */
    'Status'?: string;
    /**
     *
     * @type {string}
     * @memberof ReturnBurnGiftCard1DataAttributesCardData
     */
    'CurrencyChanges'?: string;
    /**
     *
     * @type {object}
     * @memberof ReturnBurnGiftCard1DataAttributesCardData
     */
    'BurnDate'?: object;
    /**
     *
     * @type {ReturnBurnGiftCard1DataAttributesCardDataOperations}
     * @memberof ReturnBurnGiftCard1DataAttributesCardData
     */
    'Operations'?: ReturnBurnGiftCard1DataAttributesCardDataOperations;
    /**
     *
     * @type {string}
     * @memberof ReturnBurnGiftCard1DataAttributesCardData
     */
    'StatusTranslated'?: string;
    /**
     *
     * @type {boolean}
     * @memberof ReturnBurnGiftCard1DataAttributesCardData
     */
    'gift_is_storecredit'?: boolean;
}
/**
 *
 * @export
 * @interface ReturnBurnGiftCard1DataAttributesCardDataOperations
 */
export interface ReturnBurnGiftCard1DataAttributesCardDataOperations {
    /**
     *
     * @type {Array<ReturnBurnGiftCard1DataAttributesCardDataOperationsIssuedCardOperation>}
     * @memberof ReturnBurnGiftCard1DataAttributesCardDataOperations
     */
    'IssuedCardOperation'?: Array<ReturnBurnGiftCard1DataAttributesCardDataOperationsIssuedCardOperation>;
}
/**
 *
 * @export
 * @interface ReturnBurnGiftCard1DataAttributesCardDataOperationsIssuedCardOperation
 */
export interface ReturnBurnGiftCard1DataAttributesCardDataOperationsIssuedCardOperation {
    /**
     *
     * @type {string}
     * @memberof ReturnBurnGiftCard1DataAttributesCardDataOperationsIssuedCardOperation
     */
    'Amount'?: string;
    /**
     *
     * @type {string}
     * @memberof ReturnBurnGiftCard1DataAttributesCardDataOperationsIssuedCardOperation
     */
    'CurrencyID'?: string;
    /**
     *
     * @type {string}
     * @memberof ReturnBurnGiftCard1DataAttributesCardDataOperationsIssuedCardOperation
     */
    'Date'?: string;
    /**
     *
     * @type {string}
     * @memberof ReturnBurnGiftCard1DataAttributesCardDataOperationsIssuedCardOperation
     */
    'OperationType'?: string;
    /**
     *
     * @type {string}
     * @memberof ReturnBurnGiftCard1DataAttributesCardDataOperationsIssuedCardOperation
     */
    'OperatorDescription'?: string;
    /**
     *
     * @type {string}
     * @memberof ReturnBurnGiftCard1DataAttributesCardDataOperationsIssuedCardOperation
     */
    'OperatorTermId'?: string;
}
/**
 *
 * @export
 * @interface ReturnData
 */
export interface ReturnData {
    /**
     *
     * @type {string}
     * @memberof ReturnData
     */
    'id'?: string;
    /**
     * Fixed string
     * @type {string}
     * @memberof ReturnData
     */
    'type'?: string;
    /**
     *
     * @type {ReturnAttributes}
     * @memberof ReturnData
     */
    'attributes'?: ReturnAttributes;
}
/**
 *
 * @export
 * @interface ReturnsCCRefund
 */
export interface ReturnsCCRefund {
    /**
     *
     * @type {ApiV1ReturnsSaleIdPaymentsPosPosTransactionIdData}
     * @memberof ReturnsCCRefund
     */
    'data'?: ApiV1ReturnsSaleIdPaymentsPosPosTransactionIdData;
}
/**
 *
 * @export
 * @interface ReturnsCCRefund1
 */
export interface ReturnsCCRefund1 {
    /**
     *
     * @type {ApiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdData}
     * @memberof ReturnsCCRefund1
     */
    'data'?: ApiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdData;
}
/**
 *
 * @export
 * @interface ReturnsGiftCard
 */
export interface ReturnsGiftCard {
    /**
     *
     * @type {ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeData}
     * @memberof ReturnsGiftCard
     */
    'data'?: ApiV1ReturnsSaleIdPaymentsGiftCardGiftCodeData;
}
/**
 *
 * @export
 * @interface RistampaAcconti
 */
export interface RistampaAcconti {
    /**
     *
     * @type {RistampaAccontiData}
     * @memberof RistampaAcconti
     */
    'data'?: RistampaAccontiData;
}
/**
 *
 * @export
 * @interface RistampaAccontiData
 */
export interface RistampaAccontiData {
    /**
     * Unique id
     * @type {string}
     * @memberof RistampaAccontiData
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof RistampaAccontiData
     */
    'type'?: string;
    /**
     *
     * @type {RistampaAccontiDataAttributes}
     * @memberof RistampaAccontiData
     */
    'attributes'?: RistampaAccontiDataAttributes;
}
/**
 *
 * @export
 * @interface RistampaAccontiDataAttributes
 */
export interface RistampaAccontiDataAttributes {
    /**
     *
     * @type {Array<RistampaAccontiDataAttributesBrowserDownload>}
     * @memberof RistampaAccontiDataAttributes
     */
    'browser_download'?: Array<RistampaAccontiDataAttributesBrowserDownload>;
}
/**
 *
 * @export
 * @interface RistampaAccontiDataAttributesBrowserDownload
 */
export interface RistampaAccontiDataAttributesBrowserDownload {
    /**
     * file pdf content base64
     * @type {string}
     * @memberof RistampaAccontiDataAttributesBrowserDownload
     */
    'pdf_content'?: string;
    /**
     *
     * @type {string}
     * @memberof RistampaAccontiDataAttributesBrowserDownload
     */
    'file_name'?: string;
}
/**
 *
 * @export
 * @interface RistampaBollettinaResponse
 */
export interface RistampaBollettinaResponse {
    /**
     *
     * @type {RistampaBollettinaResponseData}
     * @memberof RistampaBollettinaResponse
     */
    'data'?: RistampaBollettinaResponseData;
}
/**
 *
 * @export
 * @interface RistampaBollettinaResponseData
 */
export interface RistampaBollettinaResponseData {
    /**
     *
     * @type {string}
     * @memberof RistampaBollettinaResponseData
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof RistampaBollettinaResponseData
     */
    'type'?: string;
    /**
     *
     * @type {RistampaBollettinaResponseDataAttributes}
     * @memberof RistampaBollettinaResponseData
     */
    'attributes'?: RistampaBollettinaResponseDataAttributes;
}
/**
 *
 * @export
 * @interface RistampaBollettinaResponseDataAttributes
 */
export interface RistampaBollettinaResponseDataAttributes {
    /**
     *
     * @type {string}
     * @memberof RistampaBollettinaResponseDataAttributes
     */
    'status'?: string;
    /**
     *
     * @type {string}
     * @memberof RistampaBollettinaResponseDataAttributes
     */
    'message'?: string;
    /**
     *
     * @type {Array<RistampaBollettinaResponseDataAttributesBrowserDownload>}
     * @memberof RistampaBollettinaResponseDataAttributes
     */
    'browser_download'?: Array<RistampaBollettinaResponseDataAttributesBrowserDownload>;
}
/**
 *
 * @export
 * @interface RistampaBollettinaResponseDataAttributesBrowserDownload
 */
export interface RistampaBollettinaResponseDataAttributesBrowserDownload {
    /**
     *
     * @type {string}
     * @memberof RistampaBollettinaResponseDataAttributesBrowserDownload
     */
    'pdf_content'?: string;
    /**
     *
     * @type {string}
     * @memberof RistampaBollettinaResponseDataAttributesBrowserDownload
     */
    'file_name'?: string;
}
/**
 *
 * @export
 * @interface RistampaFiscaleResponse
 */
export interface RistampaFiscaleResponse {
    /**
     *
     * @type {RistampaFiscaleResponseData}
     * @memberof RistampaFiscaleResponse
     */
    'data'?: RistampaFiscaleResponseData;
}
/**
 *
 * @export
 * @interface RistampaFiscaleResponseData
 */
export interface RistampaFiscaleResponseData {
    /**
     *
     * @type {string}
     * @memberof RistampaFiscaleResponseData
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof RistampaFiscaleResponseData
     */
    'type'?: string;
    /**
     *
     * @type {RistampaBollettinaResponseDataAttributes}
     * @memberof RistampaFiscaleResponseData
     */
    'attributes'?: RistampaBollettinaResponseDataAttributes;
}
/**
 *
 * @export
 * @interface RistampaScontrinoCortesiaResponse
 */
export interface RistampaScontrinoCortesiaResponse {
    /**
     *
     * @type {RistampaScontrinoCortesiaResponseData}
     * @memberof RistampaScontrinoCortesiaResponse
     */
    'data'?: RistampaScontrinoCortesiaResponseData;
}
/**
 *
 * @export
 * @interface RistampaScontrinoCortesiaResponseData
 */
export interface RistampaScontrinoCortesiaResponseData {
    /**
     *
     * @type {string}
     * @memberof RistampaScontrinoCortesiaResponseData
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof RistampaScontrinoCortesiaResponseData
     */
    'type'?: string;
    /**
     *
     * @type {RistampaBollettinaResponseDataAttributes}
     * @memberof RistampaScontrinoCortesiaResponseData
     */
    'attributes'?: RistampaBollettinaResponseDataAttributes;
}
/**
 *
 * @export
 * @interface RuoliUtente
 */
export interface RuoliUtente {
    /**
     *
     * @type {RuoliUtenteData}
     * @memberof RuoliUtente
     */
    'data'?: RuoliUtenteData;
}
/**
 *
 * @export
 * @interface RuoliUtenteData
 */
export interface RuoliUtenteData {
    /**
     *
     * @type {string}
     * @memberof RuoliUtenteData
     */
    'type'?: string;
    /**
     *
     * @type {Array<RuoliUtenteDataAttributes1>}
     * @memberof RuoliUtenteData
     */
    'attributes'?: Array<RuoliUtenteDataAttributes1>;
}
/**
 *
 * @export
 * @interface RuoliUtenteDataAttributes
 */
export interface RuoliUtenteDataAttributes {
    /**
     *
     * @type {string}
     * @memberof RuoliUtenteDataAttributes
     */
    'ruolo'?: string;
    /**
     *
     * @type {string}
     * @memberof RuoliUtenteDataAttributes
     */
    'traduzione'?: string;
    /**
     *
     * @type {string}
     * @memberof RuoliUtenteDataAttributes
     */
    'immagine'?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof RuoliUtenteDataAttributes
     */
    'roleslist'?: Array<string>;
}
/**
 *
 * @export
 * @interface RuoliUtenteDataAttributes1
 */
export interface RuoliUtenteDataAttributes1 {
    /**
     *
     * @type {string}
     * @memberof RuoliUtenteDataAttributes1
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof RuoliUtenteDataAttributes1
     */
    'id'?: string;
    /**
     *
     * @type {RuoliUtenteDataAttributes}
     * @memberof RuoliUtenteDataAttributes1
     */
    'attributes'?: RuoliUtenteDataAttributes;
}
/**
 *
 * @export
 * @interface Sale
 */
export interface Sale {
    /**
     *
     * @type {SaleData}
     * @memberof Sale
     */
    'data'?: SaleData;
}
/**
 *
 * @export
 * @interface Sale1
 */
export interface Sale1 {
    /**
     *
     * @type {ApiV1SalesData}
     * @memberof Sale1
     */
    'data'?: ApiV1SalesData;
}
/**
 *
 * @export
 * @interface Sale2
 */
export interface Sale2 {
    /**
     *
     * @type {Sale2Data}
     * @memberof Sale2
     */
    'data'?: Sale2Data;
}
/**
 *
 * @export
 * @interface Sale2Data
 */
export interface Sale2Data {
    /**
     *
     * @type {string}
     * @memberof Sale2Data
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof Sale2Data
     */
    'id'?: string;
    /**
     *
     * @type {Sale2DataAttributes}
     * @memberof Sale2Data
     */
    'attributes'?: Sale2DataAttributes;
}
/**
 *
 * @export
 * @interface Sale2DataAttributes
 */
export interface Sale2DataAttributes {
    /**
     * sale counter
     * @type {number}
     * @memberof Sale2DataAttributes
     */
    'id_transazione'?: number;
    /**
     * pk_consumer
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'pk_consumer'?: string;
    /**
     * sid
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'sid'?: string;
    /**
     * tipo_applicazione_apertura
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'tipo_applicazione_apertura'?: string;
    /**
     * tipo_applicazione_chiusura
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'tipo_applicazione_chiusura'?: string;
    /**
     * cod_cassa
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'cod_cassa'?: string;
    /**
     * cod_cassiera
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'cod_cassiera'?: string;
    /**
     * cod_documento
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'cod_documento'?: string;
    /**
     * cod_negozio
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'cod_negozio'?: string;
    /**
     * cod_vettore
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'cod_vettore'?: string;
    /**
     * codice_movimento
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'codice_movimento'?: string;
    /**
     * codice_stato
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'codice_stato'?: string;
    /**
     * data_creazione
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'data_creazione'?: string;
    /**
     * data_documento
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'data_documento'?: string;
    /**
     * data_modifica
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'data_modifica'?: string;
    /**
     * dati_aggiuntivi
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'dati_aggiuntivi'?: string;
    /**
     * stringified object dati_documenti
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'dati_documenti'?: string;
    /**
     * divisa
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'divisa'?: string;
    /**
     * flag_stampa_documento
     * @type {number}
     * @memberof Sale2DataAttributes
     */
    'flag_stampa_documento'?: number;
    /**
     * id_postazione_apertura
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'id_postazione_apertura'?: string;
    /**
     * id_postazione_chiusura
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'id_postazione_chiusura'?: string;
    /**
     * id_utente
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'id_utente'?: string;
    /**
     * importo_finale
     * @type {number}
     * @memberof Sale2DataAttributes
     */
    'importo_finale'?: number;
    /**
     * importo_iniziale
     * @type {number}
     * @memberof Sale2DataAttributes
     */
    'importo_iniziale'?: number;
    /**
     * importo_pagato
     * @type {number}
     * @memberof Sale2DataAttributes
     */
    'importo_pagato'?: number;
    /**
     * nota
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'nota'?: string;
    /**
     * numero_documento
     * @type {number}
     * @memberof Sale2DataAttributes
     */
    'numero_documento'?: number;
    /**
     * om_num_order
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'om_num_order'?: string;
    /**
     * numero_stampa_documento
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'numero_stampa_documento'?: string;
    /**
     * ora_documento
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'ora_documento'?: string;
    /**
     *
     * @type {Array<Sale2DataAttributesCapi>}
     * @memberof Sale2DataAttributes
     */
    'capi'?: Array<Sale2DataAttributesCapi>;
    /**
     *
     * @type {Array<Sale2DataAttributesPagamenti>}
     * @memberof Sale2DataAttributes
     */
    'pagamenti'?: Array<Sale2DataAttributesPagamenti>;
    /**
     *
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'message'?: string;
    /**
     *
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'print_error_result'?: string;
    /**
     *
     * @type {string}
     * @memberof Sale2DataAttributes
     */
    'warning_msg'?: string;
    /**
     *
     * @type {Array<Array<string>>}
     * @memberof Sale2DataAttributes
     */
    'prints_to_retry'?: Array<Array<string>>;
}
/**
 *
 * @export
 * @interface Sale2DataAttributesCapi
 */
export interface Sale2DataAttributesCapi {
    /**
     * classe
     * @type {string}
     * @memberof Sale2DataAttributesCapi
     */
    'classe'?: string;
    /**
     * sale counter
     * @type {number}
     * @memberof Sale2DataAttributesCapi
     */
    'id_transazione'?: number;
    /**
     * cod_commessa
     * @type {string}
     * @memberof Sale2DataAttributesCapi
     */
    'cod_commessa'?: string;
    /**
     * cod_negozio
     * @type {string}
     * @memberof Sale2DataAttributesCapi
     */
    'cod_negozio'?: string;
    /**
     * codice_movimento
     * @type {string}
     * @memberof Sale2DataAttributesCapi
     */
    'codice_movimento'?: string;
    /**
     * codice_stato
     * @type {string}
     * @memberof Sale2DataAttributesCapi
     */
    'codice_stato'?: string;
    /**
     *
     * @type {GetSkuDataAttributesComposizione}
     * @memberof Sale2DataAttributesCapi
     */
    'composizione'?: GetSkuDataAttributesComposizione;
    /**
     * correzione_importo
     * @type {number}
     * @memberof Sale2DataAttributesCapi
     */
    'correzione_importo'?: number;
    /**
     * custom_data
     * @type {string}
     * @memberof Sale2DataAttributesCapi
     */
    'custom_data'?: string;
    /**
     * data_creazione
     * @type {string}
     * @memberof Sale2DataAttributesCapi
     */
    'data_creazione'?: string;
    /**
     * data_modifica
     * @type {string}
     * @memberof Sale2DataAttributesCapi
     */
    'data_modifica'?: string;
    /**
     * ean
     * @type {string}
     * @memberof Sale2DataAttributesCapi
     */
    'ean'?: string;
    /**
     * flag_divisa
     * @type {number}
     * @memberof Sale2DataAttributesCapi
     */
    'flag_divisa'?: number;
    /**
     * flag_promo
     * @type {number}
     * @memberof Sale2DataAttributesCapi
     */
    'flag_promo'?: number;
    /**
     * importo_custom
     * @type {number}
     * @memberof Sale2DataAttributesCapi
     */
    'importo_custom'?: number;
    /**
     * importo_finale
     * @type {number}
     * @memberof Sale2DataAttributesCapi
     */
    'importo_finale'?: number;
    /**
     * importo_iniziale
     * @type {number}
     * @memberof Sale2DataAttributesCapi
     */
    'importo_iniziale'?: number;
    /**
     * iva
     * @type {number}
     * @memberof Sale2DataAttributesCapi
     */
    'iva'?: number;
    /**
     * nome
     * @type {string}
     * @memberof Sale2DataAttributesCapi
     */
    'nome'?: string;
    /**
     * nota
     * @type {string}
     * @memberof Sale2DataAttributesCapi
     */
    'nota'?: string;
    /**
     * progressivo
     * @type {number}
     * @memberof Sale2DataAttributesCapi
     */
    'progressivo'?: number;
    /**
     * sconto
     * @type {number}
     * @memberof Sale2DataAttributesCapi
     */
    'sconto'?: number;
    /**
     * sku
     * @type {string}
     * @memberof Sale2DataAttributesCapi
     */
    'sku'?: string;
    /**
     * sku_created
     * @type {number}
     * @memberof Sale2DataAttributesCapi
     */
    'sku_created'?: number;
    /**
     * sku_read
     * @type {string}
     * @memberof Sale2DataAttributesCapi
     */
    'sku_read'?: string;
    /**
     * sku_splitted
     * @type {number}
     * @memberof Sale2DataAttributesCapi
     */
    'sku_splitted'?: number;
    /**
     * tipo_importo
     * @type {string}
     * @memberof Sale2DataAttributesCapi
     */
    'tipo_importo'?: string;
    /**
     * tipologia_merce
     * @type {string}
     * @memberof Sale2DataAttributesCapi
     */
    'tipologia_merce'?: string;
    /**
     * causale_reso
     * @type {string}
     * @memberof Sale2DataAttributesCapi
     */
    'causale_reso'?: string;
    /**
     * flag_tassabile
     * @type {number}
     * @memberof Sale2DataAttributesCapi
     */
    'flag_tassabile'?: number;
    /**
     * prezzo_listino_vendita
     * @type {number}
     * @memberof Sale2DataAttributesCapi
     */
    'prezzo_listino_vendita'?: number;
    /**
     * reso
     * @type {number}
     * @memberof Sale2DataAttributesCapi
     */
    'reso'?: number;
    /**
     * rfid
     * @type {string}
     * @memberof Sale2DataAttributesCapi
     */
    'rfid'?: string;
    /**
     * sconto_listino_vendita
     * @type {number}
     * @memberof Sale2DataAttributesCapi
     */
    'sconto_listino_vendita'?: number;
    /**
     * sku_gruppo
     * @type {string}
     * @memberof Sale2DataAttributesCapi
     */
    'sku_gruppo'?: string;
}
/**
 *
 * @export
 * @interface Sale2DataAttributesPagamenti
 */
export interface Sale2DataAttributesPagamenti {
    /**
     * sale counter
     * @type {number}
     * @memberof Sale2DataAttributesPagamenti
     */
    'id_transazione'?: number;
    /**
     * barcode
     * @type {string}
     * @memberof Sale2DataAttributesPagamenti
     */
    'barcode'?: string;
    /**
     * cod_negozio
     * @type {string}
     * @memberof Sale2DataAttributesPagamenti
     */
    'cod_negozio'?: string;
    /**
     * cod_operazione
     * @type {string}
     * @memberof Sale2DataAttributesPagamenti
     */
    'cod_operazione'?: string;
    /**
     * codice_movimento
     * @type {string}
     * @memberof Sale2DataAttributesPagamenti
     */
    'codice_movimento'?: string;
    /**
     * codice_stato
     * @type {string}
     * @memberof Sale2DataAttributesPagamenti
     */
    'codice_stato'?: string;
    /**
     * data_creazione
     * @type {string}
     * @memberof Sale2DataAttributesPagamenti
     */
    'data_creazione'?: string;
    /**
     * data_modifica
     * @type {string}
     * @memberof Sale2DataAttributesPagamenti
     */
    'data_modifica'?: string;
    /**
     * dati_operazione
     * @type {string}
     * @memberof Sale2DataAttributesPagamenti
     */
    'dati_operazione'?: string;
    /**
     * divisa
     * @type {string}
     * @memberof Sale2DataAttributesPagamenti
     */
    'divisa'?: string;
    /**
     * id_postazione
     * @type {string}
     * @memberof Sale2DataAttributesPagamenti
     */
    'id_postazione'?: string;
    /**
     * importo_finale
     * @type {number}
     * @memberof Sale2DataAttributesPagamenti
     */
    'importo_finale'?: number;
    /**
     * importo_iniziale
     * @type {number}
     * @memberof Sale2DataAttributesPagamenti
     */
    'importo_iniziale'?: number;
    /**
     * nota
     * @type {string}
     * @memberof Sale2DataAttributesPagamenti
     */
    'nota'?: string;
    /**
     * progressivo
     * @type {number}
     * @memberof Sale2DataAttributesPagamenti
     */
    'progressivo'?: number;
    /**
     * progressivo_capo
     * @type {number}
     * @memberof Sale2DataAttributesPagamenti
     */
    'progressivo_capo'?: number;
    /**
     * tipo_applicazione
     * @type {string}
     * @memberof Sale2DataAttributesPagamenti
     */
    'tipo_applicazione'?: string;
    /**
     * reso
     * @type {number}
     * @memberof Sale2DataAttributesPagamenti
     */
    'reso'?: number;
}
/**
 *
 * @export
 * @interface Sale3
 */
export interface Sale3 {
    /**
     *
     * @type {Array<Sale3Data>}
     * @memberof Sale3
     */
    'data'?: Array<Sale3Data>;
}
/**
 *
 * @export
 * @interface Sale3Attributes
 */
export interface Sale3Attributes {
    /**
     * sale counter
     * @type {number}
     * @memberof Sale3Attributes
     */
    'id_transazione'?: number;
    /**
     * pk_consumer
     * @type {string}
     * @memberof Sale3Attributes
     */
    'pk_consumer'?: string;
    /**
     * sid
     * @type {string}
     * @memberof Sale3Attributes
     */
    'sid'?: string;
    /**
     * tipo_applicazione_apertura
     * @type {string}
     * @memberof Sale3Attributes
     */
    'tipo_applicazione_apertura'?: string;
    /**
     * tipo_applicazione_chiusura
     * @type {string}
     * @memberof Sale3Attributes
     */
    'tipo_applicazione_chiusura'?: string;
    /**
     * cod_cassa
     * @type {string}
     * @memberof Sale3Attributes
     */
    'cod_cassa'?: string;
    /**
     * cod_cassiera
     * @type {string}
     * @memberof Sale3Attributes
     */
    'cod_cassiera'?: string;
    /**
     * cod_documento
     * @type {string}
     * @memberof Sale3Attributes
     */
    'cod_documento'?: string;
    /**
     * cod_negozio
     * @type {string}
     * @memberof Sale3Attributes
     */
    'cod_negozio'?: string;
    /**
     * cod_vettore
     * @type {string}
     * @memberof Sale3Attributes
     */
    'cod_vettore'?: string;
    /**
     * codice_movimento
     * @type {string}
     * @memberof Sale3Attributes
     */
    'codice_movimento'?: string;
    /**
     * codice_stato
     * @type {string}
     * @memberof Sale3Attributes
     */
    'codice_stato'?: string;
    /**
     * data_creazione
     * @type {string}
     * @memberof Sale3Attributes
     */
    'data_creazione'?: string;
    /**
     * data_documento
     * @type {string}
     * @memberof Sale3Attributes
     */
    'data_documento'?: string;
    /**
     * data_modifica
     * @type {string}
     * @memberof Sale3Attributes
     */
    'data_modifica'?: string;
    /**
     * dati_aggiuntivi
     * @type {string}
     * @memberof Sale3Attributes
     */
    'dati_aggiuntivi'?: string;
    /**
     * stringified object dati_documenti
     * @type {string}
     * @memberof Sale3Attributes
     */
    'dati_documenti'?: string;
    /**
     * divisa
     * @type {string}
     * @memberof Sale3Attributes
     */
    'divisa'?: string;
    /**
     * flag_stampa_documento
     * @type {number}
     * @memberof Sale3Attributes
     */
    'flag_stampa_documento'?: number;
    /**
     * id_postazione_apertura
     * @type {string}
     * @memberof Sale3Attributes
     */
    'id_postazione_apertura'?: string;
    /**
     * id_postazione_chiusura
     * @type {string}
     * @memberof Sale3Attributes
     */
    'id_postazione_chiusura'?: string;
    /**
     * id_utente
     * @type {string}
     * @memberof Sale3Attributes
     */
    'id_utente'?: string;
    /**
     * importo_finale
     * @type {number}
     * @memberof Sale3Attributes
     */
    'importo_finale'?: number;
    /**
     * importo_iniziale
     * @type {number}
     * @memberof Sale3Attributes
     */
    'importo_iniziale'?: number;
    /**
     * importo_pagato
     * @type {number}
     * @memberof Sale3Attributes
     */
    'importo_pagato'?: number;
    /**
     * nota
     * @type {string}
     * @memberof Sale3Attributes
     */
    'nota'?: string;
    /**
     * numero_documento
     * @type {number}
     * @memberof Sale3Attributes
     */
    'numero_documento'?: number;
    /**
     * om_num_order
     * @type {string}
     * @memberof Sale3Attributes
     */
    'om_num_order'?: string;
    /**
     * numero_stampa_documento
     * @type {string}
     * @memberof Sale3Attributes
     */
    'numero_stampa_documento'?: string;
    /**
     * ora_documento
     * @type {string}
     * @memberof Sale3Attributes
     */
    'ora_documento'?: string;
    /**
     * empty object
     * @type {object}
     * @memberof Sale3Attributes
     */
    'sale_return_data'?: object;
    /**
     *
     * @type {Sale3AttributesConsumer}
     * @memberof Sale3Attributes
     */
    'consumer'?: Sale3AttributesConsumer;
    /**
     *
     * @type {Array<ApiV1SalesDataAttributesCapi>}
     * @memberof Sale3Attributes
     */
    'capi'?: Array<ApiV1SalesDataAttributesCapi>;
    /**
     *
     * @type {Array<ApiV1SalesDataAttributesPagamenti>}
     * @memberof Sale3Attributes
     */
    'pagamenti'?: Array<ApiV1SalesDataAttributesPagamenti>;
}
/**
 *
 * @export
 * @interface Sale3AttributesConsumer
 */
export interface Sale3AttributesConsumer {
    /**
     *
     * @type {string}
     * @memberof Sale3AttributesConsumer
     */
    'cognome'?: string;
    /**
     *
     * @type {string}
     * @memberof Sale3AttributesConsumer
     */
    'nome'?: string;
    /**
     *
     * @type {boolean}
     * @memberof Sale3AttributesConsumer
     */
    'is_anonima'?: boolean;
    /**
     *
     * @type {number}
     * @memberof Sale3AttributesConsumer
     */
    'grado_anonimato'?: number;
}
/**
 *
 * @export
 * @interface Sale3Data
 */
export interface Sale3Data {
    /**
     *
     * @type {string}
     * @memberof Sale3Data
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof Sale3Data
     */
    'id'?: string;
    /**
     *
     * @type {Sale3Attributes}
     * @memberof Sale3Data
     */
    'attributes'?: Sale3Attributes;
}
/**
 *
 * @export
 * @interface Sale4
 */
export interface Sale4 {
    /**
     *
     * @type {Sale4Data}
     * @memberof Sale4
     */
    'data'?: Sale4Data;
}
/**
 *
 * @export
 * @interface Sale4Data
 */
export interface Sale4Data {
    /**
     *
     * @type {string}
     * @memberof Sale4Data
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof Sale4Data
     */
    'id'?: string;
    /**
     *
     * @type {Sale4DataAttributes}
     * @memberof Sale4Data
     */
    'attributes'?: Sale4DataAttributes;
}
/**
 *
 * @export
 * @interface Sale4DataAttributes
 */
export interface Sale4DataAttributes {
    /**
     * sale counter
     * @type {number}
     * @memberof Sale4DataAttributes
     */
    'id_transazione'?: number;
    /**
     * pk_consumer
     * @type {string}
     * @memberof Sale4DataAttributes
     */
    'pk_consumer'?: string;
    /**
     * sid
     * @type {string}
     * @memberof Sale4DataAttributes
     */
    'sid'?: string;
    /**
     * tipo_applicazione_apertura
     * @type {string}
     * @memberof Sale4DataAttributes
     */
    'tipo_applicazione_apertura'?: string;
    /**
     * tipo_applicazione_chiusura
     * @type {string}
     * @memberof Sale4DataAttributes
     */
    'tipo_applicazione_chiusura'?: string;
    /**
     * cod_cassa
     * @type {string}
     * @memberof Sale4DataAttributes
     */
    'cod_cassa'?: string;
    /**
     * cod_cassiera
     * @type {string}
     * @memberof Sale4DataAttributes
     */
    'cod_cassiera'?: string;
    /**
     * cod_documento
     * @type {string}
     * @memberof Sale4DataAttributes
     */
    'cod_documento'?: string;
    /**
     * cod_negozio
     * @type {string}
     * @memberof Sale4DataAttributes
     */
    'cod_negozio'?: string;
    /**
     * cod_vettore
     * @type {string}
     * @memberof Sale4DataAttributes
     */
    'cod_vettore'?: string;
    /**
     * codice_movimento
     * @type {string}
     * @memberof Sale4DataAttributes
     */
    'codice_movimento'?: string;
    /**
     * codice_stato
     * @type {string}
     * @memberof Sale4DataAttributes
     */
    'codice_stato'?: string;
    /**
     * data_creazione
     * @type {string}
     * @memberof Sale4DataAttributes
     */
    'data_creazione'?: string;
    /**
     * data_documento
     * @type {string}
     * @memberof Sale4DataAttributes
     */
    'data_documento'?: string;
    /**
     * data_modifica
     * @type {string}
     * @memberof Sale4DataAttributes
     */
    'data_modifica'?: string;
    /**
     * dati_aggiuntivi
     * @type {string}
     * @memberof Sale4DataAttributes
     */
    'dati_aggiuntivi'?: string;
    /**
     * stringified object dati_documenti
     * @type {string}
     * @memberof Sale4DataAttributes
     */
    'dati_documenti'?: string;
    /**
     * divisa
     * @type {string}
     * @memberof Sale4DataAttributes
     */
    'divisa'?: string;
    /**
     * flag_stampa_documento
     * @type {number}
     * @memberof Sale4DataAttributes
     */
    'flag_stampa_documento'?: number;
    /**
     * ristampa_fiscale
     * @type {number}
     * @memberof Sale4DataAttributes
     */
    'ristampa_fiscale'?: number;
    /**
     * id_postazione_apertura
     * @type {string}
     * @memberof Sale4DataAttributes
     */
    'id_postazione_apertura'?: string;
    /**
     * id_postazione_chiusura
     * @type {string}
     * @memberof Sale4DataAttributes
     */
    'id_postazione_chiusura'?: string;
    /**
     * id_utente
     * @type {string}
     * @memberof Sale4DataAttributes
     */
    'id_utente'?: string;
    /**
     * importo_finale
     * @type {number}
     * @memberof Sale4DataAttributes
     */
    'importo_finale'?: number;
    /**
     * importo_iniziale
     * @type {number}
     * @memberof Sale4DataAttributes
     */
    'importo_iniziale'?: number;
    /**
     * importo_pagato
     * @type {number}
     * @memberof Sale4DataAttributes
     */
    'importo_pagato'?: number;
    /**
     * nota
     * @type {string}
     * @memberof Sale4DataAttributes
     */
    'nota'?: string;
    /**
     * numero_documento
     * @type {number}
     * @memberof Sale4DataAttributes
     */
    'numero_documento'?: number;
    /**
     * om_num_order
     * @type {string}
     * @memberof Sale4DataAttributes
     */
    'om_num_order'?: string;
    /**
     * numero_stampa_documento
     * @type {string}
     * @memberof Sale4DataAttributes
     */
    'numero_stampa_documento'?: string;
    /**
     * ora_documento
     * @type {string}
     * @memberof Sale4DataAttributes
     */
    'ora_documento'?: string;
    /**
     *
     * @type {Array<ApiV1SalesDataAttributesCapi>}
     * @memberof Sale4DataAttributes
     */
    'capi'?: Array<ApiV1SalesDataAttributesCapi>;
    /**
     *
     * @type {Array<ApiV1SalesDataAttributesPagamenti>}
     * @memberof Sale4DataAttributes
     */
    'pagamenti'?: Array<ApiV1SalesDataAttributesPagamenti>;
    /**
     *
     * @type {Sale4DataAttributesScorporo}
     * @memberof Sale4DataAttributes
     */
    'scorporo'?: Sale4DataAttributesScorporo;
    /**
     *
     * @type {string}
     * @memberof Sale4DataAttributes
     */
    'id_carrello'?: string;
    /**
     *
     * @type {Sale4DataAttributesPunti}
     * @memberof Sale4DataAttributes
     */
    'punti'?: Sale4DataAttributesPunti;
    /**
     *
     * @type {Sale4DataAttributesReprint}
     * @memberof Sale4DataAttributes
     */
    'reprint'?: Sale4DataAttributesReprint;
}
/**
 *
 * @export
 * @interface Sale4DataAttributesPunti
 */
export interface Sale4DataAttributesPunti {
    /**
     *
     * @type {number}
     * @memberof Sale4DataAttributesPunti
     */
    'guadagnati'?: number;
    /**
     *
     * @type {number}
     * @memberof Sale4DataAttributesPunti
     */
    'spesi'?: number;
    /**
     *
     * @type {number}
     * @memberof Sale4DataAttributesPunti
     */
    'nuovo_saldo'?: number;
}
/**
 *
 * @export
 * @interface Sale4DataAttributesReprint
 */
export interface Sale4DataAttributesReprint {
    /**
     *
     * @type {boolean}
     * @memberof Sale4DataAttributesReprint
     */
    'print_pdf_spedizione'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Sale4DataAttributesReprint
     */
    'reprint_fiscale'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Sale4DataAttributesReprint
     */
    'reprint_fattura_non_fiscale'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Sale4DataAttributesReprint
     */
    'reprint_non_fiscale'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Sale4DataAttributesReprint
     */
    'reprint_cortesia'?: boolean;
}
/**
 *
 * @export
 * @interface Sale4DataAttributesScorporo
 */
export interface Sale4DataAttributesScorporo {
    /**
     *
     * @type {Array<Sale4DataAttributesScorporoAliquote>}
     * @memberof Sale4DataAttributesScorporo
     */
    'aliquote'?: Array<Sale4DataAttributesScorporoAliquote>;
    /**
     *
     * @type {Array<object>}
     * @memberof Sale4DataAttributesScorporo
     */
    'totali_vendite'?: Array<object>;
    /**
     *
     * @type {string}
     * @memberof Sale4DataAttributesScorporo
     */
    'taxfree_document_id'?: string;
}
/**
 *
 * @export
 * @interface Sale4DataAttributesScorporoAliquote
 */
export interface Sale4DataAttributesScorporoAliquote {
    /**
     *
     * @type {number}
     * @memberof Sale4DataAttributesScorporoAliquote
     */
    'qta'?: number;
    /**
     *
     * @type {string}
     * @memberof Sale4DataAttributesScorporoAliquote
     */
    'imponibile'?: string;
    /**
     *
     * @type {string}
     * @memberof Sale4DataAttributesScorporoAliquote
     */
    'iva'?: string;
    /**
     *
     * @type {string}
     * @memberof Sale4DataAttributesScorporoAliquote
     */
    'imposta'?: string;
    /**
     *
     * @type {string}
     * @memberof Sale4DataAttributesScorporoAliquote
     */
    'prezzo_finale'?: string;
}
/**
 *
 * @export
 * @interface SaleAndFidelity
 */
export interface SaleAndFidelity {
    /**
     *
     * @type {Array<object>}
     * @memberof SaleAndFidelity
     */
    'data'?: Array<object>;
}
/**
 *
 * @export
 * @interface SaleCCPayment
 */
export interface SaleCCPayment {
    /**
     *
     * @type {ApiV1SalesSaleIdPaymentsPosPosTransactionIdData}
     * @memberof SaleCCPayment
     */
    'data'?: ApiV1SalesSaleIdPaymentsPosPosTransactionIdData;
}
/**
 *
 * @export
 * @interface SaleCCPaymentReversal
 */
export interface SaleCCPaymentReversal {
    /**
     *
     * @type {ApiV1SalesSaleIdPaymentsReversalPosPosTransactionIdData}
     * @memberof SaleCCPaymentReversal
     */
    'data'?: ApiV1SalesSaleIdPaymentsReversalPosPosTransactionIdData;
}
/**
 *
 * @export
 * @interface SaleData
 */
export interface SaleData {
    /**
     *
     * @type {string}
     * @memberof SaleData
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof SaleData
     */
    'id'?: string;
    /**
     *
     * @type {SaleDataAttributes}
     * @memberof SaleData
     */
    'attributes'?: SaleDataAttributes;
}
/**
 *
 * @export
 * @interface SaleDataAttributes
 */
export interface SaleDataAttributes {
    /**
     * sale counter
     * @type {number}
     * @memberof SaleDataAttributes
     */
    'id_transazione'?: number;
    /**
     * pk_consumer
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'pk_consumer'?: string;
    /**
     * sid
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'sid'?: string;
    /**
     * tipo_applicazione_apertura
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'tipo_applicazione_apertura'?: string;
    /**
     * tipo_applicazione_chiusura
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'tipo_applicazione_chiusura'?: string;
    /**
     * cod_cassa
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'cod_cassa'?: string;
    /**
     * cod_cassiera
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'cod_cassiera'?: string;
    /**
     * cod_documento
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'cod_documento'?: string;
    /**
     * cod_negozio
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'cod_negozio'?: string;
    /**
     * cod_vettore
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'cod_vettore'?: string;
    /**
     * codice_movimento
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'codice_movimento'?: string;
    /**
     * codice_stato
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'codice_stato'?: string;
    /**
     * data_creazione
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'data_creazione'?: string;
    /**
     * data_documento
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'data_documento'?: string;
    /**
     * data_modifica
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'data_modifica'?: string;
    /**
     * dati_aggiuntivi
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'dati_aggiuntivi'?: string;
    /**
     * stringified object dati_documenti
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'dati_documenti'?: string;
    /**
     * divisa
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'divisa'?: string;
    /**
     * flag_stampa_documento
     * @type {number}
     * @memberof SaleDataAttributes
     */
    'flag_stampa_documento'?: number;
    /**
     * id_postazione_apertura
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'id_postazione_apertura'?: string;
    /**
     * id_postazione_chiusura
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'id_postazione_chiusura'?: string;
    /**
     * id_utente
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'id_utente'?: string;
    /**
     * importo_finale
     * @type {number}
     * @memberof SaleDataAttributes
     */
    'importo_finale'?: number;
    /**
     * importo_iniziale
     * @type {number}
     * @memberof SaleDataAttributes
     */
    'importo_iniziale'?: number;
    /**
     * importo_pagato
     * @type {number}
     * @memberof SaleDataAttributes
     */
    'importo_pagato'?: number;
    /**
     * nota
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'nota'?: string;
    /**
     * numero_documento
     * @type {number}
     * @memberof SaleDataAttributes
     */
    'numero_documento'?: number;
    /**
     * om_num_order
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'om_num_order'?: string;
    /**
     * numero_stampa_documento
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'numero_stampa_documento'?: string;
    /**
     * ora_documento
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'ora_documento'?: string;
    /**
     *
     * @type {Array<ApiV1SalesDataAttributesCapi>}
     * @memberof SaleDataAttributes
     */
    'capi'?: Array<ApiV1SalesDataAttributesCapi>;
    /**
     *
     * @type {Array<ApiV1SalesDataAttributesPagamenti>}
     * @memberof SaleDataAttributes
     */
    'pagamenti'?: Array<ApiV1SalesDataAttributesPagamenti>;
    /**
     *
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'message'?: string;
    /**
     *
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'print_error_result'?: string;
    /**
     *
     * @type {string}
     * @memberof SaleDataAttributes
     */
    'warning_msg'?: string;
    /**
     *
     * @type {Array<Array<string>>}
     * @memberof SaleDataAttributes
     */
    'prints_to_retry'?: Array<Array<string>>;
}
/**
 *
 * @export
 * @interface SalesData
 */
export interface SalesData {
    /**
     *
     * @type {SalesDataData}
     * @memberof SalesData
     */
    'data'?: SalesDataData;
}
/**
 *
 * @export
 * @interface SalesDataData
 */
export interface SalesDataData {
    /**
     *
     * @type {string}
     * @memberof SalesDataData
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataData
     */
    'id'?: string;
    /**
     *
     * @type {SalesDataDataAttributes}
     * @memberof SalesDataData
     */
    'attributes'?: SalesDataDataAttributes;
}
/**
 *
 * @export
 * @interface SalesDataDataAttributes
 */
export interface SalesDataDataAttributes {
    /**
     *
     * @type {Array<SalesDataDataAttributesCapi>}
     * @memberof SalesDataDataAttributes
     */
    'capi'?: Array<SalesDataDataAttributesCapi>;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributes
     */
    'cod_cassa'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributes
     */
    'cod_cassiera'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributes
     */
    'cod_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributes
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributes
     */
    'cod_vettore'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributes
     */
    'codice_movimento'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributes
     */
    'codice_stato'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributes
     */
    'data_creazione'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributes
     */
    'data_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributes
     */
    'data_modifica'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributes
     */
    'dati_aggiuntivi'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributes
     */
    'dati_documenti'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributes
     */
    'divisa'?: string;
    /**
     *
     * @type {number}
     * @memberof SalesDataDataAttributes
     */
    'flag_stampa_documento'?: number;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributes
     */
    'id_postazione_apertura'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributes
     */
    'id_postazione_chiusura'?: string;
    /**
     *
     * @type {number}
     * @memberof SalesDataDataAttributes
     */
    'id_transazione'?: number;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributes
     */
    'id_utente'?: string;
    /**
     *
     * @type {number}
     * @memberof SalesDataDataAttributes
     */
    'importo_finale'?: number;
    /**
     *
     * @type {number}
     * @memberof SalesDataDataAttributes
     */
    'importo_iniziale'?: number;
    /**
     *
     * @type {number}
     * @memberof SalesDataDataAttributes
     */
    'importo_pagato'?: number;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributes
     */
    'nota'?: string;
    /**
     *
     * @type {number}
     * @memberof SalesDataDataAttributes
     */
    'numero_documento'?: number;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributes
     */
    'numero_stampa_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributes
     */
    'om_num_order'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributes
     */
    'ora_documento'?: string;
    /**
     *
     * @type {Array<object>}
     * @memberof SalesDataDataAttributes
     */
    'pagamenti'?: Array<object>;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributes
     */
    'pagamento_resto'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributes
     */
    'pk_consumer'?: string;
    /**
     *
     * @type {number}
     * @memberof SalesDataDataAttributes
     */
    'resto'?: number;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributes
     */
    'sid'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributes
     */
    'tipo_applicazione_apertura'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributes
     */
    'tipo_applicazione_chiusura'?: string;
}
/**
 *
 * @export
 * @interface SalesDataDataAttributesCapi
 */
export interface SalesDataDataAttributesCapi {
    /**
     *
     * @type {number}
     * @memberof SalesDataDataAttributesCapi
     */
    'id_transazione'?: number;
    /**
     *
     * @type {number}
     * @memberof SalesDataDataAttributesCapi
     */
    'progressivo'?: number;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributesCapi
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributesCapi
     */
    'codice_stato'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributesCapi
     */
    'codice_movimento'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributesCapi
     */
    'cod_commessa'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributesCapi
     */
    'sku'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributesCapi
     */
    'ean'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributesCapi
     */
    'rfid'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributesCapi
     */
    'sku_gruppo'?: string;
    /**
     *
     * @type {number}
     * @memberof SalesDataDataAttributesCapi
     */
    'flag_promo'?: number;
    /**
     *
     * @type {number}
     * @memberof SalesDataDataAttributesCapi
     */
    'flag_divisa'?: number;
    /**
     *
     * @type {number}
     * @memberof SalesDataDataAttributesCapi
     */
    'sku_created'?: number;
    /**
     *
     * @type {number}
     * @memberof SalesDataDataAttributesCapi
     */
    'sku_splitted'?: number;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributesCapi
     */
    'sku_read'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributesCapi
     */
    'nome'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributesCapi
     */
    'classe'?: string;
    /**
     *
     * @type {number}
     * @memberof SalesDataDataAttributesCapi
     */
    'iva'?: number;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributesCapi
     */
    'composizione'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributesCapi
     */
    'custom_data'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributesCapi
     */
    'nota'?: string;
    /**
     *
     * @type {number}
     * @memberof SalesDataDataAttributesCapi
     */
    'prezzo_listino_vendita'?: number;
    /**
     *
     * @type {number}
     * @memberof SalesDataDataAttributesCapi
     */
    'sconto_listino_vendita'?: number;
    /**
     *
     * @type {number}
     * @memberof SalesDataDataAttributesCapi
     */
    'importo_iniziale'?: number;
    /**
     *
     * @type {number}
     * @memberof SalesDataDataAttributesCapi
     */
    'importo_custom'?: number;
    /**
     *
     * @type {number}
     * @memberof SalesDataDataAttributesCapi
     */
    'sconto'?: number;
    /**
     *
     * @type {number}
     * @memberof SalesDataDataAttributesCapi
     */
    'correzione_importo'?: number;
    /**
     *
     * @type {number}
     * @memberof SalesDataDataAttributesCapi
     */
    'importo_finale'?: number;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributesCapi
     */
    'tipo_importo'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributesCapi
     */
    'tipologia_merce'?: string;
    /**
     *
     * @type {number}
     * @memberof SalesDataDataAttributesCapi
     */
    'reso'?: number;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributesCapi
     */
    'causale_reso'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributesCapi
     */
    'data_creazione'?: string;
    /**
     *
     * @type {string}
     * @memberof SalesDataDataAttributesCapi
     */
    'data_modifica'?: string;
    /**
     *
     * @type {number}
     * @memberof SalesDataDataAttributesCapi
     */
    'flag_tassabile'?: number;
}
/**
 *
 * @export
 * @interface Session
 */
export interface Session {
    /**
     *
     * @type {SessionData}
     * @memberof Session
     */
    'data'?: SessionData;
}
/**
 *
 * @export
 * @interface SessionData
 */
export interface SessionData {
    /**
     *
     * @type {string}
     * @memberof SessionData
     */
    'type'?: string;
    /**
     * Session unique id
     * @type {string}
     * @memberof SessionData
     */
    'id'?: string;
    /**
     *
     * @type {SessionDataAttributes}
     * @memberof SessionData
     */
    'attributes'?: SessionDataAttributes;
}
/**
 *
 * @export
 * @interface SessionDataAttributes
 */
export interface SessionDataAttributes {
    /**
     * User selected language, defaults on the store language
     * @type {string}
     * @memberof SessionDataAttributes
     */
    'language'?: string;
    /**
     * ISO code of the language field
     * @type {string}
     * @memberof SessionDataAttributes
     */
    'language_iso'?: string;
    /**
     *
     * @type {string}
     * @memberof SessionDataAttributes
     */
    'username'?: string;
}
/**
 *
 * @export
 * @interface StampaConfermata
 */
export interface StampaConfermata {
    /**
     *
     * @type {StampaConfermataData}
     * @memberof StampaConfermata
     */
    'data'?: StampaConfermataData;
}
/**
 *
 * @export
 * @interface StampaConfermataData
 */
export interface StampaConfermataData {
    /**
     *
     * @type {string}
     * @memberof StampaConfermataData
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof StampaConfermataData
     */
    'id'?: string;
    /**
     *
     * @type {StampaConfermataDataAttributes}
     * @memberof StampaConfermataData
     */
    'attributes'?: StampaConfermataDataAttributes;
}
/**
 *
 * @export
 * @interface StampaConfermataDataAttributes
 */
export interface StampaConfermataDataAttributes {
    /**
     *
     * @type {string}
     * @memberof StampaConfermataDataAttributes
     */
    'message'?: string;
}
/**
 *
 * @export
 * @interface StampaModuloAntiriciclaggio
 */
export interface StampaModuloAntiriciclaggio {
    /**
     *
     * @type {ApiV1SalesCheckDatiAntiriciclaggioData}
     * @memberof StampaModuloAntiriciclaggio
     */
    'data'?: ApiV1SalesCheckDatiAntiriciclaggioData;
}
/**
 *
 * @export
 * @interface StampaPdfRichiesta
 */
export interface StampaPdfRichiesta {
    /**
     *
     * @type {StampaPdfRichiestaData}
     * @memberof StampaPdfRichiesta
     */
    'data'?: StampaPdfRichiestaData;
}
/**
 *
 * @export
 * @interface StampaPdfRichiestaData
 */
export interface StampaPdfRichiestaData {
    /**
     *
     * @type {string}
     * @memberof StampaPdfRichiestaData
     */
    'type'?: string;
    /**
     *
     * @type {StampaPdfRichiestaDataAttributes}
     * @memberof StampaPdfRichiestaData
     */
    'attributes'?: StampaPdfRichiestaDataAttributes;
}
/**
 *
 * @export
 * @interface StampaPdfRichiestaDataAttributes
 */
export interface StampaPdfRichiestaDataAttributes {
    /**
     *
     * @type {string}
     * @memberof StampaPdfRichiestaDataAttributes
     */
    'pdf_content'?: string;
    /**
     *
     * @type {string}
     * @memberof StampaPdfRichiestaDataAttributes
     */
    'file_name'?: string;
}
/**
 *
 * @export
 * @interface StoreInfo
 */
export interface StoreInfo {
    /**
     *
     * @type {StoreInfoData}
     * @memberof StoreInfo
     */
    'data'?: StoreInfoData;
}
/**
 *
 * @export
 * @interface StoreInfoData
 */
export interface StoreInfoData {
    /**
     *
     * @type {string}
     * @memberof StoreInfoData
     */
    'type'?: string;
    /**
     *
     * @type {StoreInfoDataAttributes}
     * @memberof StoreInfoData
     */
    'attributes'?: StoreInfoDataAttributes;
}
/**
 *
 * @export
 * @interface StoreInfoDataAttributes
 */
export interface StoreInfoDataAttributes {
    /**
     *
     * @type {string}
     * @memberof StoreInfoDataAttributes
     */
    'ragione_sociale'?: string;
    /**
     *
     * @type {string}
     * @memberof StoreInfoDataAttributes
     */
    'indirizzo_ditta'?: string;
    /**
     *
     * @type {string}
     * @memberof StoreInfoDataAttributes
     */
    'cap_ditta'?: string;
    /**
     *
     * @type {string}
     * @memberof StoreInfoDataAttributes
     */
    'citta_ditta'?: string;
}
/**
 *
 * @export
 * @interface StrumentiChangeDate
 */
export interface StrumentiChangeDate {
    /**
     *
     * @type {ApiV1StrumentiChangeDateData}
     * @memberof StrumentiChangeDate
     */
    'data'?: ApiV1StrumentiChangeDateData;
}
/**
 *
 * @export
 * @interface StrumentiConcessioneAutorizzazione
 */
export interface StrumentiConcessioneAutorizzazione {
    /**
     *
     * @type {StrumentiConcessioneAutorizzazioneData}
     * @memberof StrumentiConcessioneAutorizzazione
     */
    'data'?: StrumentiConcessioneAutorizzazioneData;
}
/**
 *
 * @export
 * @interface StrumentiConcessioneAutorizzazioneData
 */
export interface StrumentiConcessioneAutorizzazioneData {
    /**
     *
     * @type {string}
     * @memberof StrumentiConcessioneAutorizzazioneData
     */
    'type'?: string;
    /**
     *
     * @type {StrumentiConcessioneAutorizzazioneDataAttributes}
     * @memberof StrumentiConcessioneAutorizzazioneData
     */
    'attributes'?: StrumentiConcessioneAutorizzazioneDataAttributes;
}
/**
 *
 * @export
 * @interface StrumentiConcessioneAutorizzazioneDataAttributes
 */
export interface StrumentiConcessioneAutorizzazioneDataAttributes {
    /**
     *
     * @type {Array<string>}
     * @memberof StrumentiConcessioneAutorizzazioneDataAttributes
     */
    'messages'?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof StrumentiConcessioneAutorizzazioneDataAttributes
     */
    'buttons'?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof StrumentiConcessioneAutorizzazioneDataAttributes
     */
    'funzione'?: string;
    /**
     *
     * @type {number}
     * @memberof StrumentiConcessioneAutorizzazioneDataAttributes
     */
    'numero_iniziale'?: number;
}
/**
 *
 * @export
 * @interface StrumentiConfigurazionePostazione
 */
export interface StrumentiConfigurazionePostazione {
    /**
     *
     * @type {ApiV1StrumentiBundleEditData}
     * @memberof StrumentiConfigurazionePostazione
     */
    'data'?: ApiV1StrumentiBundleEditData;
}
/**
 *
 * @export
 * @interface StrumentiControlloAutorizzazione
 */
export interface StrumentiControlloAutorizzazione {
    /**
     *
     * @type {StrumentiControlloAutorizzazioneData}
     * @memberof StrumentiControlloAutorizzazione
     */
    'data'?: StrumentiControlloAutorizzazioneData;
}
/**
 *
 * @export
 * @interface StrumentiControlloAutorizzazioneData
 */
export interface StrumentiControlloAutorizzazioneData {
    /**
     *
     * @type {string}
     * @memberof StrumentiControlloAutorizzazioneData
     */
    'type'?: string;
    /**
     *
     * @type {StrumentiControlloAutorizzazioneDataAttributes}
     * @memberof StrumentiControlloAutorizzazioneData
     */
    'attributes'?: StrumentiControlloAutorizzazioneDataAttributes;
}
/**
 *
 * @export
 * @interface StrumentiControlloAutorizzazioneDataAttributes
 */
export interface StrumentiControlloAutorizzazioneDataAttributes {
    /**
     *
     * @type {string}
     * @memberof StrumentiControlloAutorizzazioneDataAttributes
     */
    'status'?: string;
    /**
     *
     * @type {StrumentiControlloAutorizzazioneDataAttributesData}
     * @memberof StrumentiControlloAutorizzazioneDataAttributes
     */
    'data'?: StrumentiControlloAutorizzazioneDataAttributesData;
    /**
     *
     * @type {string}
     * @memberof StrumentiControlloAutorizzazioneDataAttributes
     */
    'message'?: string;
}
/**
 *
 * @export
 * @interface StrumentiControlloAutorizzazioneDataAttributesData
 */
export interface StrumentiControlloAutorizzazioneDataAttributesData {
    /**
     *
     * @type {string}
     * @memberof StrumentiControlloAutorizzazioneDataAttributesData
     */
    'numero_iniziale'?: string;
    /**
     *
     * @type {number}
     * @memberof StrumentiControlloAutorizzazioneDataAttributesData
     */
    'codice_autorizzazione'?: number;
}
/**
 *
 * @export
 * @interface StrumentiControlloCodiceAutorizzazione
 */
export interface StrumentiControlloCodiceAutorizzazione {
    /**
     *
     * @type {ApiV1StrumentiCheckCodiceAutorizzazioneData}
     * @memberof StrumentiControlloCodiceAutorizzazione
     */
    'data'?: ApiV1StrumentiCheckCodiceAutorizzazioneData;
}
/**
 *
 * @export
 * @interface StrumentiEsitoChangeDate
 */
export interface StrumentiEsitoChangeDate {
    /**
     *
     * @type {StrumentiEsitoChangeDateData}
     * @memberof StrumentiEsitoChangeDate
     */
    'data'?: StrumentiEsitoChangeDateData;
}
/**
 *
 * @export
 * @interface StrumentiEsitoChangeDateData
 */
export interface StrumentiEsitoChangeDateData {
    /**
     *
     * @type {string}
     * @memberof StrumentiEsitoChangeDateData
     */
    'type'?: string;
    /**
     *
     * @type {StrumentiEsitoChangeDateDataAttributes}
     * @memberof StrumentiEsitoChangeDateData
     */
    'attributes'?: StrumentiEsitoChangeDateDataAttributes;
}
/**
 *
 * @export
 * @interface StrumentiEsitoChangeDateDataAttributes
 */
export interface StrumentiEsitoChangeDateDataAttributes {
    /**
     *
     * @type {string}
     * @memberof StrumentiEsitoChangeDateDataAttributes
     */
    'status'?: StrumentiEsitoChangeDateDataAttributesStatusEnum;
    /**
     *
     * @type {string}
     * @memberof StrumentiEsitoChangeDateDataAttributes
     */
    'message'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum StrumentiEsitoChangeDateDataAttributesStatusEnum {
    Success = 'SUCCESS',
    Error = 'ERROR'
}

/**
 *
 * @export
 * @interface StrumentiEsitoConfigurazione
 */
export interface StrumentiEsitoConfigurazione {
    /**
     *
     * @type {StrumentiEsitoConfigurazioneData}
     * @memberof StrumentiEsitoConfigurazione
     */
    'data'?: StrumentiEsitoConfigurazioneData;
}
/**
 *
 * @export
 * @interface StrumentiEsitoConfigurazioneData
 */
export interface StrumentiEsitoConfigurazioneData {
    /**
     *
     * @type {string}
     * @memberof StrumentiEsitoConfigurazioneData
     */
    'type'?: string;
    /**
     *
     * @type {StrumentiEsitoConfigurazioneDataAttributes}
     * @memberof StrumentiEsitoConfigurazioneData
     */
    'attributes'?: StrumentiEsitoConfigurazioneDataAttributes;
}
/**
 *
 * @export
 * @interface StrumentiEsitoConfigurazioneDataAttributes
 */
export interface StrumentiEsitoConfigurazioneDataAttributes {
    /**
     *
     * @type {string}
     * @memberof StrumentiEsitoConfigurazioneDataAttributes
     */
    'data'?: string;
    /**
     *
     * @type {string}
     * @memberof StrumentiEsitoConfigurazioneDataAttributes
     */
    'message'?: string;
}
/**
 *
 * @export
 * @interface StrumentiEsitoInvioComandiStampante
 */
export interface StrumentiEsitoInvioComandiStampante {
    /**
     *
     * @type {StrumentiEsitoInvioComandiStampanteData}
     * @memberof StrumentiEsitoInvioComandiStampante
     */
    'data'?: StrumentiEsitoInvioComandiStampanteData;
}
/**
 *
 * @export
 * @interface StrumentiEsitoInvioComandiStampanteData
 */
export interface StrumentiEsitoInvioComandiStampanteData {
    /**
     *
     * @type {string}
     * @memberof StrumentiEsitoInvioComandiStampanteData
     */
    'type'?: string;
    /**
     *
     * @type {StrumentiEsitoInvioComandiStampanteDataAttributes}
     * @memberof StrumentiEsitoInvioComandiStampanteData
     */
    'attributes'?: StrumentiEsitoInvioComandiStampanteDataAttributes;
}
/**
 *
 * @export
 * @interface StrumentiEsitoInvioComandiStampanteDataAttributes
 */
export interface StrumentiEsitoInvioComandiStampanteDataAttributes {
    /**
     *
     * @type {string}
     * @memberof StrumentiEsitoInvioComandiStampanteDataAttributes
     */
    'status'?: string;
    /**
     *
     * @type {string}
     * @memberof StrumentiEsitoInvioComandiStampanteDataAttributes
     */
    'message'?: string;
}
/**
 *
 * @export
 * @interface StrumentiEsitoStampaTotali
 */
export interface StrumentiEsitoStampaTotali {
    /**
     *
     * @type {StrumentiEsitoStampaTotaliData}
     * @memberof StrumentiEsitoStampaTotali
     */
    'data'?: StrumentiEsitoStampaTotaliData;
}
/**
 *
 * @export
 * @interface StrumentiEsitoStampaTotaliData
 */
export interface StrumentiEsitoStampaTotaliData {
    /**
     *
     * @type {string}
     * @memberof StrumentiEsitoStampaTotaliData
     */
    'type'?: string;
    /**
     *
     * @type {StrumentiEsitoStampaTotaliDataAttributes}
     * @memberof StrumentiEsitoStampaTotaliData
     */
    'attributes'?: StrumentiEsitoStampaTotaliDataAttributes;
}
/**
 *
 * @export
 * @interface StrumentiEsitoStampaTotaliDataAttributes
 */
export interface StrumentiEsitoStampaTotaliDataAttributes {
    /**
     *
     * @type {string}
     * @memberof StrumentiEsitoStampaTotaliDataAttributes
     */
    'status'?: StrumentiEsitoStampaTotaliDataAttributesStatusEnum;
    /**
     *
     * @type {string}
     * @memberof StrumentiEsitoStampaTotaliDataAttributes
     */
    'result'?: string;
    /**
     *
     * @type {string}
     * @memberof StrumentiEsitoStampaTotaliDataAttributes
     */
    'message'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum StrumentiEsitoStampaTotaliDataAttributesStatusEnum {
    Success = 'SUCCESS',
    Error = 'ERROR'
}

/**
 *
 * @export
 * @interface StrumentiForceCashClosure
 */
export interface StrumentiForceCashClosure {
    /**
     *
     * @type {ApiV1StrumentiForceCashClosureData}
     * @memberof StrumentiForceCashClosure
     */
    'data'?: ApiV1StrumentiForceCashClosureData;
}
/**
 *
 * @export
 * @interface StrumentiGetConfigurazione
 */
export interface StrumentiGetConfigurazione {
    /**
     *
     * @type {StrumentiGetConfigurazioneData}
     * @memberof StrumentiGetConfigurazione
     */
    'data'?: StrumentiGetConfigurazioneData;
}
/**
 *
 * @export
 * @interface StrumentiGetConfigurazioneData
 */
export interface StrumentiGetConfigurazioneData {
    /**
     *
     * @type {string}
     * @memberof StrumentiGetConfigurazioneData
     */
    'type'?: string;
    /**
     *
     * @type {Array<StrumentiGetConfigurazioneDataAttributes>}
     * @memberof StrumentiGetConfigurazioneData
     */
    'attributes'?: Array<StrumentiGetConfigurazioneDataAttributes>;
}
/**
 *
 * @export
 * @interface StrumentiGetConfigurazioneDataAttributes
 */
export interface StrumentiGetConfigurazioneDataAttributes {
    /**
     *
     * @type {string}
     * @memberof StrumentiGetConfigurazioneDataAttributes
     */
    'param'?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof StrumentiGetConfigurazioneDataAttributes
     */
    'values'?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof StrumentiGetConfigurazioneDataAttributes
     */
    'label'?: string;
    /**
     *
     * @type {string}
     * @memberof StrumentiGetConfigurazioneDataAttributes
     */
    'current'?: string;
}
/**
 *
 * @export
 * @interface StrumentiInvioComandiAllaStampante
 */
export interface StrumentiInvioComandiAllaStampante {
    /**
     *
     * @type {ApiV1StrumentiSendCommandToCustomPrinterData}
     * @memberof StrumentiInvioComandiAllaStampante
     */
    'data'?: ApiV1StrumentiSendCommandToCustomPrinterData;
}
/**
 *
 * @export
 * @interface StrumentiRemovalSid
 */
export interface StrumentiRemovalSid {
    /**
     *
     * @type {StrumentiRemovalSidData}
     * @memberof StrumentiRemovalSid
     */
    'data'?: StrumentiRemovalSidData;
}
/**
 *
 * @export
 * @interface StrumentiRemovalSidData
 */
export interface StrumentiRemovalSidData {
    /**
     *
     * @type {string}
     * @memberof StrumentiRemovalSidData
     */
    'type'?: string;
    /**
     *
     * @type {StrumentiRemovalSidDataAttributes}
     * @memberof StrumentiRemovalSidData
     */
    'attributes'?: StrumentiRemovalSidDataAttributes;
}
/**
 *
 * @export
 * @interface StrumentiRemovalSidDataAttributes
 */
export interface StrumentiRemovalSidDataAttributes {
    /**
     *
     * @type {string}
     * @memberof StrumentiRemovalSidDataAttributes
     */
    'status'?: StrumentiRemovalSidDataAttributesStatusEnum;
    /**
     *
     * @type {string}
     * @memberof StrumentiRemovalSidDataAttributes
     */
    'message'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum StrumentiRemovalSidDataAttributesStatusEnum {
    Success = 'SUCCESS',
    Error = 'ERROR'
}

/**
 *
 * @export
 * @interface StrumentiResponseCashList
 */
export interface StrumentiResponseCashList {
    /**
     *
     * @type {StrumentiResponseCashListData}
     * @memberof StrumentiResponseCashList
     */
    'data'?: StrumentiResponseCashListData;
}
/**
 *
 * @export
 * @interface StrumentiResponseCashListData
 */
export interface StrumentiResponseCashListData {
    /**
     *
     * @type {string}
     * @memberof StrumentiResponseCashListData
     */
    'type'?: string;
    /**
     *
     * @type {Array<StrumentiResponseCashListDataAttributes>}
     * @memberof StrumentiResponseCashListData
     */
    'attributes'?: Array<StrumentiResponseCashListDataAttributes>;
}
/**
 *
 * @export
 * @interface StrumentiResponseCashListDataAttributes
 */
export interface StrumentiResponseCashListDataAttributes {
    /**
     *
     * @type {string}
     * @memberof StrumentiResponseCashListDataAttributes
     */
    'cash_rols'?: string;
    /**
     *
     * @type {string}
     * @memberof StrumentiResponseCashListDataAttributes
     */
    'cash_status'?: string;
    /**
     *
     * @type {string}
     * @memberof StrumentiResponseCashListDataAttributes
     */
    'cod_cassa'?: string;
    /**
     *
     * @type {string}
     * @memberof StrumentiResponseCashListDataAttributes
     */
    'cod_negozio'?: string;
}
/**
 *
 * @export
 * @interface StrumentiResponseForceCashClosure
 */
export interface StrumentiResponseForceCashClosure {
    /**
     *
     * @type {StrumentiResponseForceCashClosureData}
     * @memberof StrumentiResponseForceCashClosure
     */
    'data'?: StrumentiResponseForceCashClosureData;
}
/**
 *
 * @export
 * @interface StrumentiResponseForceCashClosureData
 */
export interface StrumentiResponseForceCashClosureData {
    /**
     *
     * @type {string}
     * @memberof StrumentiResponseForceCashClosureData
     */
    'type'?: string;
    /**
     *
     * @type {InlineResponse2001DataAttributes}
     * @memberof StrumentiResponseForceCashClosureData
     */
    'attributes'?: InlineResponse2001DataAttributes;
}
/**
 *
 * @export
 * @interface StrumentiResponseSezioniAbilitate
 */
export interface StrumentiResponseSezioniAbilitate {
    /**
     *
     * @type {StrumentiResponseSezioniAbilitateData}
     * @memberof StrumentiResponseSezioniAbilitate
     */
    'data'?: StrumentiResponseSezioniAbilitateData;
}
/**
 *
 * @export
 * @interface StrumentiResponseSezioniAbilitateData
 */
export interface StrumentiResponseSezioniAbilitateData {
    /**
     *
     * @type {string}
     * @memberof StrumentiResponseSezioniAbilitateData
     */
    'type'?: string;
    /**
     *
     * @type {StrumentiResponseSezioniAbilitateDataAttributes}
     * @memberof StrumentiResponseSezioniAbilitateData
     */
    'attributes'?: StrumentiResponseSezioniAbilitateDataAttributes;
}
/**
 *
 * @export
 * @interface StrumentiResponseSezioniAbilitateDataAttributes
 */
export interface StrumentiResponseSezioniAbilitateDataAttributes {
    /**
     *
     * @type {string}
     * @memberof StrumentiResponseSezioniAbilitateDataAttributes
     */
    'user_role'?: string;
    /**
     *
     * @type {boolean}
     * @memberof StrumentiResponseSezioniAbilitateDataAttributes
     */
    'test_periferiche'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof StrumentiResponseSezioniAbilitateDataAttributes
     */
    'cancellazione_sid'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof StrumentiResponseSezioniAbilitateDataAttributes
     */
    'registratore_cassa'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof StrumentiResponseSezioniAbilitateDataAttributes
     */
    'configurazione_avanzata_cambio_data'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof StrumentiResponseSezioniAbilitateDataAttributes
     */
    'configurazione_avanzata_configurazione_postazione'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof StrumentiResponseSezioniAbilitateDataAttributes
     */
    'chiusura_casse'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof StrumentiResponseSezioniAbilitateDataAttributes
     */
    'utility_stampante_custom'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof StrumentiResponseSezioniAbilitateDataAttributes
     */
    'traduzioni'?: boolean;
}
/**
 *
 * @export
 * @interface StrumentiRichiestaAutorizzazione
 */
export interface StrumentiRichiestaAutorizzazione {
    /**
     *
     * @type {ApiV1StrumentiGetCodiceAutorizzazioneData}
     * @memberof StrumentiRichiestaAutorizzazione
     */
    'data'?: ApiV1StrumentiGetCodiceAutorizzazioneData;
}
/**
 *
 * @export
 * @interface StrumentiSetKubeDate
 */
export interface StrumentiSetKubeDate {
    /**
     *
     * @type {StrumentiSetKubeDateData}
     * @memberof StrumentiSetKubeDate
     */
    'data'?: StrumentiSetKubeDateData;
}
/**
 *
 * @export
 * @interface StrumentiSetKubeDateData
 */
export interface StrumentiSetKubeDateData {
    /**
     *
     * @type {string}
     * @memberof StrumentiSetKubeDateData
     */
    'type'?: string;
    /**
     *
     * @type {StrumentiSetKubeDateDataAttributes}
     * @memberof StrumentiSetKubeDateData
     */
    'attributes'?: StrumentiSetKubeDateDataAttributes;
}
/**
 *
 * @export
 * @interface StrumentiSetKubeDateDataAttributes
 */
export interface StrumentiSetKubeDateDataAttributes {
    /**
     *
     * @type {string}
     * @memberof StrumentiSetKubeDateDataAttributes
     */
    'status'?: StrumentiSetKubeDateDataAttributesStatusEnum;
    /**
     *
     * @type {string}
     * @memberof StrumentiSetKubeDateDataAttributes
     */
    'message'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum StrumentiSetKubeDateDataAttributesStatusEnum {
    Success = 'SUCCESS',
    Error = 'ERROR'
}

/**
 *
 * @export
 * @interface StrumentiStampaChiusuraFallita
 */
export interface StrumentiStampaChiusuraFallita {
    /**
     *
     * @type {StrumentiStampaChiusuraFallitaData}
     * @memberof StrumentiStampaChiusuraFallita
     */
    'data'?: StrumentiStampaChiusuraFallitaData;
}
/**
 *
 * @export
 * @interface StrumentiStampaChiusuraFallitaData
 */
export interface StrumentiStampaChiusuraFallitaData {
    /**
     *
     * @type {string}
     * @memberof StrumentiStampaChiusuraFallitaData
     */
    'type'?: string;
    /**
     *
     * @type {StrumentiStampaChiusuraFallitaDataAttributes}
     * @memberof StrumentiStampaChiusuraFallitaData
     */
    'attributes'?: StrumentiStampaChiusuraFallitaDataAttributes;
}
/**
 *
 * @export
 * @interface StrumentiStampaChiusuraFallitaDataAttributes
 */
export interface StrumentiStampaChiusuraFallitaDataAttributes {
    /**
     *
     * @type {string}
     * @memberof StrumentiStampaChiusuraFallitaDataAttributes
     */
    'result'?: string;
    /**
     *
     * @type {string}
     * @memberof StrumentiStampaChiusuraFallitaDataAttributes
     */
    'message'?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof StrumentiStampaChiusuraFallitaDataAttributes
     */
    'sequence'?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof StrumentiStampaChiusuraFallitaDataAttributes
     */
    'action'?: string;
    /**
     *
     * @type {string}
     * @memberof StrumentiStampaChiusuraFallitaDataAttributes
     */
    'message2'?: string;
}
/**
 *
 * @export
 * @interface StrumentiStampaTotaliPos
 */
export interface StrumentiStampaTotaliPos {
    /**
     *
     * @type {ApiV1StrumentiStampaTotaliPosData}
     * @memberof StrumentiStampaTotaliPos
     */
    'data'?: ApiV1StrumentiStampaTotaliPosData;
}
/**
 *
 * @export
 * @interface StrumentiTestKube
 */
export interface StrumentiTestKube {
    /**
     *
     * @type {StrumentiTestKubeData}
     * @memberof StrumentiTestKube
     */
    'data'?: StrumentiTestKubeData;
}
/**
 *
 * @export
 * @interface StrumentiTestKubeData
 */
export interface StrumentiTestKubeData {
    /**
     *
     * @type {string}
     * @memberof StrumentiTestKubeData
     */
    'type'?: string;
    /**
     *
     * @type {StrumentiTestKubeDataAttributes}
     * @memberof StrumentiTestKubeData
     */
    'attributes'?: StrumentiTestKubeDataAttributes;
}
/**
 *
 * @export
 * @interface StrumentiTestKubeDataAttributes
 */
export interface StrumentiTestKubeDataAttributes {
    /**
     * file pdf content base64
     * @type {string}
     * @memberof StrumentiTestKubeDataAttributes
     */
    'status'?: StrumentiTestKubeDataAttributesStatusEnum;
    /**
     *
     * @type {string}
     * @memberof StrumentiTestKubeDataAttributes
     */
    'message'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum StrumentiTestKubeDataAttributesStatusEnum {
    Success = 'SUCCESS',
    Error = 'ERROR'
}

/**
 *
 * @export
 * @interface SuspendedCreditCCPayment
 */
export interface SuspendedCreditCCPayment {
    /**
     *
     * @type {ApiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdData}
     * @memberof SuspendedCreditCCPayment
     */
    'data'?: ApiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdData;
}
/**
 *
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     *
     * @type {ApiV1ReturnData}
     * @memberof Transaction
     */
    'data'?: ApiV1ReturnData;
}
/**
 *
 * @export
 * @interface TransactionNewID
 */
export interface TransactionNewID {
    /**
     *
     * @type {TransactionNewIDData}
     * @memberof TransactionNewID
     */
    'data'?: TransactionNewIDData;
}
/**
 *
 * @export
 * @interface TransactionNewIDData
 */
export interface TransactionNewIDData {
    /**
     *
     * @type {string}
     * @memberof TransactionNewIDData
     */
    'type'?: string;
    /**
     *
     * @type {TransactionNewIDDataAttributes}
     * @memberof TransactionNewIDData
     */
    'attributes'?: TransactionNewIDDataAttributes;
}
/**
 *
 * @export
 * @interface TransactionNewIDDataAttributes
 */
export interface TransactionNewIDDataAttributes {
    /**
     *
     * @type {number}
     * @memberof TransactionNewIDDataAttributes
     */
    'id_transazione'?: number;
}
/**
 *
 * @export
 * @interface Values
 */
export interface Values {
    /**
     *
     * @type {ValuesData}
     * @memberof Values
     */
    'data'?: ValuesData;
}
/**
 *
 * @export
 * @interface ValuesData
 */
export interface ValuesData {
    /**
     *
     * @type {string}
     * @memberof ValuesData
     */
    'id'?: string;
    /**
     * Fixed string
     * @type {string}
     * @memberof ValuesData
     */
    'type'?: string;
    /**
     *
     * @type {ValuesDataAttributes}
     * @memberof ValuesData
     */
    'attributes'?: ValuesDataAttributes;
}
/**
 *
 * @export
 * @interface ValuesDataAttributes
 */
export interface ValuesDataAttributes {
    /**
     *
     * @type {string}
     * @memberof ValuesDataAttributes
     */
    'versione'?: string;
    /**
     *
     * @type {string}
     * @memberof ValuesDataAttributes
     */
    'codice_negozio'?: string;
    /**
     *
     * @type {number}
     * @memberof ValuesDataAttributes
     */
    'numero_documento'?: number;
    /**
     *
     * @type {string}
     * @memberof ValuesDataAttributes
     */
    'data_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof ValuesDataAttributes
     */
    'flag_negozio_b2e'?: string;
    /**
     *
     * @type {string}
     * @memberof ValuesDataAttributes
     */
    'padding'?: string;
    /**
     *
     * @type {string}
     * @memberof ValuesDataAttributes
     */
    'barcode'?: string;
}
/**
 *
 * @export
 * @interface Versamento
 */
export interface Versamento {
    /**
     * Bank deposit detail
     * @type {Array<VersamentoData>}
     * @memberof Versamento
     */
    'data'?: Array<VersamentoData>;
}
/**
 *
 * @export
 * @interface VersamentoAttributes
 */
export interface VersamentoAttributes {
    /**
     *
     * @type {string}
     * @memberof VersamentoAttributes
     */
    'cod_negozio'?: string;
    /**
     *
     * @type {number}
     * @memberof VersamentoAttributes
     */
    'numero_documento'?: number;
    /**
     *
     * @type {string}
     * @memberof VersamentoAttributes
     */
    'data_documento'?: string;
    /**
     *
     * @type {string}
     * @memberof VersamentoAttributes
     */
    'ora_documento'?: string;
    /**
     *
     * @type {number}
     * @memberof VersamentoAttributes
     */
    'progressivo'?: number;
    /**
     *
     * @type {string}
     * @memberof VersamentoAttributes
     */
    'cod_cassiera'?: string;
    /**
     *
     * @type {string}
     * @memberof VersamentoAttributes
     */
    'codice_banca'?: string;
    /**
     *
     * @type {number}
     * @memberof VersamentoAttributes
     */
    'importo'?: number;
    /**
     *
     * @type {string}
     * @memberof VersamentoAttributes
     */
    'importo_divisa_vers'?: string;
    /**
     *
     * @type {string}
     * @memberof VersamentoAttributes
     */
    'divisa_versamento'?: string;
    /**
     *
     * @type {string}
     * @memberof VersamentoAttributes
     */
    'dati'?: string;
    /**
     *
     * @type {string}
     * @memberof VersamentoAttributes
     */
    'data_versamento'?: string;
    /**
     *
     * @type {string}
     * @memberof VersamentoAttributes
     */
    'data_valuta'?: string;
    /**
     *
     * @type {string}
     * @memberof VersamentoAttributes
     */
    'tipo_versamento'?: string;
    /**
     *
     * @type {string}
     * @memberof VersamentoAttributes
     */
    'data_modifica'?: string;
}
/**
 *
 * @export
 * @interface VersamentoData
 */
export interface VersamentoData {
    /**
     *
     * @type {string}
     * @memberof VersamentoData
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof VersamentoData
     */
    'id'?: string;
    /**
     *
     * @type {VersamentoAttributes}
     * @memberof VersamentoData
     */
    'attributes'?: VersamentoAttributes;
}

/**
 * AccontiApi - axios parameter creator
 * @export
 */
export const AccontiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Dettagli acconto
         * @summary Chiamata per avere i dettagli dell\'acconto
         * @param {string} codNegozioidTransazione codice negozio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LaybyCodNegozioidTransazioneGet: async (codNegozioidTransazione: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'codNegozioidTransazione' is not null or undefined
            assertParamExists('apiV1LaybyCodNegozioidTransazioneGet', 'codNegozioidTransazione', codNegozioidTransazione)
            const localVarPath = `/api/v1/layby/{cod_negozio,id_transazione}`
                .replace(`{${"cod_negozio,id_transazione"}}`, encodeURIComponent(String(codNegozioidTransazione)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ricerca di acconti tramite un serie di parametri
         * @summary Chiamata per ricercare gli acconti in base a parametri
         * @param {string} [filterDataDa] filtro per data minima dell\&#39;acconto
         * @param {string} [filterDataA] filtro per data massima dell\&#39;acconto
         * @param {string} [filterNumeroDocumento] filtro per numero documento
         * @param {'OPEN' | 'FINALIZED' | 'VOIDED' | 'EXPIRED' | 'CANCELLED'} [filterStato] filtro per stato
         * @param {string} [filterDataScadenzaDa] filtro per data scadenza minima
         * @param {string} [filterDataScadenzaA] filtro per data scadenza massima
         * @param {string} [filterConsumatrice] filtro per lettere nel nome cognome consumatrice
         * @param {string} [filterProssimoPagamentoDa] filtro per data prossimo pagamento minima
         * @param {string} [filterProssimoPagamentoA] filtro per data prossimo pagamento massima
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LaybyGet: async (filterDataDa?: string, filterDataA?: string, filterNumeroDocumento?: string, filterStato?: 'OPEN' | 'FINALIZED' | 'VOIDED' | 'EXPIRED' | 'CANCELLED', filterDataScadenzaDa?: string, filterDataScadenzaA?: string, filterConsumatrice?: string, filterProssimoPagamentoDa?: string, filterProssimoPagamentoA?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/layby`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterDataDa !== undefined) {
                localVarQueryParameter['filter[data_da]'] = filterDataDa;
            }

            if (filterDataA !== undefined) {
                localVarQueryParameter['filter[data_a]'] = filterDataA;
            }

            if (filterNumeroDocumento !== undefined) {
                localVarQueryParameter['filter[numero_documento]'] = filterNumeroDocumento;
            }

            if (filterStato !== undefined) {
                localVarQueryParameter['filter[stato]'] = filterStato;
            }

            if (filterDataScadenzaDa !== undefined) {
                localVarQueryParameter['filter[data_scadenza_da]'] = filterDataScadenzaDa;
            }

            if (filterDataScadenzaA !== undefined) {
                localVarQueryParameter['filter[data_scadenza_a]'] = filterDataScadenzaA;
            }

            if (filterConsumatrice !== undefined) {
                localVarQueryParameter['filter[consumatrice]'] = filterConsumatrice;
            }

            if (filterProssimoPagamentoDa !== undefined) {
                localVarQueryParameter['filter[prossimo_pagamento_da]'] = filterProssimoPagamentoDa;
            }

            if (filterProssimoPagamentoA !== undefined) {
                localVarQueryParameter['filter[prossimo_pagamento_a]'] = filterProssimoPagamentoA;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ristampa dell\' acconto
         * @summary Chiamata per avere la ristampa documento acconto
         * @param {string} codNegozioidTransazione codice negozio e id_transazione
         * @param {0 | 1} [fiscale] richiesta di ristampa bollettina se 0, ristampa fiscale nel caso 1 (default 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LaybyRistampaDocumentoCodNegozioidTransazioneGet: async (codNegozioidTransazione: string, fiscale?: 0 | 1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'codNegozioidTransazione' is not null or undefined
            assertParamExists('apiV1LaybyRistampaDocumentoCodNegozioidTransazioneGet', 'codNegozioidTransazione', codNegozioidTransazione)
            const localVarPath = `/api/v1/layby/ristampa-documento/{cod_negozio,id_transazione}`
                .replace(`{${"cod_negozio,id_transazione"}}`, encodeURIComponent(String(codNegozioidTransazione)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fiscale !== undefined) {
                localVarQueryParameter['fiscale'] = fiscale;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccontiApi - functional programming interface
 * @export
 */
export const AccontiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccontiApiAxiosParamCreator(configuration)
    return {
        /**
         * Dettagli acconto
         * @summary Chiamata per avere i dettagli dell\'acconto
         * @param {string} codNegozioidTransazione codice negozio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LaybyCodNegozioidTransazioneGet(codNegozioidTransazione: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DettaglioAcconti>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LaybyCodNegozioidTransazioneGet(codNegozioidTransazione, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Ricerca di acconti tramite un serie di parametri
         * @summary Chiamata per ricercare gli acconti in base a parametri
         * @param {string} [filterDataDa] filtro per data minima dell\&#39;acconto
         * @param {string} [filterDataA] filtro per data massima dell\&#39;acconto
         * @param {string} [filterNumeroDocumento] filtro per numero documento
         * @param {'OPEN' | 'FINALIZED' | 'VOIDED' | 'EXPIRED' | 'CANCELLED'} [filterStato] filtro per stato
         * @param {string} [filterDataScadenzaDa] filtro per data scadenza minima
         * @param {string} [filterDataScadenzaA] filtro per data scadenza massima
         * @param {string} [filterConsumatrice] filtro per lettere nel nome cognome consumatrice
         * @param {string} [filterProssimoPagamentoDa] filtro per data prossimo pagamento minima
         * @param {string} [filterProssimoPagamentoA] filtro per data prossimo pagamento massima
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LaybyGet(filterDataDa?: string, filterDataA?: string, filterNumeroDocumento?: string, filterStato?: 'OPEN' | 'FINALIZED' | 'VOIDED' | 'EXPIRED' | 'CANCELLED', filterDataScadenzaDa?: string, filterDataScadenzaA?: string, filterConsumatrice?: string, filterProssimoPagamentoDa?: string, filterProssimoPagamentoA?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElencoAcconti>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LaybyGet(filterDataDa, filterDataA, filterNumeroDocumento, filterStato, filterDataScadenzaDa, filterDataScadenzaA, filterConsumatrice, filterProssimoPagamentoDa, filterProssimoPagamentoA, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Ristampa dell\' acconto
         * @summary Chiamata per avere la ristampa documento acconto
         * @param {string} codNegozioidTransazione codice negozio e id_transazione
         * @param {0 | 1} [fiscale] richiesta di ristampa bollettina se 0, ristampa fiscale nel caso 1 (default 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LaybyRistampaDocumentoCodNegozioidTransazioneGet(codNegozioidTransazione: string, fiscale?: 0 | 1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RistampaAcconti>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LaybyRistampaDocumentoCodNegozioidTransazioneGet(codNegozioidTransazione, fiscale, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccontiApi - factory interface
 * @export
 */
export const AccontiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccontiApiFp(configuration)
    return {
        /**
         * Dettagli acconto
         * @summary Chiamata per avere i dettagli dell\'acconto
         * @param {string} codNegozioidTransazione codice negozio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LaybyCodNegozioidTransazioneGet(codNegozioidTransazione: string, options?: any): AxiosPromise<DettaglioAcconti> {
            return localVarFp.apiV1LaybyCodNegozioidTransazioneGet(codNegozioidTransazione, options).then((request) => request(axios, basePath));
        },
        /**
         * Ricerca di acconti tramite un serie di parametri
         * @summary Chiamata per ricercare gli acconti in base a parametri
         * @param {string} [filterDataDa] filtro per data minima dell\&#39;acconto
         * @param {string} [filterDataA] filtro per data massima dell\&#39;acconto
         * @param {string} [filterNumeroDocumento] filtro per numero documento
         * @param {'OPEN' | 'FINALIZED' | 'VOIDED' | 'EXPIRED' | 'CANCELLED'} [filterStato] filtro per stato
         * @param {string} [filterDataScadenzaDa] filtro per data scadenza minima
         * @param {string} [filterDataScadenzaA] filtro per data scadenza massima
         * @param {string} [filterConsumatrice] filtro per lettere nel nome cognome consumatrice
         * @param {string} [filterProssimoPagamentoDa] filtro per data prossimo pagamento minima
         * @param {string} [filterProssimoPagamentoA] filtro per data prossimo pagamento massima
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LaybyGet(filterDataDa?: string, filterDataA?: string, filterNumeroDocumento?: string, filterStato?: 'OPEN' | 'FINALIZED' | 'VOIDED' | 'EXPIRED' | 'CANCELLED', filterDataScadenzaDa?: string, filterDataScadenzaA?: string, filterConsumatrice?: string, filterProssimoPagamentoDa?: string, filterProssimoPagamentoA?: string, options?: any): AxiosPromise<ElencoAcconti> {
            return localVarFp.apiV1LaybyGet(filterDataDa, filterDataA, filterNumeroDocumento, filterStato, filterDataScadenzaDa, filterDataScadenzaA, filterConsumatrice, filterProssimoPagamentoDa, filterProssimoPagamentoA, options).then((request) => request(axios, basePath));
        },
        /**
         * Ristampa dell\' acconto
         * @summary Chiamata per avere la ristampa documento acconto
         * @param {string} codNegozioidTransazione codice negozio e id_transazione
         * @param {0 | 1} [fiscale] richiesta di ristampa bollettina se 0, ristampa fiscale nel caso 1 (default 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LaybyRistampaDocumentoCodNegozioidTransazioneGet(codNegozioidTransazione: string, fiscale?: 0 | 1, options?: any): AxiosPromise<RistampaAcconti> {
            return localVarFp.apiV1LaybyRistampaDocumentoCodNegozioidTransazioneGet(codNegozioidTransazione, fiscale, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccontiApi - object-oriented interface
 * @export
 * @class AccontiApi
 * @extends {BaseAPI}
 */
export class AccontiApi extends BaseAPI {
    /**
     * Dettagli acconto
     * @summary Chiamata per avere i dettagli dell\'acconto
     * @param {string} codNegozioidTransazione codice negozio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccontiApi
     */
    public apiV1LaybyCodNegozioidTransazioneGet(codNegozioidTransazione: string, options?: AxiosRequestConfig) {
        return AccontiApiFp(this.configuration).apiV1LaybyCodNegozioidTransazioneGet(codNegozioidTransazione, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Ricerca di acconti tramite un serie di parametri
     * @summary Chiamata per ricercare gli acconti in base a parametri
     * @param {string} [filterDataDa] filtro per data minima dell\&#39;acconto
     * @param {string} [filterDataA] filtro per data massima dell\&#39;acconto
     * @param {string} [filterNumeroDocumento] filtro per numero documento
     * @param {'OPEN' | 'FINALIZED' | 'VOIDED' | 'EXPIRED' | 'CANCELLED'} [filterStato] filtro per stato
     * @param {string} [filterDataScadenzaDa] filtro per data scadenza minima
     * @param {string} [filterDataScadenzaA] filtro per data scadenza massima
     * @param {string} [filterConsumatrice] filtro per lettere nel nome cognome consumatrice
     * @param {string} [filterProssimoPagamentoDa] filtro per data prossimo pagamento minima
     * @param {string} [filterProssimoPagamentoA] filtro per data prossimo pagamento massima
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccontiApi
     */
    public apiV1LaybyGet(filterDataDa?: string, filterDataA?: string, filterNumeroDocumento?: string, filterStato?: 'OPEN' | 'FINALIZED' | 'VOIDED' | 'EXPIRED' | 'CANCELLED', filterDataScadenzaDa?: string, filterDataScadenzaA?: string, filterConsumatrice?: string, filterProssimoPagamentoDa?: string, filterProssimoPagamentoA?: string, options?: AxiosRequestConfig) {
        return AccontiApiFp(this.configuration).apiV1LaybyGet(filterDataDa, filterDataA, filterNumeroDocumento, filterStato, filterDataScadenzaDa, filterDataScadenzaA, filterConsumatrice, filterProssimoPagamentoDa, filterProssimoPagamentoA, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Ristampa dell\' acconto
     * @summary Chiamata per avere la ristampa documento acconto
     * @param {string} codNegozioidTransazione codice negozio e id_transazione
     * @param {0 | 1} [fiscale] richiesta di ristampa bollettina se 0, ristampa fiscale nel caso 1 (default 0)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccontiApi
     */
    public apiV1LaybyRistampaDocumentoCodNegozioidTransazioneGet(codNegozioidTransazione: string, fiscale?: 0 | 1, options?: AxiosRequestConfig) {
        return AccontiApiFp(this.configuration).apiV1LaybyRistampaDocumentoCodNegozioidTransazioneGet(codNegozioidTransazione, fiscale, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AggiornamentoPrezziApi - axios parameter creator
 * @export
 */
export const AggiornamentoPrezziApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get list of quantity of prices updates by date
         * @summary get info about some prices updates
         * @param {string} filterToday unformatted posweb date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AggiornamentoprezziGetGruppoPrezziGet: async (filterToday: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterToday' is not null or undefined
            assertParamExists('apiV1AggiornamentoprezziGetGruppoPrezziGet', 'filterToday', filterToday)
            const localVarPath = `/api/v1/aggiornamentoprezzi/get-gruppo-prezzi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterToday !== undefined) {
                localVarQueryParameter['filter[today]'] = filterToday;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list details of prices updates selected by date
         * @summary get data list about prices updates
         * @param {string} mode unformatted posweb date or the value \&quot;all\&quot;
         * @param {'0' | '1'} [soloImponibile] flag per vedere solo informazioni \&quot;imponibile\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AggiornamentoprezziGetPrezziGet: async (mode: string, soloImponibile?: '0' | '1', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mode' is not null or undefined
            assertParamExists('apiV1AggiornamentoprezziGetPrezziGet', 'mode', mode)
            const localVarPath = `/api/v1/aggiornamentoprezzi/get-prezzi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mode !== undefined) {
                localVarQueryParameter['mode'] = mode;
            }

            if (soloImponibile !== undefined) {
                localVarQueryParameter['solo_imponibile'] = soloImponibile;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Prints items with updated prices
         * @summary Prints items with updated prices
         * @param {PostStampaElenco} [postStampaElenco]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AggiornamentoprezziStampaElencoPost: async (postStampaElenco?: PostStampaElenco, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/aggiornamentoprezzi/stampa-elenco`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postStampaElenco, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Prints items tags
         * @summary Prints items tags
         * @param {PostStampaEtichette} [postStampaEtichette]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AggiornamentoprezziStampaEtichettePost: async (postStampaEtichette?: PostStampaEtichette, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/aggiornamentoprezzi/stampa-etichette`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postStampaEtichette, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AggiornamentoPrezziApi - functional programming interface
 * @export
 */
export const AggiornamentoPrezziApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AggiornamentoPrezziApiAxiosParamCreator(configuration)
    return {
        /**
         * Get list of quantity of prices updates by date
         * @summary get info about some prices updates
         * @param {string} filterToday unformatted posweb date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AggiornamentoprezziGetGruppoPrezziGet(filterToday: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataForPricesUpdates>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AggiornamentoprezziGetGruppoPrezziGet(filterToday, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list details of prices updates selected by date
         * @summary get data list about prices updates
         * @param {string} mode unformatted posweb date or the value \&quot;all\&quot;
         * @param {'0' | '1'} [soloImponibile] flag per vedere solo informazioni \&quot;imponibile\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AggiornamentoprezziGetPrezziGet(mode: string, soloImponibile?: '0' | '1', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataListOfPricesUpdates>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AggiornamentoprezziGetPrezziGet(mode, soloImponibile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Prints items with updated prices
         * @summary Prints items with updated prices
         * @param {PostStampaElenco} [postStampaElenco]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AggiornamentoprezziStampaElencoPost(postStampaElenco?: PostStampaElenco, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfFile2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AggiornamentoprezziStampaElencoPost(postStampaElenco, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Prints items tags
         * @summary Prints items tags
         * @param {PostStampaEtichette} [postStampaEtichette]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AggiornamentoprezziStampaEtichettePost(postStampaEtichette?: PostStampaEtichette, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfFile3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AggiornamentoprezziStampaEtichettePost(postStampaEtichette, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AggiornamentoPrezziApi - factory interface
 * @export
 */
export const AggiornamentoPrezziApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AggiornamentoPrezziApiFp(configuration)
    return {
        /**
         * Get list of quantity of prices updates by date
         * @summary get info about some prices updates
         * @param {string} filterToday unformatted posweb date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AggiornamentoprezziGetGruppoPrezziGet(filterToday: string, options?: any): AxiosPromise<DataForPricesUpdates> {
            return localVarFp.apiV1AggiornamentoprezziGetGruppoPrezziGet(filterToday, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list details of prices updates selected by date
         * @summary get data list about prices updates
         * @param {string} mode unformatted posweb date or the value \&quot;all\&quot;
         * @param {'0' | '1'} [soloImponibile] flag per vedere solo informazioni \&quot;imponibile\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AggiornamentoprezziGetPrezziGet(mode: string, soloImponibile?: '0' | '1', options?: any): AxiosPromise<DataListOfPricesUpdates> {
            return localVarFp.apiV1AggiornamentoprezziGetPrezziGet(mode, soloImponibile, options).then((request) => request(axios, basePath));
        },
        /**
         * Prints items with updated prices
         * @summary Prints items with updated prices
         * @param {PostStampaElenco} [postStampaElenco]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AggiornamentoprezziStampaElencoPost(postStampaElenco?: PostStampaElenco, options?: any): AxiosPromise<PdfFile2> {
            return localVarFp.apiV1AggiornamentoprezziStampaElencoPost(postStampaElenco, options).then((request) => request(axios, basePath));
        },
        /**
         * Prints items tags
         * @summary Prints items tags
         * @param {PostStampaEtichette} [postStampaEtichette]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AggiornamentoprezziStampaEtichettePost(postStampaEtichette?: PostStampaEtichette, options?: any): AxiosPromise<PdfFile3> {
            return localVarFp.apiV1AggiornamentoprezziStampaEtichettePost(postStampaEtichette, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AggiornamentoPrezziApi - object-oriented interface
 * @export
 * @class AggiornamentoPrezziApi
 * @extends {BaseAPI}
 */
export class AggiornamentoPrezziApi extends BaseAPI {
    /**
     * Get list of quantity of prices updates by date
     * @summary get info about some prices updates
     * @param {string} filterToday unformatted posweb date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AggiornamentoPrezziApi
     */
    public apiV1AggiornamentoprezziGetGruppoPrezziGet(filterToday: string, options?: AxiosRequestConfig) {
        return AggiornamentoPrezziApiFp(this.configuration).apiV1AggiornamentoprezziGetGruppoPrezziGet(filterToday, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list details of prices updates selected by date
     * @summary get data list about prices updates
     * @param {string} mode unformatted posweb date or the value \&quot;all\&quot;
     * @param {'0' | '1'} [soloImponibile] flag per vedere solo informazioni \&quot;imponibile\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AggiornamentoPrezziApi
     */
    public apiV1AggiornamentoprezziGetPrezziGet(mode: string, soloImponibile?: '0' | '1', options?: AxiosRequestConfig) {
        return AggiornamentoPrezziApiFp(this.configuration).apiV1AggiornamentoprezziGetPrezziGet(mode, soloImponibile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Prints items with updated prices
     * @summary Prints items with updated prices
     * @param {PostStampaElenco} [postStampaElenco]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AggiornamentoPrezziApi
     */
    public apiV1AggiornamentoprezziStampaElencoPost(postStampaElenco?: PostStampaElenco, options?: AxiosRequestConfig) {
        return AggiornamentoPrezziApiFp(this.configuration).apiV1AggiornamentoprezziStampaElencoPost(postStampaElenco, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Prints items tags
     * @summary Prints items tags
     * @param {PostStampaEtichette} [postStampaEtichette]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AggiornamentoPrezziApi
     */
    public apiV1AggiornamentoprezziStampaEtichettePost(postStampaEtichette?: PostStampaEtichette, options?: AxiosRequestConfig) {
        return AggiornamentoPrezziApiFp(this.configuration).apiV1AggiornamentoprezziStampaEtichettePost(postStampaEtichette, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * B2ERefundsApi - axios parameter creator
 * @export
 */
export const B2ERefundsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List available refunds.  At least one of those parameters is required to start search: - order_number, order_date, sale_number, delivery_number, surname
         * @summary Refunds search
         * @param {string} [filterSaleNumber]
         * @param {string} [filterSaleDate]
         * @param {number} [filterOrderNumber]
         * @param {string} [filterOrderDate]
         * @param {string} [filterDeliveryNumber]
         * @param {string} [filterSurname] consumer\&#39;s surname.
         * @param {string} [filterName] consumer\&#39;s name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1B2eRefundsGet: async (filterSaleNumber?: string, filterSaleDate?: string, filterOrderNumber?: number, filterOrderDate?: string, filterDeliveryNumber?: string, filterSurname?: string, filterName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/b2e-refunds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterSaleNumber !== undefined) {
                localVarQueryParameter['filter[sale_number]'] = filterSaleNumber;
            }

            if (filterSaleDate !== undefined) {
                localVarQueryParameter['filter[sale_date]'] = filterSaleDate;
            }

            if (filterOrderNumber !== undefined) {
                localVarQueryParameter['filter[order_number]'] = filterOrderNumber;
            }

            if (filterOrderDate !== undefined) {
                localVarQueryParameter['filter[order_date]'] = filterOrderDate;
            }

            if (filterDeliveryNumber !== undefined) {
                localVarQueryParameter['filter[delivery_number]'] = filterDeliveryNumber;
            }

            if (filterSurname !== undefined) {
                localVarQueryParameter['filter[surname]'] = filterSurname;
            }

            if (filterName !== undefined) {
                localVarQueryParameter['filter[name]'] = filterName;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## Create the refund  ### in Response: some keys are compiled if some print errors occur: - message - prints_to_retry  The key \"browser_download_pdf\" is compiled if the print is a pdf document
         * @summary Post a refund
         * @param {PostB2ERefund} [postB2ERefund]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1B2eRefundsPost: async (postB2ERefund?: PostB2ERefund, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/b2e-refunds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postB2ERefund, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * B2ERefundsApi - functional programming interface
 * @export
 */
export const B2ERefundsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = B2ERefundsApiAxiosParamCreator(configuration)
    return {
        /**
         * List available refunds.  At least one of those parameters is required to start search: - order_number, order_date, sale_number, delivery_number, surname
         * @summary Refunds search
         * @param {string} [filterSaleNumber]
         * @param {string} [filterSaleDate]
         * @param {number} [filterOrderNumber]
         * @param {string} [filterOrderDate]
         * @param {string} [filterDeliveryNumber]
         * @param {string} [filterSurname] consumer\&#39;s surname.
         * @param {string} [filterName] consumer\&#39;s name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1B2eRefundsGet(filterSaleNumber?: string, filterSaleDate?: string, filterOrderNumber?: number, filterOrderDate?: string, filterDeliveryNumber?: string, filterSurname?: string, filterName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetB2ERefunds>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1B2eRefundsGet(filterSaleNumber, filterSaleDate, filterOrderNumber, filterOrderDate, filterDeliveryNumber, filterSurname, filterName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ## Create the refund  ### in Response: some keys are compiled if some print errors occur: - message - prints_to_retry  The key \"browser_download_pdf\" is compiled if the print is a pdf document
         * @summary Post a refund
         * @param {PostB2ERefund} [postB2ERefund]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1B2eRefundsPost(postB2ERefund?: PostB2ERefund, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetB2ERefunds1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1B2eRefundsPost(postB2ERefund, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * B2ERefundsApi - factory interface
 * @export
 */
export const B2ERefundsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = B2ERefundsApiFp(configuration)
    return {
        /**
         * List available refunds.  At least one of those parameters is required to start search: - order_number, order_date, sale_number, delivery_number, surname
         * @summary Refunds search
         * @param {string} [filterSaleNumber]
         * @param {string} [filterSaleDate]
         * @param {number} [filterOrderNumber]
         * @param {string} [filterOrderDate]
         * @param {string} [filterDeliveryNumber]
         * @param {string} [filterSurname] consumer\&#39;s surname.
         * @param {string} [filterName] consumer\&#39;s name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1B2eRefundsGet(filterSaleNumber?: string, filterSaleDate?: string, filterOrderNumber?: number, filterOrderDate?: string, filterDeliveryNumber?: string, filterSurname?: string, filterName?: string, options?: any): AxiosPromise<GetB2ERefunds> {
            return localVarFp.apiV1B2eRefundsGet(filterSaleNumber, filterSaleDate, filterOrderNumber, filterOrderDate, filterDeliveryNumber, filterSurname, filterName, options).then((request) => request(axios, basePath));
        },
        /**
         * ## Create the refund  ### in Response: some keys are compiled if some print errors occur: - message - prints_to_retry  The key \"browser_download_pdf\" is compiled if the print is a pdf document
         * @summary Post a refund
         * @param {PostB2ERefund} [postB2ERefund]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1B2eRefundsPost(postB2ERefund?: PostB2ERefund, options?: any): AxiosPromise<GetB2ERefunds1> {
            return localVarFp.apiV1B2eRefundsPost(postB2ERefund, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * B2ERefundsApi - object-oriented interface
 * @export
 * @class B2ERefundsApi
 * @extends {BaseAPI}
 */
export class B2ERefundsApi extends BaseAPI {
    /**
     * List available refunds.  At least one of those parameters is required to start search: - order_number, order_date, sale_number, delivery_number, surname
     * @summary Refunds search
     * @param {string} [filterSaleNumber]
     * @param {string} [filterSaleDate]
     * @param {number} [filterOrderNumber]
     * @param {string} [filterOrderDate]
     * @param {string} [filterDeliveryNumber]
     * @param {string} [filterSurname] consumer\&#39;s surname.
     * @param {string} [filterName] consumer\&#39;s name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof B2ERefundsApi
     */
    public apiV1B2eRefundsGet(filterSaleNumber?: string, filterSaleDate?: string, filterOrderNumber?: number, filterOrderDate?: string, filterDeliveryNumber?: string, filterSurname?: string, filterName?: string, options?: AxiosRequestConfig) {
        return B2ERefundsApiFp(this.configuration).apiV1B2eRefundsGet(filterSaleNumber, filterSaleDate, filterOrderNumber, filterOrderDate, filterDeliveryNumber, filterSurname, filterName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## Create the refund  ### in Response: some keys are compiled if some print errors occur: - message - prints_to_retry  The key \"browser_download_pdf\" is compiled if the print is a pdf document
     * @summary Post a refund
     * @param {PostB2ERefund} [postB2ERefund]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof B2ERefundsApi
     */
    public apiV1B2eRefundsPost(postB2ERefund?: PostB2ERefund, options?: AxiosRequestConfig) {
        return B2ERefundsApiFp(this.configuration).apiV1B2eRefundsPost(postB2ERefund, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CatalogApi - axios parameter creator
 * @export
 */
export const CatalogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets filters and his values
         * @summary Search for advanced filters
         * @param {string} [filterBrand] brand to be filtered
         * @param {string} [filterCategory] category to be filtered
         * @param {'collection' | 'mainline' | 'modelB2C' | 'newArrivals' | 'onSale' | 'price' | 'sizes' | 'stock' | 'yearSeason' | 'occasionFilter' | 'buttoningFilter' | 'comfortableFacet' | 'coatSilhouetteFilter' | 'shapeFilter' | 'bodyLengthFilter' | 'colorFilter' | 'materialFilter' | 'sleeveLengthFilter' | 'styleFilter' | 'typefilter'} [filterCode] code of the filter
         * @param {'Collezione' | 'Main Line' | 'Con Foto' | 'Nuovi Arrivi' | 'In Saldo' | 'Prezzo' | 'Taglie' | 'In giacenza' | 'Anno stagione'} [filterDesc] description of the filter, coupled with code, as price-Prezzo. Use as alternative of code
         * @param {string} [filterVal] value for the specified code or desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebAdvancedFiltersGet: async (filterBrand?: string, filterCategory?: string, filterCode?: 'collection' | 'mainline' | 'modelB2C' | 'newArrivals' | 'onSale' | 'price' | 'sizes' | 'stock' | 'yearSeason' | 'occasionFilter' | 'buttoningFilter' | 'comfortableFacet' | 'coatSilhouetteFilter' | 'shapeFilter' | 'bodyLengthFilter' | 'colorFilter' | 'materialFilter' | 'sleeveLengthFilter' | 'styleFilter' | 'typefilter', filterDesc?: 'Collezione' | 'Main Line' | 'Con Foto' | 'Nuovi Arrivi' | 'In Saldo' | 'Prezzo' | 'Taglie' | 'In giacenza' | 'Anno stagione', filterVal?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/advanced-filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterBrand !== undefined) {
                localVarQueryParameter['filter[brand]'] = filterBrand;
            }

            if (filterCategory !== undefined) {
                localVarQueryParameter['filter[category]'] = filterCategory;
            }

            if (filterCode !== undefined) {
                localVarQueryParameter['filter[code]'] = filterCode;
            }

            if (filterDesc !== undefined) {
                localVarQueryParameter['filter[desc]'] = filterDesc;
            }

            if (filterVal !== undefined) {
                localVarQueryParameter['filter[val]'] = filterVal;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets categories of models by brand
         * @summary Get list of categories
         * @param {string} filterBrand brand to be elaborated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebCategoriesGet: async (filterBrand: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterBrand' is not null or undefined
            assertParamExists('apiV1PoswebCategoriesGet', 'filterBrand', filterBrand)
            const localVarPath = `/api/v1/posweb/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterBrand !== undefined) {
                localVarQueryParameter['filter[brand]'] = filterBrand;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets sku data from catalog  Parameter \"filter[skip_giacenza]\" at 1 avoids calculating the stock for the sku supplied
         * @summary Search for sku
         * @param {string} [filterSku] SKU to be retrieved
         * @param {string} [filterRfid] RFID to be retrieved
         * @param {string} [filterSkuGruppo] sku_gruppo to be retrieved
         * @param {number} [filterSkipGiacenza] skip calculation of giacenza for the sku supplied
         * @param {string} [filterPkConsumer] consumer pk identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebModelsDataGet: async (filterSku?: string, filterRfid?: string, filterSkuGruppo?: string, filterSkipGiacenza?: number, filterPkConsumer?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/models/data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterSku !== undefined) {
                localVarQueryParameter['filter[sku]'] = filterSku;
            }

            if (filterRfid !== undefined) {
                localVarQueryParameter['filter[rfid]'] = filterRfid;
            }

            if (filterSkuGruppo !== undefined) {
                localVarQueryParameter['filter[sku_gruppo]'] = filterSkuGruppo;
            }

            if (filterSkipGiacenza !== undefined) {
                localVarQueryParameter['filter[skip_giacenza]'] = filterSkipGiacenza;
            }

            if (filterPkConsumer !== undefined) {
                localVarQueryParameter['filter[pk_consumer]'] = filterPkConsumer;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets models from catalog
         * @summary Search for models
         * @param {string} [filterSku] SKU to be elaborated
         * @param {string} [filterGetRelated] when equal to 1 all related models will be returned if sku exists
         * @param {string} [filterPkConsumer] pk_consumer to be elaborated
         * @param {string} [filterBrand] brand to be elaborated, comma separated values are admitted
         * @param {string} [filterCategoria] categoria to be elaborated
         * @param {number} [filterRighe] righe or number of record per page
         * @param {number} [filterPagina] pagina to be elaborated
         * @param {string} [filterModel] model to be elaborated
         * @param {string} [filterModelvariant] modelvariant to be elaborated
         * @param {string} [filterSizes] sizes to be elaborated, | string composed by groups and values values are comma separated, groups are pipe separated
         * @param {0 | 1} [filterStock] 1 or 0 to get stock informations
         * @param {string} [filterName] model name to be elaborated
         * @param {string} [filterEan] ean to be elaborated, comma separated values admitted
         * @param {0 | 1} [filterLikeMode] like_mode used in query instead of &#x3D;
         * @param {string} [filterRfidStore] product rfid
         * @param {string} [filterCodCategoria] product cod categoria
         * @param {string} [filterCodSottocategoria] product cod sottocategoria
         * @param {string} [filterCollection] filtri avanzati, collection
         * @param {string} [filterMainline] filtri avanzati, mainline
         * @param {string} [filterModelB2C] filtri avanzati, modelB2C
         * @param {string} [filterNewArrivals] filtri avanzati, newArrivals
         * @param {string} [filterOnSale] filtri avanzati, onSale
         * @param {string} [filterPrice] filtri avanzati, price
         * @param {number} [filterMinprice] filtri avanzati, minprice
         * @param {number} [filterMaxprice] filtri avanzati, maxprice
         * @param {string} [filterYearSeason] filtri avanzati, yearSeason
         * @param {string} [filterStyleFilter] filtri avanzati, styleFilter
         * @param {string} [filterBodyLengthFilter] filtri avanzati, bodyLengthFilter
         * @param {string} [filterColorFilter] filtri avanzati, colorFilter
         * @param {string} [filterMaterialFilter] filtri avanzati, materialFilter
         * @param {string} [filterTypefilter] filtri avanzati, typefilter
         * @param {string} [filterSleeveLengthFilter] filtri avanzati, sleeveLengthFilter
         * @param {string} [filterOccasionFilter] filtri avanzati, occasionFilter
         * @param {string} [filterButtoningFilter] filtri avanzati, buttoningFilter
         * @param {string} [filterComfortableFacet] filtri avanzati, comfortableFacet
         * @param {string} [filterCoatSilhouetteFilter] filtri avanzati, coatSilhouetteFilter
         * @param {string} [filterShapeFilter] filtri avanzati, shapeFilter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebModelsGet: async (filterSku?: string, filterGetRelated?: string, filterPkConsumer?: string, filterBrand?: string, filterCategoria?: string, filterRighe?: number, filterPagina?: number, filterModel?: string, filterModelvariant?: string, filterSizes?: string, filterStock?: 0 | 1, filterName?: string, filterEan?: string, filterLikeMode?: 0 | 1, filterRfidStore?: string, filterCodCategoria?: string, filterCodSottocategoria?: string, filterCollection?: string, filterMainline?: string, filterModelB2C?: string, filterNewArrivals?: string, filterOnSale?: string, filterPrice?: string, filterMinprice?: number, filterMaxprice?: number, filterYearSeason?: string, filterStyleFilter?: string, filterBodyLengthFilter?: string, filterColorFilter?: string, filterMaterialFilter?: string, filterTypefilter?: string, filterSleeveLengthFilter?: string, filterOccasionFilter?: string, filterButtoningFilter?: string, filterComfortableFacet?: string, filterCoatSilhouetteFilter?: string, filterShapeFilter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterSku !== undefined) {
                localVarQueryParameter['filter[sku]'] = filterSku;
            }

            if (filterGetRelated !== undefined) {
                localVarQueryParameter['filter[get_related]'] = filterGetRelated;
            }

            if (filterPkConsumer !== undefined) {
                localVarQueryParameter['filter[pk_consumer]'] = filterPkConsumer;
            }

            if (filterBrand !== undefined) {
                localVarQueryParameter['filter[brand]'] = filterBrand;
            }

            if (filterCategoria !== undefined) {
                localVarQueryParameter['filter[categoria]'] = filterCategoria;
            }

            if (filterRighe !== undefined) {
                localVarQueryParameter['filter[righe]'] = filterRighe;
            }

            if (filterPagina !== undefined) {
                localVarQueryParameter['filter[pagina]'] = filterPagina;
            }

            if (filterModel !== undefined) {
                localVarQueryParameter['filter[model]'] = filterModel;
            }

            if (filterModelvariant !== undefined) {
                localVarQueryParameter['filter[modelvariant]'] = filterModelvariant;
            }

            if (filterSizes !== undefined) {
                localVarQueryParameter['filter[sizes]'] = filterSizes;
            }

            if (filterStock !== undefined) {
                localVarQueryParameter['filter[stock]'] = filterStock;
            }

            if (filterName !== undefined) {
                localVarQueryParameter['filter[name]'] = filterName;
            }

            if (filterEan !== undefined) {
                localVarQueryParameter['filter[ean]'] = filterEan;
            }

            if (filterLikeMode !== undefined) {
                localVarQueryParameter['filter[like_mode]'] = filterLikeMode;
            }

            if (filterRfidStore !== undefined) {
                localVarQueryParameter['filter[rfid_store]'] = filterRfidStore;
            }

            if (filterCodCategoria !== undefined) {
                localVarQueryParameter['filter[cod_categoria]'] = filterCodCategoria;
            }

            if (filterCodSottocategoria !== undefined) {
                localVarQueryParameter['filter[cod_sottocategoria]'] = filterCodSottocategoria;
            }

            if (filterCollection !== undefined) {
                localVarQueryParameter['filter[collection]'] = filterCollection;
            }

            if (filterMainline !== undefined) {
                localVarQueryParameter['filter[mainline]'] = filterMainline;
            }

            if (filterModelB2C !== undefined) {
                localVarQueryParameter['filter[modelB2C]'] = filterModelB2C;
            }

            if (filterNewArrivals !== undefined) {
                localVarQueryParameter['filter[newArrivals]'] = filterNewArrivals;
            }

            if (filterOnSale !== undefined) {
                localVarQueryParameter['filter[onSale]'] = filterOnSale;
            }

            if (filterPrice !== undefined) {
                localVarQueryParameter['filter[price]'] = filterPrice;
            }

            if (filterMinprice !== undefined) {
                localVarQueryParameter['filter[minprice]'] = filterMinprice;
            }

            if (filterMaxprice !== undefined) {
                localVarQueryParameter['filter[maxprice]'] = filterMaxprice;
            }

            if (filterYearSeason !== undefined) {
                localVarQueryParameter['filter[yearSeason]'] = filterYearSeason;
            }

            if (filterStyleFilter !== undefined) {
                localVarQueryParameter['filter[styleFilter]'] = filterStyleFilter;
            }

            if (filterBodyLengthFilter !== undefined) {
                localVarQueryParameter['filter[bodyLengthFilter]'] = filterBodyLengthFilter;
            }

            if (filterColorFilter !== undefined) {
                localVarQueryParameter['filter[colorFilter]'] = filterColorFilter;
            }

            if (filterMaterialFilter !== undefined) {
                localVarQueryParameter['filter[materialFilter]'] = filterMaterialFilter;
            }

            if (filterTypefilter !== undefined) {
                localVarQueryParameter['filter[typefilter]'] = filterTypefilter;
            }

            if (filterSleeveLengthFilter !== undefined) {
                localVarQueryParameter['filter[sleeveLengthFilter]'] = filterSleeveLengthFilter;
            }

            if (filterOccasionFilter !== undefined) {
                localVarQueryParameter['filter[occasionFilter]'] = filterOccasionFilter;
            }

            if (filterButtoningFilter !== undefined) {
                localVarQueryParameter['filter[buttoningFilter]'] = filterButtoningFilter;
            }

            if (filterComfortableFacet !== undefined) {
                localVarQueryParameter['filter[comfortableFacet]'] = filterComfortableFacet;
            }

            if (filterCoatSilhouetteFilter !== undefined) {
                localVarQueryParameter['filter[coatSilhouetteFilter]'] = filterCoatSilhouetteFilter;
            }

            if (filterShapeFilter !== undefined) {
                localVarQueryParameter['filter[shapeFilter]'] = filterShapeFilter;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets sku data from catalog
         * @summary Obtain giacenza data for sku
         * @param {string} filterSku SKU to be elaborated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebModelsGiacenzaSkuGet: async (filterSku: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterSku' is not null or undefined
            assertParamExists('apiV1PoswebModelsGiacenzaSkuGet', 'filterSku', filterSku)
            const localVarPath = `/api/v1/posweb/models/giacenza_sku`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterSku !== undefined) {
                localVarQueryParameter['filter[sku]'] = filterSku;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets model data from catalog
         * @summary Search for model detail
         * @param {string} model Model to be elaborated
         * @param {string} [filterPkConsumer] pk_consumer to be elaborated
         * @param {string} [filterBrand] brand to be elaborated, comma separated values are admitted
         * @param {string} [filterCategoria] categoria to be elaborated
         * @param {number} [filterRighe] righe or number of record per page
         * @param {number} [filterPagina] pagina to be elaborated
         * @param {string} [filterModelvariant] modelvariant to be elaborated
         * @param {string} [filterSizes] sizes to be elaborated, | string composed by groups and values values are comma separated, groups are pipe separated
         * @param {string} [filterStock] 1 or 0 to get stock informations
         * @param {string} [filterName] name to be elaborated
         * @param {string} [filterEan] ean to be elaborated, comma separated values admitted
         * @param {0 | 1} [filterLikeMode] like_mode used in query instead of &#x3D;
         * @param {string} [filterRfidStore] product rfid
         * @param {string} [filterCodCategoria] product cod categoria
         * @param {string} [filterCodSottocategoria] product cod sottocategoria
         * @param {string} [filterCollection] filtri avanzati, collection
         * @param {string} [filterMainline] filtri avanzati, mainline
         * @param {string} [filterModelB2C] filtri avanzati, modelB2C
         * @param {string} [filterNewArrivals] filtri avanzati, newArrivals
         * @param {string} [filterOnSale] filtri avanzati, onSale
         * @param {string} [filterPrice] filtri avanzati, price
         * @param {number} [filterMinprice] filtri avanzati, minprice
         * @param {number} [filterMaxprice] filtri avanzati, maxprice
         * @param {string} [filterYearSeason] filtri avanzati, yearSeason
         * @param {string} [filterStyleFilter] filtri avanzati, styleFilter
         * @param {string} [filterBodyLengthFilter] filtri avanzati, bodyLengthFilter
         * @param {string} [filterColorFilter] filtri avanzati, colorFilter
         * @param {string} [filterMaterialFilter] filtri avanzati, materialFilter
         * @param {string} [filterTypefilter] filtri avanzati, typefilter
         * @param {string} [filterSleeveLengthFilter] filtri avanzati, sleeveLengthFilter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebModelsModelGet: async (model: string, filterPkConsumer?: string, filterBrand?: string, filterCategoria?: string, filterRighe?: number, filterPagina?: number, filterModelvariant?: string, filterSizes?: string, filterStock?: string, filterName?: string, filterEan?: string, filterLikeMode?: 0 | 1, filterRfidStore?: string, filterCodCategoria?: string, filterCodSottocategoria?: string, filterCollection?: string, filterMainline?: string, filterModelB2C?: string, filterNewArrivals?: string, filterOnSale?: string, filterPrice?: string, filterMinprice?: number, filterMaxprice?: number, filterYearSeason?: string, filterStyleFilter?: string, filterBodyLengthFilter?: string, filterColorFilter?: string, filterMaterialFilter?: string, filterTypefilter?: string, filterSleeveLengthFilter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'model' is not null or undefined
            assertParamExists('apiV1PoswebModelsModelGet', 'model', model)
            const localVarPath = `/api/v1/posweb/models/{model}/`
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterPkConsumer !== undefined) {
                localVarQueryParameter['filter[pk_consumer]'] = filterPkConsumer;
            }

            if (filterBrand !== undefined) {
                localVarQueryParameter['filter[brand]'] = filterBrand;
            }

            if (filterCategoria !== undefined) {
                localVarQueryParameter['filter[categoria]'] = filterCategoria;
            }

            if (filterRighe !== undefined) {
                localVarQueryParameter['filter[righe]'] = filterRighe;
            }

            if (filterPagina !== undefined) {
                localVarQueryParameter['filter[pagina]'] = filterPagina;
            }

            if (filterModelvariant !== undefined) {
                localVarQueryParameter['filter[modelvariant]'] = filterModelvariant;
            }

            if (filterSizes !== undefined) {
                localVarQueryParameter['filter[sizes]'] = filterSizes;
            }

            if (filterStock !== undefined) {
                localVarQueryParameter['filter[stock]'] = filterStock;
            }

            if (filterName !== undefined) {
                localVarQueryParameter['filter[name]'] = filterName;
            }

            if (filterEan !== undefined) {
                localVarQueryParameter['filter[ean]'] = filterEan;
            }

            if (filterLikeMode !== undefined) {
                localVarQueryParameter['filter[like_mode]'] = filterLikeMode;
            }

            if (filterRfidStore !== undefined) {
                localVarQueryParameter['filter[rfid_store]'] = filterRfidStore;
            }

            if (filterCodCategoria !== undefined) {
                localVarQueryParameter['filter[cod_categoria]'] = filterCodCategoria;
            }

            if (filterCodSottocategoria !== undefined) {
                localVarQueryParameter['filter[cod_sottocategoria]'] = filterCodSottocategoria;
            }

            if (filterCollection !== undefined) {
                localVarQueryParameter['filter[collection]'] = filterCollection;
            }

            if (filterMainline !== undefined) {
                localVarQueryParameter['filter[mainline]'] = filterMainline;
            }

            if (filterModelB2C !== undefined) {
                localVarQueryParameter['filter[modelB2C]'] = filterModelB2C;
            }

            if (filterNewArrivals !== undefined) {
                localVarQueryParameter['filter[newArrivals]'] = filterNewArrivals;
            }

            if (filterOnSale !== undefined) {
                localVarQueryParameter['filter[onSale]'] = filterOnSale;
            }

            if (filterPrice !== undefined) {
                localVarQueryParameter['filter[price]'] = filterPrice;
            }

            if (filterMinprice !== undefined) {
                localVarQueryParameter['filter[minprice]'] = filterMinprice;
            }

            if (filterMaxprice !== undefined) {
                localVarQueryParameter['filter[maxprice]'] = filterMaxprice;
            }

            if (filterYearSeason !== undefined) {
                localVarQueryParameter['filter[yearSeason]'] = filterYearSeason;
            }

            if (filterStyleFilter !== undefined) {
                localVarQueryParameter['filter[styleFilter]'] = filterStyleFilter;
            }

            if (filterBodyLengthFilter !== undefined) {
                localVarQueryParameter['filter[bodyLengthFilter]'] = filterBodyLengthFilter;
            }

            if (filterColorFilter !== undefined) {
                localVarQueryParameter['filter[colorFilter]'] = filterColorFilter;
            }

            if (filterMaterialFilter !== undefined) {
                localVarQueryParameter['filter[materialFilter]'] = filterMaterialFilter;
            }

            if (filterTypefilter !== undefined) {
                localVarQueryParameter['filter[typefilter]'] = filterTypefilter;
            }

            if (filterSleeveLengthFilter !== undefined) {
                localVarQueryParameter['filter[sleeveLengthFilter]'] = filterSleeveLengthFilter;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets sizes of models from catalog
         * @summary Search for sizes
         * @param {string} filterBrand brand to be elaborated
         * @param {string} filterCategoria category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebSizesGet: async (filterBrand: string, filterCategoria: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterBrand' is not null or undefined
            assertParamExists('apiV1PoswebSizesGet', 'filterBrand', filterBrand)
            // verify required parameter 'filterCategoria' is not null or undefined
            assertParamExists('apiV1PoswebSizesGet', 'filterCategoria', filterCategoria)
            const localVarPath = `/api/v1/posweb/sizes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterBrand !== undefined) {
                localVarQueryParameter['filter[brand]'] = filterBrand;
            }

            if (filterCategoria !== undefined) {
                localVarQueryParameter['filter[categoria]'] = filterCategoria;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Serve per suggerire altri capi all’utente a partire dal dettaglio del capo corrente
         * @summary Suggerimenti di altri capi
         * @param {string} [filterBrand] brand, comma separated values are admitted
         * @param {string} [filterSkus] sku of item sample, comma separated values are admitted
         * @param {string} [filterLimit] limit of record returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebYmalGet: async (filterBrand?: string, filterSkus?: string, filterLimit?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/ymal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterBrand !== undefined) {
                localVarQueryParameter['filter[brand]'] = filterBrand;
            }

            if (filterSkus !== undefined) {
                localVarQueryParameter['filter[skus]'] = filterSkus;
            }

            if (filterLimit !== undefined) {
                localVarQueryParameter['filter[limit]'] = filterLimit;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CatalogApi - functional programming interface
 * @export
 */
export const CatalogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CatalogApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets filters and his values
         * @summary Search for advanced filters
         * @param {string} [filterBrand] brand to be filtered
         * @param {string} [filterCategory] category to be filtered
         * @param {'collection' | 'mainline' | 'modelB2C' | 'newArrivals' | 'onSale' | 'price' | 'sizes' | 'stock' | 'yearSeason' | 'occasionFilter' | 'buttoningFilter' | 'comfortableFacet' | 'coatSilhouetteFilter' | 'shapeFilter' | 'bodyLengthFilter' | 'colorFilter' | 'materialFilter' | 'sleeveLengthFilter' | 'styleFilter' | 'typefilter'} [filterCode] code of the filter
         * @param {'Collezione' | 'Main Line' | 'Con Foto' | 'Nuovi Arrivi' | 'In Saldo' | 'Prezzo' | 'Taglie' | 'In giacenza' | 'Anno stagione'} [filterDesc] description of the filter, coupled with code, as price-Prezzo. Use as alternative of code
         * @param {string} [filterVal] value for the specified code or desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebAdvancedFiltersGet(filterBrand?: string, filterCategory?: string, filterCode?: 'collection' | 'mainline' | 'modelB2C' | 'newArrivals' | 'onSale' | 'price' | 'sizes' | 'stock' | 'yearSeason' | 'occasionFilter' | 'buttoningFilter' | 'comfortableFacet' | 'coatSilhouetteFilter' | 'shapeFilter' | 'bodyLengthFilter' | 'colorFilter' | 'materialFilter' | 'sleeveLengthFilter' | 'styleFilter' | 'typefilter', filterDesc?: 'Collezione' | 'Main Line' | 'Con Foto' | 'Nuovi Arrivi' | 'In Saldo' | 'Prezzo' | 'Taglie' | 'In giacenza' | 'Anno stagione', filterVal?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAdvancedFilters>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebAdvancedFiltersGet(filterBrand, filterCategory, filterCode, filterDesc, filterVal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets categories of models by brand
         * @summary Get list of categories
         * @param {string} filterBrand brand to be elaborated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebCategoriesGet(filterBrand: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBrandCategories>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebCategoriesGet(filterBrand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets sku data from catalog  Parameter \"filter[skip_giacenza]\" at 1 avoids calculating the stock for the sku supplied
         * @summary Search for sku
         * @param {string} [filterSku] SKU to be retrieved
         * @param {string} [filterRfid] RFID to be retrieved
         * @param {string} [filterSkuGruppo] sku_gruppo to be retrieved
         * @param {number} [filterSkipGiacenza] skip calculation of giacenza for the sku supplied
         * @param {string} [filterPkConsumer] consumer pk identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebModelsDataGet(filterSku?: string, filterRfid?: string, filterSkuGruppo?: string, filterSkipGiacenza?: number, filterPkConsumer?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSkuData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebModelsDataGet(filterSku, filterRfid, filterSkuGruppo, filterSkipGiacenza, filterPkConsumer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets models from catalog
         * @summary Search for models
         * @param {string} [filterSku] SKU to be elaborated
         * @param {string} [filterGetRelated] when equal to 1 all related models will be returned if sku exists
         * @param {string} [filterPkConsumer] pk_consumer to be elaborated
         * @param {string} [filterBrand] brand to be elaborated, comma separated values are admitted
         * @param {string} [filterCategoria] categoria to be elaborated
         * @param {number} [filterRighe] righe or number of record per page
         * @param {number} [filterPagina] pagina to be elaborated
         * @param {string} [filterModel] model to be elaborated
         * @param {string} [filterModelvariant] modelvariant to be elaborated
         * @param {string} [filterSizes] sizes to be elaborated, | string composed by groups and values values are comma separated, groups are pipe separated
         * @param {0 | 1} [filterStock] 1 or 0 to get stock informations
         * @param {string} [filterName] model name to be elaborated
         * @param {string} [filterEan] ean to be elaborated, comma separated values admitted
         * @param {0 | 1} [filterLikeMode] like_mode used in query instead of &#x3D;
         * @param {string} [filterRfidStore] product rfid
         * @param {string} [filterCodCategoria] product cod categoria
         * @param {string} [filterCodSottocategoria] product cod sottocategoria
         * @param {string} [filterCollection] filtri avanzati, collection
         * @param {string} [filterMainline] filtri avanzati, mainline
         * @param {string} [filterModelB2C] filtri avanzati, modelB2C
         * @param {string} [filterNewArrivals] filtri avanzati, newArrivals
         * @param {string} [filterOnSale] filtri avanzati, onSale
         * @param {string} [filterPrice] filtri avanzati, price
         * @param {number} [filterMinprice] filtri avanzati, minprice
         * @param {number} [filterMaxprice] filtri avanzati, maxprice
         * @param {string} [filterYearSeason] filtri avanzati, yearSeason
         * @param {string} [filterStyleFilter] filtri avanzati, styleFilter
         * @param {string} [filterBodyLengthFilter] filtri avanzati, bodyLengthFilter
         * @param {string} [filterColorFilter] filtri avanzati, colorFilter
         * @param {string} [filterMaterialFilter] filtri avanzati, materialFilter
         * @param {string} [filterTypefilter] filtri avanzati, typefilter
         * @param {string} [filterSleeveLengthFilter] filtri avanzati, sleeveLengthFilter
         * @param {string} [filterOccasionFilter] filtri avanzati, occasionFilter
         * @param {string} [filterButtoningFilter] filtri avanzati, buttoningFilter
         * @param {string} [filterComfortableFacet] filtri avanzati, comfortableFacet
         * @param {string} [filterCoatSilhouetteFilter] filtri avanzati, coatSilhouetteFilter
         * @param {string} [filterShapeFilter] filtri avanzati, shapeFilter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebModelsGet(filterSku?: string, filterGetRelated?: string, filterPkConsumer?: string, filterBrand?: string, filterCategoria?: string, filterRighe?: number, filterPagina?: number, filterModel?: string, filterModelvariant?: string, filterSizes?: string, filterStock?: 0 | 1, filterName?: string, filterEan?: string, filterLikeMode?: 0 | 1, filterRfidStore?: string, filterCodCategoria?: string, filterCodSottocategoria?: string, filterCollection?: string, filterMainline?: string, filterModelB2C?: string, filterNewArrivals?: string, filterOnSale?: string, filterPrice?: string, filterMinprice?: number, filterMaxprice?: number, filterYearSeason?: string, filterStyleFilter?: string, filterBodyLengthFilter?: string, filterColorFilter?: string, filterMaterialFilter?: string, filterTypefilter?: string, filterSleeveLengthFilter?: string, filterOccasionFilter?: string, filterButtoningFilter?: string, filterComfortableFacet?: string, filterCoatSilhouetteFilter?: string, filterShapeFilter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetModels>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebModelsGet(filterSku, filterGetRelated, filterPkConsumer, filterBrand, filterCategoria, filterRighe, filterPagina, filterModel, filterModelvariant, filterSizes, filterStock, filterName, filterEan, filterLikeMode, filterRfidStore, filterCodCategoria, filterCodSottocategoria, filterCollection, filterMainline, filterModelB2C, filterNewArrivals, filterOnSale, filterPrice, filterMinprice, filterMaxprice, filterYearSeason, filterStyleFilter, filterBodyLengthFilter, filterColorFilter, filterMaterialFilter, filterTypefilter, filterSleeveLengthFilter, filterOccasionFilter, filterButtoningFilter, filterComfortableFacet, filterCoatSilhouetteFilter, filterShapeFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets sku data from catalog
         * @summary Obtain giacenza data for sku
         * @param {string} filterSku SKU to be elaborated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebModelsGiacenzaSkuGet(filterSku: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSkuGiacenza>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebModelsGiacenzaSkuGet(filterSku, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets model data from catalog
         * @summary Search for model detail
         * @param {string} model Model to be elaborated
         * @param {string} [filterPkConsumer] pk_consumer to be elaborated
         * @param {string} [filterBrand] brand to be elaborated, comma separated values are admitted
         * @param {string} [filterCategoria] categoria to be elaborated
         * @param {number} [filterRighe] righe or number of record per page
         * @param {number} [filterPagina] pagina to be elaborated
         * @param {string} [filterModelvariant] modelvariant to be elaborated
         * @param {string} [filterSizes] sizes to be elaborated, | string composed by groups and values values are comma separated, groups are pipe separated
         * @param {string} [filterStock] 1 or 0 to get stock informations
         * @param {string} [filterName] name to be elaborated
         * @param {string} [filterEan] ean to be elaborated, comma separated values admitted
         * @param {0 | 1} [filterLikeMode] like_mode used in query instead of &#x3D;
         * @param {string} [filterRfidStore] product rfid
         * @param {string} [filterCodCategoria] product cod categoria
         * @param {string} [filterCodSottocategoria] product cod sottocategoria
         * @param {string} [filterCollection] filtri avanzati, collection
         * @param {string} [filterMainline] filtri avanzati, mainline
         * @param {string} [filterModelB2C] filtri avanzati, modelB2C
         * @param {string} [filterNewArrivals] filtri avanzati, newArrivals
         * @param {string} [filterOnSale] filtri avanzati, onSale
         * @param {string} [filterPrice] filtri avanzati, price
         * @param {number} [filterMinprice] filtri avanzati, minprice
         * @param {number} [filterMaxprice] filtri avanzati, maxprice
         * @param {string} [filterYearSeason] filtri avanzati, yearSeason
         * @param {string} [filterStyleFilter] filtri avanzati, styleFilter
         * @param {string} [filterBodyLengthFilter] filtri avanzati, bodyLengthFilter
         * @param {string} [filterColorFilter] filtri avanzati, colorFilter
         * @param {string} [filterMaterialFilter] filtri avanzati, materialFilter
         * @param {string} [filterTypefilter] filtri avanzati, typefilter
         * @param {string} [filterSleeveLengthFilter] filtri avanzati, sleeveLengthFilter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebModelsModelGet(model: string, filterPkConsumer?: string, filterBrand?: string, filterCategoria?: string, filterRighe?: number, filterPagina?: number, filterModelvariant?: string, filterSizes?: string, filterStock?: string, filterName?: string, filterEan?: string, filterLikeMode?: 0 | 1, filterRfidStore?: string, filterCodCategoria?: string, filterCodSottocategoria?: string, filterCollection?: string, filterMainline?: string, filterModelB2C?: string, filterNewArrivals?: string, filterOnSale?: string, filterPrice?: string, filterMinprice?: number, filterMaxprice?: number, filterYearSeason?: string, filterStyleFilter?: string, filterBodyLengthFilter?: string, filterColorFilter?: string, filterMaterialFilter?: string, filterTypefilter?: string, filterSleeveLengthFilter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetModelData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebModelsModelGet(model, filterPkConsumer, filterBrand, filterCategoria, filterRighe, filterPagina, filterModelvariant, filterSizes, filterStock, filterName, filterEan, filterLikeMode, filterRfidStore, filterCodCategoria, filterCodSottocategoria, filterCollection, filterMainline, filterModelB2C, filterNewArrivals, filterOnSale, filterPrice, filterMinprice, filterMaxprice, filterYearSeason, filterStyleFilter, filterBodyLengthFilter, filterColorFilter, filterMaterialFilter, filterTypefilter, filterSleeveLengthFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets sizes of models from catalog
         * @summary Search for sizes
         * @param {string} filterBrand brand to be elaborated
         * @param {string} filterCategoria category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebSizesGet(filterBrand: string, filterCategoria: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetModelsSizes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebSizesGet(filterBrand, filterCategoria, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Serve per suggerire altri capi all’utente a partire dal dettaglio del capo corrente
         * @summary Suggerimenti di altri capi
         * @param {string} [filterBrand] brand, comma separated values are admitted
         * @param {string} [filterSkus] sku of item sample, comma separated values are admitted
         * @param {string} [filterLimit] limit of record returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebYmalGet(filterBrand?: string, filterSkus?: string, filterLimit?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetYmalData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebYmalGet(filterBrand, filterSkus, filterLimit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CatalogApi - factory interface
 * @export
 */
export const CatalogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CatalogApiFp(configuration)
    return {
        /**
         * Gets filters and his values
         * @summary Search for advanced filters
         * @param {string} [filterBrand] brand to be filtered
         * @param {string} [filterCategory] category to be filtered
         * @param {'collection' | 'mainline' | 'modelB2C' | 'newArrivals' | 'onSale' | 'price' | 'sizes' | 'stock' | 'yearSeason' | 'occasionFilter' | 'buttoningFilter' | 'comfortableFacet' | 'coatSilhouetteFilter' | 'shapeFilter' | 'bodyLengthFilter' | 'colorFilter' | 'materialFilter' | 'sleeveLengthFilter' | 'styleFilter' | 'typefilter'} [filterCode] code of the filter
         * @param {'Collezione' | 'Main Line' | 'Con Foto' | 'Nuovi Arrivi' | 'In Saldo' | 'Prezzo' | 'Taglie' | 'In giacenza' | 'Anno stagione'} [filterDesc] description of the filter, coupled with code, as price-Prezzo. Use as alternative of code
         * @param {string} [filterVal] value for the specified code or desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebAdvancedFiltersGet(filterBrand?: string, filterCategory?: string, filterCode?: 'collection' | 'mainline' | 'modelB2C' | 'newArrivals' | 'onSale' | 'price' | 'sizes' | 'stock' | 'yearSeason' | 'occasionFilter' | 'buttoningFilter' | 'comfortableFacet' | 'coatSilhouetteFilter' | 'shapeFilter' | 'bodyLengthFilter' | 'colorFilter' | 'materialFilter' | 'sleeveLengthFilter' | 'styleFilter' | 'typefilter', filterDesc?: 'Collezione' | 'Main Line' | 'Con Foto' | 'Nuovi Arrivi' | 'In Saldo' | 'Prezzo' | 'Taglie' | 'In giacenza' | 'Anno stagione', filterVal?: string, options?: any): AxiosPromise<GetAdvancedFilters> {
            return localVarFp.apiV1PoswebAdvancedFiltersGet(filterBrand, filterCategory, filterCode, filterDesc, filterVal, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets categories of models by brand
         * @summary Get list of categories
         * @param {string} filterBrand brand to be elaborated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebCategoriesGet(filterBrand: string, options?: any): AxiosPromise<GetBrandCategories> {
            return localVarFp.apiV1PoswebCategoriesGet(filterBrand, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets sku data from catalog  Parameter \"filter[skip_giacenza]\" at 1 avoids calculating the stock for the sku supplied
         * @summary Search for sku
         * @param {string} [filterSku] SKU to be retrieved
         * @param {string} [filterRfid] RFID to be retrieved
         * @param {string} [filterSkuGruppo] sku_gruppo to be retrieved
         * @param {number} [filterSkipGiacenza] skip calculation of giacenza for the sku supplied
         * @param {string} [filterPkConsumer] consumer pk identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebModelsDataGet(filterSku?: string, filterRfid?: string, filterSkuGruppo?: string, filterSkipGiacenza?: number, filterPkConsumer?: string, options?: any): AxiosPromise<GetSkuData> {
            return localVarFp.apiV1PoswebModelsDataGet(filterSku, filterRfid, filterSkuGruppo, filterSkipGiacenza, filterPkConsumer, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets models from catalog
         * @summary Search for models
         * @param {string} [filterSku] SKU to be elaborated
         * @param {string} [filterGetRelated] when equal to 1 all related models will be returned if sku exists
         * @param {string} [filterPkConsumer] pk_consumer to be elaborated
         * @param {string} [filterBrand] brand to be elaborated, comma separated values are admitted
         * @param {string} [filterCategoria] categoria to be elaborated
         * @param {number} [filterRighe] righe or number of record per page
         * @param {number} [filterPagina] pagina to be elaborated
         * @param {string} [filterModel] model to be elaborated
         * @param {string} [filterModelvariant] modelvariant to be elaborated
         * @param {string} [filterSizes] sizes to be elaborated, | string composed by groups and values values are comma separated, groups are pipe separated
         * @param {0 | 1} [filterStock] 1 or 0 to get stock informations
         * @param {string} [filterName] model name to be elaborated
         * @param {string} [filterEan] ean to be elaborated, comma separated values admitted
         * @param {0 | 1} [filterLikeMode] like_mode used in query instead of &#x3D;
         * @param {string} [filterRfidStore] product rfid
         * @param {string} [filterCodCategoria] product cod categoria
         * @param {string} [filterCodSottocategoria] product cod sottocategoria
         * @param {string} [filterCollection] filtri avanzati, collection
         * @param {string} [filterMainline] filtri avanzati, mainline
         * @param {string} [filterModelB2C] filtri avanzati, modelB2C
         * @param {string} [filterNewArrivals] filtri avanzati, newArrivals
         * @param {string} [filterOnSale] filtri avanzati, onSale
         * @param {string} [filterPrice] filtri avanzati, price
         * @param {number} [filterMinprice] filtri avanzati, minprice
         * @param {number} [filterMaxprice] filtri avanzati, maxprice
         * @param {string} [filterYearSeason] filtri avanzati, yearSeason
         * @param {string} [filterStyleFilter] filtri avanzati, styleFilter
         * @param {string} [filterBodyLengthFilter] filtri avanzati, bodyLengthFilter
         * @param {string} [filterColorFilter] filtri avanzati, colorFilter
         * @param {string} [filterMaterialFilter] filtri avanzati, materialFilter
         * @param {string} [filterTypefilter] filtri avanzati, typefilter
         * @param {string} [filterSleeveLengthFilter] filtri avanzati, sleeveLengthFilter
         * @param {string} [filterOccasionFilter] filtri avanzati, occasionFilter
         * @param {string} [filterButtoningFilter] filtri avanzati, buttoningFilter
         * @param {string} [filterComfortableFacet] filtri avanzati, comfortableFacet
         * @param {string} [filterCoatSilhouetteFilter] filtri avanzati, coatSilhouetteFilter
         * @param {string} [filterShapeFilter] filtri avanzati, shapeFilter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebModelsGet(filterSku?: string, filterGetRelated?: string, filterPkConsumer?: string, filterBrand?: string, filterCategoria?: string, filterRighe?: number, filterPagina?: number, filterModel?: string, filterModelvariant?: string, filterSizes?: string, filterStock?: 0 | 1, filterName?: string, filterEan?: string, filterLikeMode?: 0 | 1, filterRfidStore?: string, filterCodCategoria?: string, filterCodSottocategoria?: string, filterCollection?: string, filterMainline?: string, filterModelB2C?: string, filterNewArrivals?: string, filterOnSale?: string, filterPrice?: string, filterMinprice?: number, filterMaxprice?: number, filterYearSeason?: string, filterStyleFilter?: string, filterBodyLengthFilter?: string, filterColorFilter?: string, filterMaterialFilter?: string, filterTypefilter?: string, filterSleeveLengthFilter?: string, filterOccasionFilter?: string, filterButtoningFilter?: string, filterComfortableFacet?: string, filterCoatSilhouetteFilter?: string, filterShapeFilter?: string, options?: any): AxiosPromise<GetModels> {
            return localVarFp.apiV1PoswebModelsGet(filterSku, filterGetRelated, filterPkConsumer, filterBrand, filterCategoria, filterRighe, filterPagina, filterModel, filterModelvariant, filterSizes, filterStock, filterName, filterEan, filterLikeMode, filterRfidStore, filterCodCategoria, filterCodSottocategoria, filterCollection, filterMainline, filterModelB2C, filterNewArrivals, filterOnSale, filterPrice, filterMinprice, filterMaxprice, filterYearSeason, filterStyleFilter, filterBodyLengthFilter, filterColorFilter, filterMaterialFilter, filterTypefilter, filterSleeveLengthFilter, filterOccasionFilter, filterButtoningFilter, filterComfortableFacet, filterCoatSilhouetteFilter, filterShapeFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets sku data from catalog
         * @summary Obtain giacenza data for sku
         * @param {string} filterSku SKU to be elaborated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebModelsGiacenzaSkuGet(filterSku: string, options?: any): AxiosPromise<GetSkuGiacenza> {
            return localVarFp.apiV1PoswebModelsGiacenzaSkuGet(filterSku, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets model data from catalog
         * @summary Search for model detail
         * @param {string} model Model to be elaborated
         * @param {string} [filterPkConsumer] pk_consumer to be elaborated
         * @param {string} [filterBrand] brand to be elaborated, comma separated values are admitted
         * @param {string} [filterCategoria] categoria to be elaborated
         * @param {number} [filterRighe] righe or number of record per page
         * @param {number} [filterPagina] pagina to be elaborated
         * @param {string} [filterModelvariant] modelvariant to be elaborated
         * @param {string} [filterSizes] sizes to be elaborated, | string composed by groups and values values are comma separated, groups are pipe separated
         * @param {string} [filterStock] 1 or 0 to get stock informations
         * @param {string} [filterName] name to be elaborated
         * @param {string} [filterEan] ean to be elaborated, comma separated values admitted
         * @param {0 | 1} [filterLikeMode] like_mode used in query instead of &#x3D;
         * @param {string} [filterRfidStore] product rfid
         * @param {string} [filterCodCategoria] product cod categoria
         * @param {string} [filterCodSottocategoria] product cod sottocategoria
         * @param {string} [filterCollection] filtri avanzati, collection
         * @param {string} [filterMainline] filtri avanzati, mainline
         * @param {string} [filterModelB2C] filtri avanzati, modelB2C
         * @param {string} [filterNewArrivals] filtri avanzati, newArrivals
         * @param {string} [filterOnSale] filtri avanzati, onSale
         * @param {string} [filterPrice] filtri avanzati, price
         * @param {number} [filterMinprice] filtri avanzati, minprice
         * @param {number} [filterMaxprice] filtri avanzati, maxprice
         * @param {string} [filterYearSeason] filtri avanzati, yearSeason
         * @param {string} [filterStyleFilter] filtri avanzati, styleFilter
         * @param {string} [filterBodyLengthFilter] filtri avanzati, bodyLengthFilter
         * @param {string} [filterColorFilter] filtri avanzati, colorFilter
         * @param {string} [filterMaterialFilter] filtri avanzati, materialFilter
         * @param {string} [filterTypefilter] filtri avanzati, typefilter
         * @param {string} [filterSleeveLengthFilter] filtri avanzati, sleeveLengthFilter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebModelsModelGet(model: string, filterPkConsumer?: string, filterBrand?: string, filterCategoria?: string, filterRighe?: number, filterPagina?: number, filterModelvariant?: string, filterSizes?: string, filterStock?: string, filterName?: string, filterEan?: string, filterLikeMode?: 0 | 1, filterRfidStore?: string, filterCodCategoria?: string, filterCodSottocategoria?: string, filterCollection?: string, filterMainline?: string, filterModelB2C?: string, filterNewArrivals?: string, filterOnSale?: string, filterPrice?: string, filterMinprice?: number, filterMaxprice?: number, filterYearSeason?: string, filterStyleFilter?: string, filterBodyLengthFilter?: string, filterColorFilter?: string, filterMaterialFilter?: string, filterTypefilter?: string, filterSleeveLengthFilter?: string, options?: any): AxiosPromise<GetModelData> {
            return localVarFp.apiV1PoswebModelsModelGet(model, filterPkConsumer, filterBrand, filterCategoria, filterRighe, filterPagina, filterModelvariant, filterSizes, filterStock, filterName, filterEan, filterLikeMode, filterRfidStore, filterCodCategoria, filterCodSottocategoria, filterCollection, filterMainline, filterModelB2C, filterNewArrivals, filterOnSale, filterPrice, filterMinprice, filterMaxprice, filterYearSeason, filterStyleFilter, filterBodyLengthFilter, filterColorFilter, filterMaterialFilter, filterTypefilter, filterSleeveLengthFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets sizes of models from catalog
         * @summary Search for sizes
         * @param {string} filterBrand brand to be elaborated
         * @param {string} filterCategoria category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebSizesGet(filterBrand: string, filterCategoria: string, options?: any): AxiosPromise<GetModelsSizes> {
            return localVarFp.apiV1PoswebSizesGet(filterBrand, filterCategoria, options).then((request) => request(axios, basePath));
        },
        /**
         * Serve per suggerire altri capi all’utente a partire dal dettaglio del capo corrente
         * @summary Suggerimenti di altri capi
         * @param {string} [filterBrand] brand, comma separated values are admitted
         * @param {string} [filterSkus] sku of item sample, comma separated values are admitted
         * @param {string} [filterLimit] limit of record returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebYmalGet(filterBrand?: string, filterSkus?: string, filterLimit?: string, options?: any): AxiosPromise<GetYmalData> {
            return localVarFp.apiV1PoswebYmalGet(filterBrand, filterSkus, filterLimit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CatalogApi - object-oriented interface
 * @export
 * @class CatalogApi
 * @extends {BaseAPI}
 */
export class CatalogApi extends BaseAPI {
    /**
     * Gets filters and his values
     * @summary Search for advanced filters
     * @param {string} [filterBrand] brand to be filtered
     * @param {string} [filterCategory] category to be filtered
     * @param {'collection' | 'mainline' | 'modelB2C' | 'newArrivals' | 'onSale' | 'price' | 'sizes' | 'stock' | 'yearSeason' | 'occasionFilter' | 'buttoningFilter' | 'comfortableFacet' | 'coatSilhouetteFilter' | 'shapeFilter' | 'bodyLengthFilter' | 'colorFilter' | 'materialFilter' | 'sleeveLengthFilter' | 'styleFilter' | 'typefilter'} [filterCode] code of the filter
     * @param {'Collezione' | 'Main Line' | 'Con Foto' | 'Nuovi Arrivi' | 'In Saldo' | 'Prezzo' | 'Taglie' | 'In giacenza' | 'Anno stagione'} [filterDesc] description of the filter, coupled with code, as price-Prezzo. Use as alternative of code
     * @param {string} [filterVal] value for the specified code or desc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogApi
     */
    public apiV1PoswebAdvancedFiltersGet(filterBrand?: string, filterCategory?: string, filterCode?: 'collection' | 'mainline' | 'modelB2C' | 'newArrivals' | 'onSale' | 'price' | 'sizes' | 'stock' | 'yearSeason' | 'occasionFilter' | 'buttoningFilter' | 'comfortableFacet' | 'coatSilhouetteFilter' | 'shapeFilter' | 'bodyLengthFilter' | 'colorFilter' | 'materialFilter' | 'sleeveLengthFilter' | 'styleFilter' | 'typefilter', filterDesc?: 'Collezione' | 'Main Line' | 'Con Foto' | 'Nuovi Arrivi' | 'In Saldo' | 'Prezzo' | 'Taglie' | 'In giacenza' | 'Anno stagione', filterVal?: string, options?: AxiosRequestConfig) {
        return CatalogApiFp(this.configuration).apiV1PoswebAdvancedFiltersGet(filterBrand, filterCategory, filterCode, filterDesc, filterVal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets categories of models by brand
     * @summary Get list of categories
     * @param {string} filterBrand brand to be elaborated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogApi
     */
    public apiV1PoswebCategoriesGet(filterBrand: string, options?: AxiosRequestConfig) {
        return CatalogApiFp(this.configuration).apiV1PoswebCategoriesGet(filterBrand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets sku data from catalog  Parameter \"filter[skip_giacenza]\" at 1 avoids calculating the stock for the sku supplied
     * @summary Search for sku
     * @param {string} [filterSku] SKU to be retrieved
     * @param {string} [filterRfid] RFID to be retrieved
     * @param {string} [filterSkuGruppo] sku_gruppo to be retrieved
     * @param {number} [filterSkipGiacenza] skip calculation of giacenza for the sku supplied
     * @param {string} [filterPkConsumer] consumer pk identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogApi
     */
    public apiV1PoswebModelsDataGet(filterSku?: string, filterRfid?: string, filterSkuGruppo?: string, filterSkipGiacenza?: number, filterPkConsumer?: string, options?: AxiosRequestConfig) {
        return CatalogApiFp(this.configuration).apiV1PoswebModelsDataGet(filterSku, filterRfid, filterSkuGruppo, filterSkipGiacenza, filterPkConsumer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets models from catalog
     * @summary Search for models
     * @param {string} [filterSku] SKU to be elaborated
     * @param {string} [filterGetRelated] when equal to 1 all related models will be returned if sku exists
     * @param {string} [filterPkConsumer] pk_consumer to be elaborated
     * @param {string} [filterBrand] brand to be elaborated, comma separated values are admitted
     * @param {string} [filterCategoria] categoria to be elaborated
     * @param {number} [filterRighe] righe or number of record per page
     * @param {number} [filterPagina] pagina to be elaborated
     * @param {string} [filterModel] model to be elaborated
     * @param {string} [filterModelvariant] modelvariant to be elaborated
     * @param {string} [filterSizes] sizes to be elaborated, | string composed by groups and values values are comma separated, groups are pipe separated
     * @param {0 | 1} [filterStock] 1 or 0 to get stock informations
     * @param {string} [filterName] model name to be elaborated
     * @param {string} [filterEan] ean to be elaborated, comma separated values admitted
     * @param {0 | 1} [filterLikeMode] like_mode used in query instead of &#x3D;
     * @param {string} [filterRfidStore] product rfid
     * @param {string} [filterCodCategoria] product cod categoria
     * @param {string} [filterCodSottocategoria] product cod sottocategoria
     * @param {string} [filterCollection] filtri avanzati, collection
     * @param {string} [filterMainline] filtri avanzati, mainline
     * @param {string} [filterModelB2C] filtri avanzati, modelB2C
     * @param {string} [filterNewArrivals] filtri avanzati, newArrivals
     * @param {string} [filterOnSale] filtri avanzati, onSale
     * @param {string} [filterPrice] filtri avanzati, price
     * @param {number} [filterMinprice] filtri avanzati, minprice
     * @param {number} [filterMaxprice] filtri avanzati, maxprice
     * @param {string} [filterYearSeason] filtri avanzati, yearSeason
     * @param {string} [filterStyleFilter] filtri avanzati, styleFilter
     * @param {string} [filterBodyLengthFilter] filtri avanzati, bodyLengthFilter
     * @param {string} [filterColorFilter] filtri avanzati, colorFilter
     * @param {string} [filterMaterialFilter] filtri avanzati, materialFilter
     * @param {string} [filterTypefilter] filtri avanzati, typefilter
     * @param {string} [filterSleeveLengthFilter] filtri avanzati, sleeveLengthFilter
     * @param {string} [filterOccasionFilter] filtri avanzati, occasionFilter
     * @param {string} [filterButtoningFilter] filtri avanzati, buttoningFilter
     * @param {string} [filterComfortableFacet] filtri avanzati, comfortableFacet
     * @param {string} [filterCoatSilhouetteFilter] filtri avanzati, coatSilhouetteFilter
     * @param {string} [filterShapeFilter] filtri avanzati, shapeFilter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogApi
     */
    public apiV1PoswebModelsGet(filterSku?: string, filterGetRelated?: string, filterPkConsumer?: string, filterBrand?: string, filterCategoria?: string, filterRighe?: number, filterPagina?: number, filterModel?: string, filterModelvariant?: string, filterSizes?: string, filterStock?: 0 | 1, filterName?: string, filterEan?: string, filterLikeMode?: 0 | 1, filterRfidStore?: string, filterCodCategoria?: string, filterCodSottocategoria?: string, filterCollection?: string, filterMainline?: string, filterModelB2C?: string, filterNewArrivals?: string, filterOnSale?: string, filterPrice?: string, filterMinprice?: number, filterMaxprice?: number, filterYearSeason?: string, filterStyleFilter?: string, filterBodyLengthFilter?: string, filterColorFilter?: string, filterMaterialFilter?: string, filterTypefilter?: string, filterSleeveLengthFilter?: string, filterOccasionFilter?: string, filterButtoningFilter?: string, filterComfortableFacet?: string, filterCoatSilhouetteFilter?: string, filterShapeFilter?: string, options?: AxiosRequestConfig) {
        return CatalogApiFp(this.configuration).apiV1PoswebModelsGet(filterSku, filterGetRelated, filterPkConsumer, filterBrand, filterCategoria, filterRighe, filterPagina, filterModel, filterModelvariant, filterSizes, filterStock, filterName, filterEan, filterLikeMode, filterRfidStore, filterCodCategoria, filterCodSottocategoria, filterCollection, filterMainline, filterModelB2C, filterNewArrivals, filterOnSale, filterPrice, filterMinprice, filterMaxprice, filterYearSeason, filterStyleFilter, filterBodyLengthFilter, filterColorFilter, filterMaterialFilter, filterTypefilter, filterSleeveLengthFilter, filterOccasionFilter, filterButtoningFilter, filterComfortableFacet, filterCoatSilhouetteFilter, filterShapeFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets sku data from catalog
     * @summary Obtain giacenza data for sku
     * @param {string} filterSku SKU to be elaborated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogApi
     */
    public apiV1PoswebModelsGiacenzaSkuGet(filterSku: string, options?: AxiosRequestConfig) {
        return CatalogApiFp(this.configuration).apiV1PoswebModelsGiacenzaSkuGet(filterSku, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets model data from catalog
     * @summary Search for model detail
     * @param {string} model Model to be elaborated
     * @param {string} [filterPkConsumer] pk_consumer to be elaborated
     * @param {string} [filterBrand] brand to be elaborated, comma separated values are admitted
     * @param {string} [filterCategoria] categoria to be elaborated
     * @param {number} [filterRighe] righe or number of record per page
     * @param {number} [filterPagina] pagina to be elaborated
     * @param {string} [filterModelvariant] modelvariant to be elaborated
     * @param {string} [filterSizes] sizes to be elaborated, | string composed by groups and values values are comma separated, groups are pipe separated
     * @param {string} [filterStock] 1 or 0 to get stock informations
     * @param {string} [filterName] name to be elaborated
     * @param {string} [filterEan] ean to be elaborated, comma separated values admitted
     * @param {0 | 1} [filterLikeMode] like_mode used in query instead of &#x3D;
     * @param {string} [filterRfidStore] product rfid
     * @param {string} [filterCodCategoria] product cod categoria
     * @param {string} [filterCodSottocategoria] product cod sottocategoria
     * @param {string} [filterCollection] filtri avanzati, collection
     * @param {string} [filterMainline] filtri avanzati, mainline
     * @param {string} [filterModelB2C] filtri avanzati, modelB2C
     * @param {string} [filterNewArrivals] filtri avanzati, newArrivals
     * @param {string} [filterOnSale] filtri avanzati, onSale
     * @param {string} [filterPrice] filtri avanzati, price
     * @param {number} [filterMinprice] filtri avanzati, minprice
     * @param {number} [filterMaxprice] filtri avanzati, maxprice
     * @param {string} [filterYearSeason] filtri avanzati, yearSeason
     * @param {string} [filterStyleFilter] filtri avanzati, styleFilter
     * @param {string} [filterBodyLengthFilter] filtri avanzati, bodyLengthFilter
     * @param {string} [filterColorFilter] filtri avanzati, colorFilter
     * @param {string} [filterMaterialFilter] filtri avanzati, materialFilter
     * @param {string} [filterTypefilter] filtri avanzati, typefilter
     * @param {string} [filterSleeveLengthFilter] filtri avanzati, sleeveLengthFilter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogApi
     */
    public apiV1PoswebModelsModelGet(model: string, filterPkConsumer?: string, filterBrand?: string, filterCategoria?: string, filterRighe?: number, filterPagina?: number, filterModelvariant?: string, filterSizes?: string, filterStock?: string, filterName?: string, filterEan?: string, filterLikeMode?: 0 | 1, filterRfidStore?: string, filterCodCategoria?: string, filterCodSottocategoria?: string, filterCollection?: string, filterMainline?: string, filterModelB2C?: string, filterNewArrivals?: string, filterOnSale?: string, filterPrice?: string, filterMinprice?: number, filterMaxprice?: number, filterYearSeason?: string, filterStyleFilter?: string, filterBodyLengthFilter?: string, filterColorFilter?: string, filterMaterialFilter?: string, filterTypefilter?: string, filterSleeveLengthFilter?: string, options?: AxiosRequestConfig) {
        return CatalogApiFp(this.configuration).apiV1PoswebModelsModelGet(model, filterPkConsumer, filterBrand, filterCategoria, filterRighe, filterPagina, filterModelvariant, filterSizes, filterStock, filterName, filterEan, filterLikeMode, filterRfidStore, filterCodCategoria, filterCodSottocategoria, filterCollection, filterMainline, filterModelB2C, filterNewArrivals, filterOnSale, filterPrice, filterMinprice, filterMaxprice, filterYearSeason, filterStyleFilter, filterBodyLengthFilter, filterColorFilter, filterMaterialFilter, filterTypefilter, filterSleeveLengthFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets sizes of models from catalog
     * @summary Search for sizes
     * @param {string} filterBrand brand to be elaborated
     * @param {string} filterCategoria category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogApi
     */
    public apiV1PoswebSizesGet(filterBrand: string, filterCategoria: string, options?: AxiosRequestConfig) {
        return CatalogApiFp(this.configuration).apiV1PoswebSizesGet(filterBrand, filterCategoria, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Serve per suggerire altri capi all’utente a partire dal dettaglio del capo corrente
     * @summary Suggerimenti di altri capi
     * @param {string} [filterBrand] brand, comma separated values are admitted
     * @param {string} [filterSkus] sku of item sample, comma separated values are admitted
     * @param {string} [filterLimit] limit of record returned
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogApi
     */
    public apiV1PoswebYmalGet(filterBrand?: string, filterSkus?: string, filterLimit?: string, options?: AxiosRequestConfig) {
        return CatalogApiFp(this.configuration).apiV1PoswebYmalGet(filterBrand, filterSkus, filterLimit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChiusuraGiornalieraApi - axios parameter creator
 * @export
 */
export const ChiusuraGiornalieraApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * closing status and commands to be used for daily closing
         * @summary List commands to be used for daily closing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChiusuracassaChiusuraGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/chiusuracassa/chiusura`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Close credit card machine
         * @summary Close credit card machine
         * @param {CloseCreditCardMachine} [closeCreditCardMachine]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChiusuracassaChiusuraPosPost: async (closeCreditCardMachine?: CloseCreditCardMachine, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/chiusuracassa/chiusura-pos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(closeCreditCardMachine, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Conferma chiusura  Nella risposta, la chiave brwoser_download può non essere presente  le chiavi result e message sono stringhe vuote!
         * @summary Conferma chiusura
         * @param {RequestCashRegisterClosing1} [requestCashRegisterClosing1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChiusuracassaConfermaChiusuraPost: async (requestCashRegisterClosing1?: RequestCashRegisterClosing1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/chiusuracassa/conferma-chiusura`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestCashRegisterClosing1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get data about credits card totals  Nella risposta, l\'attributo \"data\" contiene i valori \"descrizione_esito_negativo, codice_errore\" se l\'attributo message non è \"OK\"  altrimenti contiene gli altri campi con le informazioni
         * @summary Get data about credits card totals
         * @param {CloseCreditCardMachine1} [closeCreditCardMachine1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChiusuracassaRispostaCartaTotaliPost: async (closeCreditCardMachine1?: CloseCreditCardMachine1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/chiusuracassa/risposta-carta-totali`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(closeCreditCardMachine1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get print of cash register closing
         * @summary Get print of cash register closing
         * @param {RequestCashRegisterClosing} [requestCashRegisterClosing]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChiusuracassaStampaChiusuraRegistratorePost: async (requestCashRegisterClosing?: RequestCashRegisterClosing, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/chiusuracassa/stampa-chiusura-registratore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestCashRegisterClosing, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get print of credit cards summary
         * @summary Get print of credit cards summary
         * @param {RequestReditCardSummary} [requestReditCardSummary]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChiusuracassaStampaResocontoCartaPost: async (requestReditCardSummary?: RequestReditCardSummary, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/chiusuracassa/stampa-resoconto-carta`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestReditCardSummary, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stampa report gift card
         * @summary Stampa report gift card
         * @param {RequestGiftCardReport} [requestGiftCardReport]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChiusuracassaStampaResocontoGiftCardPost: async (requestGiftCardReport?: RequestGiftCardReport, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/chiusuracassa/stampa-resoconto-gift-card`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestGiftCardReport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get print of daily summary
         * @summary Get print of daily summary
         * @param {RequestDailyTotals} [requestDailyTotals]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChiusuracassaStampaRiepilogoGiornalieroPost: async (requestDailyTotals?: RequestDailyTotals, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/chiusuracassa/stampa-riepilogo-giornaliero`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestDailyTotals, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Print credit card machines report
         * @summary Print credit card machines report
         * @param {CallThePrintTotalsOnCreditCardMachine} [callThePrintTotalsOnCreditCardMachine]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChiusuracassaStampaTotaliPosPost: async (callThePrintTotalsOnCreditCardMachine?: CallThePrintTotalsOnCreditCardMachine, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/chiusuracassa/stampa-totali-pos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(callThePrintTotalsOnCreditCardMachine, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChiusuraGiornalieraApi - functional programming interface
 * @export
 */
export const ChiusuraGiornalieraApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChiusuraGiornalieraApiAxiosParamCreator(configuration)
    return {
        /**
         * closing status and commands to be used for daily closing
         * @summary List commands to be used for daily closing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChiusuracassaChiusuraGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePayload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChiusuracassaChiusuraGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Close credit card machine
         * @summary Close credit card machine
         * @param {CloseCreditCardMachine} [closeCreditCardMachine]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChiusuracassaChiusuraPosPost(closeCreditCardMachine?: CloseCreditCardMachine, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePayload2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChiusuracassaChiusuraPosPost(closeCreditCardMachine, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Conferma chiusura  Nella risposta, la chiave brwoser_download può non essere presente  le chiavi result e message sono stringhe vuote!
         * @summary Conferma chiusura
         * @param {RequestCashRegisterClosing1} [requestCashRegisterClosing1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChiusuracassaConfermaChiusuraPost(requestCashRegisterClosing1?: RequestCashRegisterClosing1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePayload8>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChiusuracassaConfermaChiusuraPost(requestCashRegisterClosing1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get data about credits card totals  Nella risposta, l\'attributo \"data\" contiene i valori \"descrizione_esito_negativo, codice_errore\" se l\'attributo message non è \"OK\"  altrimenti contiene gli altri campi con le informazioni
         * @summary Get data about credits card totals
         * @param {CloseCreditCardMachine1} [closeCreditCardMachine1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChiusuracassaRispostaCartaTotaliPost(closeCreditCardMachine1?: CloseCreditCardMachine1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePayload7>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChiusuracassaRispostaCartaTotaliPost(closeCreditCardMachine1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get print of cash register closing
         * @summary Get print of cash register closing
         * @param {RequestCashRegisterClosing} [requestCashRegisterClosing]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChiusuracassaStampaChiusuraRegistratorePost(requestCashRegisterClosing?: RequestCashRegisterClosing, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePayload6>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChiusuracassaStampaChiusuraRegistratorePost(requestCashRegisterClosing, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get print of credit cards summary
         * @summary Get print of credit cards summary
         * @param {RequestReditCardSummary} [requestReditCardSummary]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChiusuracassaStampaResocontoCartaPost(requestReditCardSummary?: RequestReditCardSummary, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePayload5>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChiusuracassaStampaResocontoCartaPost(requestReditCardSummary, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stampa report gift card
         * @summary Stampa report gift card
         * @param {RequestGiftCardReport} [requestGiftCardReport]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChiusuracassaStampaResocontoGiftCardPost(requestGiftCardReport?: RequestGiftCardReport, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePayload3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChiusuracassaStampaResocontoGiftCardPost(requestGiftCardReport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get print of daily summary
         * @summary Get print of daily summary
         * @param {RequestDailyTotals} [requestDailyTotals]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChiusuracassaStampaRiepilogoGiornalieroPost(requestDailyTotals?: RequestDailyTotals, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePayload4>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChiusuracassaStampaRiepilogoGiornalieroPost(requestDailyTotals, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Print credit card machines report
         * @summary Print credit card machines report
         * @param {CallThePrintTotalsOnCreditCardMachine} [callThePrintTotalsOnCreditCardMachine]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChiusuracassaStampaTotaliPosPost(callThePrintTotalsOnCreditCardMachine?: CallThePrintTotalsOnCreditCardMachine, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePayload1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChiusuracassaStampaTotaliPosPost(callThePrintTotalsOnCreditCardMachine, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChiusuraGiornalieraApi - factory interface
 * @export
 */
export const ChiusuraGiornalieraApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChiusuraGiornalieraApiFp(configuration)
    return {
        /**
         * closing status and commands to be used for daily closing
         * @summary List commands to be used for daily closing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChiusuracassaChiusuraGet(options?: any): AxiosPromise<ResponsePayload> {
            return localVarFp.apiV1ChiusuracassaChiusuraGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Close credit card machine
         * @summary Close credit card machine
         * @param {CloseCreditCardMachine} [closeCreditCardMachine]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChiusuracassaChiusuraPosPost(closeCreditCardMachine?: CloseCreditCardMachine, options?: any): AxiosPromise<ResponsePayload2> {
            return localVarFp.apiV1ChiusuracassaChiusuraPosPost(closeCreditCardMachine, options).then((request) => request(axios, basePath));
        },
        /**
         * Conferma chiusura  Nella risposta, la chiave brwoser_download può non essere presente  le chiavi result e message sono stringhe vuote!
         * @summary Conferma chiusura
         * @param {RequestCashRegisterClosing1} [requestCashRegisterClosing1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChiusuracassaConfermaChiusuraPost(requestCashRegisterClosing1?: RequestCashRegisterClosing1, options?: any): AxiosPromise<ResponsePayload8> {
            return localVarFp.apiV1ChiusuracassaConfermaChiusuraPost(requestCashRegisterClosing1, options).then((request) => request(axios, basePath));
        },
        /**
         * Get data about credits card totals  Nella risposta, l\'attributo \"data\" contiene i valori \"descrizione_esito_negativo, codice_errore\" se l\'attributo message non è \"OK\"  altrimenti contiene gli altri campi con le informazioni
         * @summary Get data about credits card totals
         * @param {CloseCreditCardMachine1} [closeCreditCardMachine1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChiusuracassaRispostaCartaTotaliPost(closeCreditCardMachine1?: CloseCreditCardMachine1, options?: any): AxiosPromise<ResponsePayload7> {
            return localVarFp.apiV1ChiusuracassaRispostaCartaTotaliPost(closeCreditCardMachine1, options).then((request) => request(axios, basePath));
        },
        /**
         * Get print of cash register closing
         * @summary Get print of cash register closing
         * @param {RequestCashRegisterClosing} [requestCashRegisterClosing]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChiusuracassaStampaChiusuraRegistratorePost(requestCashRegisterClosing?: RequestCashRegisterClosing, options?: any): AxiosPromise<ResponsePayload6> {
            return localVarFp.apiV1ChiusuracassaStampaChiusuraRegistratorePost(requestCashRegisterClosing, options).then((request) => request(axios, basePath));
        },
        /**
         * Get print of credit cards summary
         * @summary Get print of credit cards summary
         * @param {RequestReditCardSummary} [requestReditCardSummary]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChiusuracassaStampaResocontoCartaPost(requestReditCardSummary?: RequestReditCardSummary, options?: any): AxiosPromise<ResponsePayload5> {
            return localVarFp.apiV1ChiusuracassaStampaResocontoCartaPost(requestReditCardSummary, options).then((request) => request(axios, basePath));
        },
        /**
         * Stampa report gift card
         * @summary Stampa report gift card
         * @param {RequestGiftCardReport} [requestGiftCardReport]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChiusuracassaStampaResocontoGiftCardPost(requestGiftCardReport?: RequestGiftCardReport, options?: any): AxiosPromise<ResponsePayload3> {
            return localVarFp.apiV1ChiusuracassaStampaResocontoGiftCardPost(requestGiftCardReport, options).then((request) => request(axios, basePath));
        },
        /**
         * Get print of daily summary
         * @summary Get print of daily summary
         * @param {RequestDailyTotals} [requestDailyTotals]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChiusuracassaStampaRiepilogoGiornalieroPost(requestDailyTotals?: RequestDailyTotals, options?: any): AxiosPromise<ResponsePayload4> {
            return localVarFp.apiV1ChiusuracassaStampaRiepilogoGiornalieroPost(requestDailyTotals, options).then((request) => request(axios, basePath));
        },
        /**
         * Print credit card machines report
         * @summary Print credit card machines report
         * @param {CallThePrintTotalsOnCreditCardMachine} [callThePrintTotalsOnCreditCardMachine]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChiusuracassaStampaTotaliPosPost(callThePrintTotalsOnCreditCardMachine?: CallThePrintTotalsOnCreditCardMachine, options?: any): AxiosPromise<ResponsePayload1> {
            return localVarFp.apiV1ChiusuracassaStampaTotaliPosPost(callThePrintTotalsOnCreditCardMachine, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChiusuraGiornalieraApi - object-oriented interface
 * @export
 * @class ChiusuraGiornalieraApi
 * @extends {BaseAPI}
 */
export class ChiusuraGiornalieraApi extends BaseAPI {
    /**
     * closing status and commands to be used for daily closing
     * @summary List commands to be used for daily closing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChiusuraGiornalieraApi
     */
    public apiV1ChiusuracassaChiusuraGet(options?: AxiosRequestConfig) {
        return ChiusuraGiornalieraApiFp(this.configuration).apiV1ChiusuracassaChiusuraGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Close credit card machine
     * @summary Close credit card machine
     * @param {CloseCreditCardMachine} [closeCreditCardMachine]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChiusuraGiornalieraApi
     */
    public apiV1ChiusuracassaChiusuraPosPost(closeCreditCardMachine?: CloseCreditCardMachine, options?: AxiosRequestConfig) {
        return ChiusuraGiornalieraApiFp(this.configuration).apiV1ChiusuracassaChiusuraPosPost(closeCreditCardMachine, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Conferma chiusura  Nella risposta, la chiave brwoser_download può non essere presente  le chiavi result e message sono stringhe vuote!
     * @summary Conferma chiusura
     * @param {RequestCashRegisterClosing1} [requestCashRegisterClosing1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChiusuraGiornalieraApi
     */
    public apiV1ChiusuracassaConfermaChiusuraPost(requestCashRegisterClosing1?: RequestCashRegisterClosing1, options?: AxiosRequestConfig) {
        return ChiusuraGiornalieraApiFp(this.configuration).apiV1ChiusuracassaConfermaChiusuraPost(requestCashRegisterClosing1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get data about credits card totals  Nella risposta, l\'attributo \"data\" contiene i valori \"descrizione_esito_negativo, codice_errore\" se l\'attributo message non è \"OK\"  altrimenti contiene gli altri campi con le informazioni
     * @summary Get data about credits card totals
     * @param {CloseCreditCardMachine1} [closeCreditCardMachine1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChiusuraGiornalieraApi
     */
    public apiV1ChiusuracassaRispostaCartaTotaliPost(closeCreditCardMachine1?: CloseCreditCardMachine1, options?: AxiosRequestConfig) {
        return ChiusuraGiornalieraApiFp(this.configuration).apiV1ChiusuracassaRispostaCartaTotaliPost(closeCreditCardMachine1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get print of cash register closing
     * @summary Get print of cash register closing
     * @param {RequestCashRegisterClosing} [requestCashRegisterClosing]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChiusuraGiornalieraApi
     */
    public apiV1ChiusuracassaStampaChiusuraRegistratorePost(requestCashRegisterClosing?: RequestCashRegisterClosing, options?: AxiosRequestConfig) {
        return ChiusuraGiornalieraApiFp(this.configuration).apiV1ChiusuracassaStampaChiusuraRegistratorePost(requestCashRegisterClosing, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get print of credit cards summary
     * @summary Get print of credit cards summary
     * @param {RequestReditCardSummary} [requestReditCardSummary]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChiusuraGiornalieraApi
     */
    public apiV1ChiusuracassaStampaResocontoCartaPost(requestReditCardSummary?: RequestReditCardSummary, options?: AxiosRequestConfig) {
        return ChiusuraGiornalieraApiFp(this.configuration).apiV1ChiusuracassaStampaResocontoCartaPost(requestReditCardSummary, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stampa report gift card
     * @summary Stampa report gift card
     * @param {RequestGiftCardReport} [requestGiftCardReport]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChiusuraGiornalieraApi
     */
    public apiV1ChiusuracassaStampaResocontoGiftCardPost(requestGiftCardReport?: RequestGiftCardReport, options?: AxiosRequestConfig) {
        return ChiusuraGiornalieraApiFp(this.configuration).apiV1ChiusuracassaStampaResocontoGiftCardPost(requestGiftCardReport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get print of daily summary
     * @summary Get print of daily summary
     * @param {RequestDailyTotals} [requestDailyTotals]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChiusuraGiornalieraApi
     */
    public apiV1ChiusuracassaStampaRiepilogoGiornalieroPost(requestDailyTotals?: RequestDailyTotals, options?: AxiosRequestConfig) {
        return ChiusuraGiornalieraApiFp(this.configuration).apiV1ChiusuracassaStampaRiepilogoGiornalieroPost(requestDailyTotals, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Print credit card machines report
     * @summary Print credit card machines report
     * @param {CallThePrintTotalsOnCreditCardMachine} [callThePrintTotalsOnCreditCardMachine]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChiusuraGiornalieraApi
     */
    public apiV1ChiusuracassaStampaTotaliPosPost(callThePrintTotalsOnCreditCardMachine?: CallThePrintTotalsOnCreditCardMachine, options?: AxiosRequestConfig) {
        return ChiusuraGiornalieraApiFp(this.configuration).apiV1ChiusuracassaStampaTotaliPosPost(callThePrintTotalsOnCreditCardMachine, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConsumerApi - axios parameter creator
 * @export
 */
export const ConsumerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets dictionary of anonymous consumers nation codes by pk
         * @summary Anonymous Consumer search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AnonymousConsumerGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/anonymous-consumer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Effettua la validazione dell\'indirizzo inviato. In alternativa, propone altri indirizzi consigliati
         * @summary Validazione dell\'indirizzo
         * @param {InlineObject2} [inlineObject2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersAddressValidationPost: async (inlineObject2?: InlineObject2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/consumers/address_validation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Api per ottenere l\'elenco delle nazioni corrispondenti alle consumatrici anonime codificate come nazioni,  da usare nella ricerca in \"vendita\"
         * @summary Nazioni corrispondenti a consumer anonymous
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersCountriesAnonymousGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/consumers/countries-anonymous`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List available consumers. SHOULD BE USED WHEN THE FILTERS CONTAINS AT LIST 3 CHARS. Search can be performed using those filter combination: * source: mandatory  mutually exclusive: * query * cognome + (optional) - username * email * barcode * pk_consumer * email * telefono  The last filter[ultime_modifiche] is optional and will be considered only with filter[pk_consumer_search] with ONE pk!
         * @summary Consumer search
         * @param {'local' | 'company'} [filterSource] Segregation flag: * \&#39;local\&#39; is for in-store lookup only consumers related to the store are returned. * \&#39;company\&#39; proxies the search to the corporate crm
         * @param {'+.*' | '.*@.*' | 'pk-.*' | '\\d*' | '.{2,}'} [filterQuery] Free-text search, depending on the value a different search is performed: * \&quot;+\&quot;\&quot; followed by any char: mobile and telephone fields are used as filter * @ is present in the string: email is used * the value starts with the string \&#39;pk-\&#39;: consumer_code lookup * the value is only digits: search by fidelity card code * Any other combination will fetch data using consumer second name
         * @param {number} [filterBarcode] barcode della fidelity card
         * @param {string} [filterTelefono] see \&quot;query\&quot; filter description
         * @param {string} [filterEmail] see \&quot;query\&quot; filter description
         * @param {string} [filterPkConsumerSearch] Comma separated values are admitted. Only for \&#39;local\&#39; search
         * @param {string} [filterNazioneIso]
         * @param {string} [filterLocalita]
         * @param {string} [filterIndirizzo]
         * @param {string} [filterCognome] see \&quot;query\&quot; filter description.
         * @param {string} [filterNome] consumer\&#39;s name
         * @param {0 | 1} [filterUltimeModifiche] add ultime_modifiche info to customer data
         * @param {string} [filterPkConsumer] Single cunsumer code!  Use only for search when filter[source]&#x3D;company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersGet: async (filterSource?: 'local' | 'company', filterQuery?: '+.*' | '.*@.*' | 'pk-.*' | '\\d*' | '.{2,}', filterBarcode?: number, filterTelefono?: string, filterEmail?: string, filterPkConsumerSearch?: string, filterNazioneIso?: string, filterLocalita?: string, filterIndirizzo?: string, filterCognome?: string, filterNome?: string, filterUltimeModifiche?: 0 | 1, filterPkConsumer?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/consumers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterSource !== undefined) {
                localVarQueryParameter['filter[source]'] = filterSource;
            }

            if (filterQuery !== undefined) {
                localVarQueryParameter['filter[query]'] = filterQuery;
            }

            if (filterBarcode !== undefined) {
                localVarQueryParameter['filter[barcode]'] = filterBarcode;
            }

            if (filterTelefono !== undefined) {
                localVarQueryParameter['filter[telefono]'] = filterTelefono;
            }

            if (filterEmail !== undefined) {
                localVarQueryParameter['filter[email]'] = filterEmail;
            }

            if (filterPkConsumerSearch !== undefined) {
                localVarQueryParameter['filter[pk_consumer_search]'] = filterPkConsumerSearch;
            }

            if (filterNazioneIso !== undefined) {
                localVarQueryParameter['filter[nazione_iso]'] = filterNazioneIso;
            }

            if (filterLocalita !== undefined) {
                localVarQueryParameter['filter[localita]'] = filterLocalita;
            }

            if (filterIndirizzo !== undefined) {
                localVarQueryParameter['filter[indirizzo]'] = filterIndirizzo;
            }

            if (filterCognome !== undefined) {
                localVarQueryParameter['filter[cognome]'] = filterCognome;
            }

            if (filterNome !== undefined) {
                localVarQueryParameter['filter[nome]'] = filterNome;
            }

            if (filterUltimeModifiche !== undefined) {
                localVarQueryParameter['filter[ultime_modifiche]'] = filterUltimeModifiche;
            }

            if (filterPkConsumer !== undefined) {
                localVarQueryParameter['filter[pk_consumer]'] = filterPkConsumer;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get Consumer extra Info for Icons data
         * @param {string} pkConsumer Consumer unique id
         * @param {string} [filterDataA] filtro data \&quot;fino a\&quot;: recupera la lista di documenti fino alla data indicata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersIconsPkConsumerGet: async (pkConsumer: string, filterDataA?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersIconsPkConsumerGet', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/icons/{pk_consumer}`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterDataA !== undefined) {
                localVarQueryParameter['filter[data_a]'] = filterDataA;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a VAt numebr as filter and returns invoicing/accounting data needed for invoice emission
         * @summary Invoicing client data search
         * @param {string} [filterInput] Vat code to be searched
         * @param {string} [filterDocumentType] Document type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersInvoiceGet: async (filterInput?: string, filterDocumentType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/consumers-invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterInput !== undefined) {
                localVarQueryParameter['filter[input]'] = filterInput;
            }

            if (filterDocumentType !== undefined) {
                localVarQueryParameter['filter[document_type]'] = filterDocumentType;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Aggiunge la consumer di sede / gruppo indicata dal pk_consumer al db locale del negozio
         * @summary Aggiunge consumer al db locale
         * @param {string} pkConsumer Consumer ID
         * @param {ConsumerAddToLocal} [consumerAddToLocal]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerAddToLocalPost: async (pkConsumer: string, consumerAddToLocal?: ConsumerAddToLocal, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerAddToLocalPost', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/add_to_local`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consumerAddToLocal, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Servizio che ritorna la situazione anonimizzazione consumatrice.
         * @summary Situazione anonimizzazione consumatrice
         * @param {string} pkConsumer Consumer code
         * @param {number} jsonapi flag per avere risposta in formato jsonapi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerAnonymizationStatusGet: async (pkConsumer: string, jsonapi: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerAnonymizationStatusGet', 'pkConsumer', pkConsumer)
            // verify required parameter 'jsonapi' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerAnonymizationStatusGet', 'jsonapi', jsonapi)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/anonymization_status`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (jsonapi !== undefined) {
                localVarQueryParameter['jsonapi'] = jsonapi;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Servizio per attivare una nuova card ad una consumatrice
         * @summary Attivazione card della consumatrice
         * @param {string} pkConsumer Consumer code
         * @param {ConsumerCardActivate} [consumerCardActivate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsActivatePost: async (pkConsumer: string, consumerCardActivate?: ConsumerCardActivate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsActivatePost', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/cards/activate`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consumerCardActivate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Servizio per disattivare una card della consumatrice
         * @summary Disattivazione card della consumatrice
         * @param {string} pkConsumer Consumer code
         * @param {ConsumerCardDisable} [consumerCardDisable]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsDisablePost: async (pkConsumer: string, consumerCardDisable?: ConsumerCardDisable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsDisablePost', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/cards/disable`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consumerCardDisable, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Servizio per settare il punteggio di una fidelity card
         * @summary Settare il punteggio di una fidelity card
         * @param {string} pkConsumer Consumer code
         * @param {ConsumerCardForceValue} [consumerCardForceValue]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsForcePost: async (pkConsumer: string, consumerCardForceValue?: ConsumerCardForceValue, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsForcePost', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/cards/force`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consumerCardForceValue, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Questo servizio ritorna la situazione fidelity della consumatrice, tutte le card attive e disattive con i relativi dati e punteggi.
         * @summary Situazione fidelity della consumatrice
         * @param {string} pkConsumer Consumer code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsGet: async (pkConsumer: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsGet', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/cards`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Servizio per travasare i punti da una fidelity card ad un\'altra.
         * @summary Travaso punti da una card disattiva
         * @param {string} pkConsumer Consumer code
         * @param {ConsumerCardMoveValue} [consumerCardMoveValue]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsMovePost: async (pkConsumer: string, consumerCardMoveValue?: ConsumerCardMoveValue, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsMovePost', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/cards/move`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consumerCardMoveValue, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Servizio che ritorna le fidelity associate alla consumatrice per i programmi fidelity affiliati a quello attivo su negozio.
         * @summary Situazione fidelity della consumatrice: programmi fidelity affiliati a quello attivo su negozio
         * @param {string} pkConsumer Consumer code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsRemoteGet: async (pkConsumer: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsRemoteGet', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/cards/remote`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check soglia contanti
         * @summary Check soglia contanti
         * @param {string} pkConsumer Consumer code
         * @param {CheckSogliaContanti} [checkSogliaContanti]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCheckSogliaContantiPost: async (pkConsumer: string, checkSogliaContanti?: CheckSogliaContanti, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCheckSogliaContantiPost', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/check_soglia_contanti`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkSogliaContanti, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Restituisce la scheda Privacy in PDF, precompilata con i dati della consumatrice  -- Se si indica il parametro filter[history_limit] si ottengono le n versioni richieste
         * @summary Recupera scheda privacy precompilata
         * @param {string} pkConsumer Consumer code
         * @param {number} [filterHistoryLimit] numero di versioni da scaricare
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerHistorySchedaGet: async (pkConsumer: string, filterHistoryLimit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerHistorySchedaGet', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/history-scheda`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterHistoryLimit !== undefined) {
                localVarQueryParameter['filter[history_limit]'] = filterHistoryLimit;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Recupero scheda Privacy consumatrice con la firma allegata.   Viene risposto il documento pdf da scaricare  Se si aggiunge il payload history_limit sono scaricate le n versioni richieste in formato Zip    Il parametro \"sale_origin\" vale per Abstrid per restituire un payload col file invece del contenuto del file
         * @summary Recupero della scheda PDF Firmata
         * @param {string} pkConsumer Consumer code
         * @param {InlineObject1} [inlineObject1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerHistorySchedaPost: async (pkConsumer: string, inlineObject1?: InlineObject1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerHistorySchedaPost', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/history-scheda`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Modify Consumer
         * @param {string} pkConsumer Consumer unique id
         * @param {PatchConsumer} [patchConsumer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerPatch: async (pkConsumer: string, patchConsumer?: PatchConsumer, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerPatch', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/{pk_consumer}`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchConsumer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Save Consumer prefrences
         * @param {string} pkConsumer Consumer unique id
         * @param {ConsumerPreferences} [consumerPreferences]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerPreferenzeContattoPost: async (pkConsumer: string, consumerPreferences?: ConsumerPreferences, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerPreferenzeContattoPost', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/preferenze_contatto`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consumerPreferences, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GEt Print pdf consumer contact form
         * @summary Get pdf consumer contact form
         * @param {string} pkConsumer Consumer code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerPrintContactFormGet: async (pkConsumer: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerPrintContactFormGet', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/print_contact_form`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Restituisce la scheda Privacy in PDF, precompilata con i dati della consumatrice Viene generato un documento con 4  pagine: 2 per il documento in lingua e 2 per documento in inglese   Se si indica il parametro {lingua} si forza la generazione del solo documento nella lingua indicata
         * @summary Scheda privacy precompilata
         * @param {string} pkConsumer Consumer code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerSchedaGet: async (pkConsumer: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerSchedaGet', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/scheda`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Restituisce la scheda Privacy in PDF, precompilata con i dati della consumatrice Viene generato un documento con 4  pagine: 2 per il documento in lingua e 2 per documento in inglese   Se si indica il parametro {lingua} si forza la generazione del solo documento nella lingua indicata
         * @summary Scheda privacy precompilata
         * @param {string} pkConsumer Consumer code
         * @param {string} lingua lingua scelta del documento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerSchedaLinguaGet: async (pkConsumer: string, lingua: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerSchedaLinguaGet', 'pkConsumer', pkConsumer)
            // verify required parameter 'lingua' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerSchedaLinguaGet', 'lingua', lingua)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/scheda/{lingua}`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)))
                .replace(`{${"lingua"}}`, encodeURIComponent(String(lingua)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generazione scheda Privacy consumatrice con la firma allegata. Il pdf contiene una copia in lingua e una copia in inglese  Se si passa il parametro {lingua} si ottiene solamente il documento nella lingua indicata      Se si mettono nel payload solo uno o entrambi i parametri dei consensi, i valori 0/1 indicati sono usati per compilare la scheda con queste impostazioni di consenso.
         * @summary Generazione della scheda PDF Firmata
         * @param {string} pkConsumer Consumer code
         * @param {string} lingua lingua scelta del documento
         * @param {InlineObject} [inlineObject]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerSchedaLinguaPost: async (pkConsumer: string, lingua: string, inlineObject?: InlineObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerSchedaLinguaPost', 'pkConsumer', pkConsumer)
            // verify required parameter 'lingua' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerSchedaLinguaPost', 'lingua', lingua)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/scheda/{lingua}`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)))
                .replace(`{${"lingua"}}`, encodeURIComponent(String(lingua)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generazione scheda Privacy consumatrice con la firma allegata. Il pdf contiene una copia in lingua e una copia in inglese  Se si passa il parametro {lingua} si ottiene solamente il documento nella lingua indicata
         * @summary Generazione della scheda PDF Firmata
         * @param {string} pkConsumer Consumer code
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerSchedaPost: async (pkConsumer: string, body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerSchedaPost', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/scheda`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'text/plain';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Consumer static data
         * @summary Consumer static data
         * @param {string} pkConsumer Consumer unique code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerStatisticsGet: async (pkConsumer: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerStatisticsGet', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/statistics`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Consumer static data insegna pop-up (like FE)
         * @summary Consumer static data insegna pop-up (like FE)
         * @param {string} pkConsumer Consumer unique code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerStatisticsInsegnaGet: async (pkConsumer: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerStatisticsInsegnaGet', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/statistics_insegna`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Report on daily consumer cash usage
         * @summary Consumer used cash data
         * @param {string} pkConsumer Consumer code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerUsedCashGet: async (pkConsumer: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerUsedCashGet', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/used_cash`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ottiene la lista dei prodotti indicati come desiderati della consumer
         * @summary Ottiene la lista dei desiderata della consumer
         * @param {string} pkConsumer Consumer ID
         * @param {string} [brand] brand
         * @param {number} [skuLimit] limite al numero di  sku restituiti per ogni wishlist
         * @param {0 | 1} [details] aggiungere o meno dettagli alla risposta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerWishlistsGet: async (pkConsumer: string, brand?: string, skuLimit?: number, details?: 0 | 1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerWishlistsGet', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/wishlists`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (brand !== undefined) {
                localVarQueryParameter['brand'] = brand;
            }

            if (skuLimit !== undefined) {
                localVarQueryParameter['sku_limit'] = skuLimit;
            }

            if (details !== undefined) {
                localVarQueryParameter['details'] = details;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Consumer creation
         * @param {PostConsumer} [postConsumer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPost: async (postConsumer?: PostConsumer, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/consumers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postConsumer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Servizio che restituisce l\'elenco delle lingue disponibilei per il pdf della scheda Privacy in Pdf
         * @summary Lingue disponibili per la schedaPDF
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersSchedaLingueGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/consumers/scheda/lingue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Searches for taxfree consumers filtered by global blue
         * @summary Taxfree Consumer search
         * @param {string} filterType global blue search method
         * @param {string} [filterValue] global blue search value
         * @param {string} [filterPassportNumber] passport number, only if filter[type] is AUTOFILL_PASSPORT
         * @param {string} [filterCountry] passport country, only if filter[type] is AUTOFILL_PASSPORT
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersTaxfreeGet: async (filterType: string, filterValue?: string, filterPassportNumber?: string, filterCountry?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterType' is not null or undefined
            assertParamExists('apiV1ConsumersTaxfreeGet', 'filterType', filterType)
            const localVarPath = `/api/v1/consumers-taxfree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterType !== undefined) {
                localVarQueryParameter['filter[type]'] = filterType;
            }

            if (filterValue !== undefined) {
                localVarQueryParameter['filter[value]'] = filterValue;
            }

            if (filterPassportNumber !== undefined) {
                localVarQueryParameter['filter[passport_number]'] = filterPassportNumber;
            }

            if (filterCountry !== undefined) {
                localVarQueryParameter['filter[country]'] = filterCountry;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConsumerApi - functional programming interface
 * @export
 */
export const ConsumerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConsumerApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets dictionary of anonymous consumers nation codes by pk
         * @summary Anonymous Consumer search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AnonymousConsumerGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAnonymousConsumer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AnonymousConsumerGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Effettua la validazione dell\'indirizzo inviato. In alternativa, propone altri indirizzi consigliati
         * @summary Validazione dell\'indirizzo
         * @param {InlineObject2} [inlineObject2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersAddressValidationPost(inlineObject2?: InlineObject2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersAddressValidationPost(inlineObject2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Api per ottenere l\'elenco delle nazioni corrispondenti alle consumatrici anonime codificate come nazioni,  da usare nella ricerca in \"vendita\"
         * @summary Nazioni corrispondenti a consumer anonymous
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersCountriesAnonymousGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCountriesAnonymous>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersCountriesAnonymousGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List available consumers. SHOULD BE USED WHEN THE FILTERS CONTAINS AT LIST 3 CHARS. Search can be performed using those filter combination: * source: mandatory  mutually exclusive: * query * cognome + (optional) - username * email * barcode * pk_consumer * email * telefono  The last filter[ultime_modifiche] is optional and will be considered only with filter[pk_consumer_search] with ONE pk!
         * @summary Consumer search
         * @param {'local' | 'company'} [filterSource] Segregation flag: * \&#39;local\&#39; is for in-store lookup only consumers related to the store are returned. * \&#39;company\&#39; proxies the search to the corporate crm
         * @param {'+.*' | '.*@.*' | 'pk-.*' | '\\d*' | '.{2,}'} [filterQuery] Free-text search, depending on the value a different search is performed: * \&quot;+\&quot;\&quot; followed by any char: mobile and telephone fields are used as filter * @ is present in the string: email is used * the value starts with the string \&#39;pk-\&#39;: consumer_code lookup * the value is only digits: search by fidelity card code * Any other combination will fetch data using consumer second name
         * @param {number} [filterBarcode] barcode della fidelity card
         * @param {string} [filterTelefono] see \&quot;query\&quot; filter description
         * @param {string} [filterEmail] see \&quot;query\&quot; filter description
         * @param {string} [filterPkConsumerSearch] Comma separated values are admitted. Only for \&#39;local\&#39; search
         * @param {string} [filterNazioneIso]
         * @param {string} [filterLocalita]
         * @param {string} [filterIndirizzo]
         * @param {string} [filterCognome] see \&quot;query\&quot; filter description.
         * @param {string} [filterNome] consumer\&#39;s name
         * @param {0 | 1} [filterUltimeModifiche] add ultime_modifiche info to customer data
         * @param {string} [filterPkConsumer] Single cunsumer code!  Use only for search when filter[source]&#x3D;company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersGet(filterSource?: 'local' | 'company', filterQuery?: '+.*' | '.*@.*' | 'pk-.*' | '\\d*' | '.{2,}', filterBarcode?: number, filterTelefono?: string, filterEmail?: string, filterPkConsumerSearch?: string, filterNazioneIso?: string, filterLocalita?: string, filterIndirizzo?: string, filterCognome?: string, filterNome?: string, filterUltimeModifiche?: 0 | 1, filterPkConsumer?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConsumer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersGet(filterSource, filterQuery, filterBarcode, filterTelefono, filterEmail, filterPkConsumerSearch, filterNazioneIso, filterLocalita, filterIndirizzo, filterCognome, filterNome, filterUltimeModifiche, filterPkConsumer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get Consumer extra Info for Icons data
         * @param {string} pkConsumer Consumer unique id
         * @param {string} [filterDataA] filtro data \&quot;fino a\&quot;: recupera la lista di documenti fino alla data indicata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersIconsPkConsumerGet(pkConsumer: string, filterDataA?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConsumerExtraInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersIconsPkConsumerGet(pkConsumer, filterDataA, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a VAt numebr as filter and returns invoicing/accounting data needed for invoice emission
         * @summary Invoicing client data search
         * @param {string} [filterInput] Vat code to be searched
         * @param {string} [filterDocumentType] Document type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersInvoiceGet(filterInput?: string, filterDocumentType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConsumerInvoicingData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersInvoiceGet(filterInput, filterDocumentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Aggiunge la consumer di sede / gruppo indicata dal pk_consumer al db locale del negozio
         * @summary Aggiunge consumer al db locale
         * @param {string} pkConsumer Consumer ID
         * @param {ConsumerAddToLocal} [consumerAddToLocal]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerAddToLocalPost(pkConsumer: string, consumerAddToLocal?: ConsumerAddToLocal, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsumerAddToLocalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerAddToLocalPost(pkConsumer, consumerAddToLocal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Servizio che ritorna la situazione anonimizzazione consumatrice.
         * @summary Situazione anonimizzazione consumatrice
         * @param {string} pkConsumer Consumer code
         * @param {number} jsonapi flag per avere risposta in formato jsonapi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerAnonymizationStatusGet(pkConsumer: string, jsonapi: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsumerAnonymizationStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerAnonymizationStatusGet(pkConsumer, jsonapi, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Servizio per attivare una nuova card ad una consumatrice
         * @summary Attivazione card della consumatrice
         * @param {string} pkConsumer Consumer code
         * @param {ConsumerCardActivate} [consumerCardActivate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerCardsActivatePost(pkConsumer: string, consumerCardActivate?: ConsumerCardActivate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConsumerCardsInfo1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerCardsActivatePost(pkConsumer, consumerCardActivate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Servizio per disattivare una card della consumatrice
         * @summary Disattivazione card della consumatrice
         * @param {string} pkConsumer Consumer code
         * @param {ConsumerCardDisable} [consumerCardDisable]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerCardsDisablePost(pkConsumer: string, consumerCardDisable?: ConsumerCardDisable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConsumerCardsInfo1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerCardsDisablePost(pkConsumer, consumerCardDisable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Servizio per settare il punteggio di una fidelity card
         * @summary Settare il punteggio di una fidelity card
         * @param {string} pkConsumer Consumer code
         * @param {ConsumerCardForceValue} [consumerCardForceValue]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerCardsForcePost(pkConsumer: string, consumerCardForceValue?: ConsumerCardForceValue, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConsumerCardsInfo1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerCardsForcePost(pkConsumer, consumerCardForceValue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Questo servizio ritorna la situazione fidelity della consumatrice, tutte le card attive e disattive con i relativi dati e punteggi.
         * @summary Situazione fidelity della consumatrice
         * @param {string} pkConsumer Consumer code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerCardsGet(pkConsumer: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConsumerCardsInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerCardsGet(pkConsumer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Servizio per travasare i punti da una fidelity card ad un\'altra.
         * @summary Travaso punti da una card disattiva
         * @param {string} pkConsumer Consumer code
         * @param {ConsumerCardMoveValue} [consumerCardMoveValue]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerCardsMovePost(pkConsumer: string, consumerCardMoveValue?: ConsumerCardMoveValue, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConsumerCardsInfo1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerCardsMovePost(pkConsumer, consumerCardMoveValue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Servizio che ritorna le fidelity associate alla consumatrice per i programmi fidelity affiliati a quello attivo su negozio.
         * @summary Situazione fidelity della consumatrice: programmi fidelity affiliati a quello attivo su negozio
         * @param {string} pkConsumer Consumer code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerCardsRemoteGet(pkConsumer: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConsumerAffiliations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerCardsRemoteGet(pkConsumer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Check soglia contanti
         * @summary Check soglia contanti
         * @param {string} pkConsumer Consumer code
         * @param {CheckSogliaContanti} [checkSogliaContanti]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerCheckSogliaContantiPost(pkConsumer: string, checkSogliaContanti?: CheckSogliaContanti, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsumerCheckSogliaContanti>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerCheckSogliaContantiPost(pkConsumer, checkSogliaContanti, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Restituisce la scheda Privacy in PDF, precompilata con i dati della consumatrice  -- Se si indica il parametro filter[history_limit] si ottengono le n versioni richieste
         * @summary Recupera scheda privacy precompilata
         * @param {string} pkConsumer Consumer code
         * @param {number} [filterHistoryLimit] numero di versioni da scaricare
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerHistorySchedaGet(pkConsumer: string, filterHistoryLimit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerHistorySchedaGet(pkConsumer, filterHistoryLimit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Recupero scheda Privacy consumatrice con la firma allegata.   Viene risposto il documento pdf da scaricare  Se si aggiunge il payload history_limit sono scaricate le n versioni richieste in formato Zip    Il parametro \"sale_origin\" vale per Abstrid per restituire un payload col file invece del contenuto del file
         * @summary Recupero della scheda PDF Firmata
         * @param {string} pkConsumer Consumer code
         * @param {InlineObject1} [inlineObject1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerHistorySchedaPost(pkConsumer: string, inlineObject1?: InlineObject1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerHistorySchedaPost(pkConsumer, inlineObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Modify Consumer
         * @param {string} pkConsumer Consumer unique id
         * @param {PatchConsumer} [patchConsumer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerPatch(pkConsumer: string, patchConsumer?: PatchConsumer, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatchConsumerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerPatch(pkConsumer, patchConsumer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Save Consumer prefrences
         * @param {string} pkConsumer Consumer unique id
         * @param {ConsumerPreferences} [consumerPreferences]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerPreferenzeContattoPost(pkConsumer: string, consumerPreferences?: ConsumerPreferences, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsumerPreferenceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerPreferenzeContattoPost(pkConsumer, consumerPreferences, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GEt Print pdf consumer contact form
         * @summary Get pdf consumer contact form
         * @param {string} pkConsumer Consumer code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerPrintContactFormGet(pkConsumer: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConsumerPdfContactForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerPrintContactFormGet(pkConsumer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Restituisce la scheda Privacy in PDF, precompilata con i dati della consumatrice Viene generato un documento con 4  pagine: 2 per il documento in lingua e 2 per documento in inglese   Se si indica il parametro {lingua} si forza la generazione del solo documento nella lingua indicata
         * @summary Scheda privacy precompilata
         * @param {string} pkConsumer Consumer code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerSchedaGet(pkConsumer: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerSchedaGet(pkConsumer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Restituisce la scheda Privacy in PDF, precompilata con i dati della consumatrice Viene generato un documento con 4  pagine: 2 per il documento in lingua e 2 per documento in inglese   Se si indica il parametro {lingua} si forza la generazione del solo documento nella lingua indicata
         * @summary Scheda privacy precompilata
         * @param {string} pkConsumer Consumer code
         * @param {string} lingua lingua scelta del documento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerSchedaLinguaGet(pkConsumer: string, lingua: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerSchedaLinguaGet(pkConsumer, lingua, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generazione scheda Privacy consumatrice con la firma allegata. Il pdf contiene una copia in lingua e una copia in inglese  Se si passa il parametro {lingua} si ottiene solamente il documento nella lingua indicata      Se si mettono nel payload solo uno o entrambi i parametri dei consensi, i valori 0/1 indicati sono usati per compilare la scheda con queste impostazioni di consenso.
         * @summary Generazione della scheda PDF Firmata
         * @param {string} pkConsumer Consumer code
         * @param {string} lingua lingua scelta del documento
         * @param {InlineObject} [inlineObject]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerSchedaLinguaPost(pkConsumer: string, lingua: string, inlineObject?: InlineObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerSchedaLinguaPost(pkConsumer, lingua, inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generazione scheda Privacy consumatrice con la firma allegata. Il pdf contiene una copia in lingua e una copia in inglese  Se si passa il parametro {lingua} si ottiene solamente il documento nella lingua indicata
         * @summary Generazione della scheda PDF Firmata
         * @param {string} pkConsumer Consumer code
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerSchedaPost(pkConsumer: string, body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerSchedaPost(pkConsumer, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Consumer static data
         * @summary Consumer static data
         * @param {string} pkConsumer Consumer unique code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerStatisticsGet(pkConsumer: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConsumerStatistics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerStatisticsGet(pkConsumer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Consumer static data insegna pop-up (like FE)
         * @summary Consumer static data insegna pop-up (like FE)
         * @param {string} pkConsumer Consumer unique code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerStatisticsInsegnaGet(pkConsumer: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInsegnaConsumerStatistics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerStatisticsInsegnaGet(pkConsumer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Report on daily consumer cash usage
         * @summary Consumer used cash data
         * @param {string} pkConsumer Consumer code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerUsedCashGet(pkConsumer: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConsumerCashUsage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerUsedCashGet(pkConsumer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Ottiene la lista dei prodotti indicati come desiderati della consumer
         * @summary Ottiene la lista dei desiderata della consumer
         * @param {string} pkConsumer Consumer ID
         * @param {string} [brand] brand
         * @param {number} [skuLimit] limite al numero di  sku restituiti per ogni wishlist
         * @param {0 | 1} [details] aggiungere o meno dettagli alla risposta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerWishlistsGet(pkConsumer: string, brand?: string, skuLimit?: number, details?: 0 | 1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsumerWishlist>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerWishlistsGet(pkConsumer, brand, skuLimit, details, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Consumer creation
         * @param {PostConsumer} [postConsumer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPost(postConsumer?: PostConsumer, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostConsumerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPost(postConsumer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Servizio che restituisce l\'elenco delle lingue disponibilei per il pdf della scheda Privacy in Pdf
         * @summary Lingue disponibili per la schedaPDF
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersSchedaLingueGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSchedaPrivacyLanguages>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersSchedaLingueGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Searches for taxfree consumers filtered by global blue
         * @summary Taxfree Consumer search
         * @param {string} filterType global blue search method
         * @param {string} [filterValue] global blue search value
         * @param {string} [filterPassportNumber] passport number, only if filter[type] is AUTOFILL_PASSPORT
         * @param {string} [filterCountry] passport country, only if filter[type] is AUTOFILL_PASSPORT
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersTaxfreeGet(filterType: string, filterValue?: string, filterPassportNumber?: string, filterCountry?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAnonymousConsumer1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersTaxfreeGet(filterType, filterValue, filterPassportNumber, filterCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConsumerApi - factory interface
 * @export
 */
export const ConsumerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConsumerApiFp(configuration)
    return {
        /**
         * Gets dictionary of anonymous consumers nation codes by pk
         * @summary Anonymous Consumer search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AnonymousConsumerGet(options?: any): AxiosPromise<GetAnonymousConsumer> {
            return localVarFp.apiV1AnonymousConsumerGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Effettua la validazione dell\'indirizzo inviato. In alternativa, propone altri indirizzi consigliati
         * @summary Validazione dell\'indirizzo
         * @param {InlineObject2} [inlineObject2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersAddressValidationPost(inlineObject2?: InlineObject2, options?: any): AxiosPromise<InlineResponse2002> {
            return localVarFp.apiV1ConsumersAddressValidationPost(inlineObject2, options).then((request) => request(axios, basePath));
        },
        /**
         * Api per ottenere l\'elenco delle nazioni corrispondenti alle consumatrici anonime codificate come nazioni,  da usare nella ricerca in \"vendita\"
         * @summary Nazioni corrispondenti a consumer anonymous
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersCountriesAnonymousGet(options?: any): AxiosPromise<GetCountriesAnonymous> {
            return localVarFp.apiV1ConsumersCountriesAnonymousGet(options).then((request) => request(axios, basePath));
        },
        /**
         * List available consumers. SHOULD BE USED WHEN THE FILTERS CONTAINS AT LIST 3 CHARS. Search can be performed using those filter combination: * source: mandatory  mutually exclusive: * query * cognome + (optional) - username * email * barcode * pk_consumer * email * telefono  The last filter[ultime_modifiche] is optional and will be considered only with filter[pk_consumer_search] with ONE pk!
         * @summary Consumer search
         * @param {'local' | 'company'} [filterSource] Segregation flag: * \&#39;local\&#39; is for in-store lookup only consumers related to the store are returned. * \&#39;company\&#39; proxies the search to the corporate crm
         * @param {'+.*' | '.*@.*' | 'pk-.*' | '\\d*' | '.{2,}'} [filterQuery] Free-text search, depending on the value a different search is performed: * \&quot;+\&quot;\&quot; followed by any char: mobile and telephone fields are used as filter * @ is present in the string: email is used * the value starts with the string \&#39;pk-\&#39;: consumer_code lookup * the value is only digits: search by fidelity card code * Any other combination will fetch data using consumer second name
         * @param {number} [filterBarcode] barcode della fidelity card
         * @param {string} [filterTelefono] see \&quot;query\&quot; filter description
         * @param {string} [filterEmail] see \&quot;query\&quot; filter description
         * @param {string} [filterPkConsumerSearch] Comma separated values are admitted. Only for \&#39;local\&#39; search
         * @param {string} [filterNazioneIso]
         * @param {string} [filterLocalita]
         * @param {string} [filterIndirizzo]
         * @param {string} [filterCognome] see \&quot;query\&quot; filter description.
         * @param {string} [filterNome] consumer\&#39;s name
         * @param {0 | 1} [filterUltimeModifiche] add ultime_modifiche info to customer data
         * @param {string} [filterPkConsumer] Single cunsumer code!  Use only for search when filter[source]&#x3D;company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersGet(filterSource?: 'local' | 'company', filterQuery?: '+.*' | '.*@.*' | 'pk-.*' | '\\d*' | '.{2,}', filterBarcode?: number, filterTelefono?: string, filterEmail?: string, filterPkConsumerSearch?: string, filterNazioneIso?: string, filterLocalita?: string, filterIndirizzo?: string, filterCognome?: string, filterNome?: string, filterUltimeModifiche?: 0 | 1, filterPkConsumer?: string, options?: any): AxiosPromise<GetConsumer> {
            return localVarFp.apiV1ConsumersGet(filterSource, filterQuery, filterBarcode, filterTelefono, filterEmail, filterPkConsumerSearch, filterNazioneIso, filterLocalita, filterIndirizzo, filterCognome, filterNome, filterUltimeModifiche, filterPkConsumer, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get Consumer extra Info for Icons data
         * @param {string} pkConsumer Consumer unique id
         * @param {string} [filterDataA] filtro data \&quot;fino a\&quot;: recupera la lista di documenti fino alla data indicata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersIconsPkConsumerGet(pkConsumer: string, filterDataA?: string, options?: any): AxiosPromise<GetConsumerExtraInfo> {
            return localVarFp.apiV1ConsumersIconsPkConsumerGet(pkConsumer, filterDataA, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a VAt numebr as filter and returns invoicing/accounting data needed for invoice emission
         * @summary Invoicing client data search
         * @param {string} [filterInput] Vat code to be searched
         * @param {string} [filterDocumentType] Document type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersInvoiceGet(filterInput?: string, filterDocumentType?: string, options?: any): AxiosPromise<GetConsumerInvoicingData> {
            return localVarFp.apiV1ConsumersInvoiceGet(filterInput, filterDocumentType, options).then((request) => request(axios, basePath));
        },
        /**
         * Aggiunge la consumer di sede / gruppo indicata dal pk_consumer al db locale del negozio
         * @summary Aggiunge consumer al db locale
         * @param {string} pkConsumer Consumer ID
         * @param {ConsumerAddToLocal} [consumerAddToLocal]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerAddToLocalPost(pkConsumer: string, consumerAddToLocal?: ConsumerAddToLocal, options?: any): AxiosPromise<ConsumerAddToLocalResponse> {
            return localVarFp.apiV1ConsumersPkConsumerAddToLocalPost(pkConsumer, consumerAddToLocal, options).then((request) => request(axios, basePath));
        },
        /**
         * Servizio che ritorna la situazione anonimizzazione consumatrice.
         * @summary Situazione anonimizzazione consumatrice
         * @param {string} pkConsumer Consumer code
         * @param {number} jsonapi flag per avere risposta in formato jsonapi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerAnonymizationStatusGet(pkConsumer: string, jsonapi: number, options?: any): AxiosPromise<ConsumerAnonymizationStatus> {
            return localVarFp.apiV1ConsumersPkConsumerAnonymizationStatusGet(pkConsumer, jsonapi, options).then((request) => request(axios, basePath));
        },
        /**
         * Servizio per attivare una nuova card ad una consumatrice
         * @summary Attivazione card della consumatrice
         * @param {string} pkConsumer Consumer code
         * @param {ConsumerCardActivate} [consumerCardActivate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsActivatePost(pkConsumer: string, consumerCardActivate?: ConsumerCardActivate, options?: any): AxiosPromise<GetConsumerCardsInfo1> {
            return localVarFp.apiV1ConsumersPkConsumerCardsActivatePost(pkConsumer, consumerCardActivate, options).then((request) => request(axios, basePath));
        },
        /**
         * Servizio per disattivare una card della consumatrice
         * @summary Disattivazione card della consumatrice
         * @param {string} pkConsumer Consumer code
         * @param {ConsumerCardDisable} [consumerCardDisable]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsDisablePost(pkConsumer: string, consumerCardDisable?: ConsumerCardDisable, options?: any): AxiosPromise<GetConsumerCardsInfo1> {
            return localVarFp.apiV1ConsumersPkConsumerCardsDisablePost(pkConsumer, consumerCardDisable, options).then((request) => request(axios, basePath));
        },
        /**
         * Servizio per settare il punteggio di una fidelity card
         * @summary Settare il punteggio di una fidelity card
         * @param {string} pkConsumer Consumer code
         * @param {ConsumerCardForceValue} [consumerCardForceValue]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsForcePost(pkConsumer: string, consumerCardForceValue?: ConsumerCardForceValue, options?: any): AxiosPromise<GetConsumerCardsInfo1> {
            return localVarFp.apiV1ConsumersPkConsumerCardsForcePost(pkConsumer, consumerCardForceValue, options).then((request) => request(axios, basePath));
        },
        /**
         * Questo servizio ritorna la situazione fidelity della consumatrice, tutte le card attive e disattive con i relativi dati e punteggi.
         * @summary Situazione fidelity della consumatrice
         * @param {string} pkConsumer Consumer code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsGet(pkConsumer: string, options?: any): AxiosPromise<GetConsumerCardsInfo> {
            return localVarFp.apiV1ConsumersPkConsumerCardsGet(pkConsumer, options).then((request) => request(axios, basePath));
        },
        /**
         * Servizio per travasare i punti da una fidelity card ad un\'altra.
         * @summary Travaso punti da una card disattiva
         * @param {string} pkConsumer Consumer code
         * @param {ConsumerCardMoveValue} [consumerCardMoveValue]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsMovePost(pkConsumer: string, consumerCardMoveValue?: ConsumerCardMoveValue, options?: any): AxiosPromise<GetConsumerCardsInfo1> {
            return localVarFp.apiV1ConsumersPkConsumerCardsMovePost(pkConsumer, consumerCardMoveValue, options).then((request) => request(axios, basePath));
        },
        /**
         * Servizio che ritorna le fidelity associate alla consumatrice per i programmi fidelity affiliati a quello attivo su negozio.
         * @summary Situazione fidelity della consumatrice: programmi fidelity affiliati a quello attivo su negozio
         * @param {string} pkConsumer Consumer code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsRemoteGet(pkConsumer: string, options?: any): AxiosPromise<GetConsumerAffiliations> {
            return localVarFp.apiV1ConsumersPkConsumerCardsRemoteGet(pkConsumer, options).then((request) => request(axios, basePath));
        },
        /**
         * Check soglia contanti
         * @summary Check soglia contanti
         * @param {string} pkConsumer Consumer code
         * @param {CheckSogliaContanti} [checkSogliaContanti]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCheckSogliaContantiPost(pkConsumer: string, checkSogliaContanti?: CheckSogliaContanti, options?: any): AxiosPromise<ConsumerCheckSogliaContanti> {
            return localVarFp.apiV1ConsumersPkConsumerCheckSogliaContantiPost(pkConsumer, checkSogliaContanti, options).then((request) => request(axios, basePath));
        },
        /**
         * Restituisce la scheda Privacy in PDF, precompilata con i dati della consumatrice  -- Se si indica il parametro filter[history_limit] si ottengono le n versioni richieste
         * @summary Recupera scheda privacy precompilata
         * @param {string} pkConsumer Consumer code
         * @param {number} [filterHistoryLimit] numero di versioni da scaricare
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerHistorySchedaGet(pkConsumer: string, filterHistoryLimit?: number, options?: any): AxiosPromise<any> {
            return localVarFp.apiV1ConsumersPkConsumerHistorySchedaGet(pkConsumer, filterHistoryLimit, options).then((request) => request(axios, basePath));
        },
        /**
         * Recupero scheda Privacy consumatrice con la firma allegata.   Viene risposto il documento pdf da scaricare  Se si aggiunge il payload history_limit sono scaricate le n versioni richieste in formato Zip    Il parametro \"sale_origin\" vale per Abstrid per restituire un payload col file invece del contenuto del file
         * @summary Recupero della scheda PDF Firmata
         * @param {string} pkConsumer Consumer code
         * @param {InlineObject1} [inlineObject1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerHistorySchedaPost(pkConsumer: string, inlineObject1?: InlineObject1, options?: any): AxiosPromise<any> {
            return localVarFp.apiV1ConsumersPkConsumerHistorySchedaPost(pkConsumer, inlineObject1, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Modify Consumer
         * @param {string} pkConsumer Consumer unique id
         * @param {PatchConsumer} [patchConsumer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerPatch(pkConsumer: string, patchConsumer?: PatchConsumer, options?: any): AxiosPromise<PatchConsumerResponse> {
            return localVarFp.apiV1ConsumersPkConsumerPatch(pkConsumer, patchConsumer, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Save Consumer prefrences
         * @param {string} pkConsumer Consumer unique id
         * @param {ConsumerPreferences} [consumerPreferences]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerPreferenzeContattoPost(pkConsumer: string, consumerPreferences?: ConsumerPreferences, options?: any): AxiosPromise<ConsumerPreferenceResponse> {
            return localVarFp.apiV1ConsumersPkConsumerPreferenzeContattoPost(pkConsumer, consumerPreferences, options).then((request) => request(axios, basePath));
        },
        /**
         * GEt Print pdf consumer contact form
         * @summary Get pdf consumer contact form
         * @param {string} pkConsumer Consumer code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerPrintContactFormGet(pkConsumer: string, options?: any): AxiosPromise<GetConsumerPdfContactForm> {
            return localVarFp.apiV1ConsumersPkConsumerPrintContactFormGet(pkConsumer, options).then((request) => request(axios, basePath));
        },
        /**
         * Restituisce la scheda Privacy in PDF, precompilata con i dati della consumatrice Viene generato un documento con 4  pagine: 2 per il documento in lingua e 2 per documento in inglese   Se si indica il parametro {lingua} si forza la generazione del solo documento nella lingua indicata
         * @summary Scheda privacy precompilata
         * @param {string} pkConsumer Consumer code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerSchedaGet(pkConsumer: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiV1ConsumersPkConsumerSchedaGet(pkConsumer, options).then((request) => request(axios, basePath));
        },
        /**
         * Restituisce la scheda Privacy in PDF, precompilata con i dati della consumatrice Viene generato un documento con 4  pagine: 2 per il documento in lingua e 2 per documento in inglese   Se si indica il parametro {lingua} si forza la generazione del solo documento nella lingua indicata
         * @summary Scheda privacy precompilata
         * @param {string} pkConsumer Consumer code
         * @param {string} lingua lingua scelta del documento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerSchedaLinguaGet(pkConsumer: string, lingua: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiV1ConsumersPkConsumerSchedaLinguaGet(pkConsumer, lingua, options).then((request) => request(axios, basePath));
        },
        /**
         * Generazione scheda Privacy consumatrice con la firma allegata. Il pdf contiene una copia in lingua e una copia in inglese  Se si passa il parametro {lingua} si ottiene solamente il documento nella lingua indicata      Se si mettono nel payload solo uno o entrambi i parametri dei consensi, i valori 0/1 indicati sono usati per compilare la scheda con queste impostazioni di consenso.
         * @summary Generazione della scheda PDF Firmata
         * @param {string} pkConsumer Consumer code
         * @param {string} lingua lingua scelta del documento
         * @param {InlineObject} [inlineObject]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerSchedaLinguaPost(pkConsumer: string, lingua: string, inlineObject?: InlineObject, options?: any): AxiosPromise<any> {
            return localVarFp.apiV1ConsumersPkConsumerSchedaLinguaPost(pkConsumer, lingua, inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * Generazione scheda Privacy consumatrice con la firma allegata. Il pdf contiene una copia in lingua e una copia in inglese  Se si passa il parametro {lingua} si ottiene solamente il documento nella lingua indicata
         * @summary Generazione della scheda PDF Firmata
         * @param {string} pkConsumer Consumer code
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerSchedaPost(pkConsumer: string, body?: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiV1ConsumersPkConsumerSchedaPost(pkConsumer, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Consumer static data
         * @summary Consumer static data
         * @param {string} pkConsumer Consumer unique code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerStatisticsGet(pkConsumer: string, options?: any): AxiosPromise<GetConsumerStatistics> {
            return localVarFp.apiV1ConsumersPkConsumerStatisticsGet(pkConsumer, options).then((request) => request(axios, basePath));
        },
        /**
         * Consumer static data insegna pop-up (like FE)
         * @summary Consumer static data insegna pop-up (like FE)
         * @param {string} pkConsumer Consumer unique code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerStatisticsInsegnaGet(pkConsumer: string, options?: any): AxiosPromise<GetInsegnaConsumerStatistics> {
            return localVarFp.apiV1ConsumersPkConsumerStatisticsInsegnaGet(pkConsumer, options).then((request) => request(axios, basePath));
        },
        /**
         * Report on daily consumer cash usage
         * @summary Consumer used cash data
         * @param {string} pkConsumer Consumer code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerUsedCashGet(pkConsumer: string, options?: any): AxiosPromise<GetConsumerCashUsage> {
            return localVarFp.apiV1ConsumersPkConsumerUsedCashGet(pkConsumer, options).then((request) => request(axios, basePath));
        },
        /**
         * Ottiene la lista dei prodotti indicati come desiderati della consumer
         * @summary Ottiene la lista dei desiderata della consumer
         * @param {string} pkConsumer Consumer ID
         * @param {string} [brand] brand
         * @param {number} [skuLimit] limite al numero di  sku restituiti per ogni wishlist
         * @param {0 | 1} [details] aggiungere o meno dettagli alla risposta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerWishlistsGet(pkConsumer: string, brand?: string, skuLimit?: number, details?: 0 | 1, options?: any): AxiosPromise<ConsumerWishlist> {
            return localVarFp.apiV1ConsumersPkConsumerWishlistsGet(pkConsumer, brand, skuLimit, details, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Consumer creation
         * @param {PostConsumer} [postConsumer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPost(postConsumer?: PostConsumer, options?: any): AxiosPromise<PostConsumerResponse> {
            return localVarFp.apiV1ConsumersPost(postConsumer, options).then((request) => request(axios, basePath));
        },
        /**
         * Servizio che restituisce l\'elenco delle lingue disponibilei per il pdf della scheda Privacy in Pdf
         * @summary Lingue disponibili per la schedaPDF
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersSchedaLingueGet(options?: any): AxiosPromise<GetSchedaPrivacyLanguages> {
            return localVarFp.apiV1ConsumersSchedaLingueGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Searches for taxfree consumers filtered by global blue
         * @summary Taxfree Consumer search
         * @param {string} filterType global blue search method
         * @param {string} [filterValue] global blue search value
         * @param {string} [filterPassportNumber] passport number, only if filter[type] is AUTOFILL_PASSPORT
         * @param {string} [filterCountry] passport country, only if filter[type] is AUTOFILL_PASSPORT
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersTaxfreeGet(filterType: string, filterValue?: string, filterPassportNumber?: string, filterCountry?: string, options?: any): AxiosPromise<GetAnonymousConsumer1> {
            return localVarFp.apiV1ConsumersTaxfreeGet(filterType, filterValue, filterPassportNumber, filterCountry, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConsumerApi - object-oriented interface
 * @export
 * @class ConsumerApi
 * @extends {BaseAPI}
 */
export class ConsumerApi extends BaseAPI {
    /**
     * Gets dictionary of anonymous consumers nation codes by pk
     * @summary Anonymous Consumer search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1AnonymousConsumerGet(options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1AnonymousConsumerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Effettua la validazione dell\'indirizzo inviato. In alternativa, propone altri indirizzi consigliati
     * @summary Validazione dell\'indirizzo
     * @param {InlineObject2} [inlineObject2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersAddressValidationPost(inlineObject2?: InlineObject2, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersAddressValidationPost(inlineObject2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Api per ottenere l\'elenco delle nazioni corrispondenti alle consumatrici anonime codificate come nazioni,  da usare nella ricerca in \"vendita\"
     * @summary Nazioni corrispondenti a consumer anonymous
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersCountriesAnonymousGet(options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersCountriesAnonymousGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List available consumers. SHOULD BE USED WHEN THE FILTERS CONTAINS AT LIST 3 CHARS. Search can be performed using those filter combination: * source: mandatory  mutually exclusive: * query * cognome + (optional) - username * email * barcode * pk_consumer * email * telefono  The last filter[ultime_modifiche] is optional and will be considered only with filter[pk_consumer_search] with ONE pk!
     * @summary Consumer search
     * @param {'local' | 'company'} [filterSource] Segregation flag: * \&#39;local\&#39; is for in-store lookup only consumers related to the store are returned. * \&#39;company\&#39; proxies the search to the corporate crm
     * @param {'+.*' | '.*@.*' | 'pk-.*' | '\\d*' | '.{2,}'} [filterQuery] Free-text search, depending on the value a different search is performed: * \&quot;+\&quot;\&quot; followed by any char: mobile and telephone fields are used as filter * @ is present in the string: email is used * the value starts with the string \&#39;pk-\&#39;: consumer_code lookup * the value is only digits: search by fidelity card code * Any other combination will fetch data using consumer second name
     * @param {number} [filterBarcode] barcode della fidelity card
     * @param {string} [filterTelefono] see \&quot;query\&quot; filter description
     * @param {string} [filterEmail] see \&quot;query\&quot; filter description
     * @param {string} [filterPkConsumerSearch] Comma separated values are admitted. Only for \&#39;local\&#39; search
     * @param {string} [filterNazioneIso]
     * @param {string} [filterLocalita]
     * @param {string} [filterIndirizzo]
     * @param {string} [filterCognome] see \&quot;query\&quot; filter description.
     * @param {string} [filterNome] consumer\&#39;s name
     * @param {0 | 1} [filterUltimeModifiche] add ultime_modifiche info to customer data
     * @param {string} [filterPkConsumer] Single cunsumer code!  Use only for search when filter[source]&#x3D;company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersGet(filterSource?: 'local' | 'company', filterQuery?: '+.*' | '.*@.*' | 'pk-.*' | '\\d*' | '.{2,}', filterBarcode?: number, filterTelefono?: string, filterEmail?: string, filterPkConsumerSearch?: string, filterNazioneIso?: string, filterLocalita?: string, filterIndirizzo?: string, filterCognome?: string, filterNome?: string, filterUltimeModifiche?: 0 | 1, filterPkConsumer?: string, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersGet(filterSource, filterQuery, filterBarcode, filterTelefono, filterEmail, filterPkConsumerSearch, filterNazioneIso, filterLocalita, filterIndirizzo, filterCognome, filterNome, filterUltimeModifiche, filterPkConsumer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get Consumer extra Info for Icons data
     * @param {string} pkConsumer Consumer unique id
     * @param {string} [filterDataA] filtro data \&quot;fino a\&quot;: recupera la lista di documenti fino alla data indicata
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersIconsPkConsumerGet(pkConsumer: string, filterDataA?: string, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersIconsPkConsumerGet(pkConsumer, filterDataA, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a VAt numebr as filter and returns invoicing/accounting data needed for invoice emission
     * @summary Invoicing client data search
     * @param {string} [filterInput] Vat code to be searched
     * @param {string} [filterDocumentType] Document type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersInvoiceGet(filterInput?: string, filterDocumentType?: string, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersInvoiceGet(filterInput, filterDocumentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Aggiunge la consumer di sede / gruppo indicata dal pk_consumer al db locale del negozio
     * @summary Aggiunge consumer al db locale
     * @param {string} pkConsumer Consumer ID
     * @param {ConsumerAddToLocal} [consumerAddToLocal]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersPkConsumerAddToLocalPost(pkConsumer: string, consumerAddToLocal?: ConsumerAddToLocal, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersPkConsumerAddToLocalPost(pkConsumer, consumerAddToLocal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Servizio che ritorna la situazione anonimizzazione consumatrice.
     * @summary Situazione anonimizzazione consumatrice
     * @param {string} pkConsumer Consumer code
     * @param {number} jsonapi flag per avere risposta in formato jsonapi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersPkConsumerAnonymizationStatusGet(pkConsumer: string, jsonapi: number, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersPkConsumerAnonymizationStatusGet(pkConsumer, jsonapi, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Servizio per attivare una nuova card ad una consumatrice
     * @summary Attivazione card della consumatrice
     * @param {string} pkConsumer Consumer code
     * @param {ConsumerCardActivate} [consumerCardActivate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersPkConsumerCardsActivatePost(pkConsumer: string, consumerCardActivate?: ConsumerCardActivate, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersPkConsumerCardsActivatePost(pkConsumer, consumerCardActivate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Servizio per disattivare una card della consumatrice
     * @summary Disattivazione card della consumatrice
     * @param {string} pkConsumer Consumer code
     * @param {ConsumerCardDisable} [consumerCardDisable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersPkConsumerCardsDisablePost(pkConsumer: string, consumerCardDisable?: ConsumerCardDisable, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersPkConsumerCardsDisablePost(pkConsumer, consumerCardDisable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Servizio per settare il punteggio di una fidelity card
     * @summary Settare il punteggio di una fidelity card
     * @param {string} pkConsumer Consumer code
     * @param {ConsumerCardForceValue} [consumerCardForceValue]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersPkConsumerCardsForcePost(pkConsumer: string, consumerCardForceValue?: ConsumerCardForceValue, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersPkConsumerCardsForcePost(pkConsumer, consumerCardForceValue, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Questo servizio ritorna la situazione fidelity della consumatrice, tutte le card attive e disattive con i relativi dati e punteggi.
     * @summary Situazione fidelity della consumatrice
     * @param {string} pkConsumer Consumer code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersPkConsumerCardsGet(pkConsumer: string, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersPkConsumerCardsGet(pkConsumer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Servizio per travasare i punti da una fidelity card ad un\'altra.
     * @summary Travaso punti da una card disattiva
     * @param {string} pkConsumer Consumer code
     * @param {ConsumerCardMoveValue} [consumerCardMoveValue]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersPkConsumerCardsMovePost(pkConsumer: string, consumerCardMoveValue?: ConsumerCardMoveValue, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersPkConsumerCardsMovePost(pkConsumer, consumerCardMoveValue, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Servizio che ritorna le fidelity associate alla consumatrice per i programmi fidelity affiliati a quello attivo su negozio.
     * @summary Situazione fidelity della consumatrice: programmi fidelity affiliati a quello attivo su negozio
     * @param {string} pkConsumer Consumer code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersPkConsumerCardsRemoteGet(pkConsumer: string, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersPkConsumerCardsRemoteGet(pkConsumer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Check soglia contanti
     * @summary Check soglia contanti
     * @param {string} pkConsumer Consumer code
     * @param {CheckSogliaContanti} [checkSogliaContanti]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersPkConsumerCheckSogliaContantiPost(pkConsumer: string, checkSogliaContanti?: CheckSogliaContanti, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersPkConsumerCheckSogliaContantiPost(pkConsumer, checkSogliaContanti, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Restituisce la scheda Privacy in PDF, precompilata con i dati della consumatrice  -- Se si indica il parametro filter[history_limit] si ottengono le n versioni richieste
     * @summary Recupera scheda privacy precompilata
     * @param {string} pkConsumer Consumer code
     * @param {number} [filterHistoryLimit] numero di versioni da scaricare
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersPkConsumerHistorySchedaGet(pkConsumer: string, filterHistoryLimit?: number, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersPkConsumerHistorySchedaGet(pkConsumer, filterHistoryLimit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Recupero scheda Privacy consumatrice con la firma allegata.   Viene risposto il documento pdf da scaricare  Se si aggiunge il payload history_limit sono scaricate le n versioni richieste in formato Zip    Il parametro \"sale_origin\" vale per Abstrid per restituire un payload col file invece del contenuto del file
     * @summary Recupero della scheda PDF Firmata
     * @param {string} pkConsumer Consumer code
     * @param {InlineObject1} [inlineObject1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersPkConsumerHistorySchedaPost(pkConsumer: string, inlineObject1?: InlineObject1, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersPkConsumerHistorySchedaPost(pkConsumer, inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Modify Consumer
     * @param {string} pkConsumer Consumer unique id
     * @param {PatchConsumer} [patchConsumer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersPkConsumerPatch(pkConsumer: string, patchConsumer?: PatchConsumer, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersPkConsumerPatch(pkConsumer, patchConsumer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Save Consumer prefrences
     * @param {string} pkConsumer Consumer unique id
     * @param {ConsumerPreferences} [consumerPreferences]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersPkConsumerPreferenzeContattoPost(pkConsumer: string, consumerPreferences?: ConsumerPreferences, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersPkConsumerPreferenzeContattoPost(pkConsumer, consumerPreferences, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GEt Print pdf consumer contact form
     * @summary Get pdf consumer contact form
     * @param {string} pkConsumer Consumer code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersPkConsumerPrintContactFormGet(pkConsumer: string, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersPkConsumerPrintContactFormGet(pkConsumer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Restituisce la scheda Privacy in PDF, precompilata con i dati della consumatrice Viene generato un documento con 4  pagine: 2 per il documento in lingua e 2 per documento in inglese   Se si indica il parametro {lingua} si forza la generazione del solo documento nella lingua indicata
     * @summary Scheda privacy precompilata
     * @param {string} pkConsumer Consumer code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersPkConsumerSchedaGet(pkConsumer: string, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersPkConsumerSchedaGet(pkConsumer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Restituisce la scheda Privacy in PDF, precompilata con i dati della consumatrice Viene generato un documento con 4  pagine: 2 per il documento in lingua e 2 per documento in inglese   Se si indica il parametro {lingua} si forza la generazione del solo documento nella lingua indicata
     * @summary Scheda privacy precompilata
     * @param {string} pkConsumer Consumer code
     * @param {string} lingua lingua scelta del documento
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersPkConsumerSchedaLinguaGet(pkConsumer: string, lingua: string, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersPkConsumerSchedaLinguaGet(pkConsumer, lingua, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generazione scheda Privacy consumatrice con la firma allegata. Il pdf contiene una copia in lingua e una copia in inglese  Se si passa il parametro {lingua} si ottiene solamente il documento nella lingua indicata      Se si mettono nel payload solo uno o entrambi i parametri dei consensi, i valori 0/1 indicati sono usati per compilare la scheda con queste impostazioni di consenso.
     * @summary Generazione della scheda PDF Firmata
     * @param {string} pkConsumer Consumer code
     * @param {string} lingua lingua scelta del documento
     * @param {InlineObject} [inlineObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersPkConsumerSchedaLinguaPost(pkConsumer: string, lingua: string, inlineObject?: InlineObject, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersPkConsumerSchedaLinguaPost(pkConsumer, lingua, inlineObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generazione scheda Privacy consumatrice con la firma allegata. Il pdf contiene una copia in lingua e una copia in inglese  Se si passa il parametro {lingua} si ottiene solamente il documento nella lingua indicata
     * @summary Generazione della scheda PDF Firmata
     * @param {string} pkConsumer Consumer code
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersPkConsumerSchedaPost(pkConsumer: string, body?: string, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersPkConsumerSchedaPost(pkConsumer, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Consumer static data
     * @summary Consumer static data
     * @param {string} pkConsumer Consumer unique code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersPkConsumerStatisticsGet(pkConsumer: string, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersPkConsumerStatisticsGet(pkConsumer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Consumer static data insegna pop-up (like FE)
     * @summary Consumer static data insegna pop-up (like FE)
     * @param {string} pkConsumer Consumer unique code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersPkConsumerStatisticsInsegnaGet(pkConsumer: string, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersPkConsumerStatisticsInsegnaGet(pkConsumer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Report on daily consumer cash usage
     * @summary Consumer used cash data
     * @param {string} pkConsumer Consumer code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersPkConsumerUsedCashGet(pkConsumer: string, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersPkConsumerUsedCashGet(pkConsumer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Ottiene la lista dei prodotti indicati come desiderati della consumer
     * @summary Ottiene la lista dei desiderata della consumer
     * @param {string} pkConsumer Consumer ID
     * @param {string} [brand] brand
     * @param {number} [skuLimit] limite al numero di  sku restituiti per ogni wishlist
     * @param {0 | 1} [details] aggiungere o meno dettagli alla risposta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersPkConsumerWishlistsGet(pkConsumer: string, brand?: string, skuLimit?: number, details?: 0 | 1, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersPkConsumerWishlistsGet(pkConsumer, brand, skuLimit, details, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Consumer creation
     * @param {PostConsumer} [postConsumer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersPost(postConsumer?: PostConsumer, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersPost(postConsumer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Servizio che restituisce l\'elenco delle lingue disponibilei per il pdf della scheda Privacy in Pdf
     * @summary Lingue disponibili per la schedaPDF
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersSchedaLingueGet(options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersSchedaLingueGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Searches for taxfree consumers filtered by global blue
     * @summary Taxfree Consumer search
     * @param {string} filterType global blue search method
     * @param {string} [filterValue] global blue search value
     * @param {string} [filterPassportNumber] passport number, only if filter[type] is AUTOFILL_PASSPORT
     * @param {string} [filterCountry] passport country, only if filter[type] is AUTOFILL_PASSPORT
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerApi
     */
    public apiV1ConsumersTaxfreeGet(filterType: string, filterValue?: string, filterPassportNumber?: string, filterCountry?: string, options?: AxiosRequestConfig) {
        return ConsumerApiFp(this.configuration).apiV1ConsumersTaxfreeGet(filterType, filterValue, filterPassportNumber, filterCountry, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CouponsApi - axios parameter creator
 * @export
 */
export const CouponsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Used to validate a coupon and obtain the list of item codes where the coupon is applicable
         * @summary Coupon validation
         * @param {string} couponCode Coupon code
         * @param {PostCoupon} [postCoupon]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CouponsCouponCodeCheckPost: async (couponCode: string, postCoupon?: PostCoupon, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'couponCode' is not null or undefined
            assertParamExists('apiV1CouponsCouponCodeCheckPost', 'couponCode', couponCode)
            const localVarPath = `/api/v1/coupons/{coupon_code}/check`
                .replace(`{${"coupon_code"}}`, encodeURIComponent(String(couponCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCoupon, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to validate a coupon over a sale transaction
         * @summary Coupon validation over sale transaction
         * @param {PostCoupon1} [postCoupon1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CouponsValidateSalePost: async (postCoupon1?: PostCoupon1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/coupons/validate/sale`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCoupon1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CouponsApi - functional programming interface
 * @export
 */
export const CouponsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CouponsApiAxiosParamCreator(configuration)
    return {
        /**
         * Used to validate a coupon and obtain the list of item codes where the coupon is applicable
         * @summary Coupon validation
         * @param {string} couponCode Coupon code
         * @param {PostCoupon} [postCoupon]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CouponsCouponCodeCheckPost(couponCode: string, postCoupon?: PostCoupon, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Coupon>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CouponsCouponCodeCheckPost(couponCode, postCoupon, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Used to validate a coupon over a sale transaction
         * @summary Coupon validation over sale transaction
         * @param {PostCoupon1} [postCoupon1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CouponsValidateSalePost(postCoupon1?: PostCoupon1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Coupon1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CouponsValidateSalePost(postCoupon1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CouponsApi - factory interface
 * @export
 */
export const CouponsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CouponsApiFp(configuration)
    return {
        /**
         * Used to validate a coupon and obtain the list of item codes where the coupon is applicable
         * @summary Coupon validation
         * @param {string} couponCode Coupon code
         * @param {PostCoupon} [postCoupon]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CouponsCouponCodeCheckPost(couponCode: string, postCoupon?: PostCoupon, options?: any): AxiosPromise<Coupon> {
            return localVarFp.apiV1CouponsCouponCodeCheckPost(couponCode, postCoupon, options).then((request) => request(axios, basePath));
        },
        /**
         * Used to validate a coupon over a sale transaction
         * @summary Coupon validation over sale transaction
         * @param {PostCoupon1} [postCoupon1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CouponsValidateSalePost(postCoupon1?: PostCoupon1, options?: any): AxiosPromise<Coupon1> {
            return localVarFp.apiV1CouponsValidateSalePost(postCoupon1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CouponsApi - object-oriented interface
 * @export
 * @class CouponsApi
 * @extends {BaseAPI}
 */
export class CouponsApi extends BaseAPI {
    /**
     * Used to validate a coupon and obtain the list of item codes where the coupon is applicable
     * @summary Coupon validation
     * @param {string} couponCode Coupon code
     * @param {PostCoupon} [postCoupon]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public apiV1CouponsCouponCodeCheckPost(couponCode: string, postCoupon?: PostCoupon, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).apiV1CouponsCouponCodeCheckPost(couponCode, postCoupon, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Used to validate a coupon over a sale transaction
     * @summary Coupon validation over sale transaction
     * @param {PostCoupon1} [postCoupon1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public apiV1CouponsValidateSalePost(postCoupon1?: PostCoupon1, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).apiV1CouponsValidateSalePost(postCoupon1, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CreditCardApi - axios parameter creator
 * @export
 */
export const CreditCardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Instructs the BE to issue a refund through the pos terminal
         * @summary Credit Card refund B2E
         * @param {string} saleId Sale unique id: codice_negozio,id_transazione
         * @param {string} posTransactionId CC transaction unique id
         * @param {ReturnsCCRefund1} [returnsCCRefund1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdPost: async (saleId: string, posTransactionId: string, returnsCCRefund1?: ReturnsCCRefund1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdPost', 'saleId', saleId)
            // verify required parameter 'posTransactionId' is not null or undefined
            assertParamExists('apiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdPost', 'posTransactionId', posTransactionId)
            const localVarPath = `/api/v1/refunds-b2e/{sale_id}/payments/pos/{pos_transaction_id}`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)))
                .replace(`{${"pos_transaction_id"}}`, encodeURIComponent(String(posTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(returnsCCRefund1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Instructs the BE to issue a refund through the pos terminal
         * @summary Return Credit Card refund
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {ReturnsCCRefund} [returnsCCRefund]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReturnsSaleIdPaymentsPosPosTransactionIdPost: async (saleId: string, posTransactionId: string, returnsCCRefund?: ReturnsCCRefund, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1ReturnsSaleIdPaymentsPosPosTransactionIdPost', 'saleId', saleId)
            // verify required parameter 'posTransactionId' is not null or undefined
            assertParamExists('apiV1ReturnsSaleIdPaymentsPosPosTransactionIdPost', 'posTransactionId', posTransactionId)
            const localVarPath = `/api/v1/returns/{sale_id}/payments/pos/{pos_transaction_id}`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)))
                .replace(`{${"pos_transaction_id"}}`, encodeURIComponent(String(posTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(returnsCCRefund, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Polling on the pos terminal status
         * @summary PosStatus
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {CCStatus} [cCStatus]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusPost: async (saleId: string, posTransactionId: string, cCStatus?: CCStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusPost', 'saleId', saleId)
            // verify required parameter 'posTransactionId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusPost', 'posTransactionId', posTransactionId)
            const localVarPath = `/api/v1/sales/{sale_id}/payments/credit_card/{pos_transaction_id}/status`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)))
                .replace(`{${"pos_transaction_id"}}`, encodeURIComponent(String(posTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cCStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Instructs the BE to issue a payment on the pos terminal
         * @summary Sale Credit Card payment
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {SaleCCPayment} [saleCCPayment]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdPaymentsPosPosTransactionIdPost: async (saleId: string, posTransactionId: string, saleCCPayment?: SaleCCPayment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdPaymentsPosPosTransactionIdPost', 'saleId', saleId)
            // verify required parameter 'posTransactionId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdPaymentsPosPosTransactionIdPost', 'posTransactionId', posTransactionId)
            const localVarPath = `/api/v1/sales/{sale_id}/payments/pos/{pos_transaction_id}`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)))
                .replace(`{${"pos_transaction_id"}}`, encodeURIComponent(String(posTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saleCCPayment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Instructs the BE to issue a payment pos terminal for a suspended credit
         * @summary Suspended Credit - Credit Card payment
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {SuspendedCreditCCPayment} [suspendedCreditCCPayment]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdPost: async (saleId: string, posTransactionId: string, suspendedCreditCCPayment?: SuspendedCreditCCPayment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdPost', 'saleId', saleId)
            // verify required parameter 'posTransactionId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdPost', 'posTransactionId', posTransactionId)
            const localVarPath = `/api/v1/sales/{sale_id}/payments/pos-suspended/{pos_transaction_id}`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)))
                .replace(`{${"pos_transaction_id"}}`, encodeURIComponent(String(posTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(suspendedCreditCCPayment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Instructs the BE to issue a payment reversal on the pos terminal
         * @summary Sale Credit Card payment reversal
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {SaleCCPaymentReversal} [saleCCPaymentReversal]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdPaymentsReversalPosPosTransactionIdPost: async (saleId: string, posTransactionId: string, saleCCPaymentReversal?: SaleCCPaymentReversal, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdPaymentsReversalPosPosTransactionIdPost', 'saleId', saleId)
            // verify required parameter 'posTransactionId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdPaymentsReversalPosPosTransactionIdPost', 'posTransactionId', posTransactionId)
            const localVarPath = `/api/v1/sales/{sale_id}/payments-reversal/pos/{pos_transaction_id}`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)))
                .replace(`{${"pos_transaction_id"}}`, encodeURIComponent(String(posTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saleCCPaymentReversal, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CreditCardApi - functional programming interface
 * @export
 */
export const CreditCardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CreditCardApiAxiosParamCreator(configuration)
    return {
        /**
         * Instructs the BE to issue a refund through the pos terminal
         * @summary Credit Card refund B2E
         * @param {string} saleId Sale unique id: codice_negozio,id_transazione
         * @param {string} posTransactionId CC transaction unique id
         * @param {ReturnsCCRefund1} [returnsCCRefund1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdPost(saleId: string, posTransactionId: string, returnsCCRefund1?: ReturnsCCRefund1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CCPayment1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdPost(saleId, posTransactionId, returnsCCRefund1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Instructs the BE to issue a refund through the pos terminal
         * @summary Return Credit Card refund
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {ReturnsCCRefund} [returnsCCRefund]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReturnsSaleIdPaymentsPosPosTransactionIdPost(saleId: string, posTransactionId: string, returnsCCRefund?: ReturnsCCRefund, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CCPayment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReturnsSaleIdPaymentsPosPosTransactionIdPost(saleId, posTransactionId, returnsCCRefund, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Polling on the pos terminal status
         * @summary PosStatus
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {CCStatus} [cCStatus]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusPost(saleId: string, posTransactionId: string, cCStatus?: CCStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CCPaymentResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusPost(saleId, posTransactionId, cCStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Instructs the BE to issue a payment on the pos terminal
         * @summary Sale Credit Card payment
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {SaleCCPayment} [saleCCPayment]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesSaleIdPaymentsPosPosTransactionIdPost(saleId: string, posTransactionId: string, saleCCPayment?: SaleCCPayment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CCPayment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesSaleIdPaymentsPosPosTransactionIdPost(saleId, posTransactionId, saleCCPayment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Instructs the BE to issue a payment pos terminal for a suspended credit
         * @summary Suspended Credit - Credit Card payment
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {SuspendedCreditCCPayment} [suspendedCreditCCPayment]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdPost(saleId: string, posTransactionId: string, suspendedCreditCCPayment?: SuspendedCreditCCPayment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CCPayment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdPost(saleId, posTransactionId, suspendedCreditCCPayment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Instructs the BE to issue a payment reversal on the pos terminal
         * @summary Sale Credit Card payment reversal
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {SaleCCPaymentReversal} [saleCCPaymentReversal]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesSaleIdPaymentsReversalPosPosTransactionIdPost(saleId: string, posTransactionId: string, saleCCPaymentReversal?: SaleCCPaymentReversal, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CCPayment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesSaleIdPaymentsReversalPosPosTransactionIdPost(saleId, posTransactionId, saleCCPaymentReversal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CreditCardApi - factory interface
 * @export
 */
export const CreditCardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CreditCardApiFp(configuration)
    return {
        /**
         * Instructs the BE to issue a refund through the pos terminal
         * @summary Credit Card refund B2E
         * @param {string} saleId Sale unique id: codice_negozio,id_transazione
         * @param {string} posTransactionId CC transaction unique id
         * @param {ReturnsCCRefund1} [returnsCCRefund1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdPost(saleId: string, posTransactionId: string, returnsCCRefund1?: ReturnsCCRefund1, options?: any): AxiosPromise<CCPayment1> {
            return localVarFp.apiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdPost(saleId, posTransactionId, returnsCCRefund1, options).then((request) => request(axios, basePath));
        },
        /**
         * Instructs the BE to issue a refund through the pos terminal
         * @summary Return Credit Card refund
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {ReturnsCCRefund} [returnsCCRefund]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReturnsSaleIdPaymentsPosPosTransactionIdPost(saleId: string, posTransactionId: string, returnsCCRefund?: ReturnsCCRefund, options?: any): AxiosPromise<CCPayment> {
            return localVarFp.apiV1ReturnsSaleIdPaymentsPosPosTransactionIdPost(saleId, posTransactionId, returnsCCRefund, options).then((request) => request(axios, basePath));
        },
        /**
         * Polling on the pos terminal status
         * @summary PosStatus
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {CCStatus} [cCStatus]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusPost(saleId: string, posTransactionId: string, cCStatus?: CCStatus, options?: any): AxiosPromise<CCPaymentResult> {
            return localVarFp.apiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusPost(saleId, posTransactionId, cCStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * Instructs the BE to issue a payment on the pos terminal
         * @summary Sale Credit Card payment
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {SaleCCPayment} [saleCCPayment]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdPaymentsPosPosTransactionIdPost(saleId: string, posTransactionId: string, saleCCPayment?: SaleCCPayment, options?: any): AxiosPromise<CCPayment> {
            return localVarFp.apiV1SalesSaleIdPaymentsPosPosTransactionIdPost(saleId, posTransactionId, saleCCPayment, options).then((request) => request(axios, basePath));
        },
        /**
         * Instructs the BE to issue a payment pos terminal for a suspended credit
         * @summary Suspended Credit - Credit Card payment
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {SuspendedCreditCCPayment} [suspendedCreditCCPayment]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdPost(saleId: string, posTransactionId: string, suspendedCreditCCPayment?: SuspendedCreditCCPayment, options?: any): AxiosPromise<CCPayment> {
            return localVarFp.apiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdPost(saleId, posTransactionId, suspendedCreditCCPayment, options).then((request) => request(axios, basePath));
        },
        /**
         * Instructs the BE to issue a payment reversal on the pos terminal
         * @summary Sale Credit Card payment reversal
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {SaleCCPaymentReversal} [saleCCPaymentReversal]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdPaymentsReversalPosPosTransactionIdPost(saleId: string, posTransactionId: string, saleCCPaymentReversal?: SaleCCPaymentReversal, options?: any): AxiosPromise<CCPayment> {
            return localVarFp.apiV1SalesSaleIdPaymentsReversalPosPosTransactionIdPost(saleId, posTransactionId, saleCCPaymentReversal, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CreditCardApi - object-oriented interface
 * @export
 * @class CreditCardApi
 * @extends {BaseAPI}
 */
export class CreditCardApi extends BaseAPI {
    /**
     * Instructs the BE to issue a refund through the pos terminal
     * @summary Credit Card refund B2E
     * @param {string} saleId Sale unique id: codice_negozio,id_transazione
     * @param {string} posTransactionId CC transaction unique id
     * @param {ReturnsCCRefund1} [returnsCCRefund1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCardApi
     */
    public apiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdPost(saleId: string, posTransactionId: string, returnsCCRefund1?: ReturnsCCRefund1, options?: AxiosRequestConfig) {
        return CreditCardApiFp(this.configuration).apiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdPost(saleId, posTransactionId, returnsCCRefund1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Instructs the BE to issue a refund through the pos terminal
     * @summary Return Credit Card refund
     * @param {string} saleId Sale unique id
     * @param {string} posTransactionId CC transaction unique id
     * @param {ReturnsCCRefund} [returnsCCRefund]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCardApi
     */
    public apiV1ReturnsSaleIdPaymentsPosPosTransactionIdPost(saleId: string, posTransactionId: string, returnsCCRefund?: ReturnsCCRefund, options?: AxiosRequestConfig) {
        return CreditCardApiFp(this.configuration).apiV1ReturnsSaleIdPaymentsPosPosTransactionIdPost(saleId, posTransactionId, returnsCCRefund, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Polling on the pos terminal status
     * @summary PosStatus
     * @param {string} saleId Sale unique id
     * @param {string} posTransactionId CC transaction unique id
     * @param {CCStatus} [cCStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCardApi
     */
    public apiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusPost(saleId: string, posTransactionId: string, cCStatus?: CCStatus, options?: AxiosRequestConfig) {
        return CreditCardApiFp(this.configuration).apiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusPost(saleId, posTransactionId, cCStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Instructs the BE to issue a payment on the pos terminal
     * @summary Sale Credit Card payment
     * @param {string} saleId Sale unique id
     * @param {string} posTransactionId CC transaction unique id
     * @param {SaleCCPayment} [saleCCPayment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCardApi
     */
    public apiV1SalesSaleIdPaymentsPosPosTransactionIdPost(saleId: string, posTransactionId: string, saleCCPayment?: SaleCCPayment, options?: AxiosRequestConfig) {
        return CreditCardApiFp(this.configuration).apiV1SalesSaleIdPaymentsPosPosTransactionIdPost(saleId, posTransactionId, saleCCPayment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Instructs the BE to issue a payment pos terminal for a suspended credit
     * @summary Suspended Credit - Credit Card payment
     * @param {string} saleId Sale unique id
     * @param {string} posTransactionId CC transaction unique id
     * @param {SuspendedCreditCCPayment} [suspendedCreditCCPayment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCardApi
     */
    public apiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdPost(saleId: string, posTransactionId: string, suspendedCreditCCPayment?: SuspendedCreditCCPayment, options?: AxiosRequestConfig) {
        return CreditCardApiFp(this.configuration).apiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdPost(saleId, posTransactionId, suspendedCreditCCPayment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Instructs the BE to issue a payment reversal on the pos terminal
     * @summary Sale Credit Card payment reversal
     * @param {string} saleId Sale unique id
     * @param {string} posTransactionId CC transaction unique id
     * @param {SaleCCPaymentReversal} [saleCCPaymentReversal]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCardApi
     */
    public apiV1SalesSaleIdPaymentsReversalPosPosTransactionIdPost(saleId: string, posTransactionId: string, saleCCPaymentReversal?: SaleCCPaymentReversal, options?: AxiosRequestConfig) {
        return CreditCardApiFp(this.configuration).apiV1SalesSaleIdPaymentsReversalPosPosTransactionIdPost(saleId, posTransactionId, saleCCPaymentReversal, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Chiamate per pttenere le informazioni da mostrare nella dashboard:  la prima chiamata obbligatoria è con \"elemento\" = \'promemoria\'  Questa restituisce una serie di flag utili per le successive chiamate (type: \'dashboard_elements\')  e una serie di messaggi da mostrare negli oggetti successivi (type \'dashboard_promemoria\')  Per ogni elemento sarà ottenuto un oggetto \'dashboard_promemoria\' o un oggetto data = []  i valori possibili di elemento sono:  - promemoria - info_bolle - info_vendite_non_accolte - info_shortage - b2c - b2e - cec
         * @summary Chiamate per popolare la dashboard
         * @param {'promemoria' | 'info_bolle' | 'info_vendite_non_accolte' | 'info_shortage' | 'b2c' | 'b2e' | 'cec'} elemento elemento dati richiesti
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DashboardPromemoriaElementoGet: async (elemento: 'promemoria' | 'info_bolle' | 'info_vendite_non_accolte' | 'info_shortage' | 'b2c' | 'b2e' | 'cec', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elemento' is not null or undefined
            assertParamExists('apiV1DashboardPromemoriaElementoGet', 'elemento', elemento)
            const localVarPath = `/api/v1/dashboard/promemoria/{elemento}`
                .replace(`{${"elemento"}}`, encodeURIComponent(String(elemento)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Chiamata per l\'elenco dei siti esterni (sezione siti esterni)
         * @summary Chiamata per ottenere l\'elenco di link esterni
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DashboardSitiEsterniGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dashboard/siti-esterni`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Chiamata per ottenere lo stato di alcuni elementi relativi al negozio
         * @summary Chiamate per popolare la dashboard sezione stato-negozio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DashboardStatoNegozioGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dashboard/stato-negozio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Chiamata per informazioni sullo stato del registratore di cassa (sezione stato-negozio)
         * @summary Chiamata per ottenere lo stato di registratore di cassa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DashboardStatoNegozioRegistratoreCassaGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dashboard/stato-negozio/registratore-cassa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * Chiamate per pttenere le informazioni da mostrare nella dashboard:  la prima chiamata obbligatoria è con \"elemento\" = \'promemoria\'  Questa restituisce una serie di flag utili per le successive chiamate (type: \'dashboard_elements\')  e una serie di messaggi da mostrare negli oggetti successivi (type \'dashboard_promemoria\')  Per ogni elemento sarà ottenuto un oggetto \'dashboard_promemoria\' o un oggetto data = []  i valori possibili di elemento sono:  - promemoria - info_bolle - info_vendite_non_accolte - info_shortage - b2c - b2e - cec
         * @summary Chiamate per popolare la dashboard
         * @param {'promemoria' | 'info_bolle' | 'info_vendite_non_accolte' | 'info_shortage' | 'b2c' | 'b2e' | 'cec'} elemento elemento dati richiesti
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DashboardPromemoriaElementoGet(elemento: 'promemoria' | 'info_bolle' | 'info_vendite_non_accolte' | 'info_shortage' | 'b2c' | 'b2e' | 'cec', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePayload9>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DashboardPromemoriaElementoGet(elemento, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Chiamata per l\'elenco dei siti esterni (sezione siti esterni)
         * @summary Chiamata per ottenere l\'elenco di link esterni
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DashboardSitiEsterniGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePayload10>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DashboardSitiEsterniGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Chiamata per ottenere lo stato di alcuni elementi relativi al negozio
         * @summary Chiamate per popolare la dashboard sezione stato-negozio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DashboardStatoNegozioGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDashboardStatoNegozio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DashboardStatoNegozioGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Chiamata per informazioni sullo stato del registratore di cassa (sezione stato-negozio)
         * @summary Chiamata per ottenere lo stato di registratore di cassa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DashboardStatoNegozioRegistratoreCassaGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseStatoRegistratoreCassa>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DashboardStatoNegozioRegistratoreCassaGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * Chiamate per pttenere le informazioni da mostrare nella dashboard:  la prima chiamata obbligatoria è con \"elemento\" = \'promemoria\'  Questa restituisce una serie di flag utili per le successive chiamate (type: \'dashboard_elements\')  e una serie di messaggi da mostrare negli oggetti successivi (type \'dashboard_promemoria\')  Per ogni elemento sarà ottenuto un oggetto \'dashboard_promemoria\' o un oggetto data = []  i valori possibili di elemento sono:  - promemoria - info_bolle - info_vendite_non_accolte - info_shortage - b2c - b2e - cec
         * @summary Chiamate per popolare la dashboard
         * @param {'promemoria' | 'info_bolle' | 'info_vendite_non_accolte' | 'info_shortage' | 'b2c' | 'b2e' | 'cec'} elemento elemento dati richiesti
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DashboardPromemoriaElementoGet(elemento: 'promemoria' | 'info_bolle' | 'info_vendite_non_accolte' | 'info_shortage' | 'b2c' | 'b2e' | 'cec', options?: any): AxiosPromise<ResponsePayload9> {
            return localVarFp.apiV1DashboardPromemoriaElementoGet(elemento, options).then((request) => request(axios, basePath));
        },
        /**
         * Chiamata per l\'elenco dei siti esterni (sezione siti esterni)
         * @summary Chiamata per ottenere l\'elenco di link esterni
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DashboardSitiEsterniGet(options?: any): AxiosPromise<ResponsePayload10> {
            return localVarFp.apiV1DashboardSitiEsterniGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Chiamata per ottenere lo stato di alcuni elementi relativi al negozio
         * @summary Chiamate per popolare la dashboard sezione stato-negozio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DashboardStatoNegozioGet(options?: any): AxiosPromise<ResponseDashboardStatoNegozio> {
            return localVarFp.apiV1DashboardStatoNegozioGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Chiamata per informazioni sullo stato del registratore di cassa (sezione stato-negozio)
         * @summary Chiamata per ottenere lo stato di registratore di cassa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DashboardStatoNegozioRegistratoreCassaGet(options?: any): AxiosPromise<ResponseStatoRegistratoreCassa> {
            return localVarFp.apiV1DashboardStatoNegozioRegistratoreCassaGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * Chiamate per pttenere le informazioni da mostrare nella dashboard:  la prima chiamata obbligatoria è con \"elemento\" = \'promemoria\'  Questa restituisce una serie di flag utili per le successive chiamate (type: \'dashboard_elements\')  e una serie di messaggi da mostrare negli oggetti successivi (type \'dashboard_promemoria\')  Per ogni elemento sarà ottenuto un oggetto \'dashboard_promemoria\' o un oggetto data = []  i valori possibili di elemento sono:  - promemoria - info_bolle - info_vendite_non_accolte - info_shortage - b2c - b2e - cec
     * @summary Chiamate per popolare la dashboard
     * @param {'promemoria' | 'info_bolle' | 'info_vendite_non_accolte' | 'info_shortage' | 'b2c' | 'b2e' | 'cec'} elemento elemento dati richiesti
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiV1DashboardPromemoriaElementoGet(elemento: 'promemoria' | 'info_bolle' | 'info_vendite_non_accolte' | 'info_shortage' | 'b2c' | 'b2e' | 'cec', options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiV1DashboardPromemoriaElementoGet(elemento, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Chiamata per l\'elenco dei siti esterni (sezione siti esterni)
     * @summary Chiamata per ottenere l\'elenco di link esterni
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiV1DashboardSitiEsterniGet(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiV1DashboardSitiEsterniGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Chiamata per ottenere lo stato di alcuni elementi relativi al negozio
     * @summary Chiamate per popolare la dashboard sezione stato-negozio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiV1DashboardStatoNegozioGet(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiV1DashboardStatoNegozioGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Chiamata per informazioni sullo stato del registratore di cassa (sezione stato-negozio)
     * @summary Chiamata per ottenere lo stato di registratore di cassa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiV1DashboardStatoNegozioRegistratoreCassaGet(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiV1DashboardStatoNegozioRegistratoreCassaGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DigitalLoyaltyCardApi - axios parameter creator
 * @export
 */
export const DigitalLoyaltyCardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Recupera l\'elenco delle lingue
         * @summary Elenco lingue
         * @param {string} codicePromozione Codice della promozione
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersCardsCodicePromozioneSchedaLingueGet: async (codicePromozione: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'codicePromozione' is not null or undefined
            assertParamExists('apiV1ConsumersCardsCodicePromozioneSchedaLingueGet', 'codicePromozione', codicePromozione)
            const localVarPath = `/api/v1/consumers/cards/{codice_promozione}/scheda/lingue`
                .replace(`{${"codice_promozione"}}`, encodeURIComponent(String(codicePromozione)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Crea il documento pdf loyalty firmato e viene inviato alla mail del destinatario. Se l\'invio non avviene, la richiesta viene accodata
         * @summary Creazione documento ed invio per mail - \"Conferma\"
         * @param {string} pkConsumer Consumer code
         * @param {LoyaltyCreateEmailSend} loyaltyCreateEmailSend
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsArchiveDocumentPost: async (pkConsumer: string, loyaltyCreateEmailSend: LoyaltyCreateEmailSend, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsArchiveDocumentPost', 'pkConsumer', pkConsumer)
            // verify required parameter 'loyaltyCreateEmailSend' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsArchiveDocumentPost', 'loyaltyCreateEmailSend', loyaltyCreateEmailSend)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/cards/archive_document`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loyaltyCreateEmailSend, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Scarica il pdf firmato del documento da CRM
         * @summary Scarica il pdf firmato da CRM
         * @param {string} pkConsumer Consumer code
         * @param {string} codPromozione Codice della promozione
         * @param {string} codCard Codice della card (barcode)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsCodPromozioneCodCardDownloadDocumentGet: async (pkConsumer: string, codPromozione: string, codCard: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsCodPromozioneCodCardDownloadDocumentGet', 'pkConsumer', pkConsumer)
            // verify required parameter 'codPromozione' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsCodPromozioneCodCardDownloadDocumentGet', 'codPromozione', codPromozione)
            // verify required parameter 'codCard' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsCodPromozioneCodCardDownloadDocumentGet', 'codCard', codCard)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/cards/{cod_promozione}/{cod_card}/download_document`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)))
                .replace(`{${"cod_promozione"}}`, encodeURIComponent(String(codPromozione)))
                .replace(`{${"cod_card"}}`, encodeURIComponent(String(codCard)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stampa il pdf loyalty firmato o non firmato
         * @summary Stampa pdf Loyalty non firmato
         * @param {string} pkConsumer Consumer code
         * @param {string} codicePromozione Codice della promozione
         * @param {string} lingua Lingua
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsCodicePromozioneSchedaLinguaGet: async (pkConsumer: string, codicePromozione: string, lingua: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsCodicePromozioneSchedaLinguaGet', 'pkConsumer', pkConsumer)
            // verify required parameter 'codicePromozione' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsCodicePromozioneSchedaLinguaGet', 'codicePromozione', codicePromozione)
            // verify required parameter 'lingua' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsCodicePromozioneSchedaLinguaGet', 'lingua', lingua)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/cards/{codice_promozione}/scheda/{lingua}`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)))
                .replace(`{${"codice_promozione"}}`, encodeURIComponent(String(codicePromozione)))
                .replace(`{${"lingua"}}`, encodeURIComponent(String(lingua)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stampa il pdf loyalty firmato
         * @summary Stampa pdf Loyalty firmato
         * @param {string} pkConsumer Consumer code
         * @param {string} codicePromozione Codice della promozione
         * @param {string} lingua Lingua
         * @param {LoyaltyPdfContactFormSign} loyaltyPdfContactFormSign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsCodicePromozioneSchedaLinguaPost: async (pkConsumer: string, codicePromozione: string, lingua: string, loyaltyPdfContactFormSign: LoyaltyPdfContactFormSign, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsCodicePromozioneSchedaLinguaPost', 'pkConsumer', pkConsumer)
            // verify required parameter 'codicePromozione' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsCodicePromozioneSchedaLinguaPost', 'codicePromozione', codicePromozione)
            // verify required parameter 'lingua' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsCodicePromozioneSchedaLinguaPost', 'lingua', lingua)
            // verify required parameter 'loyaltyPdfContactFormSign' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsCodicePromozioneSchedaLinguaPost', 'loyaltyPdfContactFormSign', loyaltyPdfContactFormSign)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/cards/{codice_promozione}/scheda/{lingua}`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)))
                .replace(`{${"codice_promozione"}}`, encodeURIComponent(String(codicePromozione)))
                .replace(`{${"lingua"}}`, encodeURIComponent(String(lingua)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loyaltyPdfContactFormSign, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Crea una nuova card digitale
         * @summary Creazione card digitale
         * @param {string} pkConsumer Consumer code
         * @param {LoyaltyCardCreation} loyaltyCardCreation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsCreatePost: async (pkConsumer: string, loyaltyCardCreation: LoyaltyCardCreation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsCreatePost', 'pkConsumer', pkConsumer)
            // verify required parameter 'loyaltyCardCreation' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsCreatePost', 'loyaltyCardCreation', loyaltyCardCreation)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/cards/create`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loyaltyCardCreation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Permette di stampare direttamente sulla stampante locale, i dati della card digitale: programma loyalty, dati consumatrice e barcode (codice e grafica)
         * @summary Stampa card su Kube
         * @param {string} pkConsumer Consumer code
         * @param {LoyaltyCardPrint} loyaltyCardPrint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsPrintPost: async (pkConsumer: string, loyaltyCardPrint: LoyaltyCardPrint, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsPrintPost', 'pkConsumer', pkConsumer)
            // verify required parameter 'loyaltyCardPrint' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsPrintPost', 'loyaltyCardPrint', loyaltyCardPrint)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/cards/print`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loyaltyCardPrint, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Invia nuovamente la mail della card loyalty. Se l\'invio non avviene, la richiesta viene accodata
         * @summary Reinvio email card (CON accodamento)
         * @param {string} pkConsumer Consumer code
         * @param {LoyaltyEmailSend1} loyaltyEmailSend1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsSendEmailCardPost: async (pkConsumer: string, loyaltyEmailSend1: LoyaltyEmailSend1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsSendEmailCardPost', 'pkConsumer', pkConsumer)
            // verify required parameter 'loyaltyEmailSend1' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsSendEmailCardPost', 'loyaltyEmailSend1', loyaltyEmailSend1)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/cards/send_email_card`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loyaltyEmailSend1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Invia nuovamente la mail del documento pdf loyalty. Se l\'invio non avviene, la richiesta viene accodata
         * @summary Reinvio email documento (CON accodamento)
         * @param {string} pkConsumer Consumer code
         * @param {LoyaltyEmailSend} loyaltyEmailSend
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsSendEmailDocumentPost: async (pkConsumer: string, loyaltyEmailSend: LoyaltyEmailSend, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsSendEmailDocumentPost', 'pkConsumer', pkConsumer)
            // verify required parameter 'loyaltyEmailSend' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsSendEmailDocumentPost', 'loyaltyEmailSend', loyaltyEmailSend)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/cards/send_email_document`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loyaltyEmailSend, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DigitalLoyaltyCardApi - functional programming interface
 * @export
 */
export const DigitalLoyaltyCardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DigitalLoyaltyCardApiAxiosParamCreator(configuration)
    return {
        /**
         * Recupera l\'elenco delle lingue
         * @summary Elenco lingue
         * @param {string} codicePromozione Codice della promozione
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersCardsCodicePromozioneSchedaLingueGet(codicePromozione: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConsumerSchedaLingue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersCardsCodicePromozioneSchedaLingueGet(codicePromozione, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Crea il documento pdf loyalty firmato e viene inviato alla mail del destinatario. Se l\'invio non avviene, la richiesta viene accodata
         * @summary Creazione documento ed invio per mail - \"Conferma\"
         * @param {string} pkConsumer Consumer code
         * @param {LoyaltyCreateEmailSend} loyaltyCreateEmailSend
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerCardsArchiveDocumentPost(pkConsumer: string, loyaltyCreateEmailSend: LoyaltyCreateEmailSend, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoyaltyEmailSendResponse2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerCardsArchiveDocumentPost(pkConsumer, loyaltyCreateEmailSend, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Scarica il pdf firmato del documento da CRM
         * @summary Scarica il pdf firmato da CRM
         * @param {string} pkConsumer Consumer code
         * @param {string} codPromozione Codice della promozione
         * @param {string} codCard Codice della card (barcode)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerCardsCodPromozioneCodCardDownloadDocumentGet(pkConsumer: string, codPromozione: string, codCard: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLoyaltyPdfContactCRM>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerCardsCodPromozioneCodCardDownloadDocumentGet(pkConsumer, codPromozione, codCard, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stampa il pdf loyalty firmato o non firmato
         * @summary Stampa pdf Loyalty non firmato
         * @param {string} pkConsumer Consumer code
         * @param {string} codicePromozione Codice della promozione
         * @param {string} lingua Lingua
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerCardsCodicePromozioneSchedaLinguaGet(pkConsumer: string, codicePromozione: string, lingua: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerCardsCodicePromozioneSchedaLinguaGet(pkConsumer, codicePromozione, lingua, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stampa il pdf loyalty firmato
         * @summary Stampa pdf Loyalty firmato
         * @param {string} pkConsumer Consumer code
         * @param {string} codicePromozione Codice della promozione
         * @param {string} lingua Lingua
         * @param {LoyaltyPdfContactFormSign} loyaltyPdfContactFormSign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerCardsCodicePromozioneSchedaLinguaPost(pkConsumer: string, codicePromozione: string, lingua: string, loyaltyPdfContactFormSign: LoyaltyPdfContactFormSign, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerCardsCodicePromozioneSchedaLinguaPost(pkConsumer, codicePromozione, lingua, loyaltyPdfContactFormSign, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Crea una nuova card digitale
         * @summary Creazione card digitale
         * @param {string} pkConsumer Consumer code
         * @param {LoyaltyCardCreation} loyaltyCardCreation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerCardsCreatePost(pkConsumer: string, loyaltyCardCreation: LoyaltyCardCreation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConsumerCardsInfo1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerCardsCreatePost(pkConsumer, loyaltyCardCreation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Permette di stampare direttamente sulla stampante locale, i dati della card digitale: programma loyalty, dati consumatrice e barcode (codice e grafica)
         * @summary Stampa card su Kube
         * @param {string} pkConsumer Consumer code
         * @param {LoyaltyCardPrint} loyaltyCardPrint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerCardsPrintPost(pkConsumer: string, loyaltyCardPrint: LoyaltyCardPrint, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoyaltyCardPrintResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerCardsPrintPost(pkConsumer, loyaltyCardPrint, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Invia nuovamente la mail della card loyalty. Se l\'invio non avviene, la richiesta viene accodata
         * @summary Reinvio email card (CON accodamento)
         * @param {string} pkConsumer Consumer code
         * @param {LoyaltyEmailSend1} loyaltyEmailSend1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerCardsSendEmailCardPost(pkConsumer: string, loyaltyEmailSend1: LoyaltyEmailSend1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoyaltyEmailSendResponse1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerCardsSendEmailCardPost(pkConsumer, loyaltyEmailSend1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Invia nuovamente la mail del documento pdf loyalty. Se l\'invio non avviene, la richiesta viene accodata
         * @summary Reinvio email documento (CON accodamento)
         * @param {string} pkConsumer Consumer code
         * @param {LoyaltyEmailSend} loyaltyEmailSend
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerCardsSendEmailDocumentPost(pkConsumer: string, loyaltyEmailSend: LoyaltyEmailSend, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoyaltyEmailSendResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerCardsSendEmailDocumentPost(pkConsumer, loyaltyEmailSend, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DigitalLoyaltyCardApi - factory interface
 * @export
 */
export const DigitalLoyaltyCardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DigitalLoyaltyCardApiFp(configuration)
    return {
        /**
         * Recupera l\'elenco delle lingue
         * @summary Elenco lingue
         * @param {string} codicePromozione Codice della promozione
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersCardsCodicePromozioneSchedaLingueGet(codicePromozione: string, options?: any): AxiosPromise<GetConsumerSchedaLingue> {
            return localVarFp.apiV1ConsumersCardsCodicePromozioneSchedaLingueGet(codicePromozione, options).then((request) => request(axios, basePath));
        },
        /**
         * Crea il documento pdf loyalty firmato e viene inviato alla mail del destinatario. Se l\'invio non avviene, la richiesta viene accodata
         * @summary Creazione documento ed invio per mail - \"Conferma\"
         * @param {string} pkConsumer Consumer code
         * @param {LoyaltyCreateEmailSend} loyaltyCreateEmailSend
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsArchiveDocumentPost(pkConsumer: string, loyaltyCreateEmailSend: LoyaltyCreateEmailSend, options?: any): AxiosPromise<LoyaltyEmailSendResponse2> {
            return localVarFp.apiV1ConsumersPkConsumerCardsArchiveDocumentPost(pkConsumer, loyaltyCreateEmailSend, options).then((request) => request(axios, basePath));
        },
        /**
         * Scarica il pdf firmato del documento da CRM
         * @summary Scarica il pdf firmato da CRM
         * @param {string} pkConsumer Consumer code
         * @param {string} codPromozione Codice della promozione
         * @param {string} codCard Codice della card (barcode)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsCodPromozioneCodCardDownloadDocumentGet(pkConsumer: string, codPromozione: string, codCard: string, options?: any): AxiosPromise<GetLoyaltyPdfContactCRM> {
            return localVarFp.apiV1ConsumersPkConsumerCardsCodPromozioneCodCardDownloadDocumentGet(pkConsumer, codPromozione, codCard, options).then((request) => request(axios, basePath));
        },
        /**
         * Stampa il pdf loyalty firmato o non firmato
         * @summary Stampa pdf Loyalty non firmato
         * @param {string} pkConsumer Consumer code
         * @param {string} codicePromozione Codice della promozione
         * @param {string} lingua Lingua
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsCodicePromozioneSchedaLinguaGet(pkConsumer: string, codicePromozione: string, lingua: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiV1ConsumersPkConsumerCardsCodicePromozioneSchedaLinguaGet(pkConsumer, codicePromozione, lingua, options).then((request) => request(axios, basePath));
        },
        /**
         * Stampa il pdf loyalty firmato
         * @summary Stampa pdf Loyalty firmato
         * @param {string} pkConsumer Consumer code
         * @param {string} codicePromozione Codice della promozione
         * @param {string} lingua Lingua
         * @param {LoyaltyPdfContactFormSign} loyaltyPdfContactFormSign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsCodicePromozioneSchedaLinguaPost(pkConsumer: string, codicePromozione: string, lingua: string, loyaltyPdfContactFormSign: LoyaltyPdfContactFormSign, options?: any): AxiosPromise<string> {
            return localVarFp.apiV1ConsumersPkConsumerCardsCodicePromozioneSchedaLinguaPost(pkConsumer, codicePromozione, lingua, loyaltyPdfContactFormSign, options).then((request) => request(axios, basePath));
        },
        /**
         * Crea una nuova card digitale
         * @summary Creazione card digitale
         * @param {string} pkConsumer Consumer code
         * @param {LoyaltyCardCreation} loyaltyCardCreation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsCreatePost(pkConsumer: string, loyaltyCardCreation: LoyaltyCardCreation, options?: any): AxiosPromise<GetConsumerCardsInfo1> {
            return localVarFp.apiV1ConsumersPkConsumerCardsCreatePost(pkConsumer, loyaltyCardCreation, options).then((request) => request(axios, basePath));
        },
        /**
         * Permette di stampare direttamente sulla stampante locale, i dati della card digitale: programma loyalty, dati consumatrice e barcode (codice e grafica)
         * @summary Stampa card su Kube
         * @param {string} pkConsumer Consumer code
         * @param {LoyaltyCardPrint} loyaltyCardPrint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsPrintPost(pkConsumer: string, loyaltyCardPrint: LoyaltyCardPrint, options?: any): AxiosPromise<LoyaltyCardPrintResponse> {
            return localVarFp.apiV1ConsumersPkConsumerCardsPrintPost(pkConsumer, loyaltyCardPrint, options).then((request) => request(axios, basePath));
        },
        /**
         * Invia nuovamente la mail della card loyalty. Se l\'invio non avviene, la richiesta viene accodata
         * @summary Reinvio email card (CON accodamento)
         * @param {string} pkConsumer Consumer code
         * @param {LoyaltyEmailSend1} loyaltyEmailSend1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsSendEmailCardPost(pkConsumer: string, loyaltyEmailSend1: LoyaltyEmailSend1, options?: any): AxiosPromise<LoyaltyEmailSendResponse1> {
            return localVarFp.apiV1ConsumersPkConsumerCardsSendEmailCardPost(pkConsumer, loyaltyEmailSend1, options).then((request) => request(axios, basePath));
        },
        /**
         * Invia nuovamente la mail del documento pdf loyalty. Se l\'invio non avviene, la richiesta viene accodata
         * @summary Reinvio email documento (CON accodamento)
         * @param {string} pkConsumer Consumer code
         * @param {LoyaltyEmailSend} loyaltyEmailSend
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsSendEmailDocumentPost(pkConsumer: string, loyaltyEmailSend: LoyaltyEmailSend, options?: any): AxiosPromise<LoyaltyEmailSendResponse> {
            return localVarFp.apiV1ConsumersPkConsumerCardsSendEmailDocumentPost(pkConsumer, loyaltyEmailSend, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DigitalLoyaltyCardApi - object-oriented interface
 * @export
 * @class DigitalLoyaltyCardApi
 * @extends {BaseAPI}
 */
export class DigitalLoyaltyCardApi extends BaseAPI {
    /**
     * Recupera l\'elenco delle lingue
     * @summary Elenco lingue
     * @param {string} codicePromozione Codice della promozione
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalLoyaltyCardApi
     */
    public apiV1ConsumersCardsCodicePromozioneSchedaLingueGet(codicePromozione: string, options?: AxiosRequestConfig) {
        return DigitalLoyaltyCardApiFp(this.configuration).apiV1ConsumersCardsCodicePromozioneSchedaLingueGet(codicePromozione, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Crea il documento pdf loyalty firmato e viene inviato alla mail del destinatario. Se l\'invio non avviene, la richiesta viene accodata
     * @summary Creazione documento ed invio per mail - \"Conferma\"
     * @param {string} pkConsumer Consumer code
     * @param {LoyaltyCreateEmailSend} loyaltyCreateEmailSend
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalLoyaltyCardApi
     */
    public apiV1ConsumersPkConsumerCardsArchiveDocumentPost(pkConsumer: string, loyaltyCreateEmailSend: LoyaltyCreateEmailSend, options?: AxiosRequestConfig) {
        return DigitalLoyaltyCardApiFp(this.configuration).apiV1ConsumersPkConsumerCardsArchiveDocumentPost(pkConsumer, loyaltyCreateEmailSend, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Scarica il pdf firmato del documento da CRM
     * @summary Scarica il pdf firmato da CRM
     * @param {string} pkConsumer Consumer code
     * @param {string} codPromozione Codice della promozione
     * @param {string} codCard Codice della card (barcode)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalLoyaltyCardApi
     */
    public apiV1ConsumersPkConsumerCardsCodPromozioneCodCardDownloadDocumentGet(pkConsumer: string, codPromozione: string, codCard: string, options?: AxiosRequestConfig) {
        return DigitalLoyaltyCardApiFp(this.configuration).apiV1ConsumersPkConsumerCardsCodPromozioneCodCardDownloadDocumentGet(pkConsumer, codPromozione, codCard, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stampa il pdf loyalty firmato o non firmato
     * @summary Stampa pdf Loyalty non firmato
     * @param {string} pkConsumer Consumer code
     * @param {string} codicePromozione Codice della promozione
     * @param {string} lingua Lingua
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalLoyaltyCardApi
     */
    public apiV1ConsumersPkConsumerCardsCodicePromozioneSchedaLinguaGet(pkConsumer: string, codicePromozione: string, lingua: string, options?: AxiosRequestConfig) {
        return DigitalLoyaltyCardApiFp(this.configuration).apiV1ConsumersPkConsumerCardsCodicePromozioneSchedaLinguaGet(pkConsumer, codicePromozione, lingua, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stampa il pdf loyalty firmato
     * @summary Stampa pdf Loyalty firmato
     * @param {string} pkConsumer Consumer code
     * @param {string} codicePromozione Codice della promozione
     * @param {string} lingua Lingua
     * @param {LoyaltyPdfContactFormSign} loyaltyPdfContactFormSign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalLoyaltyCardApi
     */
    public apiV1ConsumersPkConsumerCardsCodicePromozioneSchedaLinguaPost(pkConsumer: string, codicePromozione: string, lingua: string, loyaltyPdfContactFormSign: LoyaltyPdfContactFormSign, options?: AxiosRequestConfig) {
        return DigitalLoyaltyCardApiFp(this.configuration).apiV1ConsumersPkConsumerCardsCodicePromozioneSchedaLinguaPost(pkConsumer, codicePromozione, lingua, loyaltyPdfContactFormSign, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Crea una nuova card digitale
     * @summary Creazione card digitale
     * @param {string} pkConsumer Consumer code
     * @param {LoyaltyCardCreation} loyaltyCardCreation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalLoyaltyCardApi
     */
    public apiV1ConsumersPkConsumerCardsCreatePost(pkConsumer: string, loyaltyCardCreation: LoyaltyCardCreation, options?: AxiosRequestConfig) {
        return DigitalLoyaltyCardApiFp(this.configuration).apiV1ConsumersPkConsumerCardsCreatePost(pkConsumer, loyaltyCardCreation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Permette di stampare direttamente sulla stampante locale, i dati della card digitale: programma loyalty, dati consumatrice e barcode (codice e grafica)
     * @summary Stampa card su Kube
     * @param {string} pkConsumer Consumer code
     * @param {LoyaltyCardPrint} loyaltyCardPrint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalLoyaltyCardApi
     */
    public apiV1ConsumersPkConsumerCardsPrintPost(pkConsumer: string, loyaltyCardPrint: LoyaltyCardPrint, options?: AxiosRequestConfig) {
        return DigitalLoyaltyCardApiFp(this.configuration).apiV1ConsumersPkConsumerCardsPrintPost(pkConsumer, loyaltyCardPrint, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Invia nuovamente la mail della card loyalty. Se l\'invio non avviene, la richiesta viene accodata
     * @summary Reinvio email card (CON accodamento)
     * @param {string} pkConsumer Consumer code
     * @param {LoyaltyEmailSend1} loyaltyEmailSend1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalLoyaltyCardApi
     */
    public apiV1ConsumersPkConsumerCardsSendEmailCardPost(pkConsumer: string, loyaltyEmailSend1: LoyaltyEmailSend1, options?: AxiosRequestConfig) {
        return DigitalLoyaltyCardApiFp(this.configuration).apiV1ConsumersPkConsumerCardsSendEmailCardPost(pkConsumer, loyaltyEmailSend1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Invia nuovamente la mail del documento pdf loyalty. Se l\'invio non avviene, la richiesta viene accodata
     * @summary Reinvio email documento (CON accodamento)
     * @param {string} pkConsumer Consumer code
     * @param {LoyaltyEmailSend} loyaltyEmailSend
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalLoyaltyCardApi
     */
    public apiV1ConsumersPkConsumerCardsSendEmailDocumentPost(pkConsumer: string, loyaltyEmailSend: LoyaltyEmailSend, options?: AxiosRequestConfig) {
        return DigitalLoyaltyCardApiFp(this.configuration).apiV1ConsumersPkConsumerCardsSendEmailDocumentPost(pkConsumer, loyaltyEmailSend, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EndlessAisleApi - axios parameter creator
 * @export
 */
export const EndlessAisleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Ottiene la lista dei prodotti indicati come desiderati della consumer
         * @summary Ottiene la lista dei desiderata della consumer
         * @param {string} pkConsumer Consumer ID
         * @param {string} [brand] brand
         * @param {number} [skuLimit] limite al numero di  sku restituiti per ogni wishlist
         * @param {0 | 1} [details] aggiungere o meno dettagli alla risposta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerWishlistsGet: async (pkConsumer: string, brand?: string, skuLimit?: number, details?: 0 | 1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerWishlistsGet', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/wishlists`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (brand !== undefined) {
                localVarQueryParameter['brand'] = brand;
            }

            if (skuLimit !== undefined) {
                localVarQueryParameter['sku_limit'] = skuLimit;
            }

            if (details !== undefined) {
                localVarQueryParameter['details'] = details;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets filters and his values
         * @summary Search for advanced filters
         * @param {string} [filterBrand] brand to be filtered
         * @param {string} [filterCategory] category to be filtered
         * @param {'collection' | 'mainline' | 'modelB2C' | 'newArrivals' | 'onSale' | 'price' | 'sizes' | 'stock' | 'yearSeason' | 'occasionFilter' | 'buttoningFilter' | 'comfortableFacet' | 'coatSilhouetteFilter' | 'shapeFilter' | 'bodyLengthFilter' | 'colorFilter' | 'materialFilter' | 'sleeveLengthFilter' | 'styleFilter' | 'typefilter'} [filterCode] code of the filter
         * @param {'Collezione' | 'Main Line' | 'Con Foto' | 'Nuovi Arrivi' | 'In Saldo' | 'Prezzo' | 'Taglie' | 'In giacenza' | 'Anno stagione'} [filterDesc] description of the filter, coupled with code, as price-Prezzo. Use as alternative of code
         * @param {string} [filterVal] value for the specified code or desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebAdvancedFiltersGet: async (filterBrand?: string, filterCategory?: string, filterCode?: 'collection' | 'mainline' | 'modelB2C' | 'newArrivals' | 'onSale' | 'price' | 'sizes' | 'stock' | 'yearSeason' | 'occasionFilter' | 'buttoningFilter' | 'comfortableFacet' | 'coatSilhouetteFilter' | 'shapeFilter' | 'bodyLengthFilter' | 'colorFilter' | 'materialFilter' | 'sleeveLengthFilter' | 'styleFilter' | 'typefilter', filterDesc?: 'Collezione' | 'Main Line' | 'Con Foto' | 'Nuovi Arrivi' | 'In Saldo' | 'Prezzo' | 'Taglie' | 'In giacenza' | 'Anno stagione', filterVal?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/advanced-filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterBrand !== undefined) {
                localVarQueryParameter['filter[brand]'] = filterBrand;
            }

            if (filterCategory !== undefined) {
                localVarQueryParameter['filter[category]'] = filterCategory;
            }

            if (filterCode !== undefined) {
                localVarQueryParameter['filter[code]'] = filterCode;
            }

            if (filterDesc !== undefined) {
                localVarQueryParameter['filter[desc]'] = filterDesc;
            }

            if (filterVal !== undefined) {
                localVarQueryParameter['filter[val]'] = filterVal;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets categories of models by brand
         * @summary Get list of categories
         * @param {string} filterBrand brand to be elaborated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebCategoriesGet: async (filterBrand: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterBrand' is not null or undefined
            assertParamExists('apiV1PoswebCategoriesGet', 'filterBrand', filterBrand)
            const localVarPath = `/api/v1/posweb/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterBrand !== undefined) {
                localVarQueryParameter['filter[brand]'] = filterBrand;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets models from catalog
         * @summary Search for models
         * @param {string} [filterSku] SKU to be elaborated
         * @param {string} [filterGetRelated] when equal to 1 all related models will be returned if sku exists
         * @param {string} [filterPkConsumer] pk_consumer to be elaborated
         * @param {string} [filterBrand] brand to be elaborated, comma separated values are admitted
         * @param {string} [filterCategoria] categoria to be elaborated
         * @param {number} [filterRighe] righe or number of record per page
         * @param {number} [filterPagina] pagina to be elaborated
         * @param {string} [filterModel] model to be elaborated
         * @param {string} [filterModelvariant] modelvariant to be elaborated
         * @param {string} [filterSizes] sizes to be elaborated, | string composed by groups and values values are comma separated, groups are pipe separated
         * @param {0 | 1} [filterStock] 1 or 0 to get stock informations
         * @param {string} [filterName] model name to be elaborated
         * @param {string} [filterEan] ean to be elaborated, comma separated values admitted
         * @param {0 | 1} [filterLikeMode] like_mode used in query instead of &#x3D;
         * @param {string} [filterRfidStore] product rfid
         * @param {string} [filterCodCategoria] product cod categoria
         * @param {string} [filterCodSottocategoria] product cod sottocategoria
         * @param {string} [filterCollection] filtri avanzati, collection
         * @param {string} [filterMainline] filtri avanzati, mainline
         * @param {string} [filterModelB2C] filtri avanzati, modelB2C
         * @param {string} [filterNewArrivals] filtri avanzati, newArrivals
         * @param {string} [filterOnSale] filtri avanzati, onSale
         * @param {string} [filterPrice] filtri avanzati, price
         * @param {number} [filterMinprice] filtri avanzati, minprice
         * @param {number} [filterMaxprice] filtri avanzati, maxprice
         * @param {string} [filterYearSeason] filtri avanzati, yearSeason
         * @param {string} [filterStyleFilter] filtri avanzati, styleFilter
         * @param {string} [filterBodyLengthFilter] filtri avanzati, bodyLengthFilter
         * @param {string} [filterColorFilter] filtri avanzati, colorFilter
         * @param {string} [filterMaterialFilter] filtri avanzati, materialFilter
         * @param {string} [filterTypefilter] filtri avanzati, typefilter
         * @param {string} [filterSleeveLengthFilter] filtri avanzati, sleeveLengthFilter
         * @param {string} [filterOccasionFilter] filtri avanzati, occasionFilter
         * @param {string} [filterButtoningFilter] filtri avanzati, buttoningFilter
         * @param {string} [filterComfortableFacet] filtri avanzati, comfortableFacet
         * @param {string} [filterCoatSilhouetteFilter] filtri avanzati, coatSilhouetteFilter
         * @param {string} [filterShapeFilter] filtri avanzati, shapeFilter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebModelsGet: async (filterSku?: string, filterGetRelated?: string, filterPkConsumer?: string, filterBrand?: string, filterCategoria?: string, filterRighe?: number, filterPagina?: number, filterModel?: string, filterModelvariant?: string, filterSizes?: string, filterStock?: 0 | 1, filterName?: string, filterEan?: string, filterLikeMode?: 0 | 1, filterRfidStore?: string, filterCodCategoria?: string, filterCodSottocategoria?: string, filterCollection?: string, filterMainline?: string, filterModelB2C?: string, filterNewArrivals?: string, filterOnSale?: string, filterPrice?: string, filterMinprice?: number, filterMaxprice?: number, filterYearSeason?: string, filterStyleFilter?: string, filterBodyLengthFilter?: string, filterColorFilter?: string, filterMaterialFilter?: string, filterTypefilter?: string, filterSleeveLengthFilter?: string, filterOccasionFilter?: string, filterButtoningFilter?: string, filterComfortableFacet?: string, filterCoatSilhouetteFilter?: string, filterShapeFilter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterSku !== undefined) {
                localVarQueryParameter['filter[sku]'] = filterSku;
            }

            if (filterGetRelated !== undefined) {
                localVarQueryParameter['filter[get_related]'] = filterGetRelated;
            }

            if (filterPkConsumer !== undefined) {
                localVarQueryParameter['filter[pk_consumer]'] = filterPkConsumer;
            }

            if (filterBrand !== undefined) {
                localVarQueryParameter['filter[brand]'] = filterBrand;
            }

            if (filterCategoria !== undefined) {
                localVarQueryParameter['filter[categoria]'] = filterCategoria;
            }

            if (filterRighe !== undefined) {
                localVarQueryParameter['filter[righe]'] = filterRighe;
            }

            if (filterPagina !== undefined) {
                localVarQueryParameter['filter[pagina]'] = filterPagina;
            }

            if (filterModel !== undefined) {
                localVarQueryParameter['filter[model]'] = filterModel;
            }

            if (filterModelvariant !== undefined) {
                localVarQueryParameter['filter[modelvariant]'] = filterModelvariant;
            }

            if (filterSizes !== undefined) {
                localVarQueryParameter['filter[sizes]'] = filterSizes;
            }

            if (filterStock !== undefined) {
                localVarQueryParameter['filter[stock]'] = filterStock;
            }

            if (filterName !== undefined) {
                localVarQueryParameter['filter[name]'] = filterName;
            }

            if (filterEan !== undefined) {
                localVarQueryParameter['filter[ean]'] = filterEan;
            }

            if (filterLikeMode !== undefined) {
                localVarQueryParameter['filter[like_mode]'] = filterLikeMode;
            }

            if (filterRfidStore !== undefined) {
                localVarQueryParameter['filter[rfid_store]'] = filterRfidStore;
            }

            if (filterCodCategoria !== undefined) {
                localVarQueryParameter['filter[cod_categoria]'] = filterCodCategoria;
            }

            if (filterCodSottocategoria !== undefined) {
                localVarQueryParameter['filter[cod_sottocategoria]'] = filterCodSottocategoria;
            }

            if (filterCollection !== undefined) {
                localVarQueryParameter['filter[collection]'] = filterCollection;
            }

            if (filterMainline !== undefined) {
                localVarQueryParameter['filter[mainline]'] = filterMainline;
            }

            if (filterModelB2C !== undefined) {
                localVarQueryParameter['filter[modelB2C]'] = filterModelB2C;
            }

            if (filterNewArrivals !== undefined) {
                localVarQueryParameter['filter[newArrivals]'] = filterNewArrivals;
            }

            if (filterOnSale !== undefined) {
                localVarQueryParameter['filter[onSale]'] = filterOnSale;
            }

            if (filterPrice !== undefined) {
                localVarQueryParameter['filter[price]'] = filterPrice;
            }

            if (filterMinprice !== undefined) {
                localVarQueryParameter['filter[minprice]'] = filterMinprice;
            }

            if (filterMaxprice !== undefined) {
                localVarQueryParameter['filter[maxprice]'] = filterMaxprice;
            }

            if (filterYearSeason !== undefined) {
                localVarQueryParameter['filter[yearSeason]'] = filterYearSeason;
            }

            if (filterStyleFilter !== undefined) {
                localVarQueryParameter['filter[styleFilter]'] = filterStyleFilter;
            }

            if (filterBodyLengthFilter !== undefined) {
                localVarQueryParameter['filter[bodyLengthFilter]'] = filterBodyLengthFilter;
            }

            if (filterColorFilter !== undefined) {
                localVarQueryParameter['filter[colorFilter]'] = filterColorFilter;
            }

            if (filterMaterialFilter !== undefined) {
                localVarQueryParameter['filter[materialFilter]'] = filterMaterialFilter;
            }

            if (filterTypefilter !== undefined) {
                localVarQueryParameter['filter[typefilter]'] = filterTypefilter;
            }

            if (filterSleeveLengthFilter !== undefined) {
                localVarQueryParameter['filter[sleeveLengthFilter]'] = filterSleeveLengthFilter;
            }

            if (filterOccasionFilter !== undefined) {
                localVarQueryParameter['filter[occasionFilter]'] = filterOccasionFilter;
            }

            if (filterButtoningFilter !== undefined) {
                localVarQueryParameter['filter[buttoningFilter]'] = filterButtoningFilter;
            }

            if (filterComfortableFacet !== undefined) {
                localVarQueryParameter['filter[comfortableFacet]'] = filterComfortableFacet;
            }

            if (filterCoatSilhouetteFilter !== undefined) {
                localVarQueryParameter['filter[coatSilhouetteFilter]'] = filterCoatSilhouetteFilter;
            }

            if (filterShapeFilter !== undefined) {
                localVarQueryParameter['filter[shapeFilter]'] = filterShapeFilter;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets model data from catalog
         * @summary Search for model detail
         * @param {string} model Model to be elaborated
         * @param {string} [filterPkConsumer] pk_consumer to be elaborated
         * @param {string} [filterBrand] brand to be elaborated, comma separated values are admitted
         * @param {string} [filterCategoria] categoria to be elaborated
         * @param {number} [filterRighe] righe or number of record per page
         * @param {number} [filterPagina] pagina to be elaborated
         * @param {string} [filterModelvariant] modelvariant to be elaborated
         * @param {string} [filterSizes] sizes to be elaborated, | string composed by groups and values values are comma separated, groups are pipe separated
         * @param {string} [filterStock] 1 or 0 to get stock informations
         * @param {string} [filterName] name to be elaborated
         * @param {string} [filterEan] ean to be elaborated, comma separated values admitted
         * @param {0 | 1} [filterLikeMode] like_mode used in query instead of &#x3D;
         * @param {string} [filterRfidStore] product rfid
         * @param {string} [filterCodCategoria] product cod categoria
         * @param {string} [filterCodSottocategoria] product cod sottocategoria
         * @param {string} [filterCollection] filtri avanzati, collection
         * @param {string} [filterMainline] filtri avanzati, mainline
         * @param {string} [filterModelB2C] filtri avanzati, modelB2C
         * @param {string} [filterNewArrivals] filtri avanzati, newArrivals
         * @param {string} [filterOnSale] filtri avanzati, onSale
         * @param {string} [filterPrice] filtri avanzati, price
         * @param {number} [filterMinprice] filtri avanzati, minprice
         * @param {number} [filterMaxprice] filtri avanzati, maxprice
         * @param {string} [filterYearSeason] filtri avanzati, yearSeason
         * @param {string} [filterStyleFilter] filtri avanzati, styleFilter
         * @param {string} [filterBodyLengthFilter] filtri avanzati, bodyLengthFilter
         * @param {string} [filterColorFilter] filtri avanzati, colorFilter
         * @param {string} [filterMaterialFilter] filtri avanzati, materialFilter
         * @param {string} [filterTypefilter] filtri avanzati, typefilter
         * @param {string} [filterSleeveLengthFilter] filtri avanzati, sleeveLengthFilter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebModelsModelGet: async (model: string, filterPkConsumer?: string, filterBrand?: string, filterCategoria?: string, filterRighe?: number, filterPagina?: number, filterModelvariant?: string, filterSizes?: string, filterStock?: string, filterName?: string, filterEan?: string, filterLikeMode?: 0 | 1, filterRfidStore?: string, filterCodCategoria?: string, filterCodSottocategoria?: string, filterCollection?: string, filterMainline?: string, filterModelB2C?: string, filterNewArrivals?: string, filterOnSale?: string, filterPrice?: string, filterMinprice?: number, filterMaxprice?: number, filterYearSeason?: string, filterStyleFilter?: string, filterBodyLengthFilter?: string, filterColorFilter?: string, filterMaterialFilter?: string, filterTypefilter?: string, filterSleeveLengthFilter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'model' is not null or undefined
            assertParamExists('apiV1PoswebModelsModelGet', 'model', model)
            const localVarPath = `/api/v1/posweb/models/{model}/`
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterPkConsumer !== undefined) {
                localVarQueryParameter['filter[pk_consumer]'] = filterPkConsumer;
            }

            if (filterBrand !== undefined) {
                localVarQueryParameter['filter[brand]'] = filterBrand;
            }

            if (filterCategoria !== undefined) {
                localVarQueryParameter['filter[categoria]'] = filterCategoria;
            }

            if (filterRighe !== undefined) {
                localVarQueryParameter['filter[righe]'] = filterRighe;
            }

            if (filterPagina !== undefined) {
                localVarQueryParameter['filter[pagina]'] = filterPagina;
            }

            if (filterModelvariant !== undefined) {
                localVarQueryParameter['filter[modelvariant]'] = filterModelvariant;
            }

            if (filterSizes !== undefined) {
                localVarQueryParameter['filter[sizes]'] = filterSizes;
            }

            if (filterStock !== undefined) {
                localVarQueryParameter['filter[stock]'] = filterStock;
            }

            if (filterName !== undefined) {
                localVarQueryParameter['filter[name]'] = filterName;
            }

            if (filterEan !== undefined) {
                localVarQueryParameter['filter[ean]'] = filterEan;
            }

            if (filterLikeMode !== undefined) {
                localVarQueryParameter['filter[like_mode]'] = filterLikeMode;
            }

            if (filterRfidStore !== undefined) {
                localVarQueryParameter['filter[rfid_store]'] = filterRfidStore;
            }

            if (filterCodCategoria !== undefined) {
                localVarQueryParameter['filter[cod_categoria]'] = filterCodCategoria;
            }

            if (filterCodSottocategoria !== undefined) {
                localVarQueryParameter['filter[cod_sottocategoria]'] = filterCodSottocategoria;
            }

            if (filterCollection !== undefined) {
                localVarQueryParameter['filter[collection]'] = filterCollection;
            }

            if (filterMainline !== undefined) {
                localVarQueryParameter['filter[mainline]'] = filterMainline;
            }

            if (filterModelB2C !== undefined) {
                localVarQueryParameter['filter[modelB2C]'] = filterModelB2C;
            }

            if (filterNewArrivals !== undefined) {
                localVarQueryParameter['filter[newArrivals]'] = filterNewArrivals;
            }

            if (filterOnSale !== undefined) {
                localVarQueryParameter['filter[onSale]'] = filterOnSale;
            }

            if (filterPrice !== undefined) {
                localVarQueryParameter['filter[price]'] = filterPrice;
            }

            if (filterMinprice !== undefined) {
                localVarQueryParameter['filter[minprice]'] = filterMinprice;
            }

            if (filterMaxprice !== undefined) {
                localVarQueryParameter['filter[maxprice]'] = filterMaxprice;
            }

            if (filterYearSeason !== undefined) {
                localVarQueryParameter['filter[yearSeason]'] = filterYearSeason;
            }

            if (filterStyleFilter !== undefined) {
                localVarQueryParameter['filter[styleFilter]'] = filterStyleFilter;
            }

            if (filterBodyLengthFilter !== undefined) {
                localVarQueryParameter['filter[bodyLengthFilter]'] = filterBodyLengthFilter;
            }

            if (filterColorFilter !== undefined) {
                localVarQueryParameter['filter[colorFilter]'] = filterColorFilter;
            }

            if (filterMaterialFilter !== undefined) {
                localVarQueryParameter['filter[materialFilter]'] = filterMaterialFilter;
            }

            if (filterTypefilter !== undefined) {
                localVarQueryParameter['filter[typefilter]'] = filterTypefilter;
            }

            if (filterSleeveLengthFilter !== undefined) {
                localVarQueryParameter['filter[sleeveLengthFilter]'] = filterSleeveLengthFilter;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets sizes of models from catalog
         * @summary Search for sizes
         * @param {string} filterBrand brand to be elaborated
         * @param {string} filterCategoria category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebSizesGet: async (filterBrand: string, filterCategoria: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterBrand' is not null or undefined
            assertParamExists('apiV1PoswebSizesGet', 'filterBrand', filterBrand)
            // verify required parameter 'filterCategoria' is not null or undefined
            assertParamExists('apiV1PoswebSizesGet', 'filterCategoria', filterCategoria)
            const localVarPath = `/api/v1/posweb/sizes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterBrand !== undefined) {
                localVarQueryParameter['filter[brand]'] = filterBrand;
            }

            if (filterCategoria !== undefined) {
                localVarQueryParameter['filter[categoria]'] = filterCategoria;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Serve per suggerire altri capi all’utente a partire dal dettaglio del capo corrente
         * @summary Suggerimenti di altri capi
         * @param {string} [filterBrand] brand, comma separated values are admitted
         * @param {string} [filterSkus] sku of item sample, comma separated values are admitted
         * @param {string} [filterLimit] limit of record returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebYmalGet: async (filterBrand?: string, filterSkus?: string, filterLimit?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/ymal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterBrand !== undefined) {
                localVarQueryParameter['filter[brand]'] = filterBrand;
            }

            if (filterSkus !== undefined) {
                localVarQueryParameter['filter[skus]'] = filterSkus;
            }

            if (filterLimit !== undefined) {
                localVarQueryParameter['filter[limit]'] = filterLimit;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Restituisce la giacenza per l\'item indicato La risposta è diversa se si passa details 0 o 1: - filter[details]=0: in attributes le chiavi riportano la giacenza del settore - filter[details]=1: negli \"attributes\" della risposta le chiavi sono oggetti, le cui chiavi sono i codici negozio dove l\'item ha giacenza
         * @summary Informazioni sulla giacenza di un item
         * @param {string} filterSku sku dell\&#39;item, si possono indicare più sku separandoli con la virgola
         * @param {0 | 1} filterDetails fornire i dettagli per negozio nella risposta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StockGet: async (filterSku: string, filterDetails: 0 | 1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterSku' is not null or undefined
            assertParamExists('apiV1StockGet', 'filterSku', filterSku)
            // verify required parameter 'filterDetails' is not null or undefined
            assertParamExists('apiV1StockGet', 'filterDetails', filterDetails)
            const localVarPath = `/api/v1/stock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterSku !== undefined) {
                localVarQueryParameter['filter[sku]'] = filterSku;
            }

            if (filterDetails !== undefined) {
                localVarQueryParameter['filter[details]'] = filterDetails;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EndlessAisleApi - functional programming interface
 * @export
 */
export const EndlessAisleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EndlessAisleApiAxiosParamCreator(configuration)
    return {
        /**
         * Ottiene la lista dei prodotti indicati come desiderati della consumer
         * @summary Ottiene la lista dei desiderata della consumer
         * @param {string} pkConsumer Consumer ID
         * @param {string} [brand] brand
         * @param {number} [skuLimit] limite al numero di  sku restituiti per ogni wishlist
         * @param {0 | 1} [details] aggiungere o meno dettagli alla risposta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerWishlistsGet(pkConsumer: string, brand?: string, skuLimit?: number, details?: 0 | 1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsumerWishlist>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerWishlistsGet(pkConsumer, brand, skuLimit, details, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets filters and his values
         * @summary Search for advanced filters
         * @param {string} [filterBrand] brand to be filtered
         * @param {string} [filterCategory] category to be filtered
         * @param {'collection' | 'mainline' | 'modelB2C' | 'newArrivals' | 'onSale' | 'price' | 'sizes' | 'stock' | 'yearSeason' | 'occasionFilter' | 'buttoningFilter' | 'comfortableFacet' | 'coatSilhouetteFilter' | 'shapeFilter' | 'bodyLengthFilter' | 'colorFilter' | 'materialFilter' | 'sleeveLengthFilter' | 'styleFilter' | 'typefilter'} [filterCode] code of the filter
         * @param {'Collezione' | 'Main Line' | 'Con Foto' | 'Nuovi Arrivi' | 'In Saldo' | 'Prezzo' | 'Taglie' | 'In giacenza' | 'Anno stagione'} [filterDesc] description of the filter, coupled with code, as price-Prezzo. Use as alternative of code
         * @param {string} [filterVal] value for the specified code or desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebAdvancedFiltersGet(filterBrand?: string, filterCategory?: string, filterCode?: 'collection' | 'mainline' | 'modelB2C' | 'newArrivals' | 'onSale' | 'price' | 'sizes' | 'stock' | 'yearSeason' | 'occasionFilter' | 'buttoningFilter' | 'comfortableFacet' | 'coatSilhouetteFilter' | 'shapeFilter' | 'bodyLengthFilter' | 'colorFilter' | 'materialFilter' | 'sleeveLengthFilter' | 'styleFilter' | 'typefilter', filterDesc?: 'Collezione' | 'Main Line' | 'Con Foto' | 'Nuovi Arrivi' | 'In Saldo' | 'Prezzo' | 'Taglie' | 'In giacenza' | 'Anno stagione', filterVal?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAdvancedFilters>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebAdvancedFiltersGet(filterBrand, filterCategory, filterCode, filterDesc, filterVal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets categories of models by brand
         * @summary Get list of categories
         * @param {string} filterBrand brand to be elaborated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebCategoriesGet(filterBrand: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBrandCategories>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebCategoriesGet(filterBrand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets models from catalog
         * @summary Search for models
         * @param {string} [filterSku] SKU to be elaborated
         * @param {string} [filterGetRelated] when equal to 1 all related models will be returned if sku exists
         * @param {string} [filterPkConsumer] pk_consumer to be elaborated
         * @param {string} [filterBrand] brand to be elaborated, comma separated values are admitted
         * @param {string} [filterCategoria] categoria to be elaborated
         * @param {number} [filterRighe] righe or number of record per page
         * @param {number} [filterPagina] pagina to be elaborated
         * @param {string} [filterModel] model to be elaborated
         * @param {string} [filterModelvariant] modelvariant to be elaborated
         * @param {string} [filterSizes] sizes to be elaborated, | string composed by groups and values values are comma separated, groups are pipe separated
         * @param {0 | 1} [filterStock] 1 or 0 to get stock informations
         * @param {string} [filterName] model name to be elaborated
         * @param {string} [filterEan] ean to be elaborated, comma separated values admitted
         * @param {0 | 1} [filterLikeMode] like_mode used in query instead of &#x3D;
         * @param {string} [filterRfidStore] product rfid
         * @param {string} [filterCodCategoria] product cod categoria
         * @param {string} [filterCodSottocategoria] product cod sottocategoria
         * @param {string} [filterCollection] filtri avanzati, collection
         * @param {string} [filterMainline] filtri avanzati, mainline
         * @param {string} [filterModelB2C] filtri avanzati, modelB2C
         * @param {string} [filterNewArrivals] filtri avanzati, newArrivals
         * @param {string} [filterOnSale] filtri avanzati, onSale
         * @param {string} [filterPrice] filtri avanzati, price
         * @param {number} [filterMinprice] filtri avanzati, minprice
         * @param {number} [filterMaxprice] filtri avanzati, maxprice
         * @param {string} [filterYearSeason] filtri avanzati, yearSeason
         * @param {string} [filterStyleFilter] filtri avanzati, styleFilter
         * @param {string} [filterBodyLengthFilter] filtri avanzati, bodyLengthFilter
         * @param {string} [filterColorFilter] filtri avanzati, colorFilter
         * @param {string} [filterMaterialFilter] filtri avanzati, materialFilter
         * @param {string} [filterTypefilter] filtri avanzati, typefilter
         * @param {string} [filterSleeveLengthFilter] filtri avanzati, sleeveLengthFilter
         * @param {string} [filterOccasionFilter] filtri avanzati, occasionFilter
         * @param {string} [filterButtoningFilter] filtri avanzati, buttoningFilter
         * @param {string} [filterComfortableFacet] filtri avanzati, comfortableFacet
         * @param {string} [filterCoatSilhouetteFilter] filtri avanzati, coatSilhouetteFilter
         * @param {string} [filterShapeFilter] filtri avanzati, shapeFilter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebModelsGet(filterSku?: string, filterGetRelated?: string, filterPkConsumer?: string, filterBrand?: string, filterCategoria?: string, filterRighe?: number, filterPagina?: number, filterModel?: string, filterModelvariant?: string, filterSizes?: string, filterStock?: 0 | 1, filterName?: string, filterEan?: string, filterLikeMode?: 0 | 1, filterRfidStore?: string, filterCodCategoria?: string, filterCodSottocategoria?: string, filterCollection?: string, filterMainline?: string, filterModelB2C?: string, filterNewArrivals?: string, filterOnSale?: string, filterPrice?: string, filterMinprice?: number, filterMaxprice?: number, filterYearSeason?: string, filterStyleFilter?: string, filterBodyLengthFilter?: string, filterColorFilter?: string, filterMaterialFilter?: string, filterTypefilter?: string, filterSleeveLengthFilter?: string, filterOccasionFilter?: string, filterButtoningFilter?: string, filterComfortableFacet?: string, filterCoatSilhouetteFilter?: string, filterShapeFilter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetModels>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebModelsGet(filterSku, filterGetRelated, filterPkConsumer, filterBrand, filterCategoria, filterRighe, filterPagina, filterModel, filterModelvariant, filterSizes, filterStock, filterName, filterEan, filterLikeMode, filterRfidStore, filterCodCategoria, filterCodSottocategoria, filterCollection, filterMainline, filterModelB2C, filterNewArrivals, filterOnSale, filterPrice, filterMinprice, filterMaxprice, filterYearSeason, filterStyleFilter, filterBodyLengthFilter, filterColorFilter, filterMaterialFilter, filterTypefilter, filterSleeveLengthFilter, filterOccasionFilter, filterButtoningFilter, filterComfortableFacet, filterCoatSilhouetteFilter, filterShapeFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets model data from catalog
         * @summary Search for model detail
         * @param {string} model Model to be elaborated
         * @param {string} [filterPkConsumer] pk_consumer to be elaborated
         * @param {string} [filterBrand] brand to be elaborated, comma separated values are admitted
         * @param {string} [filterCategoria] categoria to be elaborated
         * @param {number} [filterRighe] righe or number of record per page
         * @param {number} [filterPagina] pagina to be elaborated
         * @param {string} [filterModelvariant] modelvariant to be elaborated
         * @param {string} [filterSizes] sizes to be elaborated, | string composed by groups and values values are comma separated, groups are pipe separated
         * @param {string} [filterStock] 1 or 0 to get stock informations
         * @param {string} [filterName] name to be elaborated
         * @param {string} [filterEan] ean to be elaborated, comma separated values admitted
         * @param {0 | 1} [filterLikeMode] like_mode used in query instead of &#x3D;
         * @param {string} [filterRfidStore] product rfid
         * @param {string} [filterCodCategoria] product cod categoria
         * @param {string} [filterCodSottocategoria] product cod sottocategoria
         * @param {string} [filterCollection] filtri avanzati, collection
         * @param {string} [filterMainline] filtri avanzati, mainline
         * @param {string} [filterModelB2C] filtri avanzati, modelB2C
         * @param {string} [filterNewArrivals] filtri avanzati, newArrivals
         * @param {string} [filterOnSale] filtri avanzati, onSale
         * @param {string} [filterPrice] filtri avanzati, price
         * @param {number} [filterMinprice] filtri avanzati, minprice
         * @param {number} [filterMaxprice] filtri avanzati, maxprice
         * @param {string} [filterYearSeason] filtri avanzati, yearSeason
         * @param {string} [filterStyleFilter] filtri avanzati, styleFilter
         * @param {string} [filterBodyLengthFilter] filtri avanzati, bodyLengthFilter
         * @param {string} [filterColorFilter] filtri avanzati, colorFilter
         * @param {string} [filterMaterialFilter] filtri avanzati, materialFilter
         * @param {string} [filterTypefilter] filtri avanzati, typefilter
         * @param {string} [filterSleeveLengthFilter] filtri avanzati, sleeveLengthFilter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebModelsModelGet(model: string, filterPkConsumer?: string, filterBrand?: string, filterCategoria?: string, filterRighe?: number, filterPagina?: number, filterModelvariant?: string, filterSizes?: string, filterStock?: string, filterName?: string, filterEan?: string, filterLikeMode?: 0 | 1, filterRfidStore?: string, filterCodCategoria?: string, filterCodSottocategoria?: string, filterCollection?: string, filterMainline?: string, filterModelB2C?: string, filterNewArrivals?: string, filterOnSale?: string, filterPrice?: string, filterMinprice?: number, filterMaxprice?: number, filterYearSeason?: string, filterStyleFilter?: string, filterBodyLengthFilter?: string, filterColorFilter?: string, filterMaterialFilter?: string, filterTypefilter?: string, filterSleeveLengthFilter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetModelData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebModelsModelGet(model, filterPkConsumer, filterBrand, filterCategoria, filterRighe, filterPagina, filterModelvariant, filterSizes, filterStock, filterName, filterEan, filterLikeMode, filterRfidStore, filterCodCategoria, filterCodSottocategoria, filterCollection, filterMainline, filterModelB2C, filterNewArrivals, filterOnSale, filterPrice, filterMinprice, filterMaxprice, filterYearSeason, filterStyleFilter, filterBodyLengthFilter, filterColorFilter, filterMaterialFilter, filterTypefilter, filterSleeveLengthFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets sizes of models from catalog
         * @summary Search for sizes
         * @param {string} filterBrand brand to be elaborated
         * @param {string} filterCategoria category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebSizesGet(filterBrand: string, filterCategoria: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetModelsSizes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebSizesGet(filterBrand, filterCategoria, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Serve per suggerire altri capi all’utente a partire dal dettaglio del capo corrente
         * @summary Suggerimenti di altri capi
         * @param {string} [filterBrand] brand, comma separated values are admitted
         * @param {string} [filterSkus] sku of item sample, comma separated values are admitted
         * @param {string} [filterLimit] limit of record returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebYmalGet(filterBrand?: string, filterSkus?: string, filterLimit?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetYmalData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebYmalGet(filterBrand, filterSkus, filterLimit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Restituisce la giacenza per l\'item indicato La risposta è diversa se si passa details 0 o 1: - filter[details]=0: in attributes le chiavi riportano la giacenza del settore - filter[details]=1: negli \"attributes\" della risposta le chiavi sono oggetti, le cui chiavi sono i codici negozio dove l\'item ha giacenza
         * @summary Informazioni sulla giacenza di un item
         * @param {string} filterSku sku dell\&#39;item, si possono indicare più sku separandoli con la virgola
         * @param {0 | 1} filterDetails fornire i dettagli per negozio nella risposta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StockGet(filterSku: string, filterDetails: 0 | 1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStockItemData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StockGet(filterSku, filterDetails, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EndlessAisleApi - factory interface
 * @export
 */
export const EndlessAisleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EndlessAisleApiFp(configuration)
    return {
        /**
         * Ottiene la lista dei prodotti indicati come desiderati della consumer
         * @summary Ottiene la lista dei desiderata della consumer
         * @param {string} pkConsumer Consumer ID
         * @param {string} [brand] brand
         * @param {number} [skuLimit] limite al numero di  sku restituiti per ogni wishlist
         * @param {0 | 1} [details] aggiungere o meno dettagli alla risposta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerWishlistsGet(pkConsumer: string, brand?: string, skuLimit?: number, details?: 0 | 1, options?: any): AxiosPromise<ConsumerWishlist> {
            return localVarFp.apiV1ConsumersPkConsumerWishlistsGet(pkConsumer, brand, skuLimit, details, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets filters and his values
         * @summary Search for advanced filters
         * @param {string} [filterBrand] brand to be filtered
         * @param {string} [filterCategory] category to be filtered
         * @param {'collection' | 'mainline' | 'modelB2C' | 'newArrivals' | 'onSale' | 'price' | 'sizes' | 'stock' | 'yearSeason' | 'occasionFilter' | 'buttoningFilter' | 'comfortableFacet' | 'coatSilhouetteFilter' | 'shapeFilter' | 'bodyLengthFilter' | 'colorFilter' | 'materialFilter' | 'sleeveLengthFilter' | 'styleFilter' | 'typefilter'} [filterCode] code of the filter
         * @param {'Collezione' | 'Main Line' | 'Con Foto' | 'Nuovi Arrivi' | 'In Saldo' | 'Prezzo' | 'Taglie' | 'In giacenza' | 'Anno stagione'} [filterDesc] description of the filter, coupled with code, as price-Prezzo. Use as alternative of code
         * @param {string} [filterVal] value for the specified code or desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebAdvancedFiltersGet(filterBrand?: string, filterCategory?: string, filterCode?: 'collection' | 'mainline' | 'modelB2C' | 'newArrivals' | 'onSale' | 'price' | 'sizes' | 'stock' | 'yearSeason' | 'occasionFilter' | 'buttoningFilter' | 'comfortableFacet' | 'coatSilhouetteFilter' | 'shapeFilter' | 'bodyLengthFilter' | 'colorFilter' | 'materialFilter' | 'sleeveLengthFilter' | 'styleFilter' | 'typefilter', filterDesc?: 'Collezione' | 'Main Line' | 'Con Foto' | 'Nuovi Arrivi' | 'In Saldo' | 'Prezzo' | 'Taglie' | 'In giacenza' | 'Anno stagione', filterVal?: string, options?: any): AxiosPromise<GetAdvancedFilters> {
            return localVarFp.apiV1PoswebAdvancedFiltersGet(filterBrand, filterCategory, filterCode, filterDesc, filterVal, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets categories of models by brand
         * @summary Get list of categories
         * @param {string} filterBrand brand to be elaborated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebCategoriesGet(filterBrand: string, options?: any): AxiosPromise<GetBrandCategories> {
            return localVarFp.apiV1PoswebCategoriesGet(filterBrand, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets models from catalog
         * @summary Search for models
         * @param {string} [filterSku] SKU to be elaborated
         * @param {string} [filterGetRelated] when equal to 1 all related models will be returned if sku exists
         * @param {string} [filterPkConsumer] pk_consumer to be elaborated
         * @param {string} [filterBrand] brand to be elaborated, comma separated values are admitted
         * @param {string} [filterCategoria] categoria to be elaborated
         * @param {number} [filterRighe] righe or number of record per page
         * @param {number} [filterPagina] pagina to be elaborated
         * @param {string} [filterModel] model to be elaborated
         * @param {string} [filterModelvariant] modelvariant to be elaborated
         * @param {string} [filterSizes] sizes to be elaborated, | string composed by groups and values values are comma separated, groups are pipe separated
         * @param {0 | 1} [filterStock] 1 or 0 to get stock informations
         * @param {string} [filterName] model name to be elaborated
         * @param {string} [filterEan] ean to be elaborated, comma separated values admitted
         * @param {0 | 1} [filterLikeMode] like_mode used in query instead of &#x3D;
         * @param {string} [filterRfidStore] product rfid
         * @param {string} [filterCodCategoria] product cod categoria
         * @param {string} [filterCodSottocategoria] product cod sottocategoria
         * @param {string} [filterCollection] filtri avanzati, collection
         * @param {string} [filterMainline] filtri avanzati, mainline
         * @param {string} [filterModelB2C] filtri avanzati, modelB2C
         * @param {string} [filterNewArrivals] filtri avanzati, newArrivals
         * @param {string} [filterOnSale] filtri avanzati, onSale
         * @param {string} [filterPrice] filtri avanzati, price
         * @param {number} [filterMinprice] filtri avanzati, minprice
         * @param {number} [filterMaxprice] filtri avanzati, maxprice
         * @param {string} [filterYearSeason] filtri avanzati, yearSeason
         * @param {string} [filterStyleFilter] filtri avanzati, styleFilter
         * @param {string} [filterBodyLengthFilter] filtri avanzati, bodyLengthFilter
         * @param {string} [filterColorFilter] filtri avanzati, colorFilter
         * @param {string} [filterMaterialFilter] filtri avanzati, materialFilter
         * @param {string} [filterTypefilter] filtri avanzati, typefilter
         * @param {string} [filterSleeveLengthFilter] filtri avanzati, sleeveLengthFilter
         * @param {string} [filterOccasionFilter] filtri avanzati, occasionFilter
         * @param {string} [filterButtoningFilter] filtri avanzati, buttoningFilter
         * @param {string} [filterComfortableFacet] filtri avanzati, comfortableFacet
         * @param {string} [filterCoatSilhouetteFilter] filtri avanzati, coatSilhouetteFilter
         * @param {string} [filterShapeFilter] filtri avanzati, shapeFilter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebModelsGet(filterSku?: string, filterGetRelated?: string, filterPkConsumer?: string, filterBrand?: string, filterCategoria?: string, filterRighe?: number, filterPagina?: number, filterModel?: string, filterModelvariant?: string, filterSizes?: string, filterStock?: 0 | 1, filterName?: string, filterEan?: string, filterLikeMode?: 0 | 1, filterRfidStore?: string, filterCodCategoria?: string, filterCodSottocategoria?: string, filterCollection?: string, filterMainline?: string, filterModelB2C?: string, filterNewArrivals?: string, filterOnSale?: string, filterPrice?: string, filterMinprice?: number, filterMaxprice?: number, filterYearSeason?: string, filterStyleFilter?: string, filterBodyLengthFilter?: string, filterColorFilter?: string, filterMaterialFilter?: string, filterTypefilter?: string, filterSleeveLengthFilter?: string, filterOccasionFilter?: string, filterButtoningFilter?: string, filterComfortableFacet?: string, filterCoatSilhouetteFilter?: string, filterShapeFilter?: string, options?: any): AxiosPromise<GetModels> {
            return localVarFp.apiV1PoswebModelsGet(filterSku, filterGetRelated, filterPkConsumer, filterBrand, filterCategoria, filterRighe, filterPagina, filterModel, filterModelvariant, filterSizes, filterStock, filterName, filterEan, filterLikeMode, filterRfidStore, filterCodCategoria, filterCodSottocategoria, filterCollection, filterMainline, filterModelB2C, filterNewArrivals, filterOnSale, filterPrice, filterMinprice, filterMaxprice, filterYearSeason, filterStyleFilter, filterBodyLengthFilter, filterColorFilter, filterMaterialFilter, filterTypefilter, filterSleeveLengthFilter, filterOccasionFilter, filterButtoningFilter, filterComfortableFacet, filterCoatSilhouetteFilter, filterShapeFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets model data from catalog
         * @summary Search for model detail
         * @param {string} model Model to be elaborated
         * @param {string} [filterPkConsumer] pk_consumer to be elaborated
         * @param {string} [filterBrand] brand to be elaborated, comma separated values are admitted
         * @param {string} [filterCategoria] categoria to be elaborated
         * @param {number} [filterRighe] righe or number of record per page
         * @param {number} [filterPagina] pagina to be elaborated
         * @param {string} [filterModelvariant] modelvariant to be elaborated
         * @param {string} [filterSizes] sizes to be elaborated, | string composed by groups and values values are comma separated, groups are pipe separated
         * @param {string} [filterStock] 1 or 0 to get stock informations
         * @param {string} [filterName] name to be elaborated
         * @param {string} [filterEan] ean to be elaborated, comma separated values admitted
         * @param {0 | 1} [filterLikeMode] like_mode used in query instead of &#x3D;
         * @param {string} [filterRfidStore] product rfid
         * @param {string} [filterCodCategoria] product cod categoria
         * @param {string} [filterCodSottocategoria] product cod sottocategoria
         * @param {string} [filterCollection] filtri avanzati, collection
         * @param {string} [filterMainline] filtri avanzati, mainline
         * @param {string} [filterModelB2C] filtri avanzati, modelB2C
         * @param {string} [filterNewArrivals] filtri avanzati, newArrivals
         * @param {string} [filterOnSale] filtri avanzati, onSale
         * @param {string} [filterPrice] filtri avanzati, price
         * @param {number} [filterMinprice] filtri avanzati, minprice
         * @param {number} [filterMaxprice] filtri avanzati, maxprice
         * @param {string} [filterYearSeason] filtri avanzati, yearSeason
         * @param {string} [filterStyleFilter] filtri avanzati, styleFilter
         * @param {string} [filterBodyLengthFilter] filtri avanzati, bodyLengthFilter
         * @param {string} [filterColorFilter] filtri avanzati, colorFilter
         * @param {string} [filterMaterialFilter] filtri avanzati, materialFilter
         * @param {string} [filterTypefilter] filtri avanzati, typefilter
         * @param {string} [filterSleeveLengthFilter] filtri avanzati, sleeveLengthFilter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebModelsModelGet(model: string, filterPkConsumer?: string, filterBrand?: string, filterCategoria?: string, filterRighe?: number, filterPagina?: number, filterModelvariant?: string, filterSizes?: string, filterStock?: string, filterName?: string, filterEan?: string, filterLikeMode?: 0 | 1, filterRfidStore?: string, filterCodCategoria?: string, filterCodSottocategoria?: string, filterCollection?: string, filterMainline?: string, filterModelB2C?: string, filterNewArrivals?: string, filterOnSale?: string, filterPrice?: string, filterMinprice?: number, filterMaxprice?: number, filterYearSeason?: string, filterStyleFilter?: string, filterBodyLengthFilter?: string, filterColorFilter?: string, filterMaterialFilter?: string, filterTypefilter?: string, filterSleeveLengthFilter?: string, options?: any): AxiosPromise<GetModelData> {
            return localVarFp.apiV1PoswebModelsModelGet(model, filterPkConsumer, filterBrand, filterCategoria, filterRighe, filterPagina, filterModelvariant, filterSizes, filterStock, filterName, filterEan, filterLikeMode, filterRfidStore, filterCodCategoria, filterCodSottocategoria, filterCollection, filterMainline, filterModelB2C, filterNewArrivals, filterOnSale, filterPrice, filterMinprice, filterMaxprice, filterYearSeason, filterStyleFilter, filterBodyLengthFilter, filterColorFilter, filterMaterialFilter, filterTypefilter, filterSleeveLengthFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets sizes of models from catalog
         * @summary Search for sizes
         * @param {string} filterBrand brand to be elaborated
         * @param {string} filterCategoria category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebSizesGet(filterBrand: string, filterCategoria: string, options?: any): AxiosPromise<GetModelsSizes> {
            return localVarFp.apiV1PoswebSizesGet(filterBrand, filterCategoria, options).then((request) => request(axios, basePath));
        },
        /**
         * Serve per suggerire altri capi all’utente a partire dal dettaglio del capo corrente
         * @summary Suggerimenti di altri capi
         * @param {string} [filterBrand] brand, comma separated values are admitted
         * @param {string} [filterSkus] sku of item sample, comma separated values are admitted
         * @param {string} [filterLimit] limit of record returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebYmalGet(filterBrand?: string, filterSkus?: string, filterLimit?: string, options?: any): AxiosPromise<GetYmalData> {
            return localVarFp.apiV1PoswebYmalGet(filterBrand, filterSkus, filterLimit, options).then((request) => request(axios, basePath));
        },
        /**
         * Restituisce la giacenza per l\'item indicato La risposta è diversa se si passa details 0 o 1: - filter[details]=0: in attributes le chiavi riportano la giacenza del settore - filter[details]=1: negli \"attributes\" della risposta le chiavi sono oggetti, le cui chiavi sono i codici negozio dove l\'item ha giacenza
         * @summary Informazioni sulla giacenza di un item
         * @param {string} filterSku sku dell\&#39;item, si possono indicare più sku separandoli con la virgola
         * @param {0 | 1} filterDetails fornire i dettagli per negozio nella risposta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StockGet(filterSku: string, filterDetails: 0 | 1, options?: any): AxiosPromise<GetStockItemData> {
            return localVarFp.apiV1StockGet(filterSku, filterDetails, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EndlessAisleApi - object-oriented interface
 * @export
 * @class EndlessAisleApi
 * @extends {BaseAPI}
 */
export class EndlessAisleApi extends BaseAPI {
    /**
     * Ottiene la lista dei prodotti indicati come desiderati della consumer
     * @summary Ottiene la lista dei desiderata della consumer
     * @param {string} pkConsumer Consumer ID
     * @param {string} [brand] brand
     * @param {number} [skuLimit] limite al numero di  sku restituiti per ogni wishlist
     * @param {0 | 1} [details] aggiungere o meno dettagli alla risposta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EndlessAisleApi
     */
    public apiV1ConsumersPkConsumerWishlistsGet(pkConsumer: string, brand?: string, skuLimit?: number, details?: 0 | 1, options?: AxiosRequestConfig) {
        return EndlessAisleApiFp(this.configuration).apiV1ConsumersPkConsumerWishlistsGet(pkConsumer, brand, skuLimit, details, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets filters and his values
     * @summary Search for advanced filters
     * @param {string} [filterBrand] brand to be filtered
     * @param {string} [filterCategory] category to be filtered
     * @param {'collection' | 'mainline' | 'modelB2C' | 'newArrivals' | 'onSale' | 'price' | 'sizes' | 'stock' | 'yearSeason' | 'occasionFilter' | 'buttoningFilter' | 'comfortableFacet' | 'coatSilhouetteFilter' | 'shapeFilter' | 'bodyLengthFilter' | 'colorFilter' | 'materialFilter' | 'sleeveLengthFilter' | 'styleFilter' | 'typefilter'} [filterCode] code of the filter
     * @param {'Collezione' | 'Main Line' | 'Con Foto' | 'Nuovi Arrivi' | 'In Saldo' | 'Prezzo' | 'Taglie' | 'In giacenza' | 'Anno stagione'} [filterDesc] description of the filter, coupled with code, as price-Prezzo. Use as alternative of code
     * @param {string} [filterVal] value for the specified code or desc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EndlessAisleApi
     */
    public apiV1PoswebAdvancedFiltersGet(filterBrand?: string, filterCategory?: string, filterCode?: 'collection' | 'mainline' | 'modelB2C' | 'newArrivals' | 'onSale' | 'price' | 'sizes' | 'stock' | 'yearSeason' | 'occasionFilter' | 'buttoningFilter' | 'comfortableFacet' | 'coatSilhouetteFilter' | 'shapeFilter' | 'bodyLengthFilter' | 'colorFilter' | 'materialFilter' | 'sleeveLengthFilter' | 'styleFilter' | 'typefilter', filterDesc?: 'Collezione' | 'Main Line' | 'Con Foto' | 'Nuovi Arrivi' | 'In Saldo' | 'Prezzo' | 'Taglie' | 'In giacenza' | 'Anno stagione', filterVal?: string, options?: AxiosRequestConfig) {
        return EndlessAisleApiFp(this.configuration).apiV1PoswebAdvancedFiltersGet(filterBrand, filterCategory, filterCode, filterDesc, filterVal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets categories of models by brand
     * @summary Get list of categories
     * @param {string} filterBrand brand to be elaborated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EndlessAisleApi
     */
    public apiV1PoswebCategoriesGet(filterBrand: string, options?: AxiosRequestConfig) {
        return EndlessAisleApiFp(this.configuration).apiV1PoswebCategoriesGet(filterBrand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets models from catalog
     * @summary Search for models
     * @param {string} [filterSku] SKU to be elaborated
     * @param {string} [filterGetRelated] when equal to 1 all related models will be returned if sku exists
     * @param {string} [filterPkConsumer] pk_consumer to be elaborated
     * @param {string} [filterBrand] brand to be elaborated, comma separated values are admitted
     * @param {string} [filterCategoria] categoria to be elaborated
     * @param {number} [filterRighe] righe or number of record per page
     * @param {number} [filterPagina] pagina to be elaborated
     * @param {string} [filterModel] model to be elaborated
     * @param {string} [filterModelvariant] modelvariant to be elaborated
     * @param {string} [filterSizes] sizes to be elaborated, | string composed by groups and values values are comma separated, groups are pipe separated
     * @param {0 | 1} [filterStock] 1 or 0 to get stock informations
     * @param {string} [filterName] model name to be elaborated
     * @param {string} [filterEan] ean to be elaborated, comma separated values admitted
     * @param {0 | 1} [filterLikeMode] like_mode used in query instead of &#x3D;
     * @param {string} [filterRfidStore] product rfid
     * @param {string} [filterCodCategoria] product cod categoria
     * @param {string} [filterCodSottocategoria] product cod sottocategoria
     * @param {string} [filterCollection] filtri avanzati, collection
     * @param {string} [filterMainline] filtri avanzati, mainline
     * @param {string} [filterModelB2C] filtri avanzati, modelB2C
     * @param {string} [filterNewArrivals] filtri avanzati, newArrivals
     * @param {string} [filterOnSale] filtri avanzati, onSale
     * @param {string} [filterPrice] filtri avanzati, price
     * @param {number} [filterMinprice] filtri avanzati, minprice
     * @param {number} [filterMaxprice] filtri avanzati, maxprice
     * @param {string} [filterYearSeason] filtri avanzati, yearSeason
     * @param {string} [filterStyleFilter] filtri avanzati, styleFilter
     * @param {string} [filterBodyLengthFilter] filtri avanzati, bodyLengthFilter
     * @param {string} [filterColorFilter] filtri avanzati, colorFilter
     * @param {string} [filterMaterialFilter] filtri avanzati, materialFilter
     * @param {string} [filterTypefilter] filtri avanzati, typefilter
     * @param {string} [filterSleeveLengthFilter] filtri avanzati, sleeveLengthFilter
     * @param {string} [filterOccasionFilter] filtri avanzati, occasionFilter
     * @param {string} [filterButtoningFilter] filtri avanzati, buttoningFilter
     * @param {string} [filterComfortableFacet] filtri avanzati, comfortableFacet
     * @param {string} [filterCoatSilhouetteFilter] filtri avanzati, coatSilhouetteFilter
     * @param {string} [filterShapeFilter] filtri avanzati, shapeFilter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EndlessAisleApi
     */
    public apiV1PoswebModelsGet(filterSku?: string, filterGetRelated?: string, filterPkConsumer?: string, filterBrand?: string, filterCategoria?: string, filterRighe?: number, filterPagina?: number, filterModel?: string, filterModelvariant?: string, filterSizes?: string, filterStock?: 0 | 1, filterName?: string, filterEan?: string, filterLikeMode?: 0 | 1, filterRfidStore?: string, filterCodCategoria?: string, filterCodSottocategoria?: string, filterCollection?: string, filterMainline?: string, filterModelB2C?: string, filterNewArrivals?: string, filterOnSale?: string, filterPrice?: string, filterMinprice?: number, filterMaxprice?: number, filterYearSeason?: string, filterStyleFilter?: string, filterBodyLengthFilter?: string, filterColorFilter?: string, filterMaterialFilter?: string, filterTypefilter?: string, filterSleeveLengthFilter?: string, filterOccasionFilter?: string, filterButtoningFilter?: string, filterComfortableFacet?: string, filterCoatSilhouetteFilter?: string, filterShapeFilter?: string, options?: AxiosRequestConfig) {
        return EndlessAisleApiFp(this.configuration).apiV1PoswebModelsGet(filterSku, filterGetRelated, filterPkConsumer, filterBrand, filterCategoria, filterRighe, filterPagina, filterModel, filterModelvariant, filterSizes, filterStock, filterName, filterEan, filterLikeMode, filterRfidStore, filterCodCategoria, filterCodSottocategoria, filterCollection, filterMainline, filterModelB2C, filterNewArrivals, filterOnSale, filterPrice, filterMinprice, filterMaxprice, filterYearSeason, filterStyleFilter, filterBodyLengthFilter, filterColorFilter, filterMaterialFilter, filterTypefilter, filterSleeveLengthFilter, filterOccasionFilter, filterButtoningFilter, filterComfortableFacet, filterCoatSilhouetteFilter, filterShapeFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets model data from catalog
     * @summary Search for model detail
     * @param {string} model Model to be elaborated
     * @param {string} [filterPkConsumer] pk_consumer to be elaborated
     * @param {string} [filterBrand] brand to be elaborated, comma separated values are admitted
     * @param {string} [filterCategoria] categoria to be elaborated
     * @param {number} [filterRighe] righe or number of record per page
     * @param {number} [filterPagina] pagina to be elaborated
     * @param {string} [filterModelvariant] modelvariant to be elaborated
     * @param {string} [filterSizes] sizes to be elaborated, | string composed by groups and values values are comma separated, groups are pipe separated
     * @param {string} [filterStock] 1 or 0 to get stock informations
     * @param {string} [filterName] name to be elaborated
     * @param {string} [filterEan] ean to be elaborated, comma separated values admitted
     * @param {0 | 1} [filterLikeMode] like_mode used in query instead of &#x3D;
     * @param {string} [filterRfidStore] product rfid
     * @param {string} [filterCodCategoria] product cod categoria
     * @param {string} [filterCodSottocategoria] product cod sottocategoria
     * @param {string} [filterCollection] filtri avanzati, collection
     * @param {string} [filterMainline] filtri avanzati, mainline
     * @param {string} [filterModelB2C] filtri avanzati, modelB2C
     * @param {string} [filterNewArrivals] filtri avanzati, newArrivals
     * @param {string} [filterOnSale] filtri avanzati, onSale
     * @param {string} [filterPrice] filtri avanzati, price
     * @param {number} [filterMinprice] filtri avanzati, minprice
     * @param {number} [filterMaxprice] filtri avanzati, maxprice
     * @param {string} [filterYearSeason] filtri avanzati, yearSeason
     * @param {string} [filterStyleFilter] filtri avanzati, styleFilter
     * @param {string} [filterBodyLengthFilter] filtri avanzati, bodyLengthFilter
     * @param {string} [filterColorFilter] filtri avanzati, colorFilter
     * @param {string} [filterMaterialFilter] filtri avanzati, materialFilter
     * @param {string} [filterTypefilter] filtri avanzati, typefilter
     * @param {string} [filterSleeveLengthFilter] filtri avanzati, sleeveLengthFilter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EndlessAisleApi
     */
    public apiV1PoswebModelsModelGet(model: string, filterPkConsumer?: string, filterBrand?: string, filterCategoria?: string, filterRighe?: number, filterPagina?: number, filterModelvariant?: string, filterSizes?: string, filterStock?: string, filterName?: string, filterEan?: string, filterLikeMode?: 0 | 1, filterRfidStore?: string, filterCodCategoria?: string, filterCodSottocategoria?: string, filterCollection?: string, filterMainline?: string, filterModelB2C?: string, filterNewArrivals?: string, filterOnSale?: string, filterPrice?: string, filterMinprice?: number, filterMaxprice?: number, filterYearSeason?: string, filterStyleFilter?: string, filterBodyLengthFilter?: string, filterColorFilter?: string, filterMaterialFilter?: string, filterTypefilter?: string, filterSleeveLengthFilter?: string, options?: AxiosRequestConfig) {
        return EndlessAisleApiFp(this.configuration).apiV1PoswebModelsModelGet(model, filterPkConsumer, filterBrand, filterCategoria, filterRighe, filterPagina, filterModelvariant, filterSizes, filterStock, filterName, filterEan, filterLikeMode, filterRfidStore, filterCodCategoria, filterCodSottocategoria, filterCollection, filterMainline, filterModelB2C, filterNewArrivals, filterOnSale, filterPrice, filterMinprice, filterMaxprice, filterYearSeason, filterStyleFilter, filterBodyLengthFilter, filterColorFilter, filterMaterialFilter, filterTypefilter, filterSleeveLengthFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets sizes of models from catalog
     * @summary Search for sizes
     * @param {string} filterBrand brand to be elaborated
     * @param {string} filterCategoria category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EndlessAisleApi
     */
    public apiV1PoswebSizesGet(filterBrand: string, filterCategoria: string, options?: AxiosRequestConfig) {
        return EndlessAisleApiFp(this.configuration).apiV1PoswebSizesGet(filterBrand, filterCategoria, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Serve per suggerire altri capi all’utente a partire dal dettaglio del capo corrente
     * @summary Suggerimenti di altri capi
     * @param {string} [filterBrand] brand, comma separated values are admitted
     * @param {string} [filterSkus] sku of item sample, comma separated values are admitted
     * @param {string} [filterLimit] limit of record returned
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EndlessAisleApi
     */
    public apiV1PoswebYmalGet(filterBrand?: string, filterSkus?: string, filterLimit?: string, options?: AxiosRequestConfig) {
        return EndlessAisleApiFp(this.configuration).apiV1PoswebYmalGet(filterBrand, filterSkus, filterLimit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Restituisce la giacenza per l\'item indicato La risposta è diversa se si passa details 0 o 1: - filter[details]=0: in attributes le chiavi riportano la giacenza del settore - filter[details]=1: negli \"attributes\" della risposta le chiavi sono oggetti, le cui chiavi sono i codici negozio dove l\'item ha giacenza
     * @summary Informazioni sulla giacenza di un item
     * @param {string} filterSku sku dell\&#39;item, si possono indicare più sku separandoli con la virgola
     * @param {0 | 1} filterDetails fornire i dettagli per negozio nella risposta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EndlessAisleApi
     */
    public apiV1StockGet(filterSku: string, filterDetails: 0 | 1, options?: AxiosRequestConfig) {
        return EndlessAisleApiFp(this.configuration).apiV1StockGet(filterSku, filterDetails, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FidelityApi - axios parameter creator
 * @export
 */
export const FidelityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Servizio per attivare una nuova card ad una consumatrice
         * @summary Attivazione card della consumatrice
         * @param {string} pkConsumer Consumer code
         * @param {ConsumerCardActivate} [consumerCardActivate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsActivatePost: async (pkConsumer: string, consumerCardActivate?: ConsumerCardActivate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsActivatePost', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/cards/activate`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consumerCardActivate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Servizio per disattivare una card della consumatrice
         * @summary Disattivazione card della consumatrice
         * @param {string} pkConsumer Consumer code
         * @param {ConsumerCardDisable} [consumerCardDisable]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsDisablePost: async (pkConsumer: string, consumerCardDisable?: ConsumerCardDisable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsDisablePost', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/cards/disable`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consumerCardDisable, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Servizio per settare il punteggio di una fidelity card
         * @summary Settare il punteggio di una fidelity card
         * @param {string} pkConsumer Consumer code
         * @param {ConsumerCardForceValue} [consumerCardForceValue]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsForcePost: async (pkConsumer: string, consumerCardForceValue?: ConsumerCardForceValue, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsForcePost', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/cards/force`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consumerCardForceValue, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Questo servizio ritorna la situazione fidelity della consumatrice, tutte le card attive e disattive con i relativi dati e punteggi.
         * @summary Situazione fidelity della consumatrice
         * @param {string} pkConsumer Consumer code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsGet: async (pkConsumer: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsGet', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/cards`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Servizio per travasare i punti da una fidelity card ad un\'altra.
         * @summary Travaso punti da una card disattiva
         * @param {string} pkConsumer Consumer code
         * @param {ConsumerCardMoveValue} [consumerCardMoveValue]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsMovePost: async (pkConsumer: string, consumerCardMoveValue?: ConsumerCardMoveValue, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsMovePost', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/cards/move`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consumerCardMoveValue, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Servizio che ritorna le fidelity associate alla consumatrice per i programmi fidelity affiliati a quello attivo su negozio.
         * @summary Situazione fidelity della consumatrice: programmi fidelity affiliati a quello attivo su negozio
         * @param {string} pkConsumer Consumer code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsRemoteGet: async (pkConsumer: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkConsumer' is not null or undefined
            assertParamExists('apiV1ConsumersPkConsumerCardsRemoteGet', 'pkConsumer', pkConsumer)
            const localVarPath = `/api/v1/consumers/{pk_consumer}/cards/remote`
                .replace(`{${"pk_consumer"}}`, encodeURIComponent(String(pkConsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Questo servizio ritorna l\'elenco dei programmi fidelity attivi sul negozio.
         * @summary Elenco dei programmi fidelity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebPromotionTypeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/promotion-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Servizio per preventivo punti fidelity accumulati sulla vendita corrente
         * @summary Preventivo punti fidelity accumulati sulla vendita corrente
         * @param {DataSale} [dataSale]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesFidelityPointsPost: async (dataSale?: DataSale, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sales/fidelity_points`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataSale, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check an existing sale
         * @summary Check sale payload
         * @param {string} saleId Sale unique id.
         * @param {PatchSaleFidelity} [patchSaleFidelity]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdFidelityPointsPatch: async (saleId: string, patchSaleFidelity?: PatchSaleFidelity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdFidelityPointsPatch', 'saleId', saleId)
            const localVarPath = `/api/v1/sales/{sale_id}/fidelity_points`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchSaleFidelity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FidelityApi - functional programming interface
 * @export
 */
export const FidelityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FidelityApiAxiosParamCreator(configuration)
    return {
        /**
         * Servizio per attivare una nuova card ad una consumatrice
         * @summary Attivazione card della consumatrice
         * @param {string} pkConsumer Consumer code
         * @param {ConsumerCardActivate} [consumerCardActivate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerCardsActivatePost(pkConsumer: string, consumerCardActivate?: ConsumerCardActivate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConsumerCardsInfo1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerCardsActivatePost(pkConsumer, consumerCardActivate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Servizio per disattivare una card della consumatrice
         * @summary Disattivazione card della consumatrice
         * @param {string} pkConsumer Consumer code
         * @param {ConsumerCardDisable} [consumerCardDisable]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerCardsDisablePost(pkConsumer: string, consumerCardDisable?: ConsumerCardDisable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConsumerCardsInfo1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerCardsDisablePost(pkConsumer, consumerCardDisable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Servizio per settare il punteggio di una fidelity card
         * @summary Settare il punteggio di una fidelity card
         * @param {string} pkConsumer Consumer code
         * @param {ConsumerCardForceValue} [consumerCardForceValue]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerCardsForcePost(pkConsumer: string, consumerCardForceValue?: ConsumerCardForceValue, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConsumerCardsInfo1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerCardsForcePost(pkConsumer, consumerCardForceValue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Questo servizio ritorna la situazione fidelity della consumatrice, tutte le card attive e disattive con i relativi dati e punteggi.
         * @summary Situazione fidelity della consumatrice
         * @param {string} pkConsumer Consumer code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerCardsGet(pkConsumer: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConsumerCardsInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerCardsGet(pkConsumer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Servizio per travasare i punti da una fidelity card ad un\'altra.
         * @summary Travaso punti da una card disattiva
         * @param {string} pkConsumer Consumer code
         * @param {ConsumerCardMoveValue} [consumerCardMoveValue]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerCardsMovePost(pkConsumer: string, consumerCardMoveValue?: ConsumerCardMoveValue, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConsumerCardsInfo1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerCardsMovePost(pkConsumer, consumerCardMoveValue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Servizio che ritorna le fidelity associate alla consumatrice per i programmi fidelity affiliati a quello attivo su negozio.
         * @summary Situazione fidelity della consumatrice: programmi fidelity affiliati a quello attivo su negozio
         * @param {string} pkConsumer Consumer code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConsumersPkConsumerCardsRemoteGet(pkConsumer: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConsumerAffiliations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConsumersPkConsumerCardsRemoteGet(pkConsumer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Questo servizio ritorna l\'elenco dei programmi fidelity attivi sul negozio.
         * @summary Elenco dei programmi fidelity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebPromotionTypeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPoswebPromotionTypes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebPromotionTypeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Servizio per preventivo punti fidelity accumulati sulla vendita corrente
         * @summary Preventivo punti fidelity accumulati sulla vendita corrente
         * @param {DataSale} [dataSale]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesFidelityPointsPost(dataSale?: DataSale, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaleAndFidelity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesFidelityPointsPost(dataSale, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Check an existing sale
         * @summary Check sale payload
         * @param {string} saleId Sale unique id.
         * @param {PatchSaleFidelity} [patchSaleFidelity]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesSaleIdFidelityPointsPatch(saleId: string, patchSaleFidelity?: PatchSaleFidelity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaleAndFidelity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesSaleIdFidelityPointsPatch(saleId, patchSaleFidelity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FidelityApi - factory interface
 * @export
 */
export const FidelityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FidelityApiFp(configuration)
    return {
        /**
         * Servizio per attivare una nuova card ad una consumatrice
         * @summary Attivazione card della consumatrice
         * @param {string} pkConsumer Consumer code
         * @param {ConsumerCardActivate} [consumerCardActivate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsActivatePost(pkConsumer: string, consumerCardActivate?: ConsumerCardActivate, options?: any): AxiosPromise<GetConsumerCardsInfo1> {
            return localVarFp.apiV1ConsumersPkConsumerCardsActivatePost(pkConsumer, consumerCardActivate, options).then((request) => request(axios, basePath));
        },
        /**
         * Servizio per disattivare una card della consumatrice
         * @summary Disattivazione card della consumatrice
         * @param {string} pkConsumer Consumer code
         * @param {ConsumerCardDisable} [consumerCardDisable]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsDisablePost(pkConsumer: string, consumerCardDisable?: ConsumerCardDisable, options?: any): AxiosPromise<GetConsumerCardsInfo1> {
            return localVarFp.apiV1ConsumersPkConsumerCardsDisablePost(pkConsumer, consumerCardDisable, options).then((request) => request(axios, basePath));
        },
        /**
         * Servizio per settare il punteggio di una fidelity card
         * @summary Settare il punteggio di una fidelity card
         * @param {string} pkConsumer Consumer code
         * @param {ConsumerCardForceValue} [consumerCardForceValue]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsForcePost(pkConsumer: string, consumerCardForceValue?: ConsumerCardForceValue, options?: any): AxiosPromise<GetConsumerCardsInfo1> {
            return localVarFp.apiV1ConsumersPkConsumerCardsForcePost(pkConsumer, consumerCardForceValue, options).then((request) => request(axios, basePath));
        },
        /**
         * Questo servizio ritorna la situazione fidelity della consumatrice, tutte le card attive e disattive con i relativi dati e punteggi.
         * @summary Situazione fidelity della consumatrice
         * @param {string} pkConsumer Consumer code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsGet(pkConsumer: string, options?: any): AxiosPromise<GetConsumerCardsInfo> {
            return localVarFp.apiV1ConsumersPkConsumerCardsGet(pkConsumer, options).then((request) => request(axios, basePath));
        },
        /**
         * Servizio per travasare i punti da una fidelity card ad un\'altra.
         * @summary Travaso punti da una card disattiva
         * @param {string} pkConsumer Consumer code
         * @param {ConsumerCardMoveValue} [consumerCardMoveValue]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsMovePost(pkConsumer: string, consumerCardMoveValue?: ConsumerCardMoveValue, options?: any): AxiosPromise<GetConsumerCardsInfo1> {
            return localVarFp.apiV1ConsumersPkConsumerCardsMovePost(pkConsumer, consumerCardMoveValue, options).then((request) => request(axios, basePath));
        },
        /**
         * Servizio che ritorna le fidelity associate alla consumatrice per i programmi fidelity affiliati a quello attivo su negozio.
         * @summary Situazione fidelity della consumatrice: programmi fidelity affiliati a quello attivo su negozio
         * @param {string} pkConsumer Consumer code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConsumersPkConsumerCardsRemoteGet(pkConsumer: string, options?: any): AxiosPromise<GetConsumerAffiliations> {
            return localVarFp.apiV1ConsumersPkConsumerCardsRemoteGet(pkConsumer, options).then((request) => request(axios, basePath));
        },
        /**
         * Questo servizio ritorna l\'elenco dei programmi fidelity attivi sul negozio.
         * @summary Elenco dei programmi fidelity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebPromotionTypeGet(options?: any): AxiosPromise<GetPoswebPromotionTypes> {
            return localVarFp.apiV1PoswebPromotionTypeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Servizio per preventivo punti fidelity accumulati sulla vendita corrente
         * @summary Preventivo punti fidelity accumulati sulla vendita corrente
         * @param {DataSale} [dataSale]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesFidelityPointsPost(dataSale?: DataSale, options?: any): AxiosPromise<SaleAndFidelity> {
            return localVarFp.apiV1SalesFidelityPointsPost(dataSale, options).then((request) => request(axios, basePath));
        },
        /**
         * Check an existing sale
         * @summary Check sale payload
         * @param {string} saleId Sale unique id.
         * @param {PatchSaleFidelity} [patchSaleFidelity]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdFidelityPointsPatch(saleId: string, patchSaleFidelity?: PatchSaleFidelity, options?: any): AxiosPromise<SaleAndFidelity> {
            return localVarFp.apiV1SalesSaleIdFidelityPointsPatch(saleId, patchSaleFidelity, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FidelityApi - object-oriented interface
 * @export
 * @class FidelityApi
 * @extends {BaseAPI}
 */
export class FidelityApi extends BaseAPI {
    /**
     * Servizio per attivare una nuova card ad una consumatrice
     * @summary Attivazione card della consumatrice
     * @param {string} pkConsumer Consumer code
     * @param {ConsumerCardActivate} [consumerCardActivate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FidelityApi
     */
    public apiV1ConsumersPkConsumerCardsActivatePost(pkConsumer: string, consumerCardActivate?: ConsumerCardActivate, options?: AxiosRequestConfig) {
        return FidelityApiFp(this.configuration).apiV1ConsumersPkConsumerCardsActivatePost(pkConsumer, consumerCardActivate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Servizio per disattivare una card della consumatrice
     * @summary Disattivazione card della consumatrice
     * @param {string} pkConsumer Consumer code
     * @param {ConsumerCardDisable} [consumerCardDisable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FidelityApi
     */
    public apiV1ConsumersPkConsumerCardsDisablePost(pkConsumer: string, consumerCardDisable?: ConsumerCardDisable, options?: AxiosRequestConfig) {
        return FidelityApiFp(this.configuration).apiV1ConsumersPkConsumerCardsDisablePost(pkConsumer, consumerCardDisable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Servizio per settare il punteggio di una fidelity card
     * @summary Settare il punteggio di una fidelity card
     * @param {string} pkConsumer Consumer code
     * @param {ConsumerCardForceValue} [consumerCardForceValue]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FidelityApi
     */
    public apiV1ConsumersPkConsumerCardsForcePost(pkConsumer: string, consumerCardForceValue?: ConsumerCardForceValue, options?: AxiosRequestConfig) {
        return FidelityApiFp(this.configuration).apiV1ConsumersPkConsumerCardsForcePost(pkConsumer, consumerCardForceValue, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Questo servizio ritorna la situazione fidelity della consumatrice, tutte le card attive e disattive con i relativi dati e punteggi.
     * @summary Situazione fidelity della consumatrice
     * @param {string} pkConsumer Consumer code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FidelityApi
     */
    public apiV1ConsumersPkConsumerCardsGet(pkConsumer: string, options?: AxiosRequestConfig) {
        return FidelityApiFp(this.configuration).apiV1ConsumersPkConsumerCardsGet(pkConsumer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Servizio per travasare i punti da una fidelity card ad un\'altra.
     * @summary Travaso punti da una card disattiva
     * @param {string} pkConsumer Consumer code
     * @param {ConsumerCardMoveValue} [consumerCardMoveValue]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FidelityApi
     */
    public apiV1ConsumersPkConsumerCardsMovePost(pkConsumer: string, consumerCardMoveValue?: ConsumerCardMoveValue, options?: AxiosRequestConfig) {
        return FidelityApiFp(this.configuration).apiV1ConsumersPkConsumerCardsMovePost(pkConsumer, consumerCardMoveValue, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Servizio che ritorna le fidelity associate alla consumatrice per i programmi fidelity affiliati a quello attivo su negozio.
     * @summary Situazione fidelity della consumatrice: programmi fidelity affiliati a quello attivo su negozio
     * @param {string} pkConsumer Consumer code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FidelityApi
     */
    public apiV1ConsumersPkConsumerCardsRemoteGet(pkConsumer: string, options?: AxiosRequestConfig) {
        return FidelityApiFp(this.configuration).apiV1ConsumersPkConsumerCardsRemoteGet(pkConsumer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Questo servizio ritorna l\'elenco dei programmi fidelity attivi sul negozio.
     * @summary Elenco dei programmi fidelity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FidelityApi
     */
    public apiV1PoswebPromotionTypeGet(options?: AxiosRequestConfig) {
        return FidelityApiFp(this.configuration).apiV1PoswebPromotionTypeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Servizio per preventivo punti fidelity accumulati sulla vendita corrente
     * @summary Preventivo punti fidelity accumulati sulla vendita corrente
     * @param {DataSale} [dataSale]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FidelityApi
     */
    public apiV1SalesFidelityPointsPost(dataSale?: DataSale, options?: AxiosRequestConfig) {
        return FidelityApiFp(this.configuration).apiV1SalesFidelityPointsPost(dataSale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Check an existing sale
     * @summary Check sale payload
     * @param {string} saleId Sale unique id.
     * @param {PatchSaleFidelity} [patchSaleFidelity]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FidelityApi
     */
    public apiV1SalesSaleIdFidelityPointsPatch(saleId: string, patchSaleFidelity?: PatchSaleFidelity, options?: AxiosRequestConfig) {
        return FidelityApiFp(this.configuration).apiV1SalesSaleIdFidelityPointsPatch(saleId, patchSaleFidelity, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GiftCardsApi - axios parameter creator
 * @export
 */
export const GiftCardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Activate Gift Cards      La risposta 200 restituisce un oggetto per ogni singolo barcode fornito nel payload     e in base allo \"status\" (created o error) il contenuto di \"attributes\" è differente
         * @summary Activate Gift cards
         * @param {string} saleId The sale ID of the transaction: cod_negozio,id_transazione
         * @param {GiftCards} [giftCards]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GiftCardsActivateSaleIdPost: async (saleId: string, giftCards?: GiftCards, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1GiftCardsActivateSaleIdPost', 'saleId', saleId)
            const localVarPath = `/api/v1/gift_cards/activate/{sale_id}`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(giftCards, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Prints items tags
         * @summary Prints items tags
         * @param {string} barcode The Barcode of the Gift Card
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GiftCardsCheckBarcodeGet: async (barcode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'barcode' is not null or undefined
            assertParamExists('apiV1GiftCardsCheckBarcodeGet', 'barcode', barcode)
            const localVarPath = `/api/v1/gift_cards/check/{barcode}`
                .replace(`{${"barcode"}}`, encodeURIComponent(String(barcode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GiftCardsApi - functional programming interface
 * @export
 */
export const GiftCardsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GiftCardsApiAxiosParamCreator(configuration)
    return {
        /**
         * Activate Gift Cards      La risposta 200 restituisce un oggetto per ogni singolo barcode fornito nel payload     e in base allo \"status\" (created o error) il contenuto di \"attributes\" è differente
         * @summary Activate Gift cards
         * @param {string} saleId The sale ID of the transaction: cod_negozio,id_transazione
         * @param {GiftCards} [giftCards]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GiftCardsActivateSaleIdPost(saleId: string, giftCards?: GiftCards, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardActivateAnswer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GiftCardsActivateSaleIdPost(saleId, giftCards, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Prints items tags
         * @summary Prints items tags
         * @param {string} barcode The Barcode of the Gift Card
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GiftCardsCheckBarcodeGet(barcode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardInformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GiftCardsCheckBarcodeGet(barcode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GiftCardsApi - factory interface
 * @export
 */
export const GiftCardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GiftCardsApiFp(configuration)
    return {
        /**
         * Activate Gift Cards      La risposta 200 restituisce un oggetto per ogni singolo barcode fornito nel payload     e in base allo \"status\" (created o error) il contenuto di \"attributes\" è differente
         * @summary Activate Gift cards
         * @param {string} saleId The sale ID of the transaction: cod_negozio,id_transazione
         * @param {GiftCards} [giftCards]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GiftCardsActivateSaleIdPost(saleId: string, giftCards?: GiftCards, options?: any): AxiosPromise<GiftCardActivateAnswer> {
            return localVarFp.apiV1GiftCardsActivateSaleIdPost(saleId, giftCards, options).then((request) => request(axios, basePath));
        },
        /**
         * Prints items tags
         * @summary Prints items tags
         * @param {string} barcode The Barcode of the Gift Card
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GiftCardsCheckBarcodeGet(barcode: string, options?: any): AxiosPromise<GiftCardInformation> {
            return localVarFp.apiV1GiftCardsCheckBarcodeGet(barcode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GiftCardsApi - object-oriented interface
 * @export
 * @class GiftCardsApi
 * @extends {BaseAPI}
 */
export class GiftCardsApi extends BaseAPI {
    /**
     * Activate Gift Cards      La risposta 200 restituisce un oggetto per ogni singolo barcode fornito nel payload     e in base allo \"status\" (created o error) il contenuto di \"attributes\" è differente
     * @summary Activate Gift cards
     * @param {string} saleId The sale ID of the transaction: cod_negozio,id_transazione
     * @param {GiftCards} [giftCards]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardsApi
     */
    public apiV1GiftCardsActivateSaleIdPost(saleId: string, giftCards?: GiftCards, options?: AxiosRequestConfig) {
        return GiftCardsApiFp(this.configuration).apiV1GiftCardsActivateSaleIdPost(saleId, giftCards, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Prints items tags
     * @summary Prints items tags
     * @param {string} barcode The Barcode of the Gift Card
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardsApi
     */
    public apiV1GiftCardsCheckBarcodeGet(barcode: string, options?: AxiosRequestConfig) {
        return GiftCardsApiFp(this.configuration).apiV1GiftCardsCheckBarcodeGet(barcode, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemsApi - axios parameter creator
 * @export
 */
export const ItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Avaiable price alterations
         * @summary Avaiable price alterations
         * @param {string} [filterClasse] Item class
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebAlterationsPricesGet: async (filterClasse?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/alterations-prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterClasse !== undefined) {
                localVarQueryParameter['filter[classe]'] = filterClasse;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Avaiable Classes
         * @summary Item Classes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebClassesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Avaiable Fabrics
         * @summary Item Fabrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebFabricsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/fabrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemsApi - functional programming interface
 * @export
 */
export const ItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * Avaiable price alterations
         * @summary Avaiable price alterations
         * @param {string} [filterClasse] Item class
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebAlterationsPricesGet(filterClasse?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetItemsPriceAlteration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebAlterationsPricesGet(filterClasse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Avaiable Classes
         * @summary Item Classes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebClassesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetItemsClasses>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebClassesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Avaiable Fabrics
         * @summary Item Fabrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebFabricsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetItemsFabrics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebFabricsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemsApi - factory interface
 * @export
 */
export const ItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemsApiFp(configuration)
    return {
        /**
         * Avaiable price alterations
         * @summary Avaiable price alterations
         * @param {string} [filterClasse] Item class
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebAlterationsPricesGet(filterClasse?: string, options?: any): AxiosPromise<GetItemsPriceAlteration> {
            return localVarFp.apiV1PoswebAlterationsPricesGet(filterClasse, options).then((request) => request(axios, basePath));
        },
        /**
         * Avaiable Classes
         * @summary Item Classes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebClassesGet(options?: any): AxiosPromise<GetItemsClasses> {
            return localVarFp.apiV1PoswebClassesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Avaiable Fabrics
         * @summary Item Fabrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebFabricsGet(options?: any): AxiosPromise<GetItemsFabrics> {
            return localVarFp.apiV1PoswebFabricsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemsApi - object-oriented interface
 * @export
 * @class ItemsApi
 * @extends {BaseAPI}
 */
export class ItemsApi extends BaseAPI {
    /**
     * Avaiable price alterations
     * @summary Avaiable price alterations
     * @param {string} [filterClasse] Item class
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public apiV1PoswebAlterationsPricesGet(filterClasse?: string, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).apiV1PoswebAlterationsPricesGet(filterClasse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Avaiable Classes
     * @summary Item Classes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public apiV1PoswebClassesGet(options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).apiV1PoswebClassesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Avaiable Fabrics
     * @summary Item Fabrics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public apiV1PoswebFabricsGet(options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).apiV1PoswebFabricsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ListaVenditeApi - axios parameter creator
 * @export
 */
export const ListaVenditeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get list of active document types on shop, with translated name of document type
         * @summary get list od document types active on shop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ListSalesDocumentTypesActiveGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/list-sales/document-types-active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * search for sales  the parameters are optionally: need at least one to start search  constraint: the interval between dates must be less than 2 months, either between both or today
         * @summary search for a list of sales
         * @param {string} [filterDataBollettinaDa] start date for search, passed as unformatted date yyyymmdd
         * @param {string} [filterDataBollettinaA] end date for search
         * @param {number} [filterNumeroBollettina] number of ticket to search
         * @param {string} [filterCognome] firstname of customer
         * @param {string} [filterNome] lastname of customer
         * @param {string} [filterBarcode] barcode of the ticket
         * @param {string} [filterStato] status of tickets
         * @param {string} [filterFidelity] fidelity card number
         * @param {number} [filterVipLevels] value of from a selection
         * @param {string} [filterEan] value of a product ean
         * @param {string} [filterCodiceStato] value state document selection
         * @param {string} [filterCodDocumento] value
         * @param {string} [filterNumeroFiscale] value
         * @param {string} [filterRdv] value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ListSalesGet: async (filterDataBollettinaDa?: string, filterDataBollettinaA?: string, filterNumeroBollettina?: number, filterCognome?: string, filterNome?: string, filterBarcode?: string, filterStato?: string, filterFidelity?: string, filterVipLevels?: number, filterEan?: string, filterCodiceStato?: string, filterCodDocumento?: string, filterNumeroFiscale?: string, filterRdv?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/list-sales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterDataBollettinaDa !== undefined) {
                localVarQueryParameter['filter[data_bollettina_da]'] = filterDataBollettinaDa;
            }

            if (filterDataBollettinaA !== undefined) {
                localVarQueryParameter['filter[data_bollettina_a]'] = filterDataBollettinaA;
            }

            if (filterNumeroBollettina !== undefined) {
                localVarQueryParameter['filter[numero_bollettina]'] = filterNumeroBollettina;
            }

            if (filterCognome !== undefined) {
                localVarQueryParameter['filter[cognome]'] = filterCognome;
            }

            if (filterNome !== undefined) {
                localVarQueryParameter['filter[nome]'] = filterNome;
            }

            if (filterBarcode !== undefined) {
                localVarQueryParameter['filter[barcode]'] = filterBarcode;
            }

            if (filterStato !== undefined) {
                localVarQueryParameter['filter[stato]'] = filterStato;
            }

            if (filterFidelity !== undefined) {
                localVarQueryParameter['filter[fidelity]'] = filterFidelity;
            }

            if (filterVipLevels !== undefined) {
                localVarQueryParameter['filter[vip_levels]'] = filterVipLevels;
            }

            if (filterEan !== undefined) {
                localVarQueryParameter['filter[ean]'] = filterEan;
            }

            if (filterCodiceStato !== undefined) {
                localVarQueryParameter['filter[codice_stato]'] = filterCodiceStato;
            }

            if (filterCodDocumento !== undefined) {
                localVarQueryParameter['filter[cod_documento]'] = filterCodDocumento;
            }

            if (filterNumeroFiscale !== undefined) {
                localVarQueryParameter['filter[numero_fiscale]'] = filterNumeroFiscale;
            }

            if (filterRdv !== undefined) {
                localVarQueryParameter['filter[rdv]'] = filterRdv;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * print invoice to pdf file
         * @summary print invoice
         * @param {string} key Transaction id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ListSalesPrintInvoiceKeyGet: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('apiV1ListSalesPrintInvoiceKeyGet', 'key', key)
            const localVarPath = `/api/v1/list-sales/print-invoice/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * print bill in oss format pdf file
         * @summary print bill in oss format pdf file
         * @param {string} key Transaction id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ListSalesPrintPdfOssKeyGet: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('apiV1ListSalesPrintPdfOssKeyGet', 'key', key)
            const localVarPath = `/api/v1/list-sales/print-pdf-oss/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get transaction details
         * @summary get details of selected sale
         * @param {string} key key of the transaction cod_negozio,id_transazione
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ListSalesSaleDetailKeyGet: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('apiV1ListSalesSaleDetailKeyGet', 'key', key)
            const localVarPath = `/api/v1/list-sales/sale-detail/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Print internal document
         * @summary Print a bollettina
         * @param {string} idTransazione Transaction unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RistampaBollettinaGet: async (idTransazione: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idTransazione' is not null or undefined
            assertParamExists('apiV1RistampaBollettinaGet', 'idTransazione', idTransazione)
            const localVarPath = `/api/v1/ristampa_bollettina`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (idTransazione !== undefined) {
                localVarQueryParameter['id_transazione'] = idTransazione;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Print fiscal document
         * @summary Print a bollettina fiscale
         * @param {string} idTransazione Transaction unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RistampaFiscaleGet: async (idTransazione: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idTransazione' is not null or undefined
            assertParamExists('apiV1RistampaFiscaleGet', 'idTransazione', idTransazione)
            const localVarPath = `/api/v1/ristampa_fiscale`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (idTransazione !== undefined) {
                localVarQueryParameter['id_transazione'] = idTransazione;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Print customer document
         * @summary Print a scontrino cortesia
         * @param {string} idTransazione Transaction unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RistampaScontrinoCortesiaGet: async (idTransazione: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idTransazione' is not null or undefined
            assertParamExists('apiV1RistampaScontrinoCortesiaGet', 'idTransazione', idTransazione)
            const localVarPath = `/api/v1/ristampa_scontrino_cortesia`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (idTransazione !== undefined) {
                localVarQueryParameter['id_transazione'] = idTransazione;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Effettua la ristampa del documento della transazione indicata sa {sale_id} (cod_negozio,id_transazione)      in risposta:     il \"type\" sarà \"receipt_pdf\" per una stampa pdf oppure \"receipt_print\"     in \"attributes\" sarà presente la chiave \"data\" se ho una stampa pdf altrimenti sarà vuoto {}
         * @summary Ristampa documento
         * @param {string} saleId Sale unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdReceiptPatch: async (saleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdReceiptPatch', 'saleId', saleId)
            const localVarPath = `/api/v1/sales/{sale_id}/receipt`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ListaVenditeApi - functional programming interface
 * @export
 */
export const ListaVenditeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ListaVenditeApiAxiosParamCreator(configuration)
    return {
        /**
         * get list of active document types on shop, with translated name of document type
         * @summary get list od document types active on shop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ListSalesDocumentTypesActiveGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTypeList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ListSalesDocumentTypesActiveGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * search for sales  the parameters are optionally: need at least one to start search  constraint: the interval between dates must be less than 2 months, either between both or today
         * @summary search for a list of sales
         * @param {string} [filterDataBollettinaDa] start date for search, passed as unformatted date yyyymmdd
         * @param {string} [filterDataBollettinaA] end date for search
         * @param {number} [filterNumeroBollettina] number of ticket to search
         * @param {string} [filterCognome] firstname of customer
         * @param {string} [filterNome] lastname of customer
         * @param {string} [filterBarcode] barcode of the ticket
         * @param {string} [filterStato] status of tickets
         * @param {string} [filterFidelity] fidelity card number
         * @param {number} [filterVipLevels] value of from a selection
         * @param {string} [filterEan] value of a product ean
         * @param {string} [filterCodiceStato] value state document selection
         * @param {string} [filterCodDocumento] value
         * @param {string} [filterNumeroFiscale] value
         * @param {string} [filterRdv] value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ListSalesGet(filterDataBollettinaDa?: string, filterDataBollettinaA?: string, filterNumeroBollettina?: number, filterCognome?: string, filterNome?: string, filterBarcode?: string, filterStato?: string, filterFidelity?: string, filterVipLevels?: number, filterEan?: string, filterCodiceStato?: string, filterCodDocumento?: string, filterNumeroFiscale?: string, filterRdv?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentAsListOfSales>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ListSalesGet(filterDataBollettinaDa, filterDataBollettinaA, filterNumeroBollettina, filterCognome, filterNome, filterBarcode, filterStato, filterFidelity, filterVipLevels, filterEan, filterCodiceStato, filterCodDocumento, filterNumeroFiscale, filterRdv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * print invoice to pdf file
         * @summary print invoice
         * @param {string} key Transaction id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ListSalesPrintInvoiceKeyGet(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfFile1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ListSalesPrintInvoiceKeyGet(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * print bill in oss format pdf file
         * @summary print bill in oss format pdf file
         * @param {string} key Transaction id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ListSalesPrintPdfOssKeyGet(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ListSalesPrintPdfOssKeyGet(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get transaction details
         * @summary get details of selected sale
         * @param {string} key key of the transaction cod_negozio,id_transazione
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ListSalesSaleDetailKeyGet(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sale4>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ListSalesSaleDetailKeyGet(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Print internal document
         * @summary Print a bollettina
         * @param {string} idTransazione Transaction unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RistampaBollettinaGet(idTransazione: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RistampaBollettinaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RistampaBollettinaGet(idTransazione, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Print fiscal document
         * @summary Print a bollettina fiscale
         * @param {string} idTransazione Transaction unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RistampaFiscaleGet(idTransazione: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RistampaFiscaleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RistampaFiscaleGet(idTransazione, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Print customer document
         * @summary Print a scontrino cortesia
         * @param {string} idTransazione Transaction unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RistampaScontrinoCortesiaGet(idTransazione: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RistampaScontrinoCortesiaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RistampaScontrinoCortesiaGet(idTransazione, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Effettua la ristampa del documento della transazione indicata sa {sale_id} (cod_negozio,id_transazione)      in risposta:     il \"type\" sarà \"receipt_pdf\" per una stampa pdf oppure \"receipt_print\"     in \"attributes\" sarà presente la chiave \"data\" se ho una stampa pdf altrimenti sarà vuoto {}
         * @summary Ristampa documento
         * @param {string} saleId Sale unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesSaleIdReceiptPatch(saleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EsitoRistampaDocumento>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesSaleIdReceiptPatch(saleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ListaVenditeApi - factory interface
 * @export
 */
export const ListaVenditeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ListaVenditeApiFp(configuration)
    return {
        /**
         * get list of active document types on shop, with translated name of document type
         * @summary get list od document types active on shop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ListSalesDocumentTypesActiveGet(options?: any): AxiosPromise<DocumentTypeList> {
            return localVarFp.apiV1ListSalesDocumentTypesActiveGet(options).then((request) => request(axios, basePath));
        },
        /**
         * search for sales  the parameters are optionally: need at least one to start search  constraint: the interval between dates must be less than 2 months, either between both or today
         * @summary search for a list of sales
         * @param {string} [filterDataBollettinaDa] start date for search, passed as unformatted date yyyymmdd
         * @param {string} [filterDataBollettinaA] end date for search
         * @param {number} [filterNumeroBollettina] number of ticket to search
         * @param {string} [filterCognome] firstname of customer
         * @param {string} [filterNome] lastname of customer
         * @param {string} [filterBarcode] barcode of the ticket
         * @param {string} [filterStato] status of tickets
         * @param {string} [filterFidelity] fidelity card number
         * @param {number} [filterVipLevels] value of from a selection
         * @param {string} [filterEan] value of a product ean
         * @param {string} [filterCodiceStato] value state document selection
         * @param {string} [filterCodDocumento] value
         * @param {string} [filterNumeroFiscale] value
         * @param {string} [filterRdv] value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ListSalesGet(filterDataBollettinaDa?: string, filterDataBollettinaA?: string, filterNumeroBollettina?: number, filterCognome?: string, filterNome?: string, filterBarcode?: string, filterStato?: string, filterFidelity?: string, filterVipLevels?: number, filterEan?: string, filterCodiceStato?: string, filterCodDocumento?: string, filterNumeroFiscale?: string, filterRdv?: string, options?: any): AxiosPromise<ContentAsListOfSales> {
            return localVarFp.apiV1ListSalesGet(filterDataBollettinaDa, filterDataBollettinaA, filterNumeroBollettina, filterCognome, filterNome, filterBarcode, filterStato, filterFidelity, filterVipLevels, filterEan, filterCodiceStato, filterCodDocumento, filterNumeroFiscale, filterRdv, options).then((request) => request(axios, basePath));
        },
        /**
         * print invoice to pdf file
         * @summary print invoice
         * @param {string} key Transaction id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ListSalesPrintInvoiceKeyGet(key: string, options?: any): AxiosPromise<PdfFile1> {
            return localVarFp.apiV1ListSalesPrintInvoiceKeyGet(key, options).then((request) => request(axios, basePath));
        },
        /**
         * print bill in oss format pdf file
         * @summary print bill in oss format pdf file
         * @param {string} key Transaction id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ListSalesPrintPdfOssKeyGet(key: string, options?: any): AxiosPromise<PdfFile> {
            return localVarFp.apiV1ListSalesPrintPdfOssKeyGet(key, options).then((request) => request(axios, basePath));
        },
        /**
         * get transaction details
         * @summary get details of selected sale
         * @param {string} key key of the transaction cod_negozio,id_transazione
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ListSalesSaleDetailKeyGet(key: string, options?: any): AxiosPromise<Sale4> {
            return localVarFp.apiV1ListSalesSaleDetailKeyGet(key, options).then((request) => request(axios, basePath));
        },
        /**
         * Print internal document
         * @summary Print a bollettina
         * @param {string} idTransazione Transaction unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RistampaBollettinaGet(idTransazione: string, options?: any): AxiosPromise<RistampaBollettinaResponse> {
            return localVarFp.apiV1RistampaBollettinaGet(idTransazione, options).then((request) => request(axios, basePath));
        },
        /**
         * Print fiscal document
         * @summary Print a bollettina fiscale
         * @param {string} idTransazione Transaction unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RistampaFiscaleGet(idTransazione: string, options?: any): AxiosPromise<RistampaFiscaleResponse> {
            return localVarFp.apiV1RistampaFiscaleGet(idTransazione, options).then((request) => request(axios, basePath));
        },
        /**
         * Print customer document
         * @summary Print a scontrino cortesia
         * @param {string} idTransazione Transaction unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RistampaScontrinoCortesiaGet(idTransazione: string, options?: any): AxiosPromise<RistampaScontrinoCortesiaResponse> {
            return localVarFp.apiV1RistampaScontrinoCortesiaGet(idTransazione, options).then((request) => request(axios, basePath));
        },
        /**
         * Effettua la ristampa del documento della transazione indicata sa {sale_id} (cod_negozio,id_transazione)      in risposta:     il \"type\" sarà \"receipt_pdf\" per una stampa pdf oppure \"receipt_print\"     in \"attributes\" sarà presente la chiave \"data\" se ho una stampa pdf altrimenti sarà vuoto {}
         * @summary Ristampa documento
         * @param {string} saleId Sale unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdReceiptPatch(saleId: string, options?: any): AxiosPromise<EsitoRistampaDocumento> {
            return localVarFp.apiV1SalesSaleIdReceiptPatch(saleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ListaVenditeApi - object-oriented interface
 * @export
 * @class ListaVenditeApi
 * @extends {BaseAPI}
 */
export class ListaVenditeApi extends BaseAPI {
    /**
     * get list of active document types on shop, with translated name of document type
     * @summary get list od document types active on shop
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListaVenditeApi
     */
    public apiV1ListSalesDocumentTypesActiveGet(options?: AxiosRequestConfig) {
        return ListaVenditeApiFp(this.configuration).apiV1ListSalesDocumentTypesActiveGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * search for sales  the parameters are optionally: need at least one to start search  constraint: the interval between dates must be less than 2 months, either between both or today
     * @summary search for a list of sales
     * @param {string} [filterDataBollettinaDa] start date for search, passed as unformatted date yyyymmdd
     * @param {string} [filterDataBollettinaA] end date for search
     * @param {number} [filterNumeroBollettina] number of ticket to search
     * @param {string} [filterCognome] firstname of customer
     * @param {string} [filterNome] lastname of customer
     * @param {string} [filterBarcode] barcode of the ticket
     * @param {string} [filterStato] status of tickets
     * @param {string} [filterFidelity] fidelity card number
     * @param {number} [filterVipLevels] value of from a selection
     * @param {string} [filterEan] value of a product ean
     * @param {string} [filterCodiceStato] value state document selection
     * @param {string} [filterCodDocumento] value
     * @param {string} [filterNumeroFiscale] value
     * @param {string} [filterRdv] value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListaVenditeApi
     */
    public apiV1ListSalesGet(filterDataBollettinaDa?: string, filterDataBollettinaA?: string, filterNumeroBollettina?: number, filterCognome?: string, filterNome?: string, filterBarcode?: string, filterStato?: string, filterFidelity?: string, filterVipLevels?: number, filterEan?: string, filterCodiceStato?: string, filterCodDocumento?: string, filterNumeroFiscale?: string, filterRdv?: string, options?: AxiosRequestConfig) {
        return ListaVenditeApiFp(this.configuration).apiV1ListSalesGet(filterDataBollettinaDa, filterDataBollettinaA, filterNumeroBollettina, filterCognome, filterNome, filterBarcode, filterStato, filterFidelity, filterVipLevels, filterEan, filterCodiceStato, filterCodDocumento, filterNumeroFiscale, filterRdv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * print invoice to pdf file
     * @summary print invoice
     * @param {string} key Transaction id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListaVenditeApi
     */
    public apiV1ListSalesPrintInvoiceKeyGet(key: string, options?: AxiosRequestConfig) {
        return ListaVenditeApiFp(this.configuration).apiV1ListSalesPrintInvoiceKeyGet(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * print bill in oss format pdf file
     * @summary print bill in oss format pdf file
     * @param {string} key Transaction id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListaVenditeApi
     */
    public apiV1ListSalesPrintPdfOssKeyGet(key: string, options?: AxiosRequestConfig) {
        return ListaVenditeApiFp(this.configuration).apiV1ListSalesPrintPdfOssKeyGet(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get transaction details
     * @summary get details of selected sale
     * @param {string} key key of the transaction cod_negozio,id_transazione
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListaVenditeApi
     */
    public apiV1ListSalesSaleDetailKeyGet(key: string, options?: AxiosRequestConfig) {
        return ListaVenditeApiFp(this.configuration).apiV1ListSalesSaleDetailKeyGet(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Print internal document
     * @summary Print a bollettina
     * @param {string} idTransazione Transaction unique id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListaVenditeApi
     */
    public apiV1RistampaBollettinaGet(idTransazione: string, options?: AxiosRequestConfig) {
        return ListaVenditeApiFp(this.configuration).apiV1RistampaBollettinaGet(idTransazione, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Print fiscal document
     * @summary Print a bollettina fiscale
     * @param {string} idTransazione Transaction unique id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListaVenditeApi
     */
    public apiV1RistampaFiscaleGet(idTransazione: string, options?: AxiosRequestConfig) {
        return ListaVenditeApiFp(this.configuration).apiV1RistampaFiscaleGet(idTransazione, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Print customer document
     * @summary Print a scontrino cortesia
     * @param {string} idTransazione Transaction unique id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListaVenditeApi
     */
    public apiV1RistampaScontrinoCortesiaGet(idTransazione: string, options?: AxiosRequestConfig) {
        return ListaVenditeApiFp(this.configuration).apiV1RistampaScontrinoCortesiaGet(idTransazione, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Effettua la ristampa del documento della transazione indicata sa {sale_id} (cod_negozio,id_transazione)      in risposta:     il \"type\" sarà \"receipt_pdf\" per una stampa pdf oppure \"receipt_print\"     in \"attributes\" sarà presente la chiave \"data\" se ho una stampa pdf altrimenti sarà vuoto {}
     * @summary Ristampa documento
     * @param {string} saleId Sale unique id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListaVenditeApi
     */
    public apiV1SalesSaleIdReceiptPatch(saleId: string, options?: AxiosRequestConfig) {
        return ListaVenditeApiFp(this.configuration).apiV1SalesSaleIdReceiptPatch(saleId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OMTokenApi - axios parameter creator
 * @export
 */
export const OMTokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the token from OM
         * @summary Get OM Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OmAuthTokenGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/om/auth/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OMTokenApi - functional programming interface
 * @export
 */
export const OMTokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OMTokenApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the token from OM
         * @summary Get OM Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OmAuthTokenGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOMToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OmAuthTokenGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OMTokenApi - factory interface
 * @export
 */
export const OMTokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OMTokenApiFp(configuration)
    return {
        /**
         * Returns the token from OM
         * @summary Get OM Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OmAuthTokenGet(options?: any): AxiosPromise<GetOMToken> {
            return localVarFp.apiV1OmAuthTokenGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OMTokenApi - object-oriented interface
 * @export
 * @class OMTokenApi
 * @extends {BaseAPI}
 */
export class OMTokenApi extends BaseAPI {
    /**
     * Returns the token from OM
     * @summary Get OM Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OMTokenApi
     */
    public apiV1OmAuthTokenGet(options?: AxiosRequestConfig) {
        return OMTokenApiFp(this.configuration).apiV1OmAuthTokenGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the list of possible CC usable circuits
         * @summary Avaiable Credit card circuits
         * @param {string} [filterCodiceCompagnia] Corporate credit card circuit code
         * @param {string} [filterDescrTipoCarta] Credit card circuit name
         * @param {string} [filterCodiceAquirer] Acquirer credit card circuit code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CreditCardGet: async (filterCodiceCompagnia?: string, filterDescrTipoCarta?: string, filterCodiceAquirer?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/credit-card`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterCodiceCompagnia !== undefined) {
                localVarQueryParameter['filter[codice_compagnia]'] = filterCodiceCompagnia;
            }

            if (filterDescrTipoCarta !== undefined) {
                localVarQueryParameter['filter[descr_tipo_carta]'] = filterDescrTipoCarta;
            }

            if (filterCodiceAquirer !== undefined) {
                localVarQueryParameter['filter[codice_aquirer]'] = filterCodiceAquirer;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the list of allowed id types for a payment or a sale closure
         * @summary Allowed Ids
         * @param {string} [filterCodiceGruppo] Payment or document section
         * @param {string} [filterCodiceDocumento] Document id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebDocumentTypesGet: async (filterCodiceGruppo?: string, filterCodiceDocumento?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/document-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterCodiceGruppo !== undefined) {
                localVarQueryParameter['filter[codice_gruppo]'] = filterCodiceGruppo;
            }

            if (filterCodiceDocumento !== undefined) {
                localVarQueryParameter['filter[codice_documento]'] = filterCodiceDocumento;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Instructs the BE to issue a refund through the pos terminal
         * @summary Credit Card refund B2E
         * @param {string} saleId Sale unique id: codice_negozio,id_transazione
         * @param {string} posTransactionId CC transaction unique id
         * @param {ReturnsCCRefund1} [returnsCCRefund1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdPost: async (saleId: string, posTransactionId: string, returnsCCRefund1?: ReturnsCCRefund1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdPost', 'saleId', saleId)
            // verify required parameter 'posTransactionId' is not null or undefined
            assertParamExists('apiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdPost', 'posTransactionId', posTransactionId)
            const localVarPath = `/api/v1/refunds-b2e/{sale_id}/payments/pos/{pos_transaction_id}`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)))
                .replace(`{${"pos_transaction_id"}}`, encodeURIComponent(String(posTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(returnsCCRefund1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Payload: data necessary to return a sale, with mandatory key \"importo\" !  Il payload sarà lo stesso usato per la chiamata di creazione reso: POST /return  a cui deve essere aggiunto il campo \"importo\": valore immesso nella nuova gift card e negativo !
         * @summary Restore gift card used when do a return
         * @param {string} saleId Sale unique id: cod_negozio,id_transazione
         * @param {string} giftCode Gift Card unique code
         * @param {ReturnsGiftCard} [returnsGiftCard]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReturnsSaleIdPaymentsGiftCardGiftCodePost: async (saleId: string, giftCode: string, returnsGiftCard?: ReturnsGiftCard, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1ReturnsSaleIdPaymentsGiftCardGiftCodePost', 'saleId', saleId)
            // verify required parameter 'giftCode' is not null or undefined
            assertParamExists('apiV1ReturnsSaleIdPaymentsGiftCardGiftCodePost', 'giftCode', giftCode)
            const localVarPath = `/api/v1/returns/{sale_id}/payments/gift_card/{gift_code}`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)))
                .replace(`{${"gift_code"}}`, encodeURIComponent(String(giftCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(returnsGiftCard, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Instructs the BE to issue a refund through the pos terminal
         * @summary Return Credit Card refund
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {ReturnsCCRefund} [returnsCCRefund]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReturnsSaleIdPaymentsPosPosTransactionIdPost: async (saleId: string, posTransactionId: string, returnsCCRefund?: ReturnsCCRefund, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1ReturnsSaleIdPaymentsPosPosTransactionIdPost', 'saleId', saleId)
            // verify required parameter 'posTransactionId' is not null or undefined
            assertParamExists('apiV1ReturnsSaleIdPaymentsPosPosTransactionIdPost', 'posTransactionId', posTransactionId)
            const localVarPath = `/api/v1/returns/{sale_id}/payments/pos/{pos_transaction_id}`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)))
                .replace(`{${"pos_transaction_id"}}`, encodeURIComponent(String(posTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(returnsCCRefund, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Polling on the pos terminal status
         * @summary PosStatus
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {CCStatus} [cCStatus]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusPost: async (saleId: string, posTransactionId: string, cCStatus?: CCStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusPost', 'saleId', saleId)
            // verify required parameter 'posTransactionId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusPost', 'posTransactionId', posTransactionId)
            const localVarPath = `/api/v1/sales/{sale_id}/payments/credit_card/{pos_transaction_id}/status`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)))
                .replace(`{${"pos_transaction_id"}}`, encodeURIComponent(String(posTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cCStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Burn gift card used for payment
         * @summary Burn gift card used for payment
         * @param {string} saleId Sale unique id: cod_negozio,id_transazione
         * @param {string} giftCode Gift Card unique code
         * @param {BurnGiftCard1} [burnGiftCard1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdPaymentsGiftCardGiftCodePatch: async (saleId: string, giftCode: string, burnGiftCard1?: BurnGiftCard1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdPaymentsGiftCardGiftCodePatch', 'saleId', saleId)
            // verify required parameter 'giftCode' is not null or undefined
            assertParamExists('apiV1SalesSaleIdPaymentsGiftCardGiftCodePatch', 'giftCode', giftCode)
            const localVarPath = `/api/v1/sales/{sale_id}/payments/gift_card/{gift_code}`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)))
                .replace(`{${"gift_code"}}`, encodeURIComponent(String(giftCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(burnGiftCard1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Burn gift card used for payment
         * @summary Burn gift card used for payment
         * @param {string} saleId Sale unique id: cod_negozio,id_transazione&#x3D;0
         * @param {string} giftCode Gift Card unique code
         * @param {BurnGiftCard} [burnGiftCard]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdPaymentsGiftCardGiftCodePost: async (saleId: string, giftCode: string, burnGiftCard?: BurnGiftCard, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdPaymentsGiftCardGiftCodePost', 'saleId', saleId)
            // verify required parameter 'giftCode' is not null or undefined
            assertParamExists('apiV1SalesSaleIdPaymentsGiftCardGiftCodePost', 'giftCode', giftCode)
            const localVarPath = `/api/v1/sales/{sale_id}/payments/gift_card/{gift_code}`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)))
                .replace(`{${"gift_code"}}`, encodeURIComponent(String(giftCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(burnGiftCard, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Instructs the BE to issue a payment on the pos terminal
         * @summary Sale Credit Card payment
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {SaleCCPayment} [saleCCPayment]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdPaymentsPosPosTransactionIdPost: async (saleId: string, posTransactionId: string, saleCCPayment?: SaleCCPayment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdPaymentsPosPosTransactionIdPost', 'saleId', saleId)
            // verify required parameter 'posTransactionId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdPaymentsPosPosTransactionIdPost', 'posTransactionId', posTransactionId)
            const localVarPath = `/api/v1/sales/{sale_id}/payments/pos/{pos_transaction_id}`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)))
                .replace(`{${"pos_transaction_id"}}`, encodeURIComponent(String(posTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saleCCPayment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Instructs the BE to issue a payment pos terminal for a suspended credit
         * @summary Suspended Credit - Credit Card payment
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {SuspendedCreditCCPayment} [suspendedCreditCCPayment]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdPost: async (saleId: string, posTransactionId: string, suspendedCreditCCPayment?: SuspendedCreditCCPayment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdPost', 'saleId', saleId)
            // verify required parameter 'posTransactionId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdPost', 'posTransactionId', posTransactionId)
            const localVarPath = `/api/v1/sales/{sale_id}/payments/pos-suspended/{pos_transaction_id}`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)))
                .replace(`{${"pos_transaction_id"}}`, encodeURIComponent(String(posTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(suspendedCreditCCPayment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Instructs the BE to issue a payment reversal on the pos terminal
         * @summary Sale Credit Card payment reversal
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {SaleCCPaymentReversal} [saleCCPaymentReversal]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdPaymentsReversalPosPosTransactionIdPost: async (saleId: string, posTransactionId: string, saleCCPaymentReversal?: SaleCCPaymentReversal, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdPaymentsReversalPosPosTransactionIdPost', 'saleId', saleId)
            // verify required parameter 'posTransactionId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdPaymentsReversalPosPosTransactionIdPost', 'posTransactionId', posTransactionId)
            const localVarPath = `/api/v1/sales/{sale_id}/payments-reversal/pos/{pos_transaction_id}`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)))
                .replace(`{${"pos_transaction_id"}}`, encodeURIComponent(String(posTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saleCCPaymentReversal, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Burn gift card used for payment
         * @summary Burn gift card used for payment
         * @param {string} saleId Sale unique id: cod_negozio,id_transazione
         * @param {string} giftCode Gift Card unique code
         * @param {BurnGiftCard2} [burnGiftCard2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SuspendedSaleIdPaymentsGiftCardGiftCodePost: async (saleId: string, giftCode: string, burnGiftCard2?: BurnGiftCard2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1SuspendedSaleIdPaymentsGiftCardGiftCodePost', 'saleId', saleId)
            // verify required parameter 'giftCode' is not null or undefined
            assertParamExists('apiV1SuspendedSaleIdPaymentsGiftCardGiftCodePost', 'giftCode', giftCode)
            const localVarPath = `/api/v1/suspended/{sale_id}/payments/gift_card/{gift_code}`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)))
                .replace(`{${"gift_code"}}`, encodeURIComponent(String(giftCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(burnGiftCard2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the list of possible CC usable circuits
         * @summary Avaiable Credit card circuits
         * @param {string} [filterCodiceCompagnia] Corporate credit card circuit code
         * @param {string} [filterDescrTipoCarta] Credit card circuit name
         * @param {string} [filterCodiceAquirer] Acquirer credit card circuit code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CreditCardGet(filterCodiceCompagnia?: string, filterDescrTipoCarta?: string, filterCodiceAquirer?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCreditCardCircuits>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CreditCardGet(filterCodiceCompagnia, filterDescrTipoCarta, filterCodiceAquirer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the list of allowed id types for a payment or a sale closure
         * @summary Allowed Ids
         * @param {string} [filterCodiceGruppo] Payment or document section
         * @param {string} [filterCodiceDocumento] Document id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebDocumentTypesGet(filterCodiceGruppo?: string, filterCodiceDocumento?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCreditCardCircuits1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebDocumentTypesGet(filterCodiceGruppo, filterCodiceDocumento, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Instructs the BE to issue a refund through the pos terminal
         * @summary Credit Card refund B2E
         * @param {string} saleId Sale unique id: codice_negozio,id_transazione
         * @param {string} posTransactionId CC transaction unique id
         * @param {ReturnsCCRefund1} [returnsCCRefund1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdPost(saleId: string, posTransactionId: string, returnsCCRefund1?: ReturnsCCRefund1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CCPayment1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdPost(saleId, posTransactionId, returnsCCRefund1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Payload: data necessary to return a sale, with mandatory key \"importo\" !  Il payload sarà lo stesso usato per la chiamata di creazione reso: POST /return  a cui deve essere aggiunto il campo \"importo\": valore immesso nella nuova gift card e negativo !
         * @summary Restore gift card used when do a return
         * @param {string} saleId Sale unique id: cod_negozio,id_transazione
         * @param {string} giftCode Gift Card unique code
         * @param {ReturnsGiftCard} [returnsGiftCard]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReturnsSaleIdPaymentsGiftCardGiftCodePost(saleId: string, giftCode: string, returnsGiftCard?: ReturnsGiftCard, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnBurnGiftCard1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReturnsSaleIdPaymentsGiftCardGiftCodePost(saleId, giftCode, returnsGiftCard, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Instructs the BE to issue a refund through the pos terminal
         * @summary Return Credit Card refund
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {ReturnsCCRefund} [returnsCCRefund]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReturnsSaleIdPaymentsPosPosTransactionIdPost(saleId: string, posTransactionId: string, returnsCCRefund?: ReturnsCCRefund, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CCPayment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReturnsSaleIdPaymentsPosPosTransactionIdPost(saleId, posTransactionId, returnsCCRefund, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Polling on the pos terminal status
         * @summary PosStatus
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {CCStatus} [cCStatus]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusPost(saleId: string, posTransactionId: string, cCStatus?: CCStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CCPaymentResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusPost(saleId, posTransactionId, cCStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Burn gift card used for payment
         * @summary Burn gift card used for payment
         * @param {string} saleId Sale unique id: cod_negozio,id_transazione
         * @param {string} giftCode Gift Card unique code
         * @param {BurnGiftCard1} [burnGiftCard1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesSaleIdPaymentsGiftCardGiftCodePatch(saleId: string, giftCode: string, burnGiftCard1?: BurnGiftCard1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnBurnGiftCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesSaleIdPaymentsGiftCardGiftCodePatch(saleId, giftCode, burnGiftCard1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Burn gift card used for payment
         * @summary Burn gift card used for payment
         * @param {string} saleId Sale unique id: cod_negozio,id_transazione&#x3D;0
         * @param {string} giftCode Gift Card unique code
         * @param {BurnGiftCard} [burnGiftCard]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesSaleIdPaymentsGiftCardGiftCodePost(saleId: string, giftCode: string, burnGiftCard?: BurnGiftCard, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnBurnGiftCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesSaleIdPaymentsGiftCardGiftCodePost(saleId, giftCode, burnGiftCard, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Instructs the BE to issue a payment on the pos terminal
         * @summary Sale Credit Card payment
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {SaleCCPayment} [saleCCPayment]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesSaleIdPaymentsPosPosTransactionIdPost(saleId: string, posTransactionId: string, saleCCPayment?: SaleCCPayment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CCPayment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesSaleIdPaymentsPosPosTransactionIdPost(saleId, posTransactionId, saleCCPayment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Instructs the BE to issue a payment pos terminal for a suspended credit
         * @summary Suspended Credit - Credit Card payment
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {SuspendedCreditCCPayment} [suspendedCreditCCPayment]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdPost(saleId: string, posTransactionId: string, suspendedCreditCCPayment?: SuspendedCreditCCPayment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CCPayment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdPost(saleId, posTransactionId, suspendedCreditCCPayment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Instructs the BE to issue a payment reversal on the pos terminal
         * @summary Sale Credit Card payment reversal
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {SaleCCPaymentReversal} [saleCCPaymentReversal]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesSaleIdPaymentsReversalPosPosTransactionIdPost(saleId: string, posTransactionId: string, saleCCPaymentReversal?: SaleCCPaymentReversal, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CCPayment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesSaleIdPaymentsReversalPosPosTransactionIdPost(saleId, posTransactionId, saleCCPaymentReversal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Burn gift card used for payment
         * @summary Burn gift card used for payment
         * @param {string} saleId Sale unique id: cod_negozio,id_transazione
         * @param {string} giftCode Gift Card unique code
         * @param {BurnGiftCard2} [burnGiftCard2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SuspendedSaleIdPaymentsGiftCardGiftCodePost(saleId: string, giftCode: string, burnGiftCard2?: BurnGiftCard2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardData1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SuspendedSaleIdPaymentsGiftCardGiftCodePost(saleId, giftCode, burnGiftCard2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentsApiFp(configuration)
    return {
        /**
         * Get the list of possible CC usable circuits
         * @summary Avaiable Credit card circuits
         * @param {string} [filterCodiceCompagnia] Corporate credit card circuit code
         * @param {string} [filterDescrTipoCarta] Credit card circuit name
         * @param {string} [filterCodiceAquirer] Acquirer credit card circuit code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CreditCardGet(filterCodiceCompagnia?: string, filterDescrTipoCarta?: string, filterCodiceAquirer?: string, options?: any): AxiosPromise<GetCreditCardCircuits> {
            return localVarFp.apiV1CreditCardGet(filterCodiceCompagnia, filterDescrTipoCarta, filterCodiceAquirer, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the list of allowed id types for a payment or a sale closure
         * @summary Allowed Ids
         * @param {string} [filterCodiceGruppo] Payment or document section
         * @param {string} [filterCodiceDocumento] Document id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebDocumentTypesGet(filterCodiceGruppo?: string, filterCodiceDocumento?: string, options?: any): AxiosPromise<GetCreditCardCircuits1> {
            return localVarFp.apiV1PoswebDocumentTypesGet(filterCodiceGruppo, filterCodiceDocumento, options).then((request) => request(axios, basePath));
        },
        /**
         * Instructs the BE to issue a refund through the pos terminal
         * @summary Credit Card refund B2E
         * @param {string} saleId Sale unique id: codice_negozio,id_transazione
         * @param {string} posTransactionId CC transaction unique id
         * @param {ReturnsCCRefund1} [returnsCCRefund1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdPost(saleId: string, posTransactionId: string, returnsCCRefund1?: ReturnsCCRefund1, options?: any): AxiosPromise<CCPayment1> {
            return localVarFp.apiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdPost(saleId, posTransactionId, returnsCCRefund1, options).then((request) => request(axios, basePath));
        },
        /**
         * Payload: data necessary to return a sale, with mandatory key \"importo\" !  Il payload sarà lo stesso usato per la chiamata di creazione reso: POST /return  a cui deve essere aggiunto il campo \"importo\": valore immesso nella nuova gift card e negativo !
         * @summary Restore gift card used when do a return
         * @param {string} saleId Sale unique id: cod_negozio,id_transazione
         * @param {string} giftCode Gift Card unique code
         * @param {ReturnsGiftCard} [returnsGiftCard]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReturnsSaleIdPaymentsGiftCardGiftCodePost(saleId: string, giftCode: string, returnsGiftCard?: ReturnsGiftCard, options?: any): AxiosPromise<ReturnBurnGiftCard1> {
            return localVarFp.apiV1ReturnsSaleIdPaymentsGiftCardGiftCodePost(saleId, giftCode, returnsGiftCard, options).then((request) => request(axios, basePath));
        },
        /**
         * Instructs the BE to issue a refund through the pos terminal
         * @summary Return Credit Card refund
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {ReturnsCCRefund} [returnsCCRefund]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReturnsSaleIdPaymentsPosPosTransactionIdPost(saleId: string, posTransactionId: string, returnsCCRefund?: ReturnsCCRefund, options?: any): AxiosPromise<CCPayment> {
            return localVarFp.apiV1ReturnsSaleIdPaymentsPosPosTransactionIdPost(saleId, posTransactionId, returnsCCRefund, options).then((request) => request(axios, basePath));
        },
        /**
         * Polling on the pos terminal status
         * @summary PosStatus
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {CCStatus} [cCStatus]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusPost(saleId: string, posTransactionId: string, cCStatus?: CCStatus, options?: any): AxiosPromise<CCPaymentResult> {
            return localVarFp.apiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusPost(saleId, posTransactionId, cCStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * Burn gift card used for payment
         * @summary Burn gift card used for payment
         * @param {string} saleId Sale unique id: cod_negozio,id_transazione
         * @param {string} giftCode Gift Card unique code
         * @param {BurnGiftCard1} [burnGiftCard1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdPaymentsGiftCardGiftCodePatch(saleId: string, giftCode: string, burnGiftCard1?: BurnGiftCard1, options?: any): AxiosPromise<ReturnBurnGiftCard> {
            return localVarFp.apiV1SalesSaleIdPaymentsGiftCardGiftCodePatch(saleId, giftCode, burnGiftCard1, options).then((request) => request(axios, basePath));
        },
        /**
         * Burn gift card used for payment
         * @summary Burn gift card used for payment
         * @param {string} saleId Sale unique id: cod_negozio,id_transazione&#x3D;0
         * @param {string} giftCode Gift Card unique code
         * @param {BurnGiftCard} [burnGiftCard]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdPaymentsGiftCardGiftCodePost(saleId: string, giftCode: string, burnGiftCard?: BurnGiftCard, options?: any): AxiosPromise<ReturnBurnGiftCard> {
            return localVarFp.apiV1SalesSaleIdPaymentsGiftCardGiftCodePost(saleId, giftCode, burnGiftCard, options).then((request) => request(axios, basePath));
        },
        /**
         * Instructs the BE to issue a payment on the pos terminal
         * @summary Sale Credit Card payment
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {SaleCCPayment} [saleCCPayment]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdPaymentsPosPosTransactionIdPost(saleId: string, posTransactionId: string, saleCCPayment?: SaleCCPayment, options?: any): AxiosPromise<CCPayment> {
            return localVarFp.apiV1SalesSaleIdPaymentsPosPosTransactionIdPost(saleId, posTransactionId, saleCCPayment, options).then((request) => request(axios, basePath));
        },
        /**
         * Instructs the BE to issue a payment pos terminal for a suspended credit
         * @summary Suspended Credit - Credit Card payment
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {SuspendedCreditCCPayment} [suspendedCreditCCPayment]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdPost(saleId: string, posTransactionId: string, suspendedCreditCCPayment?: SuspendedCreditCCPayment, options?: any): AxiosPromise<CCPayment> {
            return localVarFp.apiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdPost(saleId, posTransactionId, suspendedCreditCCPayment, options).then((request) => request(axios, basePath));
        },
        /**
         * Instructs the BE to issue a payment reversal on the pos terminal
         * @summary Sale Credit Card payment reversal
         * @param {string} saleId Sale unique id
         * @param {string} posTransactionId CC transaction unique id
         * @param {SaleCCPaymentReversal} [saleCCPaymentReversal]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdPaymentsReversalPosPosTransactionIdPost(saleId: string, posTransactionId: string, saleCCPaymentReversal?: SaleCCPaymentReversal, options?: any): AxiosPromise<CCPayment> {
            return localVarFp.apiV1SalesSaleIdPaymentsReversalPosPosTransactionIdPost(saleId, posTransactionId, saleCCPaymentReversal, options).then((request) => request(axios, basePath));
        },
        /**
         * Burn gift card used for payment
         * @summary Burn gift card used for payment
         * @param {string} saleId Sale unique id: cod_negozio,id_transazione
         * @param {string} giftCode Gift Card unique code
         * @param {BurnGiftCard2} [burnGiftCard2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SuspendedSaleIdPaymentsGiftCardGiftCodePost(saleId: string, giftCode: string, burnGiftCard2?: BurnGiftCard2, options?: any): AxiosPromise<GiftCardData1> {
            return localVarFp.apiV1SuspendedSaleIdPaymentsGiftCardGiftCodePost(saleId, giftCode, burnGiftCard2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
    /**
     * Get the list of possible CC usable circuits
     * @summary Avaiable Credit card circuits
     * @param {string} [filterCodiceCompagnia] Corporate credit card circuit code
     * @param {string} [filterDescrTipoCarta] Credit card circuit name
     * @param {string} [filterCodiceAquirer] Acquirer credit card circuit code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public apiV1CreditCardGet(filterCodiceCompagnia?: string, filterDescrTipoCarta?: string, filterCodiceAquirer?: string, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).apiV1CreditCardGet(filterCodiceCompagnia, filterDescrTipoCarta, filterCodiceAquirer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the list of allowed id types for a payment or a sale closure
     * @summary Allowed Ids
     * @param {string} [filterCodiceGruppo] Payment or document section
     * @param {string} [filterCodiceDocumento] Document id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public apiV1PoswebDocumentTypesGet(filterCodiceGruppo?: string, filterCodiceDocumento?: string, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).apiV1PoswebDocumentTypesGet(filterCodiceGruppo, filterCodiceDocumento, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Instructs the BE to issue a refund through the pos terminal
     * @summary Credit Card refund B2E
     * @param {string} saleId Sale unique id: codice_negozio,id_transazione
     * @param {string} posTransactionId CC transaction unique id
     * @param {ReturnsCCRefund1} [returnsCCRefund1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public apiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdPost(saleId: string, posTransactionId: string, returnsCCRefund1?: ReturnsCCRefund1, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).apiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdPost(saleId, posTransactionId, returnsCCRefund1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Payload: data necessary to return a sale, with mandatory key \"importo\" !  Il payload sarà lo stesso usato per la chiamata di creazione reso: POST /return  a cui deve essere aggiunto il campo \"importo\": valore immesso nella nuova gift card e negativo !
     * @summary Restore gift card used when do a return
     * @param {string} saleId Sale unique id: cod_negozio,id_transazione
     * @param {string} giftCode Gift Card unique code
     * @param {ReturnsGiftCard} [returnsGiftCard]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public apiV1ReturnsSaleIdPaymentsGiftCardGiftCodePost(saleId: string, giftCode: string, returnsGiftCard?: ReturnsGiftCard, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).apiV1ReturnsSaleIdPaymentsGiftCardGiftCodePost(saleId, giftCode, returnsGiftCard, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Instructs the BE to issue a refund through the pos terminal
     * @summary Return Credit Card refund
     * @param {string} saleId Sale unique id
     * @param {string} posTransactionId CC transaction unique id
     * @param {ReturnsCCRefund} [returnsCCRefund]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public apiV1ReturnsSaleIdPaymentsPosPosTransactionIdPost(saleId: string, posTransactionId: string, returnsCCRefund?: ReturnsCCRefund, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).apiV1ReturnsSaleIdPaymentsPosPosTransactionIdPost(saleId, posTransactionId, returnsCCRefund, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Polling on the pos terminal status
     * @summary PosStatus
     * @param {string} saleId Sale unique id
     * @param {string} posTransactionId CC transaction unique id
     * @param {CCStatus} [cCStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public apiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusPost(saleId: string, posTransactionId: string, cCStatus?: CCStatus, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).apiV1SalesSaleIdPaymentsCreditCardPosTransactionIdStatusPost(saleId, posTransactionId, cCStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Burn gift card used for payment
     * @summary Burn gift card used for payment
     * @param {string} saleId Sale unique id: cod_negozio,id_transazione
     * @param {string} giftCode Gift Card unique code
     * @param {BurnGiftCard1} [burnGiftCard1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public apiV1SalesSaleIdPaymentsGiftCardGiftCodePatch(saleId: string, giftCode: string, burnGiftCard1?: BurnGiftCard1, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).apiV1SalesSaleIdPaymentsGiftCardGiftCodePatch(saleId, giftCode, burnGiftCard1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Burn gift card used for payment
     * @summary Burn gift card used for payment
     * @param {string} saleId Sale unique id: cod_negozio,id_transazione&#x3D;0
     * @param {string} giftCode Gift Card unique code
     * @param {BurnGiftCard} [burnGiftCard]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public apiV1SalesSaleIdPaymentsGiftCardGiftCodePost(saleId: string, giftCode: string, burnGiftCard?: BurnGiftCard, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).apiV1SalesSaleIdPaymentsGiftCardGiftCodePost(saleId, giftCode, burnGiftCard, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Instructs the BE to issue a payment on the pos terminal
     * @summary Sale Credit Card payment
     * @param {string} saleId Sale unique id
     * @param {string} posTransactionId CC transaction unique id
     * @param {SaleCCPayment} [saleCCPayment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public apiV1SalesSaleIdPaymentsPosPosTransactionIdPost(saleId: string, posTransactionId: string, saleCCPayment?: SaleCCPayment, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).apiV1SalesSaleIdPaymentsPosPosTransactionIdPost(saleId, posTransactionId, saleCCPayment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Instructs the BE to issue a payment pos terminal for a suspended credit
     * @summary Suspended Credit - Credit Card payment
     * @param {string} saleId Sale unique id
     * @param {string} posTransactionId CC transaction unique id
     * @param {SuspendedCreditCCPayment} [suspendedCreditCCPayment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public apiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdPost(saleId: string, posTransactionId: string, suspendedCreditCCPayment?: SuspendedCreditCCPayment, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).apiV1SalesSaleIdPaymentsPosSuspendedPosTransactionIdPost(saleId, posTransactionId, suspendedCreditCCPayment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Instructs the BE to issue a payment reversal on the pos terminal
     * @summary Sale Credit Card payment reversal
     * @param {string} saleId Sale unique id
     * @param {string} posTransactionId CC transaction unique id
     * @param {SaleCCPaymentReversal} [saleCCPaymentReversal]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public apiV1SalesSaleIdPaymentsReversalPosPosTransactionIdPost(saleId: string, posTransactionId: string, saleCCPaymentReversal?: SaleCCPaymentReversal, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).apiV1SalesSaleIdPaymentsReversalPosPosTransactionIdPost(saleId, posTransactionId, saleCCPaymentReversal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Burn gift card used for payment
     * @summary Burn gift card used for payment
     * @param {string} saleId Sale unique id: cod_negozio,id_transazione
     * @param {string} giftCode Gift Card unique code
     * @param {BurnGiftCard2} [burnGiftCard2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public apiV1SuspendedSaleIdPaymentsGiftCardGiftCodePost(saleId: string, giftCode: string, burnGiftCard2?: BurnGiftCard2, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).apiV1SuspendedSaleIdPaymentsGiftCardGiftCodePost(saleId, giftCode, burnGiftCard2, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PettyCashApi - axios parameter creator
 * @export
 */
export const PettyCashApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Permette di recuperare la lista di tutti i documenti che sono ammissibili per il petty cash
         * @summary Lista dei documenti che sono ammissibili per il petty cash
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PettycashDocumentTypeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/pettycash/document-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Permette di cercare uno o più documenti petty cash impostando diversi parametri di ricerca
         * @summary Cerca i documenti petty cash
         * @param {PettyCashFilter} [pettyCashFilter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PettycashGetListPost: async (pettyCashFilter?: PettyCashFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/pettycash/get_list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pettyCashFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Permette di recuperare tutte le informazioni associate ad un documento petty cash
         * @summary Visualizza dati di un documento petty cash
         * @param {number} numeroDocumento numero del documento
         * @param {string} dataDocumento data del documento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PettycashNumeroDocumentoDataDocumentoGet: async (numeroDocumento: number, dataDocumento: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'numeroDocumento' is not null or undefined
            assertParamExists('apiV1PettycashNumeroDocumentoDataDocumentoGet', 'numeroDocumento', numeroDocumento)
            // verify required parameter 'dataDocumento' is not null or undefined
            assertParamExists('apiV1PettycashNumeroDocumentoDataDocumentoGet', 'dataDocumento', dataDocumento)
            const localVarPath = `/api/v1/pettycash/{numero_documento}/{data_documento}`
                .replace(`{${"numero_documento"}}`, encodeURIComponent(String(numeroDocumento)))
                .replace(`{${"data_documento"}}`, encodeURIComponent(String(dataDocumento)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Permette di modificare i dati di un documento petty cash
         * @summary Modificare dati di un documento petty cash
         * @param {number} numeroDocumento numero del documento
         * @param {string} dataDocumento data del documento
         * @param {PettyCashItem} [pettyCashItem]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PettycashNumeroDocumentoDataDocumentoPatch: async (numeroDocumento: number, dataDocumento: string, pettyCashItem?: PettyCashItem, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'numeroDocumento' is not null or undefined
            assertParamExists('apiV1PettycashNumeroDocumentoDataDocumentoPatch', 'numeroDocumento', numeroDocumento)
            // verify required parameter 'dataDocumento' is not null or undefined
            assertParamExists('apiV1PettycashNumeroDocumentoDataDocumentoPatch', 'dataDocumento', dataDocumento)
            const localVarPath = `/api/v1/pettycash/{numero_documento}/{data_documento}`
                .replace(`{${"numero_documento"}}`, encodeURIComponent(String(numeroDocumento)))
                .replace(`{${"data_documento"}}`, encodeURIComponent(String(dataDocumento)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pettyCashItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Permette di cancellare i dati di un documento petty cash
         * @summary Cancella dati di un documento petty cash
         * @param {number} numeroDocumento numero del documento
         * @param {PettyCashDelete} [pettyCashDelete]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PettycashNumeroDocumentoDelete: async (numeroDocumento: number, pettyCashDelete?: PettyCashDelete, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'numeroDocumento' is not null or undefined
            assertParamExists('apiV1PettycashNumeroDocumentoDelete', 'numeroDocumento', numeroDocumento)
            const localVarPath = `/api/v1/pettycash/{numero_documento}`
                .replace(`{${"numero_documento"}}`, encodeURIComponent(String(numeroDocumento)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pettyCashDelete, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Permette di inserire un nuovo documento petty cash
         * @summary Permette di inserire un documento petty cash
         * @param {PettyCashItem} [pettyCashItem]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PettycashPost: async (pettyCashItem?: PettyCashItem, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/pettycash`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pettyCashItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Permette di recuperare le informazioni del negozio per la corretta intestazione di Fatture o assegni
         * @summary Informazioni sul negozio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PettycashStoreInfoGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/pettycash/store_info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PettyCashApi - functional programming interface
 * @export
 */
export const PettyCashApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PettyCashApiAxiosParamCreator(configuration)
    return {
        /**
         * Permette di recuperare la lista di tutti i documenti che sono ammissibili per il petty cash
         * @summary Lista dei documenti che sono ammissibili per il petty cash
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PettycashDocumentTypeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PettyCashList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PettycashDocumentTypeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Permette di cercare uno o più documenti petty cash impostando diversi parametri di ricerca
         * @summary Cerca i documenti petty cash
         * @param {PettyCashFilter} [pettyCashFilter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PettycashGetListPost(pettyCashFilter?: PettyCashFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PettyCashListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PettycashGetListPost(pettyCashFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Permette di recuperare tutte le informazioni associate ad un documento petty cash
         * @summary Visualizza dati di un documento petty cash
         * @param {number} numeroDocumento numero del documento
         * @param {string} dataDocumento data del documento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PettycashNumeroDocumentoDataDocumentoGet(numeroDocumento: number, dataDocumento: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PettyCashItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PettycashNumeroDocumentoDataDocumentoGet(numeroDocumento, dataDocumento, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Permette di modificare i dati di un documento petty cash
         * @summary Modificare dati di un documento petty cash
         * @param {number} numeroDocumento numero del documento
         * @param {string} dataDocumento data del documento
         * @param {PettyCashItem} [pettyCashItem]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PettycashNumeroDocumentoDataDocumentoPatch(numeroDocumento: number, dataDocumento: string, pettyCashItem?: PettyCashItem, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PettycashNumeroDocumentoDataDocumentoPatch(numeroDocumento, dataDocumento, pettyCashItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Permette di cancellare i dati di un documento petty cash
         * @summary Cancella dati di un documento petty cash
         * @param {number} numeroDocumento numero del documento
         * @param {PettyCashDelete} [pettyCashDelete]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PettycashNumeroDocumentoDelete(numeroDocumento: number, pettyCashDelete?: PettyCashDelete, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PettyCashItemDeletedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PettycashNumeroDocumentoDelete(numeroDocumento, pettyCashDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Permette di inserire un nuovo documento petty cash
         * @summary Permette di inserire un documento petty cash
         * @param {PettyCashItem} [pettyCashItem]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PettycashPost(pettyCashItem?: PettyCashItem, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PettyCashItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PettycashPost(pettyCashItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Permette di recuperare le informazioni del negozio per la corretta intestazione di Fatture o assegni
         * @summary Informazioni sul negozio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PettycashStoreInfoGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PettycashStoreInfoGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PettyCashApi - factory interface
 * @export
 */
export const PettyCashApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PettyCashApiFp(configuration)
    return {
        /**
         * Permette di recuperare la lista di tutti i documenti che sono ammissibili per il petty cash
         * @summary Lista dei documenti che sono ammissibili per il petty cash
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PettycashDocumentTypeGet(options?: any): AxiosPromise<PettyCashList> {
            return localVarFp.apiV1PettycashDocumentTypeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Permette di cercare uno o più documenti petty cash impostando diversi parametri di ricerca
         * @summary Cerca i documenti petty cash
         * @param {PettyCashFilter} [pettyCashFilter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PettycashGetListPost(pettyCashFilter?: PettyCashFilter, options?: any): AxiosPromise<PettyCashListResponse> {
            return localVarFp.apiV1PettycashGetListPost(pettyCashFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Permette di recuperare tutte le informazioni associate ad un documento petty cash
         * @summary Visualizza dati di un documento petty cash
         * @param {number} numeroDocumento numero del documento
         * @param {string} dataDocumento data del documento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PettycashNumeroDocumentoDataDocumentoGet(numeroDocumento: number, dataDocumento: string, options?: any): AxiosPromise<PettyCashItemResponse> {
            return localVarFp.apiV1PettycashNumeroDocumentoDataDocumentoGet(numeroDocumento, dataDocumento, options).then((request) => request(axios, basePath));
        },
        /**
         * Permette di modificare i dati di un documento petty cash
         * @summary Modificare dati di un documento petty cash
         * @param {number} numeroDocumento numero del documento
         * @param {string} dataDocumento data del documento
         * @param {PettyCashItem} [pettyCashItem]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PettycashNumeroDocumentoDataDocumentoPatch(numeroDocumento: number, dataDocumento: string, pettyCashItem?: PettyCashItem, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1PettycashNumeroDocumentoDataDocumentoPatch(numeroDocumento, dataDocumento, pettyCashItem, options).then((request) => request(axios, basePath));
        },
        /**
         * Permette di cancellare i dati di un documento petty cash
         * @summary Cancella dati di un documento petty cash
         * @param {number} numeroDocumento numero del documento
         * @param {PettyCashDelete} [pettyCashDelete]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PettycashNumeroDocumentoDelete(numeroDocumento: number, pettyCashDelete?: PettyCashDelete, options?: any): AxiosPromise<PettyCashItemDeletedResponse> {
            return localVarFp.apiV1PettycashNumeroDocumentoDelete(numeroDocumento, pettyCashDelete, options).then((request) => request(axios, basePath));
        },
        /**
         * Permette di inserire un nuovo documento petty cash
         * @summary Permette di inserire un documento petty cash
         * @param {PettyCashItem} [pettyCashItem]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PettycashPost(pettyCashItem?: PettyCashItem, options?: any): AxiosPromise<PettyCashItemResponse> {
            return localVarFp.apiV1PettycashPost(pettyCashItem, options).then((request) => request(axios, basePath));
        },
        /**
         * Permette di recuperare le informazioni del negozio per la corretta intestazione di Fatture o assegni
         * @summary Informazioni sul negozio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PettycashStoreInfoGet(options?: any): AxiosPromise<StoreInfo> {
            return localVarFp.apiV1PettycashStoreInfoGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PettyCashApi - object-oriented interface
 * @export
 * @class PettyCashApi
 * @extends {BaseAPI}
 */
export class PettyCashApi extends BaseAPI {
    /**
     * Permette di recuperare la lista di tutti i documenti che sono ammissibili per il petty cash
     * @summary Lista dei documenti che sono ammissibili per il petty cash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PettyCashApi
     */
    public apiV1PettycashDocumentTypeGet(options?: AxiosRequestConfig) {
        return PettyCashApiFp(this.configuration).apiV1PettycashDocumentTypeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Permette di cercare uno o più documenti petty cash impostando diversi parametri di ricerca
     * @summary Cerca i documenti petty cash
     * @param {PettyCashFilter} [pettyCashFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PettyCashApi
     */
    public apiV1PettycashGetListPost(pettyCashFilter?: PettyCashFilter, options?: AxiosRequestConfig) {
        return PettyCashApiFp(this.configuration).apiV1PettycashGetListPost(pettyCashFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Permette di recuperare tutte le informazioni associate ad un documento petty cash
     * @summary Visualizza dati di un documento petty cash
     * @param {number} numeroDocumento numero del documento
     * @param {string} dataDocumento data del documento
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PettyCashApi
     */
    public apiV1PettycashNumeroDocumentoDataDocumentoGet(numeroDocumento: number, dataDocumento: string, options?: AxiosRequestConfig) {
        return PettyCashApiFp(this.configuration).apiV1PettycashNumeroDocumentoDataDocumentoGet(numeroDocumento, dataDocumento, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Permette di modificare i dati di un documento petty cash
     * @summary Modificare dati di un documento petty cash
     * @param {number} numeroDocumento numero del documento
     * @param {string} dataDocumento data del documento
     * @param {PettyCashItem} [pettyCashItem]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PettyCashApi
     */
    public apiV1PettycashNumeroDocumentoDataDocumentoPatch(numeroDocumento: number, dataDocumento: string, pettyCashItem?: PettyCashItem, options?: AxiosRequestConfig) {
        return PettyCashApiFp(this.configuration).apiV1PettycashNumeroDocumentoDataDocumentoPatch(numeroDocumento, dataDocumento, pettyCashItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Permette di cancellare i dati di un documento petty cash
     * @summary Cancella dati di un documento petty cash
     * @param {number} numeroDocumento numero del documento
     * @param {PettyCashDelete} [pettyCashDelete]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PettyCashApi
     */
    public apiV1PettycashNumeroDocumentoDelete(numeroDocumento: number, pettyCashDelete?: PettyCashDelete, options?: AxiosRequestConfig) {
        return PettyCashApiFp(this.configuration).apiV1PettycashNumeroDocumentoDelete(numeroDocumento, pettyCashDelete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Permette di inserire un nuovo documento petty cash
     * @summary Permette di inserire un documento petty cash
     * @param {PettyCashItem} [pettyCashItem]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PettyCashApi
     */
    public apiV1PettycashPost(pettyCashItem?: PettyCashItem, options?: AxiosRequestConfig) {
        return PettyCashApiFp(this.configuration).apiV1PettycashPost(pettyCashItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Permette di recuperare le informazioni del negozio per la corretta intestazione di Fatture o assegni
     * @summary Informazioni sul negozio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PettyCashApi
     */
    public apiV1PettycashStoreInfoGet(options?: AxiosRequestConfig) {
        return PettyCashApiFp(this.configuration).apiV1PettycashStoreInfoGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PromoEngineApi - axios parameter creator
 * @export
 */
export const PromoEngineApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Recupera l\'elenco delle promozioni attive sul negozio.
         * @summary List active promotions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PromoEngineV2ListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/promo_engine_v2_list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromoEngineApi - functional programming interface
 * @export
 */
export const PromoEngineApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromoEngineApiAxiosParamCreator(configuration)
    return {
        /**
         * Recupera l\'elenco delle promozioni attive sul negozio.
         * @summary List active promotions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PromoEngineV2ListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPromoEnginePromotionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PromoEngineV2ListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PromoEngineApi - factory interface
 * @export
 */
export const PromoEngineApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromoEngineApiFp(configuration)
    return {
        /**
         * Recupera l\'elenco delle promozioni attive sul negozio.
         * @summary List active promotions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PromoEngineV2ListGet(options?: any): AxiosPromise<GetPromoEnginePromotionList> {
            return localVarFp.apiV1PromoEngineV2ListGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromoEngineApi - object-oriented interface
 * @export
 * @class PromoEngineApi
 * @extends {BaseAPI}
 */
export class PromoEngineApi extends BaseAPI {
    /**
     * Recupera l\'elenco delle promozioni attive sul negozio.
     * @summary List active promotions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromoEngineApi
     */
    public apiV1PromoEngineV2ListGet(options?: AxiosRequestConfig) {
        return PromoEngineApiFp(this.configuration).apiV1PromoEngineV2ListGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RFIDApi - axios parameter creator
 * @export
 */
export const RFIDApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Oltre all\'azione sull\'impianto Rfid, sono effettuate anche le operazioni sui dati in start e reset: azzeramento contenuto cart
         * @summary Azione per attivare/disattivare antenna RFID
         * @param {'start' | 'stop' | 'reset'} action action for Rfid antenna
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CartActionActionPut: async (action: 'start' | 'stop' | 'reset', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'action' is not null or undefined
            assertParamExists('apiV1CartActionActionPut', 'action', action)
            const localVarPath = `/api/v1/cart/action/{action}`
                .replace(`{${"action"}}`, encodeURIComponent(String(action)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Recupera i capi letti con l\'RFID
         * @summary Recupera i capi letti con l\'RFID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CartGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/cart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina i record dei capi dalla transazione
         * @summary Elimina i record dei capi dalla transazione
         * @param {number} idTransazione ID transazione
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CartIdTransazioneDelete: async (idTransazione: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idTransazione' is not null or undefined
            assertParamExists('apiV1CartIdTransazioneDelete', 'idTransazione', idTransazione)
            const localVarPath = `/api/v1/cart/{id_transazione}`
                .replace(`{${"id_transazione"}}`, encodeURIComponent(String(idTransazione)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lo stato passa da New a Cart per gli rfid corretti (sezione \"ok\"), mentre passa da New a Error per gli altri (sezione \"ko\")
         * @summary Modifica lo stato dei capi letti con l\'RFID
         * @param {DatiCorrezioneStatoItemRfid} [datiCorrezioneStatoItemRfid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CartPut: async (datiCorrezioneStatoItemRfid?: DatiCorrezioneStatoItemRfid, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/cart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datiCorrezioneStatoItemRfid, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RFIDApi - functional programming interface
 * @export
 */
export const RFIDApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RFIDApiAxiosParamCreator(configuration)
    return {
        /**
         * Oltre all\'azione sull\'impianto Rfid, sono effettuate anche le operazioni sui dati in start e reset: azzeramento contenuto cart
         * @summary Azione per attivare/disattivare antenna RFID
         * @param {'start' | 'stop' | 'reset'} action action for Rfid antenna
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CartActionActionPut(action: 'start' | 'stop' | 'reset', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CartActionActionPut(action, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Recupera i capi letti con l\'RFID
         * @summary Recupera i capi letti con l\'RFID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CartGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListaDeiCapi>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CartGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina i record dei capi dalla transazione
         * @summary Elimina i record dei capi dalla transazione
         * @param {number} idTransazione ID transazione
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CartIdTransazioneDelete(idTransazione: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListaDeiNuoviTotaliTransazione>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CartIdTransazioneDelete(idTransazione, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lo stato passa da New a Cart per gli rfid corretti (sezione \"ok\"), mentre passa da New a Error per gli altri (sezione \"ko\")
         * @summary Modifica lo stato dei capi letti con l\'RFID
         * @param {DatiCorrezioneStatoItemRfid} [datiCorrezioneStatoItemRfid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CartPut(datiCorrezioneStatoItemRfid?: DatiCorrezioneStatoItemRfid, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListaDeiCapi1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CartPut(datiCorrezioneStatoItemRfid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RFIDApi - factory interface
 * @export
 */
export const RFIDApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RFIDApiFp(configuration)
    return {
        /**
         * Oltre all\'azione sull\'impianto Rfid, sono effettuate anche le operazioni sui dati in start e reset: azzeramento contenuto cart
         * @summary Azione per attivare/disattivare antenna RFID
         * @param {'start' | 'stop' | 'reset'} action action for Rfid antenna
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CartActionActionPut(action: 'start' | 'stop' | 'reset', options?: any): AxiosPromise<ActionResult> {
            return localVarFp.apiV1CartActionActionPut(action, options).then((request) => request(axios, basePath));
        },
        /**
         * Recupera i capi letti con l\'RFID
         * @summary Recupera i capi letti con l\'RFID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CartGet(options?: any): AxiosPromise<ListaDeiCapi> {
            return localVarFp.apiV1CartGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina i record dei capi dalla transazione
         * @summary Elimina i record dei capi dalla transazione
         * @param {number} idTransazione ID transazione
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CartIdTransazioneDelete(idTransazione: number, options?: any): AxiosPromise<ListaDeiNuoviTotaliTransazione> {
            return localVarFp.apiV1CartIdTransazioneDelete(idTransazione, options).then((request) => request(axios, basePath));
        },
        /**
         * Lo stato passa da New a Cart per gli rfid corretti (sezione \"ok\"), mentre passa da New a Error per gli altri (sezione \"ko\")
         * @summary Modifica lo stato dei capi letti con l\'RFID
         * @param {DatiCorrezioneStatoItemRfid} [datiCorrezioneStatoItemRfid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CartPut(datiCorrezioneStatoItemRfid?: DatiCorrezioneStatoItemRfid, options?: any): AxiosPromise<ListaDeiCapi1> {
            return localVarFp.apiV1CartPut(datiCorrezioneStatoItemRfid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RFIDApi - object-oriented interface
 * @export
 * @class RFIDApi
 * @extends {BaseAPI}
 */
export class RFIDApi extends BaseAPI {
    /**
     * Oltre all\'azione sull\'impianto Rfid, sono effettuate anche le operazioni sui dati in start e reset: azzeramento contenuto cart
     * @summary Azione per attivare/disattivare antenna RFID
     * @param {'start' | 'stop' | 'reset'} action action for Rfid antenna
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RFIDApi
     */
    public apiV1CartActionActionPut(action: 'start' | 'stop' | 'reset', options?: AxiosRequestConfig) {
        return RFIDApiFp(this.configuration).apiV1CartActionActionPut(action, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Recupera i capi letti con l\'RFID
     * @summary Recupera i capi letti con l\'RFID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RFIDApi
     */
    public apiV1CartGet(options?: AxiosRequestConfig) {
        return RFIDApiFp(this.configuration).apiV1CartGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina i record dei capi dalla transazione
     * @summary Elimina i record dei capi dalla transazione
     * @param {number} idTransazione ID transazione
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RFIDApi
     */
    public apiV1CartIdTransazioneDelete(idTransazione: number, options?: AxiosRequestConfig) {
        return RFIDApiFp(this.configuration).apiV1CartIdTransazioneDelete(idTransazione, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lo stato passa da New a Cart per gli rfid corretti (sezione \"ok\"), mentre passa da New a Error per gli altri (sezione \"ko\")
     * @summary Modifica lo stato dei capi letti con l\'RFID
     * @param {DatiCorrezioneStatoItemRfid} [datiCorrezioneStatoItemRfid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RFIDApi
     */
    public apiV1CartPut(datiCorrezioneStatoItemRfid?: DatiCorrezioneStatoItemRfid, options?: AxiosRequestConfig) {
        return RFIDApiFp(this.configuration).apiV1CartPut(datiCorrezioneStatoItemRfid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReintegriApi - axios parameter creator
 * @export
 */
export const ReintegriApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the types of documents that exist for petty cash
         * @summary Document Types Reintegri
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReintegriDocumentTypeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reintegri/document-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a list of petty cash with filters
         * @summary Petty cash list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReintegriGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reintegri`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a petty cash with year and number of document
         * @summary Get petty cash detail
         * @param {string} key aano,numero_documento reintegro
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReintegriKeyGet: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('apiV1ReintegriKeyGet', 'key', key)
            const localVarPath = `/api/v1/reintegri/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST a new petty cash (with relative body)
         * @summary New petty cash
         * @param {PostPettyCash} [postPettyCash]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReintegriPost: async (postPettyCash?: PostPettyCash, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reintegri`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postPettyCash, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Print a petty cash with complete date and number of document (e.g. 20221202, 1)
         * @summary Print petty cash
         * @param {string} key id reintegro
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReintegriStampaKeyGet: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('apiV1ReintegriStampaKeyGet', 'key', key)
            const localVarPath = `/api/v1/reintegri/stampa/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReintegriApi - functional programming interface
 * @export
 */
export const ReintegriApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReintegriApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the types of documents that exist for petty cash
         * @summary Document Types Reintegri
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReintegriDocumentTypeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocTypesReintegri>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReintegriDocumentTypeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a list of petty cash with filters
         * @summary Petty cash list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReintegriGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPettyCashList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReintegriGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a petty cash with year and number of document
         * @summary Get petty cash detail
         * @param {string} key aano,numero_documento reintegro
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReintegriKeyGet(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPettyCashDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReintegriKeyGet(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST a new petty cash (with relative body)
         * @summary New petty cash
         * @param {PostPettyCash} [postPettyCash]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReintegriPost(postPettyCash?: PostPettyCash, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReintegriPost(postPettyCash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Print a petty cash with complete date and number of document (e.g. 20221202, 1)
         * @summary Print petty cash
         * @param {string} key id reintegro
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReintegriStampaKeyGet(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReintegriStampaKeyGet(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReintegriApi - factory interface
 * @export
 */
export const ReintegriApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReintegriApiFp(configuration)
    return {
        /**
         * Get the types of documents that exist for petty cash
         * @summary Document Types Reintegri
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReintegriDocumentTypeGet(options?: any): AxiosPromise<GetDocTypesReintegri> {
            return localVarFp.apiV1ReintegriDocumentTypeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get a list of petty cash with filters
         * @summary Petty cash list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReintegriGet(options?: any): AxiosPromise<GetPettyCashList> {
            return localVarFp.apiV1ReintegriGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get a petty cash with year and number of document
         * @summary Get petty cash detail
         * @param {string} key aano,numero_documento reintegro
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReintegriKeyGet(key: string, options?: any): AxiosPromise<GetPettyCashDetail> {
            return localVarFp.apiV1ReintegriKeyGet(key, options).then((request) => request(axios, basePath));
        },
        /**
         * POST a new petty cash (with relative body)
         * @summary New petty cash
         * @param {PostPettyCash} [postPettyCash]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReintegriPost(postPettyCash?: PostPettyCash, options?: any): AxiosPromise<object> {
            return localVarFp.apiV1ReintegriPost(postPettyCash, options).then((request) => request(axios, basePath));
        },
        /**
         * Print a petty cash with complete date and number of document (e.g. 20221202, 1)
         * @summary Print petty cash
         * @param {string} key id reintegro
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReintegriStampaKeyGet(key: string, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.apiV1ReintegriStampaKeyGet(key, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReintegriApi - object-oriented interface
 * @export
 * @class ReintegriApi
 * @extends {BaseAPI}
 */
export class ReintegriApi extends BaseAPI {
    /**
     * Get the types of documents that exist for petty cash
     * @summary Document Types Reintegri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReintegriApi
     */
    public apiV1ReintegriDocumentTypeGet(options?: AxiosRequestConfig) {
        return ReintegriApiFp(this.configuration).apiV1ReintegriDocumentTypeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a list of petty cash with filters
     * @summary Petty cash list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReintegriApi
     */
    public apiV1ReintegriGet(options?: AxiosRequestConfig) {
        return ReintegriApiFp(this.configuration).apiV1ReintegriGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a petty cash with year and number of document
     * @summary Get petty cash detail
     * @param {string} key aano,numero_documento reintegro
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReintegriApi
     */
    public apiV1ReintegriKeyGet(key: string, options?: AxiosRequestConfig) {
        return ReintegriApiFp(this.configuration).apiV1ReintegriKeyGet(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST a new petty cash (with relative body)
     * @summary New petty cash
     * @param {PostPettyCash} [postPettyCash]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReintegriApi
     */
    public apiV1ReintegriPost(postPettyCash?: PostPettyCash, options?: AxiosRequestConfig) {
        return ReintegriApiFp(this.configuration).apiV1ReintegriPost(postPettyCash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Print a petty cash with complete date and number of document (e.g. 20221202, 1)
     * @summary Print petty cash
     * @param {string} key id reintegro
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReintegriApi
     */
    public apiV1ReintegriStampaKeyGet(key: string, options?: AxiosRequestConfig) {
        return ReintegriApiFp(this.configuration).apiV1ReintegriStampaKeyGet(key, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ResiApi - axios parameter creator
 * @export
 */
export const ResiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Check barcode validity
         * @summary Check barcode validity
         * @param {string} barcode The Barcode of the ticket
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReturnBarcodeBarcodeGet: async (barcode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'barcode' is not null or undefined
            assertParamExists('apiV1ReturnBarcodeBarcodeGet', 'barcode', barcode)
            const localVarPath = `/api/v1/return/barcode/{barcode}`
                .replace(`{${"barcode"}}`, encodeURIComponent(String(barcode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cancel a return
         * @summary Cancel a return
         * @param {FullReturnData} [fullReturnData]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReturnDelete: async (fullReturnData?: FullReturnData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/return`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fullReturnData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # List available sells.  Search can be performed using those filter combination: * date_from * date_to * pk_consumer * num * store * sku * b2e * is_anonima
         * @summary Elenco vendite rendibili
         * @param {string} [filterDateFrom] Date in the format yyyy-mm-dd
         * @param {string} [filterDateTo] Date in the format yyyy-mm-dd
         * @param {string} [filterPkConsumer] code of the consumer
         * @param {string} [filterSku] code of the product sold
         * @param {number} [filterNum] number of the ticket
         * @param {string} [filterStore] code of the store
         * @param {'empty' | 'B2E' | 'NEGOZIO'} [filterB2e] to do
         * @param {1 | 0} [filterIsAnonima] anonymous consumer or not
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReturnGet: async (filterDateFrom?: string, filterDateTo?: string, filterPkConsumer?: string, filterSku?: string, filterNum?: number, filterStore?: string, filterB2e?: 'empty' | 'B2E' | 'NEGOZIO', filterIsAnonima?: 1 | 0, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/return`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterDateFrom !== undefined) {
                localVarQueryParameter['filter[date_from]'] = filterDateFrom;
            }

            if (filterDateTo !== undefined) {
                localVarQueryParameter['filter[date_to]'] = filterDateTo;
            }

            if (filterPkConsumer !== undefined) {
                localVarQueryParameter['filter[pk_consumer]'] = filterPkConsumer;
            }

            if (filterSku !== undefined) {
                localVarQueryParameter['filter[sku]'] = filterSku;
            }

            if (filterNum !== undefined) {
                localVarQueryParameter['filter[num]'] = filterNum;
            }

            if (filterStore !== undefined) {
                localVarQueryParameter['filter[store]'] = filterStore;
            }

            if (filterB2e !== undefined) {
                localVarQueryParameter['filter[b2e]'] = filterB2e;
            }

            if (filterIsAnonima !== undefined) {
                localVarQueryParameter['filter[is_anonima]'] = filterIsAnonima;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * confirm the return with all \"reso\" collected data
         * @summary Confirm a return
         * @param {FullResoData} [fullResoData]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReturnPatch: async (fullResoData?: FullResoData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/return`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fullResoData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new return sending ticket
         * @summary Crea un nuovo reso
         * @param {Transaction} [transaction]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReturnPost: async (transaction?: Transaction, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/return`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(transaction, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of store
         * @summary Get  list of stores
         * @param {string} [filterSearch] The search parameter is used to look into \&quot;cod_negozio_anag, tipo_negozio, citta\&quot; fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StoresGet: async (filterSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/stores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter[search]'] = filterSearch;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResiApi - functional programming interface
 * @export
 */
export const ResiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ResiApiAxiosParamCreator(configuration)
    return {
        /**
         * Check barcode validity
         * @summary Check barcode validity
         * @param {string} barcode The Barcode of the ticket
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReturnBarcodeBarcodeGet(barcode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Values>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReturnBarcodeBarcodeGet(barcode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Cancel a return
         * @summary Cancel a return
         * @param {FullReturnData} [fullReturnData]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReturnDelete(fullReturnData?: FullReturnData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReturnDelete(fullReturnData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # List available sells.  Search can be performed using those filter combination: * date_from * date_to * pk_consumer * num * store * sku * b2e * is_anonima
         * @summary Elenco vendite rendibili
         * @param {string} [filterDateFrom] Date in the format yyyy-mm-dd
         * @param {string} [filterDateTo] Date in the format yyyy-mm-dd
         * @param {string} [filterPkConsumer] code of the consumer
         * @param {string} [filterSku] code of the product sold
         * @param {number} [filterNum] number of the ticket
         * @param {string} [filterStore] code of the store
         * @param {'empty' | 'B2E' | 'NEGOZIO'} [filterB2e] to do
         * @param {1 | 0} [filterIsAnonima] anonymous consumer or not
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReturnGet(filterDateFrom?: string, filterDateTo?: string, filterPkConsumer?: string, filterSku?: string, filterNum?: number, filterStore?: string, filterB2e?: 'empty' | 'B2E' | 'NEGOZIO', filterIsAnonima?: 1 | 0, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sale3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReturnGet(filterDateFrom, filterDateTo, filterPkConsumer, filterSku, filterNum, filterStore, filterB2e, filterIsAnonima, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * confirm the return with all \"reso\" collected data
         * @summary Confirm a return
         * @param {FullResoData} [fullResoData]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReturnPatch(fullResoData?: FullResoData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReturnPatch(fullResoData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new return sending ticket
         * @summary Crea un nuovo reso
         * @param {Transaction} [transaction]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReturnPost(transaction?: Transaction, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullReturnData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReturnPost(transaction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list of store
         * @summary Get  list of stores
         * @param {string} [filterSearch] The search parameter is used to look into \&quot;cod_negozio_anag, tipo_negozio, citta\&quot; fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StoresGet(filterSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Return>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StoresGet(filterSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ResiApi - factory interface
 * @export
 */
export const ResiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ResiApiFp(configuration)
    return {
        /**
         * Check barcode validity
         * @summary Check barcode validity
         * @param {string} barcode The Barcode of the ticket
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReturnBarcodeBarcodeGet(barcode: string, options?: any): AxiosPromise<Values> {
            return localVarFp.apiV1ReturnBarcodeBarcodeGet(barcode, options).then((request) => request(axios, basePath));
        },
        /**
         * Cancel a return
         * @summary Cancel a return
         * @param {FullReturnData} [fullReturnData]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReturnDelete(fullReturnData?: FullReturnData, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ReturnDelete(fullReturnData, options).then((request) => request(axios, basePath));
        },
        /**
         * # List available sells.  Search can be performed using those filter combination: * date_from * date_to * pk_consumer * num * store * sku * b2e * is_anonima
         * @summary Elenco vendite rendibili
         * @param {string} [filterDateFrom] Date in the format yyyy-mm-dd
         * @param {string} [filterDateTo] Date in the format yyyy-mm-dd
         * @param {string} [filterPkConsumer] code of the consumer
         * @param {string} [filterSku] code of the product sold
         * @param {number} [filterNum] number of the ticket
         * @param {string} [filterStore] code of the store
         * @param {'empty' | 'B2E' | 'NEGOZIO'} [filterB2e] to do
         * @param {1 | 0} [filterIsAnonima] anonymous consumer or not
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReturnGet(filterDateFrom?: string, filterDateTo?: string, filterPkConsumer?: string, filterSku?: string, filterNum?: number, filterStore?: string, filterB2e?: 'empty' | 'B2E' | 'NEGOZIO', filterIsAnonima?: 1 | 0, options?: any): AxiosPromise<Sale3> {
            return localVarFp.apiV1ReturnGet(filterDateFrom, filterDateTo, filterPkConsumer, filterSku, filterNum, filterStore, filterB2e, filterIsAnonima, options).then((request) => request(axios, basePath));
        },
        /**
         * confirm the return with all \"reso\" collected data
         * @summary Confirm a return
         * @param {FullResoData} [fullResoData]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReturnPatch(fullResoData?: FullResoData, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ReturnPatch(fullResoData, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new return sending ticket
         * @summary Crea un nuovo reso
         * @param {Transaction} [transaction]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReturnPost(transaction?: Transaction, options?: any): AxiosPromise<FullReturnData> {
            return localVarFp.apiV1ReturnPost(transaction, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of store
         * @summary Get  list of stores
         * @param {string} [filterSearch] The search parameter is used to look into \&quot;cod_negozio_anag, tipo_negozio, citta\&quot; fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StoresGet(filterSearch?: string, options?: any): AxiosPromise<Return> {
            return localVarFp.apiV1StoresGet(filterSearch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ResiApi - object-oriented interface
 * @export
 * @class ResiApi
 * @extends {BaseAPI}
 */
export class ResiApi extends BaseAPI {
    /**
     * Check barcode validity
     * @summary Check barcode validity
     * @param {string} barcode The Barcode of the ticket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResiApi
     */
    public apiV1ReturnBarcodeBarcodeGet(barcode: string, options?: AxiosRequestConfig) {
        return ResiApiFp(this.configuration).apiV1ReturnBarcodeBarcodeGet(barcode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cancel a return
     * @summary Cancel a return
     * @param {FullReturnData} [fullReturnData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResiApi
     */
    public apiV1ReturnDelete(fullReturnData?: FullReturnData, options?: AxiosRequestConfig) {
        return ResiApiFp(this.configuration).apiV1ReturnDelete(fullReturnData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # List available sells.  Search can be performed using those filter combination: * date_from * date_to * pk_consumer * num * store * sku * b2e * is_anonima
     * @summary Elenco vendite rendibili
     * @param {string} [filterDateFrom] Date in the format yyyy-mm-dd
     * @param {string} [filterDateTo] Date in the format yyyy-mm-dd
     * @param {string} [filterPkConsumer] code of the consumer
     * @param {string} [filterSku] code of the product sold
     * @param {number} [filterNum] number of the ticket
     * @param {string} [filterStore] code of the store
     * @param {'empty' | 'B2E' | 'NEGOZIO'} [filterB2e] to do
     * @param {1 | 0} [filterIsAnonima] anonymous consumer or not
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResiApi
     */
    public apiV1ReturnGet(filterDateFrom?: string, filterDateTo?: string, filterPkConsumer?: string, filterSku?: string, filterNum?: number, filterStore?: string, filterB2e?: 'empty' | 'B2E' | 'NEGOZIO', filterIsAnonima?: 1 | 0, options?: AxiosRequestConfig) {
        return ResiApiFp(this.configuration).apiV1ReturnGet(filterDateFrom, filterDateTo, filterPkConsumer, filterSku, filterNum, filterStore, filterB2e, filterIsAnonima, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * confirm the return with all \"reso\" collected data
     * @summary Confirm a return
     * @param {FullResoData} [fullResoData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResiApi
     */
    public apiV1ReturnPatch(fullResoData?: FullResoData, options?: AxiosRequestConfig) {
        return ResiApiFp(this.configuration).apiV1ReturnPatch(fullResoData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new return sending ticket
     * @summary Crea un nuovo reso
     * @param {Transaction} [transaction]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResiApi
     */
    public apiV1ReturnPost(transaction?: Transaction, options?: AxiosRequestConfig) {
        return ResiApiFp(this.configuration).apiV1ReturnPost(transaction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of store
     * @summary Get  list of stores
     * @param {string} [filterSearch] The search parameter is used to look into \&quot;cod_negozio_anag, tipo_negozio, citta\&quot; fields
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResiApi
     */
    public apiV1StoresGet(filterSearch?: string, options?: AxiosRequestConfig) {
        return ResiApiFp(this.configuration).apiV1StoresGet(filterSearch, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SaleApi - axios parameter creator
 * @export
 */
export const SaleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the list of allowed id types for a payment or a sale closure
         * @summary Allowed Ids
         * @param {string} [filterCodiceGruppo] Payment or document section
         * @param {string} [filterCodiceDocumento] Document id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebDocumentTypesGet: async (filterCodiceGruppo?: string, filterCodiceDocumento?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/document-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterCodiceGruppo !== undefined) {
                localVarQueryParameter['filter[codice_gruppo]'] = filterCodiceGruppo;
            }

            if (filterCodiceDocumento !== undefined) {
                localVarQueryParameter['filter[codice_documento]'] = filterCodiceDocumento;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SaleApi - functional programming interface
 * @export
 */
export const SaleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SaleApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the list of allowed id types for a payment or a sale closure
         * @summary Allowed Ids
         * @param {string} [filterCodiceGruppo] Payment or document section
         * @param {string} [filterCodiceDocumento] Document id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebDocumentTypesGet(filterCodiceGruppo?: string, filterCodiceDocumento?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCreditCardCircuits1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebDocumentTypesGet(filterCodiceGruppo, filterCodiceDocumento, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SaleApi - factory interface
 * @export
 */
export const SaleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SaleApiFp(configuration)
    return {
        /**
         * Get the list of allowed id types for a payment or a sale closure
         * @summary Allowed Ids
         * @param {string} [filterCodiceGruppo] Payment or document section
         * @param {string} [filterCodiceDocumento] Document id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebDocumentTypesGet(filterCodiceGruppo?: string, filterCodiceDocumento?: string, options?: any): AxiosPromise<GetCreditCardCircuits1> {
            return localVarFp.apiV1PoswebDocumentTypesGet(filterCodiceGruppo, filterCodiceDocumento, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SaleApi - object-oriented interface
 * @export
 * @class SaleApi
 * @extends {BaseAPI}
 */
export class SaleApi extends BaseAPI {
    /**
     * Get the list of allowed id types for a payment or a sale closure
     * @summary Allowed Ids
     * @param {string} [filterCodiceGruppo] Payment or document section
     * @param {string} [filterCodiceDocumento] Document id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleApi
     */
    public apiV1PoswebDocumentTypesGet(filterCodiceGruppo?: string, filterCodiceDocumento?: string, options?: AxiosRequestConfig) {
        return SaleApiFp(this.configuration).apiV1PoswebDocumentTypesGet(filterCodiceGruppo, filterCodiceDocumento, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SalesApi - axios parameter creator
 * @export
 */
export const SalesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns list of countries and their phone area codes
         * @summary Searches for countries\' area codes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PhonePrefixesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/phone-prefixes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Avaiable price alterations
         * @summary Avaiable price alterations
         * @param {string} [filterClasse] Item class
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebAlterationsPricesGet: async (filterClasse?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/alterations-prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterClasse !== undefined) {
                localVarQueryParameter['filter[classe]'] = filterClasse;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Avaiable Classes
         * @summary Item Classes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebClassesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the list of avaiable discounts for the store
         * @summary Elenco sconti applicabili
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebDiscountsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/discounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of enabled document types for a given sale
         * @summary Enabled document types
         * @param {string} [filterCodiceGruppo] Payment or document section
         * @param {PostSalesEnabledDocumentTypes} [postSalesEnabledDocumentTypes]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebDocumentTypesActivePost: async (filterCodiceGruppo?: string, postSalesEnabledDocumentTypes?: PostSalesEnabledDocumentTypes, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/document-types-active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterCodiceGruppo !== undefined) {
                localVarQueryParameter['filter[codice_gruppo]'] = filterCodiceGruppo;
            }



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postSalesEnabledDocumentTypes, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Avaiable Fabrics
         * @summary Item Fabrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebFabricsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/fabrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Givean a customer returns the active prica list applicable
         * @summary Listino prezzo abilitato
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PriceListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/price-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Questo endpoint permette di effettuare l\'applicazione delle promozioni.
         * @summary Esegui l\'applicazione della promozione
         * @param {PromoEngineApplyRequest} promoEngineApplyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PromoEngineV2ApplyPatch: async (promoEngineApplyRequest: PromoEngineApplyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promoEngineApplyRequest' is not null or undefined
            assertParamExists('apiV1PromoEngineV2ApplyPatch', 'promoEngineApplyRequest', promoEngineApplyRequest)
            const localVarPath = `/api/v1/promo_engine_v2/apply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promoEngineApplyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Questo endpoint permette di ottenere un\'anteprima delle promozioni.
         * @summary Esegui un\'anteprima della promozione
         * @param {SalesData} salesData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PromoEngineV2PreviewPost: async (salesData: SalesData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'salesData' is not null or undefined
            assertParamExists('apiV1PromoEngineV2PreviewPost', 'salesData', salesData)
            const localVarPath = `/api/v1/promo_engine_v2/preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(salesData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Payload: data necessary to return a sale, with mandatory key \"importo\" !  Il payload sarà lo stesso usato per la chiamata di creazione reso: POST /return  a cui deve essere aggiunto il campo \"importo\": valore immesso nella nuova gift card e negativo !
         * @summary Restore gift card used when do a return
         * @param {string} saleId Sale unique id: cod_negozio,id_transazione
         * @param {string} giftCode Gift Card unique code
         * @param {ReturnsGiftCard} [returnsGiftCard]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReturnsSaleIdPaymentsGiftCardGiftCodePost: async (saleId: string, giftCode: string, returnsGiftCard?: ReturnsGiftCard, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1ReturnsSaleIdPaymentsGiftCardGiftCodePost', 'saleId', saleId)
            // verify required parameter 'giftCode' is not null or undefined
            assertParamExists('apiV1ReturnsSaleIdPaymentsGiftCardGiftCodePost', 'giftCode', giftCode)
            const localVarPath = `/api/v1/returns/{sale_id}/payments/gift_card/{gift_code}`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)))
                .replace(`{${"gift_code"}}`, encodeURIComponent(String(giftCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(returnsGiftCard, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Uses (partial) town name as parameter, returns info abt matching towns
         * @summary Searches for towns by name or partial name
         * @param {string} [filterName] String of letters to search for, (partial) name of town
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RicercaComuniGet: async (filterName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ricerca-comuni`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterName !== undefined) {
                localVarQueryParameter['filter[name]'] = filterName;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * In vendita, se i pagamenti effettuati sono elevati, viene richiesto di confermare i dati della cliente e questi sono controllati formalmente al fine di allegarli alla vendita per rispondere alle norme antiriciclaggio.    La risposta ha il campo \"status\" che può essere SUCCESS, se è tutto a posto, oppure ERROR, nel qual caso sono presenti le atre chiavi come :   - \"form_errors\" che contiene gli errori relativi ai campi inviati nella forma \"campo\": \"messaggio\",   - \"generic_errors\" che contiene eventuali errori generici avvenuti durante i controlli
         * @summary controllo correttezza dati cliente per antiriciclaggio
         * @param {ChekcDatiAntiriciclaggio} [chekcDatiAntiriciclaggio]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesCheckDatiAntiriciclaggioPost: async (chekcDatiAntiriciclaggio?: ChekcDatiAntiriciclaggio, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sales/check-dati-antiriciclaggio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chekcDatiAntiriciclaggio, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Servizio per preventivo punti fidelity accumulati sulla vendita corrente
         * @summary Preventivo punti fidelity accumulati sulla vendita corrente
         * @param {DataSale} [dataSale]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesFidelityPointsPost: async (dataSale?: DataSale, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sales/fidelity_points`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataSale, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search for a sale
         * @summary Sale search
         * @param {string} [idTransazione]
         * @param {string} [codCassiera]
         * @param {string} [codCassa]
         * @param {string} [codNegozio]
         * @param {string} [codiceMovimento]
         * @param {string} [codiceStato]
         * @param {string} [dataDocumento]
         * @param {string} [idPostazioneApertura]
         * @param {string} [idPostazioneChiusura]
         * @param {string} [pkConsumer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesGet: async (idTransazione?: string, codCassiera?: string, codCassa?: string, codNegozio?: string, codiceMovimento?: string, codiceStato?: string, dataDocumento?: string, idPostazioneApertura?: string, idPostazioneChiusura?: string, pkConsumer?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (idTransazione !== undefined) {
                localVarQueryParameter['id_transazione'] = idTransazione;
            }

            if (codCassiera !== undefined) {
                localVarQueryParameter['cod_cassiera'] = codCassiera;
            }

            if (codCassa !== undefined) {
                localVarQueryParameter['cod_cassa'] = codCassa;
            }

            if (codNegozio !== undefined) {
                localVarQueryParameter['cod_negozio'] = codNegozio;
            }

            if (codiceMovimento !== undefined) {
                localVarQueryParameter['codice_movimento'] = codiceMovimento;
            }

            if (codiceStato !== undefined) {
                localVarQueryParameter['codice_stato'] = codiceStato;
            }

            if (dataDocumento !== undefined) {
                localVarQueryParameter['data_documento'] = dataDocumento;
            }

            if (idPostazioneApertura !== undefined) {
                localVarQueryParameter['id_postazione_apertura'] = idPostazioneApertura;
            }

            if (idPostazioneChiusura !== undefined) {
                localVarQueryParameter['id_postazione_chiusura'] = idPostazioneChiusura;
            }

            if (pkConsumer !== undefined) {
                localVarQueryParameter['pk_consumer'] = pkConsumer;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get new transaction id
         * @summary Get new id for transaction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesNewIdtransazioneGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sales/new-idtransazione`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get new id for picking request
         * @summary Get new id for picking request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesPickingRequestIdGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sales/picking_requestId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST a new sale      POST transactions updates for layby : payments, finalize, or deletion     only in that case add the \"layby\" field
         * @summary New sale creation
         * @param {PostSale} [postSale]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesPost: async (postSale?: PostSale, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postSale, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * try a print whose execution gave an error  on Response:   - \"status\" field value can be SUCCESS or ERROR. On ERROR, \"message\" field contain error message and \"print_error_result\" contains a cause error message   - The field \"browser_dowload\" could not be present   - The field \"prints_to_retry\" contains the data gave print error and remainig
         * @summary Retry a print
         * @param {PrintRetry} [printRetry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesPrintRetryPost: async (printRetry?: PrintRetry, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sales/print-retry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(printRetry, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check an existing sale
         * @summary Check sale payload
         * @param {string} saleId Sale unique id.
         * @param {PatchSale1} [patchSale1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdCheckTransactionPatch: async (saleId: string, patchSale1?: PatchSale1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdCheckTransactionPatch', 'saleId', saleId)
            const localVarPath = `/api/v1/sales/{sale_id}/check_transaction`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchSale1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check an existing sale
         * @summary Check sale payload
         * @param {string} saleId Sale unique id.
         * @param {PatchSaleFidelity} [patchSaleFidelity]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdFidelityPointsPatch: async (saleId: string, patchSaleFidelity?: PatchSaleFidelity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdFidelityPointsPatch', 'saleId', saleId)
            const localVarPath = `/api/v1/sales/{sale_id}/fidelity_points`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchSaleFidelity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an existing sale
         * @summary Sale retrival
         * @param {string} saleId Sale unique id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdGet: async (saleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdGet', 'saleId', saleId)
            const localVarPath = `/api/v1/sales/{sale_id}`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modify an existing sale
         * @summary Sale modification
         * @param {string} saleId Sale unique id.
         * @param {PatchSale} [patchSale]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdPatch: async (saleId: string, patchSale?: PatchSale, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdPatch', 'saleId', saleId)
            const localVarPath = `/api/v1/sales/{sale_id}`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchSale, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Burn gift card used for payment
         * @summary Burn gift card used for payment
         * @param {string} saleId Sale unique id: cod_negozio,id_transazione
         * @param {string} giftCode Gift Card unique code
         * @param {BurnGiftCard1} [burnGiftCard1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdPaymentsGiftCardGiftCodePatch: async (saleId: string, giftCode: string, burnGiftCard1?: BurnGiftCard1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdPaymentsGiftCardGiftCodePatch', 'saleId', saleId)
            // verify required parameter 'giftCode' is not null or undefined
            assertParamExists('apiV1SalesSaleIdPaymentsGiftCardGiftCodePatch', 'giftCode', giftCode)
            const localVarPath = `/api/v1/sales/{sale_id}/payments/gift_card/{gift_code}`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)))
                .replace(`{${"gift_code"}}`, encodeURIComponent(String(giftCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(burnGiftCard1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Burn gift card used for payment
         * @summary Burn gift card used for payment
         * @param {string} saleId Sale unique id: cod_negozio,id_transazione&#x3D;0
         * @param {string} giftCode Gift Card unique code
         * @param {BurnGiftCard} [burnGiftCard]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdPaymentsGiftCardGiftCodePost: async (saleId: string, giftCode: string, burnGiftCard?: BurnGiftCard, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdPaymentsGiftCardGiftCodePost', 'saleId', saleId)
            // verify required parameter 'giftCode' is not null or undefined
            assertParamExists('apiV1SalesSaleIdPaymentsGiftCardGiftCodePost', 'giftCode', giftCode)
            const localVarPath = `/api/v1/sales/{sale_id}/payments/gift_card/{gift_code}`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)))
                .replace(`{${"gift_code"}}`, encodeURIComponent(String(giftCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(burnGiftCard, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Effettua la ristampa del documento della transazione indicata sa {sale_id} (cod_negozio,id_transazione)      in risposta:     il \"type\" sarà \"receipt_pdf\" per una stampa pdf oppure \"receipt_print\"     in \"attributes\" sarà presente la chiave \"data\" se ho una stampa pdf altrimenti sarà vuoto {}
         * @summary Ristampa documento
         * @param {string} saleId Sale unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdReceiptPatch: async (saleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1SalesSaleIdReceiptPatch', 'saleId', saleId)
            const localVarPath = `/api/v1/sales/{sale_id}/receipt`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stampa documento da far firmare alla cliente per le norme antiriciclaggio
         * @summary Stampa documento per antiriciclaggio
         * @param {StampaModuloAntiriciclaggio} [stampaModuloAntiriciclaggio]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesStampaRiepilogoAntiriciclaggioPost: async (stampaModuloAntiriciclaggio?: StampaModuloAntiriciclaggio, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sales/stampa-riepilogo-antiriciclaggio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stampaModuloAntiriciclaggio, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Burn gift card used for payment
         * @summary Burn gift card used for payment
         * @param {string} saleId Sale unique id: cod_negozio,id_transazione
         * @param {string} giftCode Gift Card unique code
         * @param {BurnGiftCard2} [burnGiftCard2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SuspendedSaleIdPaymentsGiftCardGiftCodePost: async (saleId: string, giftCode: string, burnGiftCard2?: BurnGiftCard2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('apiV1SuspendedSaleIdPaymentsGiftCardGiftCodePost', 'saleId', saleId)
            // verify required parameter 'giftCode' is not null or undefined
            assertParamExists('apiV1SuspendedSaleIdPaymentsGiftCardGiftCodePost', 'giftCode', giftCode)
            const localVarPath = `/api/v1/suspended/{sale_id}/payments/gift_card/{gift_code}`
                .replace(`{${"sale_id"}}`, encodeURIComponent(String(saleId)))
                .replace(`{${"gift_code"}}`, encodeURIComponent(String(giftCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(burnGiftCard2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SalesApi - functional programming interface
 * @export
 */
export const SalesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SalesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns list of countries and their phone area codes
         * @summary Searches for countries\' area codes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PhonePrefixesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPhonePrefixes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PhonePrefixesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Avaiable price alterations
         * @summary Avaiable price alterations
         * @param {string} [filterClasse] Item class
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebAlterationsPricesGet(filterClasse?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetItemsPriceAlteration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebAlterationsPricesGet(filterClasse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Avaiable Classes
         * @summary Item Classes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebClassesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetItemsClasses>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebClassesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the list of avaiable discounts for the store
         * @summary Elenco sconti applicabili
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebDiscountsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDiscounts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebDiscountsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of enabled document types for a given sale
         * @summary Enabled document types
         * @param {string} [filterCodiceGruppo] Payment or document section
         * @param {PostSalesEnabledDocumentTypes} [postSalesEnabledDocumentTypes]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebDocumentTypesActivePost(filterCodiceGruppo?: string, postSalesEnabledDocumentTypes?: PostSalesEnabledDocumentTypes, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocumentTypes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebDocumentTypesActivePost(filterCodiceGruppo, postSalesEnabledDocumentTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Avaiable Fabrics
         * @summary Item Fabrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebFabricsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetItemsFabrics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebFabricsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Givean a customer returns the active prica list applicable
         * @summary Listino prezzo abilitato
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PriceListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCustomerPriceList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PriceListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Questo endpoint permette di effettuare l\'applicazione delle promozioni.
         * @summary Esegui l\'applicazione della promozione
         * @param {PromoEngineApplyRequest} promoEngineApplyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PromoEngineV2ApplyPatch(promoEngineApplyRequest: PromoEngineApplyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromoEngineApplyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PromoEngineV2ApplyPatch(promoEngineApplyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Questo endpoint permette di ottenere un\'anteprima delle promozioni.
         * @summary Esegui un\'anteprima della promozione
         * @param {SalesData} salesData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PromoEngineV2PreviewPost(salesData: SalesData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromoEnginePreviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PromoEngineV2PreviewPost(salesData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Payload: data necessary to return a sale, with mandatory key \"importo\" !  Il payload sarà lo stesso usato per la chiamata di creazione reso: POST /return  a cui deve essere aggiunto il campo \"importo\": valore immesso nella nuova gift card e negativo !
         * @summary Restore gift card used when do a return
         * @param {string} saleId Sale unique id: cod_negozio,id_transazione
         * @param {string} giftCode Gift Card unique code
         * @param {ReturnsGiftCard} [returnsGiftCard]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ReturnsSaleIdPaymentsGiftCardGiftCodePost(saleId: string, giftCode: string, returnsGiftCard?: ReturnsGiftCard, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnBurnGiftCard1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ReturnsSaleIdPaymentsGiftCardGiftCodePost(saleId, giftCode, returnsGiftCard, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Uses (partial) town name as parameter, returns info abt matching towns
         * @summary Searches for towns by name or partial name
         * @param {string} [filterName] String of letters to search for, (partial) name of town
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RicercaComuniGet(filterName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCodiciComuni>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RicercaComuniGet(filterName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * In vendita, se i pagamenti effettuati sono elevati, viene richiesto di confermare i dati della cliente e questi sono controllati formalmente al fine di allegarli alla vendita per rispondere alle norme antiriciclaggio.    La risposta ha il campo \"status\" che può essere SUCCESS, se è tutto a posto, oppure ERROR, nel qual caso sono presenti le atre chiavi come :   - \"form_errors\" che contiene gli errori relativi ai campi inviati nella forma \"campo\": \"messaggio\",   - \"generic_errors\" che contiene eventuali errori generici avvenuti durante i controlli
         * @summary controllo correttezza dati cliente per antiriciclaggio
         * @param {ChekcDatiAntiriciclaggio} [chekcDatiAntiriciclaggio]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesCheckDatiAntiriciclaggioPost(chekcDatiAntiriciclaggio?: ChekcDatiAntiriciclaggio, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EsitoDatiAntiriciclaggio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesCheckDatiAntiriciclaggioPost(chekcDatiAntiriciclaggio, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Servizio per preventivo punti fidelity accumulati sulla vendita corrente
         * @summary Preventivo punti fidelity accumulati sulla vendita corrente
         * @param {DataSale} [dataSale]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesFidelityPointsPost(dataSale?: DataSale, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaleAndFidelity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesFidelityPointsPost(dataSale, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Search for a sale
         * @summary Sale search
         * @param {string} [idTransazione]
         * @param {string} [codCassiera]
         * @param {string} [codCassa]
         * @param {string} [codNegozio]
         * @param {string} [codiceMovimento]
         * @param {string} [codiceStato]
         * @param {string} [dataDocumento]
         * @param {string} [idPostazioneApertura]
         * @param {string} [idPostazioneChiusura]
         * @param {string} [pkConsumer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesGet(idTransazione?: string, codCassiera?: string, codCassa?: string, codNegozio?: string, codiceMovimento?: string, codiceStato?: string, dataDocumento?: string, idPostazioneApertura?: string, idPostazioneChiusura?: string, pkConsumer?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InlineResponse200>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesGet(idTransazione, codCassiera, codCassa, codNegozio, codiceMovimento, codiceStato, dataDocumento, idPostazioneApertura, idPostazioneChiusura, pkConsumer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get new transaction id
         * @summary Get new id for transaction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesNewIdtransazioneGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionNewID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesNewIdtransazioneGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get new id for picking request
         * @summary Get new id for picking request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesPickingRequestIdGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PickinRequestID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesPickingRequestIdGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST a new sale      POST transactions updates for layby : payments, finalize, or deletion     only in that case add the \"layby\" field
         * @summary New sale creation
         * @param {PostSale} [postSale]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesPost(postSale?: PostSale, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sale>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesPost(postSale, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * try a print whose execution gave an error  on Response:   - \"status\" field value can be SUCCESS or ERROR. On ERROR, \"message\" field contain error message and \"print_error_result\" contains a cause error message   - The field \"browser_dowload\" could not be present   - The field \"prints_to_retry\" contains the data gave print error and remainig
         * @summary Retry a print
         * @param {PrintRetry} [printRetry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesPrintRetryPost(printRetry?: PrintRetry, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrintRetry1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesPrintRetryPost(printRetry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Check an existing sale
         * @summary Check sale payload
         * @param {string} saleId Sale unique id.
         * @param {PatchSale1} [patchSale1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesSaleIdCheckTransactionPatch(saleId: string, patchSale1?: PatchSale1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sale2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesSaleIdCheckTransactionPatch(saleId, patchSale1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Check an existing sale
         * @summary Check sale payload
         * @param {string} saleId Sale unique id.
         * @param {PatchSaleFidelity} [patchSaleFidelity]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesSaleIdFidelityPointsPatch(saleId: string, patchSaleFidelity?: PatchSaleFidelity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaleAndFidelity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesSaleIdFidelityPointsPatch(saleId, patchSaleFidelity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get an existing sale
         * @summary Sale retrival
         * @param {string} saleId Sale unique id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesSaleIdGet(saleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sale1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesSaleIdGet(saleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Modify an existing sale
         * @summary Sale modification
         * @param {string} saleId Sale unique id.
         * @param {PatchSale} [patchSale]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesSaleIdPatch(saleId: string, patchSale?: PatchSale, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sale>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesSaleIdPatch(saleId, patchSale, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Burn gift card used for payment
         * @summary Burn gift card used for payment
         * @param {string} saleId Sale unique id: cod_negozio,id_transazione
         * @param {string} giftCode Gift Card unique code
         * @param {BurnGiftCard1} [burnGiftCard1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesSaleIdPaymentsGiftCardGiftCodePatch(saleId: string, giftCode: string, burnGiftCard1?: BurnGiftCard1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnBurnGiftCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesSaleIdPaymentsGiftCardGiftCodePatch(saleId, giftCode, burnGiftCard1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Burn gift card used for payment
         * @summary Burn gift card used for payment
         * @param {string} saleId Sale unique id: cod_negozio,id_transazione&#x3D;0
         * @param {string} giftCode Gift Card unique code
         * @param {BurnGiftCard} [burnGiftCard]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesSaleIdPaymentsGiftCardGiftCodePost(saleId: string, giftCode: string, burnGiftCard?: BurnGiftCard, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnBurnGiftCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesSaleIdPaymentsGiftCardGiftCodePost(saleId, giftCode, burnGiftCard, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Effettua la ristampa del documento della transazione indicata sa {sale_id} (cod_negozio,id_transazione)      in risposta:     il \"type\" sarà \"receipt_pdf\" per una stampa pdf oppure \"receipt_print\"     in \"attributes\" sarà presente la chiave \"data\" se ho una stampa pdf altrimenti sarà vuoto {}
         * @summary Ristampa documento
         * @param {string} saleId Sale unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesSaleIdReceiptPatch(saleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EsitoRistampaDocumento>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesSaleIdReceiptPatch(saleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stampa documento da far firmare alla cliente per le norme antiriciclaggio
         * @summary Stampa documento per antiriciclaggio
         * @param {StampaModuloAntiriciclaggio} [stampaModuloAntiriciclaggio]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesStampaRiepilogoAntiriciclaggioPost(stampaModuloAntiriciclaggio?: StampaModuloAntiriciclaggio, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EsitoStampaAntiriciclaggio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesStampaRiepilogoAntiriciclaggioPost(stampaModuloAntiriciclaggio, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Burn gift card used for payment
         * @summary Burn gift card used for payment
         * @param {string} saleId Sale unique id: cod_negozio,id_transazione
         * @param {string} giftCode Gift Card unique code
         * @param {BurnGiftCard2} [burnGiftCard2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SuspendedSaleIdPaymentsGiftCardGiftCodePost(saleId: string, giftCode: string, burnGiftCard2?: BurnGiftCard2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardData1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SuspendedSaleIdPaymentsGiftCardGiftCodePost(saleId, giftCode, burnGiftCard2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SalesApi - factory interface
 * @export
 */
export const SalesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SalesApiFp(configuration)
    return {
        /**
         * Returns list of countries and their phone area codes
         * @summary Searches for countries\' area codes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PhonePrefixesGet(options?: any): AxiosPromise<GetPhonePrefixes> {
            return localVarFp.apiV1PhonePrefixesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Avaiable price alterations
         * @summary Avaiable price alterations
         * @param {string} [filterClasse] Item class
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebAlterationsPricesGet(filterClasse?: string, options?: any): AxiosPromise<GetItemsPriceAlteration> {
            return localVarFp.apiV1PoswebAlterationsPricesGet(filterClasse, options).then((request) => request(axios, basePath));
        },
        /**
         * Avaiable Classes
         * @summary Item Classes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebClassesGet(options?: any): AxiosPromise<GetItemsClasses> {
            return localVarFp.apiV1PoswebClassesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the list of avaiable discounts for the store
         * @summary Elenco sconti applicabili
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebDiscountsGet(options?: any): AxiosPromise<GetDiscounts> {
            return localVarFp.apiV1PoswebDiscountsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * List of enabled document types for a given sale
         * @summary Enabled document types
         * @param {string} [filterCodiceGruppo] Payment or document section
         * @param {PostSalesEnabledDocumentTypes} [postSalesEnabledDocumentTypes]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebDocumentTypesActivePost(filterCodiceGruppo?: string, postSalesEnabledDocumentTypes?: PostSalesEnabledDocumentTypes, options?: any): AxiosPromise<GetDocumentTypes> {
            return localVarFp.apiV1PoswebDocumentTypesActivePost(filterCodiceGruppo, postSalesEnabledDocumentTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * Avaiable Fabrics
         * @summary Item Fabrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebFabricsGet(options?: any): AxiosPromise<GetItemsFabrics> {
            return localVarFp.apiV1PoswebFabricsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Givean a customer returns the active prica list applicable
         * @summary Listino prezzo abilitato
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PriceListGet(options?: any): AxiosPromise<GetCustomerPriceList> {
            return localVarFp.apiV1PriceListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Questo endpoint permette di effettuare l\'applicazione delle promozioni.
         * @summary Esegui l\'applicazione della promozione
         * @param {PromoEngineApplyRequest} promoEngineApplyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PromoEngineV2ApplyPatch(promoEngineApplyRequest: PromoEngineApplyRequest, options?: any): AxiosPromise<PromoEngineApplyResponse> {
            return localVarFp.apiV1PromoEngineV2ApplyPatch(promoEngineApplyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Questo endpoint permette di ottenere un\'anteprima delle promozioni.
         * @summary Esegui un\'anteprima della promozione
         * @param {SalesData} salesData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PromoEngineV2PreviewPost(salesData: SalesData, options?: any): AxiosPromise<PromoEnginePreviewResponse> {
            return localVarFp.apiV1PromoEngineV2PreviewPost(salesData, options).then((request) => request(axios, basePath));
        },
        /**
         * Payload: data necessary to return a sale, with mandatory key \"importo\" !  Il payload sarà lo stesso usato per la chiamata di creazione reso: POST /return  a cui deve essere aggiunto il campo \"importo\": valore immesso nella nuova gift card e negativo !
         * @summary Restore gift card used when do a return
         * @param {string} saleId Sale unique id: cod_negozio,id_transazione
         * @param {string} giftCode Gift Card unique code
         * @param {ReturnsGiftCard} [returnsGiftCard]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ReturnsSaleIdPaymentsGiftCardGiftCodePost(saleId: string, giftCode: string, returnsGiftCard?: ReturnsGiftCard, options?: any): AxiosPromise<ReturnBurnGiftCard1> {
            return localVarFp.apiV1ReturnsSaleIdPaymentsGiftCardGiftCodePost(saleId, giftCode, returnsGiftCard, options).then((request) => request(axios, basePath));
        },
        /**
         * Uses (partial) town name as parameter, returns info abt matching towns
         * @summary Searches for towns by name or partial name
         * @param {string} [filterName] String of letters to search for, (partial) name of town
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RicercaComuniGet(filterName?: string, options?: any): AxiosPromise<GetCodiciComuni> {
            return localVarFp.apiV1RicercaComuniGet(filterName, options).then((request) => request(axios, basePath));
        },
        /**
         * In vendita, se i pagamenti effettuati sono elevati, viene richiesto di confermare i dati della cliente e questi sono controllati formalmente al fine di allegarli alla vendita per rispondere alle norme antiriciclaggio.    La risposta ha il campo \"status\" che può essere SUCCESS, se è tutto a posto, oppure ERROR, nel qual caso sono presenti le atre chiavi come :   - \"form_errors\" che contiene gli errori relativi ai campi inviati nella forma \"campo\": \"messaggio\",   - \"generic_errors\" che contiene eventuali errori generici avvenuti durante i controlli
         * @summary controllo correttezza dati cliente per antiriciclaggio
         * @param {ChekcDatiAntiriciclaggio} [chekcDatiAntiriciclaggio]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesCheckDatiAntiriciclaggioPost(chekcDatiAntiriciclaggio?: ChekcDatiAntiriciclaggio, options?: any): AxiosPromise<EsitoDatiAntiriciclaggio> {
            return localVarFp.apiV1SalesCheckDatiAntiriciclaggioPost(chekcDatiAntiriciclaggio, options).then((request) => request(axios, basePath));
        },
        /**
         * Servizio per preventivo punti fidelity accumulati sulla vendita corrente
         * @summary Preventivo punti fidelity accumulati sulla vendita corrente
         * @param {DataSale} [dataSale]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesFidelityPointsPost(dataSale?: DataSale, options?: any): AxiosPromise<SaleAndFidelity> {
            return localVarFp.apiV1SalesFidelityPointsPost(dataSale, options).then((request) => request(axios, basePath));
        },
        /**
         * Search for a sale
         * @summary Sale search
         * @param {string} [idTransazione]
         * @param {string} [codCassiera]
         * @param {string} [codCassa]
         * @param {string} [codNegozio]
         * @param {string} [codiceMovimento]
         * @param {string} [codiceStato]
         * @param {string} [dataDocumento]
         * @param {string} [idPostazioneApertura]
         * @param {string} [idPostazioneChiusura]
         * @param {string} [pkConsumer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesGet(idTransazione?: string, codCassiera?: string, codCassa?: string, codNegozio?: string, codiceMovimento?: string, codiceStato?: string, dataDocumento?: string, idPostazioneApertura?: string, idPostazioneChiusura?: string, pkConsumer?: string, options?: any): AxiosPromise<Array<InlineResponse200>> {
            return localVarFp.apiV1SalesGet(idTransazione, codCassiera, codCassa, codNegozio, codiceMovimento, codiceStato, dataDocumento, idPostazioneApertura, idPostazioneChiusura, pkConsumer, options).then((request) => request(axios, basePath));
        },
        /**
         * Get new transaction id
         * @summary Get new id for transaction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesNewIdtransazioneGet(options?: any): AxiosPromise<TransactionNewID> {
            return localVarFp.apiV1SalesNewIdtransazioneGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get new id for picking request
         * @summary Get new id for picking request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesPickingRequestIdGet(options?: any): AxiosPromise<PickinRequestID> {
            return localVarFp.apiV1SalesPickingRequestIdGet(options).then((request) => request(axios, basePath));
        },
        /**
         * POST a new sale      POST transactions updates for layby : payments, finalize, or deletion     only in that case add the \"layby\" field
         * @summary New sale creation
         * @param {PostSale} [postSale]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesPost(postSale?: PostSale, options?: any): AxiosPromise<Sale> {
            return localVarFp.apiV1SalesPost(postSale, options).then((request) => request(axios, basePath));
        },
        /**
         * try a print whose execution gave an error  on Response:   - \"status\" field value can be SUCCESS or ERROR. On ERROR, \"message\" field contain error message and \"print_error_result\" contains a cause error message   - The field \"browser_dowload\" could not be present   - The field \"prints_to_retry\" contains the data gave print error and remainig
         * @summary Retry a print
         * @param {PrintRetry} [printRetry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesPrintRetryPost(printRetry?: PrintRetry, options?: any): AxiosPromise<PrintRetry1> {
            return localVarFp.apiV1SalesPrintRetryPost(printRetry, options).then((request) => request(axios, basePath));
        },
        /**
         * Check an existing sale
         * @summary Check sale payload
         * @param {string} saleId Sale unique id.
         * @param {PatchSale1} [patchSale1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdCheckTransactionPatch(saleId: string, patchSale1?: PatchSale1, options?: any): AxiosPromise<Sale2> {
            return localVarFp.apiV1SalesSaleIdCheckTransactionPatch(saleId, patchSale1, options).then((request) => request(axios, basePath));
        },
        /**
         * Check an existing sale
         * @summary Check sale payload
         * @param {string} saleId Sale unique id.
         * @param {PatchSaleFidelity} [patchSaleFidelity]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdFidelityPointsPatch(saleId: string, patchSaleFidelity?: PatchSaleFidelity, options?: any): AxiosPromise<SaleAndFidelity> {
            return localVarFp.apiV1SalesSaleIdFidelityPointsPatch(saleId, patchSaleFidelity, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an existing sale
         * @summary Sale retrival
         * @param {string} saleId Sale unique id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdGet(saleId: string, options?: any): AxiosPromise<Sale1> {
            return localVarFp.apiV1SalesSaleIdGet(saleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Modify an existing sale
         * @summary Sale modification
         * @param {string} saleId Sale unique id.
         * @param {PatchSale} [patchSale]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdPatch(saleId: string, patchSale?: PatchSale, options?: any): AxiosPromise<Sale> {
            return localVarFp.apiV1SalesSaleIdPatch(saleId, patchSale, options).then((request) => request(axios, basePath));
        },
        /**
         * Burn gift card used for payment
         * @summary Burn gift card used for payment
         * @param {string} saleId Sale unique id: cod_negozio,id_transazione
         * @param {string} giftCode Gift Card unique code
         * @param {BurnGiftCard1} [burnGiftCard1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdPaymentsGiftCardGiftCodePatch(saleId: string, giftCode: string, burnGiftCard1?: BurnGiftCard1, options?: any): AxiosPromise<ReturnBurnGiftCard> {
            return localVarFp.apiV1SalesSaleIdPaymentsGiftCardGiftCodePatch(saleId, giftCode, burnGiftCard1, options).then((request) => request(axios, basePath));
        },
        /**
         * Burn gift card used for payment
         * @summary Burn gift card used for payment
         * @param {string} saleId Sale unique id: cod_negozio,id_transazione&#x3D;0
         * @param {string} giftCode Gift Card unique code
         * @param {BurnGiftCard} [burnGiftCard]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdPaymentsGiftCardGiftCodePost(saleId: string, giftCode: string, burnGiftCard?: BurnGiftCard, options?: any): AxiosPromise<ReturnBurnGiftCard> {
            return localVarFp.apiV1SalesSaleIdPaymentsGiftCardGiftCodePost(saleId, giftCode, burnGiftCard, options).then((request) => request(axios, basePath));
        },
        /**
         * Effettua la ristampa del documento della transazione indicata sa {sale_id} (cod_negozio,id_transazione)      in risposta:     il \"type\" sarà \"receipt_pdf\" per una stampa pdf oppure \"receipt_print\"     in \"attributes\" sarà presente la chiave \"data\" se ho una stampa pdf altrimenti sarà vuoto {}
         * @summary Ristampa documento
         * @param {string} saleId Sale unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesSaleIdReceiptPatch(saleId: string, options?: any): AxiosPromise<EsitoRistampaDocumento> {
            return localVarFp.apiV1SalesSaleIdReceiptPatch(saleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Stampa documento da far firmare alla cliente per le norme antiriciclaggio
         * @summary Stampa documento per antiriciclaggio
         * @param {StampaModuloAntiriciclaggio} [stampaModuloAntiriciclaggio]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesStampaRiepilogoAntiriciclaggioPost(stampaModuloAntiriciclaggio?: StampaModuloAntiriciclaggio, options?: any): AxiosPromise<EsitoStampaAntiriciclaggio> {
            return localVarFp.apiV1SalesStampaRiepilogoAntiriciclaggioPost(stampaModuloAntiriciclaggio, options).then((request) => request(axios, basePath));
        },
        /**
         * Burn gift card used for payment
         * @summary Burn gift card used for payment
         * @param {string} saleId Sale unique id: cod_negozio,id_transazione
         * @param {string} giftCode Gift Card unique code
         * @param {BurnGiftCard2} [burnGiftCard2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SuspendedSaleIdPaymentsGiftCardGiftCodePost(saleId: string, giftCode: string, burnGiftCard2?: BurnGiftCard2, options?: any): AxiosPromise<GiftCardData1> {
            return localVarFp.apiV1SuspendedSaleIdPaymentsGiftCardGiftCodePost(saleId, giftCode, burnGiftCard2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SalesApi - object-oriented interface
 * @export
 * @class SalesApi
 * @extends {BaseAPI}
 */
export class SalesApi extends BaseAPI {
    /**
     * Returns list of countries and their phone area codes
     * @summary Searches for countries\' area codes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1PhonePrefixesGet(options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1PhonePrefixesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Avaiable price alterations
     * @summary Avaiable price alterations
     * @param {string} [filterClasse] Item class
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1PoswebAlterationsPricesGet(filterClasse?: string, options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1PoswebAlterationsPricesGet(filterClasse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Avaiable Classes
     * @summary Item Classes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1PoswebClassesGet(options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1PoswebClassesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the list of avaiable discounts for the store
     * @summary Elenco sconti applicabili
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1PoswebDiscountsGet(options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1PoswebDiscountsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of enabled document types for a given sale
     * @summary Enabled document types
     * @param {string} [filterCodiceGruppo] Payment or document section
     * @param {PostSalesEnabledDocumentTypes} [postSalesEnabledDocumentTypes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1PoswebDocumentTypesActivePost(filterCodiceGruppo?: string, postSalesEnabledDocumentTypes?: PostSalesEnabledDocumentTypes, options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1PoswebDocumentTypesActivePost(filterCodiceGruppo, postSalesEnabledDocumentTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Avaiable Fabrics
     * @summary Item Fabrics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1PoswebFabricsGet(options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1PoswebFabricsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Givean a customer returns the active prica list applicable
     * @summary Listino prezzo abilitato
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1PriceListGet(options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1PriceListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Questo endpoint permette di effettuare l\'applicazione delle promozioni.
     * @summary Esegui l\'applicazione della promozione
     * @param {PromoEngineApplyRequest} promoEngineApplyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1PromoEngineV2ApplyPatch(promoEngineApplyRequest: PromoEngineApplyRequest, options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1PromoEngineV2ApplyPatch(promoEngineApplyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Questo endpoint permette di ottenere un\'anteprima delle promozioni.
     * @summary Esegui un\'anteprima della promozione
     * @param {SalesData} salesData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1PromoEngineV2PreviewPost(salesData: SalesData, options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1PromoEngineV2PreviewPost(salesData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Payload: data necessary to return a sale, with mandatory key \"importo\" !  Il payload sarà lo stesso usato per la chiamata di creazione reso: POST /return  a cui deve essere aggiunto il campo \"importo\": valore immesso nella nuova gift card e negativo !
     * @summary Restore gift card used when do a return
     * @param {string} saleId Sale unique id: cod_negozio,id_transazione
     * @param {string} giftCode Gift Card unique code
     * @param {ReturnsGiftCard} [returnsGiftCard]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1ReturnsSaleIdPaymentsGiftCardGiftCodePost(saleId: string, giftCode: string, returnsGiftCard?: ReturnsGiftCard, options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1ReturnsSaleIdPaymentsGiftCardGiftCodePost(saleId, giftCode, returnsGiftCard, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Uses (partial) town name as parameter, returns info abt matching towns
     * @summary Searches for towns by name or partial name
     * @param {string} [filterName] String of letters to search for, (partial) name of town
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1RicercaComuniGet(filterName?: string, options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1RicercaComuniGet(filterName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * In vendita, se i pagamenti effettuati sono elevati, viene richiesto di confermare i dati della cliente e questi sono controllati formalmente al fine di allegarli alla vendita per rispondere alle norme antiriciclaggio.    La risposta ha il campo \"status\" che può essere SUCCESS, se è tutto a posto, oppure ERROR, nel qual caso sono presenti le atre chiavi come :   - \"form_errors\" che contiene gli errori relativi ai campi inviati nella forma \"campo\": \"messaggio\",   - \"generic_errors\" che contiene eventuali errori generici avvenuti durante i controlli
     * @summary controllo correttezza dati cliente per antiriciclaggio
     * @param {ChekcDatiAntiriciclaggio} [chekcDatiAntiriciclaggio]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1SalesCheckDatiAntiriciclaggioPost(chekcDatiAntiriciclaggio?: ChekcDatiAntiriciclaggio, options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1SalesCheckDatiAntiriciclaggioPost(chekcDatiAntiriciclaggio, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Servizio per preventivo punti fidelity accumulati sulla vendita corrente
     * @summary Preventivo punti fidelity accumulati sulla vendita corrente
     * @param {DataSale} [dataSale]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1SalesFidelityPointsPost(dataSale?: DataSale, options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1SalesFidelityPointsPost(dataSale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search for a sale
     * @summary Sale search
     * @param {string} [idTransazione]
     * @param {string} [codCassiera]
     * @param {string} [codCassa]
     * @param {string} [codNegozio]
     * @param {string} [codiceMovimento]
     * @param {string} [codiceStato]
     * @param {string} [dataDocumento]
     * @param {string} [idPostazioneApertura]
     * @param {string} [idPostazioneChiusura]
     * @param {string} [pkConsumer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1SalesGet(idTransazione?: string, codCassiera?: string, codCassa?: string, codNegozio?: string, codiceMovimento?: string, codiceStato?: string, dataDocumento?: string, idPostazioneApertura?: string, idPostazioneChiusura?: string, pkConsumer?: string, options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1SalesGet(idTransazione, codCassiera, codCassa, codNegozio, codiceMovimento, codiceStato, dataDocumento, idPostazioneApertura, idPostazioneChiusura, pkConsumer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get new transaction id
     * @summary Get new id for transaction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1SalesNewIdtransazioneGet(options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1SalesNewIdtransazioneGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get new id for picking request
     * @summary Get new id for picking request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1SalesPickingRequestIdGet(options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1SalesPickingRequestIdGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST a new sale      POST transactions updates for layby : payments, finalize, or deletion     only in that case add the \"layby\" field
     * @summary New sale creation
     * @param {PostSale} [postSale]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1SalesPost(postSale?: PostSale, options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1SalesPost(postSale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * try a print whose execution gave an error  on Response:   - \"status\" field value can be SUCCESS or ERROR. On ERROR, \"message\" field contain error message and \"print_error_result\" contains a cause error message   - The field \"browser_dowload\" could not be present   - The field \"prints_to_retry\" contains the data gave print error and remainig
     * @summary Retry a print
     * @param {PrintRetry} [printRetry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1SalesPrintRetryPost(printRetry?: PrintRetry, options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1SalesPrintRetryPost(printRetry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Check an existing sale
     * @summary Check sale payload
     * @param {string} saleId Sale unique id.
     * @param {PatchSale1} [patchSale1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1SalesSaleIdCheckTransactionPatch(saleId: string, patchSale1?: PatchSale1, options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1SalesSaleIdCheckTransactionPatch(saleId, patchSale1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Check an existing sale
     * @summary Check sale payload
     * @param {string} saleId Sale unique id.
     * @param {PatchSaleFidelity} [patchSaleFidelity]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1SalesSaleIdFidelityPointsPatch(saleId: string, patchSaleFidelity?: PatchSaleFidelity, options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1SalesSaleIdFidelityPointsPatch(saleId, patchSaleFidelity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an existing sale
     * @summary Sale retrival
     * @param {string} saleId Sale unique id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1SalesSaleIdGet(saleId: string, options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1SalesSaleIdGet(saleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modify an existing sale
     * @summary Sale modification
     * @param {string} saleId Sale unique id.
     * @param {PatchSale} [patchSale]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1SalesSaleIdPatch(saleId: string, patchSale?: PatchSale, options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1SalesSaleIdPatch(saleId, patchSale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Burn gift card used for payment
     * @summary Burn gift card used for payment
     * @param {string} saleId Sale unique id: cod_negozio,id_transazione
     * @param {string} giftCode Gift Card unique code
     * @param {BurnGiftCard1} [burnGiftCard1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1SalesSaleIdPaymentsGiftCardGiftCodePatch(saleId: string, giftCode: string, burnGiftCard1?: BurnGiftCard1, options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1SalesSaleIdPaymentsGiftCardGiftCodePatch(saleId, giftCode, burnGiftCard1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Burn gift card used for payment
     * @summary Burn gift card used for payment
     * @param {string} saleId Sale unique id: cod_negozio,id_transazione&#x3D;0
     * @param {string} giftCode Gift Card unique code
     * @param {BurnGiftCard} [burnGiftCard]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1SalesSaleIdPaymentsGiftCardGiftCodePost(saleId: string, giftCode: string, burnGiftCard?: BurnGiftCard, options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1SalesSaleIdPaymentsGiftCardGiftCodePost(saleId, giftCode, burnGiftCard, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Effettua la ristampa del documento della transazione indicata sa {sale_id} (cod_negozio,id_transazione)      in risposta:     il \"type\" sarà \"receipt_pdf\" per una stampa pdf oppure \"receipt_print\"     in \"attributes\" sarà presente la chiave \"data\" se ho una stampa pdf altrimenti sarà vuoto {}
     * @summary Ristampa documento
     * @param {string} saleId Sale unique id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1SalesSaleIdReceiptPatch(saleId: string, options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1SalesSaleIdReceiptPatch(saleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stampa documento da far firmare alla cliente per le norme antiriciclaggio
     * @summary Stampa documento per antiriciclaggio
     * @param {StampaModuloAntiriciclaggio} [stampaModuloAntiriciclaggio]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1SalesStampaRiepilogoAntiriciclaggioPost(stampaModuloAntiriciclaggio?: StampaModuloAntiriciclaggio, options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1SalesStampaRiepilogoAntiriciclaggioPost(stampaModuloAntiriciclaggio, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Burn gift card used for payment
     * @summary Burn gift card used for payment
     * @param {string} saleId Sale unique id: cod_negozio,id_transazione
     * @param {string} giftCode Gift Card unique code
     * @param {BurnGiftCard2} [burnGiftCard2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public apiV1SuspendedSaleIdPaymentsGiftCardGiftCodePost(saleId: string, giftCode: string, burnGiftCard2?: BurnGiftCard2, options?: AxiosRequestConfig) {
        return SalesApiFp(this.configuration).apiV1SuspendedSaleIdPaymentsGiftCardGiftCodePost(saleId, giftCode, burnGiftCard2, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SessionApi - axios parameter creator
 * @export
 */
export const SessionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Used to validate a SID received from the centralized authentication server
         * @summary Assign Portale session
         * @param {string} sid Session unique id generated by Portale.
         * @param {PortaleDeleteSession} [portaleDeleteSession]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SessionsPortalSidDelete: async (sid: string, portaleDeleteSession?: PortaleDeleteSession, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sid' is not null or undefined
            assertParamExists('apiV1SessionsPortalSidDelete', 'sid', sid)
            const localVarPath = `/api/v1/sessions_portal/{sid}`
                .replace(`{${"sid"}}`, encodeURIComponent(String(sid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(portaleDeleteSession, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to validate a SID received from the centralized authentication server
         * @summary Assign Portale session
         * @param {string} sid Session unique id generated by Portale.
         * @param {PortalePatchSession} [portalePatchSession]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SessionsPortalSidPatch: async (sid: string, portalePatchSession?: PortalePatchSession, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sid' is not null or undefined
            assertParamExists('apiV1SessionsPortalSidPatch', 'sid', sid)
            const localVarPath = `/api/v1/sessions_portal/{sid}`
                .replace(`{${"sid"}}`, encodeURIComponent(String(sid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(portalePatchSession, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to validate a SID received from the centralized authentication server
         * @summary Get Portale session data
         * @param {string} sid Session unique id generated by Portale.
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SessionsPortalSidPost: async (sid: string, body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sid' is not null or undefined
            assertParamExists('apiV1SessionsPortalSidPost', 'sid', sid)
            const localVarPath = `/api/v1/sessions_portal/{sid}`
                .replace(`{${"sid"}}`, encodeURIComponent(String(sid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to login in the system
         * @summary Login
         * @param {PostSession} [postSession]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SessionsPost: async (postSession?: PostSession, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postSession, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Invalidates the session.
         * @summary Logout
         * @param {string} sid Session unique id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SessionsSidDelete: async (sid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sid' is not null or undefined
            assertParamExists('apiV1SessionsSidDelete', 'sid', sid)
            const localVarPath = `/api/v1/sessions/{sid}`
                .replace(`{${"sid"}}`, encodeURIComponent(String(sid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to to change the session language
         * @summary Language Switch
         * @param {string} sid Session unique id.
         * @param {PatchSession} [patchSession]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SessionsSidPatch: async (sid: string, patchSession?: PatchSession, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sid' is not null or undefined
            assertParamExists('apiV1SessionsSidPatch', 'sid', sid)
            const localVarPath = `/api/v1/sessions/{sid}`
                .replace(`{${"sid"}}`, encodeURIComponent(String(sid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchSession, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SessionApi - functional programming interface
 * @export
 */
export const SessionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SessionApiAxiosParamCreator(configuration)
    return {
        /**
         * Used to validate a SID received from the centralized authentication server
         * @summary Assign Portale session
         * @param {string} sid Session unique id generated by Portale.
         * @param {PortaleDeleteSession} [portaleDeleteSession]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SessionsPortalSidDelete(sid: string, portaleDeleteSession?: PortaleDeleteSession, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortaleSession>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SessionsPortalSidDelete(sid, portaleDeleteSession, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Used to validate a SID received from the centralized authentication server
         * @summary Assign Portale session
         * @param {string} sid Session unique id generated by Portale.
         * @param {PortalePatchSession} [portalePatchSession]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SessionsPortalSidPatch(sid: string, portalePatchSession?: PortalePatchSession, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortaleSession>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SessionsPortalSidPatch(sid, portalePatchSession, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Used to validate a SID received from the centralized authentication server
         * @summary Get Portale session data
         * @param {string} sid Session unique id generated by Portale.
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SessionsPortalSidPost(sid: string, body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortaleSession>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SessionsPortalSidPost(sid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Used to login in the system
         * @summary Login
         * @param {PostSession} [postSession]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SessionsPost(postSession?: PostSession, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Session>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SessionsPost(postSession, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Invalidates the session.
         * @summary Logout
         * @param {string} sid Session unique id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SessionsSidDelete(sid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SessionsSidDelete(sid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Used to to change the session language
         * @summary Language Switch
         * @param {string} sid Session unique id.
         * @param {PatchSession} [patchSession]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SessionsSidPatch(sid: string, patchSession?: PatchSession, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Session>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SessionsSidPatch(sid, patchSession, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SessionApi - factory interface
 * @export
 */
export const SessionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SessionApiFp(configuration)
    return {
        /**
         * Used to validate a SID received from the centralized authentication server
         * @summary Assign Portale session
         * @param {string} sid Session unique id generated by Portale.
         * @param {PortaleDeleteSession} [portaleDeleteSession]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SessionsPortalSidDelete(sid: string, portaleDeleteSession?: PortaleDeleteSession, options?: any): AxiosPromise<PortaleSession> {
            return localVarFp.apiV1SessionsPortalSidDelete(sid, portaleDeleteSession, options).then((request) => request(axios, basePath));
        },
        /**
         * Used to validate a SID received from the centralized authentication server
         * @summary Assign Portale session
         * @param {string} sid Session unique id generated by Portale.
         * @param {PortalePatchSession} [portalePatchSession]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SessionsPortalSidPatch(sid: string, portalePatchSession?: PortalePatchSession, options?: any): AxiosPromise<PortaleSession> {
            return localVarFp.apiV1SessionsPortalSidPatch(sid, portalePatchSession, options).then((request) => request(axios, basePath));
        },
        /**
         * Used to validate a SID received from the centralized authentication server
         * @summary Get Portale session data
         * @param {string} sid Session unique id generated by Portale.
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SessionsPortalSidPost(sid: string, body?: any, options?: any): AxiosPromise<PortaleSession> {
            return localVarFp.apiV1SessionsPortalSidPost(sid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Used to login in the system
         * @summary Login
         * @param {PostSession} [postSession]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SessionsPost(postSession?: PostSession, options?: any): AxiosPromise<Session> {
            return localVarFp.apiV1SessionsPost(postSession, options).then((request) => request(axios, basePath));
        },
        /**
         * Invalidates the session.
         * @summary Logout
         * @param {string} sid Session unique id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SessionsSidDelete(sid: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SessionsSidDelete(sid, options).then((request) => request(axios, basePath));
        },
        /**
         * Used to to change the session language
         * @summary Language Switch
         * @param {string} sid Session unique id.
         * @param {PatchSession} [patchSession]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SessionsSidPatch(sid: string, patchSession?: PatchSession, options?: any): AxiosPromise<Session> {
            return localVarFp.apiV1SessionsSidPatch(sid, patchSession, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SessionApi - object-oriented interface
 * @export
 * @class SessionApi
 * @extends {BaseAPI}
 */
export class SessionApi extends BaseAPI {
    /**
     * Used to validate a SID received from the centralized authentication server
     * @summary Assign Portale session
     * @param {string} sid Session unique id generated by Portale.
     * @param {PortaleDeleteSession} [portaleDeleteSession]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public apiV1SessionsPortalSidDelete(sid: string, portaleDeleteSession?: PortaleDeleteSession, options?: AxiosRequestConfig) {
        return SessionApiFp(this.configuration).apiV1SessionsPortalSidDelete(sid, portaleDeleteSession, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Used to validate a SID received from the centralized authentication server
     * @summary Assign Portale session
     * @param {string} sid Session unique id generated by Portale.
     * @param {PortalePatchSession} [portalePatchSession]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public apiV1SessionsPortalSidPatch(sid: string, portalePatchSession?: PortalePatchSession, options?: AxiosRequestConfig) {
        return SessionApiFp(this.configuration).apiV1SessionsPortalSidPatch(sid, portalePatchSession, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Used to validate a SID received from the centralized authentication server
     * @summary Get Portale session data
     * @param {string} sid Session unique id generated by Portale.
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public apiV1SessionsPortalSidPost(sid: string, body?: any, options?: AxiosRequestConfig) {
        return SessionApiFp(this.configuration).apiV1SessionsPortalSidPost(sid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Used to login in the system
     * @summary Login
     * @param {PostSession} [postSession]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public apiV1SessionsPost(postSession?: PostSession, options?: AxiosRequestConfig) {
        return SessionApiFp(this.configuration).apiV1SessionsPost(postSession, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Invalidates the session.
     * @summary Logout
     * @param {string} sid Session unique id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public apiV1SessionsSidDelete(sid: string, options?: AxiosRequestConfig) {
        return SessionApiFp(this.configuration).apiV1SessionsSidDelete(sid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Used to to change the session language
     * @summary Language Switch
     * @param {string} sid Session unique id.
     * @param {PatchSession} [patchSession]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public apiV1SessionsSidPatch(sid: string, patchSession?: PatchSession, options?: AxiosRequestConfig) {
        return SessionApiFp(this.configuration).apiV1SessionsSidPatch(sid, patchSession, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SospesiApi - axios parameter creator
 * @export
 */
export const SospesiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * nel json inviato, c\'e la voce \"importo\" che potrà non essere passata o se viene inserita deve essere null
         * @summary Modifica pagamenti effettuati
         * @param {string} dataDocumentonumDocumentocodNegozio
         * @param {DatiDaUsarePerAggiornare} [datiDaUsarePerAggiornare]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SospesiDataDocumentonumDocumentocodNegozioPatch: async (dataDocumentonumDocumentocodNegozio: string, datiDaUsarePerAggiornare?: DatiDaUsarePerAggiornare, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataDocumentonumDocumentocodNegozio' is not null or undefined
            assertParamExists('apiV1SospesiDataDocumentonumDocumentocodNegozioPatch', 'dataDocumentonumDocumentocodNegozio', dataDocumentonumDocumentocodNegozio)
            const localVarPath = `/api/v1/sospesi/{data_documento,num_documento,cod_negozio}`
                .replace(`{${"data_documento,num_documento,cod_negozio"}}`, encodeURIComponent(String(dataDocumentonumDocumentocodNegozio)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datiDaUsarePerAggiornare, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lista di pagamenti effettuati di sospesi
         * @summary Lista di pagamenti effettuati di sospesi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SospesiListaPagamentiGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sospesi/lista-pagamenti`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lista di sospesi
         * @summary Lista di sospesi
         * @param {string} [filterDataA] filtro data \&quot;fino a\&quot;: recupera la lista di documenti fino alla data indicata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SospesiListaSospesiGet: async (filterDataA?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sospesi/lista-sospesi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterDataA !== undefined) {
                localVarQueryParameter['filter[data_a]'] = filterDataA;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Conferma pagamento sospesi
         * @summary Conferma pagamento sospesi
         * @param {DatiPagamentoEBollettine} [datiPagamentoEBollettine]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SospesiPagamentoPost: async (datiPagamentoEBollettine?: DatiPagamentoEBollettine, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sospesi/pagamento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datiPagamentoEBollettine, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * vengono cercate le consumatrici corrispondenti che abbiano un sospeso
         * @summary cerca consumatrici con sospesi
         * @param {QueryParam} [queryParam]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SospesiRicercaConsumatriciPost: async (queryParam?: QueryParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sospesi/ricerca-consumatrici`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queryParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ricerca dei pagamenti sospesi con 2 possibili parametri: numero bollettina o codice consumatrice  è obbligatorio indicare almeno uno dei due parametri
         * @summary Ricerca pagamenti effettuati per bollettina e/o consumatrice
         * @param {string} [filterNumeroBollettina] Numero bollettina
         * @param {string} [filterPkConsumer] Codice consumatrice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SospesiRicercaGet: async (filterNumeroBollettina?: string, filterPkConsumer?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sospesi/ricerca`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterNumeroBollettina !== undefined) {
                localVarQueryParameter['filter[numero_bollettina]'] = filterNumeroBollettina;
            }

            if (filterPkConsumer !== undefined) {
                localVarQueryParameter['filter[pk_consumer]'] = filterPkConsumer;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ricerca pagamenti effettuati per bollettina e/o consumatrice
         * @summary Ricerca pagamenti effettuati per bollettina e/o consumatrice
         * @param {string} tipoLista tipo stampa richiesta: valori ammessi \&quot;all\&quot; o \&quot;scaduti\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SospesiStampaGet: async (tipoLista: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tipoLista' is not null or undefined
            assertParamExists('apiV1SospesiStampaGet', 'tipoLista', tipoLista)
            const localVarPath = `/api/v1/sospesi/stampa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tipoLista !== undefined) {
                localVarQueryParameter['tipo_lista'] = tipoLista;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ristampa del pagamenti di sospeso
         * @summary Ristampa del pagamenti di sospeso
         * @param {string} idTransazione
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SospesiStampaPagamentoIdTransazioneGet: async (idTransazione: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idTransazione' is not null or undefined
            assertParamExists('apiV1SospesiStampaPagamentoIdTransazioneGet', 'idTransazione', idTransazione)
            const localVarPath = `/api/v1/sospesi/stampa-pagamento/{id_transazione}`
                .replace(`{${"id_transazione"}}`, encodeURIComponent(String(idTransazione)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SospesiApi - functional programming interface
 * @export
 */
export const SospesiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SospesiApiAxiosParamCreator(configuration)
    return {
        /**
         * nel json inviato, c\'e la voce \"importo\" che potrà non essere passata o se viene inserita deve essere null
         * @summary Modifica pagamenti effettuati
         * @param {string} dataDocumentonumDocumentocodNegozio
         * @param {DatiDaUsarePerAggiornare} [datiDaUsarePerAggiornare]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SospesiDataDocumentonumDocumentocodNegozioPatch(dataDocumentonumDocumentocodNegozio: string, datiDaUsarePerAggiornare?: DatiDaUsarePerAggiornare, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModificaSospeso>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SospesiDataDocumentonumDocumentocodNegozioPatch(dataDocumentonumDocumentocodNegozio, datiDaUsarePerAggiornare, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lista di pagamenti effettuati di sospesi
         * @summary Lista di pagamenti effettuati di sospesi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SospesiListaPagamentiGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InformazioniListaPagamentiDiSospesi>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SospesiListaPagamentiGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lista di sospesi
         * @summary Lista di sospesi
         * @param {string} [filterDataA] filtro data \&quot;fino a\&quot;: recupera la lista di documenti fino alla data indicata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SospesiListaSospesiGet(filterDataA?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InformazioniCreditiSospesi>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SospesiListaSospesiGet(filterDataA, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Conferma pagamento sospesi
         * @summary Conferma pagamento sospesi
         * @param {DatiPagamentoEBollettine} [datiPagamentoEBollettine]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SospesiPagamentoPost(datiPagamentoEBollettine?: DatiPagamentoEBollettine, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InformazioniEsitoPagamentoSospesi>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SospesiPagamentoPost(datiPagamentoEBollettine, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * vengono cercate le consumatrici corrispondenti che abbiano un sospeso
         * @summary cerca consumatrici con sospesi
         * @param {QueryParam} [queryParam]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SospesiRicercaConsumatriciPost(queryParam?: QueryParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InformazioniConsumatriciTrovateConSospesi>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SospesiRicercaConsumatriciPost(queryParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ricerca dei pagamenti sospesi con 2 possibili parametri: numero bollettina o codice consumatrice  è obbligatorio indicare almeno uno dei due parametri
         * @summary Ricerca pagamenti effettuati per bollettina e/o consumatrice
         * @param {string} [filterNumeroBollettina] Numero bollettina
         * @param {string} [filterPkConsumer] Codice consumatrice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SospesiRicercaGet(filterNumeroBollettina?: string, filterPkConsumer?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InformazioniListaPagamentiEffettuati>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SospesiRicercaGet(filterNumeroBollettina, filterPkConsumer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Ricerca pagamenti effettuati per bollettina e/o consumatrice
         * @summary Ricerca pagamenti effettuati per bollettina e/o consumatrice
         * @param {string} tipoLista tipo stampa richiesta: valori ammessi \&quot;all\&quot; o \&quot;scaduti\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SospesiStampaGet(tipoLista: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StampaPdfRichiesta>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SospesiStampaGet(tipoLista, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Ristampa del pagamenti di sospeso
         * @summary Ristampa del pagamenti di sospeso
         * @param {string} idTransazione
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SospesiStampaPagamentoIdTransazioneGet(idTransazione: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StampaConfermata>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SospesiStampaPagamentoIdTransazioneGet(idTransazione, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SospesiApi - factory interface
 * @export
 */
export const SospesiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SospesiApiFp(configuration)
    return {
        /**
         * nel json inviato, c\'e la voce \"importo\" che potrà non essere passata o se viene inserita deve essere null
         * @summary Modifica pagamenti effettuati
         * @param {string} dataDocumentonumDocumentocodNegozio
         * @param {DatiDaUsarePerAggiornare} [datiDaUsarePerAggiornare]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SospesiDataDocumentonumDocumentocodNegozioPatch(dataDocumentonumDocumentocodNegozio: string, datiDaUsarePerAggiornare?: DatiDaUsarePerAggiornare, options?: any): AxiosPromise<ModificaSospeso> {
            return localVarFp.apiV1SospesiDataDocumentonumDocumentocodNegozioPatch(dataDocumentonumDocumentocodNegozio, datiDaUsarePerAggiornare, options).then((request) => request(axios, basePath));
        },
        /**
         * Lista di pagamenti effettuati di sospesi
         * @summary Lista di pagamenti effettuati di sospesi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SospesiListaPagamentiGet(options?: any): AxiosPromise<InformazioniListaPagamentiDiSospesi> {
            return localVarFp.apiV1SospesiListaPagamentiGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Lista di sospesi
         * @summary Lista di sospesi
         * @param {string} [filterDataA] filtro data \&quot;fino a\&quot;: recupera la lista di documenti fino alla data indicata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SospesiListaSospesiGet(filterDataA?: string, options?: any): AxiosPromise<InformazioniCreditiSospesi> {
            return localVarFp.apiV1SospesiListaSospesiGet(filterDataA, options).then((request) => request(axios, basePath));
        },
        /**
         * Conferma pagamento sospesi
         * @summary Conferma pagamento sospesi
         * @param {DatiPagamentoEBollettine} [datiPagamentoEBollettine]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SospesiPagamentoPost(datiPagamentoEBollettine?: DatiPagamentoEBollettine, options?: any): AxiosPromise<InformazioniEsitoPagamentoSospesi> {
            return localVarFp.apiV1SospesiPagamentoPost(datiPagamentoEBollettine, options).then((request) => request(axios, basePath));
        },
        /**
         * vengono cercate le consumatrici corrispondenti che abbiano un sospeso
         * @summary cerca consumatrici con sospesi
         * @param {QueryParam} [queryParam]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SospesiRicercaConsumatriciPost(queryParam?: QueryParam, options?: any): AxiosPromise<InformazioniConsumatriciTrovateConSospesi> {
            return localVarFp.apiV1SospesiRicercaConsumatriciPost(queryParam, options).then((request) => request(axios, basePath));
        },
        /**
         * ricerca dei pagamenti sospesi con 2 possibili parametri: numero bollettina o codice consumatrice  è obbligatorio indicare almeno uno dei due parametri
         * @summary Ricerca pagamenti effettuati per bollettina e/o consumatrice
         * @param {string} [filterNumeroBollettina] Numero bollettina
         * @param {string} [filterPkConsumer] Codice consumatrice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SospesiRicercaGet(filterNumeroBollettina?: string, filterPkConsumer?: string, options?: any): AxiosPromise<InformazioniListaPagamentiEffettuati> {
            return localVarFp.apiV1SospesiRicercaGet(filterNumeroBollettina, filterPkConsumer, options).then((request) => request(axios, basePath));
        },
        /**
         * Ricerca pagamenti effettuati per bollettina e/o consumatrice
         * @summary Ricerca pagamenti effettuati per bollettina e/o consumatrice
         * @param {string} tipoLista tipo stampa richiesta: valori ammessi \&quot;all\&quot; o \&quot;scaduti\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SospesiStampaGet(tipoLista: string, options?: any): AxiosPromise<StampaPdfRichiesta> {
            return localVarFp.apiV1SospesiStampaGet(tipoLista, options).then((request) => request(axios, basePath));
        },
        /**
         * Ristampa del pagamenti di sospeso
         * @summary Ristampa del pagamenti di sospeso
         * @param {string} idTransazione
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SospesiStampaPagamentoIdTransazioneGet(idTransazione: string, options?: any): AxiosPromise<StampaConfermata> {
            return localVarFp.apiV1SospesiStampaPagamentoIdTransazioneGet(idTransazione, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SospesiApi - object-oriented interface
 * @export
 * @class SospesiApi
 * @extends {BaseAPI}
 */
export class SospesiApi extends BaseAPI {
    /**
     * nel json inviato, c\'e la voce \"importo\" che potrà non essere passata o se viene inserita deve essere null
     * @summary Modifica pagamenti effettuati
     * @param {string} dataDocumentonumDocumentocodNegozio
     * @param {DatiDaUsarePerAggiornare} [datiDaUsarePerAggiornare]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SospesiApi
     */
    public apiV1SospesiDataDocumentonumDocumentocodNegozioPatch(dataDocumentonumDocumentocodNegozio: string, datiDaUsarePerAggiornare?: DatiDaUsarePerAggiornare, options?: AxiosRequestConfig) {
        return SospesiApiFp(this.configuration).apiV1SospesiDataDocumentonumDocumentocodNegozioPatch(dataDocumentonumDocumentocodNegozio, datiDaUsarePerAggiornare, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lista di pagamenti effettuati di sospesi
     * @summary Lista di pagamenti effettuati di sospesi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SospesiApi
     */
    public apiV1SospesiListaPagamentiGet(options?: AxiosRequestConfig) {
        return SospesiApiFp(this.configuration).apiV1SospesiListaPagamentiGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lista di sospesi
     * @summary Lista di sospesi
     * @param {string} [filterDataA] filtro data \&quot;fino a\&quot;: recupera la lista di documenti fino alla data indicata
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SospesiApi
     */
    public apiV1SospesiListaSospesiGet(filterDataA?: string, options?: AxiosRequestConfig) {
        return SospesiApiFp(this.configuration).apiV1SospesiListaSospesiGet(filterDataA, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Conferma pagamento sospesi
     * @summary Conferma pagamento sospesi
     * @param {DatiPagamentoEBollettine} [datiPagamentoEBollettine]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SospesiApi
     */
    public apiV1SospesiPagamentoPost(datiPagamentoEBollettine?: DatiPagamentoEBollettine, options?: AxiosRequestConfig) {
        return SospesiApiFp(this.configuration).apiV1SospesiPagamentoPost(datiPagamentoEBollettine, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * vengono cercate le consumatrici corrispondenti che abbiano un sospeso
     * @summary cerca consumatrici con sospesi
     * @param {QueryParam} [queryParam]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SospesiApi
     */
    public apiV1SospesiRicercaConsumatriciPost(queryParam?: QueryParam, options?: AxiosRequestConfig) {
        return SospesiApiFp(this.configuration).apiV1SospesiRicercaConsumatriciPost(queryParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ricerca dei pagamenti sospesi con 2 possibili parametri: numero bollettina o codice consumatrice  è obbligatorio indicare almeno uno dei due parametri
     * @summary Ricerca pagamenti effettuati per bollettina e/o consumatrice
     * @param {string} [filterNumeroBollettina] Numero bollettina
     * @param {string} [filterPkConsumer] Codice consumatrice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SospesiApi
     */
    public apiV1SospesiRicercaGet(filterNumeroBollettina?: string, filterPkConsumer?: string, options?: AxiosRequestConfig) {
        return SospesiApiFp(this.configuration).apiV1SospesiRicercaGet(filterNumeroBollettina, filterPkConsumer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Ricerca pagamenti effettuati per bollettina e/o consumatrice
     * @summary Ricerca pagamenti effettuati per bollettina e/o consumatrice
     * @param {string} tipoLista tipo stampa richiesta: valori ammessi \&quot;all\&quot; o \&quot;scaduti\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SospesiApi
     */
    public apiV1SospesiStampaGet(tipoLista: string, options?: AxiosRequestConfig) {
        return SospesiApiFp(this.configuration).apiV1SospesiStampaGet(tipoLista, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Ristampa del pagamenti di sospeso
     * @summary Ristampa del pagamenti di sospeso
     * @param {string} idTransazione
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SospesiApi
     */
    public apiV1SospesiStampaPagamentoIdTransazioneGet(idTransazione: string, options?: AxiosRequestConfig) {
        return SospesiApiFp(this.configuration).apiV1SospesiStampaPagamentoIdTransazioneGet(idTransazione, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StoreDataApi - axios parameter creator
 * @export
 */
export const StoreDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List all the store Cashes.
         * @summary Cashes
         * @param {string} [filterCashRole]
         * @param {string} [filterCashStatus]
         * @param {string} [filterCodCassa]
         * @param {string} [filterCodNegozio]
         * @param {string} [filterFeatureSet]
         * @param {string} [filterIpAddress]
         * @param {string} [filterIpPort]
         * @param {string} [filterIpProtocol]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebCashesGet: async (filterCashRole?: string, filterCashStatus?: string, filterCodCassa?: string, filterCodNegozio?: string, filterFeatureSet?: string, filterIpAddress?: string, filterIpPort?: string, filterIpProtocol?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/cashes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterCashRole !== undefined) {
                localVarQueryParameter['filter[cash_role]'] = filterCashRole;
            }

            if (filterCashStatus !== undefined) {
                localVarQueryParameter['filter[cash_status]'] = filterCashStatus;
            }

            if (filterCodCassa !== undefined) {
                localVarQueryParameter['filter[cod_cassa]'] = filterCodCassa;
            }

            if (filterCodNegozio !== undefined) {
                localVarQueryParameter['filter[cod_negozio]'] = filterCodNegozio;
            }

            if (filterFeatureSet !== undefined) {
                localVarQueryParameter['filter[feature_set]'] = filterFeatureSet;
            }

            if (filterIpAddress !== undefined) {
                localVarQueryParameter['filter[ip_address]'] = filterIpAddress;
            }

            if (filterIpPort !== undefined) {
                localVarQueryParameter['filter[ip_port]'] = filterIpPort;
            }

            if (filterIpProtocol !== undefined) {
                localVarQueryParameter['filter[ip_protocol]'] = filterIpProtocol;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all the store cashiers.
         * @summary Cashiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebCashiersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/cashiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoreDataApi - functional programming interface
 * @export
 */
export const StoreDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoreDataApiAxiosParamCreator(configuration)
    return {
        /**
         * List all the store Cashes.
         * @summary Cashes
         * @param {string} [filterCashRole]
         * @param {string} [filterCashStatus]
         * @param {string} [filterCodCassa]
         * @param {string} [filterCodNegozio]
         * @param {string} [filterFeatureSet]
         * @param {string} [filterIpAddress]
         * @param {string} [filterIpPort]
         * @param {string} [filterIpProtocol]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebCashesGet(filterCashRole?: string, filterCashStatus?: string, filterCodCassa?: string, filterCodNegozio?: string, filterFeatureSet?: string, filterIpAddress?: string, filterIpPort?: string, filterIpProtocol?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCashes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebCashesGet(filterCashRole, filterCashStatus, filterCodCassa, filterCodNegozio, filterFeatureSet, filterIpAddress, filterIpPort, filterIpProtocol, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List all the store cashiers.
         * @summary Cashiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebCashiersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCashiers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebCashiersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoreDataApi - factory interface
 * @export
 */
export const StoreDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoreDataApiFp(configuration)
    return {
        /**
         * List all the store Cashes.
         * @summary Cashes
         * @param {string} [filterCashRole]
         * @param {string} [filterCashStatus]
         * @param {string} [filterCodCassa]
         * @param {string} [filterCodNegozio]
         * @param {string} [filterFeatureSet]
         * @param {string} [filterIpAddress]
         * @param {string} [filterIpPort]
         * @param {string} [filterIpProtocol]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebCashesGet(filterCashRole?: string, filterCashStatus?: string, filterCodCassa?: string, filterCodNegozio?: string, filterFeatureSet?: string, filterIpAddress?: string, filterIpPort?: string, filterIpProtocol?: string, options?: any): AxiosPromise<GetCashes> {
            return localVarFp.apiV1PoswebCashesGet(filterCashRole, filterCashStatus, filterCodCassa, filterCodNegozio, filterFeatureSet, filterIpAddress, filterIpPort, filterIpProtocol, options).then((request) => request(axios, basePath));
        },
        /**
         * List all the store cashiers.
         * @summary Cashiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebCashiersGet(options?: any): AxiosPromise<GetCashiers> {
            return localVarFp.apiV1PoswebCashiersGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoreDataApi - object-oriented interface
 * @export
 * @class StoreDataApi
 * @extends {BaseAPI}
 */
export class StoreDataApi extends BaseAPI {
    /**
     * List all the store Cashes.
     * @summary Cashes
     * @param {string} [filterCashRole]
     * @param {string} [filterCashStatus]
     * @param {string} [filterCodCassa]
     * @param {string} [filterCodNegozio]
     * @param {string} [filterFeatureSet]
     * @param {string} [filterIpAddress]
     * @param {string} [filterIpPort]
     * @param {string} [filterIpProtocol]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreDataApi
     */
    public apiV1PoswebCashesGet(filterCashRole?: string, filterCashStatus?: string, filterCodCassa?: string, filterCodNegozio?: string, filterFeatureSet?: string, filterIpAddress?: string, filterIpPort?: string, filterIpProtocol?: string, options?: AxiosRequestConfig) {
        return StoreDataApiFp(this.configuration).apiV1PoswebCashesGet(filterCashRole, filterCashStatus, filterCodCassa, filterCodNegozio, filterFeatureSet, filterIpAddress, filterIpPort, filterIpProtocol, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all the store cashiers.
     * @summary Cashiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreDataApi
     */
    public apiV1PoswebCashiersGet(options?: AxiosRequestConfig) {
        return StoreDataApiFp(this.configuration).apiV1PoswebCashiersGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StoreManagementApi - axios parameter creator
 * @export
 */
export const StoreManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Provides data on the current cash flow situation. Both for the store (all cashes) and the selected cash.      The following keys are present only if the shop has configuration flag SALE_TEXTILE enabled            \"return_receipts_standard_capi\"           \"return_invoices_standard_capi\"           \"return_receipts_b2e_capi\"           \"return_invoices_b2e_capi\"           \"return_receipts_oss_capi\"           \"return_receipts_standard_tessuti\"           \"return_invoices_standard_tessuti\"           \"return_receipts_b2e_tessuti\"           \"return_invoices_b2e_tessuti\"           \"return_receipts_oss_tessuti\"
         * @summary Cash Controlling
         * @param {string} cashId Cash unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebCashesCashIdStatusGet: async (cashId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cashId' is not null or undefined
            assertParamExists('apiV1PoswebCashesCashIdStatusGet', 'cashId', cashId)
            const localVarPath = `/api/v1/posweb/cashes/{cash_id}/status`
                .replace(`{${"cash_id"}}`, encodeURIComponent(String(cashId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Prints cash register report for credit card transaction
         * @summary Cash Controlling
         * @param {string} cashId Cash unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebCashesCashIdStatusPaymentsCreditCardReceiptPost: async (cashId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cashId' is not null or undefined
            assertParamExists('apiV1PoswebCashesCashIdStatusPaymentsCreditCardReceiptPost', 'cashId', cashId)
            const localVarPath = `/api/v1/posweb/cashes/{cash_id}/status/payments/credit_card/receipt`
                .replace(`{${"cash_id"}}`, encodeURIComponent(String(cashId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Prints receipt for cash control
         * @summary Cash Controlling
         * @param {string} cashId Cash unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebCashesCashIdStatusReceiptPost: async (cashId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cashId' is not null or undefined
            assertParamExists('apiV1PoswebCashesCashIdStatusReceiptPost', 'cashId', cashId)
            const localVarPath = `/api/v1/posweb/cashes/{cash_id}/status/receipt`
                .replace(`{${"cash_id"}}`, encodeURIComponent(String(cashId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Local VAT info
         * @summary Obtains local VAT rate
         * @param {string} [filterNazione] Country
         * @param {string} [filterTargetType] Target type
         * @param {string} [filterTarget] Target
         * @param {string} [filterActive] Active if 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebVatCodesGet: async (filterNazione?: string, filterTargetType?: string, filterTarget?: string, filterActive?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/vat_codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterNazione !== undefined) {
                localVarQueryParameter['filter[nazione]'] = filterNazione;
            }

            if (filterTargetType !== undefined) {
                localVarQueryParameter['filter[target_type]'] = filterTargetType;
            }

            if (filterTarget !== undefined) {
                localVarQueryParameter['filter[target]'] = filterTarget;
            }

            if (filterActive !== undefined) {
                localVarQueryParameter['filter[active]'] = filterActive;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of payment types
         * @summary List of available payments
         * @param {'sale' | 'return' | 'layby' | 'suspended_sale'} [filterType] Type of section where payments list is requested
         * @param {0 | 1} [filterOutStore] B2E parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesPaymentsGet: async (filterType?: 'sale' | 'return' | 'layby' | 'suspended_sale', filterOutStore?: 0 | 1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sales/payments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterType !== undefined) {
                localVarQueryParameter['filter[type]'] = filterType;
            }

            if (filterOutStore !== undefined) {
                localVarQueryParameter['filter[out_store]'] = filterOutStore;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoreManagementApi - functional programming interface
 * @export
 */
export const StoreManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoreManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * Provides data on the current cash flow situation. Both for the store (all cashes) and the selected cash.      The following keys are present only if the shop has configuration flag SALE_TEXTILE enabled            \"return_receipts_standard_capi\"           \"return_invoices_standard_capi\"           \"return_receipts_b2e_capi\"           \"return_invoices_b2e_capi\"           \"return_receipts_oss_capi\"           \"return_receipts_standard_tessuti\"           \"return_invoices_standard_tessuti\"           \"return_receipts_b2e_tessuti\"           \"return_invoices_b2e_tessuti\"           \"return_receipts_oss_tessuti\"
         * @summary Cash Controlling
         * @param {string} cashId Cash unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebCashesCashIdStatusGet(cashId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCashFlowStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebCashesCashIdStatusGet(cashId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Prints cash register report for credit card transaction
         * @summary Cash Controlling
         * @param {string} cashId Cash unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebCashesCashIdStatusPaymentsCreditCardReceiptPost(cashId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse204>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebCashesCashIdStatusPaymentsCreditCardReceiptPost(cashId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Prints receipt for cash control
         * @summary Cash Controlling
         * @param {string} cashId Cash unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebCashesCashIdStatusReceiptPost(cashId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2041>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebCashesCashIdStatusReceiptPost(cashId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Local VAT info
         * @summary Obtains local VAT rate
         * @param {string} [filterNazione] Country
         * @param {string} [filterTargetType] Target type
         * @param {string} [filterTarget] Target
         * @param {string} [filterActive] Active if 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebVatCodesGet(filterNazione?: string, filterTargetType?: string, filterTarget?: string, filterActive?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVATRate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebVatCodesGet(filterNazione, filterTargetType, filterTarget, filterActive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of payment types
         * @summary List of available payments
         * @param {'sale' | 'return' | 'layby' | 'suspended_sale'} [filterType] Type of section where payments list is requested
         * @param {0 | 1} [filterOutStore] B2E parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SalesPaymentsGet(filterType?: 'sale' | 'return' | 'layby' | 'suspended_sale', filterOutStore?: 0 | 1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllowedPayments>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SalesPaymentsGet(filterType, filterOutStore, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoreManagementApi - factory interface
 * @export
 */
export const StoreManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoreManagementApiFp(configuration)
    return {
        /**
         * Provides data on the current cash flow situation. Both for the store (all cashes) and the selected cash.      The following keys are present only if the shop has configuration flag SALE_TEXTILE enabled            \"return_receipts_standard_capi\"           \"return_invoices_standard_capi\"           \"return_receipts_b2e_capi\"           \"return_invoices_b2e_capi\"           \"return_receipts_oss_capi\"           \"return_receipts_standard_tessuti\"           \"return_invoices_standard_tessuti\"           \"return_receipts_b2e_tessuti\"           \"return_invoices_b2e_tessuti\"           \"return_receipts_oss_tessuti\"
         * @summary Cash Controlling
         * @param {string} cashId Cash unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebCashesCashIdStatusGet(cashId: string, options?: any): AxiosPromise<GetCashFlowStatus> {
            return localVarFp.apiV1PoswebCashesCashIdStatusGet(cashId, options).then((request) => request(axios, basePath));
        },
        /**
         * Prints cash register report for credit card transaction
         * @summary Cash Controlling
         * @param {string} cashId Cash unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebCashesCashIdStatusPaymentsCreditCardReceiptPost(cashId: string, options?: any): AxiosPromise<InlineResponse204> {
            return localVarFp.apiV1PoswebCashesCashIdStatusPaymentsCreditCardReceiptPost(cashId, options).then((request) => request(axios, basePath));
        },
        /**
         * Prints receipt for cash control
         * @summary Cash Controlling
         * @param {string} cashId Cash unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebCashesCashIdStatusReceiptPost(cashId: string, options?: any): AxiosPromise<InlineResponse2041> {
            return localVarFp.apiV1PoswebCashesCashIdStatusReceiptPost(cashId, options).then((request) => request(axios, basePath));
        },
        /**
         * Local VAT info
         * @summary Obtains local VAT rate
         * @param {string} [filterNazione] Country
         * @param {string} [filterTargetType] Target type
         * @param {string} [filterTarget] Target
         * @param {string} [filterActive] Active if 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebVatCodesGet(filterNazione?: string, filterTargetType?: string, filterTarget?: string, filterActive?: string, options?: any): AxiosPromise<GetVATRate> {
            return localVarFp.apiV1PoswebVatCodesGet(filterNazione, filterTargetType, filterTarget, filterActive, options).then((request) => request(axios, basePath));
        },
        /**
         * List of payment types
         * @summary List of available payments
         * @param {'sale' | 'return' | 'layby' | 'suspended_sale'} [filterType] Type of section where payments list is requested
         * @param {0 | 1} [filterOutStore] B2E parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SalesPaymentsGet(filterType?: 'sale' | 'return' | 'layby' | 'suspended_sale', filterOutStore?: 0 | 1, options?: any): AxiosPromise<GetAllowedPayments> {
            return localVarFp.apiV1SalesPaymentsGet(filterType, filterOutStore, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoreManagementApi - object-oriented interface
 * @export
 * @class StoreManagementApi
 * @extends {BaseAPI}
 */
export class StoreManagementApi extends BaseAPI {
    /**
     * Provides data on the current cash flow situation. Both for the store (all cashes) and the selected cash.      The following keys are present only if the shop has configuration flag SALE_TEXTILE enabled            \"return_receipts_standard_capi\"           \"return_invoices_standard_capi\"           \"return_receipts_b2e_capi\"           \"return_invoices_b2e_capi\"           \"return_receipts_oss_capi\"           \"return_receipts_standard_tessuti\"           \"return_invoices_standard_tessuti\"           \"return_receipts_b2e_tessuti\"           \"return_invoices_b2e_tessuti\"           \"return_receipts_oss_tessuti\"
     * @summary Cash Controlling
     * @param {string} cashId Cash unique id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreManagementApi
     */
    public apiV1PoswebCashesCashIdStatusGet(cashId: string, options?: AxiosRequestConfig) {
        return StoreManagementApiFp(this.configuration).apiV1PoswebCashesCashIdStatusGet(cashId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Prints cash register report for credit card transaction
     * @summary Cash Controlling
     * @param {string} cashId Cash unique id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreManagementApi
     */
    public apiV1PoswebCashesCashIdStatusPaymentsCreditCardReceiptPost(cashId: string, options?: AxiosRequestConfig) {
        return StoreManagementApiFp(this.configuration).apiV1PoswebCashesCashIdStatusPaymentsCreditCardReceiptPost(cashId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Prints receipt for cash control
     * @summary Cash Controlling
     * @param {string} cashId Cash unique id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreManagementApi
     */
    public apiV1PoswebCashesCashIdStatusReceiptPost(cashId: string, options?: AxiosRequestConfig) {
        return StoreManagementApiFp(this.configuration).apiV1PoswebCashesCashIdStatusReceiptPost(cashId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Local VAT info
     * @summary Obtains local VAT rate
     * @param {string} [filterNazione] Country
     * @param {string} [filterTargetType] Target type
     * @param {string} [filterTarget] Target
     * @param {string} [filterActive] Active if 1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreManagementApi
     */
    public apiV1PoswebVatCodesGet(filterNazione?: string, filterTargetType?: string, filterTarget?: string, filterActive?: string, options?: AxiosRequestConfig) {
        return StoreManagementApiFp(this.configuration).apiV1PoswebVatCodesGet(filterNazione, filterTargetType, filterTarget, filterActive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of payment types
     * @summary List of available payments
     * @param {'sale' | 'return' | 'layby' | 'suspended_sale'} [filterType] Type of section where payments list is requested
     * @param {0 | 1} [filterOutStore] B2E parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreManagementApi
     */
    public apiV1SalesPaymentsGet(filterType?: 'sale' | 'return' | 'layby' | 'suspended_sale', filterOutStore?: 0 | 1, options?: AxiosRequestConfig) {
        return StoreManagementApiFp(this.configuration).apiV1SalesPaymentsGet(filterType, filterOutStore, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StrumentiApi - axios parameter creator
 * @export
 */
export const StrumentiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Recupera  configurazione ma solo parametri ammessi alla modifica da postazione corrente
         * @summary Recupera Configurazione postazione
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiBundleConfigGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/strumenti/bundle-config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Abilitazione registrazione di cassa si/no  il campo \"valori\" contiene  JSON.stringify(object)
         * @summary Configurazione postazione
         * @param {StrumentiConfigurazionePostazione} [strumentiConfigurazionePostazione]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiBundleEditPost: async (strumentiConfigurazionePostazione?: StrumentiConfigurazionePostazione, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/strumenti/bundle-edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(strumentiConfigurazionePostazione, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Recupera elenco casse non master, per le quali si può forzare le chiusura.  Solo utente \'assistenza\' è abilitato a vedere la lista
         * @summary Recupera Elenco casse (non master)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiCashListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/strumenti/cash-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Invia una data al backend per modificare la data di configurazione
         * @summary Modifica data programma
         * @param {StrumentiChangeDate} [strumentiChangeDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiChangeDatePost: async (strumentiChangeDate?: StrumentiChangeDate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/strumenti/change-date`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(strumentiChangeDate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viene richiamato il servizio di controllo codice autorizzazione, in caso affermativo la funzionalità viene resa disponibile
         * @summary Controllo del codice autorizzazione
         * @param {StrumentiControlloCodiceAutorizzazione} [strumentiControlloCodiceAutorizzazione]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiCheckCodiceAutorizzazionePost: async (strumentiControlloCodiceAutorizzazione?: StrumentiControlloCodiceAutorizzazione, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/strumenti/check-codice-autorizzazione`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(strumentiControlloCodiceAutorizzazione, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Forzatura chiusura cassa
         * @summary Forzatura chiusura cassa
         * @param {StrumentiForceCashClosure} [strumentiForceCashClosure]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiForceCashClosurePost: async (strumentiForceCashClosure?: StrumentiForceCashClosure, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/strumenti/force-cash-closure`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(strumentiForceCashClosure, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Per attivare alcune funzioni del deve essere inoltrata richiesta di autorizzazione      Funzioni sotto autorizzazione:       CAMBIO_DATA del sistema
         * @summary Richiede codice autorizzazione funzioni
         * @param {StrumentiRichiestaAutorizzazione} [strumentiRichiestaAutorizzazione]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiGetCodiceAutorizzazionePost: async (strumentiRichiestaAutorizzazione?: StrumentiRichiestaAutorizzazione, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/strumenti/get-codice-autorizzazione`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(strumentiRichiestaAutorizzazione, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Servizio che viene richiamato per impostare data e ora correnti sul registratore di cassa
         * @summary Regola data e ora Kube
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiRegolaDataOraKubePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/strumenti/regola-data-ora-kube`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viene richiamato il webservice ws_removal_sid per rimuovere il SID
         * @summary Cancella SID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiRemovalSidPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/strumenti/removal-sid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Solo personale di assistenza è abilitato ad eseguire questa funzione
         * @summary Invia comando alla stampante
         * @param {StrumentiInvioComandiAllaStampante} [strumentiInvioComandiAllaStampante]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiSendCommandToCustomPrinterPost: async (strumentiInvioComandiAllaStampante?: StrumentiInvioComandiAllaStampante, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/strumenti/send-command-to-custom-printer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(strumentiInvioComandiAllaStampante, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Recupera flag che indicano se mostrare o meno le sezioni degli strumenti in base a ruolo e utente
         * @summary Recupera info su sezioni da mostrare
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiSezioniAbilitateGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/strumenti/sezioni-abilitate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viene richiamato il servizio di chiusura cassa
         * @summary Effettua la chiusura del registratore di cassa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiStampaChiusuraRegistratorePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/strumenti/stampa-chiusura-registratore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Per testare il funzionamento del terminale di pos viene richiamato questo servizio backend che lancia una stampa dei totali sul terminale pos      current_date = new Date().getTime().toString()
         * @summary Test terminale POS
         * @param {StrumentiStampaTotaliPos} [strumentiStampaTotaliPos]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiStampaTotaliPosPost: async (strumentiStampaTotaliPos?: StrumentiStampaTotaliPos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/strumenti/stampa-totali-pos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(strumentiStampaTotaliPos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Il servizio che viene richiamato, si occupa di testare la stampa dei caratteri accentati e barcode. Si tratta, in pratica, di una stampa di prova
         * @summary Test registratore di cassa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiTestKubePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/strumenti/test-kube`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StrumentiApi - functional programming interface
 * @export
 */
export const StrumentiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StrumentiApiAxiosParamCreator(configuration)
    return {
        /**
         * Recupera  configurazione ma solo parametri ammessi alla modifica da postazione corrente
         * @summary Recupera Configurazione postazione
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StrumentiBundleConfigGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StrumentiGetConfigurazione>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StrumentiBundleConfigGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Abilitazione registrazione di cassa si/no  il campo \"valori\" contiene  JSON.stringify(object)
         * @summary Configurazione postazione
         * @param {StrumentiConfigurazionePostazione} [strumentiConfigurazionePostazione]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StrumentiBundleEditPost(strumentiConfigurazionePostazione?: StrumentiConfigurazionePostazione, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StrumentiEsitoConfigurazione>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StrumentiBundleEditPost(strumentiConfigurazionePostazione, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Recupera elenco casse non master, per le quali si può forzare le chiusura.  Solo utente \'assistenza\' è abilitato a vedere la lista
         * @summary Recupera Elenco casse (non master)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StrumentiCashListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StrumentiResponseCashList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StrumentiCashListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Invia una data al backend per modificare la data di configurazione
         * @summary Modifica data programma
         * @param {StrumentiChangeDate} [strumentiChangeDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StrumentiChangeDatePost(strumentiChangeDate?: StrumentiChangeDate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StrumentiEsitoChangeDate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StrumentiChangeDatePost(strumentiChangeDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Viene richiamato il servizio di controllo codice autorizzazione, in caso affermativo la funzionalità viene resa disponibile
         * @summary Controllo del codice autorizzazione
         * @param {StrumentiControlloCodiceAutorizzazione} [strumentiControlloCodiceAutorizzazione]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StrumentiCheckCodiceAutorizzazionePost(strumentiControlloCodiceAutorizzazione?: StrumentiControlloCodiceAutorizzazione, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StrumentiControlloAutorizzazione>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StrumentiCheckCodiceAutorizzazionePost(strumentiControlloCodiceAutorizzazione, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Forzatura chiusura cassa
         * @summary Forzatura chiusura cassa
         * @param {StrumentiForceCashClosure} [strumentiForceCashClosure]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StrumentiForceCashClosurePost(strumentiForceCashClosure?: StrumentiForceCashClosure, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StrumentiResponseForceCashClosure>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StrumentiForceCashClosurePost(strumentiForceCashClosure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Per attivare alcune funzioni del deve essere inoltrata richiesta di autorizzazione      Funzioni sotto autorizzazione:       CAMBIO_DATA del sistema
         * @summary Richiede codice autorizzazione funzioni
         * @param {StrumentiRichiestaAutorizzazione} [strumentiRichiestaAutorizzazione]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StrumentiGetCodiceAutorizzazionePost(strumentiRichiestaAutorizzazione?: StrumentiRichiestaAutorizzazione, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StrumentiConcessioneAutorizzazione>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StrumentiGetCodiceAutorizzazionePost(strumentiRichiestaAutorizzazione, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Servizio che viene richiamato per impostare data e ora correnti sul registratore di cassa
         * @summary Regola data e ora Kube
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StrumentiRegolaDataOraKubePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StrumentiSetKubeDate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StrumentiRegolaDataOraKubePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Viene richiamato il webservice ws_removal_sid per rimuovere il SID
         * @summary Cancella SID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StrumentiRemovalSidPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StrumentiRemovalSid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StrumentiRemovalSidPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Solo personale di assistenza è abilitato ad eseguire questa funzione
         * @summary Invia comando alla stampante
         * @param {StrumentiInvioComandiAllaStampante} [strumentiInvioComandiAllaStampante]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StrumentiSendCommandToCustomPrinterPost(strumentiInvioComandiAllaStampante?: StrumentiInvioComandiAllaStampante, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StrumentiEsitoInvioComandiStampante>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StrumentiSendCommandToCustomPrinterPost(strumentiInvioComandiAllaStampante, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Recupera flag che indicano se mostrare o meno le sezioni degli strumenti in base a ruolo e utente
         * @summary Recupera info su sezioni da mostrare
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StrumentiSezioniAbilitateGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StrumentiResponseSezioniAbilitate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StrumentiSezioniAbilitateGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Viene richiamato il servizio di chiusura cassa
         * @summary Effettua la chiusura del registratore di cassa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StrumentiStampaChiusuraRegistratorePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StrumentiStampaChiusuraFallita>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StrumentiStampaChiusuraRegistratorePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Per testare il funzionamento del terminale di pos viene richiamato questo servizio backend che lancia una stampa dei totali sul terminale pos      current_date = new Date().getTime().toString()
         * @summary Test terminale POS
         * @param {StrumentiStampaTotaliPos} [strumentiStampaTotaliPos]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StrumentiStampaTotaliPosPost(strumentiStampaTotaliPos?: StrumentiStampaTotaliPos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StrumentiEsitoStampaTotali>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StrumentiStampaTotaliPosPost(strumentiStampaTotaliPos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Il servizio che viene richiamato, si occupa di testare la stampa dei caratteri accentati e barcode. Si tratta, in pratica, di una stampa di prova
         * @summary Test registratore di cassa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StrumentiTestKubePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StrumentiTestKube>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StrumentiTestKubePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StrumentiApi - factory interface
 * @export
 */
export const StrumentiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StrumentiApiFp(configuration)
    return {
        /**
         * Recupera  configurazione ma solo parametri ammessi alla modifica da postazione corrente
         * @summary Recupera Configurazione postazione
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiBundleConfigGet(options?: any): AxiosPromise<StrumentiGetConfigurazione> {
            return localVarFp.apiV1StrumentiBundleConfigGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Abilitazione registrazione di cassa si/no  il campo \"valori\" contiene  JSON.stringify(object)
         * @summary Configurazione postazione
         * @param {StrumentiConfigurazionePostazione} [strumentiConfigurazionePostazione]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiBundleEditPost(strumentiConfigurazionePostazione?: StrumentiConfigurazionePostazione, options?: any): AxiosPromise<StrumentiEsitoConfigurazione> {
            return localVarFp.apiV1StrumentiBundleEditPost(strumentiConfigurazionePostazione, options).then((request) => request(axios, basePath));
        },
        /**
         * Recupera elenco casse non master, per le quali si può forzare le chiusura.  Solo utente \'assistenza\' è abilitato a vedere la lista
         * @summary Recupera Elenco casse (non master)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiCashListGet(options?: any): AxiosPromise<StrumentiResponseCashList> {
            return localVarFp.apiV1StrumentiCashListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Invia una data al backend per modificare la data di configurazione
         * @summary Modifica data programma
         * @param {StrumentiChangeDate} [strumentiChangeDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiChangeDatePost(strumentiChangeDate?: StrumentiChangeDate, options?: any): AxiosPromise<StrumentiEsitoChangeDate> {
            return localVarFp.apiV1StrumentiChangeDatePost(strumentiChangeDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Viene richiamato il servizio di controllo codice autorizzazione, in caso affermativo la funzionalità viene resa disponibile
         * @summary Controllo del codice autorizzazione
         * @param {StrumentiControlloCodiceAutorizzazione} [strumentiControlloCodiceAutorizzazione]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiCheckCodiceAutorizzazionePost(strumentiControlloCodiceAutorizzazione?: StrumentiControlloCodiceAutorizzazione, options?: any): AxiosPromise<StrumentiControlloAutorizzazione> {
            return localVarFp.apiV1StrumentiCheckCodiceAutorizzazionePost(strumentiControlloCodiceAutorizzazione, options).then((request) => request(axios, basePath));
        },
        /**
         * Forzatura chiusura cassa
         * @summary Forzatura chiusura cassa
         * @param {StrumentiForceCashClosure} [strumentiForceCashClosure]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiForceCashClosurePost(strumentiForceCashClosure?: StrumentiForceCashClosure, options?: any): AxiosPromise<StrumentiResponseForceCashClosure> {
            return localVarFp.apiV1StrumentiForceCashClosurePost(strumentiForceCashClosure, options).then((request) => request(axios, basePath));
        },
        /**
         * Per attivare alcune funzioni del deve essere inoltrata richiesta di autorizzazione      Funzioni sotto autorizzazione:       CAMBIO_DATA del sistema
         * @summary Richiede codice autorizzazione funzioni
         * @param {StrumentiRichiestaAutorizzazione} [strumentiRichiestaAutorizzazione]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiGetCodiceAutorizzazionePost(strumentiRichiestaAutorizzazione?: StrumentiRichiestaAutorizzazione, options?: any): AxiosPromise<StrumentiConcessioneAutorizzazione> {
            return localVarFp.apiV1StrumentiGetCodiceAutorizzazionePost(strumentiRichiestaAutorizzazione, options).then((request) => request(axios, basePath));
        },
        /**
         * Servizio che viene richiamato per impostare data e ora correnti sul registratore di cassa
         * @summary Regola data e ora Kube
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiRegolaDataOraKubePost(options?: any): AxiosPromise<StrumentiSetKubeDate> {
            return localVarFp.apiV1StrumentiRegolaDataOraKubePost(options).then((request) => request(axios, basePath));
        },
        /**
         * Viene richiamato il webservice ws_removal_sid per rimuovere il SID
         * @summary Cancella SID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiRemovalSidPost(options?: any): AxiosPromise<StrumentiRemovalSid> {
            return localVarFp.apiV1StrumentiRemovalSidPost(options).then((request) => request(axios, basePath));
        },
        /**
         * Solo personale di assistenza è abilitato ad eseguire questa funzione
         * @summary Invia comando alla stampante
         * @param {StrumentiInvioComandiAllaStampante} [strumentiInvioComandiAllaStampante]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiSendCommandToCustomPrinterPost(strumentiInvioComandiAllaStampante?: StrumentiInvioComandiAllaStampante, options?: any): AxiosPromise<StrumentiEsitoInvioComandiStampante> {
            return localVarFp.apiV1StrumentiSendCommandToCustomPrinterPost(strumentiInvioComandiAllaStampante, options).then((request) => request(axios, basePath));
        },
        /**
         * Recupera flag che indicano se mostrare o meno le sezioni degli strumenti in base a ruolo e utente
         * @summary Recupera info su sezioni da mostrare
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiSezioniAbilitateGet(options?: any): AxiosPromise<StrumentiResponseSezioniAbilitate> {
            return localVarFp.apiV1StrumentiSezioniAbilitateGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Viene richiamato il servizio di chiusura cassa
         * @summary Effettua la chiusura del registratore di cassa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiStampaChiusuraRegistratorePost(options?: any): AxiosPromise<StrumentiStampaChiusuraFallita> {
            return localVarFp.apiV1StrumentiStampaChiusuraRegistratorePost(options).then((request) => request(axios, basePath));
        },
        /**
         * Per testare il funzionamento del terminale di pos viene richiamato questo servizio backend che lancia una stampa dei totali sul terminale pos      current_date = new Date().getTime().toString()
         * @summary Test terminale POS
         * @param {StrumentiStampaTotaliPos} [strumentiStampaTotaliPos]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiStampaTotaliPosPost(strumentiStampaTotaliPos?: StrumentiStampaTotaliPos, options?: any): AxiosPromise<StrumentiEsitoStampaTotali> {
            return localVarFp.apiV1StrumentiStampaTotaliPosPost(strumentiStampaTotaliPos, options).then((request) => request(axios, basePath));
        },
        /**
         * Il servizio che viene richiamato, si occupa di testare la stampa dei caratteri accentati e barcode. Si tratta, in pratica, di una stampa di prova
         * @summary Test registratore di cassa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StrumentiTestKubePost(options?: any): AxiosPromise<StrumentiTestKube> {
            return localVarFp.apiV1StrumentiTestKubePost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StrumentiApi - object-oriented interface
 * @export
 * @class StrumentiApi
 * @extends {BaseAPI}
 */
export class StrumentiApi extends BaseAPI {
    /**
     * Recupera  configurazione ma solo parametri ammessi alla modifica da postazione corrente
     * @summary Recupera Configurazione postazione
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrumentiApi
     */
    public apiV1StrumentiBundleConfigGet(options?: AxiosRequestConfig) {
        return StrumentiApiFp(this.configuration).apiV1StrumentiBundleConfigGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Abilitazione registrazione di cassa si/no  il campo \"valori\" contiene  JSON.stringify(object)
     * @summary Configurazione postazione
     * @param {StrumentiConfigurazionePostazione} [strumentiConfigurazionePostazione]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrumentiApi
     */
    public apiV1StrumentiBundleEditPost(strumentiConfigurazionePostazione?: StrumentiConfigurazionePostazione, options?: AxiosRequestConfig) {
        return StrumentiApiFp(this.configuration).apiV1StrumentiBundleEditPost(strumentiConfigurazionePostazione, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Recupera elenco casse non master, per le quali si può forzare le chiusura.  Solo utente \'assistenza\' è abilitato a vedere la lista
     * @summary Recupera Elenco casse (non master)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrumentiApi
     */
    public apiV1StrumentiCashListGet(options?: AxiosRequestConfig) {
        return StrumentiApiFp(this.configuration).apiV1StrumentiCashListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Invia una data al backend per modificare la data di configurazione
     * @summary Modifica data programma
     * @param {StrumentiChangeDate} [strumentiChangeDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrumentiApi
     */
    public apiV1StrumentiChangeDatePost(strumentiChangeDate?: StrumentiChangeDate, options?: AxiosRequestConfig) {
        return StrumentiApiFp(this.configuration).apiV1StrumentiChangeDatePost(strumentiChangeDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viene richiamato il servizio di controllo codice autorizzazione, in caso affermativo la funzionalità viene resa disponibile
     * @summary Controllo del codice autorizzazione
     * @param {StrumentiControlloCodiceAutorizzazione} [strumentiControlloCodiceAutorizzazione]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrumentiApi
     */
    public apiV1StrumentiCheckCodiceAutorizzazionePost(strumentiControlloCodiceAutorizzazione?: StrumentiControlloCodiceAutorizzazione, options?: AxiosRequestConfig) {
        return StrumentiApiFp(this.configuration).apiV1StrumentiCheckCodiceAutorizzazionePost(strumentiControlloCodiceAutorizzazione, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Forzatura chiusura cassa
     * @summary Forzatura chiusura cassa
     * @param {StrumentiForceCashClosure} [strumentiForceCashClosure]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrumentiApi
     */
    public apiV1StrumentiForceCashClosurePost(strumentiForceCashClosure?: StrumentiForceCashClosure, options?: AxiosRequestConfig) {
        return StrumentiApiFp(this.configuration).apiV1StrumentiForceCashClosurePost(strumentiForceCashClosure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Per attivare alcune funzioni del deve essere inoltrata richiesta di autorizzazione      Funzioni sotto autorizzazione:       CAMBIO_DATA del sistema
     * @summary Richiede codice autorizzazione funzioni
     * @param {StrumentiRichiestaAutorizzazione} [strumentiRichiestaAutorizzazione]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrumentiApi
     */
    public apiV1StrumentiGetCodiceAutorizzazionePost(strumentiRichiestaAutorizzazione?: StrumentiRichiestaAutorizzazione, options?: AxiosRequestConfig) {
        return StrumentiApiFp(this.configuration).apiV1StrumentiGetCodiceAutorizzazionePost(strumentiRichiestaAutorizzazione, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Servizio che viene richiamato per impostare data e ora correnti sul registratore di cassa
     * @summary Regola data e ora Kube
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrumentiApi
     */
    public apiV1StrumentiRegolaDataOraKubePost(options?: AxiosRequestConfig) {
        return StrumentiApiFp(this.configuration).apiV1StrumentiRegolaDataOraKubePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viene richiamato il webservice ws_removal_sid per rimuovere il SID
     * @summary Cancella SID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrumentiApi
     */
    public apiV1StrumentiRemovalSidPost(options?: AxiosRequestConfig) {
        return StrumentiApiFp(this.configuration).apiV1StrumentiRemovalSidPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Solo personale di assistenza è abilitato ad eseguire questa funzione
     * @summary Invia comando alla stampante
     * @param {StrumentiInvioComandiAllaStampante} [strumentiInvioComandiAllaStampante]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrumentiApi
     */
    public apiV1StrumentiSendCommandToCustomPrinterPost(strumentiInvioComandiAllaStampante?: StrumentiInvioComandiAllaStampante, options?: AxiosRequestConfig) {
        return StrumentiApiFp(this.configuration).apiV1StrumentiSendCommandToCustomPrinterPost(strumentiInvioComandiAllaStampante, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Recupera flag che indicano se mostrare o meno le sezioni degli strumenti in base a ruolo e utente
     * @summary Recupera info su sezioni da mostrare
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrumentiApi
     */
    public apiV1StrumentiSezioniAbilitateGet(options?: AxiosRequestConfig) {
        return StrumentiApiFp(this.configuration).apiV1StrumentiSezioniAbilitateGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viene richiamato il servizio di chiusura cassa
     * @summary Effettua la chiusura del registratore di cassa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrumentiApi
     */
    public apiV1StrumentiStampaChiusuraRegistratorePost(options?: AxiosRequestConfig) {
        return StrumentiApiFp(this.configuration).apiV1StrumentiStampaChiusuraRegistratorePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Per testare il funzionamento del terminale di pos viene richiamato questo servizio backend che lancia una stampa dei totali sul terminale pos      current_date = new Date().getTime().toString()
     * @summary Test terminale POS
     * @param {StrumentiStampaTotaliPos} [strumentiStampaTotaliPos]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrumentiApi
     */
    public apiV1StrumentiStampaTotaliPosPost(strumentiStampaTotaliPos?: StrumentiStampaTotaliPos, options?: AxiosRequestConfig) {
        return StrumentiApiFp(this.configuration).apiV1StrumentiStampaTotaliPosPost(strumentiStampaTotaliPos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Il servizio che viene richiamato, si occupa di testare la stampa dei caratteri accentati e barcode. Si tratta, in pratica, di una stampa di prova
     * @summary Test registratore di cassa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrumentiApi
     */
    public apiV1StrumentiTestKubePost(options?: AxiosRequestConfig) {
        return StrumentiApiFp(this.configuration).apiV1StrumentiTestKubePost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the config of the current store.
         * @summary Store Config
         * @param {string} [filterSource] Client token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebConfigGet: async (filterSource?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterSource !== undefined) {
                localVarQueryParameter['filter[source]'] = filterSource;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of countries
         * @summary List of countries data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebCountriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the global config of the current installation, can be interpreted as a view on the .env file. Does not require prior login.
         * @summary System Config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebGlobalConfigGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/global_config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provides the menu structure for the current session, only allowed nodes are returned. Recursive strucure.
         * @summary Menu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebMenusGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/menus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of tax-free countries
         * @summary List of tax-free countries data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebTaxfreeCountriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/taxfree-countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provides translations saved on db.
         * @summary Translations
         * @param {string} [filterLanguage] Language ISO code
         * @param {string} [filterCluster] Translation group
         * @param {number} [filterId] Translation id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebTranslationsGet: async (filterLanguage?: string, filterCluster?: string, filterId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posweb/translations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterLanguage !== undefined) {
                localVarQueryParameter['filter[language]'] = filterLanguage;
            }

            if (filterCluster !== undefined) {
                localVarQueryParameter['filter[cluster]'] = filterCluster;
            }

            if (filterId !== undefined) {
                localVarQueryParameter['filter[id]'] = filterId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the current connection status between the server and the corporate systems.
         * @summary Store connection status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SessionsConnectionStatusGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sessions/connection_status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the config of the current store.
         * @summary Store Config
         * @param {string} [filterSource] Client token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebConfigGet(filterSource?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStoreConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebConfigGet(filterSource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of countries
         * @summary List of countries data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebCountriesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCountries>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebCountriesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the global config of the current installation, can be interpreted as a view on the .env file. Does not require prior login.
         * @summary System Config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebGlobalConfigGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSystemConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebGlobalConfigGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Provides the menu structure for the current session, only allowed nodes are returned. Recursive strucure.
         * @summary Menu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebMenusGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMenu>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebMenusGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of tax-free countries
         * @summary List of tax-free countries data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebTaxfreeCountriesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCountries1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebTaxfreeCountriesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Provides translations saved on db.
         * @summary Translations
         * @param {string} [filterLanguage] Language ISO code
         * @param {string} [filterCluster] Translation group
         * @param {number} [filterId] Translation id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PoswebTranslationsGet(filterLanguage?: string, filterCluster?: string, filterId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTranslations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PoswebTranslationsGet(filterLanguage, filterCluster, filterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the current connection status between the server and the corporate systems.
         * @summary Store connection status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SessionsConnectionStatusGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStoreConnectionStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SessionsConnectionStatusGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemApiFp(configuration)
    return {
        /**
         * Returns the config of the current store.
         * @summary Store Config
         * @param {string} [filterSource] Client token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebConfigGet(filterSource?: string, options?: any): AxiosPromise<GetStoreConfig> {
            return localVarFp.apiV1PoswebConfigGet(filterSource, options).then((request) => request(axios, basePath));
        },
        /**
         * List of countries
         * @summary List of countries data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebCountriesGet(options?: any): AxiosPromise<GetCountries> {
            return localVarFp.apiV1PoswebCountriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the global config of the current installation, can be interpreted as a view on the .env file. Does not require prior login.
         * @summary System Config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebGlobalConfigGet(options?: any): AxiosPromise<GetSystemConfig> {
            return localVarFp.apiV1PoswebGlobalConfigGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Provides the menu structure for the current session, only allowed nodes are returned. Recursive strucure.
         * @summary Menu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebMenusGet(options?: any): AxiosPromise<GetMenu> {
            return localVarFp.apiV1PoswebMenusGet(options).then((request) => request(axios, basePath));
        },
        /**
         * List of tax-free countries
         * @summary List of tax-free countries data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebTaxfreeCountriesGet(options?: any): AxiosPromise<GetCountries1> {
            return localVarFp.apiV1PoswebTaxfreeCountriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Provides translations saved on db.
         * @summary Translations
         * @param {string} [filterLanguage] Language ISO code
         * @param {string} [filterCluster] Translation group
         * @param {number} [filterId] Translation id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PoswebTranslationsGet(filterLanguage?: string, filterCluster?: string, filterId?: number, options?: any): AxiosPromise<GetTranslations> {
            return localVarFp.apiV1PoswebTranslationsGet(filterLanguage, filterCluster, filterId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the current connection status between the server and the corporate systems.
         * @summary Store connection status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SessionsConnectionStatusGet(options?: any): AxiosPromise<GetStoreConnectionStatus> {
            return localVarFp.apiV1SessionsConnectionStatusGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     * Returns the config of the current store.
     * @summary Store Config
     * @param {string} [filterSource] Client token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV1PoswebConfigGet(filterSource?: string, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).apiV1PoswebConfigGet(filterSource, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of countries
     * @summary List of countries data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV1PoswebCountriesGet(options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).apiV1PoswebCountriesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the global config of the current installation, can be interpreted as a view on the .env file. Does not require prior login.
     * @summary System Config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV1PoswebGlobalConfigGet(options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).apiV1PoswebGlobalConfigGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provides the menu structure for the current session, only allowed nodes are returned. Recursive strucure.
     * @summary Menu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV1PoswebMenusGet(options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).apiV1PoswebMenusGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of tax-free countries
     * @summary List of tax-free countries data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV1PoswebTaxfreeCountriesGet(options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).apiV1PoswebTaxfreeCountriesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provides translations saved on db.
     * @summary Translations
     * @param {string} [filterLanguage] Language ISO code
     * @param {string} [filterCluster] Translation group
     * @param {number} [filterId] Translation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV1PoswebTranslationsGet(filterLanguage?: string, filterCluster?: string, filterId?: number, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).apiV1PoswebTranslationsGet(filterLanguage, filterCluster, filterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the current connection status between the server and the corporate systems.
     * @summary Store connection status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV1SessionsConnectionStatusGet(options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).apiV1SessionsConnectionStatusGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UtentiApi - axios parameter creator
 * @export
 */
export const UtentiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Chiamata per ottenere la lista degli utenti del negozio se si aggiunge il parametro filter[username], sono restituiti i dati del singolo utente
         * @summary Get List of User data or single user data
         * @param {string} [filterUsername] username of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtentiGet: async (filterUsername?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/utenti`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterUsername !== undefined) {
                localVarQueryParameter['filter[username]'] = filterUsername;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Azione per inserire un nuovo utente
         * @summary Azione per creare un nuovo utente
         * @param {InserimentoUtente} [inserimentoUtente]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtentiPost: async (inserimentoUtente?: InserimentoUtente, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/utenti`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inserimentoUtente, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Azione per aggiornare i dati di un utente, cambio password, cambio ruolo, cambio nome ecc...
         * @summary Azione per aggiornare i dati di un utente
         * @param {ModificaUtente} [modificaUtente]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtentiPut: async (modificaUtente?: ModificaUtente, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/utenti`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modificaUtente, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Chiamata per ottenere la lista dei ruoli disponibili
         * @summary Get List of User Roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtentiRuoliGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/utenti/ruoli`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Azione per eliminare un utente
         * @summary Azione per eliminare un utente
         * @param {string} username username of user to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtentiUsernameDelete: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('apiV1UtentiUsernameDelete', 'username', username)
            const localVarPath = `/api/v1/utenti/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtentiApi - functional programming interface
 * @export
 */
export const UtentiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtentiApiAxiosParamCreator(configuration)
    return {
        /**
         * Chiamata per ottenere la lista degli utenti del negozio se si aggiunge il parametro filter[username], sono restituiti i dati del singolo utente
         * @summary Get List of User data or single user data
         * @param {string} [filterUsername] username of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UtentiGet(filterUsername?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElencoUtenti>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UtentiGet(filterUsername, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Azione per inserire un nuovo utente
         * @summary Azione per creare un nuovo utente
         * @param {InserimentoUtente} [inserimentoUtente]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UtentiPost(inserimentoUtente?: InserimentoUtente, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EsitoCreazioneUtente>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UtentiPost(inserimentoUtente, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Azione per aggiornare i dati di un utente, cambio password, cambio ruolo, cambio nome ecc...
         * @summary Azione per aggiornare i dati di un utente
         * @param {ModificaUtente} [modificaUtente]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UtentiPut(modificaUtente?: ModificaUtente, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EsitoModificaUtente>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UtentiPut(modificaUtente, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Chiamata per ottenere la lista dei ruoli disponibili
         * @summary Get List of User Roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UtentiRuoliGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuoliUtente>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UtentiRuoliGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Azione per eliminare un utente
         * @summary Azione per eliminare un utente
         * @param {string} username username of user to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UtentiUsernameDelete(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EsitoCancellazioneUtente>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UtentiUsernameDelete(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UtentiApi - factory interface
 * @export
 */
export const UtentiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtentiApiFp(configuration)
    return {
        /**
         * Chiamata per ottenere la lista degli utenti del negozio se si aggiunge il parametro filter[username], sono restituiti i dati del singolo utente
         * @summary Get List of User data or single user data
         * @param {string} [filterUsername] username of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtentiGet(filterUsername?: string, options?: any): AxiosPromise<ElencoUtenti> {
            return localVarFp.apiV1UtentiGet(filterUsername, options).then((request) => request(axios, basePath));
        },
        /**
         * Azione per inserire un nuovo utente
         * @summary Azione per creare un nuovo utente
         * @param {InserimentoUtente} [inserimentoUtente]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtentiPost(inserimentoUtente?: InserimentoUtente, options?: any): AxiosPromise<EsitoCreazioneUtente> {
            return localVarFp.apiV1UtentiPost(inserimentoUtente, options).then((request) => request(axios, basePath));
        },
        /**
         * Azione per aggiornare i dati di un utente, cambio password, cambio ruolo, cambio nome ecc...
         * @summary Azione per aggiornare i dati di un utente
         * @param {ModificaUtente} [modificaUtente]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtentiPut(modificaUtente?: ModificaUtente, options?: any): AxiosPromise<EsitoModificaUtente> {
            return localVarFp.apiV1UtentiPut(modificaUtente, options).then((request) => request(axios, basePath));
        },
        /**
         * Chiamata per ottenere la lista dei ruoli disponibili
         * @summary Get List of User Roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtentiRuoliGet(options?: any): AxiosPromise<RuoliUtente> {
            return localVarFp.apiV1UtentiRuoliGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Azione per eliminare un utente
         * @summary Azione per eliminare un utente
         * @param {string} username username of user to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UtentiUsernameDelete(username: string, options?: any): AxiosPromise<EsitoCancellazioneUtente> {
            return localVarFp.apiV1UtentiUsernameDelete(username, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtentiApi - object-oriented interface
 * @export
 * @class UtentiApi
 * @extends {BaseAPI}
 */
export class UtentiApi extends BaseAPI {
    /**
     * Chiamata per ottenere la lista degli utenti del negozio se si aggiunge il parametro filter[username], sono restituiti i dati del singolo utente
     * @summary Get List of User data or single user data
     * @param {string} [filterUsername] username of user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtentiApi
     */
    public apiV1UtentiGet(filterUsername?: string, options?: AxiosRequestConfig) {
        return UtentiApiFp(this.configuration).apiV1UtentiGet(filterUsername, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Azione per inserire un nuovo utente
     * @summary Azione per creare un nuovo utente
     * @param {InserimentoUtente} [inserimentoUtente]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtentiApi
     */
    public apiV1UtentiPost(inserimentoUtente?: InserimentoUtente, options?: AxiosRequestConfig) {
        return UtentiApiFp(this.configuration).apiV1UtentiPost(inserimentoUtente, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Azione per aggiornare i dati di un utente, cambio password, cambio ruolo, cambio nome ecc...
     * @summary Azione per aggiornare i dati di un utente
     * @param {ModificaUtente} [modificaUtente]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtentiApi
     */
    public apiV1UtentiPut(modificaUtente?: ModificaUtente, options?: AxiosRequestConfig) {
        return UtentiApiFp(this.configuration).apiV1UtentiPut(modificaUtente, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Chiamata per ottenere la lista dei ruoli disponibili
     * @summary Get List of User Roles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtentiApi
     */
    public apiV1UtentiRuoliGet(options?: AxiosRequestConfig) {
        return UtentiApiFp(this.configuration).apiV1UtentiRuoliGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Azione per eliminare un utente
     * @summary Azione per eliminare un utente
     * @param {string} username username of user to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtentiApi
     */
    public apiV1UtentiUsernameDelete(username: string, options?: AxiosRequestConfig) {
        return UtentiApiFp(this.configuration).apiV1UtentiUsernameDelete(username, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VersamentiApi - axios parameter creator
 * @export
 */
export const VersamentiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Uses timestamp to check if bank is valid at that time
         * @summary Searches for banks active at timestamp
         * @param {string} [filterTimestamp] Timestamp as string (?)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BancheGet: async (filterTimestamp?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/banche`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterTimestamp !== undefined) {
                localVarQueryParameter['filter[timestamp]'] = filterTimestamp;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Print a bank deposit
         * @summary Print a bank deposit
         * @param {PostStampaVersamenti} [postStampaVersamenti]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StampaVersamentoPost: async (postStampaVersamenti?: PostStampaVersamenti, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/stampa-versamento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postStampaVersamenti, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of bank deposits types
         * @summary list of bank deposit types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TipiVersamentoGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tipi-versamento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * check amount vs max cash amount for aml  amount and selectedOption are required!
         * @summary check amount vs max cash amount for aml
         * @param {ControlloVersamento} [controlloVersamento]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VersamentiControllaImportoAntiriciclaggioPost: async (controlloVersamento?: ControlloVersamento, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/versamenti/controlla-importo-antiriciclaggio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlloVersamento, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List existing bank deposits
         * @summary Search for bank deposits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VersamentiGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/versamenti`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an existing bank deposit
         * @summary Retrieve a bank deposit
         * @param {string} key Deposit id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VersamentiKeyGet: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('apiV1VersamentiKeyGet', 'key', key)
            const localVarPath = `/api/v1/versamenti/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get max cash amount for aml
         * @summary get max cash amount for aml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VersamentiMaxAmountAmlGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/versamenti/max-amount-aml`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create bank deposits
         * @summary Create bank deposits
         * @param {PostVersamenti} [postVersamenti]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VersamentiPost: async (postVersamenti?: PostVersamenti, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/versamenti`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postVersamenti, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VersamentiApi - functional programming interface
 * @export
 */
export const VersamentiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VersamentiApiAxiosParamCreator(configuration)
    return {
        /**
         * Uses timestamp to check if bank is valid at that time
         * @summary Searches for banks active at timestamp
         * @param {string} [filterTimestamp] Timestamp as string (?)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1BancheGet(filterTimestamp?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBanche>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1BancheGet(filterTimestamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Print a bank deposit
         * @summary Print a bank deposit
         * @param {PostStampaVersamenti} [postStampaVersamenti]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1StampaVersamentoPost(postStampaVersamenti?: PostStampaVersamenti, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostStampaVersamentiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1StampaVersamentoPost(postStampaVersamenti, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of bank deposits types
         * @summary list of bank deposit types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TipiVersamentoGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTipoVersamento>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TipiVersamentoGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * check amount vs max cash amount for aml  amount and selectedOption are required!
         * @summary check amount vs max cash amount for aml
         * @param {ControlloVersamento} [controlloVersamento]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VersamentiControllaImportoAntiriciclaggioPost(controlloVersamento?: ControlloVersamento, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllaImportoAntiriciclaggio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VersamentiControllaImportoAntiriciclaggioPost(controlloVersamento, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List existing bank deposits
         * @summary Search for bank deposits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VersamentiGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GestVersamenti>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VersamentiGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get an existing bank deposit
         * @summary Retrieve a bank deposit
         * @param {string} key Deposit id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VersamentiKeyGet(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Versamento>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VersamentiKeyGet(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get max cash amount for aml
         * @summary get max cash amount for aml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VersamentiMaxAmountAmlGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMaxAmountAml>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VersamentiMaxAmountAmlGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create bank deposits
         * @summary Create bank deposits
         * @param {PostVersamenti} [postVersamenti]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VersamentiPost(postVersamenti?: PostVersamenti, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostVersamentiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VersamentiPost(postVersamenti, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VersamentiApi - factory interface
 * @export
 */
export const VersamentiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VersamentiApiFp(configuration)
    return {
        /**
         * Uses timestamp to check if bank is valid at that time
         * @summary Searches for banks active at timestamp
         * @param {string} [filterTimestamp] Timestamp as string (?)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BancheGet(filterTimestamp?: string, options?: any): AxiosPromise<GetBanche> {
            return localVarFp.apiV1BancheGet(filterTimestamp, options).then((request) => request(axios, basePath));
        },
        /**
         * Print a bank deposit
         * @summary Print a bank deposit
         * @param {PostStampaVersamenti} [postStampaVersamenti]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1StampaVersamentoPost(postStampaVersamenti?: PostStampaVersamenti, options?: any): AxiosPromise<PostStampaVersamentiResponse> {
            return localVarFp.apiV1StampaVersamentoPost(postStampaVersamenti, options).then((request) => request(axios, basePath));
        },
        /**
         * List of bank deposits types
         * @summary list of bank deposit types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TipiVersamentoGet(options?: any): AxiosPromise<GetTipoVersamento> {
            return localVarFp.apiV1TipiVersamentoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * check amount vs max cash amount for aml  amount and selectedOption are required!
         * @summary check amount vs max cash amount for aml
         * @param {ControlloVersamento} [controlloVersamento]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VersamentiControllaImportoAntiriciclaggioPost(controlloVersamento?: ControlloVersamento, options?: any): AxiosPromise<ControllaImportoAntiriciclaggio> {
            return localVarFp.apiV1VersamentiControllaImportoAntiriciclaggioPost(controlloVersamento, options).then((request) => request(axios, basePath));
        },
        /**
         * List existing bank deposits
         * @summary Search for bank deposits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VersamentiGet(options?: any): AxiosPromise<GestVersamenti> {
            return localVarFp.apiV1VersamentiGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get an existing bank deposit
         * @summary Retrieve a bank deposit
         * @param {string} key Deposit id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VersamentiKeyGet(key: string, options?: any): AxiosPromise<Versamento> {
            return localVarFp.apiV1VersamentiKeyGet(key, options).then((request) => request(axios, basePath));
        },
        /**
         * get max cash amount for aml
         * @summary get max cash amount for aml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VersamentiMaxAmountAmlGet(options?: any): AxiosPromise<GetMaxAmountAml> {
            return localVarFp.apiV1VersamentiMaxAmountAmlGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Create bank deposits
         * @summary Create bank deposits
         * @param {PostVersamenti} [postVersamenti]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VersamentiPost(postVersamenti?: PostVersamenti, options?: any): AxiosPromise<PostVersamentiResponse> {
            return localVarFp.apiV1VersamentiPost(postVersamenti, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VersamentiApi - object-oriented interface
 * @export
 * @class VersamentiApi
 * @extends {BaseAPI}
 */
export class VersamentiApi extends BaseAPI {
    /**
     * Uses timestamp to check if bank is valid at that time
     * @summary Searches for banks active at timestamp
     * @param {string} [filterTimestamp] Timestamp as string (?)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersamentiApi
     */
    public apiV1BancheGet(filterTimestamp?: string, options?: AxiosRequestConfig) {
        return VersamentiApiFp(this.configuration).apiV1BancheGet(filterTimestamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Print a bank deposit
     * @summary Print a bank deposit
     * @param {PostStampaVersamenti} [postStampaVersamenti]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersamentiApi
     */
    public apiV1StampaVersamentoPost(postStampaVersamenti?: PostStampaVersamenti, options?: AxiosRequestConfig) {
        return VersamentiApiFp(this.configuration).apiV1StampaVersamentoPost(postStampaVersamenti, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of bank deposits types
     * @summary list of bank deposit types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersamentiApi
     */
    public apiV1TipiVersamentoGet(options?: AxiosRequestConfig) {
        return VersamentiApiFp(this.configuration).apiV1TipiVersamentoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * check amount vs max cash amount for aml  amount and selectedOption are required!
     * @summary check amount vs max cash amount for aml
     * @param {ControlloVersamento} [controlloVersamento]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersamentiApi
     */
    public apiV1VersamentiControllaImportoAntiriciclaggioPost(controlloVersamento?: ControlloVersamento, options?: AxiosRequestConfig) {
        return VersamentiApiFp(this.configuration).apiV1VersamentiControllaImportoAntiriciclaggioPost(controlloVersamento, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List existing bank deposits
     * @summary Search for bank deposits
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersamentiApi
     */
    public apiV1VersamentiGet(options?: AxiosRequestConfig) {
        return VersamentiApiFp(this.configuration).apiV1VersamentiGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an existing bank deposit
     * @summary Retrieve a bank deposit
     * @param {string} key Deposit id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersamentiApi
     */
    public apiV1VersamentiKeyGet(key: string, options?: AxiosRequestConfig) {
        return VersamentiApiFp(this.configuration).apiV1VersamentiKeyGet(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get max cash amount for aml
     * @summary get max cash amount for aml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersamentiApi
     */
    public apiV1VersamentiMaxAmountAmlGet(options?: AxiosRequestConfig) {
        return VersamentiApiFp(this.configuration).apiV1VersamentiMaxAmountAmlGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create bank deposits
     * @summary Create bank deposits
     * @param {PostVersamenti} [postVersamenti]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersamentiApi
     */
    public apiV1VersamentiPost(postVersamenti?: PostVersamenti, options?: AxiosRequestConfig) {
        return VersamentiApiFp(this.configuration).apiV1VersamentiPost(postVersamenti, options).then((request) => request(this.axios, this.basePath));
    }
}


